<header class="header appContainer">
  <div class="header__item">
    <!-- [TODO] Melhorar nomenclatura da função toogle -->
    <button
      class="btnIcon"
      [class.d-none]="!showToogleContratante"
      (click)="showSide()"
      title="Abrir menu"
      type="button"
    >
      <img src="assets/icons/menu-toogle.svg" alt="abrir menu" height="20" width="20"/>
    </button>

    <button
      class="btn mr-1"
      [class.d-none]="!showToogleContratante"
      (click)="home()"
      type="button"
      title="Ir para Home"
    >
      <img src="assets/img/logo-white.svg" alt="logo profissionais SA" height="35" />
    </button>
  </div>

  <!-- [TODO] - Habilitar para que a mensagem de Bem vindo apareça só na home-->
  <div *ngIf="currentRoute == 'home'" class="header__item">
  <!-- <div class="header__item d-none d-lg-block"> -->
    <!-- [TODO] Aplicar nome do usuário, mas primeiro essa informação deve ser disponibilizada globalmente -->
    <h1 class="title title--large">Bem-vindo(a), {{nome}}!</h1>
  </div>

  <div class="header__item justify-content-end">
    <a
      *ngFor="let i of menu"
      class="btnIcon"

      (click)="go(i.route)"
      [title]="i.title"
      href="javascript:void()"
    >
      <img
        [src]="i.icon"
        alt="i.icon"
        height="20"
        width="20"
      />
    </a>

    <a
      class="btnIcon"
      (click)="logout()"
      title="Sair"
      href="javascript:void()"
    >
      <img
        src="assets/icons/out.svg"
        alt="Sair"
        height="20"
      />
    </a>
  </div>
</header>
