import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ComunicacaoService, IViewersByCompany, IFilterGetUsersPush } from 'src/app/services/comunicacao';
import { GrupoService } from 'src/app/services/grupo';
import { ModalPushComponent } from '../modal-push';

interface IUsers extends IViewersByCompany {
  added: boolean
}



@Component({
  selector: 'app-push-users',
  templateUrl: './push-users.component.html',
  styleUrls: ['./push-users.component.scss']
})
export class PushUsersComponent implements OnInit, OnDestroy {
  bsModalRef: BsModalRef;

  page: number = 0;
  qtdePages: Array<number>;
  loading: boolean = false;
  isOpenFilters: boolean = true;

  groups: Array<any> = []

  users: Array<IUsers> = []
  usersSelected: Array<number> = []

  filter: IFilterGetUsersPush = { id_group: null, onlyLeader: null, onlyContributors: null, onlyUsers: true }

  constructor(
    private titleService: Title,
    private modalService: BsModalService,
    private toast: ToastrService,
    private comunicacaoService: ComunicacaoService,
    private grupoService: GrupoService,) { }

  ngOnDestroy(): void {
    this.titleService.setTitle("Dashbaord")
  }

  async ngOnInit() {
    this.titleService.setTitle("Comunicação interna")
    this.load()
    const resGroups = await this.grupoService.getGrupos();
    this.groups = resGroups;
  }

  add(user: IUsers) {
    let isInclude = this.usersSelected.find((i: number) => i == user.id_usuario)

    if (isInclude) {
      let index = this.usersSelected.indexOf(isInclude)
      this.usersSelected.splice(index, 1)
      return
    }
    this.usersSelected.push(user.id_usuario)
  }

  async load() {
    try {
      this.loading = true
      const res: Array<IUsers> = await this.comunicacaoService.getContributors(this.page, this.filter)
      this.loadPage()
      this.users = res
      this.setUsersSeleted()
      this.loading = false
    } catch (error) {
      this.toast.error("Falha de conexão ao buscar usuários")
      this.loading = false
    }
  }

  async loadPage() {
    this.loading = true
    let onlyLeader = this.filter.onlyContributors
    if (onlyLeader == true) onlyLeader = this.filter.onlyLeader
    const res: any = await this.comunicacaoService.getPagesContributors(this.filter)
    this.setQtdePages(res)
    this.loading = false
  }


  async loadAllIds(e: any) {
    if (!e.target.checked) {
      this.usersSelected = []
      this.setUsersSeleted()
      return
    }
    try {
      this.loading = true
      let onlyLeader = this.filter.onlyContributors
      if (this.filter.onlyLeader == true) onlyLeader = this.filter.onlyLeader
      const res: Array<{ id }> = await this.comunicacaoService.getAllIds(this.filter)
      this.usersSelected = res.map((i) => { return i.id })
      this.setUsersSeleted()
      this.loading = false
    } catch (error) {
      this.toast.error("Falha de conexão ao buscar usuários")
      this.loading = false
    }
  }

  setUsersSeleted() {
    this.users.map((i: IUsers) => { i.added = this.usersSelected.includes(i.id_usuario) })
  }

  changePage(number: number) {
    this.page = number;
    this.load()
  }

  setQtdePages(amount: number) {
    this.qtdePages = Array(amount);
  }

  openFilter() {
    this.isOpenFilters = !this.isOpenFilters
  }

  applyFilter() {
    this.page = 0
    this.load()
  }

  resetFilter() {
    this.filter = { id_group: null, onlyLeader: null, onlyContributors: null, onlyUsers: true }
    this.load()
  }


  nextStep() {

    if (this.usersSelected.length == 0) {
      this.toast.error("Nenhum usuário selecionado", "Selecione seus destinatários")
      return
    }

    const initialState = { users: this.usersSelected };
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalPushComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();

      if (this.bsModalRef.content.sent) {
        this.resetFilter()
        this.usersSelected = []
      }
    })
  }

  setFilterOnlyLeader() {
    if (this.filter.onlyContributors) this.filter.onlyLeader = false
  }

  setFilterOnlyContributors() {
    if (this.filter.onlyLeader) this.filter.onlyContributors = false
  }
}
