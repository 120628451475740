import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common';
import { MeusConteudosService } from 'src/app/services/meus-conteudos';
import { S3Service } from 'src/app/services/s3';
import { IInvalid } from '../../configuracoes/palestrante/tb-dados';

export interface IHabilidade {
  id: number,
  titulo: string,
  descricao: string,
  resumo: string,
  image_src: string,
  video_link: string,
  pdf_link: string,
  id_contratante: number
  id_palestrante: number
  pergunta: any
  arquivo_principal: string
  id_assunto: number
  id_casa_status: number
  status: "PUBLICADO" | "CANCELADO" | "RASCUNHO"
}

export interface IOpcao {
  ordem: number
  descricao_opcao: string
  peso: number
  explicacao: string
}

export interface IPergunta {
  id: number
  pergunta: string
  tipo: string
  opcoes: Array<IOpcao>
  ativa: number
  id_palestrante: number
  id_contratante: number
}

@Component({
  selector: 'app-habilidade',
  templateUrl: './habilidade.component.html',
  styleUrls: ['./habilidade.component.scss', '../common-style.scss']
})
export class HabilidadeComponent implements OnInit {

  invalids: Array<IInvalid> = []
  temas: Array<any> = []
  complexidades: Array<any> = []

  idHabilidade: number
  idCompetencia: number
  idContratante: number
  loading: boolean = false
  loadingImg: boolean = false
  loadingVideo: boolean = false
  loadingPDF: boolean = false

  habilidade: IHabilidade

  principais: Array<any> = [
    { text: "PDF ", value: "PDF" },
    { text: "Vídeo", value: "VIDEO" },]

  arrayStatus: Array<any> = [
    { text: "Ativo ", value: "PUBLICADO" },
    // { text: "Cancelado", value: "CANCELADO" },
    { text: "Inativo", value: "RASCUNHO" },]

  new_image_src: any
  new_video_src: any
  new_pdf_src: any

  bkp_links_s3: Array<any> = []

  isOwner: boolean = false

  progress: number = 1;

  constructor(
    private commonService: CommonService,
    private toast: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private meusConteudosService: MeusConteudosService,
    private s3Service: S3Service,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Meus conteúdos - Habilidade e Pergunta")
    let usuario = JSON.parse(localStorage.getItem("usuario"))
    this.idContratante = usuario.id_contratante
    this.idCompetencia = parseInt(this.route.snapshot.paramMap.get("idCompetencia"))
    this.idHabilidade = parseInt(this.route.snapshot.paramMap.get("idHabilidade"))

    if (this.idHabilidade > 0) this.getHabilidade()
    else {
      this.setDefaultHabilidade();
      this.isOwner = true
    }

    this.getInfo()
  }

  async getHabilidade() {
    try {
      this.loading = true
      const result = await this.meusConteudosService.getHabilidadeById(this.idHabilidade)
      this.habilidade = result
      if (!this.habilidade.arquivo_principal) this.habilidade.arquivo_principal = null
      if (!this.habilidade.id_assunto) this.habilidade.id_assunto = null
      if (this.idContratante == this.habilidade.id_contratante ||
        (this.meusConteudosService.idContratantePSA == this.idContratante && !this.habilidade.id_contratante)) {
        this.isOwner = true
      }
      this.loading = false
    } catch (error) {
      

      this.loading = false
      this.toast.error("Não foi possível carregar a habilidade!")
    }
  }

  async getInfo() {
    try {
      this.temas = await this.commonService.getAllTemas(false)
      this.complexidades = await this.commonService.getComplexidadesArtigo()
    } catch (error) {
      this.toast.error("Não foi possível carregar seus temas e complexidades")
    }
  }

  setDefaultHabilidade() {
    this.habilidade = {
      id: null,
      titulo: '',
      descricao: '',
      resumo: '',
      image_src: null,
      video_link: null,
      pdf_link: null,
      id_contratante: this.idContratante,
      id_palestrante: null,
      arquivo_principal: null,
      pergunta: null,
      id_assunto: null,
      id_casa_status: null,
      status: "RASCUNHO"
    }
  }

  getPDF() {
    if (this.habilidade.pdf_link && this.habilidade.pdf_link.length > 0)
      return this.habilidade.pdf_link
  }



  async onFileImage(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      let size = file.size / 1000

      if (size > 300) {
        this.toast.error("Não é permitido imagem com tamanho maior que 500MB")
        this.invalids.push({ campo: "new_image_src", erro: "Não é permitido imagem com tamanho maior que 500MB" })
        return
      }
      this.loadingImg = true
      try {
        this.getProgress(10)
        let bkp_url = this.habilidade.image_src
        let result = await this.s3Service.uploadFile(file, "competencias/habilidades/imagens/")

        this.progress = 100
        this.habilidade.image_src = result.url
        this.toast.success("Arquivo carregado com sucesso")
        this.loadingImg = false

        if (bkp_url) this.bkp_links_s3.push(bkp_url)

      } catch (error) {
        this.loadingImg = false

        this.toast.error("Falha ao carregar imagem")
      }
    }
  }


  async onFileVideo(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      if (file.type != "video/mp4") {
        this.toast.error("É permitido somente vídeos MP4")
        this.invalids.push({ campo: "new_video_src", erro: "É permitido somente vídeos MP4" })
        this.new_video_src = null
        return
      }

      let size = file.size / 1000000
      let maxSize = 550

      if (size > maxSize) {
        this.toast.error("Não é permitido vídeos com tamanho maior que 500MB")
        this.invalids.push({ campo: "new_video_src", erro: "Não é permitido vídeos com tamanho maior que 500MB" })
        this.new_video_src = null
        return
      }
      this.loadingVideo = true
      try {
        this.getProgress(800)
        let bkp_url = this.habilidade.video_link
        let result = await this.s3Service.uploadFile(file, "competencias/habilidades/videos/")

        this.progress = 100
        this.habilidade.video_link = result.url
        this.toast.success("Arquivo carregado com sucesso")
        this.loadingVideo = false

        if (bkp_url) this.bkp_links_s3.push(bkp_url)

      } catch (error) {
        this.loadingVideo = false

        this.toast.error("Falha ao carregar vídeo")
      }

    }
  }

  async onFilePDF(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      let size = file.size / 1000000

      if (size > 500) {
        this.toast.error("Não é permitido PDF com tamanho maior que 500MB")
        this.invalids.push({ campo: "new_psd_src", erro: "Não é permitido PDF com tamanho maior que 500MB" })
        return
      }
      this.loadingPDF = true
      try {
        this.getProgress(15)
        let bkp_url = this.habilidade.pdf_link
        let result = await this.s3Service.uploadFile(file, "competencias/habilidades/pdf/")

        this.progress = 100
        this.habilidade.pdf_link = result.url
        this.toast.success("PDF carregado com sucesso")
        this.loadingPDF = false

        if (bkp_url) this.bkp_links_s3.push(bkp_url)

      } catch (error) {
        this.loadingPDF = false

        this.toast.error("Falha ao carregar vídeo")
      }

    }
  }

  async submit() {
    try {
      this.invalids = []
      this.loading = true
      let res = await this.meusConteudosService.insertUpdateHabilidade(this.habilidade, this.idCompetencia)
      this.toast.success("Salvo com sucesso!")
      this.loading = false

      this.deleteFiles()
      this.idHabilidade = res.idHabilidade
      this.router.navigate([`/conteudos/habilidade/${this.idCompetencia}/${this.idHabilidade}`])
      this.getHabilidade()
    } catch (e) {
      this.loading = false
      if (e?.error?.message.invalids) {
        this.invalids = e.error.message.invalids
        this.toast.error("Campos inválidos!", "Por favor, revise.")
      } else {
        this.toast.error("Não foi possível salvar os dados", "Falha na conexão")
      }
    }
  }

  deleteFiles() {
    this.s3Service.deleteCollectionFiles(this.bkp_links_s3)
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }

  getProgress(ms: number) {
    this.progress = 10
    var id = setInterval(
      () => {
        if (this.progress >= 100) clearInterval(id);
        else this.progress += 1;
      }, ms);
  }

  getBtnDisabled() {
    return this.loadingImg || this.loadingPDF || this.loadingVideo || this.loading
  }
}
