<section class="container-fluid px-1 px-md-5">
    <div class="row">
        <header class="col-12 pl-md-0">
            <h3 class="font-title">Meus certificados</h3>
        </header>
        <article class="col-12 container__default">
            <ul class="list-group">

                <span [class.d-none]="certificates.length > 0" class="empty">Nenhum certificado encontrado</span>

                <li class="list-group-item d-flex flex-column flex-md-row justify-content-between"
                    *ngFor="let item of certificates">
                    <div class="d-flex flex-column container__item__info">
                        <div class="d-flex flex-column">
                            <header class="d-flex align-items-center">
                                <img class="mr-2" style="height: 1.25rem;" src="assets/icons/certificate.svg"
                                    alt="certificado">
                                <h6 class="font-title mb-0">
                                    {{item.nome}}
                                </h6>
                            </header>
                            <label class="label__title mt-2">
                                Tema: <span class="span__info">{{item.tema}}</span>
                            </label>
                            <label class="label__title">
                                Carga horária: <span class="span__info">{{item.carga_horaria}}</span>
                            </label>
                            <label class="label__title">
                                Palestrante: <span class="span__info">{{item.palestrante}}</span>
                            </label>
                        </div>
                        <div class="d-flex border-top mt-2 mr-2">
                            <div class="d-flex pt-2">
                                <img class="img__info" src="assets/icons/pin.svg" alt="local">
                                <div class="d-flex flex-column mr-5">
                                    <label class="label__title">Formato</label>
                                    <span class="span__info">{{item.tipo?.toLowerCase()}}</span>
                                </div>
                                <div class="d-flex flex-column">
                                    <label class="label__title">Conclusão</label>
                                    <span class="span__info">{{item.data_palestra}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="border-left px-2"></div>

                    <div class="d-flex container__item__buttons mr-2 mt-2 mt-md-0">
                        <button *ngIf="idPalestra != item.id_palestra"
                            (click)="getCertificate(item.id_palestra, item.pdf_url)" class="button__action"
                            title="abrir certificado">
                            <img src="assets/icons/document.svg" alt="abrir">
                            <span>visualizar</span>
                        </button>
                        <button *ngIf="loading && idPalestra == item.id_palestra" class="button__action"
                            title="abrir certificado">
                            <img src="assets/img/simple_loading.gif" alt="carregando">
                        </button>
                    </div>
                </li>

            </ul>
        </article>
    </div>
</section>
