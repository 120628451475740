<div class="p-4">
    <div class="flexFull">
        <h3 class="font-title text-center">Solicite um orçamento para o seu evento</h3>
    </div>
    <div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </div>
    <form (ngSubmit)="sendInfo()">
        <div class="form-group">
            <textarea style="width: 100%" class="form-control" [(ngModel)]="descricao" name="descricao" type="text"
                placeholder="Se você tem mais detalhes sobre o seu evento, insira aqui">
            </textarea>
        </div>
        <div class="flexFull">
            <button type="submit" class="btn btn-warning text-uppercase">Orçar</button>
        </div>
    </form>
</div>