import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MeusConteudosService } from 'src/app/services/meus-conteudos';

export interface IHabilidadeContratante {
  id: number,
  descricao: string,
  resumo: string,
  id_contratante: number
}

@Component({
  selector: 'app-habilidades',
  templateUrl: './habilidades.component.html',
  styleUrls: ['./habilidades.component.scss', '../common-style.scss']
})
export class HabilidadesComponent implements OnInit {

  idCompetencia: number
  idContratante: number
  loading: boolean = false
  isPSA: boolean = false
  competencia: any
  habilidades: Array<IHabilidadeContratante> = []

  constructor(
    private meusConteudosService: MeusConteudosService,
    private toast: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Meus conteúdos - Habilidades")
    let usuario = JSON.parse(localStorage.getItem("usuario"))
    this.idContratante = usuario.id_contratante
    this.idCompetencia = parseInt(this.route.snapshot.paramMap.get("idCompetencia"))
    this.getHabilidades()

    this.meusConteudosService.getCompetenciaById(this.idCompetencia)
      .then((res: any) => this.competencia = res)

    if (this.meusConteudosService.idContratantePSA == usuario.id_contratante) this.isPSA = true
  }

  async getHabilidades() {
    try {
      this.loading = true
      this.habilidades = await this.meusConteudosService.getHabilidadesByCompetencia(this.idCompetencia)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.toast.error("Não foi possível carregar suas habilidades!")
    }
  }

  delete() {
    this.toast.info('Em desenvolvimento.', 'Em breve traremos novidades.')
  }

  insert() {
    this.router.navigate([`/conteudos/habilidade/${this.idCompetencia}/${0}`])
  }

  goHabilidade(id: any) {
    this.router.navigate([`/conteudos/habilidade/${this.idCompetencia}/${id}`])
  }

  getResumo(resumo: string) {
    if (resumo.length <= 130) return resumo
    return resumo.substring(0, 130) + "..."
  }
}
