import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GrupoService } from 'src/app/services/grupo';
import { ToastrService } from 'ngx-toastr';
import { IColaborador } from '../colaboradores';

interface IColaboradorItem extends IColaborador {
  isLider: boolean
}

interface IUpdateCollection {
  id_colaborador: number
  id_espectador: number | null
  id_grupo: number
  isLider: boolean
}

@Component({
  selector: 'app-modal-adicionar-lideres',
  templateUrl: './modal-adicionar-lideres.component.html',
  styleUrls: ['./modal-adicionar-lideres.component.scss']
})
export class ModalAdicionarLideresComponent implements OnInit {

  id_grupo: number;

  lideres: Array<IColaborador>;

  loading: boolean = false;
  success: boolean = true;
  qtdePages: Array<number> = [];
  page: number = 0;
  search: string = "";

  lideresEditar: Array<IUpdateCollection> = [];

  limitPage: number = 10
  selectLimits: Array<number> = [10, 20, 30]

  constructor(
    public bsModalRef: BsModalRef,
    private grupoService: GrupoService,
    private toast: ToastrService,) { }

  ngOnInit(): void {
    this.loadColaboradores();
  }


  close() {
    this.bsModalRef.hide();
  }


  async loadColaboradores() {
    try {
      const result = await this.grupoService.getLideres(this.id_grupo, this.page, this.search, this.limitPage);

      this.lideres = result.lideres ? result.lideres : [];
      this.lideres.map((i: IColaboradorItem) => i.isLider = this.valueIsLider(i.id_colaborador))
      this.setQtdePages(result.quantidade)

    } catch (error) {
      console.log('error', error);
      this.toast.error("Não foi possível buscar os tutores")
    }
  }

  async salvar() {
    try {
      this.loading = true

      await this.grupoService.saveLideres({
        id_grupo: this.id_grupo,
        lideres: this.lideresEditar
      })

      this.loading = false
      this.toast.success("Salvo com sucesso")
      this.success = true;
      this.close()
    } catch (error) {
      this.loading = false
    }
  }

  addLider(lider: IColaboradorItem) {

    let aux = {
      id_colaborador: lider.id_colaborador,
      id_espectador: lider.id_espectador,
      isLider: true,
      id_grupo: this.id_grupo
    }
    var isInclude = this.lideresEditar.find(i => i.id_colaborador == lider.id_colaborador)

    if (isInclude) {
      let index = this.lideresEditar.indexOf(isInclude)
      this.lideresEditar.splice(index, 1)
      return
    }
    this.lideresEditar.push(aux)

  }

  valueIsLider(id_colaborador: number): boolean {
    let isInclude = this.lideresEditar.find(i => i.id_colaborador == id_colaborador)
    return isInclude ? true : false
  }

  changePage(number: number) {
    this.page = number;
    this.loadColaboradores()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array.from(Array(qtdeColaboradores).keys())
  }

  getPages() {
    let listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2))
    return listPage
  }

  buscar() {
    this.page = 0
    this.loadColaboradores()
  }

  selectAll(e: any) {
    let value: boolean = e.target.checked
    this.lideres.forEach((i: any) => {
      i.responsavel_grupo_bool = value
      this.addLider(i)
    })
  }
}
