import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { arrayOrders, IOrder } from 'src/app/common/array-filtro-colaboradores';
import { ContratanteService } from 'src/app/services/contratante';
import { GrupoService } from 'src/app/services/grupo';

@Component({
  selector: 'app-list-lideres',
  templateUrl: './list-lideres.component.html',
  styleUrls: ['./list-lideres.component.scss']
})
export class ListLideresComponent implements OnInit {
  @Output("selected") selected = new EventEmitter();

  lideres: Array<any>;
  page: number = 0;
  qtdePages: Array<number>;
  search: string = "";
  loading: boolean = false;
  orders: Array<IOrder> = arrayOrders;
  order: string = this.orders[1].value;

  constructor(
    private grupoService: GrupoService,
    private contratanteService: ContratanteService,
    private toast: ToastrService) { }

  ngOnInit(): void {
    this.getLideres()
  }



  async getLideres() {
    try {
      const result = await this.contratanteService.getLideres(this.page, this.order, this.search);
      this.lideres = result.lideres ? result.lideres : [];
      this.setQtdePages(result.quantidade)

    } catch (error) {
      console.log('error', error);
    }
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array(qtdeColaboradores);
  } changePage(number: number) {
    this.page = number;
    this.getLideres()
  }

  buscar() {
    this.page = 0
    this.getLideres()
  }

  changeOrder() {
    this.page = 0
    this.getLideres()
  }

  selectLider(lider: any) {
    this.selected.emit({ lider: lider })
  }
}
