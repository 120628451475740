import { Component, OnInit } from '@angular/core';
import { getUserLocalStorage } from 'src/app/controle-perfil';
import { EspectadoresService, ITotalizers } from 'src/app/services/espectadores';
import { IResSkills, ISkill, TarefasService } from 'src/app/services/tarefas';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ToastrService } from 'ngx-toastr';
import { PalestrasService } from 'src/app/services/palestras';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: true,
    dots: false,
    nav: false,
    autoHeight: false,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      }
    }
  }
  destaques: Array<any>

  totalizers: ITotalizers = {
    total_inscricao: 0,
    total_tarefas_pendentes: 0,
    total_certificados: 0,
    total_avaliacao_pendentes: 0
  };

  eventSelected: number = null;
  isLeader: boolean = false;

  userName: string = null;
  nextSkill: ISkill = null;
  percent: number = 0;

  constructor(
    private espectadoresService: EspectadoresService,
    private skillService: TarefasService,
    private palestrasService: PalestrasService,
    private toast: ToastrService,
    private router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    this.espectadoresService.getProfile().then(espectador => {
      if(espectador && !espectador.user.trilha_tipo){
        this.router.navigate(['espectador', 'trilha-personalizada'])
      }
    })
    this.espectadoresService.getTotalizers().then((res: ITotalizers) => { if (res) this.totalizers = res })
    this.destaques = await this.palestrasService.getDestaques()
    let user = getUserLocalStorage()
    this.userName = user.nome
    this.isLeader = user.responsavel_grupo == "SIM"
    const [percent] = await this.palestrasService.getPercent();
    this.percent = Math.round(percent.perc_concluido)
    this.getSkills()
  }

  async getSkills() {
    const result: IResSkills = await this.skillService.getSkills()
    if (result.proximaTarefa) this.nextSkill = result.proximaTarefa
  }

  selectToShowEvent(e: any) {
    this.setEventSelected(e.id)
  }

  setEventSelected(value: number) {
    this.eventSelected = value
  }

  messageInDevelopment() {
    this.toast.info('Em desenvolvimento.', 'Aguarde novidades.')
  }
}
