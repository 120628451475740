<div class="p-4">
    <button (click)="bsModalRef.hide()" type="button" class="btn__close close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="font-title mb-4 b-bottom-1">Alterar mídia</h3>

    <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="div__img flexFull mb-3" *ngIf="formGroup.controls.file.value">
            <img src="{{this.formGroup.controls.file.value}}" alt="midia">
        </div>

        <div class="form-group">
            <input (change)="onFileChange($event)" type="file" name="midia" class="form-control-file" accept="image/*" id="inputfile">
        </div>
        <form class="form-group col-12 px-4">
            <label class="label-input" for="nome">
              senha *
            </label>
            <input id="password" class="form-control"
              name="password" type="password"
              autocomplete="on"
              [(ngModel)]="password"
              placeholder="Insira a senha para alterar">
        </form>

        <div class="d-flex align-items-center justify-content-center mt-2">
            <button type="button" [disabled]="password.length == 0" (click)="changeMidia()"
                class="btn btn-primary btn-warning">Salvar</button>

            <button *ngIf="loading" class="btn btn-primary btn-warning">
                <img height="40px" src="../../../assets/img/simple_loading.gif" alt="loading">
            </button>
        </div>
    </div>
</div>