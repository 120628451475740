<div class="container-fluid pl-lg-5 pr-lg-5">

    <app-wizard [itemSelected]="5"></app-wizard>

    <div class="mt-3 row">
        <div class="col-12 col-md-6">
            <div class="row pb-2">

                <div class="col-12 mb-4">
                    <div class="container__conteudo shadow-none">
                        <h5 class="font-title ">Canal de notificação</h5>

                        <div class="d-flex justify-content-around">
                            <label class="container__checkbox container__checkbox__abled">E-mail
                                <input type="checkbox" checked="checked" value="email">
                                <span class="checkmark"></span>
                            </label>
                            <label [routerLink]="['/comunicacao-interna']"
                                class="container__checkbox container__checkbox__abled">Notificação
                                <input type="checkbox__disabled">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="container__conteudo__gray d-flex">

                        <div>
                            <img class="mr-4" src="assets/icons/alert.svg" alt="alerta">
                        </div>

                        <div class="d-flex flex-column">

                            <h5 class="font-title">
                                Notifique seus colaboradores
                            </h5>

                            <p class="container__conteudo__informacao__texto pb-0">Acionando a notificação de
                                colaboradores, todas as pessoas que estiverem inseridas neste grupo receberão
                                notificações por e-mail, explicando como funciona a plataforma e o motivo de estarem
                                participando desta jornada de aprendizado. Esta notificação contém o passo a passo de
                                como o colaborador deve baixar o aplicativo, como deve se cadastrar para acessar a
                                plataforma, como deve consumir os conteúdos corretamente e como será avaliado.</p>

                            <div class="d-flex flex-wrap">
                                <form class="d-flex flex-column pr-4">
                                    <div class="form-group">
                                        <h5 class="font-title mt-2">Grupo</h5>
                                        <select class="form-control" name="grupo" (ngModelChange)="resetValues()"
                                            [(ngModel)]="id_grupo">
                                            <option value="null">Selecione seu grupo</option>
                                            <option *ngFor="let i of grupos" [value]="i.id">{{i.nome}}</option>
                                        </select>
                                    </div>
                                </form>

                                <div style="flex: 1;">
                                    <h5 class="font-title mt-2">Quem notificar?</h5>

                                    <div class="d-flex flex-column">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div style="flex: 3;">
                                                <label [class.unclickable]="!(id_grupo > 0)"
                                                    class="container__checkbox container__checkbox__abled">Administradores
                                                    <input type="checkbox" (change)="getAdministradores()"
                                                        [(ngModel)]="envio.contatos">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="content__send" style="flex: 1;">
                                                <img (click)="getAdministradores()" [class.d-none]="!envio.contatos"
                                                    src="assets/icons/editar.svg" alt="Selecionar" title="Selecionar">
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div style="flex: 3;">
                                                <label [class.unclickable]="!(id_grupo > 0)"
                                                    class="container__checkbox container__checkbox__abled">Tutores
                                                    <input type="checkbox" (change)="getLideres()"
                                                        [(ngModel)]="envio.tutores">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="content__send" style="flex: 1;">
                                                <img (click)="getLideres()" [class.d-none]="!envio.tutores"
                                                    src="assets/icons/editar.svg" alt="Selecionar" title="Selecionar">
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div style="flex: 3;">
                                                <label [class.unclickable]="!(id_grupo > 0)"
                                                    class="container__checkbox container__checkbox__abled">Colaboradores
                                                    <input type="checkbox" (change)="getColaboradores()"
                                                        [(ngModel)]="envio.colaboradores">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="content__send" style="flex: 1;">
                                                <img (click)="getColaboradores()" [class.d-none]="!envio.colaboradores"
                                                    src="assets/icons/editar.svg" alt="Selecionar" title="Selecionar">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>


        <div class="col-12 col-md-6">
            <div class="container__conteudo col-12">
                <div class="d-flex justify-content-between w-100 mb-3 flex-wrap flex-column-reverse flex-md-row">
                    <h4 class="font-title">Material de apoio</h4>
                    <button (click)="salvar()" type="button" class="btn__primary">ENVIAR NOTIFICAÇÃO</button>
                </div>

                <div class="conteudo__material__video">

                    <div class="div__iframe">
                        <video *ngIf="videoLink" poster="https://img.youtube.com/vi/7DoIPCXGcEU/0.jpg"
                            class="conteudo__material__video__iframe" height="100%" controls controlsList="nodownload">
                            <source [src]="getVideo()" type="video/mp4">
                        </video>
                    </div>

                    <h5 class="font-title  mt-5">Incentive suas equipes</h5>

                    <div class="d-flex flex-column" *ngFor="let material of materiais">
                        <div (click)="download(material)"
                            class="d-flex align-items-center py-2 conteudo__material__download">
                            <img class="conteudo__material__download__icon" src="assets/icons/document_yellow.svg"
                                alt="documento">
                            <label class="conteudo__material__download__texto">{{material.titulo}}</label>
                            <img class="conteudo__material__download__acao" src="assets/icons/download.svg"
                                alt="download">
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>