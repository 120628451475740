import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { ExperienciasProfissionaisService } from 'src/app/services/experienciasProfissionais';

export interface IExperiencia {
  id: number,
  palestrantes_id: number,
  cargo: string,
  empresa: string,
  cidade: string,
  estado: string,
  pais: string,
  atual: boolean,
  data_inicio: Date,
  data_fim: Date,
  descricao: string
}
@Component({
  selector: 'app-experiencia',
  templateUrl: './experiencia.component.html',
  styleUrls: ['./experiencia.component.scss']
})
export class ExperienciaComponent implements OnInit {
  @Output() onClose = new EventEmitter()

  bsModalRef: BsModalRef;

  idSelected: number = undefined
  loading: boolean = false
  experiencias: Array<IExperiencia> = []

  constructor(
    private experienciasProfissionaisService: ExperienciasProfissionaisService,
    private toast: ToastrService,
    private modalService: BsModalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getExperiencias()
  }

  async getExperiencias() {
    try {
      this.loading = true
      const res = await this.experienciasProfissionaisService.getList()
      this.experiencias = res
      this.loading = false
    } catch (error) {
      this.toast.error("Não foi possível carregas seus produtos")
      this.loading = false
    }
  }

  insert() {
    if (!(this.experiencias.length >= 3)){
      this.idSelected = 0
    }else{
      this.toast.error("O limite de cadastros foi excedido. Exclua um dos cadastros para inserir um novo.")
    }
  }

  update(id: number) {
    this.idSelected = id
  }

  delete(id: number) {
    const initialState = { mensagem: "Deseja realmente excluir?", submensagem: "" }
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value
      value == true ? true : false;
      if (value == true) this.deleteRequest(id);
    })
  }

  async deleteRequest(id: number) {
    try {
      this.loading = true
      const res = await this.experienciasProfissionaisService.delete(id)
      this.getExperiencias()
      this.toast.success("Excluído com sucesso!")

      this.loading = false
    } catch (error) {
      this.toast.error("Não foi possível exluir")
      this.loading = false
    }
  }

  formFinished() {
    this.idSelected = undefined
    this.getExperiencias()
  }

  close(){
    this.onClose.emit('')
  }
}
