<section *ngIf="!showCheckout" class="container">

    <header>
        <h4 class="font-title">Seu Plano</h4>
        <p class="empty mb-2" *ngIf="alertPrincipalProfile">{{alertPrincipalProfile}}</p>
    </header>

    <article *ngIf="plan" class="row">

        <div class="col-12 col-md-6">
            <div class="card__plano">
                <div class="content__description">
                    <img src="assets/icons/money-mensal.svg" alt="mensal">
                    <label class="description">Plano {{plan.plano_nome}}</label>
                </div>

                <div class="d-flex">
                    <label class="valor__plano">Valor de <span class="yellow">{{formatMoney()}}</span>/por
                        mês</label>
                </div>

                <div>
                    <p [innerHTML]="plan.plano_descricao"></p>
                </div>
            </div>
        </div>

        <div *ngIf="hasPermissionActions" class="col-12 col-md-6">
            <div class="card__plano">

                <div class="content__description">
                    <img src="assets/icons/calendar-yellow.svg" alt="renovação">
                    <label class="description">RENOVAR EM</label>
                </div>
                <div class="d-flex">
                    <label class="valor__plano">{{plan.plano_valido_ate}}</label>
                </div>
                <a (click)="renewPlan()">
                    <p>Renovar plano</p>
                </a>

                <div style="margin: 2rem 0 1.5rem 0;" class="content__description">
                    <img src="assets/icons/config-yellow.svg" alt="GERENCIAMENTO">
                    <label class="description">GERENCIAMENTO</label>
                </div>
                <a (click)="updatePlan()">
                    <p>Alterar seu plano</p>
                </a>
                <a (click)="cancelPlan()">
                    <p>Cancelar plano</p>
                </a>
            </div>
        </div>

    </article>

</section>

<section *ngIf="showCheckout">
    <app-checkout-planos [idPlan]="idPlan" (finished)="finished($event)"></app-checkout-planos>
</section>