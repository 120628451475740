import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProfilePictureUpdateModalComponent } from './profile-picture-update-modal/profile-picture-update-modal.component';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
})
export class ProfilePictureComponent implements OnInit {
  bsModalRef: BsModalRef;

  @Input() foto = null;
  @Output("refresh") refresh = new EventEmitter();

  constructor(private modalService: BsModalService,) { }

  ngOnInit(): void {}

  alterarFoto() {
    const initialState  = {};
    const config        = { initialState }
    this.bsModalRef     = this.modalService.show(ProfilePictureUpdateModalComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      // if (this.bsModalRef.content.success) this.getUsuario()
      if (this.bsModalRef.content.success) this.refresh.emit()
    })
  }
}
