import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin/admin.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-resultados-pesquisas',
  templateUrl: './resultados-pesquisas.component.html',
  styleUrls: ['./resultados-pesquisas.component.scss']
})
export class ResultadosPesquisasComponent implements OnInit {

  pesquisas: any = [];

  loading: boolean = false;

  constructor(
    private adminService: AdminService,
  ) {}

  ngOnInit(): void {
    this.getPesquisas();
  }

  async getPesquisas() {
    this.loading = true;
    this.adminService.getResultadosPesquisas().subscribe(
      (result: any) => {
        console.log(result);

        this.pesquisas = result.body;
        this.loading = false;
      }
    );
  }

  parseDate(date: string) {
    return new Date(date).toLocaleDateString('pt-br');
  }

  parseTime(date: string) {
    return new Date(date).toLocaleTimeString('pt-br')
  }

  downloadCSV(): void {
    new ngxCsv(this.pesquisas, 'Resultados de pesquisas');
  }
}
