<div>
    <form class="d-flex flex-column" (submit)="update()">
        <div class="form-group">
            <label class="label-input mt-2" for="senha_atual">Senha atual</label>
            <input class="form-control" name="senha_atual" type="password" placeholder="Senha atual *"
                [(ngModel)]="senha_atual">
        </div>

        <div class="form-group">
            <label class="label-input" for="senha_nova">Nova Senha</label>
            <input class="form-control" maxlength="20" name="senha_nova" type="password" placeholder="Nova senha *"
                [(ngModel)]="senha_nova" (ngModelChange)="checkPasswordRule()">
            <app-check-validacao-senha #validacaoPass [senha]="senha_nova"></app-check-validacao-senha>
        </div>

        <div class="form-group">
            <label class="label-input" for="senha_nova_confirma">Repetir nova senha</label>
            <input class="form-control" maxlength="20" name="senha_nova_confirma" type="password"
                placeholder="Repetir nova senha *" [(ngModel)]="senha_nova_confirma">
        </div>

        <button *ngIf="!loading" type="submit" class="btn btn-primary btn-warning mt-2">Salvar</button>
        <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
            <img height="40px" src="../../../assets/img/simple_loading.gif" alt="loading">
        </button>
    </form>
</div>