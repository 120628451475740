import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { Title } from '@angular/platform-browser';
import { getPathHome } from 'src/app/controle-perfil';
import { RdStationService } from 'src/app/services/rd-station/rd-station.service';
import { environment } from 'src/environments/environment';
import { EspectadoresService } from 'src/app/services/espectadores';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  dataUser: { usuario; token } = null;
  auth: { email; password; admUser } = null;
  step: number = 1;
  profile: string;

  constructor(
    private rdStationService: RdStationService,
    private epectadoresService: EspectadoresService,
    private app: AppComponent,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Login - Profissionais SA');
  }

  async loginProfileEspectador() {
    try {
      const result = await this.epectadoresService.authLogin(
        this.auth.email,
        this.auth.password
      );
      localStorage.setItem('espectador', JSON.stringify(result.usuario));
    } catch (error) {
      console.error(error);
    }
  }

  async authProfile(res: { data; auth }) {
    const { data, auth } = res;
    this.profile = data.usuario.perfil.toUpperCase();

    this.dataUser = data;
    this.auth = auth;

    if (this.profile == 'ESPECTADOR') {
      await this.loginProfileEspectador();
      this.entry();
    }

    // console.log({ data, auth });
    // console.log('profile', this.profile);
    // return;

    this.setProfile(this.profile);
  }

  async setProfile(profile: string) {
    if (profile == 'ESPECTADOR') {
      await this.loginProfileEspectador();
    }
    if (profile == 'CONTRATANTE') {
      localStorage.setItem('admUser', JSON.stringify(this.auth.admUser));
    }

    if (profile == 'ADMIN') {
      localStorage.setItem('admUser', JSON.stringify(this.auth.admUser));
    }

    if(profile == 'GESTOR') {
      localStorage.setItem('admUser', JSON.stringify(this.auth.admUser));
    }

    this.entry();
  }

  entry() {
    localStorage.setItem('token', this.dataUser.token);
    console.log(this.dataUser.usuario)
    localStorage.setItem('usuario', JSON.stringify(this.dataUser.usuario));
    localStorage.setItem('perfil', this.profile);
    this.app.isLogado();

    this.router.navigate([getPathHome()]);
  }
}
