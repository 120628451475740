import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IConcludeTask {
  taskId: number
  userId: number,
  perguntaId: number,
  respostaId: number,
  resposta: string,
  peso: number,
  feedbackTutor: string
}

export interface ISkill {
  arquivo_principal: "VIDEO" | "PDF"
  avaliacao: string
  competencia_nome: string
  descricao_longa: string
  espectador_id_usuario: number
  habilidade_complemento: string
  habilidade_imagem_link: string
  habilidade_status: string
  habilidade_tarefa: string
  habilidade_titulo: string
  habilidade_video_link: string
  habilidade_pdf_link: string
  habilidade_arquivo_principal: null | 'PDF' | 'VIDEO'
  id: number
  id_competencia: number
  id_espectador: number
  id_grupo_equipe: number
  id_habilidade: number
  nota: number
  opacity: number
  ordem: number
  pdf_link: string
  perguntas: any
  released: boolean
  resposta: any
  resposta1: number
  resposta2: number
  resposta3: number
  search_terms: string
  tutor_nome: string
}

export interface IResSkills {
  numeroProximaTarefa: number
  ordem: Array<number>
  proximaTarefa: ISkill
  tarefasFinalizadasAvaliadas: Array<ISkill>
  tarefasIniciadas: Array<ISkill>
  tarefasPendentes: Array<ISkill>
  tarefasRespondidas: Array<ISkill>
}

export interface IAnswer {
  perguntaId: number
  resposta: any
}

@Injectable({
  providedIn: 'root'
})
export class TarefasService {

  constructor(public http: HttpClient) { }


  async getSkills(): Promise<IResSkills> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/profile/tarefas`, { headers: headers }).toPromise();
    return this.applyRuleSkills(result.body);
  }


  applyRuleSkills = (tarefas: any) => {
    const ordem = tarefas
      .filter(
        (t) =>
          t.habilidade_status != 'RESPONDIDA' &&
          t.habilidade_status != 'FINALIZADA' &&
          t.habilidade_status != 'AVALIADA'
      )
      .map((d) => d.ordem);
    const numeroProximaTarefa = Math.min(...ordem);
    const proximaTarefa = tarefas.find((item: any) => item.habilidade_status == 'INICIADA') || tarefas.find((t) => t.ordem == numeroProximaTarefa);
    const tarefasPendentes = tarefas.filter(
      (item) => item.habilidade_status == 'PENDENTE'
    );
    const tarefasFinalizadasAvaliadas = tarefas.filter(
      (item) =>
        item.habilidade_status == 'FINALIZADA' ||
        item.habilidade_status == 'AVALIADA'
    );
    const tarefasIniciadas = tarefas.filter(
      (item) =>
        item.habilidade_status == 'INICIADA' ||
        item.habilidade_status == 'REFAZER'
    );
    const tarefasRespondidas = tarefas.filter(
      (item) => item.habilidade_status == 'RESPONDIDA'
    );
    try {
      let startAvaiable = 3 - tarefasIniciadas?.length;
      let index = 0
      while (startAvaiable > 0) {
        if (tarefasPendentes[index]) tarefasPendentes[index].opacity = 1;
        startAvaiable--
        index++
      }
    } catch (error) {}

    return {
      ordem,
      numeroProximaTarefa,
      proximaTarefa,
      tarefasPendentes,
      tarefasFinalizadasAvaliadas,
      tarefasIniciadas,
      tarefasRespondidas
    };
  };

  async getTaskById(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/grupos/tarefa/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getSkillById(idSkill: number): Promise<ISkill> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/conteudos/habilidade/${idSkill}`, { headers: headers }).toPromise();
    return result.body;
  }

  async concludeTask(idSkill: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.put<any>(`${environment.API_V2_URL}app-espectador/conteudos/tarefa/concluir/${idSkill}`, {}, { headers: headers }).toPromise();
    return result.body;
  }

  async saveAnswerTask(form: IAnswer): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = form
    const result = await this.http.post<any>(`${environment.API_V2_URL}dashboard/gameficacao/perguntas/responder`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async updateStatus(idHabilidade, status: "PENDENTE" | "INICIADA" | "RESPONDIDA" | "AVALIADA" | "RESPONDIDA" | "FINALIZADA" | "REFAZER"): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = { status };
    const result = await this.http.post<any>(`${environment.API_V2_URL}app-espectador/conteudos/status/${idHabilidade}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getQuestionsSkillById(idSkill: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}gameficacao/perguntas/list-habilidade/${idSkill}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getPDF(url) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { headers: headers, responseType: 'arraybuffer' }).toPromise();
  }
}
