<div class="container">
  <div class="card">
    <h4>PESQUISAR</h4>
    <p>Quero pesquisar um tema, palestrante, comparar palestrantes ou gerar uma defesa</p>
    <button class="buttons" (click)="goTo(paths.pesquisar)">COMEÇAR</button>
  </div>
  <div class="card">
    <h4>GERAR PROPOSTA</h4>
    <p>Quero criar uma proposta de curadoria matadora! #VemMeta</p>
    <button class="buttons" (click)="goToGerador()">COMEÇAR</button>
  </div>
  <div class="card">
    <h4>PROPOSTAS GERADAS</h4>
    <p>Quero acessar uma proposta FODA que eu já montei!</p>
    <button class="buttons" (click)="goTo(paths.propostas)">COMEÇAR</button>
  </div>
  <div class="card">
    <h4>RESULTADOS</h4><br>
    <p>Quero saber como estão os leads, qualificações e a meta!</p>
    <p></p>
    <p></p>
    <button class="buttons" (click)="goTo(paths.resultados)">COMEÇAR</button>
  </div>
</div>
