<section class="container-fluid px-1 px-md-5">

    <header class="d-flex align-items-center justify-content-between mb-1">
        <div style="flex: 1;">
            <button (click)="openFilter()" type="button"
                class="btn btn-warning btn-back-circle-middle float-left flexFull mr-3">
                <img height="21" src="assets/icons/filter.svg" alt="filtros" title="Filtros">
            </button>
            <h1 class="font-title mb-2">Comunicação interna</h1>
        </div>
        <button [routerLink]="['/comunicacao-interna/historico']" class="btn__primary">histórico</button>
    </header>

    <div class="row">

        <!-- FILTRO -->
        <div class="section__filter pl-0" [ngClass]="{'col-12 col-lg-3': isOpenFilters, 'd-none': !isOpenFilters}">

            <div class="div__filter">
                <form (submit)="applyFilter()">
                    <h6 class="d-inline filter__title text-uppercase m-0">
                        <img class="mr-2" height="16" src="assets/icons/filter.svg" alt="filtros" title="Filtros">
                        Filtros
                    </h6>
                    <span class="float-right clear__filter" (click)="resetFilter()">
                        <img src="assets/icons/excluir.svg" alt="limpar filtro"> Limpar filtro
                    </span>


                    <div class="pt-2">
                        <label class="filter__label" for="id_group">Grupos</label>

                        <ng-select [searchable]="true" [clearable]="true" bindLabel="id_group" placeholder="Selecione"
                            appendTo="body" notFoundText="Não encontrado" [(ngModel)]="filter.id_group"
                            [ngModelOptions]="{standalone: true}">
                            <ng-option [value]="g.id" *ngFor="let g of groups">
                                {{g.nome}}
                            </ng-option>
                        </ng-select>
                    </div>

                    <div class="pt-3">
                        <label class="container__checkbox">
                            Somente colaboradores
                            <input type="checkbox" (change)="setFilterOnlyLeader()" [(ngModel)]="filter.onlyContributors" [ngModelOptions]="{standalone: true}">
                            <span class="checkmark bg-white"></span>
                        </label>
                    </div>

                    <div class="pt-2">
                        <label class="container__checkbox">
                            Somente tutores
                            <input type="checkbox" (change)="setFilterOnlyContributors()" [(ngModel)]="filter.onlyLeader" [ngModelOptions]="{standalone: true}">
                            <span class="checkmark bg-white"></span>
                        </label>
                    </div>

                    <button type="submit" class="btn__primary mt-3">Aplicar filtros</button>
                </form>
            </div>
        </div>
        <!-- FILTRO -->


        <div [ngClass]="{'col-lg-9': isOpenFilters}" class="col-12 container__comunicacao">
            <label class="container__checkbox mb-0">
                Selecionar todos
                <input type="checkbox" (change)="loadAllIds($event)">
                <span class="checkmark"></span>
            </label>
            <ul class="list-group">
                <li class="list-group-item list__item">
                    <div style="width: 25px;"></div>
                    <div class="flex2">
                        <span class="flex1 font-title">Nome</span>
                    </div>
                    <div class="flex3">
                        <span class="font-title">E-mail</span>
                    </div>
                    <div class="flex1">
                        <span class="font-title">Cargo</span>
                    </div>
                </li>
                <div *ngIf="loading" class="d-flex justify-content-center">
                    <app-spinner></app-spinner>
                </div>

                <div *ngIf="!loading && users.length == 0">
                    <p class="empty">Nenhum usuário encontrado</p>
                </div>

                <li class="list-group-item list__item" *ngFor="let i of users">
                    <div>
                        <label class="container__checkbox">
                            <input type="checkbox" (change)="add(i)"
                                [(ngModel)]="i.added">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <span class="list__item__text flex2">{{i.nome_completo}}</span>
                    <span class="list__item__text flex3">{{i.email}}</span>
                    <span class="list__item__text flex1">{{i.cargo}}</span>
                </li>
            </ul>
        </div>

        <div class="col-12 d-flex align-items-center justify-content-between flex-row-reverse mt-2">

            <button (click)="nextStep()" class="btn__primary">Avançar</button>

            <nav *ngIf="qtdePages?.length > 1" aria-label="Páginas">
                <ul class="pagination justify-content-center">
                    <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                        <a class="page-link"> <i class='fa fa-chevron-left'></i></a>
                    </li>
                    <li (click)="changePage(i)" class="page-item" [class.font-weight-bold]="page == i"
                        *ngFor="let number of qtdePages, let i = index">
                        <a class="page-link">{{i+1}}</a>
                    </li>
                    <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                        <a class="page-link"> <i class='fa fa-chevron-right'></i></a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>
</section>