<div class="content__default p-4 pm-md-5">
  <form class="row">
    <div class="col-12 d-flex justify-content-between">
      <h5 class="font-title text-uppercase">{{id > 0 ? 'Editar experiência' : 'Adicionar experiência'}}</h5>
      <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close" title="Cancelar">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="form-group col-12 col-md-6">
      <label class="label-input" for="cargo">cargo *</label>
      <input id="cargo" [class.invalid]="isIncorrect('cargo')" class="form-control" name="cargo" type="text"
        [(ngModel)]="experiencia.cargo" placeholder="Cargo *" maxlength=100>
      <!-- [ngModelOptions]="{standalone: true}" -->
      <span class="error">{{getError('cargo')}}</span>
    </div>

    <div class="form-group col-12 col-md-6">
      <label class="label-input" for="empresa">empresa *</label>
      <input id="empresa" [class.invalid]="isIncorrect('empresa')" class="form-control" name="empresa" type="text"
        [(ngModel)]="experiencia.empresa" placeholder="Empresa *" maxlength=150>
      <span class="error">{{getError('empresa')}}</span>
    </div>

    <div class="form-group col-12 col-md-4">
      <label class="label-input" for="pais">país *</label>
      <input id="pais" [class.invalid]="isIncorrect('pais')" class="form-control" name="pais" type="text"
        [(ngModel)]="experiencia.pais" placeholder="País *" maxlength=60>
      <span class="error">{{getError('pais')}}</span>
    </div>

    <div class="form-group col-12 col-md-4">
      <label class="label-input" for="estado">estado *</label>
      <select id="estado" [class.invalid]="isIncorrect('estado')" class="form-control" name="estado"
        placeholder="Estado *" [(ngModel)]="experiencia.estado">
        <option value="">Selecione *</option>
        <option *ngFor="let estado of arrayEstados" [value]="estado.uf">{{estado.estado}}
        </option>
      </select>
      <span class="error">{{getError('estado')}}</span>
    </div>

    <div class="form-group col-12 col-md-4">
      <label class="label-input" for="cidade">cidade *</label>
      <input id="cidade" [class.invalid]="isIncorrect('cidade')" class="form-control" name="cidade" type="text"
        [(ngModel)]="experiencia.cidade" placeholder="Cidade *" maxlength=80>
      <span class="error">{{getError('cidade')}}</span>
    </div>

    <div class="form-group col-12 col-md-4">
      <label class="label-input" for="data_inicio">Início *</label>
      <input id="data_inicio" [class.invalid]="isIncorrect('data_inicio')" class="form-control" name="data_inicio"
        type="date" [(ngModel)]="experiencia.data_inicio" placeholder="Data de início *">
      <span class="error">{{getError('data_inicio')}}</span>
    </div>

    <div [class.d-none]="experiencia.atual" class="form-group col-12 col-md-4">
      <label class="label-input" for="data_fim">Fim *</label>
      <input id="data_fim" [class.invalid]="isIncorrect('data_fim')" class="form-control" name="data_fim" type="date"
        [(ngModel)]="experiencia.data_fim" placeholder="Data final *">
      <span class="error">{{getError('data_fim')}}</span>
    </div>

    <div class="form-group col-12 col-md-4 d-flex align-items-end">
      <div>
        <label class="container__checkbox">
          Atual
          <input type="checkbox" name="atual" [(ngModel)]="experiencia.atual">
          <span class="checkmark"></span>
        </label>
      </div>
    </div>

    <div class="form-group col-12">
      <label class="label-input" for="descricao">Descrição *</label>
      <textarea rows="4" id="descricao" [class.invalid]="isIncorrect('descricao')" class="form-control" name="descricao"
        type="date" [(ngModel)]="experiencia.descricao" placeholder="Descrição *"></textarea>
      <span class="error">{{getError('descricao')}}</span>
    </div>

    <div class="col-12">
      <div class="d-flex pt-2 pb-2 div__btn__salvar">
        <button
          (click)="cancel()"
          type="button"
          class="btn btn-primary btn-secondary mr-3"
          title="Cancelar">
          CANCELAR
        </button>

        <button *ngIf="!loading" (click)="submit()" class="btn btn-primary btn-warning">Salvar</button>

        <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
          <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
        </button>
      </div>
    </div>

  </form>
</div>
