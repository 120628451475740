<div *ngIf="loading" class="d-flex justify-content-center">
    <app-spinner></app-spinner>
</div>

<section *ngIf="!loading">
    <article>
        <figure>
            <img [src]="skill.habilidade_imagem_link" [alt]="skill.habilidade_titulo">
        </figure>
        <div class="content__info">
            <h3 class="font-title">{{skill.habilidade_titulo}}</h3>

            <p class="description" [innerHtml]="skill.habilidade_complemento"></p>
            <div class="d-flex justify-content-center">
                <button (click)="start()" class="btn__primary">
                    <img height="13px" src="assets/icons/play_black.svg" alt="Assistir">
                    Assistir
                </button>
            </div>
        </div>
    </article>
</section>