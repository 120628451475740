import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { QRCodeSummary } from 'src/app/interfaces/certificates';
import { FormCertificatesService } from 'src/app/services/FormCertificates/form-certificates.service';

@Component({
  selector: 'app-use-summary',
  templateUrl: './use-summary.component.html',
  styleUrls: ['./use-summary.component.scss', '../certificates-answers.component.scss']
})
export class UseSummaryComponent implements OnInit{
  loading: boolean = false;

  filterCS: string = '';
  filterSpeaker: string = '';
  filterStart: string = '';
  filterEnd: string = '';

  showInputCS: boolean = this.filterSpeaker.length === 0;
  showInputSpeaker: boolean = this.filterCS.length === 0;

  events: Array<string>;
  summary: QRCodeSummary;

  constructor(
    private formCertificatesService: FormCertificatesService,
    private toast: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  filter(): void {
    if(this.filterCS.length == 0 && this.filterSpeaker.length == 0) {
      this.toast.error('Selecionar um CS ou um Palestrante é obrigatório');
      return;
    } else {
      this.getCSSummary();
    }
  }

  getCSSummary() {
    this.loading = true;
    console.log()
    this.formCertificatesService.getQrCodeSummaryByCS(this.filterCS, this.filterStart, this.filterEnd, this.filterSpeaker).subscribe(
      (result: any) => {
        this.summary = result.body;
        this.summary.percentageAvg = Math.round(this.summary.percentageAvg * 100) / 100;
        this.summary.numericalAvg = Math.round(this.summary.numericalAvg * 1000) / 1000;
        this.loading = false;
      },
      (data: any) => {
        this.loading = false;
        console.log(data.error.message);
        this.toast.error('Não foi possível recuperar os dados do resumo');
      }
    )
  }

  getSpeakerSummary() {

  }
}
