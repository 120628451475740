<main class="container editor__container">
  <angular-editor
    [(ngModel)]="htmlContent"
    [config]="config"
  ></angular-editor>

  <!-- <div class="actions__container mt-2">
    <p class="mx-2" (click)="getAnswer()">Escrever Mais Títulos</p>
    <p>Gerar Tudo Novamente</p>
  </div> -->
</main>
