import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IContato } from 'src/app/pages/configuracoes/contratante/tb-contatos';
import { UsuarioContatosService } from 'src/app/services/usuario-contatos';

@Component({
  selector: 'app-notificacao-modal-contatos',
  templateUrl: './notificacao-modal-contatos.component.html',
  styleUrls: ['./notificacao-modal-contatos.component.scss']
})
export class NotificacaoModalContatosComponent implements OnInit {

  id_grupo: number;
  contatos: Array<IContato>;

  loading: boolean = false;
  list: Array<number> = []

  sendAll: boolean = false

  constructor(
    public bsModalRef: BsModalRef,
    private usuarioContatosService: UsuarioContatosService,
    private toast: ToastrService,) { }

  ngOnInit(): void {
    this.load();
  }

  close() {
    this.bsModalRef.hide();
  }

  async load() {
    try {

      const result = await this.usuarioContatosService.getUsuarioContatos()
      this.contatos = result.message ? [] : result;
      this.contatos.map((i: any) => i.added = this.valueAdded(i.id_colaborador))

    } catch (error) {
      console.log('error', error);
      this.toast.error("Não foi possível buscar os administradores")
    }
  }

  add(id: number) {
    if (!this.list.includes(id)) this.list.push(id)
  }

  async save() {
    try {
      this.loading = true
      this.bsModalRef.hide();
      this.loading = false
      this.close()
    } catch (error) {
      this.loading = false
    }
  }


  valueAdded(id_colaborador: number): boolean {
    let isInclude = this.list.find(i => i == id_colaborador)
    return isInclude ? true : false
  }
}
