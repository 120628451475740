import { Component, OnInit } from '@angular/core';
import { ContratanteService } from 'src/app/services/contratante';
import { IOrder, arrayOrders } from 'src/app/common/array-filtro-colaboradores';
import { ToastrService } from 'ngx-toastr';
import { IColaborador } from '../colaboradores'
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormColaboradorComponent } from '../modal-form-colaborador';
import { Router } from '@angular/router';

interface IUpdateCollection {
  id_colaborador: number
  id_espectador: number | null
  tutor: string | "SIM" | "NAO"
}
interface IColaboradorItem extends IColaborador {
  responsavel_grupo_bool: boolean
  has_liderado: boolean
}

@Component({
  selector: 'app-seleciona-lideres',
  templateUrl: './seleciona-lideres.component.html',
  styleUrls: ['./seleciona-lideres.component.scss']
})
export class SelecionaLideresComponent implements OnInit {
  bsModalRef: BsModalRef;

  colaboradores: Array<IColaboradorItem>;

  colaboradoresEditar: Array<IUpdateCollection> = [];;

  loading: boolean = false;
  qtdePages: Array<number> = [];
  page: number = 0;
  search: string = "";
  orders: Array<IOrder> = arrayOrders;
  order: string = this.orders[1].value;

  constructor(
    private contratanteService: ContratanteService,
    private toast: ToastrService,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.loadColaboradores();
  }


  async loadColaboradores() {
    try {
      const result = await this.contratanteService.getColaboradoresByOrder(this.page, this.order, this.search, null);
      result.colaboradores.map((i: IColaboradorItem) => i.responsavel_grupo_bool = this.valueTutor(i.id_colaborador, i.responsavel_grupo))

      this.colaboradores = result.colaboradores ? result.colaboradores : [];
      
      this.setQtdePages(result.quantidade)
    } catch (error) {
      console.log('error', error);
      this.toast.error("Não foi possível buscar os colaboradores")
    }
  }

  async selecionaLideres() {
    try {
      await this.contratanteService.updateTutorCollection({ colaboradores: this.colaboradoresEditar })
      this.toast.success("Atualizado com sucesso")
      // this.router.navigate(['/universidade/grupos'])
      this.goGrupos()
    } catch (error) {
      this.toast.error("Não foi possível definir os tutores")
    }
  }

  changeTutor(colaborador: IColaboradorItem) {
    let aux = {
      id_colaborador: colaborador.id_colaborador,
      id_espectador: colaborador.id_espectador,
      tutor: colaborador.responsavel_grupo_bool == true ? "SIM" : "NAO"
    }
    var isInclude = this.colaboradoresEditar.find(i => i.id_colaborador == colaborador.id_colaborador)

    if (isInclude) {
      let index = this.colaboradoresEditar.indexOf(isInclude)
      this.colaboradoresEditar.splice(index, 1)
      return
    }
    this.colaboradoresEditar.push(aux)
  }

  valueTutor(id_colaborador: number, tutor: string): boolean {
    let isInclude = this.colaboradoresEditar.find(i => i.id_colaborador == id_colaborador)
    return isInclude ? isInclude.tutor == "SIM" : tutor == "SIM"
  }

  editar(colaborador: IColaboradorItem) {
    const initialState = { colaborador: colaborador };
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalFormColaboradorComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.success) this.loadColaboradores()
    })
  }

  async excluir(id_colaborador: number) {
    try {
      this.loading = true
      
      await this.contratanteService.deleteColaborador(id_colaborador);
      this.loadColaboradores()
      this.loading = false
    } catch (error) {
      this.loading = false
      this.toast.error("Não foi possível deletar colaborador!")
    }
  }

  changePage(number: number) {
    this.page = number;
    this.loadColaboradores()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array.from(Array(qtdeColaboradores).keys())
  }

  getPages() {
    let listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2))    
    return listPage
  }

  buscar() {
    this.page = 0
    this.loadColaboradores()
  }

  changeOrder() {
    this.page = 0
    this.loadColaboradores()
  }

  
  async goGrupos() {
    try {
      const res = await this.contratanteService.hasGrupo()

      this.router.navigate([res.hasGrupo == true ? '/universidade/grupos' : '/universidade/0/lideres'])
    } catch (error) {
      this.router.navigate(['/universidade/grupos'])
    }
  }

}
