import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PalestrasService } from 'src/app/services/palestras';

@Component({
  selector: 'app-modal-alterar-midia',
  templateUrl: './modal-alterar-midia.component.html',
  styleUrls: ['./modal-alterar-midia.component.scss']
})
export class ModalAlterarMidiaComponent implements OnInit {
  loading: boolean = false;
  success: boolean = false;
  password: string = '';
  pass: string = "zcP@!lwI";
  public palestraId;

  formGroup = this.fb.group({
    file: [null, Validators.required]
  });

  constructor(
    public bsModalRef: BsModalRef,
    private toast: ToastrService,
    private palestraService: PalestrasService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.formGroup.controls.file.setValue(null)
  }

  close() {
    this.bsModalRef.hide();
  }

  async changeMidia() {
    try {
      this.loading = true;
      console.log(this.palestraId)
      if(this.compareCode()){
        this.palestraService.setMidia({ img_data64: this.formGroup.controls.file.value, palestraId: this.palestraId }).then(() => {
          this.loading = false;
          this.success = true;
          this.toast.success("Midia alterada com sucesso!")
          this.close()
        })
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log(error);
      this.toast.error("Não foi possível alterar a mídia!")
    }
  }

  compareCode() {
    if (this.password == this.pass) {
      this.bsModalRef.hide();
      return true;
    } else {
      this.toast.error("Senha incorreta! Não foi possível alterar a mídia!")
      return false;
    }
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.formGroup.patchValue({ file: reader.result });
        this.cd.markForCheck();
      };
      return
    }
  }
}
