<div class="container-fluid px-5">
  <!-- <app-wizard [itemSelected]="3"></app-wizard> -->

  <div class="container__conteudo__default row mt-4">
    <!-- <div class="sectionActions">
            <div class="closeButton" (click)="close()">
              <img src="../../../../../assets/icons/close.svg" />
            </div>
          </div> -->

    <div
      *ngIf="loading"
      class="col-12 d-flex justify-content-center"
      style="height: 100%"
    >
      <!-- <app-spinner></app-spinner> -->
    </div>

    <div
      [class.d-none]="idSelected >= 0"
      class="col-12 container__conteudo__default"
    >
      <div class="d-flex justify-content-end mb-2">
        <button (click)="insert()" class="btn__add" title="Adicionar produto">
          Adicionar
        </button>
      </div>

      <div *ngIf="palestras.length == 0">Nenhum produto</div>

      <div
        *ngFor="let item of palestras"
        class="mb-2 container__list"
        [style.backgroundImage]="'url(' + item.photo + ')'"
        [style.backgroundRepeat]="'no-repeat'"
        [style.backgroundPosition]="'0% 50%'"
        [style.backgroundSize]="'25%, contain'"
      >
        <div class="row ml-0 palestra bgd__foto">
          <div
            [class.padd30]="item.photo && item.photo.length > 0"
            class="container__info col-12 col-md-9"
          >
            <h5 class="palestra__title">{{ item.nome }}</h5>

            <label [class.d-none]="!item.subtitulo" class="subtitle">{{
              item.subtitulo
            }}</label>

            <div class="d-flex mb-1">
              <span class="title__info">Tema: </span>
              <span class="txt__info">{{ item.assunto }}</span>
            </div>

            <p class="description">{{ item.descricao }}</p>
          </div>

          <div class="content__action col-12 col-md-3">
            <div class="d-flex flex-column">
              <button (click)="update(item.id)" class="btn">
                <img src="assets/icons/editar.svg" alt="editar" />
                Editar
              </button>
              <button
                *ngIf="item.video && item.video.length > 0"
                (click)="play(item.video, item.nome)"
                class="btn"
                title="Assistir vídeo"
              >
                <img src="assets/icons/video.svg" alt="Abrir vídeo" />
                vídeo
              </button>
              <button
                (click)="delete(item.id)"
                class="btn"
                title="Assistir vídeo"
              >
                <img src="assets/icons/excluir.svg" alt="Abrir vídeo" />
                excluir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="idSelected >= 0" class="col-12 w-100">
      <app-form-palestra-disponivel-admin
        [id]="idSelected"
        [palestrante]="palestrante"
        (finished)="formFinished()"
      >
      </app-form-palestra-disponivel-admin>
    </div>
  </div>
</div>
