<main>
  <h1>Pesquisas feitas no site</h1>

  <div>
      <section>
        <button type="button" (click)="downloadCSV()">Baixar planilha</button>
      </section>
  </div>

  <div *ngIf="loading">Carregando ...</div>

  <div *ngIf="!loading">
    <table class="customTable">
      <thead>
        <th>Pesquisa</th>
        <th>Data</th>
        <th>Hora</th>
      </thead>

      <tbody>
        <tr *ngFor="let p of pesquisas">
          <td>{{ p.value }}</td>
          <td>{{ parseDate(p.createdAt) }}</td>
          <td>{{ parseTime(p.createdAt) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</main>
