import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { CommonModule } from '@angular/common';
import { Passo1Component } from './passo1';
import { Passo2Component } from './passo2';
import { Passo3Component } from './passo3';
import { WizardCalculadoraComponent } from './wizard-calculadora';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [Passo1Component, Passo2Component, Passo3Component, WizardCalculadoraComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    NgxMaskModule.forRoot(maskConfig),
  ]
})
export class CalculadoraModule { }
