<div class="p-1">
    <button (click)="bsModalRef.hide()" type="button" class="btn__close close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="d-flex flex-column p-5">
        <h1 class="font-title text-center mb-4">Se interessou na nossa Educação Corporativa?</h1>

        <p class="descricao mb-3">
            Entre em contato conosco através do email
            <a href="mailto:contato@profissionaissa.com?subject=Interesse em Educação Corporativa">contato@profissionaissa.com</a>, assim o curador responsável por sua conta entrará em contato com você. Ou entre
            em contato conosco pelo WhatsApp clicando no botão abaixo.
        </p>

        <button (click)="whats()" type="button"
            class="btn btn-warning float-right font-title text-uppercase">WHATSAPP</button>
    </div>
</div>