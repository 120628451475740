import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormCertificatesService } from 'src/app/services/FormCertificates/form-certificates.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verify-certificates',
  templateUrl: './verify-certificates.component.html',
  styleUrls: ['./verify-certificates.component.scss']
})
export class VerifyCertificatesComponent implements OnInit {
  loading: boolean = false;

  certificados: any = [];
  filtered: any = [];

  filterName: string = '';

  constructor(
    private formCertificateService: FormCertificatesService,
    private toast: ToastrService,
    private activeRouter: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getCertificates();
  }

  getCertificates() {
    this.loading = true;
    this.formCertificateService.getCertificatePDF().subscribe(
      (result: any) => {
        this.certificados = result.body;
        this.filtered = result.body;
        this.loading = false;
        console.log(result);

      },
      (data: any) => {
        this.loading = false;
        this.toast.error('Ocorreu um erro ao recuperar os certificados.');
      }
    )
  }

  filterByName(): void {
    this.filtered = this.certificados.filter((certificado: any) => certificado.userEmail.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase()));
  }

  downloadPDF(pdf: string) {
    window.open(pdf);
  }
}
