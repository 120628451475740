import { Component, OnInit } from '@angular/core';
import { PalestrasService } from 'src/app/services/palestras';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalOrcarComponent } from "src/app/common/modal-orcar";
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

interface ITema {
  id: number
  nome: string
  descricao: string
  foto: string
}

@Component({
  selector: 'app-tema',
  templateUrl: './tema.component.html',
  styleUrls: ['./tema.component.scss']
})
export class TemaComponent implements OnInit {
  bsModalRef: BsModalRef;

  loading: boolean = false;

  tema: ITema;
  subtemas: Array<ITema> = []
  especialistas: Array<any> = []
  palestras: Array<any> = []

  relacionadosOptions: OwlOptions = {
    margin: 20,
    nav: false,
    navText: ['', ''],
    responsive: { 0: { items: 1 }, 400: { items: 2 }, 940: { items: 4 } }
  }

  palestrasOptions: OwlOptions = {
    margin: 10,
    nav: false,
    navText: ['', ''],
    responsive: { 0: { items: 1 }, 400: { items: 2 }, 940: { items: 4 } }
  }

  profissionaisOptions: OwlOptions = {
    margin: 10,
    nav: false,
    navText: ['', ''],
    responsive: { 0: { items: 1 }, 400: { items: 2 }, 940: { items: 4 } }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private palestrasService: PalestrasService) { }

  async ngOnInit() {
    const nome = this.route.snapshot.paramMap.get("nome").replace(/[-]+/g, ' ')

    try {
      this.loading = true
      await this.palestrasService.getTemaByName(nome).then(res => {
        this.tema = res.assunto
        this.subtemas = res.subtemas
        this.palestras = res.palestras
        this.especialistas = res.especialistas
        this.loading = false
      })

    } catch (error) {
      this.loading = false
      this.router.navigate(['palestras/temas'])
    }
  }

  orcar() {
    this.bsModalRef = this.modalService.show(ModalOrcarComponent);
  }

  goSubitem(nome: string) {
    this.router.navigate(['palestras/subtema', this.palestrasService.slugify(nome.toLowerCase())])
  }

  goPalestra(slug: number) {
    this.router.navigate(['palestras/disponivel', slug])
  }
}
