import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PalestrasService } from 'src/app/services/palestras';
@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit {

  contentsToDownload: Array<any> = []
  contentsCompleted: Array<any> = []
  taskFilter: String = ''
  eventSelected: number = null;

  constructor(
    private palestrasService: PalestrasService,
    private toast: ToastrService,
    ) { }

  ngOnInit(): void {
    this.getTasks()
  }

  async getTasks() {
    try {
      let result: Array<any> = await this.palestrasService.getPrevious()
      this.contentsCompleted = result
      this.contentsToDownload = result.filter((item) => (item.url_offline ? true : false));      
    } catch (error) {
      this.toast.error("Não foi possível carregar os conteúdos")
    }
  }

  async getFilteredTasks(val){
    val = val.toLowerCase()
    this.taskFilter = val
    await this.getTasks()
    this.contentsCompleted = this.contentsCompleted.filter(function (item) {
      if(item.nome.toLowerCase().includes(val) || item.palestrante.toLowerCase().includes(val) || item.tema.toLowerCase().includes(val)){
        return true;
      }
    })
    this.contentsToDownload = this.contentsToDownload.filter(function (item) { 
      if(item.nome.toLowerCase().includes(val) || item.palestrante.toLowerCase().includes(val) || item.tema.toLowerCase().includes(val)){
        return true;
      }
    })
  }
  

  selectToShowEvent(e: any) {
    this.setEventSelected(e.id)
  }

  setEventSelected(value: number) {
    this.eventSelected = value
  }
}
