<p *ngIf="contatos.length == 0">Nenhum contato encontrado.</p>

<div *ngIf="changePassword" class="col-12 col-lg-4">
    <form class="d-flex flex-column" (submit)="setSenha()">
        <h5 class="font-title">
            {{'Senha ' + contatoEditar.nome.split(" ")[0]}}
        </h5>
        <div class="form-group">
            <label class="label-input" for="senha">Nova Senha</label>
            <input class="form-control" [(ngModel)]="senha" name="senha" type="password" placeholder="Nova senha *"
                maxlength="20" (ngModelChange)="checkPasswordRule()">

            <app-check-validacao-senha #validacaoPass [senha]="senha"></app-check-validacao-senha>
        </div>

        <div class="form-group">
            <label class="label-input" for="confirma_senha">Repetir nova senha</label>
            <input class="form-control" [(ngModel)]="confirma_senha" name="confirma_senha" type="password"
                placeholder="Repetir Senha *" maxlength="20">
        </div>

        <div class="d-flex mt-2 justify-content-end">
            <button *ngIf="!loading" (click)="changePassword=false" class="btn__secondary mr-2">Cancelar</button>
            <button *ngIf="!loading" type="submit" class="btn__primary">Salvar</button>
            <button *ngIf="loading" class="btn__primary mt-2">
                <img height="40px" src="assets/img/simple_loading.gif" alt="loading">
            </button>
        </div>
    </form>
</div>

<!-- <app-form-contato [contact]="contatoEditar" (finished)="cancelarAdicionar()" *ngIf="adicionar"></app-form-contato> -->

<ul class="d-flex text-left mt-2 list-group p-0 list__contatos">

    <li [class.d-none]="!contatos || contatos.length == 0" class="list-group-item border-0">
        <div class="d-flex list__contatos__title">
            <div class="text-uppercase font-weight-bold list__title list__contatos__li__nome">NOME</div>
            <div class="text-uppercase font-weight-bold list__title list__contatos__li__email">E-MAIL</div>
            <div class="text-uppercase font-weight-bold list__title list__contatos__li__departamento">departamento</div>
            <div class="text-uppercase font-weight-bold list__title list__contatos__li__principal">principal</div>
            <div class="text-uppercase font-weight-bold list__title list__contatos__li__acao">Ações</div>
        </div>
    </li>

    <li class="list-group-item border-0 list__contatos__li" *ngFor="let contato of contatos, let index = index">
        <div class="d-flex list__contatos__li">
            <div class="list__contatos__li__nome">{{contato.nome}}</div>
            <div class="list__contatos__li__email">{{contato.email}}</div>
            <div class="list__contatos__li__departamento">{{contato.departamento}}</div>
            <div class="list__contatos__li__principal">{{contato.principal == 'SIM' ? 'SIM' : 'NÃO'}}</div>
            <div class="list__contatos__li__acao">
                <div class="td_acao">
                    <img (click)="editarContato(index)" src="assets/icons/editar.svg" alt="editar" title="Editar">
                    <img [class.d-none]="contato.id_contratante_colaborador > 0" (click)="confirmConvertToCollaborator(index)"
                        src="assets/icons/person.svg" alt="tornar um colaborador" title="Tornar um colaborador">
                    <img (click)="excluirContato(contato.id)" src="assets/icons/excluir.svg" alt="excluir"
                        title="excluir">
                    <img (click)="changePasswordContato(index)" src="assets/icons/padlock.svg" alt="trocar senha"
                        title="trocar senha ">
                </div>
            </div>
        </div>
    </li>
    <li class="list-group-item border-0 td__add">
        <div class="li__add" (click)="adicionarContato()">
            <img height="20px" src="assets/icons/mais_circle.svg" alt="adicionar">
            <span class="list__title font-title text-uppercase ml-4">Adicionar Administrador</span>
        </div>
    </li>
</ul>