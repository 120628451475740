<div class="container-fluid pl-lg-5 pr-lg-5">

    <app-wizard [itemSelected]="3"></app-wizard>

    <div class="container__conteudo mt-3 col-12 col-lg-12">
        <div><button (click)="salvar()" type="button"
                class="btn btn-warning float-right font-title text-uppercase">Avançar</button>
            <h4 class="d-inline font-title mb-4">Grupo {{grupo.nome}}</h4>
            <p class="descricao mt-2 mb-1">Adicione os colaboradores aos seus tutores.</p>
        </div>

        <div class="d-flex justify-content-between align-items-center">
            <h4 class="font-title mt-3 mb-2">Tutores</h4>

            <div class="container__search col-12 col-lg-5 d-flex">
                <img src="assets/icons/search-black.svg" alt="ícone pesquisar">
                <input class="container__search__input form-group m-0 border-0 bg-transparent" placeholder="Buscar..."
                    type="text" name="search" [(ngModel)]="search" (ngModelChange)="buscar()">
            </div>
        </div>

        <div class="content__cabecalho text-left d-flex p-0">
            <label class="font-title text-uppercase flex2">NOME</label>
            <label class="font-title text-uppercase flex1">E-MAIL</label>
            <label class="font-title text-uppercase flex1">SETOR</label>
            <label class="font-title text-uppercase flex1">CARGO</label>
            <label class="font-title text-uppercase flex1 text-center">AÇÕES</label>
        </div>

        <ul class="col-12">
            <li [ngClass]="{ isLider : lider.responsavel_grupo_bool}" *ngFor="let lider of lideres">

                <div class="d-flex content__list__item">

                    <div class="flex2 w-100 item__nome">
                        <input class="input__disable" type="text" [(ngModel)]="lider.nome_completo" disabled="disabled">
                    </div>

                    <div class="flex1">
                        <input class="input__disable" type="text" [(ngModel)]="lider.email" disabled="disabled">
                    </div>

                    <div class="flex1">
                        <input class="input__disable" type="text" [(ngModel)]="lider.setor" disabled="disabled">
                    </div>

                    <div class="flex1">
                        <input class="input__disable" type="text" [(ngModel)]="lider.cargo_nome" disabled="disabled">
                    </div>


                    <div class="flex1 td_acao">

                        <div class="div__add" (click)="addColaboradores(lider)">
                            <img height="20px" src="../../../assets/icons/mais_circle.svg" alt="adicionar">
                            <span class="label__input font-title text-uppercase ml-2">colaboradores</span>
                        </div>
                    </div>
                </div>

            </li>
        </ul>


        <p *ngIf="lideres?.length == 0">Nenhum tutor cadastrado.</p>


        <div class="d-flex flex-row-reverse justify-content-between align-items-end">

            <nav *ngIf="qtdePages?.length > 1" aria-label="Page navigation example">
                <ul class="pagination">
                    <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                        <a class="page-link">Anterior</a>
                    </li>
    
                    <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)" class="page-item">
                        <a class="page-link">1</a>
                    </li>
                    <span *ngIf="(page + 1) > 4" class="px-2">...</span>
    
                    <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)" class="page-item" *ngFor="let number of getPages()">
                        <a class="page-link">{{number+1}}</a>
                    </li>
    
                    <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
                    <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}" (click)="changePage(qtdePages.length - 1)" class="page-item">
                        <a class="page-link">{{qtdePages.length}}</a>
                    </li>
    
                    <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                        <a class="page-link">Próxima</a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>
</div>