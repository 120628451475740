import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-pdf',
  templateUrl: './modal-pdf.component.html',
  styleUrls: ['./modal-pdf.component.scss']
})
export class ModalPDFComponent implements OnInit {

  @Input('link') link: string;
  @Input('title') title: string;

  constructor(
    public bsModalRef: BsModalRef,
    public domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.bsModalRef.hide();
  }

  download() {
    window.open(this.link, "blank")
  }

}
