import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormCertificatesService {
  constructor(public http: HttpClient) { }

  createForms(body: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'multipart/form-data');
    return this.http.post(`${environment.API_V2_URL_CERTIFICADOS}forms`, body);
  }

  editForms(body: any, id: string) {
    return this.http.put(`${environment.API_V2_URL_CERTIFICADOS}forms/${id}`, body);
  }

  getAllForms() {}

  getformById(id: string) {
    return this.http.get(`${environment.API_V2_URL_CERTIFICADOS}forms/${id}`);
  }

  deleteForm(id: string) {
    return this.http.delete(`${environment.API_V2_URL_CERTIFICADOS}forms/${id}`);
  }

  getFormByHash(hash: string) {
    return this.http.get(`${environment.API_V2_URL_CERTIFICADOS}hash/form/${hash}`);
  }

  createAnswer(body: any) {
    return this.http.post(`${environment.AWS_GATEWAY_CERTIFICADOS}`, body)
  }

  getAllAnswers() {
    return this.http.get(`${environment.API_V2_URL_CERTIFICADOS}answers`);
  }

  getAnswersSummary(queryFilters: string) {
    return this.http.get(`${environment.API_V2_URL_CERTIFICADOS}resumo-respostas${queryFilters}`,);
  }

  getAnswerById() {}

  async verifyAnswer(userEmail: string, formId: string) {
    const body = {
      userEmail,
      formId,
    };

    return this.http.post(`${environment.API_V2_URL_CERTIFICADOS}verify-answers`, body).toPromise();
  }

  getAnswersForCSV(id: string | null) {
    const body = { id };

    return this.http.post(`${environment.API_V2_URL_CERTIFICADOS}csv/answers`, body);
  }

  getCertificatePDF() {
    return this.http.get(`${environment.API_V2_URL_CERTIFICADOS}verify/answers/pdf`);
  }

  generateReport(id: string) {
    return this.http.get(`${environment.API_V2_URL_CERTIFICADOS}relatorio/${id}`);
  }

  getSpeakersRanking(filterStart: string, filterEnd: string) {
    const query = `?dateStart=${filterStart ? filterStart : ''}&dateEnd=${filterEnd ? filterEnd : ''}`;
    return this.http.get(`${environment.API_V2_URL_CERTIFICADOS}ranking${query}`);
  }

  getQrCodeSummaryByCS(emailCS: string, filterStart?: string, filterEnd?: string, speakerName?: string) {
    const query = `emailCS=${emailCS}&speakerName=${speakerName}&dateStart=${filterStart ? filterStart : ''}&dateEnd=${filterEnd ? filterEnd : ''}`;
    return this.http.get(`${environment.API_V2_URL_CERTIFICADOS}qr-code-summary/cs?${query}`);
  }

  getQRCodeForDownload(formId: string) {
    return this.http.get(`${environment.API_V2_URL_CERTIFICADOS}download-qr-code/${formId}`);
  }
}
