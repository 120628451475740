<div class="container-fluid px-4 px-md-5">

    <!-- <app-wizard [itemSelected]="7"></app-wizard> -->

    <div class="container__conteudo__default row mt-4">
        <div class="sectionActions">
            <div class="closeButton" (click)="close()">
              <img src="../../../../../assets/icons/close.svg" />
            </div>
          </div>

        <div class="col-12" style="height: 100%;">
            <div class="d-flex justify-content-end mb-2">
                <button (click)="solicitar()" class="btn__add" title="Solicitar depoimento">Solicitar</button>
            </div>

            <div *ngIf="openForm" class="col-12 col-md-8">
                <app-form-solicitar-depoimento (finished)="formFinished()">
                </app-form-solicitar-depoimento>
            </div>

            <div *ngIf="depoimentos.length == 0">
                Nenhuma depoimento aprovado
            </div>

            <div [class.d-none]="openForm" class="row">
                <div *ngFor="let i of depoimentos" class="col-12 col-md-4">
                    <div class="d-flex flex-column depoimento">
                        <label class="">{{i.nome}}</label>
                        <label class="depoimento__desc">"{{i.descricao}}"</label>
                        <label class="">{{i.empresa}}{{i.cidade ? " - " : ""}}{{i.cidade}}</label>
                        <label class="">{{i.data}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>