import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActiveCampaignService } from 'src/app/services/ActiveCampaign/active-campaign.service';
import { UserService } from 'src/app/services/user';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  @Output("logged") logged = new EventEmitter();

  email: string = "";
  senha: string = "";
  mensagem: string;
  loading: boolean = false;
  lockScreen: boolean = false;
  isPrimeiroAcesso: boolean = false;
  token_acesso: string

  emailInvalid: boolean = false
  senhaInvalid: boolean = false
  showPass: boolean = false

  isRegistration: boolean = false;


  constructor(
    private userService: UserService,
    private toast: ToastrService,
    private activeCampaignService: ActiveCampaignService,
  ) { }


  ngOnInit(): void {
    let query: Array<any> = window.location.search.substr(1).split('&')
    query.forEach(i => {
      if (i.includes("email")) this.email = i.split("=")[1]
    })
    const path = window.location.href.split('/').pop();
    console.log(path);
    if (path === 'cadastro') {
      this.isRegistration = true;
    } else {
      this.isRegistration = false;
    };
  }


  async login() {
    if (!this.validations()) return;

    this.loading = true;
    try {
      const res = await this.userService.login({
        email: this.email,
        password: this.senha,
        profileRequired: "contratante,palestrante,espectador,admin,curadora,curadorb,curadorc,avaliador,gestor, gestorp, cs"
      });

      if (res.usuario.perfil.toUpperCase() == "CONTRATANTE" && !(await this.validationContratante(res.usuario.id))) return;

      if (res.usuario.perfil.toUpperCase() == "PALESTRANTE") {
        this.linkLoginTag(res.usuario);
      }

      this.loading = false;
      let auth = { email: this.email, password: this.senha, admUser: res.usuarioContato }
      this.logged.emit({ data: res, auth: auth })
    } catch (e) {
      this.mensagem = e.error ? e.error.message : "Não foi possível estabelecer uma conexão";
      this.loading = false;
      return;
    }
  }

  linkLoginTag(user: any) {
    this.activeCampaignService.getContact(user.email).subscribe(
      (result: any) => {
        if (result.body.contacts.length === 0) {

          this.activeCampaignService.createContact(user.email).subscribe(
            (result: any) => {}
          );
        }
        const contactId = result.body.contacts.map((contact: any) => contact.id);
        contactId.forEach((contact: any) => {
          this.activeCampaignService.linkContact(contact).subscribe();
        })
      }
    )
  }


  validations(): boolean {
    this.mensagem = "";
    this.emailInvalid = false
    this.senhaInvalid = false

    if (this.email.length < 1) {
      this.mensagem = "Preencha o e-mail!";
      this.emailInvalid = true
      return false
    }

    if (this.senha.length < 1) {
      this.mensagem = "Preencha a senha!";
      this.senhaInvalid = true
      return false
    }
    return true
  }


  async validationContratante(id_usuario: any): Promise<boolean> {
    try {
      const valida = await this.userService.validaUsuario({ id_usuario: id_usuario });
      const status = [64, 63, 62, 61, 60] //['diamante', 'safira', 'ouro', 'prata', 'bronze']

      if (status.includes(valida.id_status) || valida.prazo == false) {
        this.lockScreen = false;
        return true
      } else {
        this.lockScreen = true;
        this.loading = false;
        return false
      }
    } catch (error) {
      this.toast.error("Erro ao validar perfil contratante")
      return false
    }
  }


  queroCadastrar() {
    this.isRegistration = true;
  }


  setShow() {
    this.showPass = !this.showPass
  }


  async redefinirSenha(email) {
    this.emailInvalid = false
    this.mensagem = ""
    if (!email || email == "" || email == undefined || email == null) {

      this.emailInvalid = true
      this.mensagem = "Insira um e-mail válido.";
      return;

    } else {
      let body = { email: email }

      try {
        const res = await this.userService.mailerRedefinirSenha(body);
        this.mensagem = "Em breve você receberá um e-mail com as informações para redefinir sua senha.";
      } catch (e) {
        this.mensagem = e.error.message ? e.error.message : "Não foi possível estabelecer uma conexão";
      }
    }

  }

}
