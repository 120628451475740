import { SimpleChanges } from '@angular/core';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PalestrasService } from 'src/app/services/palestras';
@Component({
  selector: 'app-proximas-palestras',
  templateUrl: './proximas-palestras.component.html',
  styleUrls: ['./proximas-palestras.component.scss']
})
export class ProximasPalestrasComponent implements OnInit {
  _filter: string
  @Input() palestras: Array<any> = []
  @Input()
  public set filter(name: string) {
    this._filter = name;
    this.getFilteredPalestras();           // <-- trigger the call here
  }
  idUsuario: number

  @Output("openDetail") openDetail = new EventEmitter();

  constructor(
    private palestrasService: PalestrasService,
    private toast: ToastrService) { }

  ngOnInit(): void {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    this.idUsuario = usuario.id
    this.getPalestras()
  }

  async getPalestras() {
    try {
      const res = await this.palestrasService.getPalestrasAgenda(true)
      this.palestras = res.palestras
    } catch (error) {

    }
  }

  async getFilteredPalestras(){
    try {
      await this.getPalestras()
      const value = this._filter;
      this.palestras = this.palestras.filter(function (item) {
        if((item.nome && item.nome.toLowerCase().includes(value)) || (item.palestrante && item.palestrante.toLowerCase().includes(value)) || (item.assunto && item.assunto.toLowerCase().includes(value))){
          return true;
        }
      })
    } catch (error) {
    }
  }

  selectItem(id: number) {
    this.openDetail.emit({id: id})
  }
}
