import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FormCertificatesService } from 'src/app/services/FormCertificates/form-certificates.service';
import { ModalOptComponent } from './model-opt/modal-opt.component';
import { labelsLocalization, selectOptionsLocalization, themesLocalization } from 'src/app/utils/formTranslations';
import { NgLocaleLocalization } from '@angular/common';

@Component({
  selector: 'app-answer-forms',
  templateUrl: './answer-forms.component.html',
  styleUrls: ['./answer-forms.component.scss']
})
export class AnswerFormComponent implements OnInit {
  hash: string;

  showForm: boolean = false;
  formsInfo: any = {};
  noAvaliation: boolean = false;

  showNotice: boolean = false;
  personalizedNotice: boolean = false;
  showModalOPT: boolean = true;
  loading: boolean = false;

  answer: any = {
    userEmail: '',
    userName: '',
    avaliacao: 'Conectou demais',
    avaliacaoNew: 'Foi incrível, conectou demais',
    accessContent: 'Sim, quero',
    accessContentNew: 'Sim, quero! Adorei o(a) palestrante',
    job: 'Auxiliar',
    phone: '',
    opinion: '',
    newAnswers: true,
    aceitaTermos: false,
    theme: 'Motivação',
  }
  isDisabled: boolean = false;
  isForeign: boolean = false;
  noEmail: boolean = false;
  noPhone: boolean = false;
  language: string = 'spanish';

  errorMessage: string;
  hasAlreadyAnswered: boolean = false;

  themes: any = themesLocalization['pt-br'];
  labels: any = labelsLocalization['pt-br'];
  selectOptions: any = selectOptionsLocalization['pt-br'];

  showPhoneInput: boolean = true;

  errors: any = {
    empty: (field) => `O campo "${field}" é obrigatório`,
    invalid: (field) => `O campo "${field}" é inválido`,
    required: () => 'Preencha todos os campos obrigatórios',
  };

  constructor(
    private activeRouter: ActivatedRoute,
    private toast: ToastrService,
    private formCertificateService: FormCertificatesService,
    private bsModalRef: BsModalRef,
    private modalService:BsModalService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.activeRouter.paramMap.subscribe(
      (result: any) => {
        if (!result.params.hash) {
          this.showForm = false;
          this.loading = false;
        } else {
          this.hash = result.params.hash;
          this.getFormInfo();
        }
      }
    )
  }

  getFormInfo() {
    this.formCertificateService.getFormByHash(this.hash).subscribe(
      (result: any) => {
        if (result.body.length === 0) {
          this.showForm = false;
        }

        [this.formsInfo] = result.body;
        // verifica se precisa responder os campos de avaliação do palestrante
        this.noAvaliation = Boolean(this.formsInfo.noAvaliation);
        // verifica se o formulário deve capturar o email
        this.noEmail = Boolean(this.formsInfo.noEmail);
        // verifica se o formulário deve capturar o telefone
        this.noPhone = Boolean(this.formsInfo.noPhone);

        //checa se o palestrante é exclusivo e tem dados personalizados pro certificado
        if(this.formsInfo.redirectPage) {
          this.personalizedNotice = true;
        }

        this.themes = themesLocalization[this.formsInfo.language];
        this.labels = labelsLocalization[this.formsInfo.language];
        this.selectOptions = selectOptionsLocalization[this.formsInfo.language];

        this.showForm = true;
        this.loading = false;
      }
    ),
    (data: any) => {
      this.toast.error('Não foi possível recuperar as informações to formulário. Tente novamente mais tarde!');
      this.showForm = false;
      this.loading = false;
    }
  }

  validateEmail(email): boolean {
    const EMAIL_REGEX = /^[a-z0-9._]+@[a-z0-9]+(\.[a-z]+)+$/i;
    const isEmailValid = EMAIL_REGEX.test(email);

    if (!email) {
      this.errorMessage = this.errors.empty('Email');
      this.toast.error(
        '', 'O campo e-mail nao pode ser vazio',
        { timeOut: 5000 }
      );
      return false;
    }

    if (!isEmailValid) {
      this.loading = false;
      this.errorMessage = this.errors.invalid('Email');
      return false;
    } else {
      this.errorMessage = null;
      return true;
    }
  }

  //TO-DO write function to validate phone
  checkInput() {
    const hasEmpty = Object.values(this.answer).filter((item: string) => item.length === 0);
    if (hasEmpty.length > 0) {
      this.errorMessage = this.errors.required();
      return false;
    } else if(!this.answer.aceitaTermos) {
      this.errorMessage = 'Aceite os termos de uso antes de prosseguir'
      return false;
    } else {
      this.errorMessage = null;
      return true;
    }
  }

  async verifyIfAnswerExists() {
    try {
      const response = await this.formCertificateService.verifyAnswer(this.answer.userEmail, this.formsInfo.id);
      return true;
    } catch (err) {
      this.toast.error(err.error.message);
      return false;
    }
  }

  redirect (link: string) {
    window.location.href = this.formsInfo.redirectPage;
  }

  createBody(answer: any, form: any) {
    return {
      formId: answer.formId,
      userEmail: answer.userEmail,
      userName: answer.userName,
      job: answer.job,
      avaliacaoNew: answer.avaliacaoNew,
      accessContentNew: answer.accessContentNew,
      phone: answer?.phone || '',
      opinion: answer.opinion,
      theme: answer.theme,
      aceitaTermos: Boolean(answer.aceitaTermos),
      formInfos: {
        eventDate: form.eventDate,
        speakerName: form.speakerName,
        useClientLogo: Boolean(form.useClientLogo),
        certificateBg: form.certificateBg,
        noPSALogo: Boolean(form.noPSALogo),
        isForeign: Boolean(form.isForeign),
        talkTitle: form.talkTitle,
        eventName: form.eventName,
        useTitle: Boolean(form.useTitle),
        customHtml: form?.emailContent,
        clientLogo: form?.clientLogo,
        generateCertificate: form?.generateCertificate,
        noCertificate: form?.noCertificate || this.noEmail,
        language: form.language
      }
    }
  }

  async saveAnswer() {
    // preenche campos que não apareceram no formulário sem avaliação
    if (this.noAvaliation) {
      this.answer.opinion = 'sem avaliação';
      this.answer.avaliacao = 'sem avaliação';
    }

    if(this.noPhone) {
      this.answer.phone = '--';
    }

    // verifica se o usuário já respondeu o formulário
    const isNewEmail = await this.verifyIfAnswerExists();

    // valida email e preenchimento dos inputs
    if(!this.noEmail) {
      this.validateEmail(this.answer.userEmail);
    } else {
      this.answer.userEmail = '--';
    }
    this.checkInput();

    this.answer.formId = this.formsInfo.id;

    if ((!this.errorMessage && this.answer.aceitaTermos) && isNewEmail) {
      this.loading = true;
      const body = this.createBody(this.answer, this.formsInfo);

      this.formCertificateService.createAnswer(body).subscribe(
        (result: any) => {
          if(!this.personalizedNotice) {
            this.loading = false;
            this.showNotice = true;
            this.showForm = false;
          } else {
            window.location.href = this.formsInfo.redirectPage;
          }
        },
        (data: any) => {
          this.loading = false;
          this.toast.error('INTERNAL SERVER ERROR');
        }
      );
    }
  }

  openOPT() {
    const initialState = { aceitaTermos: this.answer.aceitaTermos };
    const config = { class: 'modal-termos', initialState };

   this.modalService.show(ModalOptComponent, config);

   this.modalService.onHide.subscribe(
    (result: any) => {
      this.answer.aceitaTermos = true;
    }
   )
  }
}
