import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { stringify } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  constructor(public http: HttpClient) { }

  async uploadFile(file: File, path: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filePath', path);

    const result = await this.http.post<any>(`${environment.API_V2_URL}s3/upload`, formData, { headers: headers }).toPromise();
    return result.body;
  }

  async deleteFile(url: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = JSON.stringify({ url: url })
    const result = await this.http.post<any>(`${environment.API_V2_URL}s3/delete`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async deleteCollectionFiles(urls: Array<string>): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = { urls: urls }
    const result = await this.http.post<any>(`${environment.API_V2_URL}s3/delete-collection`, body, { headers: headers }).toPromise();
    return result.body;
  }
}
