import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
// import { Socket } from 'ngx-socket-io';
import * as moment from 'moment';
moment.locale('pt-BR')

import { AppComponent } from 'src/app/app.component';
import { CommonService } from 'src/app/services/common';
import { LiveService } from 'src/app/services/live';
import { PalestrasService } from 'src/app/services/palestras';
import { ToastrService } from 'ngx-toastr';
import { ComponentCanDeactivate } from 'src/app/guards/guard-live.component';

@Component({
  selector: 'app-live-palestrante',
  templateUrl: './live-palestrante.component.html',
  styleUrls: ['./live-palestrante.component.scss']
})

export class LivePalestranteComponent extends ComponentCanDeactivate implements OnInit, OnDestroy {

  loading: boolean = false
  urlBambuser: any = environment.URL_BAMBUSER

  date = new Date;
  broadcaster: any;
  live: any;
  liveLiberada: boolean = false;
  window: any;
  addTempo: boolean = false;
  addTempoExibe: number = 0;

  interval: any;
  intervalQuiz: any;
  intervalTimer: any;
  segundos: number = 0;
  minutos: number = 30;
  painel: boolean = false;

  senha = '';
  nome;
  sobrenome;
  perfil = null;
  palestrante = null;
  canPay = false;
  disabledAssuntos;
  planos = [];
  assuntos = [];
  error = '';
  palestrantes_psa = '';
  msgEnviado = '';
  palestrantes = [];
  nomesPalestrantesSelected = [];
  nomes_palestrantes;
  nome_dos_palestrantes = '';

  exibeSlide: any = false;
  pdfBase64: any = "";
  exibeArquivo: boolean = false;
  palestra: any = {};
  perguntas: Array<any> = [];
  resposta: boolean = false;
  responda: any = ""
  respostaCaputra: any = "";
  retornoUrl: boolean = false;
  path: any;
  currentPage: number = 1;
  avisoTermino: boolean = false;

  id: any = null;
  links: Array<any> = [];
  mlLink: boolean = false;
  mbLink: boolean = false;

  controlaExibSlide: any = {};
  idYoutube: any;
  quiz: Array<any> = [];
  mensagem: any = "";
  mensagemAlerta: string;

  numeroEspectadores: number = 0;
  broadcasterElement: any;
  loadingPDF: boolean = true;

  editaQuiz: boolean = false;
  quizSelecionado: any;

  editaVideo: boolean = false;
  videoSelecionado: any;
  termometro: any = {};
  loadingEdit: boolean = false;

  showAvisoTempo: boolean = false;
  mensagemAvisoTempo: string;
  submensagemAvisoTempo: string;

  isTempoTolerancia: boolean = false;
  termometroAvaiable: boolean = true;

  whatsSupportNumber: string = "5551992979844"

  releasedVideo = null;

  configChatSocket: "0" | "1" = "1"

  canDeactivate(): boolean {
    return false;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private toast: ToastrService,
    private titleService: Title,
    private appComponent: AppComponent,
    private liveService: LiveService,
    private palestrasService: PalestrasService,
    private commonService: CommonService,
    // private socket: Socket,
  ) {
    super();
    this.setFullScreen()
    this.urlBambuser = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlBambuser);
  }

  setFullScreen() {
    this.appComponent.closeSide()
    this.appComponent.showHeader(false)
  }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id")
    if (!this.id) {
      window.history.back()
      return
    }

    this.commonService.getConfiguracao("live_palestrante_usar_chat_socket", "1")
      .then(res => { this.configChatSocket = res.valor })
      .catch(e => { console.log(e); })

    this.loading = true
    this.titleService.setTitle("Live")

    await this.getPalestra()

    this.buscaQuiz();

    this.getLinks()

    this.getRespostasQuiz();

    this.getArquivo()

    this.startIntervalInformacoes()
    this.loading = false

    window.addEventListener('message', (event) => {
      if (event.origin !== 'https://dist.bambuser.net') {
        return;
      }
      console.info('DATA', event);
      let broadcasterEvent = event.data.broadcasterEvent;
      if (broadcasterEvent && broadcasterEvent.name === "currentViewers") {
        this.setNroEspectadores(broadcasterEvent.data);
      }
      if (broadcasterEvent && broadcasterEvent.name === 'registeredBroadcastId') {
        console.info('broadcast id: ', broadcasterEvent.data);
        this.start(broadcasterEvent.data);
      }
      if (broadcasterEvent && broadcasterEvent.name === 'stopped') {
        this.stop();
      }
      if (broadcasterEvent && broadcasterEvent.name === 'error') {
        this.stop();
        this.mensagemAlerta = "Algo deu errado na transmissão da Live. Você não está ao vivo."
      }

    }, false);

    window.addEventListener("unload", (e) => {
      this.terminaLive()
    }, false);


    this.commonService.getConfiguracao("telefone__suporte__live", "5551992979844")
      .then(res => { this.whatsSupportNumber = res.valor })
      .catch(e => { console.log(e); })
  }

  ngOnDestroy(): void {
    this.titleService.setTitle("Dashboard")
    this.terminaLive()
    clearInterval(this.intervalTimer)
    clearInterval(this.interval)
    clearInterval(this.intervalQuiz)
    this.appComponent.showHeader(true)
  }

  async startSocket() {
    // this.socket.connect();

    // this.socket.emit('start', { id: this.id })

    // const user = JSON.parse(localStorage.getItem("usuario"))
    // this.socket.emit('set-name', `${user.nome} ${user.sobrenome}`);

    // this.socket.fromEvent('chat-message').subscribe((data: any) => {
    //   if (this.configChatSocket == "1") {
    //     this.perguntas.splice(0, 0, {
    //       nome: data.user,
    //       sobrenome: '',
    //       datahora_pergunta: data.createdAt,
    //       perguntas: data.msg,
    //       photo: data.photo
    //     });
    //   }
    // });

    // this.socket.fromEvent('users-changed').subscribe(data => {
    //   if (data['event'] !== 'left') {
    //     if (this.path) {
    //       this.socket.emit('pdf-apoio', {
    //         show: true,
    //         url: this.path,
    //         currentPage: this.currentPage
    //       })
    //     }
    //   }
    // });
  }

  async getPalestra() {
    this.palestra = await this.palestrasService.getMinistradaById(this.id)
      .catch(e => {
        this.toast.error("Não foi possível carregar a palestra")
        window.history.back()
      })
  }

  async startIntervalInformacoes() {
    // const result = await this.liveService.getPerguntas(this.palestra.id)
    // this.perguntas = result;

    this.interval = setInterval(async () => {

      if (this.configChatSocket == "0" && this.live) {
        // const result = await this.liveService.getPerguntas(this.palestra.id)
        // this.perguntas = result;
      }

      if (this.live) {
      const resTermometro = await this.liveService.getTermometro(this.palestra.id)
      this.termometro = resTermometro.resultado;

        try {
          const result = await this.liveService.verificaLive(this.id)
          if (result.type != 'live' || result == undefined) {
            this.mensagemAlerta = "Sua conexão está instável. Consulte se os espectadores estão assistindo normalmente."
          }
        } catch (error) {
          console.info('verificaLive', error);
        }
      }

    }, 5000);
  }

  async getArquivo() {
    try {
      this.loadingPDF = true;
      const resArquivo = await this.liveService.getArquivo(this.id)

      if (resArquivo.caminho) {
        this.exibeArquivo = true;
        this.controlaExibSlide = true;
        this.exibeSlide = false;
        this.retornoUrl = true;
        this.path = resArquivo.caminho;

        this.pegaPaginaAtual(0)
      }
      setTimeout(() => { this.loadingPDF = false; }, 1000);
    } catch (error) {
      this.loadingPDF = false;
    }
  }

  async setNroEspectadores(n) {
    if (n <= 2) {
      this.numeroEspectadores = n;
      return
    }
    const res = await this.commonService.getConfiguracao("nro_espec_live", '152')
      .catch((e) => console.log("erro setNro", e))
    this.numeroEspectadores = n + parseInt(res.valor);
  }

  addZero(i) {
    if (i < 10) i = "0" + i;
    return i;
  }

  async start(id_live) {
    this.startSocket()

    this.getDuracao()
    this.live = true;
    this.startTimer();
    const resultInicia = await this.liveService.iniciaLive(this.palestra.id, id_live)
      .catch(e => console.log(e))
  }

  async stop() {
    if (!this.mensagemAlerta) {
      this.terminaLive();
      this.toast.success("Live finalizada com sucesso!")
      this.mensagemAlerta = "Live finalizada com sucesso!";
      this.routeEnd()
    }
    this.live = false;
  }

  private startTimer() {
    clearInterval(this.intervalTimer)
    this.intervalTimer = setInterval(() => {
      if (this.minutos == 1 && this.segundos == 0) this.avisoLive('1 minuto!', 'Sua transmissão irá terminar em')
      if (this.minutos == 5 && this.segundos == 0) {
        this.avisoLive('5 minutos!', 'Sua transmissão irá terminar em')
        this.avisoTermino = true;
      }
      if(this.palestra.duracao == 30 && this.segundos == 0 &&
          (this.minutos == 20 || this.minutos == 15 || this.minutos == 5)
        || this.palestra.duracao == 60 &&  this.segundos == 0 &&
          (this.minutos == 50 || this.minutos == 20 || this.minutos == 1)
        || this.palestra.duracao == 90 &&  this.segundos == 0 &&
          (this.minutos == 80 || this.minutos == 50 || this.minutos == 1)){
        this.liberaAvaliacao()
      }
      if (this.segundos > 0) {
        this.segundos--;
      } else {
        if (this.minutos == 0 && this.segundos == 0) {
          this.tolerancia()
          return
        }
        if (this.segundos == 0) {
          this.minutos--;
        }
        this.segundos = 59;
        if (this.minutos < 10 && this.addTempoExibe < 2 && this.avisoTermino == false) {
          this.addTempoExibe = this.addTempoExibe + 1;
          this.addTime()

          this.avisoLive('+ 10 minutos', 'Acrescentamos mais 10 minutos. Fique atento')
        }
      }
    }, 1000)
  }

  avisoLive(mensagem, submensagem) {
    this.mensagemAvisoTempo = mensagem
    this.submensagemAvisoTempo = submensagem
    this.showAvisoTempo = true
    setTimeout(() => { this.showAvisoTempo = false; }, 5000);
  }

  tolerancia() {
    this.isTempoTolerancia = true
    setTimeout(() => {
      this.live = false;
      this.stop();
    }, 300000)
  }

  async terminaLive() {
    this.stopSlide()
    // await this.liveService.terminaLive(this.id, this.socket)
      .catch(e => console.log(e))
    clearInterval(this.intervalTimer)
    clearInterval(this.interval)
    clearInterval(this.intervalQuiz)
    // this.socket.disconnect()
  }

  routeEnd() {
    this.router.navigate(['/palestrante/agenda']);
  }

  addTime() {
    this.minutos = this.minutos + 10;
    this.addTempo = false;
  }

  cancelAddTime() {
    this.addTempo = false;
  }

  async uploadArquivo() {
    this.exibeSlide = true;
  }

  async stopSlide() {

    // this.socket.emit('pdf-apoio', {
    //   show: false,
    //   url: null,
    //   currentPage: 0
    // })
    this.currentPage = 0
    this.path = null
    this.exibeSlide = false;
    this.exibeArquivo = false;
    this.controlaExibSlide = false;
    this.liveService.deleteArquivo(this.id)
      .catch((e) => { console.log(e) })
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async onChangePdf(evt) {
    this.loadingPDF = true;
    await this.sleep(500);

    const files = evt.target.files;
    if (files[0].size > 4718592) {
      this.controlaExibSlide = true;
      this.exibeSlide = false;
      this.loadingPDF = false;
      alert('Seu arquivo é maior que o permitido. Tamanho máximo permitido 4.5 MB.');
      return;
    }

    if (files) {
      for (let i = 0, f; f = files[i]; i++) {
        if (f.type === 'application/pdf') {
          const reader = new FileReader();

          reader.onload = async (e) => {

            this.exibeSlide = false;
            this.pdfBase64 = e.target['result'];

            const resultArquivo = await this.liveService.insertArquivo(this.palestra.id, this.pdfBase64)
            if (resultArquivo) {
              if (resultArquivo.caminho == "") {
                this.loadingPDF = false;
                alert('Não foi possível compartilhar o arquivo. Tente novamente!.');
                this.exibeSlide = false;
                this.controlaExibSlide = false;
              } else {
                await this.sleep(3000)

                this.path = resultArquivo.caminho;
                this.pegaPaginaAtual(0)
                this.exibeArquivo = true;

                this.controlaExibSlide = true;
                this.exibeSlide = false;
                this.retornoUrl = true;

                setTimeout(() => { this.loadingPDF = false; }, 2500);
              }
            } else {
              this.loadingPDF = false;
              window.alert('Não foi possível compartilhar o arquivo. Tente novamente!.');
            }
          };
          reader.readAsDataURL(f);
          this.loadingPDF = false;
          break;
        }
      }
    }
  }

  closePainel() {
    this.painel = false;
  }

  openPainel() {
    this.painel = true;
  }

  pegaPaginaAtual(page) {
    // this.socket.emit('pdf-apoio', {
    //   show: true,
    //   url: this.path,
    //   currentPage: page
    // })

    this.liveService.updatePaginaArquivo(this.palestra.id, page)
      .catch(e => console.log(e))
  }

  async voltarPerguntas() {
    // const result = await this.liveService.getPerguntas(this.palestra.id)
    // this.perguntas = result;
  }

  paginaPosterior() {
    this.currentPage = this.currentPage + 1;
    this.pegaPaginaAtual(this.currentPage);
  }

  paginaAnterior() {
    if (this.currentPage > 1) {
      this.currentPage = this.currentPage - 1;
      this.pegaPaginaAtual(this.currentPage);
    } else {
      alert('Slide já esta em sua pagina inicial.');
    }
  }

  async liberaLink() {

    if (!this.live) {
      this.mensagemAlerta = "Live ainda não iniciou";
      return
    }

    let videoSelected = null

    for (const v of this.links) {
      if (v.id == this.idYoutube) {
        videoSelected = v
        break
      }
    }


    try {
      if (this.live) {

        this.releasedVideo = {
          show: true,
          url: `https://www.youtube.com/embed/${videoSelected.video_codigo}?autoplay=1&showinfo=0`,
          title: videoSelected.titulo,
        }
        // this.socket.emit('video-apoio', this.releasedVideo)

        await this.liveService.liberaLink(this.idYoutube)
        this.getLinks()

        this.closeMsg();
      } else {
        this.mensagemAlerta = "Live ainda não iniciou";
      }
    } catch (error) {
      this.toast.error("Não foi possível liberar os links")
    }
  }

  async bloqueiaLink() {
    try {
      await this.liveService.bloqueiaLink(this.idYoutube)
      this.getLinks()
      this.closeMsg();
    } catch (error) {
      this.toast.error("Não foi possível bloquear os links")
    }
  }

  async msgLibLink(id) {
    if (this.live) {
      this.mlLink = true;
      this.idYoutube = id;
    } else {
      this.mensagemAlerta = "Live ainda não iniciou";
    }
  }

  async msgBloLink(id) {
    this.mbLink = true;
    this.idYoutube = id;
  }

  closeMsg() {
    this.mlLink = false;
    this.mbLink = false;
  }

  getVideoIframe(url) {
    var video, results;
    if (url === null) return '';

    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];

    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
  }

  async buscaQuiz() {
    const res = await this.liveService.getQuiz(this.palestra.id).catch(e => console.log(e))
    this.quiz = res ? res : [];
  }

  async liberaQuiz(id) {

    if (!this.live) {
      this.mensagemAlerta = "Live ainda não iniciou";
      return
    }

    try {
      // this.socket.emit('quiz', {
      //   show: true,
      // })

      await this.liveService.liberaQuiz(id)

      this.buscaQuiz();
    } catch (e) {
      this.toast.error(e.error.message)
    }

    this.closeMsg();
  }

  async liberaAvaliacao() {
    if (!this.live) {
      this.mensagemAlerta = "Live ainda não iniciou";
      return
    }
    // this.socket.emit('termometro', {
    //   show: true
    // })
    this.mensagem = "Avaliação Liberada"
    this.termometroAvaiable = false;

    const res = await this.liveService.liberaAvaliacao(this.id)

    setTimeout(() => { this.mensagem = ""; }, 5000);
    setTimeout(() => { this.termometroAvaiable = true; }, 300000);
  }

  getRespostasQuiz() {
    var respostasQuiz = [];

    this.intervalQuiz = setInterval(async () => {
      const res = await this.liveService.getRespostasQuiz(this.id)

      respostasQuiz = res ? res : [];

      this.quiz.forEach(quiz => {
        respostasQuiz.forEach(item => {
          if (quiz.id == item.id_pergunta) {
            quiz.porcentagem1 = 0;
            quiz.porcentagem2 = 0;
            quiz.porcentagem3 = 0;
            quiz.porcentagem4 = 0;
            quiz.qtde = 0;

            item.respostas.forEach(resposta => {
              switch (resposta.alternativa) {
                case "1":
                  quiz.porcentagem1 = resposta.porcentagem.toFixed(2);
                  break;
                case "2":
                  quiz.porcentagem2 = resposta.porcentagem.toFixed(2);
                  break;
                case "3":
                  quiz.porcentagem3 = resposta.porcentagem.toFixed(2);
                  break;
                case "4":
                  quiz.porcentagem4 = resposta.porcentagem.toFixed(2);
                  break;
                default:
                  break;
              }
              quiz.qtde += resposta.qtd_resposta;
            });
          }
        })
      });
    }, 10000);

  }

  fechaAlerta() {
    this.mensagemAlerta = null;
  }

  async getDuracao() {
    await this.getPalestra()

    let duracao = this.palestra.duracao;
    let hora = this.palestra.hora;
    let data = new Date(this.palestra.data);
    let now = new Date();

    data.setHours(0);
    data.setMinutes((parseInt(hora.substring(0, 2)) * 60) + parseInt(hora.substring(3, 5)) + duracao);
    let dif = (moment.utc(moment(data, "DD/MM/YYYY HH:mm").diff(moment(now, "DD/MM/YYYY HH:mm"))).format("HH:mm"));

    let auxDuracao = parseInt(dif.substring(0, 2)) * 60 + parseInt(dif.substring(3, 5));

    this.minutos = auxDuracao > duracao ? duracao : auxDuracao;
    this.segundos = 0;
  }

  liberaLive() {
    let dateNow = new Date();
    let hours = new Date().getHours();
    let min = new Date().getMinutes() + hours * 60 + 5; //permitir iniciar live apenas 5 minutos antes
    let dataPalestra = moment(this.palestra.data).toDate();
    let horaPalestra = this.palestra.hora;
    let minPalestras = parseInt(horaPalestra.substring(0, 2)) * 60 + parseInt(horaPalestra.substring(3, 5));

    if (dataPalestra.getDay() != dateNow.getDay() || dataPalestra.getMonth() != dateNow.getMonth()) {
      this.mensagemAlerta = "Sua live não está agendada para hoje!";
      return
    }

    if (min > minPalestras && dataPalestra.getDay() == dateNow.getDay() && dataPalestra.getMonth() == dateNow.getMonth() && dataPalestra.getFullYear() == dateNow.getFullYear()) {
      this.liveLiberada = true;

      var duraction = this.palestra.duracao;
      dataPalestra.setHours(0);
      dataPalestra.setMinutes((parseInt(horaPalestra.substring(0, 2)) * 60) + parseInt(horaPalestra.substring(3, 5)) + duraction);
      if (dateNow > dataPalestra) {
        this.liveLiberada = false;
        this.mensagemAlerta = "Passou do prazo agendado para iniciar a live!";
      }
    } else {
      this.liveLiberada = false
      this.mensagemAlerta = "Só é possível iniciar a live 5 minutos antes do horário cadastrado.";
    }
  }

  selecionaQuiz(quiz) {
    this.quizSelecionado = quiz;
    this.showFormQuiz(true);
  }

  showFormQuiz(val) {
    if (!val) { this.quizSelecionado = null }
    this.editaQuiz = val;

    this.editaVideo = false;
  }

  addQuiz() {
    this.quizSelecionado = {
      id: null,
      pergunta: '',
      opcao1: '',
      opcao2: '',
      opcao3: '',
      opcao4: ''
    }
    this.showFormQuiz(true);
  }

  async salvaQuiz() {

    let auxQuiz = {
      id: this.quizSelecionado.id ? this.quizSelecionado.id : null,
      pergunta: this.quizSelecionado.pergunta,
      opcao1: this.quizSelecionado.opcao1,
      opcao2: this.quizSelecionado.opcao2,
      opcao3: this.quizSelecionado.opcao3,
      opcao4: this.quizSelecionado.opcao4
    }

    this.loadingEdit = true;
    try {
      const res = await this.liveService.insertUpdateQuiz(this.id, auxQuiz)

      this.showFormQuiz(false);
      this.quizSelecionado = null;
      this.buscaQuiz();
      this.loadingEdit = false;
    } catch (e) {
      this.toast.error(e.error.message)
      this.loadingEdit = false;
    }
  }

  selecionaVideo(video) {
    this.videoSelecionado = video;
    this.showFormVideo(true);
  }

  showFormVideo(val) {
    if (!val) { this.videoSelecionado = null }
    this.editaVideo = val;

    this.editaQuiz = false;
  }

  addVideo() {
    this.videoSelecionado = {
      id: null,
      link: null
    }
    this.showFormVideo(true);
  }

  async salvaVideo() {
    var id = this.videoSelecionado.id ? this.videoSelecionado.id : null;
    var link = this.videoSelecionado.link;
    this.loadingEdit = true;
    try {
      const resLink = await this.liveService.insertUpdateLink(this.palestra.id, id, link)

      this.getLinks()

      this.videoSelecionado = null;
      this.showFormVideo(false);
      this.loadingEdit = false;
    } catch (error) {
      this.loadingEdit = false;
    }
  }

  async getLinks() {
    try {
      this.links = await this.liveService.getLinks(this.id)
    } catch (error) {
      this.toast.error("Não foi possível buscar seus vídeos")
    }
  }
}

