import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {NgSelectModule} from '@ng-select/ng-select';

import {ExportComponentsModule} from 'src/app/common/export-components';

import {HomeComponent} from './home';
import {GruposComponent} from './grupos';
import {GrupoLideresComponent} from './grupo-lideres';
import {HabilidadesComponent} from './habilidades';
import {NotificacaoComponent} from './notificacao';
import {ColaboradoresComponent} from './colaboradores';
import {TbColaboradorComponent} from './tb-colaborador';
import {TbLiderComponent} from './tb-lider';
import {ModalFormColaboradorComponent} from './modal-form-colaborador';
import {ImportComponent} from './import';
import {SelecionaLideresComponent} from './seleciona-lideres';
import {GrupoColaboradoresComponent} from './grupo-colaboradores';
import {ModalAdicionarLideresComponent} from './modal-adicionar-lideres';
import {ModalAdicionarColaboradoresComponent} from './modal-adicionar-colaboradores';
import {WizardComponent} from './wizard';
import {ModalChangeLiderComponent} from './modal-change-lider';
import {GrupoTbColaboradoresComponent} from './grupo-tb-colaboradores';
import {RelatorioRankingComponent} from './relatorio-ranking/relatorio-ranking.component';
import {MedalhaComponent} from './medalha/medalha.component';
import {ModalLockedComponent} from './modal-locked';
import {SubstituirLiderComponent} from './substituir-lider';
import {ListLideresComponent} from './substituir-lider/list-lideres';
import {ListGruposLiderComponent} from './substituir-lider/list-grupos-lider';
import {
  NotificacaoModalContatosComponent,
  NotificacaoModalLideresComponent,
  NotificacaoModalColaboradoresComponent
} from './notificacao';
import {SolicitacaoColaboradorComponent} from './solicitacao-colaborador';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    HomeComponent,
    GruposComponent,
    GrupoLideresComponent,
    HabilidadesComponent,
    NotificacaoComponent,
    ColaboradoresComponent,
    TbColaboradorComponent,
    TbLiderComponent,
    ModalFormColaboradorComponent,
    ImportComponent,
    SelecionaLideresComponent,
    GrupoColaboradoresComponent,
    ModalAdicionarLideresComponent,
    ModalAdicionarColaboradoresComponent,
    WizardComponent,
    ModalChangeLiderComponent,
    GrupoTbColaboradoresComponent,
    RelatorioRankingComponent,
    MedalhaComponent,
    ModalLockedComponent,
    SubstituirLiderComponent,
    ListLideresComponent,
    ListGruposLiderComponent,
    NotificacaoModalContatosComponent,
    NotificacaoModalLideresComponent,
    NotificacaoModalColaboradoresComponent,
    SolicitacaoColaboradorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    DragDropModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    ExportComponentsModule,
  ]
})
export class UniversidadeCorporativaModule {
}
