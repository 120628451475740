<div class="container-fluid p-3">
    <button (click)="close()" type="button" class="btn__close close" style="z-index: 999;" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>

    <!-- STEP 1 -->
    <div *ngIf="step == 1" class="p-3 col-12 container__import">
        <h5 class="font-title">Importe a lista de colaboradores</h5>

        <p class="descricao mt-4 mb-4 col-12 col-lg-10">Ao importar a lista de colaboradores, o processo todo será
            automatizado para facilitar para você. Você não precisará inserir um por um. Desta forma, todos serão
            notificados automaticamente pelo nosso sistema, podendo ingressar na nossa plataforma de forma ágil. Confira
            bem as informações, principalmente o campo de email de cada colaborador, pois é por este canal que eles
            receberão as instruções para realizarem a finalização do seu cadastro e também o download do APP
            Profissionais SA.</p>

        <div class="row col-12 col-lg-11 mb-4">
            <div class="explicacao mt-2 mt-lg-0">
                <div class="explicacao__icon flexFull">
                    <img src="../../../assets/icons/download_file.svg" alt="download_file">
                </div>
                <p class="descricao">Baixe aqui o <a class="download" (click)="downloadExample()">Arquivo Modelo</a> que
                    você seguirá.</p>
            </div>
            <div class="explicacao mt-3 mt-lg-0">
                <div class="explicacao__icon flexFull">
                    <img src="../../../assets/icons/list_check.svg" alt="lista">
                </div>
                <p class="descricao">Preencha as informações conforme o padrão indicado, sem alterar a ordem das
                    colunas.</p>
            </div>
            <div class="explicacao mt-3 mt-lg-0">
                <div class="explicacao__icon flexFull">
                    <img src="../../../assets/icons/checked.svg" alt="salve sua lista">
                </div>
                <p class="descricao">Salve o arquivo em Excel (formato .xlsx) e selecione ele no botão de "escolher
                    arquivo". Pronto, upload de colaboradores realizado.</p>
            </div>
        </div>


        <form [formGroup]="formGroup" class="mt-3 mb-3" (submit)="import()" novalidate>
            <div class="div__input__file form-group">
                <input (change)="onFileChange($event)" class="form-control-file" type="file" id="fileUploader"
                    name="validatedCustomFile" accept=".xls, .xlsx" />
            </div>
            <button [disabled]="formGroup.invalid" type="submit" class="btn bg-warning float-right">Importar</button>
        </form>

    </div>



    <!-- STEP 2 -->
    <div *ngIf="step == 2" class="p-3 col-12 container__colaboradores">

        <div>
            <button (click)="backToImport()" type="button" class="btn btn-warning btn-back-circle flexFull">
                <img src="../../../assets/icons/back.svg" alt="back">
            </button>

            <span *ngIf="invalids.length > 0" class="text-danger">Sua importação possui {{invalids.length}}
                colaboradores inválidos de {{colaboradoresRetorno.length}}.</span>

        </div>
        <div class="container__colaboradores__cabecalho text-left d-flex p-0 m-0">
            <label class="font-title text-uppercase flex2">Nome</label>
            <label class="font-title text-uppercase flex2">E-mail</label>
            <label class="font-title text-uppercase flex15 w-100">CPF</label>
            <label class="font-title text-uppercase flex1">Setor</label>
            <label class="font-title text-uppercase flex1">Cargo</label>
            <label class="font-title text-uppercase flex2">Erro</label>
        </div>

        <ul>
            <li [ngClass]="{'invalid': colaborador.erro}"
                *ngFor="let colaborador of colaboradoresRetorno.slice(page * limit, limit * (page + 1)), let index = index">
                <input class="flex2" placeholder="Nome completo *" type="text" name="nome_completo"
                    [(ngModel)]="colaborador.nome_completo">
                <input class="flex2" placeholder="E-mail *" type="email" name="email" [(ngModel)]="colaborador.email">
                <input class="flex15" placeholder="CPF *" type="text" name="cpf" [(ngModel)]="colaborador.cpf"
                    mask="000.000.000-00">
                <input class="flex1" placeholder="Setor" type="text" name="setor" [(ngModel)]="colaborador.setor">
                <input class="flex1" placeholder="Cargo" type="text" name="cargo" [(ngModel)]="colaborador.cargo">
                <span class="flex2">{{colaborador.erro}}</span>
            </li>
        </ul>

        <div class="d-flex flex-row-reverse justify-content-between align-items-center">
            <div class="pt-3">
                <button (click)="nextIgnoring(false)" class="btn bg-warning btn__step2 mr-3">Ignorar e importar</button>
                <button (click)="nextIgnoring(true)" class="btn bg-warning btn__step2">VALIDAR NOVAMENTE E
                    IMPORTAR</button>
            </div>


            <nav *ngIf="qtdePages?.length > 1">
                <ul class="pagination">

                    <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                        <a class="page-link">Anterior</a>
                    </li>

                    <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)"
                        class="page-item">
                        <a class="page-link">1</a>
                    </li>
                    <span *ngIf="(page + 1) > 4" class="px-2">...</span>

                    <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)"
                        class="page-item" *ngFor="let number of getPages()">
                        <a class="page-link">{{number+1}}</a>
                    </li>

                    <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
                    <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}"
                        (click)="changePage(qtdePages.length - 1)" class="page-item">
                        <a class="page-link">{{qtdePages.length}}</a>
                    </li>

                    <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                        <a class="page-link">Próxima</a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>


</div>


<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>