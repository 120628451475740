import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CachesService } from 'src/app/services/caches';
import { CommonService } from 'src/app/services/common';
import { EspecialistasService } from 'src/app/services/especialistas';
import { ExperienciasProfissionaisService } from 'src/app/services/experienciasProfissionais';
import { PalestrantesService } from 'src/app/services/palestrantes';

export interface IFormacao {
  cidade: string;
  cursando: number;
  data_fim: string;
  data_inicio: string;
  estado: string;
  id: number;
  local: string;
  palestrantes_id: number;
  titulo: string;
  data_formatada: string;
}
export interface IExperiencia {
  atual: number;
  cargo: string;
  cidade: string;
  data_fim: string;
  data_inicio: string;
  descricao: string;
  empresa: string;
  estado: string;
  id: number;
  pais: string;
  data_formatada: string;
}
export interface IDepoimento {
  data: string;
  descricao: string;
  empresa: string;
  nome: string;
  pais: string;
}
export interface IAssunto {
  descricao: string;
  foto: string;
  id: number;
  id_assunto_pai: number;
  item_pai: number;
  nome: string;
}

@Component({
  selector: 'app-meu-perfil-new',
  templateUrl: './meu-perfil-new.component.html',
  styleUrls: ['./meu-perfil-new.component.scss'],
})
export class MeuPerfilNewComponent implements OnInit, OnDestroy {
  loading: boolean = false;

  nota: Array<any> = [];
  palestrante: any = {
    nome_palestrante: '',
  };

  ministradas: Array<any> = [];
  disponiveis: Array<any> = [];
  formacoes: Array<IFormacao> = [];
  experiencias: Array<IExperiencia> = [];
  depoimentos: Array<IDepoimento> = [];
  assuntos: Array<IAssunto> = [];

  usuario: any;
  perfil: any;
  temas: any[] = [];
  propostasCount: number;
  palestrasDisponiveis: any[] = [];

  openedSection: string = '';

  profileCompletion;

  caches: Array<any> = [];

  profilePicture: string = 'assets/img/profile-default.png';

  constructor(
    private palestrantesService: PalestrantesService,
    private toast: ToastrService,
    private title: Title,
    private cachesService: CachesService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getPerfil();
    this.title.setTitle('Meu perfil - Dashboard');
  }

  ngOnDestroy(): void {
    this.title.setTitle('Dashboard');
  }

  async getPropostas(palestranteId: number) {
    const response = await this.palestrantesService.getPropostasByPalestranteId(
      palestranteId
    );

    if (response.length === 0) {
      return (this.propostasCount = response.length);
    }

    this.propostasCount =
      response.length < 10 ? `0${response.length}` : response.length;
  }

  async getPerfil() {
    try {
      let profilePercentageCompletion = 0;

      this.loading = true;
      const res = await this.palestrantesService.getMeuPerfil();
      this.palestrante = res.palestrante;

      this.getPropostas(this.palestrante.id);

      this.disponiveis = res.palestrasDisponiveis;
      this.ministradas = res.palestrasMinistradas;
      this.formacoes = res.formacao;
      this.depoimentos = res.depoimentos;
      this.experiencias = res.experiencia;
      this.assuntos = res.assuntos;

      this.getCaches();

      this.palestrasDisponiveis = res?.palestrasDisponiveis?.map((p) => ({
        ...p,
        descricao: `${p.descricao.substring(0, 150)}...`,
      }));

      this.usuario = JSON.parse(localStorage.getItem('usuario'));
      this.perfil = localStorage?.getItem('perfil');

      this.temas = this.palestrante?.tema?.split(',');

      const keys = Object.keys(res);
      keys.splice(keys.indexOf('depoimentos'), 1);
      keys.splice(keys.indexOf('palestrasMinistradas'), 1);

      keys.forEach((i) => {
        if (res[i].length > 0 || i === 'palestrante') {
          const result = 100 / keys.length;

          profilePercentageCompletion += result;
        }
      });

      this.profileCompletion =
        profilePercentageCompletion >= 100
          ? 100
          : profilePercentageCompletion.toFixed(0);

      this.loading = false;
      this.setNota();
    } catch (error) {
      this.loading = false;
      console.log(error);

      this.toast.error('Não foi possível carregar seu perfil');
    }
  }

  setNota() {
    const auxNota = this.palestrante.pontuacao;
    let size = Math.round(auxNota);
    size = auxNota > size ? size + 1 : size;

    this.nota = Array.from(Array(size).keys());
    this.nota[0] = 1;
    let lastValue = auxNota - this.nota[this.nota.length - 1];
    this.nota[this.nota.length - 1] = lastValue;
  }

  openSite() {
    window.open(
      this.palestrante.id_nossa_empresa == 4
        ? `https://www.profissionaissa.com/palestrante/${this.palestrante.slug}`
        : `https://base.profissionaissa.com/palestrante/${this.palestrante.slug}`,
      '_blank'
    );
  }

  msgEmDev() {
    this.toast.info('Em desenvolvimento.', 'Em breve traremos novidades.');
  }

  async sendPDFEmailPerfil() {
    try {
      await this.palestrantesService.sendPDFPalestrante(
        this.palestrante.slug,
        this.palestrante.id_nossa_empresa
      );
      this.toast.success(
        'PDF enviado para seu e-mail',
        'Fique atento a sua caixa de entrada e spam'
      );
    } catch (error) {
      this.toast.error('Não foi possível enviar o email');
    }
  }

  goToSpeakerPage() {
    window.open(
      `https://site.profissionaissa.com/palestrante/${this.palestrante.slug}`,
      '_blank'
    );
  }

  goToPalestraPage(palestraSlug: string) {
    window.open(
      `https://site.profissionaissa.com/palestrante/${this.palestrante.slug}/palestra/${palestraSlug}`,
      '_blank'
    );
  }

  openSection(sectionName: string) {
    this.openedSection = sectionName;
  }

  closeSection() {
    this.openedSection = '';
    this.getPerfil();
  }

  async changeProfileStatus(status: string) {
    try {
      await this.palestrantesService.updateProfileStatus(status);

      await this.getPerfil();

      if (status === '0') {
        return this.toast.success('Perfil despublicado com sucesso!');
      }

      return this.toast.success('Perfil publicado com sucesso!');
    } catch (error) {
      this.toast.error('Não foi possível publicar o perfil');
    }
  }

  async getCaches() {
    try {
      const res = await this.cachesService.getList();

      this.caches = res.caches;
      this.caches = [...this.caches, ...res.preCaches];
      for (const key in this.caches) {
        this.getDuracoes(parseInt(key));
        let i = this.caches[key];
        if (!i.principal) i.principal = 0;
        if (!i.id_palestrante) i.id_palestrante = this.palestrante.id;
      }
    } catch (error) {
      this.toast.error('Não foi possível carregar a lista');
    }
  }

  async getDuracoes(index: number) {
    try {
      const res: any = await this.commonService.getDuracoes(
        this.caches[index].id_casa_formato
      );
      this.caches[index].arrayDuracoes = res ? res.duracoes : [];
    } catch (error) {
      this.toast.error('Não foi possível carregar a lista de durações');
      this.caches[index].arrayDuracoes = [];
    }
  }
}
