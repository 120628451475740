import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PalestrasService } from 'src/app/services/palestras';

@Component({
  selector: 'app-palestras-gravadas',
  templateUrl: './palestras-gravadas.component.html',
  styleUrls: ['./palestras-gravadas.component.scss']
})
export class PalestrasGravadasComponent implements OnInit {

  contentsToDownload: Array<any> = []

  eventSelected: number = null;

  constructor(
    private palestrasService: PalestrasService,
    private toast: ToastrService,
    ) { }

  ngOnInit(): void {
    this.getTasks()
  }

  async getTasks() {
    try {
      let result: Array<any> = await this.palestrasService.getPrevious()
      this.contentsToDownload = result.filter((item) => (item.url_offline ? true : false));      
    } catch (error) {
      this.toast.error("Não foi possível carregar os conteúdos")
    }
  }
  
}
