<div class="d-flex justify-content-center align-items-start">
    <div class="d-flex" *ngFor="let step of steps">
        <div class="container__step">
            <div [ngClass]="{'step__previous' : selected > step.id, 'step__active': selected == step.id}" class="div__step">
                {{step.id}}
            </div>
            <p [ngClass]="{'active': selected == step.id}" class="name">{{step.name}}</p>
            <p [ngClass]="{'active': selected == step.id}" class="description">{{step.description}}</p>
        </div>
        <div *ngIf="step.id < steps.length" class="line"></div>
    </div>
</div>