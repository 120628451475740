import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PalestrasComponent } from './palestras/palestras.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';
import { ConfiguracoesModule } from '../configuracoes';
import { PalestranteModule } from '../palestrante';
import { NgxPaginationModule } from 'ngx-pagination';



@NgModule({
  declarations: [PalestrasComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ConfiguracoesModule,
    NgSelectModule,
    SharedModule,
    PalestranteModule,
    NgxPaginationModule,
  ],
})
export class CuradorModule { }
