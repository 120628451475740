<div class="container-fluid px-5">
  <div class="row mt-4">
    <angular-editor
      [(ngModel)]="htmlContent"
      [config]="config"
    ></angular-editor>
  </div>
  <div class="row py-5">
    <button class="btn__add" type="button" (click)="preSend()">Salvar</button>
  </div>
</div>
