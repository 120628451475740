import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(public http: HttpClient) {}

  async cadastrarUsuario(body: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .post<any>(`${environment.API_V2_URL_DEFAULT}admin/usuario`, body, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async getCuradores(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(`${environment.API_V2_URL_DEFAULT}curador`, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async getCuradorById(curadorId: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(`${environment.API_V2_URL_DEFAULT}curador/${curadorId}`, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async editarCurador(curadorId: string, body: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .put<any>(`${environment.API_V2_URL_DEFAULT}curador/${curadorId}`, body, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async deleteCurador(curadorId: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
    .delete<any>(`${environment.API_V2_URL_DEFAULT}curador/${curadorId}`, {
      headers: headers,
    })
    .toPromise();
  return result.body;
  }

  async getPrices(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(`${environment.API_V2_URL_DEFAULT}admin/payment-values`, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async editPrices(body: any, id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .put<any>(`${environment.API_V2_URL_DEFAULT}admin/payment-values/${id}`, body, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  getResultadosPesquisas() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    return this.http.get(`${environment.API_V2_URL}site/pesquisas`, { headers: headers });
  };
}
