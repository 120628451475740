import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EspectadoresService } from 'src/app/services/espectadores';

@Component({
  selector: 'app-pdi-list',
  templateUrl: './pdi-list.component.html',
  styleUrls: ['./pdi-list.component.scss']
})
export class PdiListComponent implements OnInit {

  trail: string = 'PDI'
  questions: Array<any> = [];
  isLoading: boolean = false;

  constructor(
    private espectadoresService: EspectadoresService,
    private router: Router,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getQuestionList()
  }

  async finish() {
    await this.espectadoresService.setUserTrail(this.trail);
    this.toastrService.success('Trilha Personalizada', 'Sua trilha foi configurada de acordo com suas respostas.')
    let espec = this.espectadoresService.getStorage()
    espec.trilha_tipo = "PDI"
    this.espectadoresService.setStorage(espec)
    this.router.navigate(['espectador', 'home']);
  }

  async getQuestionList() {
    this.isLoading = true
    this.questions = await this.espectadoresService.getQuestionsPdi() || []
    this.isLoading = false
    const countQuestions = this.questions.length
    this.questions = this.questions.length ? this.questions.filter((q) => !q.resposta) : []
    if (countQuestions && !this.questions.length) {
      this.finish()
    }
    for (let i = 0; i < this.questions.length; i++) {
      this.questions[i].ordem = i + 1
    }
  }


  public async navigateTo(question): Promise<void> {
    const params = {
      perguntaId: question.id,
      perguntas: JSON.stringify(this.questions)
    }
    this.router.navigate(['espectador', 'pdi-execution'], { queryParams: params })
  }
}
