<div
  style="
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  "
  *ngIf="loading"
>
<img
  src="assets/img/psaLoading.svg"
  alt="Carregando"
  title="Carregando"
/>
</div>

<div>
  <h1>Certificados</h1>

  <div class="filterContainer p-3">
    <section>
      <label for="nameFilter">Filtrar por email</label>
      <input name="nameFilter" id="nameFilter" [(ngModel)]="filterName" (change)="filterByName()" />
    </section>
  </div>

  <div *ngFor="let certificado of filtered" class="mb-3">
    <div class="container cardContainer">
      <p>Certificado: {{ certificado.eventName }} - {{ certificado.eventDate }} - {{ certificado.userEmail}}</p>
      <button (click)="downloadPDF(certificado.pdf)">Link para download</button>
    </div>
  </div>
</div>
