<div *ngIf="meses.length == 0">
    Nenhum evento
</div>

<div class="container" *ngFor="let mes of meses">
    <h5 class="palestra__title mes__title">{{mes.name == mesAtual ? 'Este mês' : mes.name}}</h5>

    <div *ngFor="let item of mes.values" class="bgd__foto" #selecionada
        [ngClass]="{ hideChildren : selecionada.class !== 'showChildren'}"
        [class.bgdWhite]="item.id_usuario == idUsuario" [style.backgroundImage]="'url(' + item.foto_palestrante +')'"
        [style.backgroundRepeat]="'no-repeat'" [style.backgroundPosition]="'100% 30%'" [style.backgroundSize]="'40%'">
        <div class="row mr-0 palestra bgd__foto">
            <div class="container__info col-12 col-md-9">

                <h5 class="palestra__title">{{item.nome}}</h5>

                <div class="d-flex justify-content-between">
                    <div class="d-flex flex-column">
                        <div class="content__tipo">
                            <span>{{item.nome_formato}}</span>
                        </div>
                        <div class="d-flex">
                            <span class="title__info">Tema:</span>
                            <span class="txt__info">{{item.assunto}}</span>
                        </div>
                        <div class="d-flex">
                            <span class="title__info">Público-alvo: </span>
                            <span class="txt__info">{{item.publico_alvo}}</span>
                        </div>
                        <!-- <div class="d-flex">
                        <span class="title__info">Tipo:</span>
                        <span class="txt__info">{{item.tipo}}</span>
                    </div> -->
                        <div *ngIf="item.id_usuario != idUsuario" class="d-flex">
                            <span class="title__info">Palestrante:</span>
                            <span class="txt__info txt__palestrante">{{item.palestrante}}</span>
                        </div>
                    </div>
                    <div *ngIf="item.id_usuario != idUsuario" class="d-flex justify-content-end align-items-center">
                        <button (click)="warningChangeProfile()"
                            class="btn__primary btn__animation__border">Assistir</button>
                    </div>
                </div>

                <div class="line__info flex-column flex-md-row child">
                    <div *ngIf="item.tipo.toUpperCase() == 'PRESENCIAL'">
                        <img src="assets/icons/pin.svg" alt="local">
                        <div class="d-flex flex-column align-items-start">
                            <p>{{item.local}}</p>
                            <p>{{item.endereco}}</p>
                            <p>{{item.estado}} | {{item.pais}}</p>
                        </div>
                    </div>
                    <div>
                        <img src="assets/icons/calendar.svg" alt="horario">
                        <div class="d-flex flex-column align-items-start">
                            <span>{{item.data_formatada}}</span>
                            <span>{{item.duracao_formatada}}</span>
                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="item.id_usuario != idUsuario" class="content__foto col-12 col-md-3">

                <img #more [ngClass]="{ rotate180 : selecionada.class != 'showChildren'}"
                    (click)="selecionada.class = selecionada.class == 'showChildren' ? '' :  'showChildren'"
                    class="icon__more" src="assets/icons/open_more.svg" alt="abrir" title="Ver mais">
            </div>
            <div *ngIf="item.id_usuario == idUsuario" class="content__action col-12 col-md-3">
                <div class="d-flex flex-column align-items-end">
                    <img #more [ngClass]="{ rotate180 : selecionada.class != 'showChildren'}"
                        (click)="selecionada.class = selecionada.class == 'showChildren' ? '' :  'showChildren'"
                        class="icon__more" src="assets/icons/open_more.svg" alt="abrir" title="Ver mais">
                </div>
                <div class="d-flex flex-column">
                    <button (click)="update(item.id)" class="btn">
                        <img src="assets/icons/editar.svg" alt="editar">
                        Editar
                    </button>
                    <button [routerLink]="['/palestrante/live/' + item.id]"
                        *ngIf="item.tipo.toUpperCase() != 'PRESENCIAL' && item.id_usuario == idUsuario" class="btn"
                        title="Entre ao vivo no seu evento">
                        <img src="assets/icons/play_outline.svg" alt="Iniciar live">
                        Transmitir
                    </button>
                </div>
                <div class="d-flex flex-column child">
                    <button class="btn">
                        <img src="assets/icons/image.svg" alt="Abrir imagem">
                        imagens
                    </button>
                    <button class="btn" title="Assistir vídeo">
                        <img src="assets/icons/video.svg" alt="Abrir vídeo">
                        vídeo
                    </button>
                    <button (click)="mediasDownload(item.id)" class="btn" title="Faça o download de suas mídias">
                        <img src="assets/icons/image.svg" alt="Faça o download de suas mídias">
                        Baixar mídias
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>