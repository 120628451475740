<div class="pl-lg-5 pr-lg-4">

    <app-wizard [itemSelected]="6"></app-wizard>

    <div class="row m-0 mt-4">

        <!-- TOTALIZADORES -->
        <div class="container__totalizadores w-100 mb-4">
            <div class="content__totalizadores">

                <div class="align-middle d-flex align-items-center">
                    <button (click)="openFilter()" type="button"
                        class="btn btn-warning btn-back-circle-middle float-left flexFull mr-3">
                        <img height="21" src="assets/icons/filter.svg" alt="filtros" title="Filtros">
                    </button>
                    <h3 class="d-inline font-title m-0">
                        Relatório - Ranking
                        <img class="icon" src="assets/icons/rank.svg" alt="Ranking">
                    </h3>
                </div>

                <div class="totalizador">
                    <div class="d-flex">
                        <img class="icon" src="assets/icons/company.svg" alt="Empresas">
                        <label class="tot__number">{{totalizadores?.count_usuarios_contratante}}</label>
                    </div>
                    <label class="font-title">Empresas</label>
                </div>
                <div class="totalizador">
                    <div class="d-flex">
                        <img class="icon" src="assets/icons/grupo.svg" alt="Grupos">
                        <label class="tot__number">{{totalizadores?.count_grupos}}</label>
                    </div>
                    <label class="font-title">Grupos</label>
                </div>
                <div class="totalizador">
                    <div class="d-flex">
                        <img class="icon" src="assets/icons/lider.svg" alt="Tutores">
                        <label class="tot__number">{{totalizadores?.count_usuarios_lider}}</label>
                    </div>
                    <label class="font-title">Tutores</label>
                </div>
                <div class="totalizador">
                    <div class="d-flex">
                        <img class="icon" src="assets/icons/people.svg" alt="Usuários">
                        <label class="tot__number">{{totalizadores?.count}}</label>
                    </div>
                    <label class="font-title">Usuários</label>
                </div>
                <div class="totalizador">
                    <div class="d-flex">
                        <img class="icon" src="assets/icons/skills.svg" alt="Competências">
                        <label class="tot__number">{{totalizadores?.count_competencias}}</label>
                    </div>
                    <label class="font-title">Competências</label>
                </div>
            </div>
        </div>
        <!-- END TOTALIZADORES -->

        <!-- FILTRO -->
        <section class="section__filter pl-0"
            [ngClass]="{'col-12 col-lg-3': isOpenFilters, 'div__filter__none': !isOpenFilters}">

            <div class="div__filter">
                <form (submit)="applyFilter()">
                    <h6 class="d-inline filter__title text-uppercase m-0">
                        <img class="mr-2" height="16" src="assets/icons/filter.svg" alt="filtros" title="Filtros">
                        Filtros
                    </h6>
                    <span class="float-right clear__filter" (click)="resetFilter()">
                        <img src="assets/icons/excluir.svg" alt="limpar filtro"> Limpar filtro
                    </span>

                    <div class="pt-2 mt-3">
                        <label class="filter__label" for="id_usuario_empresa">Empresa</label>

                        <ng-select [searchable]="true" [clearable]="true" bindLabel="id_usuario_empresa"
                            placeholder="Selecione" appendTo="body" notFoundText="Não encontrado"
                            [(ngModel)]="filterEmpresa" [ngModelOptions]="{standalone: true}">
                            <ng-option [value]="e.id" *ngFor="let e of empresas">
                                {{e.nome_fantasia ? e.nome_fantasia : e.empresa}}
                            </ng-option>
                        </ng-select>

                    </div>

                    <div class="pt-2">
                        <label class="filter__label" for="pesquisa">BUSCA POR USUÁRIO</label>
                        <input type="text" placeholder="Buscar" name="pesquisa" [(ngModel)]="filterUsuario" />
                    </div>

                    <div class="pt-2">
                        <label class="filter__label" for="id_grupo">Grupo</label>

                        <ng-select [searchable]="true" [clearable]="true" bindLabel="id_grupo" placeholder="Selecione"
                            appendTo="body" notFoundText="Não encontrado" [(ngModel)]="filterGrupo"
                            [ngModelOptions]="{standalone: true}">
                            <ng-option [value]="g.id" *ngFor="let g of grupos">
                                {{g.nome.substring(0,50)}}
                            </ng-option>
                        </ng-select>
                    </div>

                    <div class="pt-2">
                        <label class="filter__label" for="id_usuario_lider">Tutor</label>

                        <ng-select [searchable]="true" [clearable]="true" bindLabel="id_usuario_lider"
                            placeholder="Selecione" appendTo="body" notFoundText="Não encontrado"
                            [(ngModel)]="filterLider" [ngModelOptions]="{standalone: true}">
                            <ng-option [value]="l.id" *ngFor="let l of lideres">
                                {{l.nome}}
                            </ng-option>
                        </ng-select>
                    </div>

                    <div class="pt-2">
                        <label class="filter__label" for="id_competencia">Competência</label>

                        <ng-select [searchable]="true" [clearable]="true" bindLabel="id_competencia"
                            placeholder="Selecione" appendTo="body" notFoundText="Não encontrado"
                            [(ngModel)]="filterCompetencia" [ngModelOptions]="{standalone: true}">
                            <ng-option [value]="c.id" *ngFor="let c of competencias">
                                {{c.descricao}}
                            </ng-option>
                        </ng-select>
                    </div>

                    <button type="submit" class="btn__primary filter__btn">Aplicar filtros</button>
                </form>
            </div>
        </section>
        <!-- FILTRO -->

        <div [ngClass]="{'col-12 col-lg-9': isOpenFilters}" class="container__conteudo p-0 w-100">

            <section class="pt-4 mt-lg-0">
                <div class="flexFull">
                    <div *ngIf="loading" class="spinner-border text-warning" role="status">
                        <span class="sr-only">Buscando...</span>
                    </div>
                </div>


                <div class="row w-100 pl-5">
                    <div *ngIf="relatorio.length == 0 && !loading" class="flexFull mt-4">
                        <h6 class="text__empty">Nenhum usuário encontrado.</h6>
                    </div>

                    <div *ngIf="relatorio.length > 0 && !loading"
                        class="col-12 content__cabecalho text-left d-flex p-0">
                        <div class="flex01 w-100"></div>
                        <div class="flex2 d-flex">
                            <label class="font-title text-uppercase">USUÁRIO</label>
                            <div class="container__icons__order">
                                <img (click)="order('nome', false)" class="icon__asc" src="assets/icons/caret-down.svg"
                                    alt="ordenar crescente" title="ordenar por crescente">
                                <img (click)="order('nome', true)" class="icon__desc" src="assets/icons/caret-down.svg"
                                    alt="ordenar decrescente" title="ordenar por decrescente">
                            </div>
                        </div>
                        <div class="flex05 d-flex">
                            <img class="icon" src="assets/icons/score_star.svg" alt="Pontuação" title="Pontuação">
                            <div class="container__icons__order">
                                <img (click)="order('pontuacao', false)" class="icon__asc"
                                    src="assets/icons/caret-down.svg" alt="ordenar crescente"
                                    title="ordenar por crescente">
                                <img (click)="order('pontuacao', true)" class="icon__desc"
                                    src="assets/icons/caret-down.svg" alt="ordenar decrescente"
                                    title="ordenar por decrescente">
                            </div>
                        </div>
                        <div class="flex03 d-flex">
                            <img class="icon" src="assets/icons/progress.svg" alt="Nota" title="Nota">
                            <div class="container__icons__order">
                                <img (click)="order('nota', false)" class="icon__asc" src="assets/icons/caret-down.svg"
                                    alt="ordenar crescente" title="ordenar por crescente">
                                <img (click)="order('nota', true)" class="icon__desc" src="assets/icons/caret-down.svg"
                                    alt="ordenar decrescente" title="ordenar por decrescente">
                            </div>
                        </div>
                        <div class="flex1 d-none d-md-flex">
                            <label class="font-title text-uppercase">GRUPO</label>
                            <div class="container__icons__order">
                                <img (click)="order('grupo_nome', false)" class="icon__asc"
                                    src="assets/icons/caret-down.svg" alt="ordenar crescente"
                                    title="ordenar por crescente">
                                <img (click)="order('grupo_nome', true)" class="icon__desc"
                                    src="assets/icons/caret-down.svg" alt="ordenar decrescente"
                                    title="ordenar por decrescente">
                            </div>
                        </div>
                        <div class="flex1 d-none d-md-flex">
                            <label class="font-title text-uppercase">TUTOR</label>
                            <div class="container__icons__order">
                                <img (click)="order('lider_nome', false)" class="icon__asc"
                                    src="assets/icons/caret-down.svg" alt="ordenar crescente"
                                    title="ordenar por crescente">
                                <img (click)="order('lider_nome', true)" class="icon__desc"
                                    src="assets/icons/caret-down.svg" alt="ordenar decrescente"
                                    title="ordenar por decrescente">
                            </div>
                        </div>
                        <div class="flex1 d-none d-md-flex">
                            <label class="font-title text-uppercase">EMPRESA</label>
                            <div class="container__icons__order">
                                <img (click)="order('contratante', false)" class="icon__asc"
                                    src="assets/icons/caret-down.svg" alt="ordenar crescente"
                                    title="ordenar por crescente">
                                <img (click)="order('contratante', true)" class="icon__desc"
                                    src="assets/icons/caret-down.svg" alt="ordenar decrescente"
                                    title="ordenar por decrescente">
                            </div>
                        </div>
                    </div>

                    <ul class="col-12">
                        <li [class.gray]="(i % 2) != 0" *ngFor="let item of relatorio, let i = index">

                            <div class="d-flex content__list__item">
                                <div class="flex01 w-100">
                                    <app-medalha *ngIf="item.posicao" [posicao]="item.posicao"></app-medalha>
                                </div>
                                <label class="flex2 w-100">{{item.nome}}</label>
                                <label class="flex05 w-100">{{item.pontuacao}}</label>
                                <label class="flex03 w-100">{{item.nota}}</label>
                                <label class="flex1 w-100 d-none d-md-inline-flex">{{item.grupo_nome.length > 50 ?
                                    item.grupo_nome.substring(0,50) + '...' : item.grupo_nome}}</label>
                                <label class="flex1 w-100 d-none d-md-inline-flex">{{item.lider_nome}}</label>
                                <label class="flex1 w-100 d-none d-md-inline-flex">{{item.contratante}}</label>
                            </div>

                        </li>
                    </ul>
                </div>

            </section>
        </div>

    </div>

    <div class="d-flex justify-content-center align-items-center">
        <nav *ngIf="qtdePages?.length > 1" aria-label="Page navigation example">
            <ul class="pagination">
                <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                    <a class="page-link">Anterior</a>
                </li>

                <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)"
                    class="page-item">
                    <a class="page-link">1</a>
                </li>
                <span *ngIf="(page + 1) > 4" class="px-2">...</span>

                <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)"
                    class="page-item" *ngFor="let number of getPages()">
                    <a class="page-link">{{number+1}}</a>
                </li>

                <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
                <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}"
                    (click)="changePage(qtdePages.length - 1)" class="page-item">
                    <a class="page-link">{{qtdePages.length}}</a>
                </li>

                <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                    <a class="page-link">Próxima</a>
                </li>
            </ul>
        </nav>
    </div>
</div>

<app-medalha></app-medalha>