<div (click)="detail(skill.id_habilidade, skill.opacity, skill.habilidade_status)"
    [class.cursorInherit]="skill.opacity != 1 && (skill.habilidade_status !== 'INICIADA' && skill.habilidade_status !== 'FINALIZADA' && skill.habilidade_status !== 'AVALIADA')"
    [ngStyle]="{'opacity': (skill.habilidade_status == 'INICIADA' || skill.habilidade_status == 'FINALIZADA' || skill.habilidade_status == 'AVALIADA') ? 1 : skill.opacity}"
    class="container__skill">
    <figure>
        <img [src]="skill.habilidade_imagem_link" [alt]="skill.habilidade_titulo">
    </figure>
    <div class="content__skill__info d-flex flex-column">
        <h2 class="skill__competence">{{skill.competencia_nome}}</h2>
        <h5 class="font-title skill__title">{{skill.habilidade_titulo}}</h5>
    </div>
</div>