<main *ngIf="showForm && !loading">
  <label *ngIf="!noEmail" for="email">E-mail:</label>
  <input *ngIf="!noEmail" id="email" name="email" placeholder="Seu e-mail" [(ngModel)]="answer.userEmail" required />

  <label for="name">{{ labels['name'] }}</label>
  <input id="name" name="name" [(ngModel)]="answer.userName" required />

  <label for="avaliacaoSpeakerNew" *ngIf="!noAvaliation">{{ labels['performance'] }}</label>

  <select *ngIf="!noAvaliation" [(ngModel)]="answer.avaliacaoNew" id="avaliacaoSpeaker" name="avaliacaoSpeaker">
    <option [ngValue]="'Foi incrível, conectou demais'">{{ selectOptions['performance'][0] }}</option>
    <option [ngValue]="'Conectou, mas não foi incrível'">{{ selectOptions['performance'][1] }}</option>
    <option [ngValue]="'Mais ou menos, esperava mais'">{{ selectOptions['performance'][2] }}</option>
    <option [ngValue]="'Não conectou'">{{ selectOptions['performance'][3] }}</option>
  </select>

  <label for="accessContentNew">{{ labels['contentAccess'] }}</label>

  <select name="accessContentNew" id="accessContent" [(ngModel)]="answer.accessContentNew">
    <option [ngValue]="'Sim, quero! Adorei o(a) palestrante'">{{ selectOptions['accessContent'][0]}}</option>
    <option [ngValue]="'Sim, pois tenho interesse neste tema, mas preciso avaliar melhor o(a) palestrante'">{{ selectOptions['accessContent'][1]}}</option>
    <option [ngValue]="'No momento não'">{{ selectOptions['accessContent'][2]}}</option>
  </select>

  <label for="job">{{ labels['job'] }}</label>
  <select name="job" id="job" [(ngModel)]="answer.job" >
    <option [ngValue]="'CEO/Presidente(a)'">{{ selectOptions['jobs'][0] }}</option>
    <option [ngValue]="'Diretor(a)'">{{ selectOptions['jobs'][1] }}</option>
    <option [ngValue]="'Gerente'">{{ selectOptions['jobs'][2] }}</option>
    <option [ngValue]="'Coordenador(a)/Supervisor(a)'">{{ selectOptions['jobs'][3] }}</option>
    <option [ngValue]="'Analista'">{{ selectOptions['jobs'][4] }}</option>
    <option [ngValue]="'Assistente'">{{ selectOptions['jobs'][5] }}</option>
    <option [ngValue]="'Auxiliar'">{{ selectOptions['jobs'][6] }}</option>
    <option [ngValue]="'Professor'">{{ selectOptions['jobs'][7] }}</option>
    <option [ngValue]="'Outros'">{{ selectOptions['jobs'][8] }}</option>
  </select>

  <label *ngIf="!noPhone" for="phone">{{ labels['phone'] }}</label>
  <input *ngIf="!noPhone" id="phone" name="phone" [(ngModel)]="answer.phone" [mask]="'(00) 0 0000-0000'" [validation]="true" />

  <label for="theme">{{ labels['themes'] }}</label>
  <select name="theme" id="theme" [(ngModel)]="answer.theme">
    <option *ngFor="let theme of themes" [ngValue]="theme.value">{{theme.localization}}</option>
  </select>

  <label for="opinion" *ngIf="!noAvaliation">{{ labels['comments'] }}</label>
  <textarea id="opinion" *ngIf="!noAvaliation" name="opinion" [(ngModel)]="answer.opinion"></textarea>

  <div>
    <input type="checkbox" [(ngModel)]="answer.aceitaTermos" />
    <a class="ml-2">Aceito os termos de uso*</a>
    <br>
    <a class="ml-3" (click)="openOPT()">Ler termos de uso</a>
  </div>

  <p class="errorMessage" *ngIf="errorMessage">{{ errorMessage }}</p>
  <button (click)="saveAnswer()" [disabled]="isDisabled">Enviar</button>
</main>

<main *ngIf="showNotice && !loading && !isForeign">
  <h1>Recebemos sua resposta!</h1>
  <p>Em breve você receberá seu certificado por email.</p>
</main>
<main *ngIf="showNotice && !loading && isForeign">
  <h1>¡Recibimos tu respuesta!</h1>
  <p>Pronto recibirás tu certificado por correo electrónico.</p>
</main>

<div
  style="
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  "
  *ngIf="loading"
>
<img
  src="assets/img/psaLoading.svg"
  alt="Carregando"
  title="Carregando"
/>
</div>
