import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-aviso-tempo-live',
  templateUrl: './aviso-tempo-live.component.html',
  styleUrls: ['./aviso-tempo-live.component.scss']
})
export class AvisoTempoLiveComponent implements OnInit {

  @Input("mensagem") mensagem: string
  @Input("submensagem") submensagem: string

  constructor() { }

  ngOnInit() {
  }

}
