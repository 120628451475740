import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-conteudo',
  templateUrl: './list-conteudo.component.html',
  styleUrls: ['./list-conteudo.component.scss']
})
export class ListConteudoComponent implements OnInit {

  @Input() contents: Array<any> = []
  @Input() title: string = ""
  @Input() fullScreen: boolean = false

  eventSelected: number = null;

  constructor() { }

  ngOnInit(): void {
  }

  selectToShowEvent(e: any) {
    this.setEventSelected(e.id)
  }

  setEventSelected(value: number) {
    this.eventSelected = value
  }
}
