import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { EspectadoresService } from 'src/app/services/espectadores';
import { ModalCertificadoComponent } from '../../live/espectador/modal-certificado';

interface IOptions {
  title: string
  description: string
}

@Component({
  selector: 'app-trilha-personalizada',
  templateUrl: './trilha-personalizada.component.html',
  styleUrls: ['./trilha-personalizada.component.scss']
})
export class TrilhaPersonalizadaComponent implements OnInit {

  isIOS: boolean = false
  isAndroid: boolean = false
  bsModalRef: BsModalRef;
  options: Array<IOptions> = [
    {
      title: "Trilha Padrão",
      description: "Você tem acesso a cursos rápidos e microaulas pré-definidas. Os conteúdos são uma fusão de competências e habilidades do profissional do futuro."
    },
    {
      title: "PDI",
      description: "Sua trilha será montada automaticamente após você responder um questionário. O seu Plano de Desenvolvimento Individual será definido com base nas habilidades que você precisa reforçar."
    },
    {
      title: "Personalizado",
      description: "Monte livremente a sua trilha com base nos seus principais interesses. Escolha as competências e habilidades que mais desejar reforçar e insira elas na sua trilha personalizada."
    },
  ]

  constructor(
    private espectadoresService: EspectadoresService,
    private toastrService: ToastrService,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    let userAgent = navigator.userAgent
    if (userAgent.includes("Android") && userAgent.includes("Mobile")) this.isAndroid = true
    if (userAgent.includes("Ios") || userAgent.includes("iPhone") && userAgent.includes("Mobile")) this.isIOS = true
  }

  openModalCustomTrail() {
    const initialState = {
      isIOS: this.isIOS,
      isAndroid: this.isAndroid,
      mensagem: "Personalizada",
      submensagem: "Para personalizar sua trilha baixe o app PROFISSIONAIS SA"
    };
    const config = { class: 'modal-login', initialState }
    this.bsModalRef = this.modalService.show(ModalCertificadoComponent, config);
  }

  async finish(item: IOptions) {
    let type = "DEFAULT";
    const initialState = {
      mensagem: item.title,
      submensagem: item.description
    }
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();

      if (this.bsModalRef.content.value) {
        this.espectadoresService.setUserTrail(type)
          .then(() => {
            let espec = this.espectadoresService.getStorage()
            espec.trilha_tipo = "DEFAULT"
            this.espectadoresService.setStorage(espec)
            this.toastrService.success("Trilha configurada com sucesso")
            this.router.navigate(["espectador", "home"])
          })
      }
    })
  }

  selectOption(item: IOptions) {
    switch (item.title) {
      case "Personalizado":
        this.openModalCustomTrail();
        break;
      case "PDI":
        this.router.navigate(["espectador", "trilha-personalizada", "pdi"])
        break;
      case "Trilha Padrão":
        this.finish(item);
        break;

      default:
        break;
    }
  }
}
