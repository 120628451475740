import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { phoneMask } from 'src/app/utils/formatters/string.formatter';
import { IInvalid } from '../../../configuracoes/palestrante/tb-dados';

interface IUsuario {
  id: number;
  photo: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  whatsapp: string;
  email: string;
  nome: string;
  telefone: string;
  sobrenome: string;

}

interface IPalestrante {
  id: number;
  consultor: string;
  influenciador: string;
  mentor: string;
  palestrante: string;
  trainer: string;
  minibio: string;
  preco: string;
  renomado: string;
  slug: string;
  titulo: string;
  usuario_id: number;
  video: string;
  ja_palestrou: number;
  palestra_gratuita: number;
  autorizar_perfil: number;
  email_oportunidade: number;
}

@Component({
  selector: 'app-dados',
  templateUrl: './dados.component.html',
  styleUrls: ['./dados.component.scss'],
})
export class DadosComponent implements OnInit {
  @Input() profilePicture: string = 'assets/img/profile-default.png';
  @Output() onClose = new EventEmitter();

  loading: boolean = false;
  invalids: Array<IInvalid> = [];
  usuario: IUsuario = {
    id: 0,
    photo: '',
    linkedin: '',
    facebook: '',
    instagram: '',
    whatsapp: '',
    telefone: '',
    email: '',
    nome: '',
    sobrenome: '',
  };
  palestrante: IPalestrante = {
    id: 0,
    consultor: '',
    influenciador: '',
    mentor: '',
    palestrante: '',
    trainer: '',
    minibio: '',
    preco: '',
    renomado: '',
    slug: '',
    titulo: '',
    usuario_id: 0,
    video: '',
    ja_palestrou: 0,
    palestra_gratuita: 0,
    autorizar_perfil: 0,
    email_oportunidade: 0,
  };

  constructor(
    private palestrantesService: PalestrantesService,
    private toast: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getDados();
  }

  async getDados() {
    const res = await this.palestrantesService.getDados();
    this.usuario = res.usuario;
    this.profilePicture = this.usuario.photo
    this.palestrante = res.palestrante;
  }

  formatMinibio(value: string) {
    return value.replace(/\\n/g, '<br />');
   }

  async submit() {
    this.invalids = [];
    try {
      this.loading = true;
      this.palestrante.minibio = this.formatMinibio(this.palestrante.minibio);
      const res = await this.palestrantesService.updateDadosPalestrante(
        this.usuario,
        this.palestrante
      );
      this.loading = false;
      this.toast.success('Salvo com sucesso!');

      console.log('reload')
      window.location.reload()
      // this.router.navigate(['palestrante/perfil/temas'])
      this.close();
    } catch (error) {
      this.loading = false;
      if (error.error.message.invalids) {
        this.invalids = error.error.message.invalids;
        this.toast.error('Campos inválidos!', 'Por favor, revise.');
      } else {
        this.toast.error(
          'Não foi possível salvar os dados',
          'Falha na conexão'
        );
      }
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key];
      if (item['campo'] == value) return true;
    }
    return false;
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key];
      if (item['campo'] == value) return item['erro'];
    }
    return '';
  }

  changeVideo() {
    let v =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    this.palestrante.video = this.palestrante.video.match(v)
      ? RegExp.$1
      : this.palestrante.video;
  }

  close() {
    this.onClose.emit('');
  }

  changePhoto(event: any) {
    this.profilePicture = event;
  }

  formatPhone(value: string) {
    this.usuario.telefone = '';
    return (this.usuario.telefone = phoneMask(value));
  }
}
