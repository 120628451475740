<h1>Depoimentos para {{palestrante}}</h1>
<owl-carousel-o [options]="customOptionsDepoimentos">
    <ng-template carouselSlide *ngFor="let depoimento of depoimentos">
        <div class="psa-showcase-depoimento">
            <div class="header col1">"</div>
            <div class="col2">
                <div class="depoimento">"{{depoimento.descricao}}"</div>
                <div class="avatar">
                    <img src="assets/icons/person.svg" />
                </div>
                <div class="cliente">
                    <h1>{{getNome(depoimento.nome)}}</h1>
                    <p>{{depoimento.empresa}}</p>
                </div>
            </div>
        </div>
    </ng-template>
</owl-carousel-o>