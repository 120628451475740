// TO-DO: IMPLEMENTAR THUMBNAIL DOS VIDEOS, TALVEZ TENHA Q FAZER ISSO NO BACK
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Videos } from 'src/app/interfaces/videos';
import { TbsbService } from 'src/app/services/tbsb/tbsb.service';
import { MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/tooltip';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'tbsb-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss', '../tbsb.component.scss'],
  viewProviders: [MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class VideosComponent implements OnInit {
  videos: Videos[] = [];

  // filters
  filterStatus: string = 'aprovado';
  categoryFilter: string = '';

  categories: Array<string> = ['Inovação', 'Empreendedorismo', 'Vendas', 'Educação', 'Comunicação', 'Inteligência Artificial e novas tecnologias', 'Motivação', 'Gestão de pessoas e Liderança', 'Saúde física e mental', 'Diversidade e inclusão', 'Espiritualidade', 'Creator Economy'];

  //pagination
  size: number = 10;
  page: number = 0;
  totalPages: number = 0;
  length = 100;

  pageEvent: PageEvent;

  constructor(
    private router: Router,
    private VideosService: TbsbService,
  ) { }

  ngOnInit(): void {
    // Initialize component
    this.getVideos();
  }

  changePage(event: PageEvent): void {
    console.log('changing page')
    console.log(event);
    this.page = event.pageIndex;
    this.getVideos();
  }

  getVideos(): void {
    // Get videos
    const category = this.categoryFilter === 'todas' ? '' : this.categoryFilter;
    console.log(category)
    this.VideosService.getVideos(this.page, this.size, category, this.filterStatus, '').subscribe((res: any) => {
      console.log(res);
      this.videos = res.content;
      this.page = res.page;
      this.totalPages = res.totalPages;
      this.size = res.size;
      this.length = 10 * res.totalPages;
    });
  }

  openVideoDetails(video: any): void {
    // Open video details
    console.log(video);
    this.router.navigate(['/admin/tbsb/videos', video.id]);
  }
}
