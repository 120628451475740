import { Component, OnInit } from '@angular/core';
import { PalestrasService } from 'src/app/services/palestras';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalOrcarComponent } from "src/app/common/modal-orcar";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

export interface ITema {
  id: number
  nome: string
  descricao: string
  foto: string
}

@Component({
  selector: 'app-temas',
  templateUrl: './temas.component.html',
  styleUrls: ['./temas.component.scss']
})
export class TemasComponent implements OnInit {
  bsModalRef: BsModalRef;

  loading: boolean = false;
  isOpenFilters: boolean = false;
  temas: Array<ITema> = []
  select_temas: Array<ITema> = []

  id_tema: number = 0
  search: string = ''

  constructor(
    private modalService: BsModalService,
    private toast: ToastrService,
    private router: Router,
    private palestrasService: PalestrasService) { }

  ngOnInit(): void {
    this.palestrasService.getTemas(false, 0, '').then(res => {
      this.select_temas = res
    })
    this.getTemas(false)
  }

  getTemas(has_filter: boolean) {
    this.loading = true
    this.palestrasService.getTemas(has_filter, this.id_tema, this.search).then(res => {
      this.loading = false
      this.temas = res
    }).catch(error => {
      this.loading = false
      this.toast.error("Não foi possível carregar temas.")
    })
  }

  orcar() {
    this.bsModalRef = this.modalService.show(ModalOrcarComponent);
  }

  goTema(nome: string) {
    this.router.navigate(['/palestras/tema/', this.palestrasService.slugify(nome.toLowerCase())])
  }

  openFilter() {
    this.isOpenFilters = !this.isOpenFilters
  }

  applyFilter() {
    this.getTemas(true)
  }
}
