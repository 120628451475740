<div class="container-fluid">
    <div class="row mx-1 mx-md-5">

        <app-wizard class="col-12 col-md-10" [id]="3"></app-wizard>
        <div class="col-12 col-md-2 container__btn__add"></div>

        <div class="col-12 col-md-6 mt-3">

            <div class="container__default pr-3">

                <h4 class="font-title mb-2">Habilidade</h4>

                <div *ngIf="loading" class="flexFull">
                    <app-spinner></app-spinner>
                </div>

                <form *ngIf="habilidade" class="row">
                    <div class="form-group col-8">
                        <label class="label-input" for="titulo">Título *</label>
                        <input disabled="{{!isOwner}}" id="titulo" [class.invalid]="isIncorrect('titulo')"
                            class="form-control input__default" name="titulo" type="text"
                            [(ngModel)]="habilidade.titulo" placeholder="Título *" maxlength=100
                            [ngModelOptions]="{standalone: true}">
                        <span class="error">{{getError('titulo')}}</span>
                    </div>

                    <div class="form-group col-4">
                        <label class="label-input" for="status">status *</label>
                        <select disabled="{{!isOwner}}" id="status" class="form-control" name="status"
                            placeholder="Status *" [class.invalid]="isIncorrect('status')"
                            [(ngModel)]="habilidade.status" [ngModelOptions]="{standalone: true}">
                            <option *ngFor="let i of arrayStatus" [value]="i.value">{{i.text}}</option>
                        </select>
                        <span class="error">{{getError('status')}}</span>
                    </div>

                    <div class="form-group col-12">
                        <label class="label-input" for="resumo">resumo *</label>
                        <p *ngIf="!isOwner" [innerHTML]="habilidade.resumo"></p>
                        <ckeditor *ngIf="isOwner" name="resumo" [(ngModel)]="habilidade.resumo"></ckeditor>
                        <span class="error">{{getError('resumo')}}</span>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="label-input" for="id_assunto">tema *</label>
                        <ng-select class="custom" [disabled]="!isOwner" [readonly]="!isOwner"
                            [class.invalid]="isIncorrect('id_assunto')" [searchable]="true" [clearable]="true"
                            bindLabel="nome" bindValue="id" placeholder="Selecione *" notFoundText="Não encontrado"
                            [(ngModel)]="habilidade.id_assunto" [items]="temas" [ngModelOptions]="{standalone: true}">
                        </ng-select>
                        <span class="error">{{getError('id_assunto')}}</span>
                    </div>

                    <div class="col-12 col-md-6 form-group">
                        <label class="label-input" for="id_casa_status">complexidade *</label>
                        <select disabled="{{!isOwner}}" id="id_casa_status" class="form-control" name="id_casa_status"
                            placeholder="Principal *" [class.invalid]="isIncorrect('id_casa_status')"
                            [(ngModel)]="habilidade.id_casa_status" [ngModelOptions]="{standalone: true}">
                            <option value="null">Selecione *</option>
                            <option *ngFor="let i of complexidades" [value]="i.id_status">{{i.status}}</option>
                        </select>
                        <span class="error">{{getError('id_casa_status')}}</span>
                    </div>

                    <div class="col-12 form__input__image">
                        <div class="form-group">
                            <label class="label-input" for="new_image_src">Imagem *</label>
                            <input [disabled]="!isOwner || loadingVideo" id="new_image_src"
                                [class.invalid]="isIncorrect('image_src')" class="form-control input__default"
                                name="new_image_src" type="file" accept="image/*" (change)="onFileImage($event)"
                                [(ngModel)]="new_image_src">
                            <span class="error">{{getError('image_src')}}</span>

                            <progress-bar *ngIf="loadingImg" [progress]="progress" [color]="'green'">
                            </progress-bar>
                        </div>
                        <img [src]="habilidade.image_src" [alt]="habilidade.titulo">
                    </div>

                    <div class="col-12 form-group">
                        <label class="label-input" for="arquivo_principal">arquivo principal *</label>
                        <select disabled="{{!isOwner}}" id="arquivo_principal" class="form-control"
                            name="arquivo_principal" placeholder="Principal *"
                            [class.invalid]="isIncorrect('arquivo_principal')"
                            [(ngModel)]="habilidade.arquivo_principal" [ngModelOptions]="{standalone: true}">
                            <option value="null">Selecione *</option>
                            <option *ngFor="let i of principais" [value]="i.value">{{i.text}}</option>
                        </select>
                        <span class="error">{{getError('arquivo_principal')}}</span>
                    </div>

                    <div class="col-12 form__input__image">
                        <div class="form-group">
                            <label class="label-input" for="new_video_src">Vídeo *</label>
                            <input [disabled]="!isOwner || loadingVideo" id="new_video_src"
                                [class.invalid]="isIncorrect('video_link')" class="form-control input__default"
                                name="new_video_src" type="file" accept="video/mp4,video/x-m4v,video/*"
                                (change)="onFileVideo($event)">
                            <span class="error">{{getError('video_link')}}</span>
                            <progress-bar *ngIf="loadingVideo" [progress]="progress" [color]="'green'">
                            </progress-bar>
                        </div>
                        <video class="mt-2" *ngIf="habilidade.video_link" width="100%" height="100%" controls
                            controlsList="nodownload">
                            <source [src]="habilidade.video_link">
                        </video>
                    </div>

                    <div class="col-12 form__input__image">
                        <div class="form-group">
                            <label class="label-input" for="new_pdf_src">PDF *</label>
                            <input [disabled]="!isOwner || loadingVideo" id="new_pdf_src"
                                [class.invalid]="isIncorrect('pdf_link')" class="form-control input__default"
                                name="new_pdf_src" type="file" accept="application/pdf" (change)="onFilePDF($event)">
                            <span class="error">{{getError('new_pdf_src')}}</span>
                            <progress-bar *ngIf="loadingPDF" [progress]="progress" [color]="'green'">
                            </progress-bar>
                        </div>
                        <div *ngIf="habilidade.pdf_link && habilidade.pdf_link.length > 0" class="content__anchor__pdf">
                            <a target="_blank" [href]="getPDF()">Abrir PDF</a>
                        </div>
                    </div>

                    <div *ngIf="isOwner" class="col-12 form-group d-flex justify-content-end mt-2 mb-0">
                        <button [disabled]="getBtnDisabled()" (click)="submit()" class="btn__primary">Salvar</button>
                    </div>
                </form>
            </div>

        </div>

        <div class="col-12 col-md-6 mt-3 container__default">

            <app-perguntas #perguntas *ngIf="habilidade" [isOwner]="isOwner" [idHabilidade]="habilidade.id">
            </app-perguntas>

        </div>

    </div>
</div>