import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit {

  @Input('mensagem') mensagem: string;
  @Input('submensagem') submensagem: string;
  value: boolean;

  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit(): void {
    window.onkeypress = (event) => { if (event.keyCode == 13) this.returnValue(true) }
  }

  returnValue(v: boolean) {
    this.value = v;
    this.bsModalRef.hide()
  }

}
