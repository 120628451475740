<div class="modal-header">
  <div class="depoimentos">
    <div *ngFor="let depoimento of depoimentos">
      <div class="depoimento__container">
        <p>"{{ depoimento.descricao }}"</p>
        <p>- {{ depoimento.nome }} | {{ depoimento.data }}</p>
      </div>
    </div>
  </div>
  <div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
  </div>
</div>
<div class="modal-body">
  <div class="psa-form-group">
      <button (click)="bsModalRef.hide()">OK</button>
  </div>
</div>


<!-- nome
data
empresa
cidade
descricao -->
