
<div *ngIf="loadingPage" class="loadingPage">
  <img src="assets/img/psaLoading.svg" alt="Carregando" />
</div>

<form
  *ngIf="!loadingPage"
  #form="ngForm"
  class="row"
  (submit)="submit()"
  novalidate
>
  <div class="col-12 col-sm-5 col-md-4 col-lg-3 mb-5">
    <!-- Foto de perfil -->
    <app-profile-picture
      [foto]="user?.photo"
      (refresh)="refreshPhoto()"
    ></app-profile-picture>

    <!-- Indicação -->
    <div class="cardBorder mt-3">
      <div>
        <h3 class="title title--smaller">Código de indicação:</h3>

        <div class="d-flex align-items-center">
          <p id="codigoIndicacao" class="mb-0 colorFontSecondary">
            {{user?.id}}
          </p>

          <button
            (click)="copyClipboard(user.id)"
            class="btn colorFontTertiary linkSimple ml-2"
            type="button"
            title="Copiar código de indicação"
          >
            copiar
          </button>
        </div>
      </div>

      <!-- TODO - Novo layout propõe que seja mostrado o nome de quem indicou -->
      <div *ngIf="user.codigo_indicacao" class="mt-3">
        <h3 class="title title--smaller">Fui indicado por:</h3>

        <p class="mb-0">{{user.codigo_indicacao || 'Não há indicação'}}</p>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-7 col-md-8 col-lg-9">
    <section>
      <h3 class="text-uppercase title title--smaller colorFontSecondary mb-4">
        Sobre você
      </h3>

      <div class="row">
        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="nome">Nome *</label>
          <input
            [(ngModel)]="user.nome"

            name="nome"
            class="form-control inputCustomBorder"
            type="text"
            placeholder="Nome *"
            required
          >
          <span class="error">{{getError('nome')}}</span>
        </div>

        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="sobrenome">Sobrenome</label>
          <input
            [(ngModel)]="user.sobrenome"

            name="sobrenome"
            class="form-control inputCustomBorder"
            type="text"
            placeholder="Sobrenome"
          >
          <span class="error">{{getError('sobrenome')}}</span>
        </div>

        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="cpf">CPF *</label>
          <input
            [(ngModel)]="user.cpf_cnpj"
            mask="000.000.000-00"
            name="cpf_cnpj"
            class="form-control inputCustomBorder"
            type="text"
            placeholder="CPF *"
            required
          >
        </div>

        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="email">E-mail *</label>
          <input
            [(ngModel)]="user.email"

            name="email"
            class="form-control inputCustomBorder"
            type="text"
            placeholder="E-mail *"
            disabled
            required
          >
          <span class="error">{{getError('email')}}</span>
        </div>

        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="nome">Telefone *</label>
          <input
            [(ngModel)]="user.telefone"
            name="telefone"
            class="form-control inputCustomBorder"
            type="text"
            placeholder="Telefone *"
            mask="(00) 0 0000-0000||(00) 0000-0000"
            required
          >
          <span class="error">{{getError('telefone')}}</span>
        </div>
      </div>
    </section>

    <hr>

    <section>
      <h3 class="text-uppercase title title--smaller colorFontSecondary mb-4">
        Informações corporativas
      </h3>

      <div class="row">
        <div *ngIf="cargos" class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="cargo">Cargo Atual *</label>
          <select
            [(ngModel)]="user.id_cargo"
            id="cargo"
            name="cargo"
            class="form-control inputCustomBorder"
            placeholder="Cargo Atual *"
            [value]="user?.id_cargo"
            required
          >
            <option disabled>Selecione *</option>
            <option
              *ngFor="let cargo of cargos"
              [value]="cargo.id"
              [selected]="cargo.id == user?.id_cargo"
            >
              {{cargo.descricao}}
            </option>
          </select>
        </div>

        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="departamento">Departamento</label>
          <select
            [(ngModel)]="user.departamento_id"
            [value]="user.departamento_id"
            id="departamento"
            name="departamento"
            placeholder="Departamento"
            class="form-control inputCustomBorder"
          >
            <option disabled>Selecione</option>
            <option
              *ngFor="let departamento of departamentos"
              [value]="departamento.id"
              [selected]="departamento.id == user.departamento_id"
            >
              {{departamento.descricao}}
            </option>
          </select>
        </div>
      </div>

      <div *ngIf="objetivos" class="mt-2">
        <h3 class="labelCustom my-2">Quais seus objetivos?</h3>

        <div class="row">
          <div *ngFor="let objetivo of objetivos" class="col-12">
            <input
              [(ngModel)]="objetivo.isChecked"
              type="checkbox"
              class="checkboxCustom"
              [name]="'objetivo'+objetivo.id"
              [id]="'objetivo'+objetivo.id"
              [checked]="objetivo.isChecked == 1"
              (change)="updateObjetivo(objetivo)"
            />

            <label for="objetivos">
              {{objetivo.descricao}}
            </label>
          </div>
        </div>
      </div>

      <div *ngIf="temas" class="mt-4">
        <h3 class="labelCustom my-2">Quais temas você tem interesse?</h3>

        <div class="row">
          <div *ngFor="let tema of temas" class="col-12 col-lg-5 col-xl-4">
            <input
              [(ngModel)]="tema.isChecked"
              type="checkbox"
              class="checkboxCustom"
              [name]="'tema'+tema.id"
              [id]="'tema'+tema.id"
              [checked]="tema.isChecked == 1"
              (change)="updateTema(tema)"
            />

            <label for="temas">
              {{tema.nome}}
            </label>
          </div>
        </div>
      </div>
    </section>

    <hr>

    <section>
      <h3 class="text-uppercase title title--smaller colorFontSecondary mb-4">
        Seu endereço
      </h3>

      <div class="row">
        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="pais">País de residência *</label>
          <input
            [(ngModel)]="user.pais"
            name="pais"
            placeholder="País de residência *"
            class="form-control inputCustomBorder"
            type="text"
            required
          >
          <span class="error">{{getError('pais')}}</span>
        </div>

        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="cep">CEP *</label>
          <input
            [(ngModel)]="user.cep"
            (change)="completeCEP()"
            mask="00000-000"

            name="cep"
            placeholder="CEP *"
            class="form-control inputCustomBorder"
            type="text"
            required
          >
          <span class="error">{{getError('cep')}}</span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="estado">Estado *</label>
          <select
            [(ngModel)]="user.estado"
            id="estado"

            name="estado"
            placeholder="Estado *"
            class="form-control inputCustomBorder"
            required
          >
            <option disabled>Selecione *</option>
            <option *ngFor="let estado of arrayStates" [value]="estado.uf">{{estado.estado}}</option>
          </select>
          <span class="error">{{getError('estado')}}</span>
        </div>

        <div class="form-group col-12 col-lg-5">
          <label class="labelCustom" for="cidade">Cidade *</label>
          <input
            [(ngModel)]="user.cidade"
            name="cidade"
            placeholder="Cidade *"

            class="form-control inputCustomBorder"
            type="text"
            required
          >
          <span class="error">{{getError('cidade')}}</span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-lg-2">
          <label class="labelCustom" for="number">Número *</label>
          <input
            [(ngModel)]="user.numero"
            name="number"
            placeholder="Número *"

            class="form-control inputCustomBorder"
            type="text"
            required
          >
          <span class="error">{{getError('numero')}}</span>
        </div>

        <div class="form-group col-12 col-lg-4">
          <label class="labelCustom" for="bairro">Bairro *</label>
          <input
            [(ngModel)]="user.bairro"
            name="bairro"
            placeholder="Bairro *"
            class="form-control inputCustomBorder"
            type="text"
            required
          >
          <span class="error">{{getError('bairro')}}</span>
        </div>

        <div class="form-group col-12 col-lg-4">
          <label class="labelCustom" for="logradouro">Logradouro (Rua) *</label>
          <input
            [(ngModel)]="user.logradouro"
            name="logradouro"
            placeholder="Logradouro (Rua)"
            class="form-control inputCustomBorder"
            type="text"
            required
          >
          <span class="error">{{getError('logradouro')}}</span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-lg-10">
          <label class="labelCustom" for="complemento">Complemento</label>
          <input
            [(ngModel)]="user.complemento"
            name="complemento"
            placeholder="Complemento"
            class="form-control inputCustomBorder"
            type="text"
          >
        </div>
      </div>
    </section>

    <div class="text-right col-lg-10 mt-4">
      <button
        class="btnCustom btnCustom--primary ml-auto"
        type="submit"
        [title]="form.valid ? 'Salvar dados' : 'Preencha os campos obrigatórios'"
        [disabled]="!form.valid"
      >
        <span *ngIf="loading" class="colorFontSecondary">
          Carregando <img height="18" src="assets/img/simple_loading.gif" alt="Carregando">
        </span>

        <span *ngIf="!loading">
          Salvar dados
        </span>
      </button>
    </div>
  </div>
</form>
