import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GrupoService } from '../../../services/grupo';
import { ToastrService } from 'ngx-toastr';
import { IGrupoLideres } from '../grupo-lideres';

@Component({
  selector: 'app-modal-change-lider',
  templateUrl: './modal-change-lider.component.html',
  styleUrls: ['./modal-change-lider.component.scss']
})
export class ModalChangeLiderComponent implements OnInit {

  lideres: Array<IGrupoLideres>;
  lider_atual: IGrupoLideres;
  lider_novo: IGrupoLideres = {
    id: null, id_colaborador: null, nome: '', descricao: '', ativo: '', id_contratante: null, id_lider: null, id_cargo_lider: null, id_usuario_lider: null, nome_lider: null, email_lider: null, isLider: null, grupos: null
  };
  id_grupo: number;

  loading: boolean = false;
  success: boolean = false;
  qtdePages: Array<number> = [];
  page: number = 0;
  search: string = "";

  constructor(
    public bsModalRef: BsModalRef,
    private grupoService: GrupoService,
    private toast: ToastrService,) { }

  ngOnInit(): void {
    this.getLideres();
  }

  async getLideres() {
    const res = await this.grupoService.getGrupoLideres(this.id_grupo, this.page, this.search)
      .catch((error) => {
        console.log(error);
        return;
      });
    this.setQtdePages(res.quantidade)

    this.lideres = res.lideres ? res.lideres.filter((i: IGrupoLideres) => (i.id_colaborador != this.lider_atual.id_colaborador)) : [];
    this.lideres.map(i => i.isLider = i.id_colaborador == this.lider_novo.id_colaborador)
  }

  async salvar() {
    try {
      if (!this.lider_novo.id_colaborador || this.lider_novo.id_colaborador < 1) {
        this.toast.error("Selecione um tutor substituto")
        return
      }
      this.loading = true
      const res = await this.grupoService.updateTutorColaboradoresCollection(this.id_grupo, this.lider_atual, this.lider_novo)
      this.loading = false
      this.success = true
      this.toast.success("Tutor excluído do grupo.")
      this.bsModalRef.hide()
    } catch (error) {
      this.loading = false
      this.toast.error("Não foi possível trocar os tutores.")
    }
  }

  changePage(number: number) {
    this.page = number;
    this.getLideres()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array(qtdeColaboradores);
  }

  buscar() {
    this.page = 0
    this.getLideres()
  }

  seleciona(lider: IGrupoLideres) {
    this.lider_novo = lider
    this.lideres.map(i => i.isLider = i.id_colaborador == this.lider_novo.id_colaborador)
  }
}
