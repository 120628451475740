<div class="container__conteudo col-12 col-lg-12">
    <h4 class="d-inline-block font-title">Notificar tutores
    </h4>
    <div class="close pull-right ml-3" aria-label="Close" (click)="bsModalRef.hide()">
        <span style="cursor: pointer; font-size: 2rem;" aria-hidden="true">&times;</span>
    </div>

    <div class="d-lg-flex justify-content-between align-items-end">
        <div style="flex: 2;" class="container__search col-12 col-lg-5 d-flex">
            <img src="assets/icons/search-black.svg" alt="ícone pesquisar">
            <input style="flex: 1;" class="container__search__input form-group m-0 border-0 bg-transparent"
                placeholder="Buscar..." type="text" name="search" [(ngModel)]="search" (ngModelChange)="buscar()">
        </div>

        <div class="d-flex">
            <label class="container__checkbox container__checkbox__abled label-input">Enviar
                para todos?
                <input (ngModelChange)="bsModalRef.hide()" type="checkbox" [(ngModel)]="sendAll">
                <span class="checkmark"></span>
            </label>
        </div>
    </div>

    <div class="content__cabecalho text-left d-flex p-0">
        <label class="flex03"></label>
        <label class="font-title text-uppercase flex2">NOME</label>
        <label class="font-title text-uppercase flex2">E-MAIL</label>
    </div>

    <ul>
        <li [ngClass]="{ added : lider.added}" *ngFor="let lider of lideres">

            <div class="d-flex content__list__item">
                <div class="flex03">
                    <label class="container__checkbox mb-0">
                        <input type="checkbox" (change)="add(lider.id_colaborador)" [(ngModel)]="lider.added">
                        <span class="checkmark"></span>
                    </label>
                </div>

                <div class="flex2 w-100 item__nome">
                    <input class="input__disable" type="text" [(ngModel)]="lider.nome_completo" disabled="disabled">
                </div>

                <div class="flex2">
                    <input class="input__disable" type="text" [(ngModel)]="lider.email" disabled="disabled">
                </div>


            </div>

        </li>
    </ul>

    <p *ngIf="lideres?.length == 0">Nenhum tutor cadastrado.</p>

    <div class="d-flex flex-row-reverse justify-content-between align-items-end">

        <button (click)="save()" class="btn__primary">SALVAR</button>

        <nav *ngIf="qtdePages?.length > 1">
            <ul class="pagination">
                <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                    <a class="page-link">Anterior</a>
                </li>

                <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)"
                    class="page-item">
                    <a class="page-link">1</a>
                </li>
                <span *ngIf="(page + 1) > 4" class="px-2">...</span>

                <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)"
                    class="page-item" *ngFor="let number of getPages()">
                    <a class="page-link">{{number+1}}</a>
                </li>

                <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
                <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}"
                    (click)="changePage(qtdePages.length - 1)" class="page-item">
                    <a class="page-link">{{qtdePages.length}}</a>
                </li>

                <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                    <a class="page-link">Próxima</a>
                </li>
            </ul>
        </nav>
    </div>
</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>