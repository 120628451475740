import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { arrayPorte, arrayEstados } from '../../common/array-selects';
// import { CepService } from '../../services/cep.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { CommonService, ISegmento } from 'src/app/services/common';
import { estados } from 'src/app/common/array-estados';

@Component({
  selector: 'app-passo1',
  templateUrl: './passo1.component.html',
  styleUrls: ['./passo1.component.scss']
})
export class Passo1Component implements OnInit {
  form: FormGroup;
  submitted = false;
  mensagem: string;

  // portes = arrayPorte;
  segmentos: Array<ISegmento> = [];
  classes: Array<any> = [];
  estados: Array<any> = estados;

  constructor(
    private titleService: Title,
    private toast: ToastrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private commonService: CommonService) { }

    ngOnInit(): void {
      this.titleService.setTitle("Calculadora");

      this.commonService.getSegmentos().then(res => { this.segmentos = res })
      this.commonService.getClassesHabitante().then(res => { this.classes = res })

      const usuario = JSON.parse(window.localStorage.getItem("usuario"));
      const calculadora = JSON.parse(window.localStorage.getItem("calculadora-contratante"));

      this.form = this.formBuilder.group({
        classe: [calculadora?.classe ? calculadora.classe : '', Validators.required],
        uf: [usuario.estado ? usuario.estado : '', Validators.required],
      });

    }

    get f() { return this.form.controls; }

    async submit() {
      this.mensagem = '';
      this.submitted = true;

      if (this.form.invalid) return false;

      try {

        const resCarteira = await this.commonService.getCarteiraByUF(this.form.value.uf)
        const usuario = JSON.parse(window.localStorage.getItem("usuario"));

        let info = this.form.value
        info.carteira = resCarteira.descricao
        info.email = usuario.email
        localStorage.setItem('calculadora-contratante', JSON.stringify(info))
        this.router.navigate(['/calculadora/passo2'])

      } catch (error) {
        this.form.controls.cep.setErrors({ valid: true });
        this.toast.error("Erro ao buscar CEP")
        return;
      }
    }

    getStyle(field: string) {
      const invalidStyle = { 'border': 'solid 1px red' };
      return (this.submitted && this.f[field].invalid) ? invalidStyle : {}
    }
  }
