import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GrupoService } from 'src/app/services/grupo';

@Component({
  selector: 'app-substituir-lider',
  templateUrl: './substituir-lider.component.html',
  styleUrls: ['./substituir-lider.component.scss']
})
export class SubstituirLiderComponent implements OnInit {

  colaborador: any
  newLider: any
  grupoUsuario: any
  step: number = 1

  loading: boolean = false

  constructor(
    public bsModalRef: BsModalRef,
    private grupoService: GrupoService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {

  }

  back() {
    this.step--
    if (this.step == 1) this.newLider = null
    if (this.step == 2) this.grupoUsuario = null
  }

  liderSeleted(e) {
    this.newLider = e.lider
    this.step++
  }

  grupoSeleted(e) {
    this.grupoUsuario = e.grupoUsuario
    this.step++
  }

  async submit() {
    try {
      this.loading = true
      await this.grupoService.changeLider(this.grupoUsuario.id, this.colaborador.id_colaborador)
      this.toast.success("Tutor substituído com sucesso!")
      this.loading = false
      this.bsModalRef.hide()
    } catch (e) {
      this.toast.error(e.error.message)
      this.loading = false
    }
  }
}
