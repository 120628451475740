<section>

    <div *ngIf="loading" class="flexFull">
        <app-spinner></app-spinner>
    </div>

    <article *ngIf="palestra" [style.backgroundImage]="'url(' + palestra.photo +')'" class="container__palestrante">

        <div class="bgd__foto py-2" [ngClass]="{'pl-3': source == 'internal'}">

            <figure style="height: 16px; width: 18px;" *ngIf="!source" (click)="back.emit()">
                <img style="height: 16px; width: 18px; object-fit: fill;" height="100%" width="100%"
                    src="assets/icons/back.svg" alt="voltar">
            </figure>



            <header class="px-0 mb-4">
                <span class="text__description">Palestra</span>
                <h2 class="text__title mb-0">{{palestra.titulo}}</h2>
                <span class="text__description">{{palestra.subtitulo}}</span>
                <div class="content__tipo">
                    <span>{{palestra.nome_formato}}</span>
                </div>
            </header>

            <div class="content__palestra">
                <p *ngIf="palestra.publico_alvo" class="text__description mb-0">
                    <span class="title__info">Público-alvo: </span> {{palestra.publico_alvo}}
                </p>
                <p class="text__description mb-0">
                    <span class="title__info">Tema: </span> {{palestra.assunto}}
                </p>
                <p class="text__description mb-0">
                    {{palestra.descricao}}
                </p>
            </div>



            <div class="palestra__location d-flex align-items-center">
                <img src="assets/icons/calendar.svg" alt="data">
                <span class="text__description">{{dataScreen}} - Duração: {{palestra.duracao}} min</span>
            </div>
            <div *ngIf="!isOnline" class="palestra__location d-flex">
                <img src="assets/icons/pin.svg" alt="local">
                <div class="d-flex flex-column">
                    <span class="text__description">{{palestra.local}}</span>
                    <span class="text__description">{{palestra.endereco}}</span>
                    <span class="text__description">{{palestra.estado}} | {{palestra.pais}}</span>
                </div>
            </div>

            <div *ngIf="isSubscribed && !isFinished" class="flexFull my-4" style="flex: 1">
                <button *ngIf="isSubscribed && !isOnline" class="btn__secondary">
                    INSCRIÇÃO REALIZADA
                </button>
                <button [routerLink]="['/espectador/live/' + palestra.id]" *ngIf="isOnline && isStarted"
                    class="btn__primary">
                    <img src="assets/icons/play_outline.svg" alt="Iniciar live">
                    LIVE
                </button>
                <button *ngIf="isOnline && !isStarted" class="btn__secondary">
                    <img src="assets/icons/play_outline.svg" alt="Iniciar live">
                    LIVE NÃO INICIOU
                </button>
            </div>
            <div *ngIf="!isSubscribed" class="flexFull my-4" style="flex: 1">
                <button (click)="subscribe()" *ngIf="!isFinished" class="btn__primary">
                    PARTICIPAR DA PALESTRA
                </button>
            </div>
            <div *ngIf="isFinished" class="flexFull my-4" style="flex: 1">
                <button (click)="generateCertificate()" *ngIf="isSubscribed" class="btn__outline">
                    <img src="assets/icons/certificate.svg" alt="Certificado">
                    Certificado
                </button>

                <button (click)="play(palestra.url_offline, palestra.titulo)" *ngIf="palestra.url_offline"
                    class="btn__outline">
                    <img src="assets/icons/play_outline.svg" alt="Assista a palestra">
                    Assistir palestra
                </button>
            </div>


            <div class="d-flex flex-column mt-4">
                <h1 class="text__title mb-0">
                    {{palestra?.nome_palestrante}}
                </h1>
            </div>

            <p style="padding-right: 10%;" class="text__description">
                {{
                palestra.minibio.length > 250
                ? palestra.minibio.substring(0,250) + '...'
                : palestra.minibio
                }}
            </p>

            <button [routerLink]="['/especialistas/' + palestra.slug]" class="btn__linkedin text-uppercase">
                ver perfil completo
            </button>

        </div>
    </article>

</section>