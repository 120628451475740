import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppComponent } from 'src/app/app.component';
import { estados } from 'src/app/common/array-estados';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { ICargo } from 'src/app/pages/universidade-corporativa';
import { EspectadoresService } from 'src/app/services/espectadores';
import { UserService } from 'src/app/services/user';
import { ViacepService } from 'src/app/services/viacep';
import { Router } from '@angular/router';
export interface IInvalid {
  campo: string
  erro: string
}

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
})
export class PersonalDataComponent implements OnInit {
  bsModalRef: BsModalRef;
  loading: boolean = false;
  loadingPage: boolean = false;
  arrayStates = estados;
  departamentos: Array<any>;
  temas: Array<any>;
  cargos: Array<ICargo>;
  user: any;
  invalids: Array<IInvalid> = [];
  objetivos: any;

  constructor(
    private cepService: ViacepService,
    private userService: UserService,
    private espectadoresService: EspectadoresService,
    private modalService: BsModalService,
    private app: AppComponent,
    private toast: ToastrService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.loadingPage = true;

    this.getUser();
    await this.getCargos();
    await this.getDepartamentos();
    await this.getTemas();

    this.loadingPage = false;
  }

  getUser() {
    this.user = JSON.parse(localStorage.getItem('espectador'));
    this.objetivos = this.user.objetivos;
  }

  async getCargos() {
    try {
      this.cargos = await this.userService.getCargos();
    } catch (error) {
      this.toast.error('Houve um erro ao carregar os cargos.', 'Oops...');
    }
  }

  async getDepartamentos() {
    try {
      this.departamentos = await this.userService.getDepartamentos();
    } catch (error) {
      this.toast.error('Houve um erro ao carregar os departamentos.', 'Oops...');
    }
  }

  async getTemas() {
    await this.userService.getTemas()
      .then(res => { this.temas = res; })
      .catch(error => {
        this.toast.error('Houve um erro ao carregar os temas.', 'Oops...');
      })
  }

  async updateTema(tema:any) {
    tema.isChecked = tema.isChecked == true ? 1 : 0;

    await this.userService.updateTema({id:tema.id, isChecked: tema.isChecked, tagRd: tema.tag_rd})
      .then(res => {
        this.toast.success('Temas alterados com sucesso.', 'Sucesso!');
      })
      .catch(error => {
        this.toast.error('Houve um erro ao atualizar os seus temas. Tente novamente mais tarde.', 'Oops...');
      })
  }

  async getObjetivos() {
    await this.userService.getObjetivos()
      .then(res => { this.objetivos = res; })
      .catch(error => {
        this.toast.error('Houve um erro ao carregar os temas.', 'Oops...');
      })
  }

  async updateObjetivo(objetivo:any) {
    objetivo.isChecked == true ? 1 : 0;

    await this.userService.updateObjetivo({id: objetivo.id, isChecked: objetivo.isChecked, tagRd: objetivo.tag_rd})
      .then(res => {
        this.objetivos = res;
        this.toast.success('Objetivos alterados com sucesso.', 'Sucesso!');
      })
      .catch(error => {
        this.toast.error('Houve um erro ao atualizar os seus objetivos. Tente novamente mais tarde.', 'Oops...');
      })
  }

  async submit() {
    this.loading            = true;
    this.user.cargo         = null;
    this.user.departamento  = null;

    await this.espectadoresService.saveProfile(this.user)
      .then(res => {
        this.toast.success('Seus dados foram atualizados.', 'Sucesso!');
        this.app.refreshInfoGlobal();
        this.espectadoresService.getProfile().then(espectador => {
          if(!espectador.user.trilha_tipo){
            this.router.navigate(['espectador', 'trilha-personalizada'])
          }
        });
      })
      .catch(error => {
        this.toast.error(
          error.error.message || "Não foi possível alterar os seus dados.",
          'Oops, algo deu errado...'
        );
      })

    this.loading = false;
  }

  async copyClipboard(text: any) {
    await navigator.clipboard.writeText(text);
    this.toast.success('Copiado para a área de transferência');
  }

  async completeCEP() {
    if (this.user.cep?.length === 8) {
      const initialState = {
        mensagem: 'Completar endereço com CEP?',
        submensagem: '',
      };

      const config = { initialState };
      this.bsModalRef = this.modalService.show(
        ModalConfirmationComponent,
        config
      );

      let newSubscriber = this.modalService.onHide.subscribe(() => {
        newSubscriber.unsubscribe();
        if (this.bsModalRef.content.value) this.completeAddressByCEP();
      });

    } else {
      this.toast.error('CEP inválido.');
    }
  }

  async completeAddressByCEP() {
    try {
      const resCEP = await this.cepService.getCEP(this.user.cep);
      if (resCEP.logradouro) {
        this.user.bairro = resCEP.bairro;
        this.user.cidade = resCEP.localidade;
        this.user.logradouro = resCEP.logradouro;
        this.user.bairro = resCEP.bairro;
        this.user.estado = resCEP.uf;
      } else {
        this.toast.error(
          'Não foi possível preencher os campos com o CEP.',
          'Revise o CEP informado.'
        );
      }
    } catch (error) {
      this.toast.error(
        'Não foi possível preencher os campos com o CEP.',
        'Revise o CEP informado.'
      );
    }
  }

  // TODO - isso é necessário?
  refreshPhoto() {
    this.espectadoresService
      .getProfile()
      .then((res: { user }) => (this.user.photo = res.user.photo));
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key];
      if (item['campo'] == value) return true;
    }
    return false;
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key];
      if (item['campo'] == value) return item['erro'];
    }
    return '';
  }

  pushInvalid(fieldName: string, text: string, isDefaultText: boolean) {
    this.invalids.push({
      campo: fieldName,
      erro: !isDefaultText ? text : `Campo ${text} deve ser preenchido`,
    });
  }
}
