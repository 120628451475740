import { Component, Input, OnInit } from '@angular/core';
import { EntryComponent } from 'src/app/entry/entry.component';
import { AppComponent } from 'src/app/app.component';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalConfirmationComponent } from '../../common/modal-confirmation';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { getPathPerfil, getPathAgenda, getPathHome, getPathNotifications } from 'src/app/controle-perfil';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  bsModalRef: BsModalRef;
  @Input('showToogle') showToogleContratante: boolean = false;
  nome: String
  menu: Array<any> = [
    {
      id: 1,
      route:getPathNotifications(),
      icon: "assets/icons/alert.svg",
      title: "Ir para Notificações"
    },
    {
      id: 2,
      route: getPathAgenda(),
      icon: "assets/icons/calendar.svg",
      title: "Ir para Calendário"
    },
    {
      id: 3,
      route: getPathPerfil(),
      icon: "assets/icons/setting.svg",
      title: "Ir para Configurações"
    },
  ]
  currentRoute: string;

  constructor(
    private router: Router,
    private entry: EntryComponent,
    private app: AppComponent,
    private toast: ToastrService,
    private modalService: BsModalService,) {
      this.currentRoute = "";
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
        }

        if (event instanceof NavigationEnd) {
            // Hide progress spinner or progress bar
            this.currentRoute = event.url.split("/")[2];
        }
    });
    }

  ngOnInit(): void {
    const usuario = JSON.parse(window.localStorage.getItem("usuario"))
    this.nome = usuario.nome;
    this.showToogleContratante = !this.entry.showSide;
  }

  msgEmDev() {
    this.toast.info('Em desenvolvimento.', 'Em breve traremos novidades.')
  }

  logout() {
    const initialState = { mensagem: "Deseja realmente deslogar?", submensagem: "" }

    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(() => {

      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value
      value == true ? true : false;

      if (value == true) this.app.logout();
    })
  }

  showSide() {
    this.entry.closeSide(false);
  }

  go(path: string) {
    if (!path) {
      this.msgEmDev()
      return
    }

    this.router.navigate([path])
  }

  home() {
    this.router.navigate([getPathHome()]);
  }
}
