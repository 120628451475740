<main>
  <h1>Tabela de Precificação</h1>

  <div>
    <table class="customTable">
      <thead>
        <th>Valor</th>
        <th>Porcentagem (1 CNPJ)</th>
        <th>Porcentagem (2 CNPJ)</th>
        <th>Salvar</th>
      </thead>
      <tbody>
        <tr *ngFor="let price of prices; index as i" [id]="price.id">
          <td>
            <input type="text" [(ngModel)]="newPrices[i].value_title" (change)="editValue(price.id)" />
          </td>
          <td>
            <input type="number" [(ngModel)]="newPrices[i].percentage_single" (change)="editValue(price.id)">
          </td>
          <td>
            <input type="number" [(ngModel)]="newPrices[i].percentage_multiple" (change)="editValue(price.id)">
          </td>
          <td>
            <button type="button" (click)="savePrices()">Salvar</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <span>** Os valores percentuais estão representados em forma decimal</span>
</main>
