import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { PalestrasService } from 'src/app/services/palestras';
import { ModalPalestraExtraComponent } from '../modal-palestra-extra/modal-palestra-extra.component';
@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss']
})
export class AgendaComponent implements OnInit, OnDestroy {
  bsModalRef: BsModalRef;

  loading: boolean = false
  getAll: boolean = true
  palestras: Array<any> = []
  palestrasAnteriores: Array<any> = []
  palestrasDay: Array<any> = []
  meses: Array<any> = []
  idUsuario: number = 0
  mesAtual: string = ""

  selectedDay: Date = new Date()

  eventCompFull: boolean = false;

  constructor(
    private palestrantesService: PalestrantesService,
    private palestrasService: PalestrasService,
    private toast: ToastrService,
    private title: Title,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.title.setTitle("Eventos - Dashboard")
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    this.idUsuario = usuario.id
    this.getPalestras()
  }

  ngOnDestroy(): void {
    this.title.setTitle("Dashboard")
  }

  async getPalestras() {
    try {
      this.loading = true
      const res = await this.palestrasService.getPalestrasAgenda(this.getAll)
      this.palestras = res.palestras
      this.mesAtual = res.mesAtual

      this.palestras.forEach(i => { new Date(i.data) == this.selectedDay })

      this.selected(this.selectedDay)
      this.setGroupMonth()

      this.loading = false
    } catch (error) {
      this.toast.error("Não foi possível carregar a agenda")
      this.loading = false
    }
  }

  setGroupMonth() {
    let setMeses = new Set(this.palestras.map(item => item.mes));
    this.meses = [];
    setMeses.forEach(g => {
      this.meses.push({
        name: g,
        values: this.palestras.filter(i => i.mes == g)
      })
    });
  }

  selected(data: any) {
    this.selectedDay = data
    this.palestrasDay = this.palestras.filter(i => new Date(i.data).setHours(0, 0, 0, 0) == this.selectedDay.setHours(0, 0, 0, 0))
  }

  setFullScreen(e: any) {
    this.eventCompFull = e.fullscreen
  }

  async addEvent() {
    if (await this.verificaPlano()) {
      this.router.navigate(['/palestrante/evento/', 0])
    }
  }

  async verificaPlano(): Promise<boolean> {
    try {
      const res = await this.palestrantesService.verificaPlano()

      if (!(res.palestras_disponiveis > 0)) {
        const config = {}
        this.bsModalRef = this.modalService.show(ModalPalestraExtraComponent, config);

        let newSubscriber = this.modalService.onHide.subscribe(() => {
          newSubscriber.unsubscribe();
          if (this.bsModalRef.content.success)
            return this.addEvent()
          else
            return false
        }) 

      } else {
        return true
      }

    } catch (error) {

      return false
    }
  }
}
