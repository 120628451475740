export const currencyFormat = (value: number) => {
  const currency = value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 3,
  });

  return currency.substring(0, currency.length - 1);
};

export function cpfCnpjMask(document: string | number) {
  let value = typeof document === 'number' ? document.toString() : document;

  value = value.replace(/[^\d]/g, '');

  if (value.length === 11) {
    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  } else if (value.length === 14) {
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }

  return value;
}

export function phoneMask(phone: string | number) {
  let value = typeof phone === 'number' ? phone.toString() : phone;

  value = value.replace(/[^\d]/g, '');

  return value.replace(/(\d{2})(\d{4,5})(\d{4})/g, '($1) $2-$3');
}

export function getDate(date: string) {
  const newDate = date.replace(/(\d+[/])(\d+[/])/, '$2$1');
  return new Date(newDate);
}
