import { Routes } from '@angular/router';

import { PerfilComponent } from './perfil';
import { PerfilPalestranteComponent } from './perfil-palestrante';
import { PerfilEspectadorComponent } from './perfil-espectador';
import { UserSettingsComponent } from './user-settings/user-settings.component';

export const ConfiguracoesRoutes: Routes = [
  {
    path: 'perfil',
    component: PerfilComponent
  },
  {
    path: 'palestrante/perfil',
    component: PerfilPalestranteComponent
  },
  {
    path: 'espectador/perfil',
    component: UserSettingsComponent
  },
];
