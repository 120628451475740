<section *ngIf="!isRegistration" class="page login">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="login__container py-5">
          <h1 class="font-weight-bold text-center">
            Bem-vindo a Profissionais SA.
          </h1>

          <form class="login__container__form mt-4">
            <div class="form-group">
              <input
                [class.invalid]="emailInvalid"
                class="form-control custom-input"
                type="email"
                name="email"
                placeholder="E-mail"
                [(ngModel)]="email"
              />
            </div>

            <div class="form-group d-flex align-items-center">
              <input
                [class.invalid]="senhaInvalid"
                class="form-control custom-input"
                [type]="showPass ? 'text' : 'password'"
                name="senha"
                placeholder="Senha"
                [(ngModel)]="senha"
              />
              <img
                [class.none]="showPass"
                class="show"
                (click)="setShow()"
                src="assets/icons/eye.svg"
                alt="mostrar senha"
                title="Mostrar senha"
              />
              <img
                [class.none]="!showPass"
                class="show"
                (click)="setShow()"
                src="assets/icons/closed-eye.svg"
                alt="mostrar senha"
                title="Mostrar senha"
              />
            </div>

            <div class="form-group" *ngIf="isPrimeiroAcesso">
              <input
                class="form-control custom-input"
                type="text"
                name="token_acesso"
                placeholder="Token de acesso"
                [(ngModel)]="token_acesso"
              />
            </div>

            <label class="login__container__form__mensagem pl-2">{{
              mensagem
            }}</label>

            <div class="form-group">
              <button
                *ngIf="!loading"
                class="d-block w-100 border-0 rounded text-uppercase bg-warning font-weight-bold py-2"
                type="submit"
                (click)="login()"
              >
                Entrar
              </button>
              <button
                *ngIf="loading"
                class="d-block w-100 border-0 rounded text-uppercase bg-warning font-weight-bold py-2"
                type="submit"
                (click)="login()"
              >
                <img
                  height="40px"
                  src="../../../assets/img/simple_loading.gif"
                  alt="loading"
                />
              </button>
            </div>
          </form>

          <div class="text-center">
            <a
              class="tex-decoration-none text-dark my-5 d-block"
              (click)="redefinirSenha(email)"
              >Esqueceu sua senha.
              <span style="text-decoration: underline; cursor: pointer"
                >Clique aqui</span
              ></a
            >
            <h3 class="login__container__subtitle">Novo por aqui?</h3>
            <h3 class="login__container__subtitle">Cadastre-se</h3>
          </div>

          <button
            (click)="queroCadastrar()"
            class="d-block w-100 py-2 mt-4 border rounded border-dark bg-transparent text-uppercase font-weight-bold"
          >
            Quero me cadastrar
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
<app-lock-screen [canclose]="1" *ngIf="lockScreen == true"></app-lock-screen>
<div *ngIf="isRegistration" style="padding: 20px">
  <app-registration 
    (onGoBack)="isRegistration = false"
  ></app-registration>
</div>
