import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { OpenaiService } from "src/app/services/openai/openai.service";
import { PalestrantesService } from "src/app/services/palestrantes";
import { promptCases, promptCompararPalestrantes, promptCompleteProfile, promptDefesa, promptProfile, promptThemeImportants } from "src/app/utils/prompts";

import {map, startWith} from 'rxjs/operators';
import { Observable } from "rxjs/internal/Observable";

@Component({
  selector: 'app-search-home',
  templateUrl: './search-home.component.html',
  styleUrls: ['./search-home.component.scss']
})

export class SearchHomeComponent implements OnInit {
  defesaInput: string = '';
  defesaAnswer: string = '';

  searchInput: string = '';
  searchAnswer: string = '';
  searchImportant: string = '';
  searchSpeakers: string = '';
  searchInfluencers: string = '';
  searchCases: string = '';
  searchDbSpeakers: string = '';
  completeProfile: string = '';
  smallProfile: string = '';
  searchCompare: string = '';

  themes: any = [];

  defesaForms: FormGroup;

  searchControl = new FormControl();
  options: any = [
    {name: 'Mary'},
    {name: 'Shelley'},
    {name: 'Igor'}
  ];
  filteredOptions: Observable<any>;

  panelOpenState: boolean = false;

  loading: boolean = false;
  constructor(
    private openaiService: OpenaiService,
    private palestrantesService: PalestrantesService,
    private router: Router,
    private toast: ToastrService,
  ) {}

  ngOnInit(): void {
    this.createForms();
    this.getTemas();
  }

  displayFn(theme: any): string {
    return theme && theme.nome ? theme.nome : '';
  }

  private _filter(name: string): any {
    console.log(name);
    const filterValue = name.toLowerCase();

    return this.themes.filter(option => option.nome.toLowerCase().indexOf(filterValue) === 0);
  }

  createForms(): void {
    this.defesaForms = new FormGroup({
      palestrante: new FormControl('', [Validators.required]),
      tema: new FormControl('', [Validators.required]),
      briefing: new FormControl('', [Validators.required])
    });
  }

  async getSpeakerProfile(input: string): Promise<any> {
    try {
      const res = await this.openaiService.getProfileByName(input);

      return res.body;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async generalSearch(): Promise<void> {
    try {
      this.loading = true;
      const res = await this.openaiService.getChatData(this.searchInput);

      localStorage.setItem('answer', JSON.stringify(res));
      console.log(res);
      this.searchAnswer = res;

      this.loading = false;
      this.redirect('generalSearch');
    } catch (e) {
      this.loading = false;
      this.toast.error('Não foi possível completar a solicitação');
    }
  }

  async getThemeImportants(): Promise<void> {
    try {
      this.loading = true;
      const res = await this.openaiService.getChatData(promptThemeImportants(this.searchImportant));
      localStorage.setItem('answer', JSON.stringify(res));
      console.log(res);

      this.loading = false;
      this.redirect('themeImportants');
    } catch (e) {
      this.loading = false;
      this.toast.error('Não foi possível completar a solicitação.');
    }
  }

  async getThemeSpeakers(): Promise<void> {
    try {
      this.loading = true;
      const prompt = 'Liste 5 especialistas brasileiros renomados que palestram sobre o tema' + this.searchSpeakers;
      const res = await this.openaiService.getChatData(prompt);
      localStorage.setItem('answer', JSON.stringify(res));
      console.log(res);

      this.loading = false;
      this.redirect('themeSpeakers');
    } catch (e) {
      this.loading = false;
      this.toast.error('Não foi possível completar a solicitação.');
    }
  }

  async getThemeCases(): Promise<void> {
    try {
      this.loading = true;

      const res = await this.openaiService.getChatData(promptCases(this.searchCases));
      localStorage.setItem('answer', JSON.stringify(res));

      this.loading = false;
      this.redirect('themeCases');
    } catch (e) {
      this.loading = false;
      this.toast.error('Não foi possível completar a solicitação.');
    }
  }

  async getTemas() {
    const temas = await this.palestrantesService.getAllConhecimentos().subscribe(
      (result: any) => {
        console.log(result);
        this.themes = result.body;
        this.filteredOptions = this.searchControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.nome),
          map(name => name ? this._filter(name) : this.themes.slice())
        );
      },
      (data: any) => {
        this.toast.error("Não foi possível carregar seus temas")
      }
    )
  }

  async getDbSpeakers(): Promise<void> {
    console.log(this.searchControl.value);
    this.openaiService.getDbSpeakers(this.searchControl.value?.id).subscribe(
      (result: any) => {
        this.loading = true;

        if(result.body.length > 0) {
          const answer = result.body.reduce((acc, curr) => acc += `<h1>${curr.nomeCompleto}</h1><br><p>${curr.minibio}</p><br><br>`, ' ');

          localStorage.setItem('answer', JSON.stringify(answer));
          this.redirect('spekers');
        }

        this.loading = false;
      },
      (data: any) => {
        this.loading = false;
        this.toast.error('Não foi possível completar a solicitação.');
      }
    );
  }

  async generateDefesa(): Promise<void> {
    try {
      this.loading = true;
      const proposta = this.defesaForms.value;
      const [ profile ] = await this.getSpeakerProfile(proposta.palestrante);

      const res = await this.openaiService.getChatData(promptDefesa(JSON.stringify(profile), proposta.palestrante, proposta.tema, proposta.briefing));
      localStorage.setItem('answer', JSON.stringify(res));

      this.loading = false;
      this.redirect('defesa');
    } catch (e) {
      this.loading = false;
      console.log(e);
      this.toast.error('Não foi possível completar a solicitação.');
    }
  }

  async getCompleteProfile(): Promise<void> {
    try {
      this.loading = true;
      const [ profile ] = await this.getSpeakerProfile(this.completeProfile);

      const res = await this.openaiService.getChatData(promptCompleteProfile(JSON.stringify(profile), this.completeProfile));
      localStorage.setItem('answer', JSON.stringify(res));

      this.loading = false;
      this.redirect('completeProfile');
    } catch (e) {
      this.loading = false;
      this.toast.error('Não foi possível completar a solicitação.');
    }
  }

  async getProfile(): Promise<void> {
    try {
      this.loading = true;
      const [ profile ] = await this.getSpeakerProfile(this.smallProfile);

      const res = await this.openaiService.getChatData(promptProfile(JSON.stringify(profile), this.smallProfile));
      localStorage.setItem('answer', JSON.stringify(res));


      this.loading = false;
      this.redirect('completeProfile');
    } catch (e) {
      this.loading = false;
      this.toast.error('Não foi possível completar a solicitação.');
    }
  }

  async compareSpeakers(): Promise<void> {
    try {
      this.loading = true;

      const speakersArr = this.searchCompare.split(',');

      if (speakersArr.length < 2) {
        this.loading = false;
        this.toast.error('Insira mais de um palestrante para a comparação');
      } else {
        let profiles = [];
        for(let i = 0; i < speakersArr.length; i++) {
          const [ profile ] = await this.getSpeakerProfile(speakersArr[i]);
          profiles.push(profile);
        }

        const res = await this.openaiService.getChatData(promptCompararPalestrantes(JSON.stringify(profiles), this.searchCompare));
        localStorage.setItem('answer', JSON.stringify(res));

        this.redirect('compare');
      }

      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.toast.error('Não foi possível completar a solicitação.');
    }
  }

  redirect(type: string) {
    localStorage.setItem('answerType', JSON.stringify(type));
    this.router.navigate(['admin', 'resposta']);
  }
}
