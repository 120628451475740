import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PalestrantesService } from 'src/app/services/palestrantes';

@Component({
  selector: 'psa-modal-depoimentos',
  templateUrl: './modal-depoimentos.component.html',
  styleUrls: ['./modal-depoimentos.component.scss'],
})
export class ModalDepoimentosComponent implements OnInit {
  @Input('id') id: string | number;

  depoimentos: any = [];
  constructor(
    public bsModalRef: BsModalRef,
    private palestranteService: PalestrantesService,
  ) {}

  ngOnInit(): void {
    console.log(this.id);
    this.getDepoimentos();
  }

  async getDepoimentos() {
    console.log(this.id)
    const depoimentosPalestrante = await this.palestranteService.getDepoimentosByPalestranteId({ id: this.id });
    this.depoimentos = depoimentosPalestrante
    console.log(this.depoimentos);
  }
}
