<main>
  <h1>Propostas Geradas</h1>

  <div class="filterContainer">
    <section>
      <label for="dateStart">Data Início</label>
      <input
        id="dateStart"
        name="dateStart"
        type="date"
        [(ngModel)]="filter.start"
      />
    </section>
    <section>
      <label for="dateEnd">Data Fim</label>
      <input
        id="dateEnd"
        name="dateEnd"
        type="date"
        [(ngModel)]="filter.end"
      />
    </section>

    <section>
      <button type="button" (click)="filterProposals()">Filtrar</button>
    </section>
    <section>
      <button type="button" (click)="resetFilter()">Resetar Filtro</button>
    </section>
  </div>

  <div class="filterContainer" *ngIf="isAdmin && !isLoading">
    <section class="checkbox">
      <input type="checkbox" name="seeAll" id="seeAll" [(ngModel)]="filter.seeAll" (change)="showAll()" />
      <label for="seeAll">Visualizar todas as propostas</label>
    </section>

    <section *ngIf="filter.seeAll">
      <label for="curadorFilter">Filtrar por curador</label>
      <select name="curadorFilter" [(ngModel)]="filter.curador" (change)="filterByCurador()">
        <option *ngFor="let curador of curadores" [value]="curador.id">{{ curador.curador }}</option>
      </select>
    </section>
  </div>

  <div class="filterContainer">
    <section>
      <label for="nameFilter">Filtrar por nome do cliente</label>
      <input name="nameFilter" id="nameFilter" [(ngModel)]="filterName" (change)="filterByName()" />
    </section>
  </div>

  <div class="filterContainer">
    <section>
      <button type="button" (click)="downloadCSV()">Baixar planilha</button>
    </section>
  </div>

  <div *ngIf="isLoading" class="d-flex justify-content-center">
    <p>Carregando propostas...</p>
  </div>

  <div  *ngIf="!isLoading" class="tableContainer">
    <table class="customTable">
      <thead>
        <th>Data de criação</th>
        <th>Contratante</th>
        <th>Email Contratante</th>
        <th>Curador</th>
        <th>Briefing</th>
        <th>validade</th>
        <th>Forma de Pagamento</th>
        <th>Principal</th>
        <th>Palestrantes</th>
        <th>Valor de Custo</th>
        <th>Valor de Venda</th>
        <th>Última Atualização</th>
        <th>Editar Proposta</th>
        <!-- <th>Copiar Link do PDF</th> -->
        <th>Download</th>
      </thead>

      <tbody>
        <tr *ngFor="let proposal of proposals | paginate: { itemsPerPage: 5, currentPage: currentPage }">
          <td>{{ proposal.createdAt }}</td>
          <td>{{ proposal.nomeEmpresa }}</td>
          <td>{{ proposal.email }}</td>
          <td>{{ proposal.curador }}</td>
          <td>{{ proposal.briefing }}</td>
          <td>{{ proposal.validade }}</td>
          <td>{{ proposal.formaPagamento }}</td>
          <td>{{ proposal.principal }}</td>
          <td>
            <ol>
              <li *ngFor="let speaker of proposal.palestrantes">{{ speaker }}</li>
            </ol>
          </td>
          <td>
            <ol>
              <li *ngFor="let value of proposal.valorCusto">{{ value }}</li>
            </ol>
          </td>
          <td>
            <ol>
              <li *ngFor="let finalValue of proposal.valorVenda">{{ finalValue }}</li>
            </ol>
          </td>
          <td>{{ proposal.updated_at }}</td>
          <td class="iconsContainer">
            <img src="../../../../assets/icons/editar.svg" alt="ícone de edição" (click)="editProposal(proposal.id, proposal.isOwner)">
          </td>
          <!-- <td class="iconsContainer">
            <img src="../../../../assets/icons/copy.svg" alt="" (click)="copyToClipboard(proposal.id)" />
          </td> -->
          <td class="iconsContainer">
            <img src="../../../../assets/icons/download.svg" alt="" (click)="downloadProposal(proposal.id)" />
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls (pageChange)="currentPage = $event"></pagination-controls>
  </div>

  <footer class="footer">
    <p>TOTAL DE PROPOSTAS NO PERÍODO: {{ proposals.length }}</p>
    <P>MÉDIA COMISSÃO LÍQUIDA NO PERÍODO: R$ {{ calcPrincipalValue() }}</P>
  </footer>

</main>
