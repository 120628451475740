import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss'],
})
export class NewHomeComponent implements OnInit {
  constructor(
    private router: Router,
  ) {}

  paths: any = {
    pesquisar: ['admin', 'pesquisar'],
    propostas: ['curador', 'palestras'],
    resultados: ['admin', 'resultados'],
  }

  ngOnInit(): void {}

  goToGerador(): void {
    //TO-DO when ready, change the link to the prd distribution
    window.open('https://d1rrqqb0o9aa7m.cloudfront.net/', '_blank');
  }

  goTo(path: String[]): void {
    this.router.navigate(path);
  }
}
