import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormCertificatesService } from 'src/app/services/FormCertificates/form-certificates.service';
import { IInvalid } from '../../palestrantes/editar-palestrante/etapas/cache-admin/cache-admin.component';
import { PalestrantesService } from 'src/app/services/palestrantes';

@Component({
  selector: 'app-create-forms',
  templateUrl: './create-forms.component.html',
  styleUrls: ['./create-forms.component.scss']
})
export class CreateFormComponent implements OnInit {

  newForm: any = {
    speakerEmail: '',
    talkTitle: '',
    eventName: '',
    emailCS: '',
    eventDate: '',
    businessName: '',
    emailClient: '',
    noAvaliation: false,
    useClientLogo: false,
    noPSALogo: false,
    useTitle: false,
    qtdParticipantes: 0,
    speakerId: null,
    language: 'pt-br',
    noCertificate: false,
    noPhone: false,
    noEmail: false
  }

  selectedSpeaker: any = {};

  file: any;

  invalids: Array<IInvalid> = []


  showConfirmation: boolean = false;
  useClientLogo: any = false;
  languages: any = [
    {
      label: 'Português',
      value: 'pt-br',
    },
    {
      label: 'Espanhol',
      value: 'es',
    },
    {
      label: 'Inglês',
      value: 'en'
    }
  ]
  loading: boolean = false;

  speakers: any = [];

  constructor(
    private formCertificateService: FormCertificatesService,
    private toast: ToastrService,
    private palestranteService: PalestrantesService,
  ) {}

  ngOnInit(): void {
    this.getSpeakers();
  }

  getSpeakers() {
    this.palestranteService.listSpeakerForCertificate().subscribe(
      (result: any) => {
        console.log(result);
        this.speakers = result.body;
      }
    )
  }

  selectSpeaker() {
    console.log(this.selectedSpeaker);
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return "";
  }

  async createForm() {
    console.log(this.file);

    const formData = new FormData();
    formData.append('speakerId', this.selectedSpeaker)
    formData.append('speakerEmail', this.newForm.speakerEmail);
    formData.append('talkTitle', this.newForm.talkTitle);
    formData.append('eventName', this.newForm.eventName);
    formData.append('emailCS', this.newForm.emailCS);
    formData.append('eventDate', this.newForm.eventDate);
    formData.append('businessName', this.newForm.businessName);
    formData.append('emailClient', this.newForm.emailClient);
    formData.append('noAvaliation', this.newForm.noAvaliation);
    formData.append('noPSALogo', this.newForm.noPSALogo);
    formData.append('useClientLogo', this.useClientLogo);
    formData.append('useTitle', this.newForm.useTitle);
    formData.append('file', this.file);
    formData.append('qtdParticipantes', this.newForm.qtdParticipantes);
    formData.append('noCertificate', this.newForm.noCertificate);
    formData.append('noEmail', this.newForm.noEmail);
    formData.append('noPhone', this.newForm.noPhone);
    formData.append('language', this.newForm.language);

    if (Object.values(this.newForm).includes("")) {
      this.toast.error('Preencha todos os campos!');
    } else {
      this.formCertificateService.createForms(formData).subscribe(
        (result: any) => {
          this.showConfirmation = true;
        },
        (data: any) => {
          this.toast.error('Ops! Algo deu errado ao tentar gerar o formulário. Tente novamente mais tarde!');
        }
      );
    }
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      var vFileTobeRead = event.target.files[0];
      var vFileReader = new FileReader();

      vFileReader.onload = () => {
        const fileSize = event.target.files[0].size / 1024 / 1024 / 1024;

        if (fileSize > 4) {
          event.target.files = null;
          event.target.value = null;

          const message = 'O arquivo selecionado ultrapassa o tamanho máximo de 4GB!';
          this.toast.error('Ocorreu um erro ao salvar a imagem');
        }
        else {
          const [file] = event.target.files;
          this.file = file
        }
      }

      vFileReader.readAsDataURL(vFileTobeRead);
    }
  }
}
