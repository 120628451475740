<main class="psa-main">
    <div class="container">
        <div class="row">

            <div class="col-12">

                <section class="sa-news__breadcrumb mt-5 mt-lg-2 mb-3 mt-lg-2">
                    <div class="">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb bg-transparent align-items-baseline">
                                <!-- <li class="breadcrumb-item"><a href="/">Home</a></li> -->
                                <li class="breadcrumb-item"><a href="/sa-news/home">SA News</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{{categoria?.descricao}}</li>
                            </ol>
                        </nav>
                    </div>
                </section>

                <div *ngIf="isLoading" class="loading flexFull">
                    <img src="assets/img/simple_loading.gif" alt="loading">
                </div>


                <div *ngIf="!isLoading" class="cabecalho d-flex flex-column flex-lg-row justify-content-around mb-5">
                    <h1 class="">{{categoria?.descricao}}</h1>

                    <p> {{categoria?.resumo ? categoria?.resumo : 'Conteúdo vivo em formato multimídia sobre desenvolvimento de pessoas e corporativo. Tenha acesso
                        especial a entrevistas exclusivas com grandes especialistas e pensadores brasileiros.'}}
                    </p>
                </div>

                <div *ngIf="artigos?.length == 0"
                    class="cabecalho d-flex flex-column flex-lg-row justify-content-around mb-5">
                    <h1 class="">Nenhum artigo encontrado</h1>
                </div>

                <section *ngIf="artigos?.length > 0" class="palestras__palestrantes mt-4 mt-lg-0">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4" *ngFor="let artigo of artigos">
                                <a class="text-decoration-none mb-3" (click)="goArtigo(artigo.slug, artigo.categoria)">
                                    <div class="artigo__item text-center pb-3 mb-3 mb-lg-5">
                                        <div class="artigo__item__div__img overflow-hidden">
                                            <img src="{{artigo.image_src}}" />
                                        </div>
                                        <div class="artigo__item__div__content text-center p-3">
                                            <h2 class="font-primary artigo__item__title py-2">{{artigo.titulo}}</h2>
                                            <p class="font-secondary text-secondary artigo__item__description"
                                                [innerHTML]="artigo.resumo">
                                            </p>
                                            <p class="text__more">Saiba mais</p>

                                            <div class="artigo__item__info">
                                                <!-- <p>{{artigo.data}}</p> -->
                                                <p class="artigo__item__info__tipo">{{artigo.tipo}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

            </div>

            <nav class="w-100 pl-2 mb-5" *ngIf="qtdePages?.length > 1" aria-label="Páginas">
                <ul class="pagination justify-content-center">
                    <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                        <a class="page-link"> <i class='fa fa-chevron-left'></i></a>
                    </li>
                    <li (click)="changePage(i)" class="page-item" [ngClass]="{'active__page': page == i}"
                        *ngFor="let number of qtdePages, let i = index">
                        <a class="page-link">{{i+1}}</a>
                    </li>
                    <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                        <a class="page-link"> <i class='fa fa-chevron-right'></i></a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
</main>