import {Component, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {ModalPlayComponent} from '../../../common/modal-play';
import {ActivatedRoute, Router} from '@angular/router';
import {GrupoService} from '../../../services/grupo';
import {ToastrService} from 'ngx-toastr';
import {ComponentCanDeactivate} from 'src/app/guards/guard-live.component';
import {ModalPDFComponent} from 'src/app/common/modal-pdf';
import sortBy from 'sort-by';

export interface IHabilidade {
  id: number;
  titulo: string;
  ordem: number;
  prioridade?: number;
  video_link?: string;
  pdf_link?: string;
  arquivo_principal?: string;
  id_habilidade: number;
  porcentagem: number;
}

export interface ICompetencia {
  id: number;
  descricao: string;
  resumo: string;
  habilidades?: IHabilidade[];
}

interface IGrupoHabilidades {
  id: number;
  nome: string;
  descricao: string;
  ativo: string;
  id_contratante: number;
  habilidades?: IHabilidade[];
  porcentagem: number;
}

@Component({
  selector: 'app-habilidades',
  templateUrl: './habilidades.component.html',
  styleUrls: ['./habilidades.component.scss']
})
export class HabilidadesComponent extends ComponentCanDeactivate implements OnInit {

  bsModalRef: BsModalRef;
  idGrupo: number;

  grupo: IGrupoHabilidades = {
    id: 0,
    nome: '',
    descricao: '',
    ativo: 'SIM',
    id_contratante: 0,
    porcentagem: 0,
  };
  colors: string[] = ['#F04950', '#F58D4E', '#FAA54B', '#FECE3D', '#FFF533', '#A3FD38', '#33B8A5', '#33A7D8', '#3277B5', '#B968AD', '#B968AD', '#E966AC'];
  competencias: Array<ICompetencia> = [];
  adicionar = false;
  competenciaSelecionada: ICompetencia;

  canDeactivate(): boolean {
    return false;
  }

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private route: ActivatedRoute,
    private grupoService: GrupoService,
    private toast: ToastrService) {
    super();
  }

  ngOnInit(): void {
    this.idGrupo = parseInt(this.route.snapshot.paramMap.get('id'));
    this.getGrupoHabilidades();
    this.getCompetencias();
  }

  async getGrupoHabilidades() {
    const resGrupo = await this.grupoService.getGrupo(this.idGrupo)
      .catch((error) => {
        
        return;
      });

    const resGrupoHabilidades = await this.grupoService.getGrupoHabilidades(this.idGrupo)
      .catch((error) => {

        return;
      });

    this.grupo = resGrupo;
    this.grupo.habilidades = resGrupoHabilidades[0] ? resGrupoHabilidades : [];
  }

  async getCompetencias() {
    try {
      this.competencias = await this.grupoService.getCompetencias();

    } catch (error) {
      return;
    }
  }

  assistir(videoLink: string, titulo: string) {
    if (!videoLink) {
      this.toast.error('Não foi possível carregar o vídeo');
    }
    const initialState = {
      videoLink,
      title: titulo
    };
    const config = {class: 'modal-play', initialState};
    this.bsModalRef = this.modalService.show(ModalPlayComponent, config);
  }

  openPDF(link: string, titulo: string) {
    if (!link) {
      this.toast.error('Não foi possível carregar o pdf');
    }
    const initialState = {
      link,
      title: titulo
    };
    const config = {class: 'modal-play', initialState};
    this.bsModalRef = this.modalService.show(ModalPDFComponent, config);
  }

  getRandomColor(i: number) {
    return this.colors[i > this.colors.length - 1 ? i - this.colors.length : i];
  }

  getColor() {
    return this.getRandomColor(this.competencias.findIndex(competencia => competencia.id === this.competenciaSelecionada?.id));
  }

  getProgressColor(i: number) {
    if (this.grupo.habilidades[i].porcentagem === 100) {
      return 'linear-gradient(to right, rgb(255, 200, 0, 0.8) 0%, rgb(255, 200, 0, 0.4) 100%)';
    }
    return ('linear-gradient( to right, #ffc800, transparent ' + this.grupo.habilidades[i].porcentagem + '%');
  }

  async salvar() {
    try {
      if (this.grupo.habilidades.length === 0) {
        this.toast.error('Selecione competência e arraste uma habilidade para o grupo.', 'Grupo deve possuir pelo menos 1 uma HABILIDADE!');
        return;
      }
      let count = 1;
      this.grupo.habilidades.forEach(i => {
        i.ordem = count++;
      });
      const body = {
        id_grupo: this.grupo.id,
        habilidades: this.grupo.habilidades
      };
      const res = await this.grupoService.saveHabilidades(body);

      await this.router.navigate(['/universidade/notificacao']);
    } catch (e) {
      console.log('error', e);
      this.toast.error('Erro ao salvar habilidades!');
      return;
    }
  }

  async excluir(idGrupoHabilidade: number, ordemIndex: number, idCompetencia: any) {
    try {
      if (idGrupoHabilidade) {
        await this.grupoService.removeGrupoHabilidade(idGrupoHabilidade);
        this.toast.success('Removido com sucesso');
        await this.getGrupoHabilidades();
        await this.getCompetencias();
      } else {
        if (idCompetencia === this.competenciaSelecionada.id) {
          this.competenciaSelecionada.habilidades.push(this.grupo.habilidades[ordemIndex]);
        } else {
          this.competencias.forEach((i: ICompetencia) => {
            if (i.id === idCompetencia) {
              i.habilidades.push(this.grupo.habilidades[ordemIndex]);
            }
          });
        }
        this.grupo.habilidades.splice(ordemIndex, 1);
      }
    } catch (e) {
      await this.getGrupoHabilidades();
      await this.getCompetencias();
      this.toast.error(e.error.message);
    }
  }

  selecionaCompetencia(competencia: ICompetencia) {
    this.competenciaSelecionada = competencia;
    this.competenciaSelecionada.habilidades = this.competenciaSelecionada.habilidades
      .filter(item => !(this.isInGrupoHabilidade(item.id_habilidade)));
    this.competenciaSelecionada.habilidades = this.getListOrdered(this.competenciaSelecionada.habilidades);
  }

  getListOrdered(list) {
    return list.sort(sortBy('titulo'));
  }

  isInGrupoHabilidade(id: number): boolean {
    for (const i in this.grupo.habilidades) {
      if (this.grupo.habilidades[i].id_habilidade === id) {
        return true;
      }
    }
    return false;
  }

  fechaCompetencia() {
    this.competenciaSelecionada = (null as ICompetencia);
  }

  drop(event: CdkDragDrop<IHabilidade[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
}
