<div class="container-fluid pl-lg-5 pr-lg-5">
  <app-wizard [itemSelected]="1"></app-wizard>
  <div class="clearfix mb-3 pr-0 col-12"></div>

  <div class="container__conteudo col-12 col-lg-12">
    <div style="position: relative;">
      <tabset>
        <tab heading="Colaboradores" id="tabcolaborador">
          <div class="margin"></div>
          <app-tb-colaborador></app-tb-colaborador>
        </tab>

        <tab heading="Tutores" [active]="tab == 'tutores'">
          <div class="margin"></div>
          <app-tb-lider></app-tb-lider>
        </tab>
      </tabset>
    </div>

    <div style="position: absolute; top: 15px; right: 10px;" class="d-flex">
      <button (click)="import()" class="btn bg-warning btn-default mr-2">
        <img style="margin-right: 4px; width: 15px;" src="../../../assets/icons/upload.svg" alt="importar">
        IMPORTAR
      </button>

      <button (click)="goGrupos()" class="btn bg-warning btn-default flexFull">
        avançar
        <img style="margin-left: 4px; transform: rotate(180deg); width: 15px;" src="../../../assets/icons/back.svg" alt="cancelar">
      </button>
    </div>
  </div>

</div>