<div class="container-fluid pl-lg-5 pr-lg-5">

    <div class="clearfix mb-2 pr-0 col-12">
      <h3 class="d-inline font-title mb-4">Notificações</h3>
      <button (click)="back()" type="button" class="btn btn-warning btn-back-circle float-right flexFull">
        <img class="float-right" src="../../../assets/icons/back.svg" alt="cancelar">
      </button>
    </div>
  
    <div class="container__conteudo col-12 col-lg-12">
      <div *ngFor="let notificacao of notifications" class="p-4">
        <div style="display: flex;justify-content: space-between;">
          <h5><b>De: </b>{{notificacao.message.data.fromUser || 'Profissionais Sa'}}</h5>
          <h5><b>{{notificacao.schedule_date}}</b></h5>
        </div>
        <div style="display: flex;justify-content: space-between;">
          <div>
            <h6><b>{{notificacao.message.notification.title}}</b></h6>
            <p>{{notificacao.message.notification.body}}</p>
            <button *ngIf="notificacao.message.data.openPage === 'palestra' || notificacao.message.data.openPage === 'plano' || notificacao.message.data.openPage === 'planos'" (click)="access(notificacao.message.data)" class="mt-1 btn btn-warning p-2 btn-lg">Acessar</button>
          </div>
          <button (click)="archive(notificacao.id)" class="mt-2 button_link">Arquivar</button>
        </div>
      </div>
    </div>
  
  </div>