<div class="content__default p-4 pm-md-5">
  <form class="row">
      <div class="col-12 d-flex justify-content-between">
          <h5 class="font-title text-uppercase">Solicitar depoimento</h5>
          <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close"
              title="Cancelar">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>

      <div class="form-group col-12 col-md-6">
          <label class="label-input" for="nome">Nome do Contratante *</label>
          <input id="nome" [class.invalid]="isIncorrect('nome')" class="form-control" name="nome" type="text"
              [(ngModel)]="form.nome" placeholder="Nome *" maxlength=100>
          <span class="error">{{getError('nome')}}</span>
      </div>

      <div class="form-group col-12 col-md-6">
          <label class="label-input" for="email">email do Contratante*</label>
          <input id="email" [class.invalid]="isIncorrect('email')" class="form-control" name="email" type="text"
              [(ngModel)]="form.email" placeholder="E-mail *" maxlength=150>
          <span class="error">{{getError('email')}}</span>
      </div>

      <div class="form-group col-12 col-md-6">
          <label class="label-input" for="empresa">empresa *</label>
          <input id="empresa" [class.invalid]="isIncorrect('empresa')" class="form-control" name="empresa" type="text"
              [(ngModel)]="form.empresa" placeholder="Empresa *" maxlength=150>
          <span class="error">{{getError('empresa')}}</span>
      </div>

      <div class="form-group col-12 col-md-6">
          <label class="label-input" for="telefone">telefone *</label>
          <input id="telefone" [class.invalid]="isIncorrect('telefone')" class="form-control" name="telefone"
              type="text" [(ngModel)]="form.telefone" placeholder="Telefone *" mask="(00) 0000-0000||(00) 0 0000-0000||00 (00) 0 0000-0000||00 (00) 0000-0000">
          <span class="error">{{getError('telefone')}}</span>
      </div>


      <div class="form-group col-12 col-md-6">
          <label class="label-input" for="nomedoevento">nome do evento *</label>
          <input id="nomedoevento" [class.invalid]="isIncorrect('nomedoevento')" class="form-control" name="nomedoevento" type="text"
              [(ngModel)]="form.nomedoevento" placeholder="Nome do evento *" maxlength=120>
          <span class="error">{{getError('nomedoevento')}}</span>
      </div>

      <div class="form-group col-12 col-md-4">
          <label class="label-input" for="tipodoevento">Tipo do Evento *</label>
          <select id="tipodoevento" [class.invalid]="isIncorrect('tipodoevento')" class="form-control"
              name="tipodoevento" placeholder="tipodoevento *" [(ngModel)]="form.tipodoevento">
              <option value="1">Online</option>
              <option value="2">Presencial</option>
          </select>
          <span class="error">{{getError('tipodoevento')}}</span>
      </div>

      <div class="form-group col-12 col-md-4">
          <label class="label-input" for="estado">estado *</label>
          <select id="estado" [class.invalid]="isIncorrect('estado')" class="form-control" name="estado"
              placeholder="Estado *" (change)="getCidades(estado)" [(ngModel)]="estado">
              <option value="">Selecione *</option>
              <option *ngFor="let estado of arrayEstados" [value]="estado.uf">{{estado.estado}}</option>
          </select>
          <span class="error">{{getError('estado')}}</span>
      </div>

      <div class="form-group col-12 col-md-4">
          <label class="label-input" for="cidade">cidade *</label>
          <select id="cidade" [class.invalid]="isIncorrect('cidade')" class="form-control" name="cidade"
              placeholder="cidade *" [(ngModel)]="form.cidade">
              <option value="">Selecione *</option>
              <option *ngFor="let i of arrayCidades" [value]="i.nome">{{i.nome}}
              </option>
          </select>
          <span class="error">{{getError('cidade')}}</span>
      </div>

      <div class="form-group col-12 col-md-6">
          <label class="label-input" for="data">data *</label>
          <input id="data" [class.invalid]="isIncorrect('data')" class="form-control" name="data" type="date"
              [(ngModel)]="form.data" placeholder="data *">
          <span class="error">{{getError('data')}}</span>
      </div>

      <div class="form-group col-12 col-md-6">
          <label class="label-input" for="data">Decsrição *</label>
          <textarea id="descricao" [class.invalid]="isIncorrect('descricao')" class="form-control" name="descricao"
              [(ngModel)]="form.descricao" placeholder="descricao *"></textarea>
          <span class="error">{{getError('descricao')}}</span>
      </div>

      <div class="col-12">
          <div class="d-flex pt-2 pb-2 div__btn__salvar">

              <button (click)="cancel()" type="button" class="btn btn-primary btn-secondary mr-3"
                  title="Cancelar">CANCELAR</button>

              <button *ngIf="!loading" (click)="submit()" class="btn btn-primary btn-warning">Salvar</button>

              <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
                  <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
              </button>
          </div>
      </div>
  </form>
</div>
