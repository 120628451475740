import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EntryComponent } from './entry';
import { MessagingService } from './services/messaging/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss',]
})
export class AppComponent {
  title = 'dashboard';
  logado: boolean;
  message;
  isRouteLogout: boolean = false;
  logoutPages: Array<string> = ["redefinir-senha/", "universidade/solicitacao-colaborador/", "responder-formulario", "confirmar-indicacao"]

  @ViewChild("entry", { static: false }) entryComponent: EntryComponent;

  constructor(private router: Router, private messagingService: MessagingService) {}

  ngOnInit(): void {
    for (const iterator of this.logoutPages) {
      if (window.location.href.includes(iterator)) {
        this.isRouteLogout = true
        break
      }
    }
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
    this.isLogado();
  }

  isLogado() {
    this.logado = localStorage.getItem("token") ? true : false;
  }

  logout() {
    localStorage.clear();
    this.logado = false;
    this.router.navigate(['/login'])
  }

  setIsRouteLogout(value: boolean) {
    this.isRouteLogout = value
  }

  refreshInfoGlobal() {
    this.entryComponent.getUsuario()
  }

  closeSide() {
    this.entryComponent.closeSide(true)
  }

  showHeader(value: boolean) {
    this.entryComponent.closeHeader(value)
  }
}
