import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { estados } from 'src/app/common/array-estados';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { ViacepService } from 'src/app/services/viacep';

interface IUsuario {
  id: number
  bairro: string
  cep: string
  cidade: string
  codigo_indicacao: string
  complemento: string
  data_nasc: string
  email: string
  estado: string
  logradouro: string
  nome: string
  numero: string
  pais: string
  perfil: string
  perfil_indicacao: string
  nome_completo_indicacao: string
  photo: string
  segmento: string
  sobrenome: string
  status: string
  telefone: string
  tipo_pessoa: string
  cpf: string
  linkedin: string
  facebook: string
  instagram: string
  whatsapp: string
  nome_mae: string
  nome_pai: string
  nome_fantasia: string
}

interface IPalestrante {
  id: number
  consultor: string
  influenciador: string
  mentor: string
  palestrante: string
  trainer: string
  minibio: string
  preco: string
  renomado: string
  slug: string
  titulo: string
  usuario_id: number
  video: string
}

export interface IInvalid {
  campo: string
  erro: string
}

@Component({
  selector: 'app-tb-palestrante-dados',
  templateUrl: './tb-dados.component.html',
  styleUrls: ['./tb-dados.component.scss']
})
export class TbDadosComponent implements OnInit {
  bsModalRef: BsModalRef;
  @Output("next") next = new EventEmitter();

  loading: boolean = false
  arrayEstados = estados;

  usuario: IUsuario = { id: 0, bairro: "", cep: "", cidade: "", codigo_indicacao: "", complemento: "", data_nasc: "", email: "", estado: "", logradouro: "", nome: "", numero: "", pais: "", perfil: "", perfil_indicacao: "", nome_completo_indicacao: "", photo: "", segmento: "", sobrenome: "", status: "", telefone: "", tipo_pessoa: "", cpf: "", linkedin: "", facebook: "", instagram: "", whatsapp: "", nome_mae: "", nome_pai: "", nome_fantasia: "", }
  palestrante: IPalestrante = { id: 0, consultor: "", influenciador: "", mentor: "", palestrante: "", trainer: "", minibio: "", preco: "", renomado: "", slug: "", titulo: "", usuario_id: 0, video: "" }

  invalids: Array<IInvalid> = []

  constructor(private palestrantesService: PalestrantesService,
    private cepService: ViacepService,
    private modalService: BsModalService,
    private viewportScroller: ViewportScroller,
    private app: AppComponent,
    private toast: ToastrService) { }

  ngOnInit(): void {
    this.getDados()
  }

  async getDados() {
    const res = await this.palestrantesService.getDados()
    this.usuario = res.usuario
    this.palestrante = res.palestrante
  }

  async completeCEP() {
    if (this.usuario.cep?.length === 8) {
      const initialState = { mensagem: "Completar endereço com CEP?", submensagem: "" }
      const config = { initialState }
      this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)
      let newSubscriber = this.modalService.onHide.subscribe(() => {
        newSubscriber.unsubscribe();
        if (this.bsModalRef.content.value) this.completaEnderecoComCEP()
      })
    } else {
      this.toast.error("CEP inválido.")
    }

  }

  async salvar() {
    this.invalids = []
    try {
      this.loading = true
      const res = await this.palestrantesService.updateDados(this.usuario, this.palestrante)
      this.loading = false
      this.toast.success("Salvo com sucesso!");
      this.next.emit({ next: true });
      this.app.refreshInfoGlobal()

    } catch (error) {
      this.loading = false
      if (error.error.message.invalids) {
        this.invalids = error.error.message.invalids
        this.toast.error("Campos inválidos!", "Por favor, revise.")
        // this.viewportScroller.scrollToAnchor(`${this.invalids[0].campo}`)
      } else {
        this.toast.error("Não foi possível salvar os dados", "Falha na conexão")
      }
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }

  async completaEnderecoComCEP() {

    try {
      const resCEP = await this.cepService.getCEP(this.usuario.cep)

      if (resCEP.logradouro) {
        this.usuario.bairro = resCEP.bairro
        this.usuario.complemento = resCEP.complemento
        this.usuario.cidade = resCEP.localidade
        this.usuario.logradouro = resCEP.logradouro
        this.usuario.bairro = resCEP.bairro
        this.usuario.estado = resCEP.uf
      } else {
        this.toast.error("Não foi possível preencher os campos com o CEP.", "Revise o CEP informado.")
      }
    } catch (error) {
      this.toast.error("Não foi possível preencher os campos com o CEP.", "Revise o CEP informado.")
    }
  }
}
