<div
  *ngIf="question"
  class="question"
  [class.question--right]="isCorrect"
  [class.question--wrong]="!isCorrect"
>
  <h3 *ngIf="size > 0" class="question__title">
    {{indexQuestion + 1}}.
    {{question.tipo == "DESCRITIVA" ? 'Tarefa prática' : question.pergunta}}
  </h3>

  <div *ngIf="question.tipo != 'DESCRITIVA' && hasArrayOptions()" class="questions__content">
    <ul class="question__options">
      <li *ngFor="let option of question.opcoes, let index = index">
        <div
          *ngIf="option?.descricao_opcao"
          class="question__option question__option--checkbox"
          [class.question__option--selected]="(option?.ordem == optionChoosed?.ordem) && isAnswered"
          [class.question__option--correct]="isAnswered && option?.peso == '100'"
        >
          <input
            type="radio"
            name="question-{{index}}"

            (click)="checkAnswer(option)"
            id="options-{{option?.ordem}}"
            [value]="option?.ordem"
            [checked]="(option?.ordem == optionChoosed?.ordem) && isAnswered"
            [disabled]="isAnswered"
          />

          <label for="question-{{index}}">
            {{option.descricao_opcao}}
          </label>
        </div>
      </li>
    </ul>

    <div *ngIf="isAnswered" class="questionCorrectionCard my-2">
      <div class="questionCorrectionCard__icon">
        <i *ngIf="isCorrect" class="colorSuccess fa fa-check"></i>
        <i *ngIf="!isCorrect" class="colorError fa fa-times"></i>
      </div>

      <div class="questionCorrectionCard__content">
        <h4 *ngIf="isCorrect" class="subtitle colorSuccess">Resposta correta.</h4>
        <h4 *ngIf="!isCorrect" class="subtitle colorError">Hmm, na verdade não.</h4>

        <p class="textDefault colorFontSecondary mb-0">
          {{questionCorrectionText}}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="question.tipo == 'DESCRITIVA'" class="question__option question__option--textarea">
    <label *ngIf="question.pergunta" for="anwser">
      {{question.pergunta}}
    </label>

    <!-- [readonly]="isAnswered == true" -->
    <textarea
      class="form-control textarea__default"
      placeholder="Descreva a resposta"
      name="anwser"
      id="anwser"
      rows="8"
      [(ngModel)]="anwser"
    >
    </textarea>
  </div>
</div>
