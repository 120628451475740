import { Routes } from '@angular/router';
import { HistoricoComponent } from './historico';
import { PushUsersComponent } from './push-users';


export const ComunicacaoInternaRoutes: Routes = [
  {
    path: 'comunicacao-interna',
    component: PushUsersComponent
  },
  {
    path: 'comunicacao-interna/historico',
    component: HistoricoComponent
  },
];
