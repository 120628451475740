import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { OrcarButtomComponent } from './orcar-buttom';
import { TemasComponent } from './temas/temas.component';
import { TemaComponent } from './tema/tema.component';
import { EspecialistasCarouselComponent } from './especialistas-carousel/especialistas-carousel.component';
import { PalestraComponent } from './palestra/palestra.component';
import { PalestrasCarouselComponent } from './palestras-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EspecialistasModule } from '../especialistas';

@NgModule({
  declarations: [TemasComponent, TemaComponent, EspecialistasCarouselComponent, OrcarButtomComponent, PalestraComponent, PalestrasCarouselComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    CarouselModule,
    EspecialistasModule
  ]
})
export class PalestrasModule { }
