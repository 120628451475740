import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IGetEspecialistasParams {
  id_tema?: string,
  tema?: string,
  investimento?: string,
  formato?: string,
  pesquisa?: string,
  regiao?: string,
  page?: number,
  empresa: number
}

@Injectable({
  providedIn: 'root'
})
export class EspecialistasService {

  constructor(public http: HttpClient) { }

  async getList(parm_body: IGetEspecialistasParams): Promise<any> {
    const body = Object.assign(parm_body)
    const res = await this.http.post<any>(`${environment.API_V2_URL}base/front/especialistas`, body).toPromise();
    return res.body
  }

  async getEspecialistaBySlug(slug: string): Promise<any> {
    let empresa = 4
    const res = await this.http.get<any>(`${environment.API_V2_URL}base/front/especialista/${empresa}/${slug}`).toPromise();
    return res.body
  }

  async getCountList(parm_body: IGetEspecialistasParams): Promise<any> {
    const body = Object.assign(parm_body)
    const res = await this.http.post<any>(`${environment.API_V2_URL}base/front/especialistasPage`, body).toPromise();
    return res.body
  }
}
