import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html'
})
export class UserSettingsComponent implements OnInit {
  tab: string = 'dados';

  constructor(
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Dashboard - Configurações');

    let params = this.activatedRoute.queryParams['_value']

    if (params.tab) {
      this.tab = params.tab
    }
  }
}
