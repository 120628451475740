export const environment = {
  production: true,
  API_URL: 'https://api.profissionaissa.com/production/base-api/',
  API_V2_URL: 'https://api-v2.profissionaissa.com/',
  API_V2_URL_DEFAULT: 'https://api-v2.profissionaissa.com/dashboard/',
  API_V2_URL_CERTIFICADOS: 'https://api-v2.profissionaissa.com/certificados/',
  AWS_GATEWAY_CERTIFICADOS: 'https://3xmprtv2ii.execute-api.us-east-1.amazonaws.com/prod/answers',
  URL_BAMBUSER: 'https://dist.bambuser.net/broadcaster/?application_id=FeCpEhF2beaZd905dinc4g&ticket=domain%3Ddashboard.profissionaissa.com&da_signature_header=da_id%3De554b3e7-1c5c-ca4b-4274-76591d7c87e0%26da_timestamp%3D1612809300%26da_signature_method%3DHMAC-SHA256%26da_ttl%3D0%26da_static%3D1%26da_signature%3Ddb44015f659b2c58395ce0bf9c4e0def7c09cc5d4dc030f0a3bc5f5d2ac3f79b',
  firebase: {
    apiKey: "AIzaSyChRKfNIxUXbkdszRhtuNq-Q5maZadSTfY",
    authDomain: "app-espectador.firebaseapp.com",
    databaseURL: "https://app-espectador.firebaseio.com/",
    projectId: "app-espectador",
    storageBucket: "app-espectador.appspot.com",
    messagingSenderId: "341019330376",
    appId: "1:341019330376:web:ab7f9b316e32dbef56157d",
    measurementId: "G-PJLZQWL9TS"
  },
  GERADOR: 'https://d1rrqqb0o9aa7m.cloudfront.net/',
  openAiToken: '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',
  tbsbBaseApi: 'https://api.thebestspeaker.com.br/tbsb-base/',
  tbsbApi: 'https://api.thebestspeaker.com.br/tbsb/',
};
