import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NoticesComponent } from '../notices.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TbsbService } from 'src/app/services/tbsb/tbsb.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tbsb-modal-edit-notice',
  templateUrl: './modal-edit-notice.component.html',
  styleUrls: ['./modal-edit-notice.component.scss'],
})
export class ModalEditNoticeComponent implements OnInit {
  newNotice: any = {};

  buttonLabel: string = 'Criar Aviso';

  loading: boolean = false;
  errorMessage: string = 'Teste erro';
  status: string = 'public';
  noticeId: string = '';

  constructor(
    public dialogRef: MatDialogRef<NoticesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private noticesService: TbsbService,
    private toastr: ToastrService,
  ) {
    console.log(data);
    this.newNotice = new FormGroup({
      title: new FormControl(data.title, [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(255)
      ]),
      description: new FormControl(data.description, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(255)
      ]),
    });

    this.status = data.status;
    this.noticeId = data.id;
  }

  ngOnInit(): void {

  }

  updateInsertNotice() {
    console.log(this.status)
    this.noticesService.editNotice({...this.newNotice.value, status: this.status }, this.noticeId).subscribe(
      (result: any) => {
        this.toastr.success('Aviso criado com sucesso!');
        this.dialogRef.close();
      },
      (data: any) => {
        this.toastr.error('Erro ao criar aviso.');
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

}
