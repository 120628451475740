import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IProfileEspectador } from 'src/app/pages/configuracoes/espectador/tb-espectador';

export interface ITotalizers {
  total_inscricao: number
  total_tarefas_pendentes: number
  total_certificados: number
  total_avaliacao_pendentes: number
}

@Injectable({
  providedIn: 'root'
})
export class EspectadoresService {

  constructor(public http: HttpClient) { }

  async authLogin(email: string, password: string): Promise<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Accept", "application/json");
    let body = JSON.stringify({ email: email, password: password })
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}auth/login`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getProfile(): Promise<any> {
    let headers = new HttpHeaders()
      .set('Authorization', localStorage.getItem("token"));;
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/profile`, { headers: headers }).toPromise();
    return result.body;
  }

  async saveProfile(body): Promise<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set('Authorization', localStorage.getItem("token"));

    let pbody     = JSON.stringify(body)
    const result  = await this.http.patch<any>(`${environment.API_V2_URL}app-espectador/profile`, pbody, { headers: headers }).toPromise();

    return result.body;
  }

  async getTotalizers(): Promise<ITotalizers> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}espectador/totalizers`, { headers: headers }).toPromise();
    return result.body;
  }

  async setUserTrail(trailType: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.put<any>(`${environment.API_V2_URL}app-espectador/conteudos/trilha/${trailType}`, null, { headers: headers }).toPromise();
    return result.body;
  }

  async getQuestionsPdi() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/conteudos/perguntas/pdi`, { headers: headers }).toPromise();
    return result.body;
  };

  async savePdiQuestion(resposta) {
    let body = JSON.stringify(resposta)
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("Accept", "application/json")
      .set('Authorization', localStorage.getItem("token"));
    const result = await this.http.post<any>(`${environment.API_V2_URL}dashboard/gameficacao/perguntas/pdi/responder`, body, { headers: headers }).toPromise();
    return result.body;
  };

  async acceptInviteToBeCollaborator(idCompany: number, idUser: number): Promise<any> {
    const body = { idCompany, idUser };
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}colaboradores/accept-company-invite`, body).toPromise();
    return result.body;
  }

  userHasCompanyPlan(): boolean {
    const viewer = JSON.parse(window.localStorage.getItem("espectador"))
    return (viewer && viewer.id_contratante > 0)
  }

  getStorage() {
    let espectador = localStorage.getItem("usuario")
    return JSON.parse(espectador)
  }

  setStorage(espectador) {
    localStorage.setItem("espectador", JSON.stringify(espectador))
  }
}
