import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-medalha',
  templateUrl: './medalha.component.html',
  styleUrls: ['./medalha.component.scss']
})
export class MedalhaComponent implements OnInit {

  @Input() posicao: number
  constructor() { }

  ngOnInit(): void {
  }

}
