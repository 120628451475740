import { Component, OnInit } from '@angular/core';
import { SaNewsService } from '../../../services/sa-news';
import { Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';

export interface IArtigo {
  titulo: string,
  artigo: string,
  resumo: string,
  slug: string,
  image_src: string
  tipo?: string
  formato?: string
  fonte?: string
  data?: string
  id_conteudo_tipo?: number
  video_link?: string
  categoria?: string
}

export interface ICategoriaArtigo {
  id: number,
  nome: string,
  descricao: string
  resumo: string
  imagem: string
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  categoriasId = [1, 2, 3, 11, 10, 12, 13]

  artigosRecentes: IArtigo[] = [];
  artigosVideos: IArtigo[] = [];
  artigos1: IArtigo[] = [];
  artigos2: IArtigo[] = [];
  artigos3: IArtigo[] = [];
  artigos4: IArtigo[] = [];
  artigos5: IArtigo[] = [];
  artigos6: IArtigo[] = [];
  artigos7: IArtigo[] = [];
  categorias: ICategoriaArtigo[];
  isLoading: boolean = true

  customOptions: OwlOptions = {
    center: true,
    dots: false,
    nav: true,
    autoHeight: true,
    navText: ["<i class='carousel-control-prev-icon'></i>", "<i class='carousel-control-next-icon'></i>"],
    responsive: { 0: { nav: false, items: 1 }, 400: { nav: true, items: 1 }, 900: { nav: true, items: 1.1 } }
  };

  customOptionsArtigos: OwlOptions = {
    margin: 10,
    dots: true,
    nav: false,
    autoHeight: true,
    navText: ["<i class='carousel-control-prev-icon'></i>", "<i class='carousel-control-next-icon'></i>"],
    responsive: { 0: { center: true, items: 1.1 }, 400: { center: true, items: 1.1 }, 750: { items: 2.5 }, 900: { items: 2.6 }, 1100: { items: 2.6 }, 1400: { items: 3.5 } }
  };

  customOptionsCategorias: OwlOptions = {
    margin: 10,
    dots: true,
    nav: false,
    navText: ["<i class='carousel-control-prev-icon'></i>", "<i class='carousel-control-next-icon'></i>"],
    responsive: { 0: { center: true, items: 1.8 }, 400: { center: true, items: 1.8 }, 900: { items: 5 } }
  };

  customOptionsVideos: OwlOptions = {
    dots: false,
    nav: true,
    animateOut: 'slideOutDown',
    animateIn: 'flipInX',
    navText: ["<i class='carousel-control-prev-icon'></i>", "<i class='carousel-control-next-icon'></i>"],
    responsive: { 0: { items: 1 } }
  };

  constructor(
    private saNewsService: SaNewsService,
    private titleService: Title,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("SA News");
    this.loadArtigos()
  }

  async loadArtigos() {
    this.isLoading = true
    try {
      await this.saNewsService.getHome().then(result => {
        this.isLoading = false
        this.artigosRecentes = result.recentes ? result.recentes : []
        this.artigosVideos = result.videos ? result.videos : []
        this.artigos1 = result.artigos1 ? result.artigos1 : []
        this.artigos2 = result.artigos2 ? result.artigos2 : []
        this.artigos3 = result.artigos3 ? result.artigos3 : []
        this.artigos4 = result.artigos4 ? result.artigos4 : []
        this.artigos5 = result.artigos5 ? result.artigos5 : []
        this.artigos6 = result.artigos6 ? result.artigos6 : []
        this.artigos7 = result.artigos7 ? result.artigos7 : []
        this.categorias = result.categorias ? result.categorias : []

      })
      console.log(this.categorias)
    } catch (error) {
      this.isLoading = false
    }
  }

  goTipo(id_tipo: number) {
    this.router.navigate(['sa-news/categoria/' + id_tipo])
  }

  goArtigo(slug: string, categoria: string) {
    this.router.navigate(['sa-news/', categoria, slug])
  }
}
