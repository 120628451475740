import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { PalestrasService } from 'src/app/services/palestras';
import { ModalMediasComponent } from '../../../modal-medias';

@Component({
  selector: 'app-tb-eventos',
  templateUrl: './tb-eventos.component.html',
  styleUrls: ['./tb-eventos.component.scss']
})
export class TbEventosComponent implements OnInit {
  bsModalRef: BsModalRef;

  @Output("toUpdate") toUpdate = new EventEmitter();

  @Input() meses: Array<any> = []
  @Input() palestras: Array<any> = []
  loading: boolean = false
  // palestras: Array<any> = []
  // meses: Array<any> = []
  idUsuario: number
  mesAtual: string = ""

  constructor(
    private palestrasService: PalestrasService,
    private modalService: BsModalService,
    private toast: ToastrService,
    private router: Router,
    private app: AppComponent) { }

  ngOnInit(): void {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    this.idUsuario = usuario.id

    this.getPalestras()
  }

  async getPalestras() {
    try {
      this.loading = true
      const res = await this.palestrasService.getPalestrasAgenda(true)
      this.palestras = res.palestras

      this.loading = false
    } catch (error) {
      this.toast.error("Não foi possível carregar a agenda")
      this.loading = false
    }
  }

  setGroupMonth() {
    let setMeses = new Set(this.palestras.map(item => item.mes));
    this.meses = [];
    setMeses.forEach(g => {
      this.meses.push({
        name: g,
        values: this.palestras.filter(i => i.mes == g)
      })
    });
  }

  update(id: number) {
    this.toUpdate.emit({ id: id })
  }

  mediasDownload(id: number) {
    if (document.location.hostname == 'localhost'){
      console.error('A geração de medias não pode ser realizada no ambiente local!')
      return
    }

    try {
      const initialState = { id: id, download: true };
      const config = { initialState }
      this.bsModalRef = this.modalService.show(ModalMediasComponent, config);
    } catch (error) {
      console.log(error);
      this.toast.error("Ocorreu algum erro ao concluir o processo.", "Não foi possível gerar as mídias")
    }
  }

  warningChangeProfile() {
    const initialState = { mensagem: "Para assistir e se increver em eventos, entre como ESPECTADOR", submensagem: "Deseja alterar agora?" }
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config);
    let newSubscriber = this.modalService.onHide.subscribe(async () => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value
      if (value == true) {
        localStorage.setItem("perfil", "ESPECTADOR")
        await this.router.navigate(['/espectador/agenda']);
        await window.location.reload()
      }
    })
  }
}
