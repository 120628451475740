<div class="d-flex justify-content-between">
    <h4 class="font-title mb-2">Tarefas</h4>
</div>

<div>
    <div *ngIf="!perguntas || perguntas.length == 0"> <span class="empty">Nenhuma tarefa cadastrada</span> </div>
    <ul class="list-group">
        <li class="list-group-item list__item" (click)="setPergunta(item)"
            [class.selected]="item.id == perguntaSelected?.id" *ngFor="let item of perguntas">
            {{item.pergunta}} - {{item.tipo}}</li>

        <div *ngIf="idHabilidade > 0 && isOwner && perguntas && perguntas.length < 3" class="div__insert" title="Adicionar tarefa"
            (click)="insert()">
            <img height="19px" src="assets/icons/mais_circle.svg" alt="adicionar">
            <span class="label__input font-title text-uppercase ml-4">Adicionar</span>
        </div>
    </ul>
</div>

<div [class.d-none]="!perguntaSelected" class="border-bottom mt-2"></div>

<div class="container__pergunta mt-2">
    <app-form-pergunta #formPerguntaComponent [idHabilidade]="idHabilidade" [opcoes]="perguntaSelected?.opcoes"
        (finished)="formFinished()">
    </app-form-pergunta>

</div>