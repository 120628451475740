
<div *ngIf="loading" class="cardTrilha" [class]="outerClasses">
  <figure class="cardTrilha__img loadingImage"></figure>

  <div class="cardTrilha__content">
    <span class="tagBadge loadingText">
      Carregando
    </span>

    <h3 class="cardTrilha__title loadingText"></h3>
  </div>
</div>

<a
  *ngIf="!loading"
  class="cardTrilha"
  [class]="outerClasses"
  [title]="skill.habilidade_titulo"
  [routerLink]="['/espectador/minhaTrilha', skill.id_habilidade]"
>
  <figure class="cardTrilha__img" [style.backgroundImage]="'url(' + skill.habilidade_imagem_link +')'">
    <span *ngIf="skill.arquivo_principal" class="tagBadge tagBadge--primary tagBadge--figure">
      {{skill.arquivo_principal}}
    </span>
    <img [src]="skill.habilidade_imagem_link" [alt]="skill.habilidade_titulo">
    <span *ngIf="concluded" class="cardTrilha__badgeConcluded">
      <i class="fas fa-check"></i>
    </span>
  </figure>

  <div class="cardTrilha__content">
    <span *ngIf="skill.competencia_nome" class="tagBadge">{{skill.competencia_nome}}</span>

    <h3 *ngIf="skill.habilidade_titulo" class="cardTrilha__title">
      {{skill.habilidade_titulo}}
    </h3>

    <a
      *ngIf="textButton"
      href="javascript:void()"
      class="btn btnPlay cardTrilha__button"
      title="Clique para iniciar a sua trilha"
      [routerLink]="['/espectador/minhaTrilha', skill.id_habilidade]"
    >
      <img height="10" src="assets/icons/play.svg" alt="Iniciar trilha">
      {{textButton}}
    </a>
  </div>
</a>
