import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TbsbService {
  constructor(public http: HttpClient) { }

  getVideos(page: number, size: number, category: string, status: string, term: string) {
    const query = `?page=${page}&size=${size}&category=${category}&status=${status}&term=${term}`;
    return this.http.get(`${environment.tbsbBaseApi}api/1.0/videos${query}`);
  }

  getVideoById(id: number) {
    return this.http.get(`${environment.tbsbBaseApi}api/1.0/videos/${id}`);
  }

  approveOrDenieVideo(id: number, body: any) {
    return this.http.put(`${environment.tbsbBaseApi}api/1.0/videos/approve-denie/${id}`, body);
  }

  updateYoutubeUrl(id: number, body: any) {
    return this.http.put(`${environment.tbsbBaseApi}api/1.0/videos/youtube-url/${id}`, body);
  }

  // notices
  createNotice(body: any) {
    return this.http.post(`${environment.tbsbBaseApi}api/1.0/notices`, body);
  }

  editNotice(body: any, id: string) {
    return this.http.put(`${environment.tbsbBaseApi}/api/1.0/notices/${id}`, body);
  }

  getNotices() {
    return this.http.get(`${environment.tbsbBaseApi}api/1.0/notices`);
  }

  // palestrantes
  getSpeakers(filters: any) {
    const query = `category=${filters.category}&dateStart=${filters.dateStart}&dateEnd=${filters.dateEnd}&email=${filters.email}`;
    return this.http.get(`${environment.tbsbApi}api/1.0/users/dashboard?${query}`);
  }

  updateRanking(body: any) {
    return this.http.post(`${environment.tbsbBaseApi}api/1.0/ranking`, body);
  }

  getAllUsers() {
    return this.http.get(`${environment.tbsbApi}api/1.0/users`);
  }
}
