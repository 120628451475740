import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RdStationService {
  private URL = 'https://www.rdstation.com.br/api/1.3/conversions';

  constructor(public http: HttpClient) { }

  async formNewsletter(body: object) {
      body['client_id'] = '3cdbe999-7697-4fad-b2ff-1e9a19e51869';
      body['token_rdstation'] = 'e923c5339f3ffe45630379ccf2798207';
      body['form_url'] = window.location.href;
      body['identificador'] = body['identificador'] ? body['identificador'] : 'ctr_form';

      return await this.http.post<any>(`${this.URL}`, body).toPromise();
  }

  async SendEmailInfoRD(bodyRD: object): Promise<any>{
    let body = JSON.stringify({ bodyRD: bodyRD });

    await fetch(environment.API_URL, {
      method: "POST",
      headers: {
        Accept: 'application/json',
        action: 'SendEmailInfoRD',
        'Content-Type': 'application/json',
        token: null
      },
      body: body
    });
  }
}
