<header class="modal-header p-2">
    <h3 class="font-title">
        {{contact.id > 0? 'Editar ' + contact.nome.split(" ")[0] : 'Adicionar Administrador'}}
    </h3>
    <div class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </div>
</header>
<section class="modal-body">
    <form class="mb-3" (submit)="salvarContato()">
        <div class="row pl-1">
            <div class="col-12 col-lg-6">

                <div class="form-group">
                    <label class="label-input" for="nome">nome</label>
                    <input class="form-control" name="nome" type="text" placeholder="Nome *" [(ngModel)]="contact.nome">
                </div>

                <div class="form-group">
                    <label class="label-input" for="email">e-mail</label>
                    <input class="form-control" name="email" type="email" placeholder="E-mail *"
                        [(ngModel)]="contact.email">
                </div>

                <div class="form-group">
                    <label class="label-input" for="telefone">telefone</label>
                    <input class="form-control" name="telefone" type="text" placeholder="Telefone"
                        [(ngModel)]="contact.telefone" mask="(00) 0000-0000||(00) 0 0000-0000">
                </div>

                <div>
                    <label class="label-input mr-2" for="principal">Principal</label>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="principal_s" name="principal" class="custom-control-input" value="SIM"
                            [(ngModel)]="contact.principal">
                        <label class="custom-control-label radio_text" for="principal_s">SIM</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="principal_n" name="ativo" class="custom-control-input" value="NAO"
                            [(ngModel)]="contact.principal">
                        <label class="custom-control-label radio_text" for="principal_n">NÃO</label>
                    </div>
                </div>

            </div>
            <div class="col-12 col-lg-6">

                <div class="form-group">
                    <label class="label-input" for="cpf_cnpj">CPF OU CNPJ</label>
                    <input class="form-control" name="cpf_cnpj" type="text" placeholder="CPF ou CNPJ"
                        [(ngModel)]="contact.cpf_cnpj" mask="000.000.000-00||00.000.000/0000-00">
                </div>

                <div class="form-group">
                    <label class="label-input" for="departamento">departamento</label>
                    <input class="form-control" name="departamento" type="text" placeholder="Departamento"
                        [(ngModel)]="contact.departamento">
                </div>

                <div class="form-group">
                    <label class="label-input" for="cargo">cargo</label>
                    <select class="form-control" name="cargo" placeholder="Cargo *" [(ngModel)]="contact.id_cargo">
                        <option *ngFor="let option of cargos" [value]="option.id">{{option.descricao}}</option>
                    </select>
                </div>

                <p *ngIf="mensagem" class="text-danger">{{mensagem}}</p>

            </div>

            <div class="col-12 d-flex mt-2 justify-content-end">
                <button (click)="close()" type="button" class="btn__secondary mr-2">Cancelar</button>

                <button *ngIf="!loading" type="submit" class="btn__primary btn-warning">Salvar</button>

                <button *ngIf="loading" class="btn__primary mt-2">
                    <img height="40px" src="assets/img/simple_loading.gif" alt="loading">
                </button>
            </div>
        </div>

    </form>
</section>