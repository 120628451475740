import { Routes } from '@angular/router';

import { HomeComponent } from './home';
import { SkillDetailComponent } from './skill-detail';
import { SkillTaskComponent } from './skill-task';

export const DesenvolvimentoRoutes: Routes = [
  {
    path: 'desenvolvimento/home',
    component: HomeComponent
  },
  {
    path: 'desenvolvimento/habilidade/:id',
    component: SkillDetailComponent
  },
  {
    path: 'desenvolvimento/habilidade/tarefa/:id',
    component: SkillTaskComponent
  },
  {
    path: 'desenvolvimento/**',
    redirectTo: 'desenvolvimento/home'
  },
];
