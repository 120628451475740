import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {GrupoService} from '../../../services/grupo';
import {ContratanteService} from '../../../services/contratante';
import {IColaborador} from '../colaboradores';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SubstituirLiderComponent} from '../substituir-lider';

@Component({
  selector: 'app-grupo-tb-colaboradores',
  templateUrl: './grupo-tb-colaboradores.component.html',
  styleUrls: ['./grupo-tb-colaboradores.component.scss'],
})
export class GrupoTbColaboradoresComponent implements OnInit {

  @Input() id_grupo: number;
  @Input() id_usuario_lider: number;

  colaboradores: Array<IColaborador>;

  loading = false;
  qtdePages: Array<number> = [];
  page = 0;
  search = '';

  constructor(
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private grupoService: GrupoService,
    private toast: ToastrService) {
  }

  ngOnInit(): void {
    this.getColaboradores();
  }

  async getColaboradores() {
    this.loading = true;
    const resColaboradores = await this.grupoService.getGrupoColaboradoresByTutor(this.id_grupo, this.id_usuario_lider, this.page, this.search)
      .catch((error) => {
        console.log(error);
        this.toast.error('Nçao foi possível efetuar a busca.');
        this.loading = false;
        return;
      });
    this.loading = false;
    this.setQtdePages(resColaboradores.quantidade);
    this.colaboradores = resColaboradores.colaboradores ? resColaboradores.colaboradores : [];
  }

  async delete(id_grupo_equipe: number) {
    try {
      this.loading = true;
      await this.grupoService.removeColaboradorEquipe(id_grupo_equipe);
      this.loading = false;
      this.toast.success('Colaborador removido do grupo.');
      this.getColaboradores();
    } catch (error) {
      this.loading = false;
      this.toast.error('Não foi possível deletar colaborador.');
    }
  }

  changePage(number: number) {
    this.page = number;
    this.getColaboradores();
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array.from(Array(qtdeColaboradores).keys());
  }

  getPages() {
    const listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2));
    return listPage;
  }

  buscar() {
    this.page = 0;
    this.getColaboradores();
  }

  changeTutor(colaborador: IColaborador) {
    const initialState = {colaborador};
    const config = {class: 'modal-play', initialState};
    this.bsModalRef = this.modalService.show(SubstituirLiderComponent, config);
    const newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      this.getColaboradores();
      this.modalService.hide();
    });
  }
}
