<div class="content">
    <div (click)="refresh()" [class.d-none]="!canClose" class="close" aria-label="Close" >
        <span style="cursor: pointer;" aria-hidden="true">&times;</span>
    </div>
    <p class="text">
        Estamos contente por você compartilhar seus desafios de educação corporativa com a gente!
    </p>

    <p class="text">
        Se você deseja contratar com a Profissionais S.A, agora seu painel é via plataforma Rok's. 
        Entre em contato conosco através do site e solicite uma demonstração!
    </p>
    <img class=" mt-5 mb-4" src="../../../assets/img/logo-roks-orange.svg" />
    <a href="https://theroks.com.br/">
        <button class="btn bg-warning mt-0">IR PARA O SITE ROK's</button>
    </a>
</div>