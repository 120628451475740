<div aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="p-5">

        <div class="modal-header d-flex flex-column">
            <div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </div>
            <p>
                {{text}}
            </p>
            <p>{{textSchedule}}</p>
        </div>

        <div [class.dnone]="preenchercodigo" class="formcodigo margin">
            <button (click)="avancar()" class="btn btn-primary btn-warning">Inserir código</button>
        </div>

        <div class="margin formcodigo" [class.dnone]="preenchercodigo == false">
            <input placeholder="Código de permissão" type="text" [(ngModel)]="codigoInput">
            <button (click)="compareCode() " class="btn btn-primary btn-warning">Liberar</button>
            <label *ngIf="mensagem" class="err">{{mensagem}}</label>
        </div>
    </div>
</div>