import { Routes } from '@angular/router';

import { TemasComponent } from './temas';
import { TemaComponent } from './tema';
import { PalestraComponent } from './palestra';

export const PalestrasRoutes: Routes = [
  {
    path: 'palestras/temas',
    component: TemasComponent
  },
  {
    path: 'palestras/tema/:nome',
    component: TemaComponent
  },
  {
    path: 'palestras/subtema/:nome',
    component: TemaComponent
  },
  {
    path: 'palestras/disponivel/:slug',
    component: PalestraComponent
  },
];
