import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ComunicacaoService } from 'src/app/services/comunicacao';

export interface IPushDataNotificationHistory {
  body: string
  image: string
  title: string
}

export interface IPushDataHistory {
  notification: IPushDataNotificationHistory
  data: any
  tokens: any
}
export interface IHistory {
  contratante_id: number
  created_at: string
  created_by: number
  id: number
  message: IPushDataHistory
  recipient_user_id: Array<any>
  schedule_date: string
  status: number
  status_message: string
  type: string
  updated_at: string
  lengthSuccess: number | null
}

@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss']
})
export class HistoricoComponent implements OnInit, OnDestroy {

  limit: number = 12;
  page: number = 0;
  qtdePages: Array<number>;
  loading: boolean = false;
  search: string = "";
  history: Array<IHistory> = [];

  constructor(
    private titleService: Title,
    private toast: ToastrService,
    private comunicacaoService: ComunicacaoService,) { }

  ngOnDestroy(): void {
    this.titleService.setTitle("Dashboard")
  }

  ngOnInit(): void {
    this.titleService.setTitle("Comunicação interna - Histórico")
    this.load()
  }
  async load() {
    try {
      this.loading = true
      const res: Array<IHistory> = await this.comunicacaoService.getHistory()
      
      this.history = res
      this.prepareFilter()
      this.loading = false
    } catch (error) {
      this.toast.error("Não foi possível carregar o histórico")
      this.loading = false
    }
  }

  getHistoryFilter() {
    return this.history.filter((i: IHistory) => (
      i.message.notification.title.toLowerCase().includes(this.search.toLowerCase())
      || i.message.notification.body.toLowerCase().includes(this.search.toLowerCase())
    ))
  }

  getHistory() {
    return this.getHistoryFilter().slice(((this.page + 1) * this.limit) - this.limit, (this.page + 1) * this.limit)
  }

  changePage(number: number) {
    this.page = number;
  }

  setQtdePages(amount: number) {
    this.qtdePages = Array(amount);
  }

  prepareFilter() {
    this.page = 0
    let list = this.getHistoryFilter()
    this.setQtdePages(Math.ceil(list.length / this.limit))
  }
}
