import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-validacao-senha',
  templateUrl: './check-validacao-senha.component.html',
  styleUrls: ['./check-validacao-senha.component.scss']
})
export class CheckValidacaoSenhaComponent implements OnInit {

  @Input() senha: string

  isValidPasswordLength: boolean = false
  isValidPasswordLowerCap: boolean = false
  isValidPasswordNumber: boolean = false
  colorPasswordLength: string = '#6c757d'
  colorPasswordLowerCap: string = '#6c757d'
  colorPasswordNumber: string = '#6c757d'

  constructor() { }

  ngOnInit(): void { }

  checkPasswordRule() {
    this.check()
  }

  checkPasswordRuleValue(value: string) {
    this.senha = value
    if (!this.senha || this.senha.length == 0) this.senha = ""
    this.check()
  }

  check() {
    const passRegexNumberCaracter = /^\S{8,20}$/;
    const passRegexLowerCap = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})/
    const passRegexnumberOnstring = /^(?=(.*[\d]){1,})/

    this.isValidPasswordLength = passRegexNumberCaracter.test(this.senha)
    this.isValidPasswordLowerCap = passRegexLowerCap.test(this.senha)
    this.isValidPasswordNumber = passRegexnumberOnstring.test(this.senha)
    this.colorPasswordLength = this.isValidPasswordLength ? 'green' : 'red'
    this.colorPasswordLowerCap = this.isValidPasswordLowerCap ? 'green' : 'red'
    this.colorPasswordNumber = this.isValidPasswordNumber ? 'green' : 'red'
  }
}
