import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ISkill } from 'src/app/services/tarefas';

@Component({
  selector: 'app-skill-template',
  templateUrl: './skill-template.component.html',
  styleUrls: ['./skill-template.component.scss']
})
export class SkillTemplateComponent implements OnInit {
  @Input() skill: ISkill
  @Input() lengthStarted: number = 0

  constructor(private router: Router, private toast :ToastrService) { }

  ngOnInit(): void {
  }


  detail(id: number, isNext: number, status: string) {
    if (isNext != 1 && status === "PENDENTE" && this.lengthStarted >= 3) {
      this.toast.error("Antes de seguir, finalize seus conteúdos em andamento.")
      return 
    }
    else if (isNext != 1 && status !== "INICIADA" && status !== "AVALIADA" && status !== "FINALIZADA") {
      this.toast.error("Aguarde o tutor avaliar as tarefas que você respondeu!")
      return 
    }  
    this.router.navigate([`/desenvolvimento/habilidade/${id}`])
  }
}
