import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ContratanteService } from 'src/app/services/contratante';
import { ImportComponent } from '../import';

export interface IColaborador {
  id_usuario: number
  id_cargo: number
  id_colaborador: number
  id_contratante: number
  id_espectador: number
  id_grupo: number
  grupo_nome: string
  nome_completo: string
  email: string
  cpf: string
  setor: string
  responsavel_grupo: string
  cargo_nome: string
  contratante_ativo: string
  removable: number
}

@Component({
  selector: 'app-colaboradores',
  templateUrl: './colaboradores.component.html',
  styleUrls: ['./colaboradores.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ColaboradoresComponent implements OnInit {
  bsModalRef: BsModalRef;

  tab: string = null;

  constructor(
    private modalService: BsModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private contratanteService: ContratanteService) { }

  ngOnInit(): void {
    this.tab = this.activatedRoute.snapshot.paramMap.get('tab');
  }

  back() {
    window.history.back()
  }

  async goGrupos() {
    try {
      const res = await this.contratanteService.hasGrupo()

      this.router.navigate([res.hasGrupo == true ? '/universidade/grupos' : '/universidade/0/lideres'])
    } catch (error) {
      this.router.navigate(['/universidade/grupos'])
    }
  }

  import() {
    const config = { class: 'modal-play' }
    this.bsModalRef = this.modalService.show(ImportComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
    })
  }
}
