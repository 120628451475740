<div class="container__dados">

    <form class="row col-12" (submit)="salvar()">

        <div class="col-12 col-lg-3 d-flex flex-column">
            <div style="flex: 5" class="d-flex flex-column align-items-center">
                <div class="d-flex flex-column justify-content-center container__dados__imagem">
                    <img (click)="alterarFoto()"
                        src="{{usuario?.photo || 'https://basesa.s3-sa-east-1.amazonaws.com/imagens/logo_img_alternativa.png'}}"
                        alt="foto usuário">
                </div>
                <label (click)="alterarFoto()" class="label-input label__alterar">Alterar Foto</label>
            </div>

            <div style="flex: 1" class="form-group">
                <label class="label-input" for="id">seu código para indicação</label>
                <input class="form-control" name="id" type="text" [value]="usuario.id" disabled>
            </div>

            <div style="flex: 1" class="form-group">
                <label class="label-input" for="pais">quem te indicou</label>
                <input class="form-control" name="pais" type="text"
                    [value]="usuario.codigo_indicacao ? usuario.perfil_indicacao + ' - ' + usuario.nome_completo_indicacao : ''"
                    disabled>
            </div>

            <div style="flex: 1"><app-concorda-termos></app-concorda-termos></div>
        </div>


        <div class="col-12 col-lg-9 d-flex flex-column">
            <div class="row">
                <div class="form-group col-12 col-lg-5">
                    <label class="label-input" for="empresa">nome fantasia</label>
                    <input class="form-control" name="empresa" type="text" [(ngModel)]="usuario.empresa"
                        disabled="{{isDisable}}" placeholder="Nome fantasia *" [class.invalid]="isIncorrect('empresa')">
                    <span class="error">{{getError('empresa')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="razao_social">razão social</label>
                    <input class="form-control" name="razao_social" type="text"
                        [(ngModel)]="usuario.nome_completo_razao_social" placeholder="Razão social"
                        [class.invalid]="isIncorrect('razao_social')" disabled="{{isDisable}}">
                    <span class="error">{{getError('razao_social')}}</span>
                </div>

                <div class="form-group col-12 col-lg-3">
                    <label class="label-input" for="cpf_cnpj">cnpj</label>
                    <input class="form-control" name="cpf_cnpj" type="text" [(ngModel)]="usuario.cpf_cnpj"
                        mask="00.000.000/0000-00" placeholder="CNPJ" [class.invalid]="isIncorrect('cpf_cnpj')"
                        disabled="{{isDisable}}">
                    <span class="error">{{getError('cpf_cnpj')}}</span>
                </div>
            </div>


            <div class="row">
                <div class="form-group col-12 col-lg-5">
                    <label class="label-input" for="email">e-mail</label>
                    <input class="form-control" name="email" type="email" [(ngModel)]="usuario.email"
                        [class.invalid]="isIncorrect('email')" disabled>
                    <span class="error">{{getError('email')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="telefone">telefone</label>
                    <input class="form-control" name="telefone" type="text" [(ngModel)]="usuario.telefone"
                        mask="(00) 0000-0000||(00) 0 0000-0000" placeholder="Telefone *"
                        [class.invalid]="isIncorrect('telefone')" disabled="{{isDisable}}">
                    <span class="error">{{getError('telefone')}}</span>
                </div>

                <div class="form-group col-12 col-lg-3">
                    <label class="label-input" for="segmento">segmento</label>
                    <select class="form-control" name="segmento" [(ngModel)]="usuario.segmento" placeholder="Segmento *"
                        [class.invalid]="isIncorrect('segmento')" disabled="{{isDisable}}">
                        <option *ngFor="let option of arraySegmentos" [value]="option.text">{{option.text}}</option>
                    </select>
                    <span class="error">{{getError('segmento')}}</span>
                </div>
            </div>


            <div class="row">
                <div class="form-group col-12 col-lg-5">
                    <label class="label-input" for="quantidade_funcionario">porte</label>
                    <select class="form-control" name="quantidade_funcionario" placeholder="Porte *"
                        [(ngModel)]="usuario.quantidade_funcionario"
                        [class.invalid]="isIncorrect('quantidade_funcionario')" disabled="{{isDisable}}">
                        <option *ngFor="let porte of arrayPorte" [value]="porte.value">{{porte.text}}</option>
                    </select>
                    <span class="error">{{getError('quantidade_funcionario')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="pais">país</label>
                    <input class="form-control" name="pais" type="text" [(ngModel)]="usuario.pais" placeholder="País *"
                        [class.invalid]="isIncorrect('pais')" disabled="{{isDisable}}">
                    <span class="error">{{getError('pais')}}</span>
                </div>

                <div class="form-group col-12 col-lg-3">
                    <label class="label-input" for="cep">cep</label>
                    <input (change)="completeCEP()" class="form-control" name="cep" type="text"
                        [(ngModel)]="usuario.cep" placeholder="CEP *" [class.invalid]="isIncorrect('cep')"
                        mask="00000-000" disabled="{{isDisable}}">
                    <span class="error">{{getError('cep')}}</span>
                </div>
            </div>


            <div class="row">
                <div class="form-group col-12 col-lg-5">
                    <label class="label-input" for="estado">estado</label>
                    <select class="form-control" name="estado" [(ngModel)]="usuario.estado" placeholder="Estado *"
                        [class.invalid]="isIncorrect('estado')" disabled="{{isDisable}}">
                        <option *ngFor="let estado of arrayEstados" [value]="estado.uf">{{estado.estado}}</option>
                    </select>
                    <span class="error">{{getError('estado')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="estado">cidade</label>
                    <input class="form-control" name="cidade" type="text" [(ngModel)]="usuario.cidade"
                        placeholder="Cidade *" [class.invalid]="isIncorrect('cidade')" disabled="{{isDisable}}">
                    <span class="error">{{getError('cidade')}}</span>
                </div>

                <div class="form-group col-12 col-lg-3">
                    <label class="label-input" for="bairro">bairro</label>
                    <input class="form-control" name="bairro" type="text" [(ngModel)]="usuario.bairro"
                        placeholder="Bairro *" [class.invalid]="isIncorrect('bairro')" disabled="{{isDisable}}">
                    <span class="error">{{getError('bairro')}}</span>
                </div>
            </div>


            <div class="row">
                <div class="form-group col-12 col-lg-5">
                    <label class="label-input" for="logradouro">logradouro</label>
                    <input class="form-control" name="logradouro" type="text" [(ngModel)]="usuario.logradouro"
                        placeholder="Logradouro *" [class.invalid]="isIncorrect('logradouro')" disabled="{{isDisable}}">
                    <span class="error">{{getError('logradouro')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="numero">número</label>
                    <input class="form-control" name="numero" type="text" [(ngModel)]="usuario.numero"
                        placeholder="Número *" [class.invalid]="isIncorrect('numero')" disabled="{{isDisable}}">
                    <span class="error">{{getError('numero')}}</span>
                </div>

                <div class="form-group col-12 col-lg-3">
                    <label class="label-input" for="complemento">complemento</label>
                    <input class="form-control" name="complemento" type="text" [(ngModel)]="usuario.complemento"
                        placeholder="Complemento" [class.invalid]="isIncorrect('complemento')" disabled="{{isDisable}}">
                    <span class="error">{{getError('complemento')}}</span>
                </div>
            </div>


            <div class="row">
                <div class="form-group col-12 col-lg-5">
                    <button *ngIf="isDisable" (click)="editar()"
                        class="btn btn-primary btn-warning btn__editar">Editar</button>
                </div>

                <div class="col-lg-4"></div>

                <div class="form-group col-12 col-lg-3">
                    <button *ngIf="isDisable" (click)="editar()"
                        class="btn btn-primary btn-warning d-lg-none">Editar</button>

                    <p *ngIf="mensagem" class="text-danger">{{mensagem}}</p>
                    <div class="d-flex div__btn__salvar">
                        <button *ngIf="!isDisable && !loading" type="submit"
                            class="btn btn-primary btn-warning">Salvar</button>

                        <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
                            <img height="40px" src="../../../assets/img/simple_loading.gif" alt="loading">
                        </button>
                    </div>

                </div>
            </div>


        </div>


    </form>
</div>