<section class="w-100">
  <div class="w-100">
    <label>Status</label>
    <select [(ngModel)]="status">
      <option value="public">Público</option>
      <option value="private">Privado</option>
    </select>
  </div>
</section>

<form
  [formGroup]="newNotice"
  (submit)="updateInsertNotice()"
  class="flex flex-column w-100"
>
  <section class="w-100">
    <mat-form-field class="w-100">
      <mat-label>Título</mat-label>
      <input
        matInput
        [formControlName]="'title'" name="title"
        [type]="'text'"
      >
      <mat-error *ngIf="newNotice.get('title')?.invalid">Título deve conter entre 5 e 255 caractres</mat-error>
    </mat-form-field>
  </section>

  <section class="w-100">
    <mat-form-field class="w-100">
      <mat-label>Descrição</mat-label>
      <input
        matInput
        [formControlName]="'description'" name="description"
        [type]="'text'"
      >
      <mat-error *ngIf="newNotice.get('description')?.invalid">Descrição deve conter entre 5 e 255 caractres</mat-error>
    </mat-form-field>
  </section>

  <section class="w-100 flex flex-row justify-content-around py-3">
    <button type="submit" [disabled]="newNotice.invalid">Enviar</button>
    <button class="cancel" type="button" (click)="close()">Cancelar</button>
  </section>
</form>
