import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ModalPDFComponent } from 'src/app/common/modal-pdf';
import { ModalPlayComponent } from 'src/app/common/modal-play';
import { ISkill, TarefasService } from 'src/app/services/tarefas';

@Component({
  selector: 'app-skill-task',
  templateUrl: './skill-task.component.html',
  styleUrls: ['./skill-task.component.scss']
})
export class SkillTaskComponent implements OnInit {

  bsModalRef: BsModalRef;
  idSkill: number;
  loading: boolean = false
  skill: ISkill

  videoSource: string

  taskStarted: boolean = true

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private taskService: TarefasService,
    private titleService: Title,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get("id")
    if (!id) {
      window.history.back()
      return
    }
    this.idSkill = parseInt(id)
    this.getSkill()
  }

  async getSkill() {
    try {
      this.loading = true
      this.skill = await this.taskService.getSkillById(this.idSkill)
      this.skill.perguntas = await this.taskService.getQuestionsSkillById(this.idSkill)
      this.loading = false

      this.videoSource = this.skill.habilidade_video_link

      this.titleService.setTitle(`Tarefa ${this.skill.habilidade_titulo}`)
    } catch (error) {
      this.toast.error("Falha ao carregar conteúdo")
      this.loading = false
    }
  }

  startTask() {
    this.taskStarted = true
  }


  openVideo() {
    const initialState = {
      videoLink: this.skill.habilidade_video_link,
      title: this.skill.habilidade_titulo
    };
    const config = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(ModalPlayComponent, config);
  }

  openPDF() {
    const initialState = {
      link: this.skill.habilidade_pdf_link,
      title: this.skill.habilidade_titulo
    };
    const config = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(ModalPDFComponent, config);
  }

  showPDF() {
    this.taskService.getPDF(this.skill.habilidade_pdf_link).then(data => {
      var file = new Blob([data], {type: 'application/pdf'});
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);
    })
  }
}
