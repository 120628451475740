import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export enum AppUrlEnum {
  apple = 'https://apps.apple.com/br/app/profissionais-sa/id1507965029',
  google = 'https://play.google.com/store/apps/details?id=io.ionic.base_app_espec',
  apple_gerenciador = 'https://apps.apple.com/br/app/profissionais-sa/id1493923339',
  google_gerenciador = 'https://play.google.com/store/apps/details?id=com.palestrassa',
}

@Component({
  selector: 'app-modal-certificado',
  templateUrl: './modal-certificado.component.html',
  styleUrls: ['./modal-certificado.component.scss']
})
export class ModalCertificadoComponent implements OnInit {

  public appUrlEnum = AppUrlEnum;

  @Input('mensagem') mensagem: string = "Tenha acesso ao seu certificado e muito mais";
  @Input('submensagem') submensagem: string = "baixando gratuitamente o app com a senha enviada para seu e-mail de cadastro";

  isIOS: boolean = false
  isAndroid: boolean = false

  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit(): void { }

  goto(loja: string) {
    window.open(loja === 'apple' ? this.appUrlEnum.apple : this.appUrlEnum.google);
  }
}
