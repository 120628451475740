import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GrupoService } from 'src/app/services/grupo';
import { ContratanteService } from 'src/app/services/contratante';

export interface IGrupos {
  id: number,
  nome: string,
  lideres: number,
  colaboradores: number,
  descricao?: string,
  ativo: string
  tipo: "DEFAULT" | "PDI"
  perguntas_descritivas: boolean
  forma_criacao: "USUARIO" | "AUTOMATICO" | "IMPORTACAO" | "INTEGRACAO"
  libera_personalizar: boolean
}

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.scss']
})
export class GruposComponent implements OnInit {

  grupos: Array<IGrupos>;
  constructor(private router: Router,
    private toast: ToastrService,
    private grupoService: GrupoService,
    private contratanteService: ContratanteService,) { }

  ngOnInit(): void {
    this.getGrupos();
  }

  async getGrupos() {
    let res = await this.grupoService.getGrupos().catch(e => {
      console.log(e);
      return;
    });

    this.grupos = (res && res.length) ? res : [];
  }

  async inativar(grupo: IGrupos) {
    grupo.ativo = "NAO";
    const resultInativa = await this.grupoService.updateGrupo(grupo).catch(e => {
      grupo.ativo = "SIM";
      console.log(e);
      return;
    });
  }

  async ativar(grupo: IGrupos) {
    grupo.ativo = "SIM";
    const resultAtiva = await this.grupoService.updateGrupo(grupo).catch(e => {
      return;
    });
  }

  async goInsert() {
    try {
      const res = await this.contratanteService.hasGrupo()

      if (res.hasGrupo == false) {
        this.toast.error("Cadastre-os ou importe sua planilha!", res.message)
        return
      }
      this.router.navigate(['/universidade/0/lideres'])
    } catch (error) {
      return false
    }

  }

}
