import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PalestrantesService } from 'src/app/services/palestrantes';

@Component({
  selector: 'app-editar-palestrante',
  templateUrl: './editar-palestrante.component.html',
  styleUrls: ['./editar-palestrante.component.scss'],
})
export class EditarPalestranteComponent implements OnInit {
  activeTab: string = 'DADOS';
  usuarioId: number;
  palestrante: any;

  canEdit: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private palestranteService: PalestrantesService
  ) {}

  ngOnInit(): void {
    const perfil = localStorage.getItem('perfil');
    if (perfil === 'ADMIN' || perfil === 'AVALIADOR' || perfil === 'GESTOR' || perfil === 'CS') {
      this.canEdit = true;
    } else {
      this.canEdit = false;
    }
    this.activatedRoute.params.subscribe((params) => {
      this.usuarioId = params.id;

      this.fetchPalestrante();
    });
  }

  async fetchPalestrante() {
    const response = await this.palestranteService.getDadosByUserId(
      this.usuarioId
    );

    this.palestrante = response.palestrante;
  }

  changeActiveTab(tabName: string) {
    this.activeTab = tabName;
  }
}
