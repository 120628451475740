<div class="content">

    <section class="section__cabecalho">
        <div class="container_fluid">
            <div>
                <app-wizard-calculadora [selected]="3"></app-wizard-calculadora>
            </div>
        </div>

        <div class="container_fluid content__cabecalho col-12 col-lg-6 offset-lg-3 p-3">
            <h2>Confira a sua caixa de entrada!</h2>
            <p>Caso não encontre o e-mail na sua caixa de entrada, procure também na caixa de spam. Esta é uma
                estimativa de investimento, se o orçamento calculado não estiver adequado a necessidade da sua empresa,
                fale conosco através do email contato@profissionaissa.com e conte com a nossa Curadoria para
                personalizar cada detalhe para o seu desafio.</p>
        </div>
    </section>

    <section>
        <div class="content__info">

            <h3 class="mt-3">Conheça as soluções em educação corporativa da Profissionais SA</h3>

            <div class="d-flex flex-column flex-lg-row py-5 mx-5">
                <div class="col-lg-3"></div>

                <div class="content__info__div col-12 col-lg-3 mr-lg-5 mb-5">
                    <div class="row">
                        <div class="col-4">
                            <img src="../../../assets/icons/table.svg" alt="table">
                        </div>
                        <div class="col-8">
                            <p class="content__info__description">Solicite agora o contato de um dos nossos Curadores e
                                receba um orçamento personalizado</p>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-center">
                        <button (click)="openWhats()" class="btn__info mt-3">
                            <img src="../../../assets/icons/whatsapp-black.svg" alt="email">
                            ENTRAR EM CONTATO
                        </button>
                    </div>
                </div>


                <div class="content__info__div col-12 col-lg-3 mb-5">
                    <div class="row">
                        <div class="col-4">
                            <img src="../../../assets/icons/smart_news.svg" alt="table">
                        </div>
                        <div class="col-8">
                            <p class="content__info__description">Confira as últimas novidades no nosso Instagram</p>
                        </div>
                    </div>
                    <div class="col-12 d-flex align-items-center justify-content-center">
                        <button (click)="openInstagram()" class="btn__info mt-3">
                            <img src="../../../assets/icons/whatsapp-black.svg" alt="email">
                            SEGUIR INSTAGRAM
                        </button>
                    </div>
                </div>

            </div>

            <div class="mb-5 div__btn__refresh__calc">
                <button (click)="refreshCalc()" class="btn__refresh__calc">REFAZER CÁLCULO</button>
            </div>
        </div>
    </section>
</div>