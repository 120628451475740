<div class="modal-header">
    <div class="flexFull flex-column">
        <h5>Palestra corporativa</h5>
        <p>Insira o código fornecido pela sua empresa para se inscrever na palestra.</p>
    </div>
    <div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </div>
</div>
<div class="modal-body">
    <div class="d-flex flex-column">
        <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="code" placeholder="Código..">
            <label class="err">{{message}}</label>
        </div>
        <button class="btn__primary" (click)="subscripe()">enviar</button>
    </div>
</div>