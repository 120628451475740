import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActiveCampaignService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  getContact(email: string) {
    const body = {
      email,
    }
    return this.httpClient.post(`${environment.API_V2_URL_DEFAULT}palestrantes/contato`, body);
  }

  linkContact(contactId: string) {
    const body = {
      contactId,
    }

    return this.httpClient.post(`${environment.API_V2_URL_DEFAULT}palestrantes/linkContato`, body);
  }

  createContact(email: string) {
    const body = {
      email,
    }

    return this.httpClient.post(`${environment.API_V2_URL_DEFAULT}palestrantes/criarContato`, body);
  }
}
