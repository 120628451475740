import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormCertificatesService } from 'src/app/services/FormCertificates/form-certificates.service';
import { IInvalid } from '../../palestrantes/editar-palestrante/etapas/cache-admin/cache-admin.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss']
})
export class EditEventComponent implements OnInit {

  newForm: any = {
    speakerName: '',
    speakerEmail: '',
    talkTitle: '',
    eventName: '',
    speakerId: 123,
    emailCS: '',
    eventDate: '',
    businessName: '',
    emailClient: '',
    noAvaliation: false,
    noPSALogo: false,
  }

  invalids: Array<IInvalid> = []


  showConfirmation: boolean = false;

  formId: string;

  loading: boolean = false;

  constructor(
    private formCertificateService: FormCertificatesService,
    private toast: ToastrService,
    private activeRouter: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activeRouter.paramMap.subscribe(
      (result: any) => {
        if (!result.params.id) {
          this.toast.error('Parece que esse formulário não existe!');
        } else {
          this.formId = result.params.id;
          this.getForm();
        }
      }
    )
  }

  getForm() {
    this.loading = true;
    this.formCertificateService.getformById(this.formId).subscribe(
      (result: any) => {
        console.log(result.body[0]);
        this.newForm = result.body[0];
        this.loading = false;
      },
      (data: any) => {
        this.toast.error(data.message);
        this.loading = false;
      }
    )
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return "";
  }

  async editForm() {
    this.loading = true;
    console.log(this.formId);
    this.formCertificateService.editForms(this.newForm, this.formId).subscribe(
      (result: any) => {
        this.loading = false;
        console.log(result)
        this.toast.success('Formulário editado com sucesso!');
      },
      (data: any) => {
        this.loading = false;
        this.toast.error('Ocorreu um erro ao editar o formulário');
      }
    )
  }
}
