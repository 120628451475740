import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-palestras',
  templateUrl: './list-palestras.component.html',
  styleUrls: ['./list-palestras.component.scss']
})
export class ListPalestrasComponent implements OnInit {

  @Input() tipo: number //0 = ministrada - 1 = disponivel
  @Input() palestras: Array<any>
  @Input() palestrante: string

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  getLink(id: any) {
    return `https://s3-sa-east-1.amazonaws.com/basesa/palestras/media_email_${id}.png`
  }

  go(id: number) {
    if (this.tipo == 0)
      this.router.navigate(['palestrante/evento/', id])
    else
      this.router.navigate(['palestrante/perfil/produtos'], { queryParams: { id: id } })
  }

}
