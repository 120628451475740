<section class="mx-4">
  <div class="container-fluid">
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <h1 class="d-inline font-title">Eventos</h1>

      <div class="form-group d-flex justify-content-end flex-wrap flex-md-nowrap div__input__all">
        <label class="label-input mb-0 mr-3" for="getAll">Somente meus eventos:</label>

        <div class="d-flex">
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="all_s" name="getAll" class="custom-control-input" [value]="false"
              (change)="getPalestras()" [(ngModel)]="getAll">
            <label class="custom-control-label radio_text" for="all_s">Sim</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="all_n" name="getAll" class="custom-control-input" [value]="true"
              (change)="getPalestras()" [(ngModel)]="getAll">
            <label class="custom-control-label radio_text" for="all_n">Não</label>
          </div>
        </div>

        <button (click)="addEvent()" class="btn__add ml-3" title="Adicionar evento">Criar Evento</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 container__palestra" [class.effect]="eventCompFull" [class.col-md-7]="!eventCompFull">
        <div class="d-flex justify-content-center">
          <app-spinner [class.none]="!loading"></app-spinner>
        </div>

        <app-eventos *ngIf="!loading" (updating)="setFullScreen($event)" [palestras]="palestras" [all]="getAll"
          [meses]="meses"></app-eventos>
      </div>

      <div [class.v-none]="eventCompFull" [class.effectOpen]="!eventCompFull" class="col-12 col-md-5 pl-md-5 pt-5 pt-md-0">
        <div class="row">

          <div class="col-12 container__calendar">
            <app-calendario *ngIf="!loading" [palestrasDay]="palestras" (selected)="selected($event)">
            </app-calendario>
          </div>

          <div class="col-12 mt-4 list__palestras__agenda container__calendar">
            <app-list-palestras [tipo]="0" [palestras]="palestrasDay" [palestrante]=""></app-list-palestras>
          </div>

        </div>
      </div>

    </div>
  </div>
</section>
