import { Component, Input, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: 'app-modal-opt',
  templateUrl: './modal-opt.component.html',
  styleUrls: ['./modal-opt.component.scss']
})
export class ModalOptComponent implements OnInit {
  @Input('aceitaTermos') aceitaTermos: boolean;

  constructor(
    private bsModalRef: BsModalRef,
  ){

  }

  ngOnInit(): void {

  }

  aceitar() {
    this.bsModalRef.hide();
  }
}
