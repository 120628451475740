import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { meses } from 'src/app/common/array-meses';
import { CommonService } from 'src/app/services/common';
import { PlanosService } from 'src/app/services/planos';
import { UserService } from 'src/app/services/user';
import { IInvalid } from '../../configuracoes/palestrante/tb-dados';

interface ICard {
  nome: string,
  numero: string,
  codigo: string,
  mes: string,
  ano: string,
  renomado: string,
}

@Component({
  selector: 'app-modal-palestra-extra',
  templateUrl: './modal-palestra-extra.component.html',
  styleUrls: ['./modal-palestra-extra.component.scss']
})
export class ModalPalestraExtraComponent implements OnInit {
  loading: boolean = false;
  success: boolean = false;

  form: ICard = {
    nome: '',
    numero: '',
    codigo: '',
    mes: '',
    ano: '',
    renomado: '',
  }

  arrayAnos = []
  arrayMeses = meses
  token: string

  invalids: Array<IInvalid> = []
  defaultMessage: string = "Campo VALUE deve ser preenchido";

  amount: number;

  constructor(
    public bsModalRef: BsModalRef,
    private toast: ToastrService,
    private userService: UserService,
    private commonService: CommonService,
    private planosService: PlanosService) {
    let date = new Date();
    let ano = date.getUTCFullYear();
    for (let i = 0; i < 12; i++) {
      this.arrayAnos.push(ano + i);
    }
  }

  async ngOnInit() {

    const valorConfig: { valor } = await this.commonService.getConfiguracao("valor__palestra__extra", '2000')
      .catch(e => { console.log(e); this.amount = 2000 })
    this.amount = parseInt(valorConfig.valor)

    let palestrante = JSON.parse(localStorage.getItem("palestrante"))
    this.form.renomado = palestrante.renomado

    let u = JSON.parse(localStorage.getItem("usuario"))
    this.userService.getToken(u.id)
      .then(res => this.token = res)
      .catch(e => this.toast.error("Não foi possível carregar seu código de acesso, entre em contato conosco: contato@profissionaissa.com"))
  }

  async pay() {
    this.validation()
    if (this.invalids.length > 0) {
      return
    }

    try {
      this.loading = true
      let user = JSON.parse(localStorage.getItem("usuario"))
      const result = await this.planosService.getnetTransaction({
        id_usuario: user.id, 
        card_name: this.form.nome, 
        card_number: this.form.numero, 
        security_code: this.form.codigo, 
        expiration_month: this.form.mes, 
        expiration_year: this.form.ano.substring(2,4), 
        amount: this.amount
      })

      await this.planosService.increaseLectureAvaiableInPlan()
      
      this.loading = false

      this.toast.success("Palestra extra adiquirida com sucesso")
      this.success = true
      this.bsModalRef.hide()

    } catch (e) {
      this.loading = false
      let message: string = e.error.message ? e.error.message : "Verifique seus dados e tente novamente"
      this.toast.error("Não foi possível realizar o pagamento.", message)
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }

  validation() {
    this.invalids = []
    if (!this.form.nome) this.invalids.push({ campo: "nome", erro: this.defaultMessage.replace("VALUE", "Nome no cartão") })
    if (!this.form.numero) this.invalids.push({ campo: "numero", erro: this.defaultMessage.replace("VALUE", "Número do cartão") })
    if (!this.form.codigo) this.invalids.push({ campo: "codigo", erro: this.defaultMessage.replace("VALUE", "Código do cartão") })
    if (!this.form.mes) this.invalids.push({ campo: "mes", erro: this.defaultMessage.replace("VALUE", "Mês") })
    if (!this.form.ano) this.invalids.push({ campo: "ano", erro: this.defaultMessage.replace("VALUE", "Ano") })
  }

  formatMoney(): string {
    let valor: number = this.amount / 100
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }
}
