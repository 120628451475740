import { Component, Input, OnInit, Output } from "@angular/core";
import { AngularEditorConfig } from "@kolkov/angular-editor";
// import { EventEmitter } from "stream";
import { EventEmitter } from "@angular/core";

@Component({
  selector: 'app-reasons',
  templateUrl: './reasons.component.html',
  styleUrls: ['./reasons.component.scss'],
})
export class ReasonsComponent implements OnInit {
  @Input('reasons') htmlReasons: any;
  @Output() newItemEvent = new EventEmitter();

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    showToolbar: true,
    enableToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor() {}

  ngOnInit(): void {

  }

  addNewItem(value: string) {
    this.newItemEvent.emit(this.htmlReasons);
  }
}
