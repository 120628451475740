import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit, OnDestroy {

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Configurações")
  }

  back(){
    window.history.back()
  }

  ngOnDestroy(): void {
    this.titleService.setTitle("Dashboard")
  }

}
