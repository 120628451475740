import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RouterModule } from '@angular/router';



import { HomeComponent } from './home';
import { CategoriaComponent } from './categoria';
import { ArtigoComponent } from './artigo';
import { InnerHtmlComponent } from './inner-html';


@NgModule({
  declarations: [HomeComponent, CategoriaComponent, ArtigoComponent, InnerHtmlComponent],
  imports: [
    CommonModule,
    CarouselModule,
    RouterModule
  ]
})
export class SaNewsModule { }
