import { Component, OnInit } from '@angular/core';
import { Toast, ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';

@Component({
  selector: 'app-cadastrar-exclusivo',
  templateUrl: './palestrante-exclusivo.component.html',
  styleUrls: ['./palestrante-exclusivo.component.scss']
})
export class PalestranteExclusivoComponent implements OnInit {
  speakers: any = [];

  exclusivo: any = {
    speakerId: null,
    emailContent: '',
    redirectPage: '',
    speaker: "",
  }

  file: any;

  loading: boolean = false;
  hasError: boolean = false;
  constructor(
    private palestrantesService: PalestrantesService,
    private toast: ToastrService,
  ) {}

  ngOnInit(): void {
    this.getSpeakers();
  }

  selectSpeaker() {
    console.log(this.exclusivo.speaker);
  }

  getSpeakers() {
    this.loading = true;
    this.palestrantesService.getSpeakersLight().subscribe(
      (result: any) => {
        this.speakers = result.body;
        this.loading = false;
      }
    );
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      var vFileTobeRead = event.target.files[0];
      var vFileReader = new FileReader();

      vFileReader.onload = () => {
        const fileSize = event.target.files[0].size / 1024 / 1024 / 1024;

        if (fileSize > 4) {
          event.target.files = null;
          event.target.value = null;

          const message = 'O arquivo selecionado ultrapassa o tamanho máximo de 4GB!';
          this.toast.error('Ocorreu um erro ao salvar a imagem');
        }
        else {
          const [file] = event.target.files;
          this.file = file
        }
      }

      vFileReader.readAsDataURL(vFileTobeRead);
    }
  }

  matchSpeaker() {
    try {
      const selected = this.speakers.filter((speaker: any) => speaker.nomeCompleto === this.exclusivo.speaker);

      if(!selected || selected.length === 0) {
        throw new Error('Palestrante não selecionado e/ou não encontrado.');
      } else {
        console.log(selected);
        this.exclusivo.speakerId = selected[0].id;
      }
    } catch (err) {
      this.hasError = true;
      this.toast.error(err.message);
    }
  }

  create() {
    this.matchSpeaker();

    const formData = new FormData();

    formData.append('speakerId', this.exclusivo.speakerId);
    formData.append('file', this.file);
    formData.append('emailContent', this.exclusivo.emailContent);
    formData.append('redirectPage', this.exclusivo.redirectPage);
    console.log(this.exclusivo);
    if(this.hasError || Object.values(this.exclusivo).includes("")) {
      this.toast.error('Preencha todos os campos');
    } else {
      this.loading = true;
      this.palestrantesService.createExclusivo(formData).subscribe(
        (result: any) => {
          console.log(result);
          this.loading = false;
          this.toast.success('Palestrante exclusivo cadastrado com sucesso!');
        },
        (data: any) => {
          this.loading = false;
          this.toast.error('Ocorreu um erro ao salvar o palestrante');
        }
      )
    }
  }
}
