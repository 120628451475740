<div class="content">
    <div *ngFor="let item of palestras" class="palestra__item" (click)="go(item.id)">
        <figure>
            <img *ngIf="tipo == 1" [class.d-none]="!item.photo" [src]="item.photo" [alt]="item.nome.substring(0,5)">
            <img *ngIf="tipo == 0" [src]="getLink(item.id)" [alt]="item.nome.substring(0,5)">
        </figure>

        <div class="content__info">
            <div class="palestra__tipo">
                <span>{{item.tipo}}</span>
            </div>
            <h6>{{item.nome}}</h6>
            <span class="info__secundary">por {{item.palestrante || palestrante}}</span>
            <span class="info__secundary">{{item.data_formatada || item.data}}</span>
        </div>
    </div>

    <div *ngIf="palestras.length == 0"><span class="empty">Nenhum evento</span></div>
</div>