import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ISkill, TarefasService } from 'src/app/services/tarefas';

@Component({
  selector: 'app-skill-detail',
  templateUrl: './skill-detail.component.html',
  styleUrls: ['./skill-detail.component.scss']
})
export class SkillDetailComponent implements OnInit {

  idSkill: number;
  loading: boolean = false
  skill: ISkill

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private taskService: TarefasService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get("id")
    if (!id) {
      window.history.back()
      return
    }
    this.idSkill = parseInt(id)
    this.getSkill()
  }

  async getSkill() {
    try {
      this.loading = true
      this.skill = await this.taskService.getSkillById(this.idSkill)
      this.loading = false
      this.titleService.setTitle(`Cursos ${this.skill.habilidade_titulo}`)

    } catch (error) {
      this.toast.error("Falha ao carregar conteúdo")
      this.loading = false
    }
  }

  async start() {
    try {
      if (this.skill.habilidade_status == "PENDENTE") {
        await this.taskService.updateStatus(this.skill.id_habilidade, "INICIADA")
      }
      this.router.navigate([`/desenvolvimento/habilidade/tarefa/${this.idSkill}`])
    } catch (error) {
      this.toast.error("Falha ao carregar conteúdo")
    }
  }
}
