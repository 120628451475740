<div class="p-1">
    <button (click)="bsModalRef.hide()" type="button" class="btn__close close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="d-flex flex-column p-5">
        <h1 class="font-title text-center mb-4">Termine de preencher os seus dados na nossa base!</h1>

        <p class="descricao mb-3">
            Estamos felizes em ter você conosco na Profissionais SA. Para que sua experiência fique completa ao utilizar
            a nossa plataforma de Educação Corporativa e liberar todas as funcionalidades, precisamos que você preencha
            mais alguns dados e aceite nossos termos e condições.
        </p>

        <div class="d-flex justify-content-center align-items-center">
            <label class="container__checkbox mb-4">Aceito os <a target="_blank"
                    href="https://s3-sa-east-1.amazonaws.com/basesa/termos-usuario.pdf">termos e condições</a> do
                contrato.
                <input type="checkbox" [(ngModel)]="concorda">
                <span class="checkmark"></span>
            </label>
        </div>

        <div class="d-flex justify-content-center align-items-center">
            <label class="container__checkbox mb-4">Concordo com as <a target="_blank"
                    href="https://basesa.s3-sa-east-1.amazonaws.com/politica_de_privacidade_e_termos_de_uso_de_dados_profissionais.pdf">
                    políticas de privacidade</a>.
                <input type="checkbox" [(ngModel)]="concordaLGPD">
                <span class="checkmark"></span>
            </label>
        </div>

        <button (click)="setTermos()" type="button" class="btn__primary">ATUALIZAR
            CADASTRO</button>
    </div>
</div>