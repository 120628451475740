<div class="container-fluid px-2 px-md-5 userProfile">
  <div *ngIf="loading" class="d-flex justify-content-center">
    <app-spinner></app-spinner>
  </div>
  <section class="profileCover" *ngIf="!loading">
    <div class="center">
      <!-- <div class="updateCoverBtn">Alterar capa</div> -->
    </div>

    <div class="bottom">
      <div class="profileLink">
        profissionaissa.com.br/palestrantes/{{ palestrante.slug }}
      </div>
      <select
        class="profileStatus buttonGray"
        name="status"
        style="
          outline: none !important;
          border: 0 !important;
          box-shadow: 0 !important;
        "
        [ngClass]="{
          published: palestrante?.autorizar_perfil === '1',
          unpublished: palestrante?.autorizar_perfil === '0'
        }"
        [value]="palestrante?.autorizar_perfil"
        (change)="changeProfileStatus($event?.target?.value)"
      >
        <option value="1">Perfil publicado</option>
        <option value="0">Perfil não publicado</option>
      </select>
      <button
        class="seePage buttonGray"
        style="background-color: #1b1b1b; color: #fff"
        (click)="goToSpeakerPage()"
      >
        Ver página publicada
      </button>
    </div>
  </section>
  <div class="row px-10" *ngIf="!loading">
    <div class="col-12 col-md-4 mt-4 marginProfileSection">
      <div class="container">
        <section class="col-12 container__default containerProfileMain">
          <div class="profilePictureBg">
            <img [src]="usuario?.photo || profilePicture" />
          </div>

          <div class="profileInfo">
            <span class="userName">{{ palestrante?.nome_palestrante }}</span>
            <span class="profileName">{{ usuario?.perfil }}</span>

            <div class="socialProfiles"></div>

            <button
              class="editProfileData buttonGray"
              (click)="openSection('EDIT_DADOS')"
            >
              Editar meus dados
            </button>
          </div>
        </section>
      </div>

      <div class="container">
        <section
          class="col-12 container__default containerCompletion"
          *ngIf="profileCompletion"
        >
          <div class="value">
            <div
              class="circle"
              [ngStyle]="{
                'background-image':
                  'conic-gradient(#d2973f ' +
                  profileCompletion +
                  '%, #d5ebd6 0)'
              }"
            >
              <div class="inner">{{ profileCompletion }}%</div>
            </div>
          </div>
          <div class="text-1">
            {{ usuario?.nome }}, seu cadastro está {{ profileCompletion }}%
            concluído
          </div>
          <div class="text-2">
            Mantenha seus dados atualizados e garanta mais oportunidades
          </div>
        </section>
      </div>

      <div class="container">
        <section
          class="col-12 container__default containerTimeline"
          *ngIf="profileCompletion"
        >
          <span class="timeline-section-title">MINHA TIMELINE</span>

          <div>
            <div class="timeline-item completed-item">
              <div class="bar-1"></div>
              <div class="dot-container"><div class="dot"></div></div>
              <div class="item-text">
                <span class="item-title">Conta criada</span>
                <span class="item-subtitle">Primeiro passo</span>
              </div>
            </div>
            <div
              class="timeline-item"
              [ngClass]="{ 'completed-item': profileCompletion >= 80 }"
            >
              <div class="bar-2"></div>
              <div class="dot-container"><div class="dot"></div></div>
              <div class="item-text">
                <span class="item-title">Completar cadastro</span>
                <span class="item-subtitle"
                  >Complete pelo menos 80% do seu cadastro</span
                >
              </div>
            </div>
            <div
              class="timeline-item"
              [ngClass]="{
                'completed-item':
                  profileCompletion >= 80 &&
                  palestrante?.autorizar_perfil === '1'
              }"
            >
              <div class="dot-container"><div class="dot"></div></div>
              <div class="item-text">
                <span class="item-title">Aprovado</span>
                <span class="item-subtitle">Pronto para palestrar</span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-12 col-md-8">
      <section class="containerProfileBio">
        <div class="slogan">
          {{ palestrante?.titulo }}
          <!-- <button class="buttonWhite" style="margin-left: 10px">
            <i
              ><img
                src="assets/icons/editar.svg"
                alt="Editar"
                title="Editar" /></i
            >Editar
          </button> -->
        </div>
        <div class="bioInfo">
          <span>{{ palestrante?.minibio }} </span>
        </div>
      </section>

      <div class="section">
        <section class="containerProfileDefault">
          <div class="title">
            MINHAS MÉTRICAS
            <div class="buttonGray">últimos 30 dias</div>
          </div>
          <div class="content">
            <div class="rowData">
              <div class="contentData">
                <div class="smallTitle">Pessoas interessadas</div>
                <div class="dataInfo">{{ propostasCount }} contatos</div>
              </div>

              <div class="contentData" style="margin-left: 10px">
                <div class="smallTitle">Visitas na página</div>
                <div class="dataInfo dataInfoComingSoon">EM BREVE</div>
              </div>
            </div>
            <div class="comingSoon">EM BREVE</div>
          </div>
        </section>
        <section class="containerProfileDefault themesDomain">
          <div class="title">
            TEMAS QUE DOMINO
            <button
              class="buttonWhite"
              style="margin-left: 10px"
              (click)="openSection('EDIT_TEMAS')"
            >
              <i
                ><img
                  src="assets/icons/editar.svg"
                  alt="Editar"
                  title="Editar" /></i
              >Editar
            </button>
          </div>
          <div class="content">
            <div class="domainThemes">
              <div class="themesCount">
                <div class="themesCountContainer">
                  <div class="themesCountNumber">
                    {{
                      temas?.length < 10
                        ? "0" + (temas ? temas?.length : "")
                        : temas?.length
                    }}
                  </div>
                  <div class="themesCountText">temas</div>
                </div>
              </div>

              <div class="themesList col" *ngIf="temas?.length > 0">
                <div *ngFor="let tema of temas">
                  <div class="themesTitle">#{{ tema }}</div>
                  <!-- <div class="themesTags">#subtemas #subtemas #subtemas</div> -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="row d-flex flex-row" style="margin-top: 20px">
        <section class="containerProfileDefault">
          <div class="title">
            MEU PORTFOLIO DE PRODUTOS
            <button
              class="buttonWhite"
              style="margin-left: 10px"
              (click)="openSection('EDIT_PORTFOLIO')"
            >
              <i
                ><img
                  src="assets/icons/editar.svg"
                  alt="Editar"
                  title="Editar" /></i
              >Editar
            </button>
          </div>
          <div class="col products">
            <div
              class="product"
              *ngFor="let palestra of palestrasDisponiveis; let idx = index"
              [ngClass]="{
                productCenter:
                  idx > 0 || idx === palestrasDisponiveis?.length - 1
              }"
              (click)="goToPalestraPage(palestra?.slug)"
            >
              <div class="productImage">
                <img
                  class="image"
                  [src]="palestra?.photo"
                  *ngIf="palestra?.photo"
                />
                <div class="image bgGrey" *ngIf="!palestra?.photo"></div>
                <!-- <button class="buttonWhite editProductBtn">
                    <i
                      ><img
                        src="assets/icons/editar.svg"
                        alt="Editar"
                        title="Editar" /></i
                    >Editar
                  </button> -->
              </div>
              <div class="productTitle">
                {{ palestra?.nome }}
              </div>
              <div class="productDescription">
                {{ palestra?.descricao }}
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="row d-flex flex-row" style="margin-top: 20px">
        <section class="containerProfileDefault">
          <div class="title">
            TABELA DE CACHES
            <button
              class="buttonWhite"
              style="margin-left: 10px"
              (click)="openSection('EDIT_CACHE')"
            >
              <i
                ><img
                  src="assets/icons/editar.svg"
                  alt="Editar"
                  title="Editar" /></i
              >Editar
            </button>
          </div>
          <div class="col products">
            <table class="customTable">
              <thead>
                <th>FORMATO</th>
                <th>DURAÇÃO</th>
                <th>VALOR</th>
                <th>PRINCIPAL</th>
              </thead>
              <tbody>
                <tr *ngFor="let c of caches">
                  <td>{{ c.nome_formato }}</td>
                  <td>{{ c.duracao }}</td>
                  <td>R$ {{ c.valor?.toFixed(2) }}</td>
                  <td>{{ c.principal === 1 ? "SIM" : "NÃO" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <div class="row d-flex flex-row" style="margin-top: 20px">
        <section class="containerProfileDefault">
          <div class="title">
            EXPERIÊNCIA PROFISSIONAL
            <button
              class="buttonWhite"
              style="margin-left: 10px"
              (click)="openSection('EDIT_EXPERIENCIA')"
            >
              <i
                ><img
                  src="assets/icons/editar.svg"
                  alt="Editar"
                  title="Editar" /></i
              >Editar
            </button>
          </div>
          <div class="col products">
            <table class="customTable">
              <thead>
                <th>CARGO</th>
                <th>EMPRESA</th>
                <th>ATUAL</th>
              </thead>
              <tbody>
                <tr *ngFor="let e of experiencias">
                  <td>{{ e.cargo }}</td>
                  <td>{{ e.empresa }}</td>
                  <td>{{ e.atual === 1 ? "SIM" : "NÃO" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <div class="row d-flex flex-row" style="margin-top: 20px">
        <section class="containerProfileDefault">
          <div class="title">
            FORMAÇÃO ACADÊMICA
            <button
              class="buttonWhite"
              style="margin-left: 10px"
              (click)="openSection('EDIT_FORMACAO')"
            >
              <i
                ><img
                  src="assets/icons/editar.svg"
                  alt="Editar"
                  title="Editar" /></i
              >Editar
            </button>
          </div>
          <div class="col products">
            <table class="customTable">
              <thead>
                <th>TITULO</th>
                <th>INSTITUIÇÃO</th>
                <th>CURSANDO</th>
              </thead>
              <tbody>
                <tr *ngFor="let f of formacoes">
                  <td>{{ f.titulo }}</td>
                  <td>{{ f.local }}</td>
                  <td>{{ f.cursando === 1 ? "SIM" : "NÃO" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>

      <div class="row d-flex flex-row" style="margin-top: 20px">
        <section class="containerProfileDefault">
          <div class="title">
            DEPOIMENTOS
            <button
              class="buttonWhite"
              style="margin-left: 10px"
              (click)="openSection('EDIT_DEPOIMENTOS')"
            >
              <i
                ><img
                  src="assets/icons/editar.svg"
                  alt="Editar"
                  title="Editar" /></i
              >Editar
            </button>
          </div>
          <div class="col products">
            <table class="customTable">
              <thead>
                <th>NOME</th>
                <th>EMPRESA</th>
                <th>DESCRIÇÃO</th>
              </thead>
              <tbody>
                <tr *ngFor="let d of depoimentos">
                  <td>{{ d.nome }}</td>
                  <td>{{ d.empresa }}</td>
                  <td>{{ d.descricao }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<div class="backdropSection" *ngIf="openedSection !== ''">
  <app-dados
    style="width: 100%"
    (onClose)="closeSection()"
    *ngIf="openedSection === 'EDIT_DADOS'"
  ></app-dados>

  <app-temas
    style="width: 100%"
    (onClose)="closeSection()"
    *ngIf="openedSection === 'EDIT_TEMAS'"
  ></app-temas>

  <app-palestras-disponiveis
    style="width: 100%"
    (onClose)="closeSection()"
    *ngIf="openedSection === 'EDIT_PORTFOLIO'"
  ></app-palestras-disponiveis>

  <app-cache
    style="width: 100%"
    (onClose)="closeSection()"
    *ngIf="openedSection === 'EDIT_CACHE'"
  ></app-cache>

  <app-experiencia
    style="width: 100%"
    (onClose)="closeSection()"
    *ngIf="openedSection === 'EDIT_EXPERIENCIA'"
  ></app-experiencia>

  <app-formacao
    style="width: 100%"
    (onClose)="closeSection()"
    *ngIf="openedSection === 'EDIT_FORMACAO'"
  ></app-formacao>

  <app-depoimentos
    style="width: 100%"
    (onClose)="closeSection()"
    *ngIf="openedSection === 'EDIT_DEPOIMENTOS'"
  ></app-depoimentos>
</div>
