<div class="container-fluid pl-lg-5 pr-lg-5">

    <div class="clearfix mb-3 pr-0 col-12">
        <h3 class="d-inline font-title mb-4">Configurações</h3>
        <button (click)="back()" type="button" class="btn btn-warning btn-back-circle float-right flexFull">
            <img class="float-right" src="assets/icons/back.svg" alt="cancelar">
        </button>
    </div>

    <div class="container__conteudo__perfil col-12 col-lg-12">
        <div>
            <tabset>

                <tab heading="Dados fiscais" [active]="tab == 'dados'">
                    <div class="margin"></div>
                    <app-tb-palestrante-dados (next)="next()"></app-tb-palestrante-dados>
                </tab>

                <tab heading="Dados bancários" [active]="tab == 'bancarios'">
                    <div class="margin"></div>
                    <app-tb-palestrante-dados-fiscais></app-tb-palestrante-dados-fiscais>
                </tab>

                <tab heading="Senha" id="senha">
                    <div class="margin"></div>
                    <app-tb-senha></app-tb-senha>
                </tab>

                <tab heading="Planos" [active]="tab == 'planos'">
                    <div class="margin"></div>
                    <app-tb-planos></app-tb-planos>
                </tab>
            </tabset>
        </div>
    </div>

</div>