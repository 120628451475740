import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NoticesComponent } from '../notices.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TbsbService } from 'src/app/services/tbsb/tbsb.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tbsb-modal-new-notice',
  templateUrl: './modal-new-notice.component.html',
  styleUrls: ['./modal-new-notice.component.scss'],
})
export class ModalNewNoticeComponent implements OnInit {
  newNotice: any = {};

  buttonLabel: string = 'Criar Aviso';

  loading: boolean = false;
  errorMessage: string = 'Teste erro';
  status: string = 'public';

  constructor(
    public dialogRef: MatDialogRef<NoticesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private noticesService: TbsbService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.newNotice = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(255)
      ]),
      description: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(255)
      ]),
    })
  }

  updateInsertNotice() {
    console.log(this.status)
    this.noticesService.createNotice({...this.newNotice.value, status: this.status }).subscribe(
      (result: any) => {
        this.toastr.success('Aviso criado com sucesso!');
        this.dialogRef.close();
      },
      (data: any) => {
        this.toastr.error('Erro ao criar aviso.');
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

}
