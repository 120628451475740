import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: HttpClient) { }

  async login(body: object): Promise<any> {
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "auth/login"}`, body).toPromise();

    return result.body;
  }

  async verificaPlano(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}auth/verifica-plano`, { headers: headers }).toPromise();
    return result.body;
  }

  async setSenha(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "auth/update-senha"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getCargos(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "usuarios/cargos"}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getDepartamentos(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "usuarios/departamentos"}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getTemas(): Promise<any> {
    let headers   = new HttpHeaders();
    headers       = headers.set('Authorization', localStorage.getItem("token"));

    const url     = `${environment.API_V2_URL_DEFAULT}usuarios/temas/usuario`;
    const result  = await this.http.get<any>(url, { headers: headers }).toPromise();
    return result.body;
  }

  async updateTema(tema:any): Promise<any> {
    let headers: any = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: null,
      authorization: localStorage.getItem("token")
    };

    const url     = `${environment.API_V2_URL_DEFAULT}usuarios/temas/usuario/update`;
    const options = { method: 'POST', headers: headers, body: JSON.stringify(tema)};
    const result  = fetch(url, options).then((r) => r.json());
    return result
  }

  async getObjetivos(): Promise<any> {
    let headers: any = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: null,
      authorization: localStorage.getItem("token")
    };

    const url     = `${environment.API_V2_URL_DEFAULT}usuarios/objetivos/usuario`;
    const options = { method: 'GET', headers: headers };
    const {body}  = await fetch(url, options).then((r) => r.json());

    return body
  }

  async updateObjetivo(objetivo:any): Promise<any> {
    let headers: any = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      token: null,
      authorization: localStorage.getItem("token")
    };

    const url     = `${environment.API_V2_URL_DEFAULT}usuarios/objetivos/usuario/update`;
    const options = { method: 'POST', headers: headers, body: JSON.stringify(objetivo)};

    const result = fetch(url, options).then((r) => r.json());
    return result
  }

  async setConcordaTermos(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "usuarios/aceita-termos"}`, {}, { headers: headers }).toPromise();
    return result.body;
  }

  async setFoto(body: object, userId: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}usuarios/update-foto/${userId}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async mailerRedefinirSenha(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "auth/email-redefinir-senha"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async redefinirSenha(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "auth/redefinir-senha"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async validaUsuario(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "auth/valida-usuario"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getTokenAcesso(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "auth/token-acesso"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getToken(idUsuario): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}profissionais/auth/token/${idUsuario}`).toPromise();
    return result.body;
  }

  async getUsuarioTermos(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}usuarios/usuario-termos`, { headers: headers }).toPromise();
    return result.body;
  }

  async verificaEmail(email: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    const body = { email: email }
    const result = await this.http.post<any>(`${environment.API_V2_URL}dashboard/usuarios/verifica-email`, body, { headers: headers }).toPromise();
    return result.body;
  }

  validationCPF(cpf: string): boolean {
    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf === '') return false;

    if (cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999') {
      return false;
    }

    let add = 0;
    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) rev = 0;

    if (rev !== parseInt(cpf.charAt(9))) return false;

    add = 0;
    for (let i = 0; i < 10; i++) {
      add += parseInt(cpf.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) rev = 0;

    if (rev !== parseInt(cpf.charAt(10))) return false;

    return true;
  }
}
