<article>
    <header *ngIf="!eventSelected" class="container__header__task__finished d-flex">
        <img src="assets/icons/clock.svg" alt="Próximos conteúdos">
        <h2 class="font-title">{{title}}</h2>
    </header>
    <div *ngIf="!eventSelected" [class.fullheight]="fullScreen" class="container__task">
        <div (click)="setEventSelected(item.id)" *ngFor="let item of contents"
            class="content__task d-flex content__item" [style.backgroundImage]="'url(' + item.photo +')'">
            <div class="bgd__foto">
                <div class="d-flex flex-column">
                    <h3 class="font-title">
                        {{item.nome}}
                    </h3>
                    <div *ngIf="item.id_contratante > 0" class="content__tipo content__tipo__secondary">
                        <span>Acesso restrito <i class='fa fa-ban'></i></span>
                    </div>
                    <div *ngIf="!item.id_contratante" class="content__tipo">
                        <span>Acesso disponível <i class="far fa-check-circle"></i></span>
                    </div>
                    <p class="content__task__palestrante">
                        {{item.palestrante}}
                    </p>
                    <p class="content__task__date">
                        {{item.data}}
                    </p>
                </div>
            </div>
        </div>
        <div *ngIf="contents.length == 0" class="content__empty">
            <label class="empty">Nenhum conteúdo encontrado</label>
        </div>
    </div>

    <div *ngIf="eventSelected" [class.fullheight]="fullScreen" class="container__task">
        <app-palestra [id]="eventSelected" (back)="setEventSelected(null)"></app-palestra>
    </div>
</article>