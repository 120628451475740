import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoricoComponent } from './historico/historico.component';
import { PushUsersComponent } from './push-users/push-users.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExportComponentsModule } from 'src/app/common/export-components';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { ModalPushComponent } from './modal-push/modal-push.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';

const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  declarations: [HistoricoComponent, PushUsersComponent, ModalPushComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ExportComponentsModule,
    NgSelectModule,
    ExportComponentsModule,
    NgxMaskModule.forRoot(maskConfig),
  ]
})
export class ComunicacaoInternaModule { }
