import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user';
import { ToastrService } from 'ngx-toastr';
import { CheckValidacaoSenhaComponent } from 'src/app/common/export-components/check-validacao-senha';

@Component({
  selector: 'app-tb-senha',
  templateUrl: './tb-senha.component.html',
  styleUrls: ['./tb-senha.component.scss']
})
export class TbSenhaComponent implements OnInit {
  senha_atual: string = "";
  senha_nova: string = "";
  senha_nova_confirma: string = "";

  loading: boolean = false;

  @ViewChild("validacaoPass", { static: true }) checkValidacaoSenhaComponent: CheckValidacaoSenhaComponent;

  constructor(
    private userService: UserService,
    private toast: ToastrService) { }

  ngOnInit(): void {
  }

  async update() {
    if (!(this.senha_atual.length > 0)) {
      this.toast.error("Senha atual deve ser preenchido!");
      return;
    }

    if (!(this.senha_nova.length > 0)) {
      this.toast.error("Informe a nova senha!");
      return;
    }

    if (!(this.senha_nova_confirma.length > 0)) {
      this.toast.error("Repita a nova senha!");
      return;
    }

    let senhaRegex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})\S{8,20}$/;
    if (!senhaRegex.test(this.senha_nova)) {
      this.toast.error("Senha nova inválida. Use 8 a 20 caracteres, contendo letras e números, sendo ao menos uma letra maiúscula.");
      return;
    }

    if (this.senha_nova !== this.senha_nova_confirma) {
      this.toast.error("Senha repetida incompátival com nova senha!");
      return;
    }

    try {
      this.loading = true
      await this.userService.setSenha({
        senha_atual: this.senha_atual,
        nova_senha: this.senha_nova,
      })

      this.loading = false
      this.toast.success("Senha alterada com sucesso!");
      this.senha_atual = "";
      this.senha_nova = "";
      this.senha_nova_confirma = "";
    } catch (e) {
      this.toast.error("Erro ao salvar senha. " + e.error.message);
      this.loading = false
    }
  }

  checkPasswordRule() {
    this.checkValidacaoSenhaComponent.checkPasswordRuleValue(this.senha_nova)
  }
}
