<main class="container mt-5">
  <h1>THE BEST SPEAKERS BRAZIL - AVISOS</h1>

  <div>
    <button type="button" (click)="openModalNew()">Novo aviso</button>
  </div>

  <div class="notices__container container">
    <div class="card" *ngFor="let notice of notices">
      <h6 class="text-bold">{{ notice.date }} - {{ notice.hour }}</h6>
      <p>{{ notice.title }} - {{ notice.status === 'public' ? 'público' : 'privado' }}</p>
      <p>{{ notice.description }}</p>

      <div class="actions__container flex w-100">
        <button type="button" class="mr-4" (click)="openModalEdit(notice)">Editar</button>
        <!-- <button type="button">Alterar visualização</button> -->
      </div>
    </div>
  </div>
</main>
