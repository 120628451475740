<div class="container__default">

    <div class="d-flex align-items-center justify-content-between">
        <h1 class="font-title">{{colaborador.nome_completo}} - Substituir tutor de {{colaborador.nome_lider}} para:</h1>
        <button (click)="bsModalRef.hide()" type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span style="font-size: 1.35rem;" aria-hidden="true">&times;</span>
        </button>
    </div>

    <app-list-lideres *ngIf="step == 1" (selected)="liderSeleted($event)"></app-list-lideres>

    <app-list-grupos-lider *ngIf="step == 2" [lider]="newLider" (back)="back()" (selected)="grupoSeleted($event)">
    </app-list-grupos-lider>

    <div *ngIf="step == 3" class="flexFull flex-column mt-5">
        <h2 class="font-title">Confirme a substituição</h2>
        <p class="info">Líder: {{newLider.nome_completo}}</p>
        <p class="info">Grupo: {{grupoUsuario.nome}}</p>
        <div class="flexFull flex-wrap mt-4">
            <button (click)="step = 1" class="btn__secondary mr-2">Voltar</button>
            <button (click)="submit()" class="btn__primary">Confirmar</button>
        </div>
    </div>

</div>