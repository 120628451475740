<section class="container-fluid px-2 px-md-5">
    <div class="row my-3 col-6">
        <div class="inputSearch d-none d-md-flex w-100">
            <button class="inputSearch__button" title="Pesquisar">
              <img src="assets/icons/search-black.svg" alt="pesquisar" width="15" height="15">
            </button>
      
            <label class="inputSearch__label" for="buscaRapida">Busca rápida</label>
            <input
              id="buscaRapida"
              class="inputSearch__input w-100 justify-content-start"
              placeholder="Busca rápida"
              type="text"
              [ngModel]="taskFilter"
              (ngModelChange)="getFilteredTasks($event)"
            />
          </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <header>
                <h2 class="font-title">Próximas palestras</h2>
            </header>
            <div class="col-12 container__conteudo">
                <app-proximas-palestras class="cardsContainer" [filter]="taskFilter" [class.d-none]="eventSelected" (openDetail)="selectToShowEvent($event)">
                </app-proximas-palestras>

                <app-palestra *ngIf="eventSelected" [id]="eventSelected" (back)="setEventSelected(null)"></app-palestra>
            </div>
        </div>

        <div class="col-12 col-md-6">
            <div class="row">

                <app-list-conteudo class="col-12" [contents]="contentsCompleted" [title]="'Palestras anteriores'"></app-list-conteudo>

                <app-list-conteudo class="col-12" [contents]="contentsToDownload" [title]="'Palestras gravadas'"></app-list-conteudo>

            </div>
        </div>

    </div>
</section>
