import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'psa-modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.css']
})
export class ModalMessageComponent implements OnInit {

  @Input('message') message: string;
  @Input('submessage') submessage: string;

  constructor(
    public bsModalRef: BsModalRef,) { }

  ngOnInit(): void { }

}
