<main>
  <h1>Resumo indicações</h1>

  <div>
    <div class="filterContainer">
      <section>
        <label for="dateStart">Data Início</label>
        <input
          id="dateStart"
          name="dateStart"
          type="date"
          [(ngModel)]="dateStart"
        />
      </section>
      <section>
        <label for="dateEnd">Data Fim</label>
        <input
          id="dateEnd"
          name="dateEnd"
          type="date"
          [(ngModel)]="dateEnd"
        />
      </section>

      <section>
        <button (click)="filterByDate()" type="button">Filtrar</button>
      </section>
      <section>
        <button type="button">Resetar Filtro</button>
      </section>
    </div>

    <div class="filterContainer">
      <section>
        <label for="nameFilter">Filtrar por nome</label>
        <input name="nameFilter" id="nameFilter" [(ngModel)]="filterName" (change)="filterByName()" />
      </section>
    </div>

    <div class="filterContainer">
      <section>
        <button type="button" (click)="downloadCSV()">Baixar planilha</button>
      </section>
  </div>
  </div>

  <div *ngIf="loading">Carregando ...</div>

  <div *ngIf="!loading">
    <table class="customTable">
      <thead>
        <th>Nome</th>
        <th (click)="filterByIndicacoes()">
          <div>
            Indicações totais
            <img *ngIf="filterI" src="../../../../assets/icons/arrow-up.svg" />
            <img *ngIf="!filterI" src="../../../../assets/icons/arrow-down.svg" />
          </div>
        </th>
        <th (click)="filterByPrincipal()">
          <div>
            Principal indicado
            <img *ngIf="filterP" src="../../../../assets/icons/arrow-up.svg" />
            <img *ngIf="!filterP" src="../../../../assets/icons/arrow-down.svg" />
          </div>
        </th>
        <th (click)="filterByTicket()">
          <div>
            Ticket médio plaestrante
            <img *ngIf="filterT" src="../../../../assets/icons/arrow-up.svg" />
            <img *ngIf="!filterT" src="../../../../assets/icons/arrow-down.svg" />
          </div>
        </th>
        <th>Curadores que mais indicam</th>
        <th>NPS</th>
        <th>Selo</th>
        <th>Último curador à indicar</th>
        <th></th>
      </thead>

      <tbody>
        <tr
          *ngFor="let s of speakers | paginate: { itemsPerPage: 10, currentPage: currentPage }"
          (click)="openDepoimentos(s?.id)"
        >
          <td>{{ s.nomeCompleto }}</td>
          <td>{{ s?.indicacoes || 0 }}</td>
          <td>{{ s?.indicacoesPrincipal || 0 }}</td>
          <td>{{ (s?.ticket / s?.indicacoes || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}}</td>
          <td>
            {{ getCuradores(s?.curadores) }}
            <ul>
              <p *ngIf="curadoresComMaisIndicacoes[0]">#1 {{ curadoresComMaisIndicacoes[0] }}</p>
              <p *ngIf="curadoresComMaisIndicacoes[1]">#2 {{ curadoresComMaisIndicacoes[1] }}</p>
              <p *ngIf="curadoresComMaisIndicacoes[2]">#3 {{ curadoresComMaisIndicacoes[2] }}</p>
            </ul>
          </td>
          <td>{{ s?.NPS || '--' }}</td>
          <td>{{ s?.selo_plano || '--'}}</td>
          <td>{{ s?.ultimaIndicacao }}</td>
          <td>Ver depoimentos</td>
        </tr>
      </tbody>
    </table>
    <pagination-controls (pageChange)="currentPage = $event"></pagination-controls>
  </div>
</main>
