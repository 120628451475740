<article class="container__questions">

    <header>
        <p class="description">Você assistiu:</p>
        <div class="d-flex justify-content-center align-items-center py-2">
            <img class="mr-2" height="18px" src="assets/icons/play.svg" alt="vídeo">
            <h6 class="font-title">{{skillTitle}}</h6>
        </div>
    </header>

    <ul [class.d-none]="indexSelected != null" class="content__question py-2">
        <p class="description instruction">Responda as tarefas abaixo para evoluir nesta habilidade</p>

        <li (click)="selectQuestionAnswer(q)" *ngFor="let q of questions" class="content__list__question">
            <img src="assets/icons/question.svg" alt="question">
            <span>
                {{q.tipo == "DESCRITIVA" ? 'Tarefa prática' : q.pergunta}}
            </span>
        </li>
    </ul>

    <!-- <app-question-answers #questionAnswer #questionAnswer>
    </app-question-answers> -->

    <div [class.d-none]="!isShowButtons" [class.d-flex]="isShowButtons" class="justify-content-center">
        <button (click)="next()" class="btn__primary">Próxima</button>
        <button *ngIf="canConcluded" (click)="concludeSkill()"
            class="btn__primary ml-2">concluir</button>
    </div>
</article>
