<div class="container-fluid px-5">
  <div class="row mt-4">
    <div class="col-12 container__conteudo__default" style="height: 100%;">
      <h5 class="font-title mt-3">Faturamento</h5>
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label for="cnpj" class="label-input">CNPJ</label>
          <input name="cnpj" type="text" class="form-control" [(ngModel)]="gestaoData.cnpj" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="formatos" class="label-input">Aceita faturar em quais formatos?</label>
          <input name="formatos" type="text" class="form-control" [(ngModel)]="gestaoData.formatoFaturas" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="overOrBV" class="label-input">Aceita trabalhar com over ou apenas BV?</label>
          <input name="overOrBV" type="text" class="form-control" [(ngModel)]="gestaoData.overOrBv" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="posPayment" class="label-input">Aceita receber pós evento?</label>
          <input name="posPayment" type="text" class="form-control" [(ngModel)]="gestaoData.posPay" />
        </div>
      </div>

      <h5 class="font-title mt-3">Dados Pessoais e Logística</h5>
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label for="completeName" class="label-input">Nome completo</label>
          <input name="completeName" type="text" class="form-control" [(ngModel)]="gestaoData.nomeCompleto" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="RG" class="label-input">RG</label>
          <input name="RG" type="text" class="form-control" [(ngModel)]="gestaoData.rg" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="CPF" class="label-input">CPF</label>
          <input name="CPF" type="text" class="form-control" [(ngModel)]="gestaoData.cpf" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="birthDate" class="label-input">Data de nascimento</label>
          <input name="birthDate"  type="date" class="form-control" [(ngModel)]="gestaoData.dataNascimento" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="residence" class="label-input">Cidade que reside</label>
          <input name="residence" type="text" class="form-control" [(ngModel)]="gestaoData.cidade" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="hasTravelPartner" class="label-input">Viaja sozinho ou acompanhado?</label>
          <input name="hasTravelPartner" type="text" class="form-control" [(ngModel)]="gestaoData.tipoViajem" />
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="partnerData" class="label-input">Dados do acompanhante</label>
          <textarea name="partnerData" class="form-control" [(ngModel)]="gestaoData.acompanhante"></textarea>
        </div>
      </div>

      <h5 class="font-title mt-3">CS</h5>
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label for="minuta" class="label-input">Minuta</label>
          <input name="minuta" type="text" class="form-control" [(ngModel)]="gestaoData.minuta" />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label for="preferencias" class="label-input">Preferências relacionadas ao dia-a-dia</label>
          <textarea name="preferencias" type="text" class="form-control" [(ngModel)]="gestaoData.preferencias"></textarea>
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="minibio" class="label-input">Minibio</label>
          <textarea name="minibio" class="form-control" [(ngModel)]="gestaoData.minibio"></textarea>
        </div>
      </div>

      <h5 class="font-title mt-3">Outros</h5>
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label for="reuniaoPreBriefing" class="label-input">Participa de reunião de pré-briefing?</label>
          <input name="reuniaoPreBriefing" type="text" class="form-control" [(ngModel)]="gestaoData.reuniaoPreBriefing" />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label for="restricaoCliente" class="label-input">Não aceita algum tipo de evento ou cliente?</label>
          <textarea name="restricaoCliente" type="text" class="form-control" [(ngModel)]="gestaoData.restricaoCliente"></textarea>
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="notas" class="label-input">Notas gerais</label>
          <textarea name="notas" type="text" class="form-control" [(ngModel)]="gestaoData.notas"></textarea>
        </div>

        <div class="form-group col-12 col-md-4">
          <label for="historico" class="label-input">Histórico de Problemas</label>
          <textarea name="historico" type="text" class="form-control" [(ngModel)]="gestaoData.historico"></textarea>
        </div>
      </div>

      <!-- log de edição -->
      <h5 class="font-title mt-3">Pasta de Fotos</h5>
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label>Link com o drive de fotos</label>
          <input name="photosDrive" type="text" class="form-control" [(ngModel)]="gestaoData.photosDrive" />
          <span>Inserir links no formato: https://dominio.com</span>
        </div>
      </div>

      <div class="row">
        <button class="btn__add" type="button" (click)="saveForm()">Salvar</button>
      </div>
    </div>
  </div>
</div>
