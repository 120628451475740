import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EspecialistasService } from 'src/app/services/especialistas';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';

export interface IEspecialista {
  carteira: string
  cep: string
  cidade: string
  consultor: string
  email: string
  estado: string
  formato: string
  id: number
  id_carteira: number
  id_estado: number
  id_nossa_empresa: number
  id_tema: string
  id_usuario: number
  influenciador: string
  investimento: string
  mentor: string
  minibio: string
  nome_palestrante: string
  ordem: number
  pais: string
  palestrante: string
  palestrante_video: string
  pesquisa: string
  photo: string
  pontuacao: 0
  preco: string
  slug: string
  tema: string
  titulo: string
  trainer: string
}

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  loadingDownload: boolean = false;
  especialista: IEspecialista;
  palestras: Array<any> = [];
  depoimentos: Array<any> = [];
  assuntos: Array<any> = [];
  relacionados: Array<any> = [];
  slug: string
  linkVideo: any;

  empresa: number = 4;

  public carouselOptions: OwlOptions = {
    margin: 10,
    nav: true,
    dots: false,
    navText: ["<i class='carousel-control-prev-icon'></i>", "<i class='carousel-control-next-icon'></i>"],
    responsive: { 0: { items: 1 }, 400: { items: 2 }, 740: { items: 3 }, 940: { items: 4 } }
  }

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private especialistasService: EspecialistasService,
    private palestrantesService: PalestrantesService,
    private toast: ToastrService) { }

  ngOnDestroy(): void {
    this.titleService.setTitle("Dashboard")
  }

  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get("slug")
    this.getBySlug()
  }

  async getBySlug() {
    try {
      this.loading = true

      this.linkVideo = null
      const res = await this.especialistasService.getEspecialistaBySlug(this.slug)
      this.especialista = res.palestrante
      this.titleService.setTitle(`Especialista ${this.especialista.nome_palestrante}`)

      this.palestras = res.palestras
      this.assuntos = res.assuntos
      this.depoimentos = res.depoimentos
      this.relacionados = res.relacionados

      this.getUrlVideo()
      this.loading = false
    } catch (error) {
      this.loading = false
    }
  }

  public getMinibio(text: string, maxLength: number = 100) {
    return text.length > maxLength ? text.substring(0, (maxLength - 4)) + ' ...' : text;
  }

  getStyle() {
    const width = window.innerWidth;
    const len = this.palestras.length;
    return { 'width': len > 2 || width < 800 ? '100%' : len < 2 ? '25%' : '50%' };
  }

  goEspecialista(slug: string) {
    this.especialista = null
    this.router.navigate(["especialistas/" + slug])
    this.slug = slug
    window.scrollTo(0,0)
    this.getBySlug()
  }

  getUrlVideo() {
    if (this.especialista.palestrante_video) {
      this.linkVideo = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.especialista.palestrante_video}`)
    } else {
      this.linkVideo = null
    }
  }

  async sendPDFEmailPerfil() {
    try {
      this.loadingDownload = true
      await this.palestrantesService.sendPDFPalestrante(this.especialista.slug, this.empresa)
      this.toast.success("PDF enviado para seu e-mail", "Fique atento a sua caixa de entrada e spam")
      this.loadingDownload = false
    } catch (error) {
      this.loadingDownload = false
      this.toast.error("Não foi possível enviar o email")
    }
  }
}
