import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TarefasService } from 'src/app/services/tarefas';

@Component({
  selector: 'app-tasks-item',
  templateUrl: './tasks-item.component.html'
})

export class TasksItemComponent implements OnInit {
  @Input() indexQuestion: number = 0
  @Input() size: number = 0
  @Input() question = {
    resposta: null,
    descritiva: "",
    tipo: "",
    pergunta: "",
    id: null,
    opcoes: []
  };

  anwser = "";
  isAnswered: boolean = false
  isCorrect: boolean = false
  loading: boolean = false
  optionChoosed: any = { ordem: null };
  questionCorrectionText: string = "";

  constructor(
    private toast: ToastrService,
    private taskService: TarefasService,
  ) { }

  ngOnInit(): void {
    this.isAnswered = false

    if (this.question.resposta && this.question.tipo == 'DESCRITIVA') {
      this.anwser = this.question.resposta.resposta
    }

    if (this.question.resposta) {
      let opctions      = this.question.opcoes
      let optionChoosed = opctions.find(i => i.ordem == this.question.resposta.ordem)

      this.checkAnswer(optionChoosed, false)
    }
  }

  checkQuestion(){
    if (!this.anwser) {
      this.toast.error("Preencha a resposta descritiva para concluir a tarefa")

      return false
    }

    this.saveAnswerQuestion()
    return true
  }

  checkAnswer(option: any, isToValidate: boolean = true) {
    if (this.isAnswered) {
      return
    }

    this.isAnswered     = true
    this.optionChoosed  = option

    if (this.question.tipo !== 'DESCRITIVA') {
      this.isCorrect = (option.peso == 100);
      this.questionCorrectionText = option.explicacao;
    }

    this.saveAnswerQuestion()
  }

  async saveAnswerQuestion() {
    if (this.question.resposta) {
      return true
    }

    this.loading = true

    try {
      await this.taskService.saveAnswerTask({
        perguntaId: this.question.id,
        resposta:
          this.question.tipo == 'DESCRITIVA'
            ? {
              descricao_opcao: this.anwser,
              explicacao: null,
              ordem: 1,
              peso: null,
            }
            : this.optionChoosed
      })
      return true
    } catch (error) {
      console.info(`Erro ao salvar questão ${error.message}`)
      return false
    }
  }

  hasArrayOptions(): boolean {
    return Array.isArray(this.question.opcoes)
  }
}
