import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  token: string = null

  constructor(public http: HttpClient) {
    this.token = localStorage.getItem("token");
  }

  async getIpDevice() {
    const result = await this.http.get<any>('https://jsonip.com').toPromise()
    return result;
  }

  async paymentRecurrency(form: any): Promise<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/json').set("authorization", this.token);
    let body = JSON.stringify(form)
    const result = await this.http.post<any>(`${environment.API_V2_URL}payment/pay/sale`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async paymentRecurrencyAndUpdatePlan(form: any): Promise<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/json').set("authorization", this.token);
    let body = JSON.stringify(form)
    const result = await this.http.post<any>(`${environment.API_V2_URL}plano/update-plano`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async verifyUserRecurrency(): Promise<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/json').set("authorization", this.token);
    const result = await this.http.get<any>(`${environment.API_V2_URL}payment/pay/has-recurrency`, { headers: headers }).toPromise();
    return result.body;
  }

}
