<div class="termometro">
    <div class="container__cabecalho d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <img class="mr-3" height="40px" src="assets/icons/meter_yellow.svg">
            <h2 class="title">Termômetro</h2>
        </div>
        <img (click)="cancel()" class="image__close" height="25px" src="assets/icons/close_yellow.svg" alt="fechar"
            title="fechar">
    </div>
    <div class="mt-2">
        <p class="subtitle mb-0">Avalie a sua satisfação com a palestra até aqui. Utilize o termômetro abaixo para
            classificar entre uma das
            opões disponíveis:</p>
    </div>

    <div class="d-flex justify-content-around py-5">
        <button (click)="send(1)" class="btn">
            Ruim
        </button>
        <button (click)="send(2)" class="btn">
            Bom
        </button>
        <button (click)="send(3)" class="btn">
            Muito Bom
        </button>
    </div>
</div>