<!-- modal-update-ranking.component.html -->

<h1>Update Ranking</h1>

<form>
  <div>
    <label for="name">Nome</label>
    <input type="text" id="name" name="name" [(ngModel)]="speaker.nomeCompleto" readonly>
  </div>

  <div>
    <label for="email">Email:</label>
    <input type="text" id="email" name="email" [(ngModel)]="speaker.email" readonly>
  </div>

  <div>
    <label for="votes">Votos Computados:</label>
    <input type="number" id="votes" name="votes" [(ngModel)]="votes" readonly>
  </div>

  <div>
    <label for="position">Posição Computada:</label>
    <input type="text" id="position" name="position" [(ngModel)]="position" readonly>
  </div>

  <div>
    <label for="position">Nova posição</label>
    <select id="position" name="position" [(ngModel)]="position_psa">
      <option [value]="1">Você está entre os 300 classificados</option>
      <option [value]="313">Você está perto dos 300 classificados</option>
      <option [value]="513">Você não está entre os 300 classificados ainda</option>
    </select>
  </div>

  <div class="flex flex-row w-100 justify-content-around py-3">
    <button class="cancel" type="button" (click)="cancel()">Cancel</button>
    <button type="submit" (click)="updateRanking()">Update</button>
  </div>
</form>
