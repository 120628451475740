<section class="px-1 px-lg-5">

    <div *ngIf="loading" class="d-flex justify-content-center">
        <app-spinner></app-spinner>
    </div>

    <div class="container__grid">

        <article class="skill__list">
            <header>
                <img src="assets/icons/clock.svg" alt="Próximos conteúdos">
                <h4 class="font-title">Próximos conteúdos</h4>
            </header>

            <div *ngFor="let i of pendingSkills">
                <app-skill-template [skill]="i" [lengthStarted]="startedSkills.length"></app-skill-template>
            </div>
        </article>


        <article class="secundary__skill__list">
            <header *ngIf="startedSkills.length > 0">
                <img src="assets/icons/clockwise.svg" alt="Pendente de Avaliação">
                <h4 class="font-title">Em Andamento</h4>
            </header>

            <div *ngFor="let i of startedSkills" class="container__skill mb-2">
                <app-skill-template [skill]="i"></app-skill-template>
            </div>

            <header *ngIf="answeredSkills.length > 0">
                <img src="assets/icons/clockwise.svg" alt="Pendente de Avaliação">
                <h4 class="font-title">Pendente de Avaliação</h4>
            </header>

            <div *ngFor="let i of answeredSkills" class="container__skill">
                <app-skill-template [skill]="i"></app-skill-template>
            </div>
        </article>


        <article class="secundary__skill__list">
            <header>
                <img src="assets/icons/checked_circle_black.svg" alt="Concluídos">
                <h4 class="font-title">Concluídos</h4>
            </header>

            <div *ngFor="let i of concludedSkills" class="container__skill">
                <app-skill-template [skill]="i"></app-skill-template>
            </div>
        </article>
    </div>
</section>