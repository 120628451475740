<div class="container-fluid pl-lg-5 pr-lg-5">

    <app-wizard [itemSelected]="3"></app-wizard>

    <div class="clearfix mb-3 pr-0 col-12">
    </div>
    <div class="container__conteudo col-12 col-lg-12">
        <div class="row justify-content-between">
            <h4 class="font-title">Informações do Grupo</h4>
            <div>
                <button (click)="salvar()" type="button" class="btn__primary">Avançar</button>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-5 form-group">
                <label class="label-input" for="nome">Nome</label>
                <input maxlength="50" class="form-control" type="text" nome="nome"
                    placeholder="Informe um Nome para esse Grupo" [(ngModel)]="grupo.nome">
            </div>

            <div class="col-12 col-lg-7 form-group">
                <label maxlength="250" class="label-input" for="descricao">Descrição</label>
                <input class="form-control" type="text" id="descricao"
                    placeholder="Descreva o perfil dos integrantes desse grupo" [(ngModel)]="grupo.descricao">
            </div>

            <div class="col-12 col-lg-4">
                <label class="label-input mr-2" for="tipo">Tipo:</label>

                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="tipo_s" name="tipo" class="custom-control-input" value="DEFAULT"
                        [(ngModel)]="grupo.tipo">
                    <label class="custom-control-label" for="tipo_s">Padrão</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="tipo_n" name="tipo" class="custom-control-input" value="PDI"
                        [(ngModel)]="grupo.tipo">
                    <label class="custom-control-label" for="tipo_n"
                        title="Plano de Desenvolvimento Individual">PDI</label>
                </div>
            </div>

            <div class="col-12 col-lg-4">
                <label class="label-input mr-2" for="perguntas_descritivas">Tarefas descritivas:</label>

                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="perguntas_descritivas_s" name="perguntas_descritivas"
                        class="custom-control-input" [value]="0" [(ngModel)]="grupo.perguntas_descritivas">
                    <label class="custom-control-label" for="perguntas_descritivas_s">Não</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="perguntas_descritivas_n" name="perguntas_descritivas"
                        class="custom-control-input" [value]="1" [(ngModel)]="grupo.perguntas_descritivas">
                    <label class="custom-control-label" for="perguntas_descritivas_n"
                        title="perguntas_descritivas">Sim</label>
                </div>
            </div>

            <div class="col-12 col-lg-4">
                <label class="label-input mr-2" for="libera_personalizar">
                    Permitir personalização
                    <img style="cursor: pointer;" height="16px" src="assets/icons/info.svg" alt="info"
                        title="Esta opção permiti que o colaborador possa adicionar mais habilidades em sua trilha">
                    :
                </label>

                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="libera_personalizar_s" name="libera_personalizar"
                        class="custom-control-input" [value]="0" [(ngModel)]="grupo.libera_personalizar">
                    <label class="custom-control-label" for="libera_personalizar_s">Não</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="libera_personalizar_n" name="libera_personalizar"
                        class="custom-control-input" [value]="1" [(ngModel)]="grupo.libera_personalizar">
                    <label class="custom-control-label" for="libera_personalizar_n"
                        title="libera_personalizar">Sim</label>
                </div>
            </div>

            <div class="col-12 col-lg-3 my-2">
                <label class="label-input mr-2" for="gera_certificado">
                    Gera Certificado:
                </label>

                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="gera_certificado_s" name="gera_certificado"
                        class="custom-control-input" [value]="0" [(ngModel)]="grupo.gera_certificado">
                    <label class="custom-control-label" for="gera_certificado_s">Não</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="gera_certificado_n" name="gera_certificado"
                        class="custom-control-input" [value]="1" [(ngModel)]="grupo.gera_certificado">
                    <label class="custom-control-label" for="gera_certificado_n"
                        title="gera_certificado">Sim</label>
                </div>
            </div>

            <!-- <div class="col-12 col-lg-4 pr-0 my-2 d-flex" *ngIf="grupo.gera_certificado">
                <label class="title__mobile" style="width: 300px;" for="modelo_certificado">Modelo Certificado</label>
                <select class="form-control" name="modelo_certificado" [(ngModel)]="grupo.modelo_certificado">
                    <option value="MODELO1">Modelo 1</option>
                    <option value="MODELO2">Modelo 2</option>
                    <option value="MODELO3">Modelo 3</option>
                </select>
            </div> -->
        </div>

        <div class="d-flex justify-content-between align-items-center mt-2">
            <div class="d-flex flex1 align-items-center">
                <h4 class="font-title mb-0 mr-3 ">
                    Tutores
                    <img style="cursor: pointer;" class="ml-2" height="18px" src="assets/icons/info.svg" alt="info"
                        title="Tutor é o responsável pelo grupo, por avaliar as tarefas dos colaboradores">
                </h4>

                <div class="container__search col-12 col-lg-5 d-flex">
                    <img height="18px" src="assets/icons/search-black.svg" alt="ícone pesquisar">
                    <input class="container__search__input form-group m-0 border-0 bg-transparent"
                        placeholder="Buscar..." type="text" name="search" [(ngModel)]="search"
                        (ngModelChange)="buscar()">
                </div>
            </div>

            <button (click)="addLideres()" class="btn__primary button__insert button__expandable"
                title="Adicionar tutores">
                <span>
                    <img src="assets/icons/add.svg" alt="adicionar">
                </span>
            </button>
        </div>

        <div class="content__cabecalho text-left d-flex p-0">
            <label class="font-title text-uppercase flex2">NOME</label>
            <label class="font-title text-uppercase flex2">E-MAIL</label>
            <label class="font-title text-uppercase flex1">SETOR</label>
            <label class="font-title text-uppercase flex1">CARGO</label>
            <label class="font-title text-uppercase flex03">AÇÕES</label>
        </div>

        <ul class="col-12">
            <li [ngClass]="{ isLider : lider.responsavel_grupo_bool}" *ngFor="let lider of lideres">

                <div class="d-flex content__list__item">

                    <div class="flex2 w-100 item__nome">
                        <input class="input__disable" type="text" [(ngModel)]="lider.nome_completo" disabled="disabled">
                    </div>

                    <div class="flex2">
                        <input class="input__disable" type="text" [(ngModel)]="lider.email" disabled="disabled">
                    </div>

                    <div class="flex1">
                        <input class="input__disable" type="text" [(ngModel)]="lider.setor" disabled="disabled">
                    </div>

                    <div class="flex1">
                        <input class="input__disable" type="text" [(ngModel)]="lider.cargo_nome" disabled="disabled">
                    </div>


                    <div class="flex03 td_acao">
                        <img (click)="addColaboradores(lider)" src="assets/icons/person-add.svg" alt="adicionar"
                            title="Adicionar colaboradores">
                        <img (click)="editar(lider)" src="assets/icons/editar.svg" alt="adicionar">
                        <img (click)="excluir(lider)" class="float-right" src="assets/icons/excluir.svg" alt="excluir">
                    </div>
                </div>

            </li>
        </ul>


        <p *ngIf="lideres?.length == 0">Nenhum tutor cadastrado.</p>


        <div class="d-flex flex-row-reverse justify-content-between align-items-end">

            <nav *ngIf="qtdePages?.length > 1" aria-label="Page navigation example">
                <ul class="pagination">
                    <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                        <a class="page-link">Anterior</a>
                    </li>

                    <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)"
                        class="page-item">
                        <a class="page-link">1</a>
                    </li>
                    <span *ngIf="(page + 1) > 4" class="px-2">...</span>

                    <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)"
                        class="page-item" *ngFor="let number of getPages()">
                        <a class="page-link">{{number+1}}</a>
                    </li>

                    <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
                    <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}"
                        (click)="changePage(qtdePages.length - 1)" class="page-item">
                        <a class="page-link">{{qtdePages.length}}</a>
                    </li>

                    <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                        <a class="page-link">Próxima</a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>
</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>