<div class="container-fluid pl-lg-5 pr-lg-5">

    <div class="clearfix mb-2 pr-0 col-12">
      <h3 class="d-inline font-title mb-4">Configurações</h3>
      <button (click)="back()" type="button" class="btn btn-warning btn-back-circle float-right flexFull">
        <img class="float-right" src="../../../assets/icons/back.svg" alt="cancelar">
      </button>
    </div>
  
    <div class="container__conteudo col-12 col-lg-12">
      <div>
        <tabset>
  
          <tab heading="Empresa">
            <div class="margin"></div>
            <app-tb-empresa></app-tb-empresa>
          </tab>
          
          <tab heading="Administradores" id="tabcontato">
            <div class="margin"></div>
            <app-tb-contatos></app-tb-contatos>
          </tab>
  
          <tab heading="Senha" id="senha">
            <div class="margin"></div>
            <app-tb-senha></app-tb-senha>
          </tab>
        </tabset>
      </div>
    </div>
  
  </div>