import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { IInvalid } from 'src/app/pages/palestrante/perfil-completo';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { phoneMask } from 'src/app/utils/formatters/string.formatter';
import { states } from 'src/app/utils/statesArray';
import { Moment } from 'moment';
import * as moment from 'moment';
import { AcessoriaService } from 'src/app/services/Acessoria/acessoria.service';

interface IUsuario {
  id: number;
  photo: string;
  linkedin: string;
  facebook: string;
  instagram: string;
  whatsapp: string;
  nome: string;
  sobrenome: string;
  email: string;
  cidade: string;
  estado: string;
  telefone: string;
  observacao: string;
  nps: number;
}

interface IPalestrante {
  id: number;
  consultor: string;
  influenciador: string;
  mentor: string;
  palestrante: string;
  trainer: string;
  minibio: string;
  preco: string;
  renomado: string;
  slug: string;
  titulo: string;
  usuario_id: number;
  video: string;
  ja_palestrou: number;
  palestra_gratuita: number;
  autorizar_perfil: number;
  email_oportunidade: number;
  indicated?: boolean;
  reasons?: string;
  selo_plano?: string;
  lastUserEdit?: string;
  ultimaEdicao?: string;
  lastEditCurador?: any;
}

@Component({
  selector: 'app-dados-admin',
  templateUrl: './dados-admin.component.html',
  styleUrls: ['./dados-admin.component.scss'],
})
export class DadosAdminComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Input() usuarioId: number;

  loading: boolean = false;
  invalids: Array<IInvalid> = [];
  usuario: IUsuario | undefined;
  palestrante: IPalestrante | undefined;

  states: Array<string> = [];

  selos: Array<string> = [
    'Exclusivo',
    'Parceiro',
    'Relacionamento',
    'Mercado',
    'Bloqueado'
  ]

  htmlContent: any = '';
  htmlContent2: any = '';
  htmlReasons: any = '';
  htmlBio: any = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    showToolbar: true,
    enableToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  assessoria: any = {
    empresa: '',
    nome: '',
    funcao: '',
    telefone: '',
    email: '',
    exclusividade: false,
  };

  curadorId: string = '';

  constructor(
    private palestrantesService: PalestrantesService,
    private acessoriaService: AcessoriaService,
    private toast: ToastrService,
  ) {}

  ngOnInit(): void {
    this.curadorId = JSON.parse(localStorage.getItem('usuario')).id;
    this.getDadosById(this.usuarioId);

  }

  addItem(newItem: string) {
    this.htmlReasons = newItem;
  }

  async getDadosById(userId: number) {
    this.loading = true;
    const res = await this.palestrantesService.getDadosByUserId(userId);

    this.usuario = res.usuario;
    this.palestrante = res.palestrante;

    this.getAcessoria();

    this.palestrante.lastEditCurador = moment(this.palestrante.lastEditCurador).format('Do MMMM YYYY, h:mm:ss a');

    this.htmlContent = this.palestrante.reasons || '';
    this.htmlReasons = this.palestrante.reasons || '';
    this.loading = false;
    this.formatPhone(this.usuario.telefone);
  }

  async getAcessoria() {
    const [res] = await this.acessoriaService.getList(this.palestrante.id);
    this.assessoria = {
      nome: res.nome,
      empresa: res.empresa,
      funcao: res.funcao,
      telefone: res.telefone,
      email: res.email,
      exclusividade: res.exclusividade,
    };
  }

  formatPhone(value: string) {
    this.usuario.telefone = '';
    return (this.usuario.telefone = phoneMask(value));
  }

  formatMinibio(value: string) {
   return value?.replace(/\\n/g, '<br />');
  }

  async submit() {
    this.invalids = [];
    try {
      this.loading = true;
      this.palestrante.minibio = this.formatMinibio(this.palestrante.minibio);
      this.palestrante.reasons = this.formatMinibio(this.palestrante.reasons);
      this.palestrante.lastUserEdit = this.curadorId;

      this.palestrante.reasons = this.htmlReasons;

      const res = await this.palestrantesService.updateDadosPalestrante(
        this.usuario,
        this.palestrante
      );

      const resAcessoria = await this.acessoriaService.insertOrUpdate(this.assessoria, this.palestrante.id);

      this.loading = false;
      this.toast.success('Salvo com sucesso!');

      window.location.reload();
      this.close();
    } catch (error) {
      this.loading = false;

      if (error.error.message.invalids) {
        this.invalids = error?.error?.message.invalids;
        this.toast.error('Campos inválidos!', 'Por favor, revise.');
      } else {
        this.toast.error(
          'Não foi possível salvar os dados',
          'Falha na conexão'
        );
      }
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key];
      if (item['campo'] == value) return true;
    }
    return false;
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key];
      if (item['campo'] == value) return item['erro'];
    }
    return '';
  }

  changeVideo() {
    let v =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    this.palestrante.video = this.palestrante.video.match(v)
      ? RegExp.$1
      : this.palestrante.video;
  }

  close() {
    this.onClose.emit('');
  }
}
