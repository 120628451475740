import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { TbsbService } from 'src/app/services/tbsb/tbsb.service';

@Component({
  selector: 'app-palestrantes',
  templateUrl: './palestrantes.component.html',
  styleUrls: ['./palestrantes.component.scss'],
})
export class PalestrantesComponent implements OnInit {
  allPalestrantes: any[] = [];
  palestrantes: any[] = [];
  canEdit: boolean = false;
  loading: boolean = false;

  filterType: string = 'palestrantes';

  constructor(
    private readonly palestrantesSvc: PalestrantesService,
    private readonly router: Router,
    private toast: ToastrService,
  ) {}

  ngOnInit(): void {
    const perfil = localStorage.getItem('perfil');
    if (perfil === 'ADMIN' || perfil === 'AVALIADOR' || perfil === 'GESTOR' || perfil === 'CS') {
      this.canEdit = true;
    } else {
      this.canEdit = false;
    }
    this.fetchPalestrantes();
  }

  fetchPalestrantes() {
    try {
      this.loading = true;
      this.palestrantesSvc.getPalestrantes().then((res) => {
        this.allPalestrantes = res;

        this.palestrantes = this.allPalestrantes.slice(0, 100);
        this.loading = false;
      });
    } catch (error) {
      this.toast.error('Não foi possível listar os palestrantes');
      this.loading = false;
    }
  }

  editarPerfil(idUsuario) {
    this.router.navigate(['/admin/palestrantes', idUsuario]);
  }

  cadastrar() {
    this.router.navigateByUrl('admin/palestrantes/cadastro');
  }

  cadastrarExclusivo() {
    this.router.navigateByUrl('admin/palestrantes/cadastro-exclusivo');
  }

  filtrar($event: any) {
    const value: string = $event.target.value;

    this.palestrantes = [];

    if (!value || value === '') {
      this.palestrantes = this.allPalestrantes;
    }

    this.palestrantes = this.allPalestrantes.filter((p) => {
      return p?.nome_palestrante?.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });
  }

  filterByType() {
    if(this.filterType === 'tbsb') {
      this.palestrantes = this.allPalestrantes.filter((p) => {
        return p?.tipo?.toLocaleLowerCase() === this.filterType.toLocaleLowerCase();
      });
    } else {
      this.palestrantes = this.allPalestrantes;
    }
  }
}
