<div class="container__conteudo col-12 col-lg-12">
    <h4 class="font-title">Tutor: {{lider.nome_completo.split(" ")[0]}}
    </h4>
    <div class="close pull-right ml-3" aria-label="Close" (click)="bsModalRef.hide()">
        <span style="cursor: pointer; font-size: 30px;" aria-hidden="true">&times;</span>
    </div>
    <tabset>
        <tab heading="Adicionar colaboradores" id="tabadd">

            <div class="d-lg-flex justify-content-between align-items-end my-2">

                <div class="container__search col-12 col-lg-5 d-flex">
                    <img src="assets/icons/search-black.svg" alt="ícone pesquisar">
                    <input class="container__search__input form-group m-0 border-0 bg-transparent"
                        placeholder="Buscar..." type="text" name="search" [(ngModel)]="search"
                        (ngModelChange)="buscar()">
                </div>
            </div>

            <div class="content__cabecalho text-left d-flex p-0">
                <label class="flex03"></label>
                <label class="font-title text-uppercase flex2">NOME</label>
                <label class="font-title text-uppercase flex2">E-MAIL</label>
                <label class="font-title text-uppercase flex1">SETOR</label>
                <label class="font-title text-uppercase flex1">CARGO</label>
            </div>

            <ul>
                <li [ngClass]="{ added : colaborador.added}" *ngFor="let colaborador of colaboradores">

                    <div class="d-flex content__list__item">
                        <div class="flex03 px-0">
                            <label class="container__checkbox mb-0">
                                <input type="checkbox" (change)="addColaborador(colaborador)"
                                    [(ngModel)]="colaborador.added">
                                <span class="checkmark"></span>
                            </label>
                        </div>

                        <div class="flex2 pl-0">
                            <input class="input__disable" type="text" [(ngModel)]="colaborador.nome_completo"
                                disabled="disabled">
                        </div>

                        <div class="flex2">
                            <input class="input__disable" type="text" [(ngModel)]="colaborador.email"
                                disabled="disabled">
                        </div>

                        <div class="flex1">
                            <input class="input__disable" type="text" [(ngModel)]="colaborador.setor"
                                disabled="disabled">
                        </div>

                        <div class="flex1">
                            <input class="input__disable" type="text" [(ngModel)]="colaborador.cargo_nome"
                                disabled="disabled">
                        </div>

                    </div>

                </li>
            </ul>

            <p *ngIf="colaboradores?.length == 0">Nenhum colaborador cadastrado.</p>

            <div class="d-flex flex-row-reverse justify-content-between align-items-end">

                <button (click)="salvar()" class="btn__primary">SALVAR</button>

                <nav *ngIf="qtdePages?.length > 1">
                    <ul class="pagination">
                        <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                            <a class="page-link">Anterior</a>
                        </li>

                        <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)"
                            class="page-item">
                            <a class="page-link">1</a>
                        </li>
                        <span *ngIf="(page + 1) > 4" class="px-2">...</span>

                        <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)"
                            class="page-item" *ngFor="let number of getPages()">
                            <a class="page-link">{{number+1}}</a>
                        </li>

                        <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
                        <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}"
                            (click)="changePage(qtdePages.length - 1)" class="page-item">
                            <a class="page-link">{{qtdePages.length}}</a>
                        </li>

                        <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                            <a class="page-link">Próxima</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </tab>


        <tab heading="Colaboradores já adicionados">
            <app-grupo-tb-colaboradores [id_grupo]="id_grupo" [id_usuario_lider]="lider.id_usuario_lider">
            </app-grupo-tb-colaboradores>
        </tab>
    </tabset>
</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>