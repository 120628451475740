import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { LiveEspectadorService } from 'src/app/services/live-espectador';
import { IQuiz } from '../live-espectador';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['../live-espectador/live-espectador.component.scss', './quiz.component.scss']
})
export class QuizComponent implements OnInit {

  @Output("finished") finished = new EventEmitter()
  @Output("hasQuiz") hasQuiz = new EventEmitter()
  @Input() idPalestra: number;
  // @Input() socket: Socket
  quiz: IQuiz;

  selected1: boolean = null
  selected2: boolean = null
  selected3: boolean = null
  selected4: boolean = null

  constructor(
    private liveEspectadorService: LiveEspectadorService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getQuiz()

    // this.socket.fromEvent('quiz').subscribe(data => {
    //   this.getQuiz()
    // });
  }

  async getQuiz() {
    try {
      this.quiz = await this.liveEspectadorService.getQuiz(this.idPalestra)
      if (this.quiz)
        this.hasQuiz.emit()
    } catch (error) { }
  }

  setSelected(value: number) {
    switch (value) {
      case 1: {
        this.selected2 = false;
        this.selected3 = false;
        this.selected4 = false;
        break;
      }
      case 2: {
        this.selected1 = false;
        this.selected3 = false;
        this.selected4 = false;
        break;
      }
      case 3: {
        this.selected1 = false;
        this.selected2 = false;
        this.selected4 = false;
        break;
      }
      case 4: {
        this.selected1 = false;
        this.selected2 = false;
        this.selected3 = false;
        break;
      }
    }
  }

  async submit() {
    try {
      var alternativa: any;
      var alternativaDescricao: any;
      if (this.selected1) {
        alternativa = 1;
        alternativaDescricao = this.quiz.opcao1;
      } else if (this.selected2) {
        alternativa = 2;
        alternativaDescricao = this.quiz.opcao2;
      } else if (this.selected3) {
        alternativa = 3;
        alternativaDescricao = this.quiz.opcao3;
      } else if (this.selected4) {
        alternativa = 4;
        alternativaDescricao = this.quiz.opcao4;
      } else {
        this.toast.error("Escolha uma alternativa.");
        return;
      }
      await this.liveEspectadorService.saveQuiz(
        this.idPalestra,
        this.quiz.id,
        alternativa,
        alternativaDescricao
      )
      this.quiz.respondido = true
      this.finished.emit({ value: false })
    } catch (error) {
      this.toast.error("Erro ao salvar resposta");
    }
  }

  cancel() {
    this.finished.emit({ value: false })
  }
}
