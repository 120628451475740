<div class="p-4">
    <div class="close pull-right ml-3" aria-label="Close" (click)="bsModalRef.hide()">
        <span style="cursor: pointer; font-size: 30px;" aria-hidden="true">&times;</span>
    </div>

    <h3 class="font-title mb-4 b-bottom-1">Colaborador</h3>

    <form class="d-flex flex-column align-items-center justify-content-center" (ngSubmit)="salvarColaborador()">

        <div class="d-flex flex-column mt-2">

            <label class="label-input" for="nome_completo">Nome Completo</label>
            <input class="form-control" type="text" name="nome_completo" [(ngModel)]="colaborador.nome_completo"
                [ngModelOptions]="{standalone: true}" placeholder="Nome">


            <label class="label-input" for="email">E-mail</label>
            <input [class.invalid]="emailErr" class="form-control" type="text" name="email"
                [(ngModel)]="colaborador.email" [ngModelOptions]="{standalone: true}" placeholder="E-mail *">
            <div *ngIf="emailErr">
                <span class="err">
                    {{emailErr}}
                    <a class="action__invite" (click)="sendInvite()">Enviar solicitação para vinculá-lo</a>
                </span>

            </div>

            <label class="label-input" for="cpf">CPF</label>
            <input class="form-control" type="text" name="cpf" [(ngModel)]="colaborador.cpf"
                [ngModelOptions]="{standalone: true}" placeholder="CPF *" mask="000.000.000-00">

            <label class="label-input" for="cargo">Cargo</label>
            <select class="form-control" name="cargo" placeholder="Cargo *" [(ngModel)]="colaborador.id_cargo"
                [ngModelOptions]="{standalone: true}">
                <option *ngFor="let option of cargos" [value]="option.id">{{option.descricao}}</option>
            </select>
            <input *ngIf="colaborador.id_cargo == 6" class="form-control mt-1" type="text" placeholder="Cargo *"
                [(ngModel)]="colaborador.cargo_nome" [ngModelOptions]="{standalone: true}">


            <label class="label-input" for="setor">Setor</label>
            <input class="form-control" type="text" name="setor" [(ngModel)]="colaborador.setor"
                [ngModelOptions]="{standalone: true}" placeholder="Setor">

            <!-- <select class="form-control" name="lider" placeholder="Líder *" [(ngModel)]="colaborador.id_lider">
                <option [value]="undefined">Selecione um líder</option>
                <option *ngFor="let option of lideres" [value]="option.id_espectador">{{option.nome}}</option>
            </select> -->

            <label class="label-input" for="contratante_ativo">Status</label>
            <select class="form-control" name="contratante_ativo" placeholder="Status *"
                [(ngModel)]="colaborador.contratante_ativo" [ngModelOptions]="{standalone: true}">
                <option [value]="'SIM'">Ativo</option>
                <option [value]="'NAO'">Inativo</option>
            </select>

            <label class="label-input" for="responsavel_grupo">É tutor?</label>
            <select class="form-control" name="responsavel_grupo" placeholder="Tutor *"
                [(ngModel)]="colaborador.responsavel_grupo" [ngModelOptions]="{standalone: true}">
                <option [value]="'SIM'">Sim</option>
                <option [value]="'NAO'">Não</option>
            </select>

            <div class="mt-4"></div>
            <button *ngIf="!loading" type="submit" class="btn btn-primary btn-warning">Salvar</button>

            <button *ngIf="loading" class="btn btn-primary btn-warning">
                <img height="25px" src="../../../assets/img/simple_loading.gif" alt="loading">
            </button>
        </div>
    </form>
</div>