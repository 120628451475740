import { Component, OnInit } from '@angular/core';
import { EspecialistasService, IGetEspecialistasParams } from 'src/app/services/especialistas';
import { CommonService } from 'src/app/services/common';
import { FormBuilder, Validators, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { faixaInvestimento } from "src/app/common/array-investimento";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalOrcarComponent } from "src/app/common/modal-orcar";
import { ActivatedRoute } from '@angular/router';

export interface IEspecialsita {
  carteira: string
  cidade: string
  consultor: string
  email: string
  estado: string
  formato: string
  id_carteira: number
  id_estado: number
  id_nossa_empresa: number
  id_tema: string
  id_usuario: number
  influenciador: string
  investimento: string
  mentor: string
  minibio: string
  nome_sobrenome: string
  ordem: number
  palestrante: string
  palestrante_video: string
  pesquisa: string
  photo: string
  pontuacao: number
  preco: string
  slug: string
  tema: string
  trainer: string
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  bsModalRef: BsModalRef;

  loading: boolean = false;
  isOpenFilters: boolean = false;

  formatos: Array<any> = []
  temas: Array<any> = []
  investimentos: Array<any> = faixaInvestimento

  especialistas: Array<IEspecialsita> = []
  qtdePages: Array<number> = [];
  page: number = 0

  formGroup: FormGroup;

  constructor(
    private modalService: BsModalService,
    private fb: FormBuilder,
    private especialistasService: EspecialistasService,
    private commonService: CommonService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    var params = this.route.queryParams['_value']

    var aux_investimento: any = ''
    if (params?.investimento) {
      aux_investimento = params.investimento < 9999 ? 9999 : params.investimento < 20000 ? 10000 : 20000
    }

    this.formGroup = this.fb.group({
      id_tema: [params.tema ? params.tema : ''],
      // regiao: [params.regiao ? params.regiao : ''],
      regiao: [''],
      formato: [params.formato ? params.formato : ''],
      investimento: [aux_investimento],
      pesquisa: [params.pesquisa ? params.pesquisa : '']
    });

    this.getInfo()
    this.getEspecilasitas()
  }

  async getInfo() {
    try {
      this.formatos = await this.commonService.getFormatos()
      this.temas = await this.commonService.getTemas()
    } catch (error) { }
  }

  async getEspecilasitas() {
    try {
      this.loading = true
      const res = await this.especialistasService.getList({
        id_tema: this.formGroup.get('id_tema').value,
        regiao: this.formGroup.get('regiao').value,
        formato: this.formGroup.get('formato').value,
        investimento: this.formGroup.get('investimento').value,
        pesquisa: this.formGroup.get('pesquisa').value,
        tema: '',
        empresa: 4,
        page: this.page,
      })
      this.especialistas = res
      this.loading = false
    } catch (error) {
      this.loading = false
    }

    try {
      const res = await this.especialistasService.getCountList({
        id_tema: this.formGroup.get('id_tema').value,
        regiao: this.formGroup.get('regiao').value,
        formato: this.formGroup.get('formato').value,
        investimento: this.formGroup.get('investimento').value,
        pesquisa: this.formGroup.get('pesquisa').value,
        tema: '',
        empresa: 4,
        page: this.page,
      })
      let count = parseInt(res)
      this.setQtdePages(parseInt("" + count) < res ? count + 1 : count)

    } catch (error) {
    }
  }

  openFilter() {
    this.isOpenFilters = !this.isOpenFilters
  }

  applyFilter() {
    this.page = 0
    this.getEspecilasitas()
  }

  changePage(number: number) {
    this.page = number;
    this.getEspecilasitas()
    this.getPages()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array.from(Array(qtdeColaboradores).keys())
  }

  getMinibio(text: String, maxString?: number) {
    if (!maxString) maxString = 47;
    return text.length > maxString ? text.substring(0, (maxString - 4)) + ' ...' : text;
  }

  getPages() {
    let listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2))
    return listPage
  }

  orcar() {
    this.bsModalRef = this.modalService.show(ModalOrcarComponent);
  }
}
