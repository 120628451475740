import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PalestrantesComponent } from './palestrantes/palestrantes.component';
import { EditarPalestranteComponent } from './palestrantes/editar-palestrante/editar-palestrante.component';
import { DadosAdminComponent } from './palestrantes/editar-palestrante/etapas/dados-admin/dados-admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfiguracoesModule } from '../configuracoes';
import { TemasAdminComponent } from './palestrantes/editar-palestrante/etapas/temas-admin/temas-admin.component';
import { PalestrasDisponiveisAdminComponent } from './palestrantes/editar-palestrante/etapas/palestras-disponiveis-admin/palestras-disponiveis-admin.component';
import { FormPalestraDisponivelAdminComponent } from './palestrantes/editar-palestrante/etapas/palestras-disponiveis-admin/form-palestra-disponivel-admin/form-palestra-disponivel-admin.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CacheAdminComponent } from './palestrantes/editar-palestrante/etapas/cache-admin/cache-admin.component';
import { ExperienciaAdminComponent } from './palestrantes/editar-palestrante/etapas/experiencia-admin/experiencia-admin.component';
import { FormExperienciaAdminComponent } from './palestrantes/editar-palestrante/etapas/experiencia-admin/form-experiencia-admin/form-experiencia-admin.component';
import { FormacaoAdminComponent } from './palestrantes/editar-palestrante/etapas/formacao-admin/formacao-admin.component';
import { FormFormacaoAdminComponent } from './palestrantes/editar-palestrante/etapas/formacao-admin/form-formacao-admin/form-formacao-admin.component';
import { DepoimentosAdminComponent } from './palestrantes/editar-palestrante/etapas/depoimentos-admin/depoimentos-admin.component';
import { FormSolicitarDepoimentoAdminComponent } from './palestrantes/editar-palestrante/etapas/depoimentos-admin/form-solicitar-depoimentos-admin/form-solicitar-depoimentos-admin.component';
import { CadastrarPalestranteComponent } from './palestrantes/cadastrar-palestrante/cadastrar-palestrante.component';
import { CadastrarUsuarioComponent } from './usuarios/cadastrar-usuario/cadastrar-usuario.component';
import { RegistrationComponent } from '../registration';
import { SharedModule } from 'src/app/shared/shared.module';
import { UsuarioComponent } from './usuarios/usuario.component';
import { EditarUsuarioComponent } from './usuarios/editar-usuario/editar-usuario.component';
import { PrecosComponent } from './precos/precos.component';
// import { RegistrationComponent } from '../registration/registration.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ReasonsComponent } from './palestrantes/editar-palestrante/etapas/reasons/reasons.component';
import { AnswerComponent } from '../pesquisar/answer/answer.component';
import { RepositorioAdminComponent } from './palestrantes/editar-palestrante/etapas/repositorio-admin/repositorio-admin.component';
import { ResultadosPesquisasComponent } from './resultados-pesquisas/resultados-pesquisas.component';
import { CreateFormComponent } from './certificados/create-forms/create-forms.component';
import { AnswerFormComponent } from './certificados/answer-forms/answer-forms.component';
import { CertificatesAnswersComponent } from './certificados/certificates-answers/certificates-answers.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { GestaoAdminComponent } from './palestrantes/editar-palestrante/etapas/gestao-admin/gestao-admin.component';

import { FormEditarDepoimentoAdminComponent } from './palestrantes/editar-palestrante/etapas/depoimentos-admin/form-editar-depoimentos-admin/form-editar-depoimentos-admin.component';
import { EditEventComponent } from './certificados/edit-event/edit-event.component';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmarIndicacaoComponent } from '../confirmarIndicacao/confirmar-indicacao.component';
import { VerifyCertificatesComponent } from './certificados/verify-certificates/verify-certificates.component';
import { ModalOptComponent } from './certificados/answer-forms/model-opt/modal-opt.component';
import { MatTabsModule } from '@angular/material/tabs';
import { PalestranteExclusivoComponent } from './palestrantes/cadastrar-palestrante-exclusivo/palestrante-exclusivo.component';
import { ExclusivoAdminComponent } from './palestrantes/editar-palestrante/etapas/exclusivo-admin/exclusivo-admin.component';
import { UseSummaryComponent } from './certificados/certificates-answers/tabs/use-summary.component';
import { VideosComponent } from './tbsb/videos/videos.component';
import { VideoDetailsComponent } from './tbsb/videos/video-details/video-details.component';
import { TbsbComponent } from './tbsb/tbsb.component';
import { RankingComponent } from './tbsb/ranking/ranking.component';
import { NoticesComponent } from './tbsb/notices/notices.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ModalUpdateRankingComponent } from './tbsb/ranking/modal-update-ranking/modal-update-ranking.component';
import {MatInputModule} from '@angular/material/input';
import { ModalNewNoticeComponent } from './tbsb/notices/modal-new-notice/modal-new-notice.component';
import { ModalEditNoticeComponent } from './tbsb/notices/modal-edit-notice/modal-edit-notice.component';
@NgModule({
  declarations: [
    PalestrantesComponent,
    EditarPalestranteComponent,
    DadosAdminComponent,
    TemasAdminComponent,
    PalestrasDisponiveisAdminComponent,
    FormPalestraDisponivelAdminComponent,
    CacheAdminComponent,
    ExperienciaAdminComponent,
    FormExperienciaAdminComponent,
    FormacaoAdminComponent,
    FormFormacaoAdminComponent,
    DepoimentosAdminComponent,
    FormSolicitarDepoimentoAdminComponent,
    FormEditarDepoimentoAdminComponent,
    CadastrarPalestranteComponent,
    CadastrarUsuarioComponent,
    UsuarioComponent,
    EditarUsuarioComponent,
    PrecosComponent,
    ReasonsComponent,
    AnswerComponent,
    RepositorioAdminComponent,
    ResultadosPesquisasComponent,
    CreateFormComponent,
    EditEventComponent,
    AnswerFormComponent,
    CertificatesAnswersComponent,
    UseSummaryComponent,
    GestaoAdminComponent,
    ConfirmarIndicacaoComponent,
    VerifyCertificatesComponent,
    ModalOptComponent,
    PalestranteExclusivoComponent,
    ExclusivoAdminComponent,
    TbsbComponent,
    VideosComponent,
    VideoDetailsComponent,
    RankingComponent,
    NoticesComponent,
    ModalUpdateRankingComponent,
    ModalNewNoticeComponent,
    ModalEditNoticeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ConfiguracoesModule,
    NgSelectModule,
    SharedModule,
    AngularEditorModule,
    NgxPaginationModule,
    MatTabsModule,
    NgxMaskModule.forRoot(),
    MatPaginatorModule,
    MatInputModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AdminModule {}
