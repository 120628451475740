import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { ModalPlayComponent } from 'src/app/common/modal-play';
import { PalestrasDisponiveisService } from 'src/app/services/palestras-disponiveis';

@Component({
  selector: 'app-palestras-disponiveis',
  templateUrl: './palestras-disponiveis.component.html',
  styleUrls: ['./palestras-disponiveis.component.scss'],
})
export class PalestrasDisponiveisComponent implements OnInit {
  @Output() onClose = new EventEmitter();

  bsModalRef: BsModalRef;

  loading: boolean = false;
  palestras: Array<any> = [];

  idSelected: number = undefined;

  constructor(
    private modalService: BsModalService,
    private disponiveisComponent: PalestrasDisponiveisService,
    private toast: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getPalestras();
  }

  async getPalestras() {
    try {
      this.loading = true;
      const res = await this.disponiveisComponent.getList();
      this.palestras = res.palestras;
      this.loading = false;
    } catch (error) {
      this.toast.error('Não foi possível carregas seus produtos');
      this.loading = false;
    }
  }

  update(id: number) {
    this.idSelected = id;
  }

  insert() {
    this.idSelected = 0;
  }

  delete(id: number) {
    const initialState = {
      mensagem: 'Deseja realmente excluir?',
      submensagem: '',
    };
    const config = { initialState };
    this.bsModalRef = this.modalService.show(
      ModalConfirmationComponent,
      config
    );

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value;
      value == true ? true : false;
      if (value == true) this.deleteRequest(id);
    });
  }

  async deleteRequest(id: number) {
    try {
      this.loading = true;
      const res = await this.disponiveisComponent.delete(id);
      this.getPalestras();
      this.toast.success('Excluído com sucesso!');

      this.loading = false;
    } catch (error) {
      this.toast.error('Não foi possível exluir');
      this.loading = false;
    }
  }

  formFinished() {
    this.idSelected = undefined;
    this.getPalestras();

    this.close();
  }

  play(code: string, titulo: string) {
    const initialState = {
      videoLink: 'https://www.youtube.com/embed/' + code,
      title: titulo,
    };
    const config = { class: 'modal-play', initialState };
    this.bsModalRef = this.modalService.show(ModalPlayComponent, config);
  }

  close() {
    this.onClose.emit('');
  }
}
