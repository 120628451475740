<section>

    <div class="container__item__full">
        <div (click)="go(0)" class="item container__item">
            <img src="assets/icons/conteudo.svg" alt="conteudo">
            <h2 class="font-title">
                Meus conteúdos
            </h2>
            <p>Aqui você faz upload e gerencia os conteúdos próprios da sua empresa. Vídeos, apostilas e tudo mais que
                você já tenha produzido e precise utilizar para complementar o T&D dos colaboradores da sua empresa.</p>
        </div>
    </div>
    <div class="d-flex flex-column container__item__50">

        <div (click)="go(1)" class="item container__item">
            <img src="assets/icons/conteudo_SA.svg" alt="conteudo">
            <h3 class="font-title">
                Conteúdos - Profissionais SA
            </h3>
            <p>Aqui você gerencia todos os conteúdos produzidos pela Profissionais SA e que farão parte das trilhas de
                microlearning para os seus colaboradores.</p>
        </div>


        <div (click)="go(2)" class="item container__item">
            <img src="assets/icons/alert.svg" alt="conteudo">
            <h3 class="font-title">
                Comunicação interna
            </h3>
            <p>Normas, avisos e demais informações que você quiser notificar os smartphones dos seus colaboradores via
                push do App Profissionais SA.</p>
        </div>
    </div>

</section>