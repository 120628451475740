import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CheckValidacaoSenhaComponent } from 'src/app/common/export-components/check-validacao-senha';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { UsuarioContatosService } from 'src/app/services/usuario-contatos';
import { FormContatoComponent } from './form-contato';
import { OpcoesAdicaoContatoComponent } from './opcoes-adicao-contato';
import { ListColaboradoresComponent } from './list-colaboradores/list-colaboradores.component';
import { UserService } from 'src/app/services/user';

export interface IContato {
  id: number,
  id_usuario: number,
  nome: string,
  email: string,
  telefone?: string,
  cargo?: string,
  id_cargo?: number,
  departamento?: string,
  principal: string,
  percentual_societario?: string,
  razaosocial_societario?: string,
  datafuntacao_societario?: string,
  tipofiscal_societario?: string,
  cpf_cnpj?: string,
  senha?: string,
  id_contratante_colaborador: number
}

@Component({
  selector: 'app-tb-contatos',
  templateUrl: './tb-contatos.component.html',
  styleUrls: ['./tb-contatos.component.scss']
})
export class TbContatosComponent implements OnInit {
  bsModalRef: BsModalRef;

  contatoEditar: IContato;
  contatos: Array<IContato> = [];
  cargos: Array<any> = [];
  adicionar: boolean = false;
  changePassword: boolean = false;
  mensagem: string = "";
  loading: boolean = false;

  senha: string = "";
  confirma_senha: string = "";

  @ViewChild("validacaoPass", { static: true }) checkValidacaoSenhaComponent: CheckValidacaoSenhaComponent;

  constructor(
    private userService: UserService,
    private userContactService: UsuarioContatosService,
    private toast: ToastrService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.getContatos();
  }

  async getContatos() {
    const resContatos = await this.userContactService.getUsuarioContatos()
    this.contatos = resContatos.message ? [] : resContatos;
  }

  changePasswordContato(i: number) {
    this.contatoEditar = this.contatos[i]
    this.changePassword = true;
    this.adicionar = false
  }

  editarContato(i: number) {
    this.changePassword = false;
    this.contatoEditar = this.contatos[i]
    this.adicionar = true

    this.openModalFormContact(this.contatos[i])
  }

  adicionarContato() {
    this.contatoEditar = null
    this.changePassword = false;
    this.reset();
    this.adicionar = true

    const initialState = { mensagem: "Deseja realmente excluir?", submensagem: "" }
    const config: any = { initialState }
    this.bsModalRef = this.modalService.show(OpcoesAdicaoContatoComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.type;
      if (value == "COLABORADOR") {
        this.openModalListCollaborators()
      } else if (value == "CONTATO") {
        this.openModalFormContact(null)
      }
    })
  }

  openModalListCollaborators() {
    const config = { class: 'modal-play' }
    this.bsModalRef = this.modalService.show(ListColaboradoresComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.reload
      value == true ? true : false;
      if (value == true) {
        this.getContatos();
      }
    })
  }

  openModalFormContact(contact: any) {
    const initialState = { contact: contact }
    const config = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(FormContatoComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.reload
      value == true ? true : false;
      if (value == true) {
        this.getContatos();
      }
    })
  }

  cancelarAdicionar() {
    this.contatoEditar = null
    this.adicionar = false
    this.getContatos();
  }


  excluirContato(id: number) {
    const initialState = { mensagem: "Deseja realmente excluir?", submensagem: "" }
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value
      value == true ? true : false;
      if (value == true) this.deleteRequest(id);
    })
  }

  async deleteRequest(id: number) {
    try {
      this.loading = true
      const res = await this.userContactService.deleteContato(id)
      this.getContatos()
      this.toast.success("Excluído com sucesso!")

      this.loading = false
    } catch (error) {
      this.toast.error("Não foi possível exluir")
      this.loading = false
    }
  }

  setSenha() {
    try {
      this.loading = true;

      if (!(this.senha.length > 0)) {
        this.loading = false;
        this.toast.error("Senha deve ser preenchido!");
        return;
      }

      if (!(this.confirma_senha.length > 0)) {
        this.loading = false;
        this.toast.error("Repita a nova senha!");
        return;
      }

      let senhaRegex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})\S{8,20}$/;
      if (!senhaRegex.test(this.senha)) {
        this.loading = false;
        this.toast.error("Senha nova inválida. Use 8 a 20 caracteres, contendo letras e números, sendo ao menos uma letra maiúscula.");
        return;
      }

      if (this.senha !== this.confirma_senha) {
        this.loading = false;
        this.toast.error("Senha repetida incompátival!");
        return;
      }

      this.userContactService.updateSenhaContato(Object.assign({ senha: this.senha, id: this.contatoEditar.id }))

      this.toast.success("Senha alterada com sucesso");

      this.reset();
      this.changePassword = false;
      this.loading = false;
      // this.getStartInfo();

    } catch (e) {
      this.loading = false;
      this.toast.error("Não foi possível trocar senha.", e.error.message)
    }
  }

  reset() {
    this.senha = "";
    this.confirma_senha = "";
    this.contatoEditar = {
      id: null,
      id_usuario: null,
      nome: "",
      email: "",
      telefone: "",
      cargo: "",
      id_cargo: null,
      departamento: "",
      principal: "NAO",
      percentual_societario: "",
      razaosocial_societario: "",
      datafuntacao_societario: "",
      tipofiscal_societario: "",
      cpf_cnpj: "",
      senha: "",
      id_contratante_colaborador: null
    }
  }

  checkPasswordRule() {
    this.checkValidacaoSenhaComponent.checkPasswordRule();
  }

  confirmConvertToCollaborator(i: number) {
    let contact = this.contatos[i];

    if (!contact.cpf_cnpj) {
      this.toast.error("Preencha o CPF", "Campo obrigatório para um colaborador")
      return
    } else if (!this.userService.validationCPF(contact.cpf_cnpj)) {
      this.toast.error("CPF inválido", "Campo obrigatório para um colaborador")
      return
    }

    const initialState = { mensagem: "Deseja realmente tornar um colaborador?", submensagem: `Administrador ${contact.nome}` };
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(async () => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.value) {
        try {
          this.loading = true
          await this.userContactService.convertContactToCollaborator(contact.id)
          this.toast.success("Colaborador criado")
          this.getContatos()
          this.loading = false
        } catch (error) {
          this.toast.error("Falha ao converter administrador em contato")
          this.loading = false
        }
      }
    })
  }
}
