import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home';
import { ExportComponentsModule } from 'src/app/common/export-components';
import { SkillTemplateComponent } from './home/skill-template';
import { SkillDetailComponent } from './skill-detail';
import { SkillTaskComponent } from './skill-task';
import { QuestionsComponent } from './skill-task/questions';
import { QuestionAnswersComponent } from './skill-task/question-answers';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [HomeComponent, SkillTemplateComponent, SkillDetailComponent, SkillTaskComponent, QuestionsComponent, QuestionAnswersComponent],
  imports: [
    CommonModule,
    ExportComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
  ]
})
export class DesenvolvimentoModule { }
