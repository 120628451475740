import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { PalestrantesService } from 'src/app/services/palestrantes';
import { UserService } from 'src/app/services/user';
import {
  cpfCnpjMask,
  phoneMask,
} from 'src/app/utils/formatters/string.formatter';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  @Output() onGoBack = new EventEmitter<any>();

  user: any = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    cpf: '',
    city: '',
    state: '',
    type: 'fisica',
    socialName: '',
    cnpj: '',
    email: '',
    password: '',
    tipo: 'PALESTRANTE',
  };

  confirmPass: string | null = null;

  errors: any = {
    empty: (field) => `O campo "${field}" é obrigatório`,
    invalid: (field) => `O campo "${field}" é inválido`,
  };

  errorMessage: string;

  isAdmin: boolean = false;

  loading: boolean = false;

  constructor(
    private userService: UserService,
    private palestrantesService: PalestrantesService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {
    const path = window.location.href.split('/');
    if (path.some((param) => param === 'admin')) this.isAdmin = true;
  }

  validateEmail(email): boolean {
    // const EMAIL_REGEX = /^[a-z0-9._]+@[a-z0-9]+(\.[a-z]+)+$/i;
    // const isEmailValid = EMAIL_REGEX.test(email);

    // if (!email) {
    //   this.errorMessage = this.errors.empty('Email');
    //   this.toast.error(
    //     '', 'O campo e-mail nao pode ser vazio',
    //     { timeOut: 5000 }
    //   );
    //   return false;
    // }

    // if (!isEmailValid) {
    //   this.errorMessage = this.errors.invalid('Email');
    //   this.toast.error(
    //     '', 'Campo "E-mail" inválido',
    //     { timeOut: 5000 }
    //   );
    //   return false;
    // } else {
    //   this.errorMessage = null;
    //   return true;
    // }
    return true;
  }

  validatePassword(password): boolean {
    if (!password) {
      this.toast.error(
        '', 'O campo "Senha" nao pode ser vazio',
        { timeOut: 5000 }
      );
      this.errorMessage = this.errors.empty('Senha')};

    if (password !== this.user.password || password !== this.confirmPass) {
      // this.toast.error(
      //   '', 'O campo "Senha" é inválido. Verifique e tente novamente',
      //   { timeOut: 5000 }
      // );
      this.errorMessage = this.errors.invalid('Senha');
      return false;
    } else {
      this.errorMessage = null;
      return true;
    }
  }

  verifyCPF(cpf): boolean {
    if (this.isAdmin) {
      return true;
    }

    const isCPFValid = this.userService.validationCPF(cpf);
    if (!isCPFValid) {
      this.toast.error(
        '', 'O campo "CPF" é inválido. Verifique e tente novamente',
        { timeOut: 5000 }
      );
      this.errorMessage = this.errors.invalid('CPF');
    } else {
      this.errorMessage = null;
    }

    return isCPFValid;
  }

  createUser(): void {}

  verifyEmptiness(): boolean {

    if (this.isAdmin) {
      return true;
    }
    if (this.user.type === 'juridica') {
      const isCompleted = Object.values(this.user).every(
        (value) => value !== ''
      );
      if (!isCompleted) {
        this.toast.error(
          '', 'CPF incompleto ou inválido. Verifique os números e tente novamente',
          { timeOut: 5000 }
        );
        return false
      };
    } else {
      const isHalfCompleted = Object.values(this.user);
      for (let i = 0; i < isHalfCompleted.length; i++) {
        if (i !== 7 && i !== 8) {
          if (isHalfCompleted[i] === '') {
            this.toast.error(
              '', 'CPF incompleto ou inválido. Verifique os números e tente novamente',
              { timeOut: 5000 }
            );
            return false;
          }
        }
      }
    }

    return true;
  }

  handleSubmit(): void {
    this.loading = true;
    if (this.isAdmin) {
      this.user.password = 'PSA123';
      this.confirmPass = 'PSA123';
    }

    const finalValidation = [
      this.verifyEmptiness(),
      this.validateEmail(this.user.email),
      this.validatePassword(this.user.password),
      this.verifyCPF(this.user.cpf),
    ];

    const canProceed = finalValidation.every((value) => value);

    if (!canProceed || this.errorMessage) {
      this.errorMessage = 'Algo deu errado';
      this.loading = false;
    } else {
      this.errorMessage = null;
      this.palestrantesService.cadastrarPalestrante(this.user).then(() => {
        this.toast.success(
          'Por favor, efetue o login para continuar.',
          'Cadastro realizado com sucesso!',
          { timeOut: 5000 }
        );
        this.loading = false;

        this.user = {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          cpf: '',
          city: '',
          state: '',
          type: 'fisica',
          socialName: '',
          cnpj: '',
          email: '',
          password: '',
          tipo: 'PALESTRANTE'
        };

        this.goBack();
      }).catch((e) => {
        console.log(e);
        this.toast.error(
          '', e.error.message.message,
          { timeOut: 5000 }
        );
        this.loading = false;
      });
    }
  }

  goBack(): void {
    this.onGoBack.emit();
  }

  formatPhone(value: string) {
    this.user.phoneNumber = '';
    return (this.user.phoneNumber = phoneMask(value));
  }

  formatCpf(value: string) {
    this.user.cpf = '';
    return (this.user.cpf = cpfCnpjMask(value));
  }

  formatCnpj(value: string) {
    this.user.cnpj = '';
    return (this.user.cnpj = cpfCnpjMask(value));
  }
}
