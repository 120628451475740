import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ISkill, TarefasService } from 'src/app/services/tarefas';
import { TasksItemComponent } from '../tasks-item/tasks-item.component';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html'
})

export class TasksComponent implements OnInit {
  @ViewChild("questionAnswer", { static: false }) questionAnswer: TasksItemComponent;
  @Input() skill: ISkill

  canConcluded: boolean = false
  indexSelected: number = null
  idSkill: number = null
  isApproved: boolean = false
  loading: boolean = false
  noQuestions: boolean = false
  questions: Array<any>
  status: string
  skillTitle: string
  tabTask: number | 1 | 2 | 3 | 4 = 1
  taskCompleted: boolean = false

  constructor(
    private toast: ToastrService,
    private route: ActivatedRoute,
    private taskService: TarefasService,
  ) { }

  ngOnInit(): void {
    this.skillTitle = this.skill.habilidade_titulo
    this.status = this.skill.habilidade_status
    this.questions = this.skill.perguntas
    this.noQuestions = (this.questions.length ===  0)

    this.isApproved = this.status === "AVALIADA"
    this.taskCompleted = this.status === "RESPONDIDA" || this.status === "AVALIADA"
    this.idSkill = parseInt(this.route.snapshot.paramMap.get("id"))

    if(this.status === "PENDENTE"){
      this.updateStatus()
    }

    this.getTabStatus()
    this.getQuestions()
  }

  addZeroLeft(num, len = 2) {
    let numberZeros = String(num);
    let counter = numberZeros.length;

    while (counter < len) {
      numberZeros = "0" + numberZeros;
      counter++;
    }

    return numberZeros;
  }

  setTabStatus(newTabStatus: number) {
    this.tabTask = newTabStatus;
  }

  getTabStatus() {
    let inititalStatus: number = 1;

    if (this.noQuestions) {
      inititalStatus = 4;
    }if (this.status === "INICIADA") {
      inititalStatus = 2;
    } else if (this.status === "RESPONDIDA" || this.status === "AVALIADA" || this.status === "FINALIZADA") {
      inititalStatus = 3;
    }

    this.setTabStatus(inititalStatus)
  }

  nextQuestion() {
    let nextIndexSelected = this.indexSelected + 1

    if(nextIndexSelected >= this.questions.length){
      this.getQuestions()
      nextIndexSelected = 0
    }

    //checagem de tipo de questão
    if (this.questionAnswer.question.tipo == "DESCRITIVA") {
      this.questionAnswer.checkQuestion();
    }

    this.selectTaskItem(this.questions[nextIndexSelected])
  }

  startQuiz() {
    this.setTabStatus(2)
  }

  selectTaskItem(question: any) {
    this.indexSelected = this.questions.indexOf(question)
    this.questionAnswer.size = this.questions.length
    this.questionAnswer.indexQuestion = this.questions.indexOf(question)
    this.questionAnswer.question = question
    this.questionAnswer.ngOnInit()
  }

  checkCanConclued() {
    let filtered = this.questions.find(i => !i.resposta)
    if (this.status === "FINALIZADA" || this.status === "AVALIADA") {
      return false;
    }else if (this.noQuestions){
      return true;
    } else if (!filtered) {
      return true;
    }

    return false;
  }

  async getQuestions() {
    try {
      this.questions = await this.taskService.getQuestionsSkillById(this.idSkill);
    } catch (error) {
      this.toast.error("Ops... Ocorreu um erro ao carregar as questões! Tente novamente mais tarde.");
      console.info(`Erro ao carregar questões: ${error.message}`);
    }

    (this.noQuestions) ? this.updateStatus('FINALIZADA') : this.selectTaskItem(this.questions[0]);
  }

  // TODO - Criar uma interface para o tipo: status da tarefa
  async updateStatus(newStatus: "INICIADA" | "RESPONDIDA" | "AVALIADA" | "FINALIZADA" | "REFAZER" = "INICIADA") {
    if (newStatus === this.status) {
      console.info('O atual status é igual ao anterior. O upgrade não será realizado');
      return;
    }

    try {
      await this.taskService.updateStatus(this.idSkill, newStatus);
      console.info(`Status atualizado com sucesso. Novo status: ${newStatus}`);
    } catch (error) {
      console.info(`Erro ao fazer o update do status: ${error.message}`);
    }
  }

  async concludeSkill() {
    // if (!this.checkCanConclued()) {
    //   this.toast.error("Verifique suas respostas e tente novamente.", "Ops... A tarefa não pode ser concluída.");
    //   return;
    // }

    try {
      await this.taskService.concludeTask(this.skill.id);
      this.setTabStatus(3);
    } catch (error) {
      this.toast.error("Erro ao concluir a tarefa. Atualize a página e tente novamente.", "Ops... Algo deu errado");
      console.info(`Erro ao concluir a tarefa: ${error.message}`);
    }
  }
}
