import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EspectadoresService } from 'src/app/services/espectadores';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pdi-execution',
  templateUrl: './pdi-execution.component.html',
  styleUrls: ['./pdi-execution.component.scss']
})
export class PdiExecutionComponent implements OnInit {
  isClick: boolean = false;
  feedback: string;
  optionChoosed: string;
  disableButton: boolean = false;
  resposta: string | null = null;
  loading: boolean = true;
  proxima: any;
  isAnswered: boolean = false;
  habilidadeId: any;
  correctOption: any;
  pergunta: any;
  perguntaId: number;
  perguntas: any[];
  isRight: boolean;
  tarefa: any;
  trilha: string = 'PDI';

  constructor(
    public activateRoute: ActivatedRoute,
    private router: Router,
    private toast: ToastrService,
    private espectadoresService: EspectadoresService,
  ) { }

  ngOnInit(): void {
    this.fetchData();
  }

  async ionViewDidEnter() {
    this.loading = true;
    await this.fetchData();

    if (this.pergunta.resposta) {
      this.checkResposta(this.pergunta.resposta, true);
    }
    this.loading = false;
  }

  async fetchData() {
    this.resetPage();
    this.activateRoute.queryParams.subscribe((params) => {
      if (params && params.perguntaId) {
        this.perguntaId = params.perguntaId;
        this.perguntas = JSON.parse(params.perguntas);
        this.pergunta = this.perguntas.find(
          (pergunta) => pergunta.id == this.perguntaId
        );
      }
      this.loading = false;
    });
    return;
  }

  ionViewWillLeave() {
    this.pergunta = undefined;
    this.proxima = undefined;
    this.habilidadeId = undefined;
    this.isClick = false;
    this.disableButton = false;
    this.optionChoosed = undefined;
    this.feedback = undefined;
    this.loading = true;
    this.correctOption = undefined;
    this.resposta = null;
  }

  resetPage() {
    this.pergunta = undefined;
    this.proxima = undefined;
    this.habilidadeId = undefined;
    this.isClick = false;
    this.disableButton = false;
    this.optionChoosed = undefined;
    this.feedback = undefined;
    this.loading = true;
    this.resposta = null;
  }

  isCliked = () => {
    return this.isClick;
  };

  checkResposta = async (option, answered = false) => {
    this.correctOption = this.pergunta.opcoes.find((r) => r.peso == 100);
    this.isRight =
      this.correctOption && this.correctOption.ordem
        ? this.correctOption.ordem == option.ordem
        : this.pergunta.resposta.peso == 100
          ? true
          : false;
    this.disableButton = true;
    this.optionChoosed = option.ordem;
    this.feedback = option.explicacao;
    this.resposta = option ? option : null;

    this.isClick = true;
    this.isAnswered = answered;
  };

  checkPerguntaDescritiva = async () => {
    if (this.pergunta.resposta) {
      const params = this.getParams();
      if (params && this.proxima) {
        this.router.navigate(['task-execution'], { queryParams: params });
        return;
      } else {
        this.back();
        return;
      }
    }
    if (!this.resposta) {
      this.toast.error('Você precisa preencher a resposta');
      return;
    }
    this.pergunta.descritiva = {
      resposta: this.resposta,
      peso: 0,
      feedbackTutor: null
    };
    return await this.salvarResposta();
  };

  getPerguntas = async () => {
    this.perguntas = (await this.espectadoresService.getQuestionsPdi()).map((q, i) => {
      q.ordem = i + 1
      return q
    }).filter((q) => (q) => !q.resposta)
  }

  salvarResposta = async () => {

    if (await this.saveAnswerQuestion()) {
      await this.getPerguntas()
      const params = this.getParams();
      if (!params.perguntaId) {
        this.finalizar();
        return;
      } else {
        this.router.navigate(['espectador', 'pdi-execution'], { queryParams: params });
        return;
      }

      return;
    }
  };

  async finalizar() {
    await this.espectadoresService.setUserTrail(this.trilha);
    // this.global.updateUserOnDevice();
    // this.appCom.loadUserMenu()
    this.router.navigate(['espectador', 'home']);
    this.toast.success('Trilha Personalizada', 'Sua trilha foi configurada de acordo com suas respostas.')
  }

  getParams() {
    const pergunta = this.perguntas.find(
      (p) => !p.resposta
    );

    return {
      perguntaId: pergunta ? pergunta.id : null,
      perguntas: JSON.stringify(this.perguntas)
    };
  }

  saveAnswerQuestion = async () => {
    if (this.pergunta.resposta) {
      return true;
    }
    if (!this.resposta) {
      this.toast.error('Você precisa reponder a pergunta para avançar!');
      return false;
    }

    for (let i = 0; i < this.perguntas.length; i++) {
      this.perguntas[i].resposta =
        this.perguntas[i].id == this.perguntaId ? this.resposta : false;
    }
    this.loading = true;
    // this.global.presentLoading('Salvando sua resposta', 500);
    try {
      await this.espectadoresService.savePdiQuestion({
        perguntaId: this.pergunta.id,
        resposta: this.resposta
      });
      return true;
    } catch (error) {
      this.toast.error('Nao conseguimos salvar sua reposta tente em alguns instantes');
      return false;
    }
  };

  back() {
    this.router.navigate(['home-corporate']);
  }

}
