import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CachesService {

  constructor(public http: HttpClient) { }

  async getList(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const palestrante = JSON.parse(localStorage.getItem("palestrante"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}caches/list/${palestrante.id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getListByPalestranteId(palestrante: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}caches/list/${palestrante.id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getById(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const palestrante = JSON.parse(localStorage.getItem("palestrante"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}caches/${id}/${palestrante.id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertOrUpdate(caches: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const body = { caches: caches }
    const res = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}caches/insert-update`, body, { headers: headers }).toPromise();
    return res.body
  }

  async deleteCache(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const res = await this.http.delete<any>(`${environment.API_V2_URL_DEFAULT}caches/${id}`, { headers }).toPromise();
    return res;
  }
}
