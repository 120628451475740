import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
// import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { LiveEspectadorService } from 'src/app/services/live-espectador';
import { IChat } from '../live-espectador';

interface IChatSocket {
  user: string
  createdAt: Date
  msg: string
  photo: string
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['../live-espectador/live-espectador.component.scss', './chat.component.scss']
})
export class ChatComponent implements OnInit {

  @Input() chat: Array<any> = []
  // @Input() socket: Socket
  @Input() idPalestra: number
  question: string

  constructor(
    private toast: ToastrService,
    private liveEspectadorService: LiveEspectadorService) { }

  ngOnInit(): void {

    // this.socket.fromEvent('chat-message').subscribe((data: IChatSocket) => {
    //   this.chat.splice(0, 0, {
    //     usuario: data.user,
    //     datahora_pergunta: data.createdAt,
    //     perguntas: data.msg,
    //     foto: data.photo
    //   });
    // });
  }

  async send() {
    try {
      const user = JSON.parse(localStorage.getItem("usuario"))

      // this.socket.emit('send-message', {
      //   text: this.question, user: user.nome, photo: user.photo
      // })

      this.liveEspectadorService.saveQuestion(this.idPalestra, this.question)
      this.question = ""
    } catch (error) {
      this.toast.error("Erro ao enviar")
    }
  }
}
