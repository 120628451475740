import { Component, Input, OnInit } from '@angular/core';
// import { Socket } from 'ngx-socket-io';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalPlayComponent } from 'src/app/common/modal-play';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['../live-espectador/live-espectador.component.scss', './videos.component.scss']
})
export class VideosComponent implements OnInit {
  bsModalRef: BsModalRef;

  // @Input() socket: Socket

  isOpened: boolean = false

  constructor(
    private modalService: BsModalService,) { }

  ngOnInit(): void {
    // this.socket.fromEvent('video-apoio').subscribe(data => {
    //   if (!this.isOpened) {
    //     this.isOpened = true
    //     this.play(data['url'], data['title'])
    //   }
    // });
  }

  play(url: string, title: string) {
    const initialState = {
      videoLink: url,
      title: title
    };
    const config = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(ModalPlayComponent, config);
    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      this.isOpened = false
    })
  }
}
