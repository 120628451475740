import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPlan } from 'src/app/services/planos';

@Component({
  selector: 'app-checkout-planos',
  templateUrl: './checkout-planos.component.html',
  styleUrls: ['./checkout-planos.component.scss']
})
export class CheckoutPlanosComponent implements OnInit {

  @Input("idPlan") idPlan;
  @Output("finished") finished = new EventEmitter();
  step: number = 1

  constructor() { }

  ngOnInit(): void {
    if (this.idPlan) {
      this.step = 2
    }
  }

  selectPlan(event: any) {
    const plan: IPlan = event.plan;
    this.step++
    this.idPlan = plan.id
  }

  finishCheckout(success: boolean) {
    this.finished.emit({ success: success })
  }

}
