<div *ngIf="palestras.length == 0">
  Não há palestras!
</div>

<div
  *ngFor="let item of palestras"
  class="cardPalestra cardsContainer__item"
  [ngClass]="{ hideChildren : selecionada.class !== 'showChildren'}"
  (click)="selectItem(item.id)"
  #selecionada
>
  <div class="cardPalestra__img" [style.backgroundImage]="'url(' + item.foto_palestrante +')'">
    <img src="{{item.foto_palestrante}}" alt="{{item.palestrante}}">
  </div>

  <div class="cardPalestra__content">
    <p *ngIf="item.palestrante" class="cardPalestra__info">
      Com {{item.palestrante}}
    </p>

    <h3 *ngIf="item.nome" class="cardPalestra__title title">
      {{item.nome}}
    </h3>

    <span *ngIf="item.assunto" class="tagBadge">{{item.assunto}}</span>

    <p class="cardPalestra__infos">
      <span *ngIf="item.data_formatada" class="cardPalestra__info">
        <img class="pr-1 hoverTransform" src="assets/icons/calendar.svg" alt="data palestra" width="20">
        {{item.data_formatada}}
      </span>

      <span *ngIf="item.pais" class="cardPalestra__info">
        <img class="pr-1 hoverTransform" src="assets/icons/pin.svg" alt="local palestra" width="20">
        {{item.pais}}
      </span>
    </p>
  </div>
</div>
