import { Component, OnInit } from '@angular/core';
import { EntryComponent } from 'src/app/entry/entry.component';
import { AppComponent } from 'src/app/app.component';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { ContratanteService } from 'src/app/services/contratante';
import { getPathHome, getMenuPrincipal, getCommonMenu, getUserLocalStorage } from 'src/app/controle-perfil';
import { CommonService } from 'src/app/services/common';

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
})
export class SideComponent implements OnInit {

  itemsMenu: Array<any> = []
  itemsMenuPrincipal: Array<any> = [];

  usuario: string = '';
  empresa: string = '';
  pontuacao: string = '';
  nota: any;
  status: string = '';
  urlActive: string = '';
  perfil: string = '';

  phoneSupport: string;
  showSubNav: boolean = true;

  admUser: { nome, email } = null;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private entry: EntryComponent,
    private contratanteService: ContratanteService,
    private commonService: CommonService,
    private app: AppComponent) { }

  ngOnInit(): void {
    this.urlActive = window.location.pathname.includes("sa-news/home") ? 'sa-news/home' : '';
    this.getUsuario()
  }

  async getUsuario() {
    const usuario = await this.contratanteService.getUsuario()

    this.perfil = localStorage.getItem("perfil")
    localStorage.setItem("usuario", JSON.stringify(usuario));
    if (this.app.logado) this.refreshLogado();

    this.itemsMenuPrincipal = getMenuPrincipal()
    this.itemsMenu = getCommonMenu()

    if (this.perfil == "ESPECTADOR") {
      this.commonService.getConfiguracao("dashboard__espectador__suporte__whatsapp", "5511950789035")
        .then(res => { this.phoneSupport = res.valor })
        .catch(e => { console.log(e); })
    }

    if(this.perfil == 'ESPECTADOR' || this.perfil == 'PALESTRANTE') this.showSubNav = false;
  }

  closeSide() {
    this.entry.closeSide(true);
  }

  home() {
    this.urlActive = null;
    this.router.navigate([getPathHome()]);
  }

  open(id: number) {
    this.itemsMenu.map(item => {
      if (item.id == id) {
        if (!item.href) {
          this.urlActive = item.route ? item.route : null;
          item.route ? this.router.navigate([`/${item.route}`]) : this.msgEmDev();
        } else {
          window.open(item.href)
        }
      }
    });
  }

  openPrincipal(id: number) {
    this.itemsMenuPrincipal.map(item => {
      if (item.id == id) {
        this.urlActive = item.route ? item.route : null;
        item.route ? this.router.navigate([`/${item.route}`]) : this.msgEmDev();
      }
    });
  }

  msgEmDev() {
    this.toast.info('Em desenvolvimento.', 'Em breve traremos novidades.')
  }

  logout() {
    this.app.logout();
  }

  refreshLogado() {
    const usuario = getUserLocalStorage();
    let nomeSplit = usuario.nome.split(" ");
    this.usuario = usuario ? nomeSplit[0] : "";
    this.usuario += nomeSplit.length > 1 ? " " + nomeSplit[nomeSplit.length - 1] : "";
    this.empresa = usuario ? usuario.empresa : "";
    this.pontuacao = usuario ? usuario.pontuacao : "";
    this.status = usuario ? usuario.status : "";
    if (this.perfil == "ESPECTADOR") this.nota = usuario ? usuario.nota : "";
    if (this.perfil == "CONTRATANTE") this.setUserAdm()
  }

  setUserAdm() {
    try {
      const adm = localStorage.getItem("admUser");
      if (adm) {
        this.admUser = JSON.parse(adm);
        this.usuario = this.admUser.nome;
      }
    } catch (error) { }
  }

  support() {
    const usuario = getUserLocalStorage();

    let texto = "Olá, sou um espectador. Entro em contato por meio do site Profissionais SA - Dashboard. Sou " + usuario.nome + ", e-mail: + " + usuario.email;
    window.open("https://api.whatsapp.com/send?phone=" + this.phoneSupport + "&text=" + texto, "_blank")
  }

  navigateExternal(url: string): void {
    window.open(url, '_blank');
  }
}
