import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormCertificatesService } from 'src/app/services/FormCertificates/form-certificates.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import * as moment from 'moment';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { timeInterval } from 'rxjs/operators';

@Component({
  selector: 'app-certificates-answers',
  templateUrl: './certificates-answers.component.html',
  styleUrls: ['./certificates-answers.component.scss']
})
export class CertificatesAnswersComponent implements OnInit {
  answers: any = [];
  filteredAnswers: any = [];
  loading: boolean = false;

  ranking: any = [];
  filteredRanking: any = [];
  loadingRanking: boolean = false;

  //table pagination
  pages: number;
  currentPage: number = 0;

  filterStart: any = '';
  filterEnd: any = '';

  generalAvg: number = 0;
  headers = [
    'Evento',
    'Carimbo de data/hora',
    'Nome do palestrante',
    'Endereço de e-mail do palestrante',
    'Título do evento',
    'Endereço de e-mail do CS responsável',
    'Endereço de e-mail do cliente',
    'Nome do negócio',
    'Endereço de e-mail do participante',
    'Qual o seu nome completo?',
    'A performance do PALESTRANTE conectou com suas expectativas?',
    'Gostaria de ter acesso a mais conteúdos do PALESTRANTE?',
    'Qual o seu cargo?',
    'Qual o seu número de telefone?',
    'Deixe um comentário sobre este aprendizado!',
  ];

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Planilha de respostas',
    useBom: true,
    noDownload: false,
    headers: this.headers,
    eol: '\n'
  };

  filterName: string = '';
  filterNameSpeaker: string = '';
  filterCS: string = '';
  currentTab: any;

  constructor(
    private formCertificatesService: FormCertificatesService,
    private toast: ToastrService,
    private clip: Clipboard
  ) {}

  ngOnInit(): void {
    this.getCertificatesAnswers();
    this.getSpeakersRanking();
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentTab = tabChangeEvent.index;
    this.filterEnd = null;
    this.filterStart = null;
  }

  calcAvgAnswers(total: number, answers: number) {
    let average = 0;
    if (!total || total == 0) {
      average = 100;
    } else {
      average = (answers * 100) / total;
    }

    return Math.round(average * 100) / 100;
  }

  calcGeneralAvg(answers: any) {
    const total = answers.reduce((acc: any, curr: any) => acc + curr.qtdParticipantes, 0);
    const qtdAnswers = answers.reduce((acc: any, curr: any) => acc + curr.respostas, 0);
    const avg = (qtdAnswers * 100) /  total
    this.generalAvg = Math.round(avg * 100) / 100;
  }

  async getCertificatesAnswers(): Promise<void> {
    this.loading = true;
    const queryFilters = `?businessName=${this.filterName}&speakerName=${this.filterNameSpeaker}&emailCS=${this.filterCS}&start=${this.filterStart}&end=${this.filterEnd}`
    this.formCertificatesService.getAnswersSummary(queryFilters).subscribe(
      (result: any) => {
        this.answers = result.body;
        this.answers.map((answer: any) => {
          answer.date  = moment(new Date(answer.eventDate)).utc().format('DD/MM/YYYY');
          answer.createdAt = moment(new Date(answer.createdAt)).utc().format('DD/MM/YYYY');
          answer.average = this.calcAvgAnswers(answer.qtdParticipantes, answer.respostas);
          return answer;
        })
        this.filteredAnswers = this.answers;
        this.calcGeneralAvg(this.filteredAnswers);
        this.loading = false;
      },
      (data: any) => {
        this.toast.error('Ops! Ocorreu um erro ao recuperar as respostas.');
        this.loading = false;
      }
    );
  }

  async getSpeakersRanking(): Promise<void> {
    this.loadingRanking = true;

    this.formCertificatesService.getSpeakersRanking(this.filterStart, this.filterEnd).subscribe(
      (result: any) => {
        this.ranking = result.body;
        this.filteredRanking = this.ranking;
        this.loadingRanking = false;
      },
      (data: any) => {
        this.loadingRanking = false;
        this.toast.error('Não foi possível recuperar o ranking de palestrantes. Tente novamente mais tarde!');
      }
    )
  }

  calcPercentageRespostas(respostas: string, total: string, filter: string): number {
    if (!total || !respostas) {
      return 0;
    }

    const arr = respostas.split('$$$');
    const totalRespostas = arr.filter((item: string) => item === filter);

    const percentage = (totalRespostas.length / Number(total)) * 100;


    return Math.round(percentage * 100) / 100;
  }

  filterByDate(): void {
    this.getCertificatesAnswers();
  }

  filterRankingByDate(): void {
    this.getSpeakersRanking();
  }

  resetFilter(): void {
    this.filterEnd = null;
    this.filterStart = null;
    this.filterName = '';
    this.filterNameSpeaker = '';
    this.filterCS = '';

    this.getCertificatesAnswers();
    this.getSpeakersRanking();
  }

  downloadCSV(): void {
    this.formCertificatesService.getAnswersForCSV(null).subscribe(
      (result: any) => {
        new ngxCsv(result.body, 'Planilha de respostas', this.options);
      }
    );
  }

  downloadLine(line: string): void {
    this.formCertificatesService.getAnswersForCSV(line).subscribe(
      (result: any) => {
        new ngxCsv(result.body, 'Planilha de respostas', this.options);
      }
    );
  }

  deleteForm(id: string) {
    this.formCertificatesService.deleteForm(id).subscribe(
      (result: any) => {
        this.toast.success('Formulário excluído com sucesso!');
        this.getCertificatesAnswers();
      },
      (data: any) => {
        this.toast.error('Algo deu errado ao tentar excluir o formulário, tente navamente mais tarde!');
      }
    )
  }

  gerarRelatorio(id: string, pdf: string) {
    this.loading = true;
    this.formCertificatesService.generateReport(id).subscribe(
      (result: any) => {
        window.open(String(result.body));
        this.loading = false;

      },
      ( data: any) => {
        this.loading = false;
        this.toast.error('Algo deu errado ao tentar gerar o relatório, tente novamente mais tarde.');
      }
    );
  }

  copyEventLink(hash: string) {
    this.clip.copy(`https://cursos.theroks.com.br/responder-formulario/${hash}`);
    this.toast.success('Link copiado para a área de transferência!');
  }

  downloadQRCode(id: string) {
    this.formCertificatesService.getQRCodeForDownload(id).subscribe(
      (result: any) => {
        console.log(result);
        setTimeout(() => {
          window.open(String(result.body.qrcode));
        }, 1000);
      },
      (data: any) => {
        this.toast.error('Não foi possível fazer o download do QRCode, tente novamente mais tarde!');
      }
    )
  }

  filterByName() {
    if (!this.filterName || this.filterName.length === 0) {
      this.filteredAnswers = this.answers;
    } else {
      this.filteredAnswers = this.answers.filter((answer: any) => (answer?.businessName ? answer.businessName : '').toLowerCase().includes(this.filterName.toLowerCase()));
    }
  }

  filterBySpeakerName() {
    if (!this.filterNameSpeaker || this.filterNameSpeaker.length === 0) {
      this.filteredAnswers = this.answers;
    } else {
      this.filteredAnswers = this.answers.filter((answer: any) => (answer?.speakerName ? answer.speakerName : '').toLowerCase().includes(this.filterNameSpeaker.toLowerCase()));
    }
  }

  filterByCS() {
    if(!this.filterCS || this.filterCS.length === 0) {
      this.filteredAnswers = this.answers;
    } else {
      this.filteredAnswers = this.answers.filter((answer: any) => (answer?.emailCS ? answer.emailCS : '').toLowerCase().includes(this.filterCS.toLowerCase()));
    }
  }

  filterRankingByName() {
    if (!this.filterName || this.filterName.length === 0) {
      this.filteredRanking = this.ranking;
    } else {
      this.filteredRanking = this.ranking.filter((rank: any) => (rank?.nomeCompleto ? rank.nomeCompleto : '').toLowerCase().includes(this.filterName.toLowerCase()));
    }
  }
}
