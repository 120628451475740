import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RankingComponent } from '../ranking.component';
import { TbsbService } from 'src/app/services/tbsb/tbsb.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tbsb-modal-update-ranking',
  templateUrl: './modal-update-ranking.component.html',
  styleUrls: ['./modal-update-ranking.component.scss']
})
export class ModalUpdateRankingComponent implements OnInit {
  name: string = '';
  position: number = 0;
  votes: number = 0;
  position_psa: number = 0;
  votes_psa: number = 0;
  userId: number = 0;

  speaker: any = {};

  constructor(
    public dialogRef: MatDialogRef<RankingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rankingService: TbsbService,
    private toastr: ToastrService,
  ) {
    this.speaker = data.speaker;
    this.speaker.nomeCompleto = data.speaker.nome + ' ' + data.speaker.sobrenome;
    this.votes = data.votes;
    this.position = data.position;
    this.votes_psa = data.votes_psa;
    this.position_psa = data.position_psa;
    this.userId = data.userId;
  }

  ngOnInit(): void {
    console.log(this.data);
  }

  updateRanking() {
    if(!this.speaker?.video_palestrantes[0]?.id) {
      this.toastr.error('Não é possível inserir um ranking para um palestrante sem vídeo.');
      this.cancel();
    }
    const rankData = {
      videoId: this.speaker?.video_palestrantes[0].id,
      speakerId: this.speaker.palestrante.id,
      votes_psa: this.votes_psa,
      position_psa: Number(this.position_psa),
      userId: this.userId,
    }
    console.log(rankData)
    this.rankingService.updateRanking(rankData).subscribe(
      (result: any) => {
        this.cancel();
        this.toastr.success('Ranking atualizado com sucesso!');
      },
      (data: any) => {
        this.cancel();
        this.toastr.error('Não foi possível atualizar o ranking.');
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }
}
