import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IPlan, PlanosService } from 'src/app/services/planos';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CheckoutService } from 'src/app/services/checkout';
import { ModalMessageComponent } from 'src/app/common/modal-message';

@Component({
  selector: 'app-pagamento',
  templateUrl: './pagamento.component.html',
  styleUrls: ['./pagamento.component.scss']
})
export class PagamentoComponent implements OnInit {

  bsModalRef: BsModalRef;
  form: FormGroup;
  submitted: boolean = false;
  loading: boolean = false;
  @Output("cancel") cancel = new EventEmitter();
  @Output("success") success = new EventEmitter();

  @Input() idPlan;
  plan: IPlan


  ip_address: any
  deviceInfo = null;

  message: string;

  user = null;

  constructor(
    private planosService: PlanosService,
    private toast: ToastrService,
    private formBuilder: FormBuilder,
    private checkoutService: CheckoutService,
    private modalService: BsModalService,
    private deviceService: DeviceDetectorService,
  ) {
    this.user = JSON.parse(localStorage.getItem("usuario"))
  }

  ngOnInit(): void {
    this.getPlan()

    this.deviceInfo = this.deviceService.getDeviceInfo()['browser'];
    this.checkoutService.getIpDevice().then(res => { this.ip_address = res.ip })

    this.form = this.formBuilder.group({
      card__number: ['', Validators.required],
      card__name: ['', Validators.required],
      card__month: ['', Validators.required],
      card__year: ['', Validators.required],
      card__ccv: ['', Validators.required],
    });
  }

  async getPlan() {
    try {
      this.plan = await this.planosService.getById(this.idPlan)
    } catch (error) {
      this.toast.error("Erro ao carregar plano")
    }
  }

  get f() { return this.form.controls; }

  getStyle(field: string) {
    const invalidStyle = { 'border': 'solid 1px red' };
    return (this.submitted && this.f[field].invalid) ? invalidStyle : {}
  }

  formatMoney(): string {
    let valor: number = this.plan?.valor / 100
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  async submit() {
    this.submitted = true
    this.message = ""

    this.validations()

    if (this.form.invalid) return false;

    this.loading = true
    try {
      const hasRecurrency = await this.checkoutService.verifyUserRecurrency()

      if (!hasRecurrency) {

        await this.checkoutService.paymentRecurrency({
          id_usuario: this.user.id,
          tipo: "recorrencia",
          forma_pagamento: "cartao",
          id_plano: this.plan.id,
          email: this.user.email,
          ip_address: this.ip_address,
          device_id: this.deviceInfo,
          numero_cartao: this.form.value.card__number,
          nome_cartao: this.form.value.card__name,
          cvv: this.form.value.card__ccv,
          mes: this.form.value.card__month,
          ano: this.form.value.card__year
        })

      } else {
        await this.paymentRecurrencyAndUpdatePlan()
      }

      this.toast.info("Pagamento concluída com sucesso!")
    } catch (e) {
      const err = e.error.message ? e.error.message : e
      this.loading = false
      this.toast.error("Não foi possível concluir a assinatura")
      this.returnError(err)
      return
    }


    try {
      await this.assignPaidPlan()
      this.showMessage("Assinatura realizada com sucesso!")
      this.toast.success("Pagamento concluída")
      this.success.emit()
      this.loading = false
    } catch (error) {
      this.loading = false
      this.message = "Não foi possível assinar o plano. tente novamente ou entre em contato conosco (contato@profissionaissa.com).";
      this.toast.error("Não foi possível assinar o plano. tente novamente ou entre em contato conosco (contato@profissionaissa.com).")
      return
    }
  }

  validations() {
    if (this.form.value.card__number.length != 16) {
      this.message = "<span>Verifique o número do cartão</span>"
      this.form.controls.card__number.setErrors({ validaForm: true });
    }
    if (this.form.value.card__ccv.length != 3) {
      this.message = "<span>Verifique o código de segurança</span>"
      this.form.controls.card__ccv.setErrors({ validaForm: true });
    }
    let now = new Date()
    if (this.form.value.card__year < now.getFullYear()) {
      this.message = "<span>Ano inválido</span>"
      this.form.controls.card__year.setErrors({ validaForm: true });
    }
    if (this.form.value.card__year.length != 4) {
      this.message = "<span>Verifique o ano</span>"
      this.form.controls.card__year.setErrors({ validaForm: true });
    }
    if (this.form.value.card__month < 1 && this.form.value.card__month > 12) {
      this.message = "<span>Mês inválido</span>"
      this.form.controls.card__month.setErrors({ validaForm: true });
    }
  }

  async assignPaidPlan() {
    try {
      await this.planosService.assignPlan({
        id_plano: this.plan.id,
        id_usuario: this.user.id
      });
    } catch (error) {
      throw error
    }
  }


  cancelForm() {
    this.cancel.emit({})
  }


  showMessage(message: string) {
    const initialState = {
      message: message,
      submensagem: ''
    };
    const config = { class: 'modal-login', initialState }
    this.modalService.show(ModalMessageComponent, config);
  }

  returnError(err: any) {
    const res = err.result.retorno ? err.result.retorno : ''

    if (res.details && res.details[0] && res.details[0].description.includes(".afpd")) {
      this.message = `<p>Transação não efetuada.</p><p><small><b>Motivo :</b> Transação negada pela GetNet - Antifraude.</small></p>`;
    } else if (res.details && res.details[0]) {
      this.message = `<p>Transação não efetuada.</p><p><small><b>Motivo :</b> ${res.details[0].description} ${res.details[0].description_detail}</small></p>`;
    } else if (res.status_code == 400 || res.status_code == 401 || res.status_code == 402) {
      this.message = '<p>Transação não efetuada.</p><p><small><b>Motivo :</b> ' + res.message + '</small></p>';
    } else {
      this.message = 'Não foi possível finalizar assinatura. Verifique seus dados e tente novamente.';
    }
    return
  }


  async paymentRecurrencyAndUpdatePlan() {
    try {
      this.loading = true
      await this.checkoutService.paymentRecurrencyAndUpdatePlan({
        "id_usuario": this.user.id,
        "tipo": "recorrencia",
        "forma_pagamento": "cartao",
        "id_plano": this.plan.id,
        "email": this.user.email,
        "ip_address": this.ip_address,
        "device_id": this.deviceInfo,
        "numero_cartao": this.form.value.card__number,
        "nome_cartao": this.form.value.card__name,
        "cvv": this.form.value.card__ccv,
        "mes": this.form.value.card__month,
        "ano": this.form.value.card__year
      })
    } catch (e) {
      const err = e.error.message ? e.error.message : e
      this.loading = false
      this.toast.error("Não foi possível concluir a assinatura.")
      this.returnError(err)
      throw e
    }

  }
}
