import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-tbsb',
  templateUrl: './tbsb.component.html',
  styleUrls: ['./tbsb.component.scss']
})
export class TbsbComponent implements OnInit {
  currentTab: any;

  constructor() { }

  ngOnInit(): void {
    // Initialization logic here
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentTab = tabChangeEvent.index;
  }
}
