//TO-DO: IMPLMENETAR PAGINAÇÃO E FILTROS
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TbsbService } from 'src/app/services/tbsb/tbsb.service';
import { ModalNewNoticeComponent } from './modal-new-notice/modal-new-notice.component';
import { ModalEditNoticeComponent } from './modal-edit-notice/modal-edit-notice.component';

@Component({
  selector: 'tbsb-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss', '../tbsb.component.scss']
})
export class NoticesComponent implements OnInit {
  notices: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  newNotice: any = {};

  buttonLabel: string = 'Criar Aviso';

  loading: boolean = false;
  errorMessage: string = 'Teste erro';

  constructor(
    private noticesService: TbsbService,
    private toast: ToastrService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getNotices();
    this.newNotice = new FormGroup({
      title: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(255)
      ]),
      description: new FormControl(''),
    })
  }

  getNotices() {
    this.loading = true;
    this.noticesService.getNotices().subscribe(
      (result: any) => {
        for(let notice of result) {
          const date = new Date(notice.createdAt)
          notice.date = date.toLocaleDateString('pt-BR');
          notice.hour = date.toLocaleTimeString('pt-BR');
        }

        this.notices = result;
        this.loading = false;
      },
      (data: any) => {
        this.toast.error('Ocorreu um erro ao carregar os avisos.')
        this.loading = false;
      }
    );
  }

  createNotice() {
    this.noticesService.createNotice(this.newNotice).subscribe(
      (res: any) => {
        this.newNotice = {};
        this.toast.success('Aviso criado com sucesso.');
        this.getNotices();
      },
      (data: any) => {
        this.toast.error('Ocorreu um erro ao criar o aviso.');
      }
    );
  }

  editNotice() {
    this.noticesService.editNotice(this.newNotice, this.newNotice.id).subscribe(
      (res: any) => {
        this.getNotices();
        this.newNotice = {};
        this.toast.success('Aviso editado com sucesso.');
      },
      (data: any) => {
        this.toast.error('Ocorreu um erro ao editar o aviso.');
      }
    );
  }

  openModalNew() {
    const MatDialogRef = this.matDialog.open(ModalNewNoticeComponent, {
      width: '500px',
      data: {
      }
    });

    MatDialogRef.afterClosed().subscribe((result: any) => {
      console.log('closed');
      this.getNotices();
    })
  }
  openModalEdit(toEdit: any) {
    const MatDialogRef = this.matDialog.open(ModalEditNoticeComponent, {
      width: '500px',
      data: {
        ...toEdit
      }
    });

    MatDialogRef.afterClosed().subscribe((result: any) => {
      console.log('closed');
      this.getNotices();
    })
  }
}
