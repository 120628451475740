<div class="d-flex">
    <div class="container__search col-12 col-lg-6 d-flex">
        <img src="assets/icons/search-black.svg" alt="ícone pesquisar">
        <input class="container__search__input form-group m-0 border-0 bg-transparent" placeholder="Buscar..."
            type="text" name="search" [(ngModel)]="search" (ngModelChange)="buscar()">
    </div>

    <div class="col-12 col-lg-6 pr-0 d-flex flex-column flex-md-row justify-content-end align-items-center">
        <div class="container__select__filter__order bg-transparent d-flex align-items-center">
            <label class="m-0 mr-2" for="order">Ordenar: </label>
            <select class="select__filter__order" [(ngModel)]="order" name="order" (ngModelChange)="changeOrder()">
                <option *ngFor="let i of orders" [value]="i.value">{{i.name}}</option>
            </select>
        </div>
    </div>
</div>

<div class="list mt-3">
    <ul class="list-group">
        <li (click)="selectLider(item)" *ngFor="let item of lideres" class="content__lider list-group-item d-flex">
            <div style="flex: 1;">{{item.nome_completo}}</div>
            <div style="flex: 1;">{{item.email}}</div>
        </li>
    </ul>
</div>


<p *ngIf="lideres?.length == 0">Nenhum líder cadastrado.</p>

<div class="d-flex justify-content-between align-items-center">
    <nav *ngIf="qtdePages?.length > 1" aria-label="Page navigation example">
        <ul class="pagination">
            <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                <a class="page-link">Anterior</a>
            </li>
            <li (click)="changePage(i)" class="page-item" *ngFor="let number of qtdePages, let i = index">
                <a class="page-link">{{i+1}}</a>
            </li>
            <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                <a class="page-link">Próxima</a>
            </li>
        </ul>
    </nav>
</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>