import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LiveEspectadorService } from 'src/app/services/live-espectador';

@Component({
  selector: 'app-termometro',
  templateUrl: './termometro.component.html',
  styleUrls: ['../live-espectador/live-espectador.component.scss', './termometro.component.scss']
})
export class TermometroComponent implements OnInit {

  @Output("finished") finished = new EventEmitter()
  @Input() idPalestra: number;

  constructor(
    private toast: ToastrService,
    private liveEspectadorService: LiveEspectadorService) { }

  ngOnInit(): void {
  }


  async send(value: number) {
    try {
      this.liveEspectadorService.saveRating(this.idPalestra, value)
      this.finished.emit()
    } catch (error) {
      this.toast.error("Erro ao enviar")
    }
  }

  cancel() {
    this.finished.emit()
  }
}
