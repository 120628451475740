import { Component, Input, OnInit } from '@angular/core';
import { ISkill } from 'src/app/services/tarefas';

@Component({
  selector: 'app-card-minha-trilha',
  templateUrl: './card-minha-trilha.component.html',
})
export class CardMinhaTrilhaComponent implements OnInit {
  @Input() skill: ISkill
  @Input() concluded: boolean = false
  @Input() loading: boolean = false
  @Input() outerClasses: string = ""
  @Input() textButton: string = "Iniciar"

  constructor() { }

  ngOnInit(): void {
  }
}
