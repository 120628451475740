<div class="content__default p-4 pm-md-5">
    <form class="row">
        <div class="col-12 d-flex justify-content-between">
            <h5 class="font-title text-uppercase">{{id > 0 ? 'Editar formação' : 'Adicionar formação'}}</h5>
            <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close"
                title="Cancelar">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="form-group col-12 col-md-6">
            <label class="label-input" for="titulo">Título *</label>
            <input id="titulo" [class.invalid]="isIncorrect('titulo')" class="form-control" name="titulo" type="text"
                [(ngModel)]="formacao.titulo" placeholder="Título *" maxlength=100>
            <!-- [ngModelOptions]="{standalone: true}" -->
            <span class="error">{{getError('titulo')}}</span>
        </div>

        <div class="form-group col-12 col-md-6">
            <label class="label-input" for="estado">estado *</label>
            <select id="estado" [class.invalid]="isIncorrect('estado')" class="form-control" name="estado"
                placeholder="Estado *" [(ngModel)]="formacao.estado">
                <option value="">Selecione *</option>
                <option *ngFor="let estado of arrayEstados" [value]="estado.uf">{{estado.estado}}
                </option>
            </select>
            <span class="error">{{getError('estado')}}</span>
        </div>
        <div class="form-group col-12 col-md-6">
            <label class="label-input" for="cidade">cidade *</label>
            <input id="cidade" [class.invalid]="isIncorrect('cidade')" class="form-control" name="cidade" type="text"
                [(ngModel)]="formacao.cidade" placeholder="Cidade *" maxlength=80>
            <span class="error">{{getError('cidade')}}</span>
        </div>
        <div class="form-group col-12">
            <label class="label-input" for="local">local *</label>
            <input id="local" [class.invalid]="isIncorrect('local')" class="form-control" name="local" type="text"
                [(ngModel)]="formacao.local" placeholder="Local *" maxlength=150>
            <span class="error">{{getError('local')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
            <label class="label-input" for="data_inicio">Início *</label>
            <input id="data_inicio" [class.invalid]="isIncorrect('data_inicio')" class="form-control" name="data_inicio"
                type="date" [(ngModel)]="formacao.data_inicio" placeholder="Data de início *">
            <span class="error">{{getError('data_inicio')}}</span>
        </div>

        <div class="form-group col-12 col-md-4 d-flex align-items-end">
            <div>
                <label class="container__checkbox">
                    Cursando
                    <input type="checkbox" name="cursando" [(ngModel)]="formacao.cursando">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>

        <div [class.d-none]="formacao.cursando" class="form-group col-12 col-md-4">
            <label class="label-input" for="data_fim">Fim *</label>
            <input id="data_fim" [class.invalid]="isIncorrect('data_fim')" class="form-control" name="data_fim"
                type="date" [(ngModel)]="formacao.data_fim" placeholder="Data final *">
            <span class="error">{{getError('data_fim')}}</span>
        </div>

        <div class="col-12">
            <div class="d-flex pt-2 pb-2 div__btn__salvar">

                <button (click)="cancel()" type="button" class="btn btn-primary btn-secondary mr-3"
                    title="Cancelar">CANCELAR</button>

                <button *ngIf="!loading" (click)="submit()" class="btn btn-primary btn-warning">Salvar</button>

                <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
                    <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
                </button>
            </div>
        </div>
    </form>
</div>