<!-- <div class="content"> -->
  <section class="card">
    <h1 *ngIf="!isAdmin" class="font-title">
      Cadastre-se como um especialista na Profissionais S.A
    </h1>
    <h5 *ngIf="isAdmin" style="text-align: left;">
      Cadastrar palestrante
    </h5>
    <div class="form">
      <div class="fields">
        <input
          class="mr-1"
          [(ngModel)]="user.firstName"
          type="text"
          placeholder="Primeiro Nome"
        />
        <input
          class="ml-1"
          [(ngModel)]="user.lastName"
          type="text"
          placeholder="Sobrenome"
        />
      </div>

      <div class="fields">
        <input
          class="custom-input"
          maxlength="15"
          [ngModel]="user.phoneNumber"
          (ngModelChange)="formatPhone($event)"
          type="text"
          placeholder="Telefone"
        />
      </div>
      <div *ngIf="!isAdmin" class="fields">
        <input
          class="custom-input"
          maxlength="14"
          [ngModel]="user.cpf"
          (ngModelChange)="formatCpf($event)"
          type="text"
          placeholder="CPF"
        />
      </div>

      <div class="fields">
        <input
          class="mr-1"
          [(ngModel)]="user.city"
          type="text"
          placeholder="Cidade"
        />

        <input
          class="ml-1"
          [(ngModel)]="user.state"
          type="text"
          placeholder="Estado"
        />
      </div>

      <div class="fields">
        <select [(ngModel)]="user.type">
          <option [value]="'fisica'">Pessoa Física</option>
          <option [value]="'juridica'">Pessoa Jurídica</option>
        </select>
      </div>

      <div class="fields">
        <select [(ngModel)]="user.tipo">
          <option [value]="'PALESTRANTE'">Palestrante</option>
          <option [value]="'TBSB'">Palestrante - TBSB</option>
        </select>
      </div>

      <div *ngIf="user.type === 'juridica'">
        <div class="fields">
          <input
            class="mr-1"
            [(ngModel)]="user.socialName"
            type="text"
            placeholder="Razão social"
          />

          <input
            *ngIf="!isAdmin"
            class="ml-1"
            [(ngModel)]="user.cnpj"
            (ngModelChange)="formatCnpj($event)"
            type="text"
            maxlength="18"
            placeholder="CNPJ"
          />
        </div>
      </div>

      <div class="fields">
        <input
          class="custom-input"
          [(ngModel)]="user.email"
          type="email"
          placeholder="Email"
        />
      </div>
      <div class="fields">
        <input
          *ngIf="!isAdmin"
          class="custom-input"
          [(ngModel)]="user.password"
          (ngModelChange)="validatePassword($event)"
          type="password"
          placeholder="Senha"
        />
      </div>
      <div class="fields">
        <input
          *ngIf="!isAdmin"
          class="custom-input"
          [(ngModel)]="confirmPass"
          (ngModelChange)="validatePassword($event)"
          type="password"
          placeholder="Confirmar Senha"
        />
      </div>
      <p *ngIf="isAdmin" style="text-align: left;">
        A senha padrão para este usuário: <b>PSA123</b>
      </p>
      <p class="errorMessage" *ngIf="errorMessage">{{ errorMessage }}</p>
      <button class="btn__primary" type="button" (click)="handleSubmit()" [disabled]="loading">
        Cadastrar
      </button>
      <button
        *ngIf="!isAdmin"
        class="btn__secondary"
        type="button"
        (click)="goBack()"
      >
        Voltar
      </button>
    </div>
  </section>

<!-- </div> -->
