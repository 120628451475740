import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoService } from '../../../services/grupo';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalAdicionarLideresComponent } from '../modal-adicionar-lideres';
import { ModalFormColaboradorComponent } from '../modal-form-colaborador';
import { ModalChangeLiderComponent } from '../modal-change-lider';
import { IColaborador } from '../colaboradores';
import { ModalAdicionarColaboradoresComponent } from '../modal-adicionar-colaboradores';


export interface ILider {
  id: number,
  nome: string,
  email: string,
  id_cargo: number,
  cargo?: string,
  id_grupo: number,
  grupo?: string,
  id_usuario_lider?: number
  status?: string
  habilidades?: Array<string>
}

export interface IGrupo {
  id: number,
  nome: string,
  descricao: string,
  ativo: string,
  id_contratante: number,
  tipo: "DEFAULT" | "PDI"
  perguntas_descritivas: boolean
  libera_personalizar: boolean
  gera_certificado: boolean
  modelo_certificado: string
  lideres?: Array<ILider>
}

export interface IGrupoLideres {
  id: number,
  id_colaborador: number,
  nome: string,
  descricao: string,
  ativo: string,
  id_contratante: number,
  id_lider: number,
  id_cargo_lider: number,
  id_espectador_lider?: number,
  id_usuario_lider: number,
  nome_lider: string
  email_lider: string
  isLider: boolean
  grupos: string
}


export interface ICargo {
  id: number,
  nome: string,
  descricao: string,
  ativo: string,
  pontos?: number
}


@Component({
  selector: 'app-lideres',
  templateUrl: './grupo-lideres.component.html',
  styleUrls: ['./grupo-lideres.component.scss']
})
export class GrupoLideresComponent implements OnInit {
  bsModalRef: BsModalRef;

  grupo: IGrupo = {
    id: null,
    nome: null,
    descricao: null,
    ativo: null,
    id_contratante: null,
    perguntas_descritivas: true,
    libera_personalizar: true,
    gera_certificado: false,
    modelo_certificado: 'MODELO1',
    tipo: 'DEFAULT'
  };

  lideres: Array<IGrupoLideres>;
  colaboradores: Array<IGrupoLideres>;
  resultGrupo: any;
  grupo_id: number;

  loading: boolean = false;
  qtdePages: Array<number> = [];
  page: number = 0;
  search: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private grupoService: GrupoService,
    private toast: ToastrService,
    private modalService: BsModalService,) { }

  ngOnInit(): void {
    this.grupo_id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.getGrupo();
  }

  async getGrupo() {
    if (!(this.grupo_id > 0)) {

      this.grupo = {
        id: 0,
        nome: "",
        descricao: "",
        ativo: "SIM",
        id_contratante: null,
        perguntas_descritivas: true,
        libera_personalizar: true,
        gera_certificado: false,
        modelo_certificado: 'MODELO1',
        tipo: "DEFAULT"
      }
      this.lideres = [];
    } else {
      const res = await this.grupoService.getGrupoLideres(this.grupo_id, this.page, this.search)
        .catch((error) => {
          this.toast.error("Não foi possível efetuar buscar os tutores.")
          return;
        });

      this.setQtdePages(res.quantidade)
      this.grupo = res.grupo;
      this.lideres = res.lideres ? res.lideres : [];

      const resColaboradores = await this.grupoService.getGrupoColaboradores(this.grupo_id, this.page, this.search)
        .catch((error) => {
          return;
        });
      this.colaboradores = resColaboradores.colaboradores ? resColaboradores.colaboradores : [];
    }
  }

  editar(lider: ILider) {
    const initialState = { colaborador: lider };
    const config: any = { initialState }
    this.bsModalRef = this.modalService.show(ModalFormColaboradorComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.success) this.getGrupo()
    })
  }

  async excluir(lider: IGrupoLideres) {
    this.loading = true
    const res = await this.grupoService.liderHasLiderado(lider.id_usuario_lider)
    if (res.hasLiderado == true) {
      this.loading = false
      const initialState = { lider_atual: lider, id_grupo: this.grupo.id };
      const config = { class: "modal-play", initialState }
      this.bsModalRef = this.modalService.show(ModalChangeLiderComponent, config);

      let newSubscriber = this.modalService.onHide.subscribe(() => {
        newSubscriber.unsubscribe();
        if (this.bsModalRef.content.success) this.getGrupo()
      })

    } else {
      try {
        const res = await this.grupoService.updateTutorColaboradoresCollection(this.grupo.id, lider, null)
        this.toast.success("Tutor excluído do grupo.")
        this.getGrupo()

        this.loading = false
      } catch (error) {
        this.loading = false
        this.toast.error("Não foi possível trocar os tutores.")
      }
    }
  }

  async salvar() {
    try {
      if (!(this.grupo.nome.length > 0)) {
        this.toast.error("Campo NOME deve ser preenchido!");
        return false;
      }
      if (!(this.grupo.descricao.length > 0)) {
        this.toast.error("Campo DESCRIÇÃO deve ser preenchido!");
        return false;
      }

      if (this.grupo_id == 0) {

        const resultInsert = await this.grupoService.insertGrupo(this.grupo);
        this.grupo_id = resultInsert.insertId;
        await this.getGrupo();
        this.router.navigate(['/universidade/' + resultInsert.insertId + '/lideres'])
        this.avancar();

        return true;
      } else {
        this.resultGrupo = await this.grupoService.updateGrupo(this.grupo);
        this.avancar();

        if (this.grupo.ativo != 'SIM') {
          this.toast.success("Grupo inativado com sucesso!");
          this.router.navigate([`/universidade/${this.grupo.id}/colaboradores`]);
        }
      }

    } catch (e) {
      console.log('error', e);
      this.toast.error("Erro ao salvar Grupo!");
      return false;
    }
  }

  avancar() {
    try {
      if (this.lideres.length == 0) {
        this.toast.error("Grupo deve possuir pelo menos 1 (um) tutor!");
        return;
      }

      if (this.colaboradores.length == 0) {
        this.toast.error("Adicione um colaborador.", "Grupo deve conter pelo menos um colaborador!")
        return
      }

      this.router.navigate(['/universidade/', this.grupo_id, 'habilidades']);
    } catch (error) {
      this.loading = false
    }

    this.router.navigate(['/universidade/', this.grupo_id, 'habilidades']);
  }

  async addLideres() {
    var isOk = true;
    if (this.grupo_id == 0) {
      isOk = await this.salvar()
    }
    if (isOk == false) return
    const initialState = { id_grupo: this.grupo.id };
    const config = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(ModalAdicionarLideresComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.success) this.getGrupo()
    })
  }

  changePage(number: number) {
    this.page = number;
    this.getGrupo()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array.from(Array(qtdeColaboradores).keys())
  }

  getPages() {
    let listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2))
    return listPage
  }

  buscar() {
    this.page = 0
    this.getGrupo()
  }

  addColaboradores(lider: IColaborador) {
    const initialState = { id_grupo: this.grupo_id, lider: lider };
    const config: any = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(ModalAdicionarColaboradoresComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      this.getGrupo()
    })
  }
}
