<section class="container">
  <div *ngIf="loading" class="button-bottom" style="text-align: center">
    <app-spinner style="
        display: block;
        margin-left: auto;
        margin-top: 40px;
        margin-right: auto;
        width: 40%;
      " name="crescent"></app-spinner>
    <span>Carregando informações...</span>
  </div>

  <div class="row">
    <div class="col-10 offset-1" *ngIf="!loading">
      <div>

        <div class="grid-container">

        </div>
        <div style="text-align: center;margin-top: 10px;font-weight: 600;">
          <span>{{pergunta?.ordem}}/{{perguntas?.length}}</span>
        </div>
      </div>

      <div *ngIf="pergunta?.tipo != 'DESCRITIVA'" class="pergunta-box">
        <span style="white-space: pre-line;" class="pergunta-text">{{pergunta.pergunta}}</span>
      </div>
      <div *ngIf="pergunta?.tipo == 'DESCRITIVA'" class="pergunta-box">
        <span style="white-space: pre-line; font-weight: 500;" class="pergunta-text">{{pergunta.pergunta}}</span>
      </div>
      <div *ngIf="pergunta?.tipo === 'OBJETIVA'">
        <div *ngIf="!isClick" class="perguntas-label">
          <label>Selecione uma alternativa</label>
        </div>
        <div *ngIf="isClick && !isRight" class="wrong-answer-label">
          <label style="margin-left: 0" class="wrong-answer-label">Errou! A resposta correta é a alternativa
            {{correctOption?.ordem}}</label>
        </div>

        <div *ngIf="isClick && isRight" class="right-answer-label">
          <label style="margin-left: 0" class="right-answer-label">Acertou! A resposta correta é a alternativa
            {{correctOption?.ordem}}</label>
        </div>

        <article *ngFor="let opcao of pergunta.opcoes" (click)="checkResposta(opcao)"
          [class.disabled]="disableButton && opcao.ordem != optionChoosed "
          [ngStyle]="{opacity:disableButton && opcao.ordem != optionChoosed ?'0.7': '1'}" class="perguntas-container"
          lines="none">
          <div>
            <div class="pergunta-container">
              <div class="index-container">
                <div
                  [ngClass]="isCliked() && ( opcao.peso == 100 && (opcao.ordem == optionChoosed || opcao.ordem != optionChoosed))
                          ? 'right-box-index' : ( opcao.peso != 100 && (opcao.ordem == optionChoosed)) ?  'wrong-box-index': 'option-index-box'">
                  <p class="option-index-text">{{opcao.ordem}}</p>
                </div>
              </div>
              <div [ngClass]="isCliked() && ( opcao.peso == 100 && (opcao.ordem == optionChoosed)) ? 'right-answer'
              :  ( opcao.peso != 100 && (opcao.ordem == optionChoosed)) ? 'wrong-answer': 'no-clicked'"
                id="resposta{{opcao.ordem}}" style="margin-top: 6px; margin-left: 2px; padding-left: 10px; padding-right: 10px;padding-top: 5px;padding-bottom: 5px ; width: 100%;">
                <div [ngStyle]="{backgroundColor:  isClick &&  ( opcao.peso != 100 && (opcao.ordem == optionChoosed))?'#B51B1B'
              : isClick &&  ( opcao.peso == 100 && (opcao.ordem == optionChoosed)) ? '#008037' : '' }" class="optspan">
                  <div style="width: 90%">
                    <p style="margin: 0; font-size: 14px">
                      {{opcao.descricao_opcao}}
                    </p>
                  </div>
                </div>
                <div *ngIf="isClick &&  ( opcao.peso == 0 && (opcao.ordem == optionChoosed)) " style="display: flex">
                  <div style="padding: 0px 5px 0px 5px; background-color: white">
                    <img style="font-size: 25px; color: #b51b1b" src="../../../assets/icons/thumb-down.svg">
                  </div>
                  <div style="background-color: white; font-size: 14px">
                    <div>
                      <span style="
                        text-transform: uppercase;
                        color: #b51b1b;
                        margin: 0;
                        margin-left: 10px;
                      ">
                        Oh, não! Você errou
                      </span>
                    </div>
                    <div style="margin-bottom: 10px">
                      <p style="
                        color: #808080;
                        margin: 0;
                        margin-left: 10px;
                        font-size: 14px;
                        padding-right: 10px;
                      ">
                        {{opcao.explicacao}}
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="isClick &&  ( opcao.peso == 100 && (opcao.ordem == optionChoosed)) " style="display: flex">
                  <div style="padding: 0px 5px 0px 5px; background-color: white">
                    <img style="font-size: 25px; color: #008037" src="../../../assets/icons/like.svg">
                  </div>
                  <div style="background-color: white; font-size: 14px">
                    <div>
                      <span style="
                        text-transform: uppercase;
                        color: #008037;
                        margin: 0;
                        margin-left: 10px;
                      ">
                        Parabéns! Você acertou.
                      </span>
                    </div>
                    <div style="margin-bottom: 10px">
                      <p style="
                        color: #808080;
                        margin: 0;
                        margin-left: 10px;
                        font-size: 14px;
                        padding-right: 10px;
                      ">
                        {{opcao.explicacao}}
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="isClick &&  ( opcao.peso == 100 && (opcao.ordem != optionChoosed)) " style="display: flex">
                  <div style="padding: 0px 5px 0px 5px; background-color: white">
                    <img style="font-size: 25px; color: #008037" src="../../../assets/icons/checked_circle.svg">
                  </div>
                  <div style="background-color: white; font-size: 14px">
                    <div>
                      <span style="
                        text-transform: uppercase;
                        color: #808080;
                        margin: 0;
                        margin-left: 10px;
                      ">
                        ESTA É A RESPOSTA CORRETA
                      </span>
                    </div>
                    <div style="margin-bottom: 10px">
                      <p style="
                        color: #808080;
                        margin: 0;
                        margin-left: 10px;
                        font-size: 14px;
                        padding-right: 10px;
                      ">
                        {{opcao.explicacao}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div *ngIf="pergunta?.tipo === 'RELATIVA'">
        <div *ngIf="!isClick" class="perguntas-label">
          <label>Selecione uma alternativa</label>
        </div>
        <div *ngIf="isClick && !isRight" class="wrong-answer-label">
          <label style="margin-left: 0" class="wrong-answer-label">Quase acertou! A melhor alternativa é a
            {{correctOption?.ordem}}</label>
        </div>
        <div *ngIf="isClick && isRight" class="right-answer-label">
          <label style="margin-left: 0" class="right-answer-label">Acertou! A melhor alternativa é a
            {{correctOption?.ordem}}</label>
        </div>

        <article *ngFor="let opcao of pergunta.opcoes" (click)="checkResposta(opcao)"
          [class.disabled]="disableButton && opcao.ordem != optionChoosed "
          [ngStyle]="{opacity:disableButton && opcao.ordem != optionChoosed ?'0.7': '1'}" class="perguntas-container">
          <div>
            <div class="pergunta-container">
              <div class="index-container">
                <div
                  [ngClass]="isCliked() && ( opcao.peso == 100 && (opcao.ordem == optionChoosed))
                          ? 'right-box-index' : ( opcao.peso != 100 && (opcao.ordem == optionChoosed)) ?  'wrong-box-index-relative': 'option-index-box'">
                  <p class="option-index-text">{{opcao.ordem}}</p>
                </div>
              </div>
              <div [ngClass]="isCliked() && ( opcao.peso == 100 && (opcao.ordem == optionChoosed)) ? 'right-answer'
              :  ( opcao.peso != 100 && (opcao.ordem == optionChoosed)) ? 'wrong-answer': 'no-clicked'"
                id="resposta{{opcao.ordem}}" style="margin-top: 6px; margin-left: 2px">
                <div [ngClass]="isClick &&  ( opcao.peso != 100 && (opcao.ordem == optionChoosed)) ? 'no-clicked'
              : isClick &&  ( opcao.peso == 100 && (opcao.ordem == optionChoosed)) ? 'no-clicked-right' : '' "
                  class="optspan">
                  <div style="width: 90%">
                    <p style="margin: 0; font-size: 14px">
                      {{opcao.descricao_opcao}}
                    </p>
                  </div>
                </div>
                <div *ngIf="isClick &&  ( opcao.peso < 100 && (opcao.ordem == optionChoosed)) " style="display: flex">
                  <div style="padding: 0px 5px 0px 5px; background-color: white"></div>
                  <div style="background-color: white; font-size: 14px">
                    <div>
                      <span style="
                        text-transform: uppercase;
                        color: #39cd7a;
                        margin: 0;
                        margin-left: 10px;
                      ">
                        Esta é uma boa alternativa mas não é a melhor
                      </span>
                    </div>
                    <div style="margin-bottom: 10px">
                      <p style="
                        color: #808080;
                        margin: 0;
                        margin-left: 10px;
                        font-size: 14px;
                        padding-right: 10px;
                      ">
                        {{opcao.explicacao}}
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="isClick &&  ( opcao.peso == 100 && (opcao.ordem == optionChoosed)) " style="display: flex">
                  <div style="padding: 0px 5px 0px 5px; background-color: white">
                    <img style="font-size: 25px; color: #008037" src="../../../assets/icons/like.svg">
                  </div>
                  <div style="background-color: white; font-size: 14px">
                    <div>
                      <span style="
                        text-transform: uppercase;
                        color: #008037;
                        margin: 0;
                        margin-left: 10px;
                      ">
                        Ótimo! Você escolheu a melhor alternativa
                      </span>
                    </div>
                    <div style="margin-bottom: 10px">
                      <p style="
                        color: #808080;
                        margin: 0;
                        margin-left: 10px;
                        font-size: 14px;
                        padding-right: 10px;
                      ">
                        {{opcao.explicacao}}
                      </p>
                    </div>
                  </div>
                </div>
                <div *ngIf="isClick &&  ( opcao.peso == 100 && (opcao.ordem != optionChoosed)) " style="display: flex">
                  <div style="padding: 0px 5px 0px 5px; background-color: white">
                    <img style="font-size: 25px; color: #008037" src="../../../assets/icons/checked_circle.svg">
                  </div>
                  <div style="background-color: white; font-size: 14px">
                    <div>
                      <span style="
                        text-transform: uppercase;
                        color: #808080;
                        margin: 0;
                        margin-left: 10px;
                      ">
                        ESTA É A MELHOR ALTERNATIVA
                      </span>
                    </div>
                    <div style="margin-bottom: 10px">
                      <p style="
                        color: #808080;
                        margin: 0;
                        margin-left: 10px;
                        font-size: 14px;
                        padding-right: 10px;
                      ">
                        {{opcao.explicacao}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div *ngIf="pergunta?.tipo === 'DESCRITIVA'">
        <div style="margin-top: 20px; margin-left: 8px;margin-right: 10px;">
          <spanarea *ngIf="!pergunta?.resposta?.resposta" [(ngModel)]="resposta" required="true" type="text"
            style="border: 1px solid #808080;" placeholder="Descreva a resposta"></spanarea>
          <div *ngIf="pergunta?.resposta?.resposta" style="border: 1px solid #808080; min-height: 70px;">
            <span>
              {{pergunta.resposta.resposta}}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="mt-3" style="text-align: center;display: flex;justify-content: space-around;">

          <button style="width: 45%;" class="btn__primary" (click)="salvarResposta()">
            Próxima pergunta
          </button>

        </div>

      </div>
    </div>

  </div>
</section>
