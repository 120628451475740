import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

enum EProfile {
  CONTRATANTE = 'CONTRATANTE',
  ESPECTADOR = 'ESPECTADOR',
  PALESTRANTE = 'PALESTRANTE',
  ADMIN = 'ADMIN',
  GESTOR = 'GESTOR',
}

@Component({
  selector: 'app-entry-profile',
  templateUrl: './entry-profile.component.html',
  styleUrls: ['./entry-profile.component.scss'],
})
export class EntryProfileComponent implements OnInit {
  option = EProfile;

  @Input() defaultProfile: string = null;
  @Output() profileSelected = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    if (this.defaultProfile === EProfile.ADMIN) {
      this.select(EProfile.ADMIN);
    }
  }

  select(value: any) {
    this.profileSelected.emit({ profile: value });
  }
}
