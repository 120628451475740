<section class="container">

    <article class="col-12 col-lg-8">
        <header>
            <h5 class="font-title mb-0">Plano: {{plan?.nome}}</h5>
            <h6>Valor: {{formatMoney()}}/mês</h6>
            <h3 class="font-title">Pagamento</h3>
        </header>

        <form [formGroup]="form" (ngSubmit)="submit()">
            <div class="form-group">
                <label class="label-input" for="descricao">NOME NO CARTÃO *</label>
                <input class="form-control input__default" type="text" name="card__name" placeholder="Nome no cartão *"
                    formControlName="card__name" [ngStyle]="getStyle('card__name')" />
            </div>
            <div class="form-group">
                <label class="label-input" for="descricao">NÚMERO DO CARTÃO *</label>
                <input class="form-control input__default" type="text" name="card__number"
                    placeholder="Número do cartão *" formControlName="card__number" [ngStyle]="getStyle('card__number')"
                    mask="0000 0000 0000 0000" />
            </div>
            <div class="d-flex flex-column flex-md-row multi__input">
                <div class="form-group d-flex flex-column mr-2">
                    <label class="label-input" for="descricao">Código *</label>
                    <input class="form-control input__default input__default" type="password" name="card__ccv"
                        placeholder="Código CCV *" formControlName="card__ccv" [ngStyle]="getStyle('card__ccv')"
                        mask="000" />
                </div>
                <div class="form-group d-flex flex-column mr-2">
                    <label class="label-input" for="descricao">MÊS * (Ex: 02)</label>
                    <input class="form-control input__default mr-2" type="text" name="card__month" placeholder="Mês *"
                        formControlName="card__month" [ngStyle]="getStyle('card__month')" mask="00" />
                </div>
                <div class="form-group d-flex flex-column">
                    <label class="label-input" for="descricao">ano (4 díg.) *</label>
                    <input class="form-control input__default" type="text" name="card__year" placeholder="Ano * (Ex: 2027)"
                        formControlName="card__year" [ngStyle]="getStyle('card__year')" mask="0000" />
                </div>
            </div>

            <div *ngIf="message" class="msg__err" [innerHTML]="message"></div>

            <div class="container__buttons d-flex flex-column-reverse flex-md-row flex-wrap mt-2">

                <button *ngIf="!loading" (click)="cancelForm()" type="button"
                    class="btn__secondary mr-0 mr-md-3 mt-1 mt-md-0">Cancelar</button>
                <button *ngIf="!loading" type="submit" class="btn__primary">Assinar</button>
                
                <button style="border: solid 2px var(--yellow); padding: 5px;" *ngIf="loading" type="button" class="btn__primary bg-white">
                    <app-spinner></app-spinner>
                </button>

            </div>
        </form>
    </article>
</section>