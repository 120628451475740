<section class="section__cabecalho">
    <div class="container_fluid">
        <div class="content__cabecalho col-12 col-lg-8 offset-lg-2 mt-5 mt-lg-0">
            <p class="content__cabecalho__titlename">calculadora</p>
            <p class="content__cabecalho__title">Calcule em menos de 1 minuto seus treinamentos e palestras ao longo do
                ano</p>
            <p class="content__cabecalho__subtitle">A calculadora ideal para você simular em 3 passos qual será o seu o
                custo médio em palestras, treinamentos, mentorias e consultorias. Seu T&D em só lugar. Simule em poucos
                cliques, planeje o seu orçamento para todo o ano e receba uma planilha completa no seu e-mail!</p>
        </div>
    </div>
</section>

<section>
    <div class="container_fluid py-5">

        <div class="pt-2 pb-3">
            <app-wizard-calculadora [selected]="1"></app-wizard-calculadora>
        </div>

        <form [formGroup]="form" (ngSubmit)="submit()" class="row col-12 col-lg-8 offset-lg-2">
            <div class="col-lg-2"></div>
            <div class="form-group col-12 col-lg-4">
                <label for="uf">Estado</label>
                <select class="form-control" name="uf" formControlName="uf" [ngStyle]="getStyle('classe')">
                    <option value="">Selecione *</option>
                    <option *ngFor="let option of estados" [value]="option.uf">{{option.estado}}</option>
                </select>
            </div>
            <div class="form-group col-12 col-lg-4">
                <label for="classe">porte da cidade</label>
                <select class="form-control" name="classe" formControlName="classe" [ngStyle]="getStyle('classe')">
                    <option value="">Selecione *</option>
                    <option *ngFor="let option of classes" [value]="option.id">{{option.habitante}}</option>
                </select>
            </div>

            <div class="col-12 d-flex justify-content-center mt-5 mb-3">
                <button type="submit" class="btn btn__submit">Avançar</button>
            </div>
        </form>
    </div>
</section>