<div class="container__conteudo col-12 col-lg-12">
    <h4 class="d-inline-block font-title">Notificar contatos
    </h4>
    <div class="close pull-right ml-3" aria-label="Close" (click)="bsModalRef.hide()">
        <span style="cursor: pointer; font-size: 2rem;" aria-hidden="true">&times;</span>
    </div>

    <div class="d-lg-flex justify-content-end align-items-end">
        <div class="d-flex">
            <label class="container__checkbox container__checkbox__abled label-input">Enviar
                para todos?
                <input (ngModelChange)="bsModalRef.hide()" type="checkbox" [(ngModel)]="sendAll">
                <span class="checkmark"></span>
            </label>
        </div>
    </div>

    <div class="content__cabecalho text-left d-flex p-0">
        <label class="flex03"></label>
        <label class="font-title text-uppercase flex2">NOME</label>
        <label class="font-title text-uppercase flex2">E-MAIL</label>
    </div>

    <ul>
        <li [ngClass]="{ added : contato.added}" *ngFor="let contato of contatos">

            <div class="d-flex content__list__item">
                <div class="flex03">
                    <label class="container__checkbox mb-0">
                        <input type="checkbox" (change)="add(contato.id)" [(ngModel)]="contato.added">
                        <span class="checkmark"></span>
                    </label>
                </div>

                <div class="flex2 w-100 item__nome">
                    <input class="input__disable" type="text" [(ngModel)]="contato.nome" disabled="disabled">
                </div>

                <div class="flex2">
                    <input class="input__disable" type="text" [(ngModel)]="contato.email" disabled="disabled">
                </div>


            </div>

        </li>
    </ul>

    <p *ngIf="contatos?.length == 0">Nenhum administrador cadastrado.</p>

    <div class="d-flex flex-row-reverse justify-content-between align-items-end">
        <button (click)="save()" class="btn__primary">SALVAR</button>
    </div>
</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>