import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common';
import { PalestrantesService } from 'src/app/services/palestrantes';

export interface IArtigoExclusivo {
  data: string
  id_conteudo_tipo: number
  image_src: string
  resumo: string
  slug: string
  titulo: string
}

export interface ITotalizadores {
  total_palestras: number
  total_agendadas: number
  total_orcamentos_avaliacao: number
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  contatoPalestrante: string
  artigos: Array<IArtigoExclusivo> = []
  totalizadores: ITotalizadores = {
    total_palestras: 0,
    total_agendadas: 0,
    total_orcamentos_avaliacao: 0
  }

  constructor(
    private palestranteService: PalestrantesService,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.getArtigos()
    this.getCount()
    
    this.commonService.getConfiguracao("telefone__contato__palestrante", "5511956372536")
      .then(res => { this.contatoPalestrante = res.valor })
      .catch(e => { console.log(e); })
  }

  async getArtigos() {
    try {
      const res = await this.palestranteService.getArtigosExclusivos(0)
      this.artigos = res
    } catch (error) {}
  }

  async getCount() {
    try {
      const res = await this.palestranteService.getCountHome()
      this.totalizadores = res
    } catch (error) {}
  }

  whats() {
    let usuario = JSON.parse(localStorage.getItem("usuario"))
    let plano_nome = sessionStorage.getItem("plano_nome")
    let txt = `Olá, sou palestrante ${usuario.nome_completo}. Do ${plano_nome}. Entro em contato por meio do Dashboard para solicitar atendimento.`
    window.open("https://api.whatsapp.com/send?phone=" + this.contatoPalestrante + "&text=" + txt, "_blank")
  }
}
