import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { ModalContaContratanteComponent } from '../../shared/modal-conta-contratante/modal-conta-contratante.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common';
import { RdStationService } from 'src/app/services/rd-station/rd-station.service';
// import { ModalMensagemComponent } from 'src/app/shared/modal-mensagem/modal-mensagem.component';


interface IOrc {
  titulo: string
  formato: number
  horas: string
  publico: number
  especialista: string
  tema: string
  acoes: number
  investimento: string
  duracoes: Array<any>
  value_min?: any
  value_med?: any
  value_max?: any
  espec_min?: any
  espec_med?: any
  espec_max?: any
}

@Component({
  selector: 'app-passo2',
  templateUrl: './passo2.component.html',
  styleUrls: ['./passo2.component.scss']
})
export class Passo2Component implements OnInit {

  bsModalRef: BsModalRef;

  loading: boolean = false;
  hasAccount: boolean = false

  default: IOrc = {
    titulo: '',
    formato: 0,
    horas: '',
    publico: null,
    especialista: '',
    tema: '',
    acoes: 1,
    investimento: '',
    duracoes: []
  }
  orcamentos: Array<IOrc> = []

  editable: number = null
  formatos: Array<any> = []
  temas: Array<any> = []
  especialistas: Array<any> = []
  duracoes: Array<any> = []
  horas: Array<any> = []
  acoes: Array<any> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  info_contratante: any;

  constructor(
    private titleService: Title,
    private commonService: CommonService,
    private toast: ToastrService,
    private router: Router,
    private modalService: BsModalService,
    private rdStationService: RdStationService) { }

  ngOnInit(): void {
    this.titleService.setTitle("Calculadora");

    let calc = JSON.parse(localStorage.getItem('calculadora-orcamento'))
    if (calc) this.orcamentos = calc

    this.info_contratante = window.localStorage.getItem("calculadora-contratante");
    if (!this.info_contratante) this.router.navigate(['/calculadora/passo1'])
    this.info_contratante = JSON.parse(this.info_contratante)

    this.generateCalculadora(false, false).catch(err => console.error(err))


    if (this.orcamentos.length == 0) {
      let size = window.innerWidth > 850 ? 5 : 1
      for (let index = 0; index < size; index++) {
        this.orcamentos.push({ titulo: '', formato: 0, horas: '', publico: null, especialista: '', tema: '', acoes: this.acoes[0], investimento: '', duracoes: [] })
      }
    }

    this.commonService.getInfoCalculadora()
      .then((res: any) => {
        this.formatos = res.formatos
        this.temas = res.temas
        this.especialistas = res.especialistas
      })
  }

  isEditable(index: number): boolean {
    return false
  }

  addLine() {
    this.orcamentos.push({ titulo: '', formato: 0, horas: '', publico: null, especialista: '', tema: '', acoes: this.acoes[0], investimento: '', duracoes: [] })
  }

  getDuracoes(index: number) {
    let id_formato = this.orcamentos[index].formato
    if (id_formato == 0 || !id_formato || id_formato == null) {
      this.orcamentos[index].duracoes = []
      return
    }
    this.orcamentos[index].horas = ''
    this.commonService.getDuracoes(id_formato)
      .then((res: any) => this.orcamentos[index].duracoes = res.duracoes)
  }

  refreshResult(index: number) {
    const orcamento = this.orcamentos[index]
    if (orcamento.titulo && orcamento.formato && orcamento.horas && orcamento.publico > 0 && orcamento.especialista && orcamento.acoes && orcamento.tema) {
      this.saveOne(index)
    }
  }

  async save() {
    try {
      var isOk: boolean = false
      for (const key in this.orcamentos) {
        const orcamento = this.orcamentos[key];
        if (orcamento.titulo && orcamento.formato && orcamento.horas && orcamento.publico && orcamento.especialista && orcamento.acoes && orcamento.tema) {
          isOk = true;
          break
        }
      }
      if (!isOk) {
        this.toast.error("Para concluir, precisa simular pelo menos um evento.")
        return
      }
      this.loading = true
      const res = await this.commonService.calcula({ orcamentos: this.orcamentos, info_contratante: this.info_contratante })

      this.loading = false
      this.orcamentos = res.orcamentos

      const resGenerate = await this.generateCalculadora(false, true)

      this.sendToRD()

      localStorage.removeItem('calculadora-orcamento')
      this.router.navigate(['calculadora/passo3'])
    } catch (error) {
      this.loading = false
      this.toast.error("Algo deu errado", "Não foi possível calcular.")
    }
  }

  async saveOne(index: number) {
    try {
      this.loading = true
      let orcamentosParm = [this.orcamentos[index]]
      const res = await this.commonService.calcula({ orcamentos: orcamentosParm, info_contratante: this.info_contratante })
      this.loading = false
      this.orcamentos[index] = res.orcamentos[0]
      localStorage.removeItem('calculadora-orcamento')
      localStorage.setItem('calculadora-orcamento', JSON.stringify(this.orcamentos))
    } catch (error) {
      this.loading = false
      this.toast.error("Algo deu errado", "Não foi possível calcular.")
    }
  }

  openPalestrante(slug: string) {
    if (slug) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/especialistas/${slug}`]));
      window.open(url, '_blank');
    }
  }

  async excel() {
    try {
      const res = await this.generateCalculadora(true, false)
      let a = document.createElement("a");
      a.href = 'data:application/octet-stream;base64,' + res;
      a.download = "calculadora.xlsx";
      a.click();
      a.remove();

    } catch (error) {

    }
  }

  async sendEmail() {
    try {
      const res = await this.generateCalculadora(false, true)
    } catch (error) {

    }
  }

  async generateCalculadora(is_excel: boolean, is_email: boolean) {
    this.loading = true
    try {
      const usuario = JSON.parse(window.localStorage.getItem("usuario"));
      this.info_contratante.email = usuario.email

      const res = await this.commonService.generateCalculadora({
        orcamentos: this.orcamentos,
        info_contratante: this.info_contratante,
        is_export_excel: is_excel,
        is_send_email: is_email
      })

      this.loading = false
      if (is_email) this.toast.success("Em breve você receberá o e-mail. Fique atento a sua caixa de entrada")
      return res

    } catch (error) {
      this.loading = false
      this.toast.error("Falha na conexão")
    }
  }

  goEspecialistas(index: number) {
    window.open(
      "https://dashboard.profissionaissa.com/especialistas?regiao=" + this.info_contratante.carteira
      + "&investimento=" + this.orcamentos[index].value_max
      + "&tema=" + this.orcamentos[index].tema)
  }

  sendToRD() {
    let tema = '';
    this.temas.forEach((i: any) => {
      if (i.id == this.orcamentos[0].tema) tema = i.nome
    })
    let formato = '';
    this.formatos.forEach((i: any) => {
      if (i.id == this.orcamentos[0].formato) formato = i.nome_formato
    })

    const bodyRD = {
      email: this.info_contratante.email,
      cep: this.info_contratante.cep,
      segmento: this.info_contratante.segmento,
      uf: this.info_contratante.uf,
      'temas de palestras': tema,
      'tema': tema,
      'Formato': formato,
      'formato': formato,
      'colaboradores': this.info_contratante.porte,
      identificador: 'ctr_calculadora'
    }

    this.rdStationService.formNewsletter(Object.assign({}, bodyRD))
      .then(() => this.toast.success("Dados enviados. Em breve, entraremos em contato.", "Obrigado!"))
      .catch((error) => {
        this.toast.error("Não foi possível enviar", "Tente novamente");
        bodyRD['erroRD'] = error;
        this.rdStationService.SendEmailInfoRD(bodyRD);
      });
  }

  getPlaceholderTitle(i): string {
    let place: string = 'Título *'
    switch (i) {
      case 0:
        place = "Ex: Convenção de Vendas"
        break;
      case 1:
        place = "Ex: Semana Sipat"
        break;
      case 2:
        place = "Ex: Treinamento de Líderes"
        break;
      case 3:
        place = "Ex: Palestra de Final de Ano"
        break;

      default:
        break;
    }
    return place
  }
}
