<div class="modal-header">
    <div class="flexFull flex-column">
        <h1>{{mensagem}}</h1>
        <h2>{{submensagem}}</h2>
    </div>
    <div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </div>
</div>
<div class="modal-body">
    <div class="d-flex flex-column flex-md-row">
        <button [class.d-none]="isAndroid" class="hader__button-loja" (click)="goto('apple')"><img
                src="assets/icons/apple.svg"> APP STORE</button>
        <button [class.d-none]="isIOS" class="hader__button-loja" (click)="goto('google')"><img
                src="assets/icons/play-store.svg">
            GOOGLE PLAY</button>
    </div>
</div>