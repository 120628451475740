<main>
  <h1>Editar Usuário</h1>

  <div>
    <form>
      <section>
        <label for="name">Nome:</label>
        <input id="name" name="name" type="text" [(ngModel)]="form.nome" />
      </section>

      <section>
        <label for="lastName">Sobrenome:</label>
        <input id="lastName" name="lastName" type="text" [(ngModel)]="form.sobrenome" />
      </section>

      <section>
        <label for="email">Email:</label>
        <input id="email" name="email" type="text" [(ngModel)]="form.email" />
      </section>

      <section>
        <label>Telefone:</label>
        <input type="text" [(ngModel)]="form.telefone" [mask]="'(00) 0 0000-0000'" [validation]="true" />
      </section>

      <section>
        <label for="senha">Senha:</label>
        <input id="senha" name="senha" type="password" [(ngModel)]="form.senha" />
      </section>

      <section>
        <label for="profile">Perfil:</label>
        <select name="profile" id="profile" [(ngModel)]="form.perfil">
          <option value="admin">Admin</option>
          <option value="curadora">Curador A</option>
          <option value="curadorb">Curador B</option>
          <option value="curadorc">Curador C</option>
          <option value="avaliador">Avaliador</option>
          <option value="gestorp">Gestor de Palestrantes</option>
          <option value="gestor">Gestão</option>
          <option value="cs">CS</option>
        </select>
      </section>

      <section class="checkbox">
        <label for="isActive">Ativar/Inativar</label>
        <input type="checkbox" name="isActive" id="isActive" [(ngModel)]="form.is_active" />
      </section>
      <button type="button" (click)="save()">Salvar</button>
    </form>
  </div>
</main>
