import { Routes } from '@angular/router';

import { Passo1Component } from './passo1';
import { Passo2Component } from './passo2';
import { Passo3Component } from './passo3';

export const CalculadoraRoutes: Routes = [
  {
    path: 'calculadora/passo1',
    component: Passo1Component
  },
  {
    path: 'calculadora/passo2',
    component: Passo2Component
  },
  {
    path: 'calculadora/passo3',
    component: Passo3Component
  },
];
