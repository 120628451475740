import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GestaoService {
  constructor(private http: HttpClient) {}

  async getList(palestranteId: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const res = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}gestao/list/${palestranteId}`, { headers: headers }).toPromise();

    return res.body;
  }

  async insertUpdate(palestranteId: number, data: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const body = { ...data };

    const res = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}gestao/insert-update/${palestranteId}`, body, { headers: headers }).toPromise();

    return res.body;
  }
}
