<div class="container pl-lg-5 pr-lg-5">
    <h3 class="font-title">Educação Corporativa</h3>

    <!-- <div class="container mx-0"> -->


    <div class="row">

        <div class="col-12 px-0">

            <div class="container__info d-flex px-2 px-lg-0 ">

                <div class="h100 container__gerencie mr-0 mr-lg-3 mb-2 mb-lg-0">
                    <div (click)="go()"
                        class="row ml-0 w-100 container__conteudo__informacao__cor">
                        <div class="col-3 h100 container__conteudo__informacao__cor_img">
                            <img class="icon41" src="assets/icons/ead.svg" alt="universidade">
                        </div>
                        <div class="col-9 d-flex flex-column justify-content-center">
                            <label class="container__conteudo__informacao__cor__descricao txt__clique">Clique aqui para
                                gerenciar</label>
                            <label class="container__conteudo__informacao__cor__descricao m-0">Cursos
                                profissionalizantes para o desenvolvimento dos seus colaboradores.</label>
                        </div>
                    </div>
                </div>
                <div class="h100 container__total container__conteudo__informacao">
                    <div class="row">
                        <div class="col-4">
                            <div class="row">
                                <div class="col-3">
                                    <img class="icon" src="assets/icons/grupo.svg" alt="grupos">
                                </div>
                                <div class="col-9">
                                    <p class="container__conteudo__numero">{{resultCount.total_grupos}}</p>
                                    <p class="container__conteudo__texto">Grupos</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-3">
                                    <img class="icon" src="assets/icons/lider.svg" alt="total lideres">
                                </div>
                                <div class="col-9">
                                    <p class="container__conteudo__numero">{{resultCount.total_lideres}}</p>
                                    <p class="container__conteudo__texto">Tutores</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-3">
                                    <img class="icon" src="assets/icons/people.svg" alt="Colaboradores">
                                </div>
                                <div class="col-9">
                                    <p class="container__conteudo__numero">{{resultCount.total_liderados}}</p>
                                    <p class="container__conteudo__texto">Colaboradores</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="col-12 w-100 m-0 px-0 py-3">
        <div class="container__conteudo row" style="overflow-x: scroll !important; ">

            <h4 class="font-title mb-3">Como implementar na sua empresa:</h4>

            <div class="d-flex align-items-center justify-content container__video">
                <video poster="assets/img/poster_video.png" controls controlsList="nodownload">
                    <source src="https://basesa.s3-sa-east-1.amazonaws.com/arquivos/usododashboard.mp4"
                        type="video/mp4">
                </video>
            </div>

        </div>
    </div>

    <!-- </div> -->
</div>