<article *ngIf="loading" class="d-flex justify-content-center w-100">
  <app-spinner></app-spinner>
</article>

<!-- TODO - Alterar para componente cardTrilha -->
<a
  *ngFor="let skill of startedSkills"
  class="cardTrilha cardTrilha--vertical"
  [title]="skill.habilidade_titulo"
  [routerLink]="['/espectador/minhaTrilha', skill.id_habilidade]"
>
  <figure class="cardTrilha__img" [style.backgroundImage]="'url(' + skill.habilidade_imagem_link +')'">
    <span *ngIf="skill.arquivo_principal" class="tagBadge tagBadge--primary tagBadge--figure">
      {{skill.arquivo_principal}}
    </span>
    <img [src]="skill.habilidade_imagem_link" [alt]="skill.habilidade_titulo">
  </figure>

  <div class="cardTrilha__content">
    <span *ngIf="skill.competencia_nome" class="tagBadge">{{skill.competencia_nome}}</span>

    <h3 *ngIf="skill.habilidade_titulo" class="cardTrilha__title">
      {{skill.habilidade_titulo}}
    </h3>

    <a
      href="javascript:void()"
      class="btn btnPlay cardTrilha__button"
      title="Clique para iniciar a sua trilha"
      [routerLink]="['/espectador/minhaTrilha', skill.id_habilidade]"
    >
      <img height="10" src="assets/icons/play.svg" alt="Iniciar trilha">
      Iniciar
    </a>
  </div>
</a>

<a
  *ngFor="let skill of pendingSkills"
  class="cardTrilha cardTrilha--vertical"
  [title]="skill.habilidade_titulo"
  [routerLink]="['/espectador/minhaTrilha', skill.id_habilidade]"
>
  <figure class="cardTrilha__img" [style.backgroundImage]="'url(' + skill.habilidade_imagem_link +')'">
    <span *ngIf="skill.arquivo_principal" class="tagBadge tagBadge--primary tagBadge--figure">
      {{skill.arquivo_principal}}
    </span>
    <img [src]="skill.habilidade_imagem_link" [alt]="skill.habilidade_titulo">
  </figure>

  <div class="cardTrilha__content">
    <span *ngIf="skill.competencia_nome" class="tagBadge">{{skill.competencia_nome}}</span>

    <h3 *ngIf="skill.habilidade_titulo" class="cardTrilha__title">
      {{skill.habilidade_titulo}}
    </h3>

    <a
      href="javascript:void()"
      class="btn btnPlay cardTrilha__button"
      title="Clique para iniciar a sua trilha"
      [routerLink]="['/espectador/minhaTrilha', skill.id_habilidade]"
    >
      <img height="10" src="assets/icons/play.svg" alt="Iniciar trilha">
      Iniciar
    </a>
  </div>
</a>
