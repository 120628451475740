import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { LivePalestranteComponent } from './palestrante/live-palestrante';
import { AvisoTempoLiveComponent } from './palestrante/aviso-tempo-live';
import { ModalAddTempoComponent } from './palestrante/modal-add-tempo';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ExportComponentsModule } from 'src/app/common/export-components';
import { LiveEspectadorComponent } from './espectador/live-espectador';
import { ChatComponent } from './espectador/chat';
import { QuizComponent } from './espectador/quiz';
import { TermometroComponent } from './espectador/termometro';
import { VideosComponent } from './espectador/videos';
import { ModalCertificadoComponent } from './espectador/modal-certificado';
import { environment } from 'src/environments/environment';

// const config: SocketIoConfig = { url: environment.API_V2_URL, options: {} };

@NgModule({
  declarations: [
    LivePalestranteComponent,
    AvisoTempoLiveComponent,
    ModalAddTempoComponent,
    LiveEspectadorComponent,
    ChatComponent,
    QuizComponent,
    TermometroComponent,
    VideosComponent,
    ModalCertificadoComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    RouterModule,
    ExportComponentsModule,
    // SocketIoModule.forRoot(config)
  ]
})
export class LiveModule { }
