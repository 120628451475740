import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IQuiz } from 'src/app/pages/live/espectador/live-espectador';

@Injectable({
  providedIn: 'root'
})
export class LiveEspectadorService {

  token: string = null

  constructor(public http: HttpClient) { }


  async getToken(): Promise<string> {
    let usuario = JSON.parse(localStorage.getItem("usuario"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}profissionais/auth/token/${usuario.id}`).toPromise();
    return result.body;
  }

  async getUserToken(): Promise<string> {
    if (!this.token) this.token = await this.getToken()
    return this.token
  }

  async verifyLive(idPalestra: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}live/verifica-live/${idPalestra}`, { headers: headers }).toPromise();
    return result.body;
  }


  async getInfo(palestra_id: number): Promise<any> {
    const token = await this.getUserToken();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Action', 'BuscaInformacoesLive')
      .set('token', token);
    let body = JSON.stringify({ palestra_id });
    const res = await this.http.post<any>(`${environment.API_URL}`, body, { headers: headers }).toPromise();
    return res
  }


  async saveQuestion(id: number, pergunta: string): Promise<any> {
    const token = await this.getUserToken();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Action', 'SalvaPalestraPergunta')
      .set('token', token);
    let body = JSON.stringify({
      palestra_id: id,
      pergunta: pergunta
    });
    const res = await this.http.post<any>(`${environment.API_URL}`, body, { headers: headers }).toPromise();
    return res
  }


  async saveQuiz(id_palestra: number, id_pergunta: number, alternativa: string, alternativaDescricao: string): Promise<any> {
    const token = await this.getUserToken();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Action', 'SalvaPalestraRespostaQuiz')
      .set('token', token);
    let body = JSON.stringify({
      id_palestra: id_palestra,
      id_pergunta: id_pergunta,
      resposta: alternativa,
      respostaDescricao: alternativaDescricao
    });
    const res = await this.http.post<any>(`${environment.API_URL}`, body, { headers: headers }).toPromise();
    return res
  }


  async saveRating(id_palestra: number, value: number): Promise<any> {
    const token = await this.getUserToken();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Action', 'SalvaPalestraAvaliacao')
      .set('token', token);
      let body = JSON.stringify({
        id_palestra: id_palestra,
        valor: value
      });
    const res = await this.http.post<any>(`${environment.API_URL}`, body, { headers: headers }).toPromise();
    return res
  }


  async getLastQuestions(palestra_id: number): Promise<any> {
    const token = await this.getUserToken();
    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Action', 'BuscaPalestraUltimasPerguntas')
      .set('token', token);
    let body = JSON.stringify({ palestra_id: palestra_id });
    const res = await this.http.post<any>(`${environment.API_URL}`, body, { headers: headers }).toPromise();
    return res
  }


  async getPalestraById(id: number): Promise<any> {
    let headers = new HttpHeaders().set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/palestras/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getQuiz(palestraId: number): Promise<IQuiz> {
    let headers = new HttpHeaders().set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/palestras/quiz/${palestraId}`, { headers: headers }).toPromise();
    return result.body;
  }


  async setWatched(id: number): Promise<IQuiz> {
    let headers = new HttpHeaders().set('Authorization', localStorage.getItem("token"));
    const result = await this.http.put<any>(`${environment.API_V2_URL}app-espectador/palestras/assistido/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

}
