import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-opcoes-adicao-contato',
  templateUrl: './opcoes-adicao-contato.component.html',
  styleUrls: ['./opcoes-adicao-contato.component.scss']
})
export class OpcoesAdicaoContatoComponent implements OnInit {

  type: string = null; //'CONTATO' / 'COLABORADOR'

  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit(): void {
  }

  option(value: string) {
    this.type = value
    this.bsModalRef.hide()
  }

}
