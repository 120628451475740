import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modal-play',
  templateUrl: './modal-play.component.html',
  styleUrls: ['./modal-play.component.scss']
})
export class ModalPlayComponent implements OnInit {

  @Input('videoLink') videoLink: string;
  @Input('title') title: string;
  url: SafeResourceUrl

  constructor(
    public bsModalRef: BsModalRef,
    public domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.url = this.getVideo()
  }

  close() {
    this.bsModalRef.hide();
  }

  getVideo() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoLink);
  }

  isYoutube() {
    return !this.videoLink ? false : this.videoLink.includes("youtu")
  }

}
