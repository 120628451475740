import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-add-tempo',
  templateUrl: './modal-add-tempo.component.html',
  styleUrls: ['./modal-add-tempo.component.css']
})
export class ModalAddTempoComponent implements OnInit {
  @Output("cancelAddTime") cancelAddTime = new EventEmitter();
  @Output("addTime") addTime = new EventEmitter();

  @Input() mensagem: string
  @Input() submensagem: string
  constructor() { }

  ngOnInit() {
  }

  select(value: boolean){
    if (value) {
      this.addTime.emit();
    } else {
      this.cancelAddTime.emit();
    }
  }
}
