import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common';

@Component({
  selector: 'app-modal-locked',
  templateUrl: './modal-locked.component.html',
  styleUrls: ['./modal-locked.component.scss']
})
export class ModalLockedComponent implements OnInit {
  whatsNumber = '5511950789035';

  constructor(
    public bsModalRef: BsModalRef,
    public commonService: CommonService,
    ) { }

  ngOnInit(): void {
    this.commonService.getConfiguracao("telefone__suporte__contratante", "5511950789035")
      .then(res => { this.whatsNumber = res.valor })
      .catch(e => { console.log(e); })
  }

  whats() {
    const usuario = JSON.parse(window.localStorage.getItem("usuario"));
    let texto = "Olá, sou " + usuario.nome + ", e-mail: + " + usuario.email + ". Entro em contato por meio do Dashboard. Tenho interesse na EDUCAÇÃO CORPORATIVA.";
    texto += usuario.empresa ? " Empresa: " + usuario.empresa : ".";
    window.open("https://api.whatsapp.com/send?phone=" + this.whatsNumber + "&text=" + texto, "_blank")
  }
}
