import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common';
import { GrupoService } from 'src/app/services/grupo';
import { IGrupo } from '../grupo-lideres';
import { NotificacaoModalColaboradoresComponent } from './notificacao-modal-colaboradores';
import { NotificacaoModalContatosComponent } from './notificacao-modal-contatos';
import { NotificacaoModalLideresComponent } from './notificacao-modal-lideres';

export interface IMaterial {
  id?: number,
  link: string,
  titulo: string
}

interface IEnvio {
  contatos: boolean,
  tutores: boolean,
  colaboradores: boolean
  idsLideres: Array<number>
  idsColaboradores: Array<number>
  idsContatos: Array<number>
  toAllLideres: boolean
  toAllColaboradores: boolean
  toAllContatos: boolean
}
@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.scss']
})
export class NotificacaoComponent implements OnInit {
  bsModalRef: BsModalRef;
  id_grupo: number = null;
  grupos: Array<IGrupo> = []
  videoLink: string = ""//"https://basesa.s3-sa-east-1.amazonaws.com/video_novo_11-2020.mp4";
  notificar: boolean = true;
  envio: IEnvio = {
    contatos: false,
    tutores: false,
    colaboradores: false,
    idsLideres: [],
    idsColaboradores: [],
    idsContatos: [],
    toAllLideres: true,
    toAllColaboradores: true,
    toAllContatos: true,
  }
  materiais = [
    {
      id: 1,
      link: "https://basesa.s3-sa-east-1.amazonaws.com/arquivos/ApresentacaoEducacaoCorporativaParaProfissionais.pdf",
      titulo: "Apresentação | Educação Corporativa para Profissionais"
    },
    {
      id: 2,
      link: "https://basesa.s3-sa-east-1.amazonaws.com/arquivos/ApresentacaoEducacaoCorporativaParaEmpresas.pdf",
      titulo: "Apresentação | Educação Corporativa para Empresas"
    },
  ];
  grupo: any;

  constructor(
    private modalService: BsModalService,
    public domSanitizer: DomSanitizer,
    private router: Router,
    private toast: ToastrService,
    private grupoService: GrupoService,
    private commonService: CommonService,
  ) { }

  async ngOnInit(): Promise<void> {
    const config = await this.commonService.getConfiguracao("dashboard_video_notificacao", "")
      .catch(e => { console.log(e); })
    this.videoLink = config.valor

    this.grupoService.getGrupos().then(res => this.grupos = res)
  }

  getVideo() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.videoLink);
  }

  async salvar() {
    try {
      if (!this.id_grupo) {
        this.toast.error("Selecione um grupo")
        return
      }
      this.grupoService.sendMaterial({
        id_grupo: this.id_grupo,
        envio: this.envio
      })
      this.toast.success("Grupo salvo com sucesso!", this.envio.colaboradores || this.envio.contatos || this.envio.tutores ? "Em breve os usuários receberão seus e-mails" : "");
      this.router.navigate(['/universidade/home'])
    } catch (error) {
      this.toast.error("Algum erro ocorreu ao tentar notificar")
    }
  }

  download(material: IMaterial) {
    window.open(material.link)
  }

  getAdministradores() {
    if (!this.envio.contatos) return;
    const initialState = { id_grupo: this.id_grupo };
    const config = { class: "modal-play", initialState }
    this.bsModalRef = this.modalService.show(NotificacaoModalContatosComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.list) this.envio.idsContatos = this.bsModalRef.content.list
      if (this.bsModalRef.content.list.length > 0) this.envio.toAllContatos = false
    })
  }

  getLideres() {
    if (!this.envio.tutores) return;
    const initialState = { id_grupo: this.id_grupo };
    const config = { class: "modal-play", initialState }
    this.bsModalRef = this.modalService.show(NotificacaoModalLideresComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.list) this.envio.idsLideres = this.bsModalRef.content.list
      if (this.bsModalRef.content.list.length > 0) this.envio.toAllLideres = false
    })
  }

  getColaboradores() {
    if (!this.envio.colaboradores) return;
    const initialState = { id_grupo: this.id_grupo };
    const config = { class: "modal-play", initialState }
    this.bsModalRef = this.modalService.show(NotificacaoModalColaboradoresComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.list) this.envio.idsColaboradores = this.bsModalRef.content.list
      if (this.bsModalRef.content.list.length > 0) this.envio.toAllColaboradores = false
    })
  }

  resetValues() {
    this.envio = { contatos: false, tutores: false, colaboradores: false, idsLideres: [], idsColaboradores: [], idsContatos: [], toAllLideres: true, toAllColaboradores: true, toAllContatos: true, }
  }
}
