<main *ngIf="!showConfirmation && !loading">
  <h1>Gerar formulário para emissão de certificado</h1>

  <div>
    <label for="speakerName">Palestrante</label>
    <input id="speakerName" name="speakerName" placeholder="Nome do palestrante" [(ngModel)]="newForm.speakerName" />

    <label for="speakerEmail">Email Palestrante</label>
    <input id="speakerEmail" name="speakerEmail" placeholder="Email do palestrante" [(ngModel)]="newForm.speakerEmail" />

    <label for="clientEmail">Email Cliente</label>
    <input id="clientEmail" name="clientEmail" placeholder="Email do cliente" [(ngModel)]="newForm.emailClient" />

    <label for="talkName">Título da palestra</label>
    <input id="talkName" name="talkName" placeholder="Nome da palestra" [(ngModel)]="newForm.talkTitle" />

    <label for="eventName">Evento</label>
    <input id="eventName" name="eventName" placeholder="Nome do evento" [(ngModel)]="newForm.eventName" />

    <label for="emailCS">Email CS Responsável</label>
    <input id="emailCS" name="emailCS" placeholder="Email do CS responsável" [(ngModel)]="newForm.emailCS" />

    <label for="eventDate">Data do evento</label>
    <input type="date" id="eventDate" name="eventDate" placeholder="Data do evento..." [(ngModel)]="newForm.eventDate" />

    <label for="businessName">Nome do negócio</label>
    <input id="businessName" name="businessName" placeholder="Nome do negócio..." [(ngModel)]="newForm.businessName" required />

    <label for="noAvaliation">Gerar formulário sem campos de avaliação?</label>
    <input name="noAvaliation" type="checkbox" [(ngModel)]="newForm.noAvaliation" />
  </div>

  <button (click)="editForm()">Gerar Formulário</button>
</main>

<main class="confirmation__message__container" *ngIf="showConfirmation">
  <div class="confirmation__message">
    <h1>Tudo Certo!</h1>
    <p>Em breve você receberá por email o QRCode com o link do formulário.</p>
  </div>

  <button (click)="this.showConfirmation = false">Gerar novo formulário</button>
</main>
