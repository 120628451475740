import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormacoesAcademicasService {
  constructor(public http: HttpClient) { }

  async getList(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const palestrante = JSON.parse(localStorage.getItem("palestrante"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}formacoes/list/${palestrante.id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getListByPalestranteId(palestrante: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}formacoes/list/${palestrante.id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getById(id: number, palestrante?: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    if(!palestrante){
      palestrante = JSON.parse(localStorage.getItem("palestrante"));
    }

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}formacoes/${id}/${palestrante.id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertOrUpdate(formacao: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const body = { formacao: formacao }
    const res = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}formacoes/insert-update`, body, { headers: headers }).toPromise();
    return res.body
  }

  async delete(id: number, palestrante?: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    if(!palestrante){
      palestrante = JSON.parse(localStorage.getItem("palestrante"));
    }

    const res = await this.http.delete<any>(`${environment.API_V2_URL_DEFAULT}formacoes/${id}/${palestrante.id}`, { headers: headers }).toPromise();
    return res.body
  }
}
