import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user';

@Component({
  selector: 'app-modal-alterar-foto',
  templateUrl: './modal-alterar-foto.component.html',
  styleUrls: ['./modal-alterar-foto.component.scss']
})
export class ModalAlterarFotoComponent implements OnInit {
  loading: boolean = false;
  success: boolean = false;
  userId: number | undefined;

  formGroup = this.fb.group({
    file: [null, Validators.required]
  });

  constructor(
    public bsModalRef: BsModalRef,
    private toast: ToastrService,
    private usuarioService: UserService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.userId = Number(this.router.url.split('/').pop());

    if(isNaN(this.userId)){
      this.userId = JSON.parse(localStorage.getItem('usuario')).id;
    }

    this.formGroup.controls.file.setValue(null)
  }

  close() {
    this.bsModalRef.hide();
  }

  async changeFoto() {
    try {
      this.loading = true;

      console.log("aqui", this.userId)
      this.usuarioService.setFoto({ img_data64: this.formGroup.controls.file.value }, this.userId).then(() => {
        this.loading = false;
        this.success = true;
        this.toast.success("Foto alterada com sucesso!")
        this.close()
      })

    } catch (error) {
      this.loading = false;
      this.toast.error("Não foi possível alterar a foto!")
    }
  }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.formGroup.patchValue({ file: reader.result });
        this.cd.markForCheck();
      };
    }


  }
}
