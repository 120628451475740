import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GrupoService } from 'src/app/services/grupo';
import { ContratanteService } from 'src/app/services/contratante';
import { AppComponent } from 'src/app/app.component';

interface IItemMenu {
  id: number,
  ordem: number,
  title: string,
  page: string,
  selected: boolean
  lastItem?: boolean
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  @Input() id: number;
  itemSelected: number;
  id_grupo: number;

  list: Array<IItemMenu> = [
    {
      id: 4,
      ordem: 1,
      title: "Tipo",
      page: 'conteudos/tipo',
      selected: false
    },
    {
      id: 1,
      ordem: 2,
      title: "Competências",
      page: 'conteudos/competencias',
      selected: false
    },
    {
      id: 2,
      ordem: 3,
      title: "Habilidades",
      page: 'conteudos/habilidades/ID_COMPETENCIA',
      selected: false
    },
    {
      id: 3,
      ordem: 4,
      title: "Habilidade e Perguntas",
      page: null,
      selected: false,
      lastItem: true
    },
  ]
  idCompetencia: any

  constructor(private router: Router,
    private app: AppComponent,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private grupoService: GrupoService,
    private contratanteService: ContratanteService,) { }

  ngOnInit(): void {
    this.idCompetencia = parseInt(this.route.snapshot.paramMap.get("idCompetencia"))
    // const usuario = JSON.parse(localStorage.getItem("usuario"));
    this.itemSelected = this.list.filter(i => i.id == this.id)[0].ordem

  }

  async goPage(item: IItemMenu) {
    if (item.id == 2 && !this.idCompetencia) {
      this.toast.error("Selecione uma competência")
      return
    }

    if (!item.page) {
      this.msgEmDev()
      return
    }
    this.router.navigate([item.page.replace("ID_COMPETENCIA", this.idCompetencia)])
  }

  msgEmDev() {
    this.toast.info('Em desenvolvimento.', 'Em breve traremos novidades.')
  }
}
