<div class="container-fluid">
    <div class="row mx-1 mx-md-5">

        <app-wizard  class="col-12 col-md-10" [id]="1"></app-wizard>
        <div class="col-12 col-md-2 container__btn__add">
            <button (click)="insert()" class="btn__primary">ADICIONAR</button>
        </div>

        <div class="col-12 mt-3 container__default">

            <div *ngIf="loading" class="flexFull">
                <app-spinner></app-spinner>
            </div>

            <div class="d-flex flex-column">

                <div *ngIf="competencias.length == 0">
                    Nenhuma competência
                </div>

                <div *ngFor="let item of competencias" class="content__item div__shadow row mx-0">
                    <div class="col-12 col-md-9 content__item__info">
                        <span *ngIf="item.id_contratante > 0" class="competencia__tipo">PERSONALIZADA</span>
                        <h4 class="font-title">{{item.descricao}}</h4>
                        <p [innerHTML]="getResumo(item.resumo)" class="content__item__descricao"></p>
                    </div>
                    <div class="col-12 col-md-3 flexColFull border-left">

                        <div *ngIf="item.id_contratante > 0" (click)="update(item)" class="flexFull acao">
                            <img src="assets/icons/editar.svg" alt="editar">
                            <label class="font-title">EDITAR</label>
                        </div>
                        <div (click)="goHabilidades(item.id)" class="flexFull acao">
                            <img src="assets/icons/habilidade.svg" alt="habilidades">
                            <label class="font-title">habilidades</label>
                        </div>
                        <div *ngIf="item.id_contratante > 0" (click)="delete()" class="flexFull acao">
                            <img src="assets/icons/excluir.svg" alt="excluir">
                            <label class="font-title">Excluir</label>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>