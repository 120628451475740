<div class="row">
    <div class="col-12 p-0">

        <div class="d-flex flex-column flex-lg-row justify-content-center align-items-canter">

            <div class="d-flex flex-column mr-2 pl-lg-2 div__title">
                <h4 class="d-inline font-title">Perfil</h4>
            </div>

            <div class="item flexFull mt-1 mt-lg-0" (click)="goPage(item)" [ngStyle]="{
                'background-color': itemSelected >= item.ordem ? 'var(--yellow)' : '#CACAD0 ',
                'opacity': item.ordem < itemSelected ? '0.5' : '1'
                }" [ngClass]="{ 
                firstTab : item.ordem == 1,
                middleTab : item.ordem > 1,
                lastTab : item.ordem == list.length
            }" *ngFor="let item of list">{{item.title}}</div>
        </div>
    </div>
</div>