import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-horario-nobre',
  templateUrl: './modal-horario-nobre.component.html',
  styleUrls: ['./modal-horario-nobre.component.scss']
})
export class ModalHorarioNobreComponent implements OnInit {

  liberado: boolean = false;
  text: string = "Não é permitido criar palestra em horários reservados. Para solicitar o horário entre em contato com seu curador."
  textSchedule: string = "Horários reservados: 19:00, 19:30, 20:00, 20:30 e 21:00"

  mensagem: string
  codigoInput: string
  pass: string = "zcP@!lwI"
  preenchercodigo: boolean = false

  constructor(
    public bsModalRef: BsModalRef,) { }

  ngOnInit(): void {
  }


  compareCode() {
    this.mensagem = ""
    if (this.codigoInput == this.pass) {
      this.liberado = true;
      this.bsModalRef.hide();
    } else {
      this.mensagem = "Código inválido"
    }
  }

  avancar() {
    this.preenchercodigo = true
  }
}
