<section>
    <header class="modal-header p-2">
        <h3 class="font-title">
            Adicionar administrador
        </h3>
    </header>
    <article class="d-flex flex-column align-items-center justify-content-center p-3">
        <button class="btn__primary mb-3" (click)="option('COLABORADOR')">adicionar um colaborador</button>
        <button class="btn__primary" (click)="option('CONTATO')">adicionar um novo administrador</button>
    </article>
</section>