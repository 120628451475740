import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TbsbService } from 'src/app/services/tbsb/tbsb.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { MatDialog } from '@angular/material/dialog';
import { ModalUpdateRankingComponent } from './modal-update-ranking/modal-update-ranking.component';


@Component({
  selector: 'tbsb-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss', '../tbsb.component.scss']
})
export class RankingComponent implements OnInit {

  loading: boolean = false;
  speakers: any = [];
  updating: any = {};
  newRank = {
    votes_psa: 0,
    position_psa: 0
  };
  userId: string = '';

  categories: Array<string> = [
    'Inovação',
    'Negócios e Empreendedorismo',
    'Vendas',
    'Inteligência Artificial e Novas Tecnologias',
    'Comunicação',
    'Motivação ',
    'Gestão de Pessoas e Liderança',
    'Educação',
    'Saúde física e mental',
    'Diversidade e inclusão',
    'Autoconhecimento',
    'Creator Economy',
  ];

  filters = {
    category: '',
    email: '',
    dateStart: '',
    dateEnd: '',
  }

  // pagination
  pages: number;
  currentPage: number = 0;

  // csv
  headers = [
    'Id',
    'posição',
    'nome',
    'email',
    'categoria do vídeo',
    'quantidade de votos computados',
    'data de envio do video'
  ];
  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Planilha de respostas',
    useBom: true,
    noDownload: false,
    headers: this.headers,
    eol: '\n'
  };

  constructor(
    private speakersService: TbsbService,
    private toastr: ToastrService,
    private matDialog: MatDialog
  ) {
    const user = JSON.parse(localStorage.getItem('usuario'));
    this.userId = user.id;
  }

  ngOnInit(): void {
    this.getSpeakers();
  }

  getSpeakers() {
    this.speakersService.getSpeakers(this.filters).subscribe(
      (res: any) => {
        this.speakers = res;
      },
      (data: any) => {
        this.toastr.error('Não foi possível recuperar os palestrantes.');
      }
    );
  }

  resetFilters() {
    this.filters = {
      category: '',
      email: '',
      dateStart: '',
      dateEnd: '',
    }
    this.getSpeakers();
  }

  cleanDataForCSV(data: any) {
    return data.map((speaker: any) => {
      return {
        id: speaker.id,
        ranking: '#1',
        nome_completo: speaker.nome + ' ' + speaker.sobrenome,
        email: speaker.email,
        category: speaker.video_palestrantes[0]?.category || 'Não enviou vídeo',
        votes: speaker.video_palestrantes[0]?.votes.length || 0,
        data_envio: speaker.video_palestrantes[0]?.createdAt || 'Não enviou vídeo',
      }
    });
  }

  exportToExcel() {
    new ngxCsv(this.cleanDataForCSV(this.speakers), 'Planilha de respostas', this.options);
  }

  openUpdateRankingDialog(speaker: any) {
    const MatDialogRef = this.matDialog.open(ModalUpdateRankingComponent, {
      width: '500px',
      data: {
        speaker,
        votes: speaker?.video_palestrantes[0]?.Rankings[0]?.votes || 0,
        position: speaker?.video_palestrantes[0]?.Rankings[0]?.position || 0,
        votes_psa: speaker?.video_palestrantes[0]?.Rankings[0]?.votes_psa || 0,
        position_psa: speaker?.video_palestrantes[0]?.Rankings[0]?.position_psa || 0,
        userId: this.userId
      }
    });

    MatDialogRef.afterClosed().subscribe((result: any) => {
      console.log('closed');
      this.getSpeakers();
    })
  }


  getPosition(speaker: any) {
    if(!speaker?.video_palestrantes[0]?.id) {
      return 'Palestrante não tem rank'
    } else {
      const position = speaker?.video_palestrantes[0]?.Rankings[0]?.position_psa || speaker?.video_palestrantes[0]?.Rankings[0]?.position;

      if(position >=1 && position <= 300) {
        return 'Palestrante entre os 300 classificados'
      } else if (position > 300 && position <= 500) {
        return 'Palestrante perto dos 300 classificados'
      } else if (position > 500) {
        return 'Palestrante não classificado'
      }
    }

    return 'Palestrante não tem rank';
  }

  getVotesOfCurrRank(speaker: any) {
    return speaker?.video_palestrantes[0]?.Rankings[0]?.votes_psa || 'Sem votos inseridos';
  }

  getDbVotes(speaker: any) {
    return speaker?.video_palestrantes[0]?.votes.length || 'Sem votos computados';
  }

  getParsedDate(speaker: any) {
    const date = new Date(speaker?.video_palestrantes[0]?.createdAt);
    return new Date(speaker?.video_palestrantes[0]?.createdAt).toLocaleDateString('pt-br') || 'Vídeo não enviado';
  }
}
