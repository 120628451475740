<main class="appContainer fadeIn">
  <div class="cardMain">
    <div class="cardMain__header">
      <h2 class="title mb-2">Configurações</h2>
      <p class="colorFontSecondary">
        Altere seus dados, revise o plano de assinatura e acesse opções de segurança
      </p>
    </div>

    <div class="cardMain__contentScroll mt-4" style="max-height: calc(66vh);">
      <tabset class="tabsetCustom">
        <tab class="tabsetCustom__item" heading="Meus dados" [active]="tab == 'dados'">
          <app-personal-data></app-personal-data>
        </tab>

        <tab class="tabsetCustom__item" heading="Meu Plano" [active]="tab == 'planos'">
          <app-tb-planos></app-tb-planos>
        </tab>

        <tab class="tabsetCustom__item" heading="Segurança/Senha" [active]="tab == 'seguranca'">
          <app-tb-senha></app-tb-senha>
        </tab>
      </tabset>
    </div>
  </div>
</main>
