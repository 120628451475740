<main class="container" *ngIf="!loading">
  <div class="details__header flex flex-row p-3 align-items-center">
    <img src="../../../../../../assets/icons/back.svg" routerLink="/admin/tbsb" />
    <h1>THE BEST SPEAKERS BRAZIL - Video</h1>
  </div>

  <div class="video__player__container" *ngIf="!isYoutubeUrl">
    <video width="100%" height="100%" controls controlsList="nodownload">
      <source [src]="videoUrl" type="video/mp4">
    </video>
  </div>

  <div class="video__player__container" *ngIf="isYoutubeUrl">
    <iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
  </div>

  <div class="container video__description">
    <h2>{{ video.usuario.nome }} {{ video.usuario.sobrenome }} | {{ video.title }}</h2>
    <span class="video__category">{{ video.category }}</span>
    <span class="video__status" [ngStyle]="video.status=='aprovado'?{'background-color':'green'} : {'background-color': 'red'}">{{ video.status }}</span>
    <p class="mt-5">
      {{ video.description }}
    </p>
  </div>

  <div class="actions__container flex flex-row w-100 align-items-center">
    <div class="w-100">
      <label for="youtube">Youtube url:</label>
      <input type="text" [(ngModel)]="youtubeUrl" id="youtube" class="form-control" placeholder="https://youtube.com/" />
    </div>

    <button class="btn btn-primary mt-5" (click)="updateYoutubeUrl()">Atualizar</button>
  </div>

  <div class="denie__video__container" *ngIf="showForm">
    <h3>Informe o motivo da recusa do vídeo:</h3>
    <form>
      <textarea type="text" class="form-control" placeholder="Motivo da recusa" [(ngModel)]="reason"></textarea>

      <button (click)="denieVideo()">Enviar recusa</button>
    </form>
  </div>

  <div *ngIf="!showForm" class="actions__container">
    <button *ngIf="status !== 'negado'" class="btn btn-danger" (click)="showForm = true">Recusar</button>
    <button *ngIf="status !== 'aprovado'" class="btn btn-success" (click)="approveVideo()">Aprovar</button>
  </div>

</main>
