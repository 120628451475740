
<nav class="sideBar">
  <button (click)="closeSide()" class="sideBar__btnToogle" title="Fechar Menu">
    <img
      src="assets/icons/close_sidemenu.svg"
      alt="Fechar menu"
      width="9"
      height="15"
    />
  </button>

  <div class="sideBar__itemSpaced">
    <a class="sideBar__logo" href="javascript:void()" title="Ir para Home" (click)="home()">
      <img class="fadeIn" src="assets/img/logo-black.svg" alt="Logo Profissonais SA" width="170" height="50">
    </a>

    <ul class="navItens">
      <li
        *ngFor="let item of itemsMenuPrincipal"
        class="navItens__item"
        [class.active]="urlActive === item.route && item.route"
      >
        <a href="javascript:void()" (click)="openPrincipal(item.id)" title="Ir para {{item.text}}">
          <span class="navItens__icon">
            <img src="{{item.srcIcon}}" alt="{{item.text}}" height="20"/>
          </span>
          {{item.text}}
        </a>
      </li>
    </ul>
  </div>

  <div *ngIf="showSubNav" class="sideBar__navigation">
    <ul class="navItens">
      <li (click)="navigateExternal('https://profissionaissa.com')">Site</li>
      <li (click)="navigateExternal('https://psa-profissionaissa.activehosted.com/admin/')">Active</li>
    </ul>
  </div>

  <div class="sideBar__itemSpaced">
    <div *ngIf="perfil == 'ESPECTADOR'" class="sideBar__item fadeIn">
      <a
        class="alertBanner alertBanner--primary hoverTransformImg"
        [routerLink]="['/espectador/perfil']"
        [queryParams]="{tab: 'planos'}"
        title="Faça o upgrade do seu plano"
      >
        <h3 class="alertBanner__title">
          <img class="mr-1" alt="faça o upgrade do seu plano" src="assets/newIcons/iconUpgradePlan.svg" height="22"/>
          Upgrade de Plano
        </h3>
        <p class="alertBanner__text">
          Fazendo o upgrade do seu plano,
          você tem acesso a benefícios
          exclusivos.
        </p>
      </a>
    </div>

    <div *ngIf="itemsMenu" class="sideBar__item">
      <ul class="navSubItens">
        <h3 class="navSubItens__title">Institucional</h3>
        <li *ngFor="let menuItem of itemsMenu" class="navSubItens__link">
          <a (click)="open(menuItem.id)" href="javascript:void()" title="Ir para {{menuItem.text}}">
            {{menuItem.text}}
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="perfil == 'ESPECTADOR'" class="sideBar__item">
      <!-- [TODO] Melhorar tratamento de controle de visulização conforme perfil -->
      <!-- [TODO] Habilitar suporte para os outros perfis -->
      <button
        class="btn btnOutline btnOutline--tertiary w-100"
        (click)="support()"
        title="Clique para entrar em contato com o Atendimento Online"
        type="button"
      >
        <img alt="Whatsapp" src="assets/icons/whatsapp-black.svg" height="15" />
        Atendimento Online
      </button>
    </div>

    <div *ngIf="perfil == 'CONTRATANTE'" class="sideBar__item fadeIn">
      <a
        class="alertBanner"
        [routerLink]="['/espectador/perfil']"
        [queryParams]="{tab: 'planos'}"
        title="Faça o upgrade do seu plano"
      >
        <h3 class="alertBanner__title">
          <small>
            {{empresa}}
          </small>
        </h3>

        <p class="alertBanner__text d-flex align-items-center justify-content-between">
          <span *ngIf="status" title="Este é o status da sua empresa entre os clientes da Profissinais SA. Assim podemos atender melhor você de acordo com o seu perfil e sua evolução na nossa carteira de clientes.">
            <img
              class="hoverTransform"
              width="15"
              src="assets/icons/info.svg"
              alt="status"
            >
            {{status}}
          </span>

          <span *ngIf="pontuacao" title="Essa é a sua pontuação">
            <img
              class="hoverTransform"
              width="15"
              src="assets/icons/star.svg"
              alt="pontuação"
            >
            {{pontuacao}}
          </span>
        </p>
      </a>
    </div>

    <div class="sideBar__item">
      <div class="userFrame">
        <div class="userFrame__item userAvatar">
          {{usuario[0]}}
        </div>
        <div class="userFrame__item">
          <h3 class="userFrame__name">
            {{usuario}}
          </h3>
          <p class="userFrame__profile">
            Pefil: {{perfil}}
          </p>
        </div>
      </div>
    </div>
  </div>
</nav>
