<div aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="">

    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
      <button (click)="bsModalRef.hide()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="d-flex justify-content-center p-1">
      <div class="rounded col-12 div__iframe">

        <pdf-viewer style="display: inline;" [src]="link" [render-text]="true" [autoresize]="true"
          [original-size]="false" [zoom-scale]="'page-fit'">
        </pdf-viewer>

      </div>
    </div>
    <div class="text-center">
      <a class="text-dark" target="_blank" (click)="download()">Download</a>
    </div>
    <div class="modal-footer">
      <button (click)="close()" type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
    </div>

  </div>
</div>