<div *ngIf="canEdit" class="container-fluid p-5">
  <div class="row">
    <div class="listPalestrantes">
      <div
        class="listPalestrantesHeader flex flex-col w-100"
      >
        <div class="mt-3" style="display: flex; flex-direction: row; justify-content: space-between; width: 80%">
          <h3 class="font-title">Palestrantes</h3>
          <div class="cadastrarPalestrante" (click)="cadastrar()">
            <img src="../../../../assets/icons/add.svg" />&nbsp;Cadastrar
          </div>
        </div>

        <div style="display: flex; flex-direction: column;">
          <label>Filtrar por nome</label>
          <input
            type="text"
            placeholder="Digite um nome e pressione enter"
            (change)="filtrar($event)"
          />
        </div>
        <div style="display: flex; flex-direction: column;">
          <label>Filtrar por tipo</label>
          <select [(ngModel)]="filterType" (change)="filterByType()">
            <option [ngValue]="'palestrantes'">Palestrantes</option>
            <option [ngValue]="'tbsb'">Palestrantes - TBSB</option>
          </select>
        </div>
      </div>

      <div class="container w-full">
        <div class="row container__default mr-0 mr-md-3">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
            "
            *ngIf="loading"
          >
            Carregando palestrantes...
          </div>
          <table class="customTable" *ngIf="!loading">
            <thead>
              <th style="width: 100px">#</th>
              <th style="width: 200px">NOME</th>
              <th style="width: 200px">E-MAIL</th>
              <th style="width: 200px">TELEFONE</th>
              <th style="width: 300px">TEMAS</th>
              <!-- <th>CACHE</th> -->
              <th style="width: 100px">LOCAL</th>
              <th style="width: 100px" *ngIf="canEdit">AÇÃO</th>
            </thead>
            <tbody>
              <tr *ngFor="let p of palestrantes">
                <td>{{ p.id_usuario }}</td>
                <td>{{ p.nome_palestrante }}</td>
                <td>{{ p.email }}</td>
                <td>{{ p.telefone }}</td>
                <td>{{ p.tema }}</td>
                <!-- <td>{{ "-" }}</td> -->
                <td>{{ p.estado }}/{{ p.cidade }}</td>
                <td *ngIf="canEdit">
                  <img
                    src="../../../../assets/icons//editar.svg"
                    (click)="editarPerfil(p.id_usuario)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!canEdit">
  <p>Você não tem autorização para acessar essa rota!</p>
</div>
