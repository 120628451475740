import { Component, OnInit } from '@angular/core';
import { ContratanteService } from 'src/app/services/contratante';
import { estados } from 'src/app/common/array-estados';
import { segmentos } from 'src/app/common/array-segmentos';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalAlterarFotoComponent } from '../../modal-alterar-foto';
import { RdStationService } from 'src/app/services/rd-station/rd-station.service';
import { AppComponent } from 'src/app/app.component';
import { porteEmpresa } from 'src/app/common/array-porte-empresa';
import { ViacepService } from 'src/app/services/viacep';
import { IInvalid } from '../../palestrante/tb-dados';

export interface IUsuarioContratante {
  id_contratante?: number,
  quantidade_funcionario?: string,
  id_contratantes_tipos?: number,
  id: number,
  nome_completo: string,
  nome: string,
  sobrenome: string,
  nome_completo_razao_social?: string,
  empresa?: string,
  cpf_cnpj?: string,
  tipo_pessoa?: string,
  segmento?: string,
  cep?: string,
  bairro?: string,
  estado?: string,
  cidade?: string,
  pais?: string,
  logradouro?: string,
  complemento?: string,
  numero?: string,
  codigo_indicacao?: number,
  nome_completo_indicacao?: string,
  perfil_indicacao?: string
}

@Component({
  selector: 'app-tb-empresa',
  templateUrl: './tb-empresa.component.html',
  styleUrls: ['./tb-empresa.component.scss']
})
export class TbEmpresaComponent implements OnInit {
  bsModalRef: BsModalRef;

  arraySegmentos = segmentos;
  arrayEstados = estados;
  arrayPorte = porteEmpresa;
  isDisable: boolean = true;
  loading: boolean = false;
  usuario: any = {};
  mensagem: string = "";

  invalids: Array<IInvalid> = []
  defaultMessage: string = "Campo VALUE deve ser preenchido";

  constructor(
    private contratanteService: ContratanteService,
    private cepService: ViacepService,
    private toast: ToastrService,
    private modalService: BsModalService,
    private rdStationService: RdStationService,
    private app: AppComponent
  ) { }

  ngOnInit(): void {
    this.loading = false;
    this.isDisable = true;
    this.getUsuario()
  }

  async getUsuario() {
    this.usuario = await this.contratanteService.getUsuario();
    if (!this.usuario.quantidade_funcionario) this.usuario.quantidade_funcionario = null
    if (this.usuario.telefone?.length == 11) {
      const tel = this.usuario.telefone
      this.usuario.telefone = tel.replace(tel.substring(0, 3), tel.substring(0, 2))
    }

    this.validations()
    if (this.invalids.length > 0) this.isDisable = false
  }

  async completeCEP() {
    if (this.usuario.cep?.length === 8) {

      const initialState = { mensagem: "Completar endereço com CEP?", submensagem: "" }
      const config = { initialState }
      this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)

    } else {
      this.toast.error("CEP inválido.")
    }

  }

  editar() {
    this.isDisable = false;
  }

  async salvar() {
    this.invalids = []

    this.validations()

    if (this.invalids.length > 0) {
      this.toast.error("Campos inválidos!", "Por favor, revise.")
      return
    }

    try {
      this.loading = true;

      const body = Object.assign({
        id: this.usuario.id,
        email: this.usuario.email.toLowerCase().trim(),
        telefone: this.usuario.telefone.trim(),
        nome: this.usuario.nome.trim(),
        sobrenome: this.usuario.sobrenome.trim(),
        nome_completo_razao_social: this.usuario.nome_completo_razao_social?.trim(),
        empresa: this.usuario.empresa.trim(),
        cpf_cnpj: this.usuario.cpf_cnpj?.trim(),
        tipo_pessoa: 'JURIDICA',
        segmento: this.usuario.segmento.trim(),
        cep: this.usuario.cep.trim(),
        bairro: this.usuario.bairro.trim(),
        estado: this.usuario.estado.trim(),
        cidade: this.usuario.cidade.trim(),
        logradouro: this.usuario.logradouro.trim(),
        complemento: this.usuario.complemento?.trim(),
        numero: this.usuario.numero.trim(),
        pais: this.usuario.pais.trim(),
        id_contratante: this.usuario.id_contratante,
        quantidade_funcionario: this.usuario.quantidade_funcionario,
        id_contratantes_tipos: this.usuario.id_contratantes_tipos,
      })
      await this.contratanteService.updateContratante(body)

      this.rdStationService.formNewsletter(body)
      this.toast.success("Salvo com sucesso!")
      this.ngOnInit()
      this.app.refreshInfoGlobal()

    } catch (e) {
      this.loading = false;
      this.toast.error("Não foi possível salvar empresa.", e.error?.message)
    }
  }

  alterarFoto() {
    this.bsModalRef = this.modalService.show(ModalAlterarFotoComponent, {backdrop : 'static', keyboard : false});

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.success) this.getUsuario()
    })
  }

  async completaEnderecoComCEP() {

    try {
      const resCEP = await this.cepService.getCEP(this.usuario.cep)

      if (resCEP.logradouro) {
        this.usuario.bairro = resCEP.bairro
        this.usuario.complemento = resCEP.complemento
        this.usuario.cidade = resCEP.localidade
        this.usuario.logradouro = resCEP.logradouro
        this.usuario.bairro = resCEP.bairro
        this.usuario.estado = resCEP.uf
      } else {
        this.toast.error("Não foi possível preencher os campos com o CEP.", "Revise o CEP informado.")
      }
    } catch (error) {
      this.toast.error("Não foi possível preencher os campos com o CEP.", "Revise o CEP informado.")
    }
  }


  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }

  validations() {

    if (!this.usuario.quantidade_funcionario || this.usuario.quantidade_funcionario == "Selecione um porte") {
      this.usuario.quantidade_funcionario = null
      this.invalids.push({ campo: "quantidade_funcionario", erro: this.defaultMessage.replace("VALUE", "PORTE") })
    }

    if (!this.usuario.empresa || this.usuario.empresa.length == 0)
      this.invalids.push({ campo: "empresa", erro: this.defaultMessage.replace("VALUE", "NOME FANTASIA") })

    if (!this.usuario.telefone || this.usuario.telefone.length == 0)
      this.invalids.push({ campo: "telefone", erro: this.defaultMessage.replace("VALUE", "TELEFONE") })

    if (this.usuario.telefone && this.usuario.telefone.length < 10)
      this.invalids.push({ campo: "telefone", erro: "TELEFONE inválido" })

    if (!this.usuario.segmento || this.usuario.segmento.length == 0 || this.usuario.segmento == "Selecione um segmento")
      this.invalids.push({ campo: "segmento", erro: this.defaultMessage.replace("VALUE", "SEGMENTO") })

    if (!this.usuario.pais || this.usuario.pais.length == 0)
      this.invalids.push({ campo: "pais", erro: this.defaultMessage.replace("VALUE", "PAÍS") })

    if (!this.usuario.cep || this.usuario.cep.length == 0)
      this.invalids.push({ campo: "cep", erro: this.defaultMessage.replace("VALUE", "CEP") })

    if (this.usuario.cep && this.usuario.cep.length != 8)
      this.invalids.push({ campo: "cep", erro: "CEP inválido" })

    if (!this.usuario.estado || this.usuario.estado.length == 0)
      this.invalids.push({ campo: "estado", erro: this.defaultMessage.replace("VALUE", "ESTADO") })

    if (!this.usuario.cidade || this.usuario.cidade.length == 0)
      this.invalids.push({ campo: "cidade", erro: this.defaultMessage.replace("VALUE", "CIDADE") })

    if (!this.usuario.bairro || this.usuario.bairro.length == 0)
      this.invalids.push({ campo: "bairro", erro: this.defaultMessage.replace("VALUE", "BAIRRO") })

    if (!this.usuario.logradouro || this.usuario.logradouro.length == 0)
      this.invalids.push({ campo: "logradouro", erro: this.defaultMessage.replace("VALUE", "LOGRADOURO") })

    if (!this.usuario.numero || this.usuario.numero.length == 0)
      this.invalids.push({ campo: "numero", erro: this.defaultMessage.replace("VALUE", "NÚMERO") })

    if (!this.usuario.cpf_cnpj.length)
    this.invalids.push({ campo: "cpf_cnpj", erro: this.defaultMessage.replace("VALUE", "CNPJ") })

    if (this.usuario.cpf_cnpj.length > 0 && this.usuario.cpf_cnpj.length != 14)
      this.invalids.push({ campo: "cpf_cnpj", erro: "CNPJ inválido" })

  }
}
