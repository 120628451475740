<div class="chat">
    <div class="cabecalho d-flex">
        <img class="mr-3" height="40px" src="assets/icons/chat.svg">
        <div class="d-flex flex-column">
            <h2 class="title">Bate-papo</h2>
            <span class="subtitle">Acompanhe as últimas dúvidas do público</span>
        </div>
    </div>
    <div class="container__chat">
        <div class="d-flex content__chat__item" *ngFor="let item of chat">
            <figure>
                <img *ngIf="!item.foto" class="img__usuario" src="assets/icons/profile.svg">
                <img *ngIf="item.foto" class="img__usuario" src="{{item.foto}}">
            </figure>
            <div class="d-flex flex-column content__chat__item__text">
                <span class="nome__usuario">{{item.usuario}} |
                    {{item.datahora_pergunta | date:"h:mm a"}}</span>
                <span class="subtitle">{{item.perguntas}}</span>
            </div>
        </div>
    </div>
    <form (submit)="send()">
        <div class="form-group mb-0 d-flex">
            <input type="text" class="form-control" name="question" [(ngModel)]="question" placeholder="Digite...">
            <button type="submit" class="btn__submit" title="Enviar mensagem">
                <img src="assets/icons/send.svg" alt="enviar" title="Enviar mensagem">
            </button>
        </div>
    </form>
</div>