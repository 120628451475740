<section class="container-fluid p-3">

    <header>
        <h3 class="font-title">Selecione um colaborador</h3>
    </header>

    <div class="row">

        <div class="col-12 container__comunicacao">
            <ul class="list-group">
                <li class="list-group-item list__item">
                    <div style="width: 25px;"></div>
                    <div class="flex1">
                        <span class="flex1 font-title">Nome</span>
                    </div>
                    <div class="flex1">
                        <span class="font-title">E-mail</span>
                    </div>
                    <div class="flex1">
                        <span class="font-title">Cargo</span>
                    </div>
                </li>
                <div *ngIf="loading" class="d-flex justify-content-center">
                    <app-spinner></app-spinner>
                </div>

                <div *ngIf="!loading && users.length == 0">
                    <p class="empty">Nenhum usuário encontrado</p>
                </div>

                <li class="list-group-item list__item" *ngFor="let i of users">
                    <div>
                        <label class="container__checkbox">
                            <input type="checkbox" (change)="add(i)" [(ngModel)]="i.added">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <span class="list__item__text flex1">{{i.nome_completo}}</span>
                    <span class="list__item__text flex1">{{i.email}}</span>
                    <span class="list__item__text flex1">{{i.cargo}}</span>
                </li>
            </ul>
        </div>

        <div class="col-12 d-flex align-items-center justify-content-between flex-row-reverse mt-2">

            <button *ngIf="!loading" (click)="convert()" class="btn__primary">Confirmar</button>
            <button *ngIf="loading" class="btn__primary">
                <img src="assets/img/simple_loading.gif" alt="loading">
            </button>

            <nav *ngIf="qtdePages?.length > 1" aria-label="Páginas">
                <ul class="pagination justify-content-center">
                    <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                        <a class="page-link"> <i class='fa fa-chevron-left'></i></a>
                    </li>
                    <li (click)="changePage(i)" class="page-item" [class.font-weight-bold]="page == i"
                        *ngFor="let number of qtdePages, let i = index">
                        <a class="page-link">{{i+1}}</a>
                    </li>
                    <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                        <a class="page-link"> <i class='fa fa-chevron-right'></i></a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>
</section>