import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ISkill, TarefasService } from 'src/app/services/tarefas';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalPDFComponent } from 'src/app/common/modal-pdf';
import { ModalPlayComponent } from 'src/app/common/modal-play';
import { PalestrasService } from 'src/app/services/palestras';

@Component({
  selector: 'app-minha-trilha-streaming',
  templateUrl: './minha-trilha-streaming.component.html',
})
export class MinhaTrilhaStreamingComponent implements OnInit {
  bsModalRef: BsModalRef
  idSkill: number
  loading: boolean = false
  skill: ISkill
  noteText: string = ''
  notes: []
  selectedNote: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastrService,
    private taskService: TarefasService,
    private palestraService: PalestrasService,
    private titleService: Title,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idSkill = params['id']
      this.getSkill()
      this.getNotes()
    })
  }
  async getNotes() {
    this.notes = await this.palestraService.getAnotacoes(this.idSkill);
    this.noteText = '';
  }

  async getSkill() {
    try {
      this.loading = true
      this.skill = await this.taskService.getSkillById(this.idSkill)
      this.loading = false

      this.titleService.setTitle(`Minha Trilha - ${this.skill.habilidade_titulo}`)
    } catch (error) {
      this.toast.error("Falha ao carregar conteúdo")
      this.loading = false
    }
  }

  async registerNote() {
    try {
      await this.palestraService.postAnotacao(this.idSkill, this.noteText)
      this.toast.success("Anotação cadastrada com sucesso!")
      this.getNotes()
    } catch (error) {
      this.toast.error("Não foi possível cadastrar a anotação!")
    }
  }

  cancel(){
    this.selectedNote = null;
    this.noteText = '';
  }

  async updateNote() {
    try {
      const noteId = this.selectedNote.id;
      await this.palestraService.updateAnotacao(noteId, this.noteText)
      this.toast.success("Anotação atualizada com sucesso!")
      this.getNotes()
    } catch (error) {
      this.toast.error("Não foi possível atualizar a anotação!")
    }
  }

  async selectNote(note) {
    this.selectedNote = note;
    this.noteText = note.observacao;
  }

  async deleteNote(note) {
    try {
      await this.palestraService.deleteAnotacao(note.id)
      this.toast.success("Anotação deletada com sucesso!")
      this.getNotes()
    } catch (error) {
      this.toast.error("Não foi possível deletar a anotação!")
    }
  }

  async start() {
    try {
      if (this.skill.habilidade_status == "PENDENTE") {
        await this.taskService.updateStatus(this.skill.id_habilidade, "INICIADA")
      }
      this.router.navigate([`/desenvolvimento/habilidade/tarefa/${this.idSkill}`])
    } catch (error) {
      this.toast.error("Falha ao carregar conteúdo")
    }
  }

  indicarAmigo() {
    this.toast.info('Em desenvolvimento.', 'Aguarde novidades')
  }

  avaliarConteudo() {
    this.toast.info('Em desenvolvimento.', 'Aguarde novidades')
  }

  getTitlePDF(){
    return this.skill.habilidade_pdf_link.replace(/^.*?([^\\\/]*)$/, '$1');
  }

  openPDF() {
    const initialState = {
      link: this.skill.habilidade_pdf_link,
      title: this.skill.habilidade_titulo
    };

    const config = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(ModalPDFComponent, config);
  }

  downloadPDF() {
    window.open(this.skill.habilidade_pdf_link, "blank")
  }
}
