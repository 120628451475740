import { Component, OnInit } from '@angular/core';
import { ContratanteService } from '../../../services/contratante';
import { IOrder, arrayOrders } from 'src/app/common/array-filtro-colaboradores';
import { GrupoService } from '../../../services/grupo';
import { ICargo } from '../grupo-lideres'
import { IColaborador } from '../colaboradores'
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormColaboradorComponent } from '../modal-form-colaborador';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';

@Component({
  selector: 'app-tb-lider',
  templateUrl: './tb-lider.component.html',
  styleUrls: ['./tb-lider.component.scss']
})
export class TbLiderComponent implements OnInit {
  bsModalRef: BsModalRef;

  lideres: Array<ICargo>;
  grupos: Array<any> = [];
  page: number = 0;
  qtdePages: Array<number>;
  search: string = "";
  orders: Array<IOrder> = arrayOrders;
  order: string = this.orders[1].value;
  loading: boolean = false;

  constructor(
    private modalService: BsModalService,
    private contratanteService: ContratanteService,
    private grupoService: GrupoService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.loadColaboradores();
    this.getStartInfo();
  }



  async loadColaboradores() {
    try {
      const result = await this.contratanteService.getLideres(this.page, this.order, this.search);
      this.lideres = result.lideres ? result.lideres : [];
      this.setQtdePages(result.quantidade)

    } catch (error) {
    }
  }

  async getStartInfo() {
    const resGrupo = await this.grupoService.getGrupos()
      .catch(() => { return; });
    if (resGrupo && resGrupo.length) this.grupos = resGrupo;
  }

  adicionarLider() {
    let lider = {
      id_usuario: null,
      id_cargo: null,
      id_colaborador: null,
      id_contratante: null,
      id_espectador: null,
      id_grupo: null,
      grupo_nome: null,
      email: null,
      setor: null,
      cpf: null,
      nome_completo: null,
      responsavel_grupo: "SIM",
      cargo_nome: null,
      contratante_ativo: "SIM",
      removable: 0
    }
    this.editar(lider)
  }

  editar(colaborador: IColaborador) {
    const initialState = { colaborador: colaborador };
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalFormColaboradorComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.success) this.loadColaboradores()
    })
  }

  async excluir(id_colaborador: number) {
    const initialState = { mensagem: "Deseja realmente excluir?", submensagem: "" }

    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(async () => {

      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value
      value == true ? true : false;

      if (value == true) {

        try {
          this.loading = true
          await this.contratanteService.deleteColaborador(id_colaborador);
          this.loadColaboradores()
          this.loading = false

        } catch (error) {
          this.loading = false
          this.toast.error("Não foi possível deletar colaborador!")
        }
      }
    })
  }

  // async excluirCollection() {
  //   const initialState = { mensagem: "Deseja realmente excluir?", submensagem: "" }

  //   const config = { initialState }
  //   this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)

  //   let newSubscriber = this.modalService.onHide.subscribe(async () => {

  //     newSubscriber.unsubscribe();
  //     let value = this.bsModalRef.content.value
  //     value == true ? true : false;

  //     if (value == true) {
  //       try {

  //         this.loading = true
  //         await this.contratanteService.deleteColaboradorCollection();
  //         this.loadColaboradores()
  //         this.loading = false

  //       } catch (error) {
  //         this.loading = false
  //         this.toast.error("Não foi possível deletar colaborador!")
  //       }
  //     }
  //   })
  // }

  async setGrupo(lider: IColaborador) {
    if (!(lider.id_grupo > 0)) return;

    try {
      await this.contratanteService.setLiderGrupo(Object.assign({
        id_espectador_lider: lider.id_espectador,
        id_colaborador: lider.id_colaborador,
        id_grupo: lider.id_grupo
      }))

      this.toast.success("Tutor salvo com sucesso!")
      this.loadColaboradores();

    } catch (e) {
      this.toast.error("Ocorreu um erro ao salvar tutor")
    }
  }

  changePage(number: number) {
    this.page = number;
    this.loadColaboradores()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array(qtdeColaboradores);
  }

  buscar() {
    this.page = 0
    this.loadColaboradores()
  }

  changeOrder() {
    this.page = 0
    this.loadColaboradores()
  }
}

