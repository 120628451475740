import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { PalestrantesService } from "src/app/services/palestrantes";

@Component({
  selector: 'app-confirmar-indicacao',
  templateUrl: './confirmar-indicacao.component.html',
  styleUrls: ['./confirmar-indicacao.component.scss'],
})
export class ConfirmarIndicacaoComponent implements OnInit {
  loading:boolean =  false;
  showError: boolean = false;

  speakerId: string;
  proposalId: string;

  constructor(
    private router: ActivatedRoute,
    private palestrantesService: PalestrantesService,
    private toaster: ToastrService,

  ) {}

  ngOnInit(): void {
    this.speakerId = this.router.snapshot.paramMap.get('speakerId');
    this.proposalId = this.router.snapshot.paramMap.get('proposalId');

    this.sendConfirmation();
  }

  sendConfirmation(): void {
    this.loading = true;
    this.palestrantesService.confirmarIndicacao(this.speakerId, this.proposalId).subscribe(
      (result: any) => {
        console.log(result);
        this.loading = false;
      },
      (data: any) => {
        this.toaster.error('Ocorreu um erro ao confirmar a indicação');
        this.loading = false;
        this.showError = true;
      }
    );
  }
}
