<div class="modal_wrapper">
  <div class="modal_content">
    <p><b>TERMOS DE USO DE DADOS</b></p>

    <p>Este Termo de Uso de Dados aplica-se a todos os usuários que se cadastram no site ou serviços da PROFISSIONAIS SA - CURADORIA DE PALESTRAS LTDA., pessoa jurídica de direito privado inscrita no CNPJ sob o n° 11.324.248/0001-24, com sede na Av. Ipiranga, nº 6681, edifício 99A, Sala 601, Bairro Partenon - Porto Alegre/RS - CEP 90.619-900 (doravante denominada "Empresa").</p>

    <p>Ao realizar o cadastro em nossa plataforma, você concorda em fornecer dados pessoais verdadeiros e atualizados, necessários para a prestação de nossos serviços.</p>

    <p>Os dados coletados serão utilizados exclusivamente para os seguintes fins: melhoria contínua dos serviços oferecidos pela Empresa, envio de conteúdos relacionados a palestras, palestrantes e infoprodutos, divulgações e promoções de eventos e produtos organizados ou recomendados pela Empresa.</p>

    <p>A Empresa compromete-se a não compartilhar seus dados com terceiros, exceto quando necessário para a prestação dos serviços mencionados ou por exigência legal.</p>

    <p>A Empresa adota medidas de segurança para proteger seus dados contra acesso não autorizado, alteração, divulgação ou destruição.</p>

    <p>o se cadastrar, você expressa sua concordância clara e inequívoca com este Termo de Uso de Dados, autorizando a Empresa a utilizar seus dados conforme descrito.</p>

    <p>Este Termo pode ser atualizado periodicamente. A continuidade do uso dos serviços após tais mudanças constituirá sua aceitação das mesmas.</p>

    <p>Em caso de dúvidas relacionadas a este Termo, por favor, entre em contato através do e-mail contato@profissionaissa.com.</p>

    <p>Ao clicar em "Aceito", você concorda com todos os termos e condições aqui estabelecidos.</p>
  </div>

  <div class="button_container">
    <button (click)="aceitar()">Aceitar</button>
  </div>
</div>
