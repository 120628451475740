<div class="content__default p-4 pm-md-5">
    <form class="row">
        <div class="col-12 d-flex justify-content-between">
            <h5 class="font-title text-uppercase">{{id > 0 ? 'Editar ' : 'Adicionar '}}
                conteúdo
            </h5>
        </div>
        <div class="form-group col-12">
            <label class="label-input" for="descricao">Nome *</label>
            <input id="descricao" [class.invalid]="isIncorrect('descricao')" class="form-control input__default"
                name="descricao" type="text" [(ngModel)]="competencia.descricao" placeholder="Nome da competência *"
                maxlength=100 [ngModelOptions]="{standalone: true}">
            <span class="error">{{getError('descricao')}}</span>
        </div>
        <div class="form-group col-12">
            <label class="label-input" for="resumo">Descrição *</label>
            <ckeditor name="resumo" [(ngModel)]="competencia.resumo"></ckeditor>
            <span class="error ">{{getError('resumo')}}</span>

        </div>

        <div class="col-12">
            <div class="d-flex pt-2 pb-2 div__btn__salvar">

                <button (click)="bsModalRef.hide()" type="button" class="btn__secondary mr-3"
                    title="Cancelar">CANCELAR</button>

                <button *ngIf="!loading" (click)="submit()" class="btn__primary">Salvar</button>

                <button *ngIf="loading" class="btn__primary mt-2">
                    <img height="35px" src="assets/img/simple_loading.gif" alt="carregando">
                </button>
            </div>
        </div>
    </form>
</div>