import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
// import { Socket } from 'ngx-socket-io';
import { LiveEspectadorService } from 'src/app/services/live-espectador';
import { AppUrlEnum, ModalCertificadoComponent } from '../modal-certificado';

export interface IRating {
  libera_avaliacao: number
  validade_avaliacao: string
}

export interface IChat {
  datahora_pergunta: string
  datahora_resposta: string
  foto: string
  id: number
  palestras_id: number
  perguntas: string
  respondido: number | null
  respostas: string | null
  usuario: string
  usuario_id: number
}

export interface IQuiz {
  id: number
  opcao1: string
  opcao2: string
  opcao3: string
  opcao4: string
  pergunta: string
  respondido: boolean
}

export interface IPDF {
  ativo: number
  caminho: string
  data_cadastro: string
  id: number
  id_palestras: number
  pagina_atual: number
  total_pagina: number
}
export interface IVideo {
  autor: string
  link: string
  titulo: string
  video_codigo: string
}

interface IInfo {
  result: boolean
  avaliacao: IRating
  perguntas: Array<IChat>
  quiz: Array<IQuiz>
  status: string //"CONCLUIDA"
  videos: Array<IVideo>
  item: IPDF
}

@Component({
  selector: 'app-live-espectador',
  templateUrl: './live-espectador.component.html',
  styleUrls: ['./live-espectador.component.scss']
})
export class LiveEspectadorComponent implements OnInit, OnDestroy {
  bsModalRef: BsModalRef;

  @ViewChild("player", { static: true }) playerEl: ElementRef;
  player: any;

  idPalestra: number;
  palestra: any;

  isOnline: boolean = false

  pdf: any;
  pdfPage: number = 1;

  live: any;
  chat: Array<IChat> = []

  hasVideo: boolean = false
  hasQuiz: boolean = false
  hasRating: boolean = false

  showInteraction: "CHAT" | "QUIZ" | "TERMOMETRO" = "CHAT"

  showMessageCertificate: boolean = false
  isIOS: boolean = false
  isAndroid: boolean = false

  public appUrlEnum = AppUrlEnum;
  private interval: any;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastrService,
    private liveEspectadorService: LiveEspectadorService,
    private titleService: Title,
    // public socket: Socket,
  ) { }

  async ngOnInit() {
    this.titleService.setTitle("Dashboard - Live")

    let userAgent = navigator.userAgent
    if (userAgent.includes("Android") && userAgent.includes("Mobile")) this.isAndroid = true
    if (userAgent.includes("Ios") || userAgent.includes("iPhone") && userAgent.includes("Mobile")) this.isIOS = true

    this.idPalestra = parseInt(this.route.snapshot.paramMap.get("id"))
    if (!this.idPalestra) {
      this.toast.error("Erro ao carregar live")
      window.history.back()
      return
    }
    this.startBambuser()
    // this.startSocket()
    await this.getPalestra()
    this._verifyHasLiveFinished()

    this.loadInfo();

    this.liveEspectadorService.setWatched(this.idPalestra)
  }

  ngOnDestroy(): void {
    clearInterval(this.interval)
  }

  async startSocket() {
    // this.socket.connect();

    // const user = JSON.parse(localStorage.getItem("usuario"))
    // let name = `${user.nome} ${user.sobrenome}`
    // this.socket.emit('set-name', name);

    // this.socket.fromEvent('start').subscribe(data => { });
    // this.socket.fromEvent('finish').subscribe((data: { id }) => { if (data.id == this.idPalestra) this._verifyHasLiveFinished() });

    // this.socket.fromEvent('termometro').subscribe(data => {
    //   if (data['show'] == true) this.showInteraction = "TERMOMETRO"
    // });

    // this.socket.fromEvent('pdf-apoio').subscribe(data => {
    //   this.pdf = data['url']
    //   this.pdfPage = data['currentPage']
    // });
  }

  async getPalestra() {
    try {
      this.palestra = await this.liveEspectadorService.getPalestraById(this.idPalestra)
      this.titleService.setTitle("Live - " + this.palestra.titulo)
    } catch (error) {
      this.toast.error("Não foi possível carregar os dados da palestra")
    }
  }

  private async _verifyHasLiveFinished(): Promise<any> {
    await this.getPalestra()
    const { status = "EXECUTANDO" } = this.palestra;
    if (!this.isOnline && status == "EXECUTANDO") {
      this.play()
      this.isOnline = true
    } else if (status != "EXECUTANDO") {
      this.isOnline = false
      this.pause()
    }
    if (status === "CONCLUIDA" && !this.showMessageCertificate) {
      this.messageCertificate()
      this.isOnline = false
      this.router.navigate([`/espectador/palestras-gravadas`])
    }
    return status === "CONCLUIDA";
  }

  startBambuser() {
    const BambuserPlayer: any = window["BambuserPlayer"];
    let resourceUri = null;
    if (!resourceUri)
      resourceUri = "https://cdn.bambuser.net/groups/91864/broadcasts?by_authors=&title_contains=&has_any_tags=&has_all_tags=&da_id=e554b3e7-1c5c-ca4b-4274-76591d7c87e0&da_timestamp=1606340127&da_signature_method=HMAC-SHA256&da_ttl=0&da_static=1&da_signature=a1f45851610a8fba089be4d1e5aeca3c373a7b0480127a4a8f63e8baa905dd15";

    const player = BambuserPlayer.create(
      this.playerEl.nativeElement,
      resourceUri
    );
    player.controls = true;
    this.player = player;
  }

  async loadInfo() {
    try {
      let res: IInfo = await this.liveEspectadorService.getInfo(this.idPalestra)

      if (res && res.result == true) {

        const { perguntas, item } = res

        if (perguntas) this.chat = perguntas
        if (item) {
          this.pdf = item.caminho
          this.pdfPage = item.pagina_atual
        } else {
          this.pdf = null
          this.pdfPage = 0
        }
      }

    } catch (error) {
    }
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  setHasQuiz(event: any) {
    if (!event) {
      this.hasQuiz = true
      return
    }
    this.resetShow()
    this.hasQuiz = false
  }

  resetShow() {
    this.showInteraction = "CHAT"
  }

  setShow(value: "CHAT" | "QUIZ" | "TERMOMETRO") {
    this.showInteraction = value
  }

  messageCertificate() {
    this.showMessageCertificate = true
    const initialState = {
      isIOS: this.isIOS,
      isAndroid: this.isAndroid
    };
    const config = { class: 'modal-login', initialState }
    this.bsModalRef = this.modalService.show(ModalCertificadoComponent, config);
    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
    })
  }

  goApp() {
    window.open(this.isIOS ? this.appUrlEnum.apple : this.appUrlEnum.google, "_blank");
  }
}
