import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Configuration, OpenAIApi } from "openai";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class OpenaiService {
  constructor(
    private http: HttpClient,
  ) {}

  readonly configuration = new Configuration({
    apiKey: environment.openAiToken
  });
  readonly openai = new OpenAIApi(this.configuration);

  async getDataFromOpenAPI(text: string) {
    const completion = await this.openai.createCompletion({
      model: "gpt-3.5-turbo-16k",
      prompt: text,
      temperature:0.9,
      max_tokens:7000,
      stop:["stop"]
    });
    console.log('choices', completion.data.choices);
    return completion.data.choices[0].text;
  }

  async getChatData(content: string) {
    const chatCompletion = await this.openai.createChatCompletion({
      model: "gpt-3.5-turbo-16k",
      messages: [{role: "user", content }],
    });

    return chatCompletion.data.choices[0].message?.content;
  }

  promptDefesa(speaker: string, theme: string, client: string): string {
    return `Defenda ${speaker} para uma palestra de ${theme} para a empresa ${client} e formate o texto de acordo com a estrutura a seguir:

    Pontos Relevantes:
    Gostaria de compartilhar os motivos pelos quais acredito que ${speaker} é a escolha certa para essa ação. Destaco três pontos importantes:
    a) [Destaque 1]: [Texto descrevendo o destaque 1 do ${speaker}].
    b) [Destaque 2]: [Texto descrevendo o destaque 2 do ${speaker}].
    c) [Destaque 3]: [Texto descrevendo o destaque 3 do ${speaker}].`
  }

  promptCompareSpeakers(speakers: string): string {
    return `Compare os palestrantes ${speakers}`
  }

  getProfileByName(name: string): Promise<any> {
    return this.http.get(`${environment.API_V2_URL}proposal/profile/${name}`).toPromise();
  }

  getDbSpeakers(theme: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    return this.http.get(`${environment.API_V2_URL_DEFAULT}palestrantes/${theme}`, { headers });
  }
}
