<section class="container-fluid px-1 px-md-5">

    <header class="d-flex align-items-center justify-content-between mb-1">
        <h1 class="font-title mb-2">Comunicação interna</h1>
        <button [routerLink]="['/comunicacao-interna']" class="btn__primary">nova comunicação</button>
    </header>

    <div class="row">
        <div class="col-12 container__comunicacao">

            <div class="container__search">
                <input type="text" class="input__default form-control" [(ngModel)]="search"
                    (ngModelChange)="prepareFilter()" placeholder="Buscar...">
            </div>

            <ul class="list-group">

                <li class="list-group-item list__item">
                    <div class="flex1">
                        <span class="flex1 font-title">Data de envio</span>
                    </div>
                    <div class="flex1">
                        <span class="flex1 font-title">Título</span>
                    </div>
                    <div class="flex1">
                        <span class="flex1 font-title">Mensagem</span>
                    </div>
                    <div class="flexLittle">
                        <span class="font-title">Qtde. usuários</span>
                    </div>
                    <div class="flex1">
                        <span class="font-title">Status</span>
                    </div>
                </li>

                <div *ngIf="loading" class="d-flex justify-content-center">
                    <app-spinner></app-spinner>
                </div>

                <li *ngIf="!loading && history.length == 0">
                    <p class="empty">Nenhuma comunicação conlcuída</p>
                </li>

                <li class="list-group-item list__item" *ngFor="let i of getHistory()">
                    <span class="list__item__text flex1">{{i.schedule_date}}</span>
                    <span class="list__item__text flex1">{{i.message.notification.title}}</span>
                    <span class="list__item__text flex1">{{i.message.notification.body.substring(0, 40)}}</span>
                    <span class="list__item__text flexLittle">
                        {{i.lengthSuccess ? i.lengthSuccess : 'Indefinido'}}
                    </span>
                    <span style="text-transform: capitalize;" class="list__item__text flex1">
                        {{i.status == 2 ? 'Mensagem Enviada' : 'Indefinido'}}
                    </span>
                </li>
            </ul>
        </div>

        <div class="col-12 d-flex align-items-center justify-content-between mt-2">

            <nav *ngIf="qtdePages?.length > 1" aria-label="Páginas">
                <ul class="pagination justify-content-center">
                    <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                        <a class="page-link"> <i class='fa fa-chevron-left'></i></a>
                    </li>
                    <li (click)="changePage(i)" class="page-item" [class.font-weight-bold]="page == i"
                        *ngFor="let number of qtdePages, let i = index">
                        <a class="page-link">{{i+1}}</a>
                    </li>
                    <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                        <a class="page-link"> <i class='fa fa-chevron-right'></i></a>
                    </li>
                </ul>
            </nav>
        </div>

    </div>

</section>