import { Component, Input, OnInit } from "@angular/core";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ToastrService } from "ngx-toastr";
import { PalestrantesService } from "src/app/services/palestrantes";

@Component({
  selector: 'app-repositorio-admin',
  templateUrl: './repositorio-admin.component.html',
  styleUrls: ['./repositorio-admin.component.scss'],
})
export class RepositorioAdminComponent implements OnInit {
  @Input('palestrante') palestrante: any;

  repositories: any = [];
  isEdit: boolean = false;

  htmlContent: any = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    showToolbar: true,
    enableToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    private palestranteService: PalestrantesService,
    private toast: ToastrService,
  ) {}

  ngOnInit(): void {
    console.log(this.palestrante);
    this.getRepositories();
  }

  async getRepositories(): Promise<void> {
    try {
      const [res] = await this.palestranteService.getRepositories(this.palestrante.id);

      if(res) {
        this.isEdit = true;
      }

      this.repositories = res;
      this.htmlContent = res?.content;
    } catch(e) {
      return;
    }
  }

  preSend(): void {
    console.log('hi', this.isEdit);
    if(this.isEdit) {
      this.edit();
    } else {
      this.submit();
    }
  }

  async edit(): Promise<void> {
    try {
      const body = {
        content: this.htmlContent,
      };

      const res = await this.palestranteService.editRepositories(this.palestrante.id, body);
      this.toast.success('Repositório salvo com sucesso!');
    } catch (e) {
      this.toast.error("Não foi possível completar a solicitação, tente novamente mais tarde.");
    }
  }

  async submit(): Promise<void> {
    try {
      const body = {
        content: this.htmlContent,
      };

      const res = await this.palestranteService.createRepositories(this.palestrante.id, body);
    } catch (e) {
      this.toast.error("Não foi possível completar a solicitação, tente novamente mais tarde.");
    }
  }
}
