import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PropostasService } from 'src/app/services/propostas';
import { currencyFormat, getDate } from 'src/app/utils/formatters/string.formatter';
import { environment } from 'src/environments/environment';
import { ngxCsv } from 'ngx-csv';

@Component({
  selector: 'app-palestras',
  templateUrl: './palestras.component.html',
  styleUrls: ['./palestras.component.scss']
})
export class PalestrasComponent implements OnInit {

  filter: any = {
    start: null,
    end: null,
    seeAll: false,
    curador: '',
  };
  filterName: string;


  curadores: any = [];

  proposals: any = [];
  allProposals: any = [];
  ownerProposals: any = [];

  proposalsCount: number = 0;
  averageComission: number = 0;

  curadorId: number;
  isAdmin: boolean;
  isLoading: boolean = false;

  //table pagination
  pages: number;
  currentPage: number = 0;

  // csv
  headers: string[] = [
    'Data de criação',
    'Contratante',
    'Email Contratante',
    'Curador',
    'Briefing',
    'Validade',
    'Forma de Pagamento',
    'Principal',
    'Palestrantes',
    'Valor de Custo',
    'Valor de Venda',
    'Última Atualização',
  ]

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Planilha de propostas geradas',
    useBom: true,
    noDownload: false,
    headers: this.headers,
    eol: '\n'
  };

  constructor(
    private proposalsService: PropostasService,
    private clipboard: Clipboard,
    private toast: ToastrService,
  ) { }

  ngOnInit(): void {
    this.curadorId = JSON.parse(localStorage.getItem('usuario')).id;
    const perfil = JSON.parse(localStorage.getItem('usuario')).perfil;
    this.isAdmin = (perfil === 'admin' || perfil === 'avaliador') || perfil === 'gestor';

    this.getCuradoresNames();
    this.getAllProposals();
    this.filter = {
      start: null,
      end: null,
      curador: '',
    }
  }

  getCuradoresNames(): void {
    this.proposalsService.getCuradores().subscribe(
      (result: any) => {
        this.curadores = result?.body;
      }
    );
  }

  getAllProposals(): void {
    this.isLoading = true;
    try {
      this.proposalsService.getAllProposals()
        .then((res) => {
          res.forEach((proposal) => {
            proposal.isOwner = proposal.userId === this.curadorId;
          });

          this.allProposals = res;

          this.parseData();
          this.isLoading = false;
        })
    } catch (e) {
      this.toast.error('Ocorreu um erro ao tentar recuperar as propostas, tente novamente mais tarde.');
      this.isLoading = false;
    }
  }

  parseData(): void {
    this.allProposals.forEach((proposal: any) => {
      proposal.validade = new Date(proposal.validade).toLocaleDateString();
      proposal.createdAt = new Date(proposal.createdAt).toLocaleDateString();
      proposal.palestrantes = this.parseSpeakerName(proposal.palestrantes.split(','));
      proposal.valorCusto = this.parseCostValue(proposal.valorCusto);
      proposal.valorVenda = this.parseFinalValue(proposal.valorVenda.split(','));
      proposal.formaPagamento = this.parseProposalType(proposal.formaPagamento);
      proposal.updated_at = proposal.updated_at ? new Date(proposal.updated_at).toLocaleDateString() : '--';
    });

    this.proposals = this.allProposals.filter((proposal) => proposal.isOwner);
    this.ownerProposals = this.proposals;
    this.proposalsCount = this.proposals.length;
  }

  parseCostValue(stringValue: string): Array<string> {
    const currencyStr = stringValue.replace(/([^,]+,[^,]+),/g,'$1;').split(';');
    const currencyArr = currencyStr.map((value: string) => parseInt(value.replace(/[\D]+/g,'')) / 100);
    return currencyArr.map((currency: number) => currencyFormat(currency));
  }

  parseFinalValue(finalValueArr): Array<string> {
    return finalValueArr.map((value: string) => currencyFormat(+value));
  }

  parseSpeakerName(speakers: Array<string>): Array<string> {
    return speakers.map((speaker: string) => speaker.replace(/[-]/g, ' '));
  }

  parseProposalType(type: string): string {
    let proposalType: string = type;
    switch(type) {
      case 'singleCNPJ':
        proposalType = 'CNPJ único';
        break;
      case 'multipleCNPJ':
          proposalType = 'Múltiplos CNPJs';
          break;
      case 'gov':
          proposalType = 'Proposta para Órgão Governamental';
          break;
      default:
          proposalType = type;
          break;
    }

    return proposalType;
  }

  showAll() : void {
    if (this.filter.seeAll) {
      this.proposals = this.allProposals;
    } else {
      this.proposals = this.ownerProposals;
    }
    this.proposalsCount = this.proposalsCount;
  }

  filterByName() {
    this.proposals = this.allProposals.filter((proposal: any) => proposal.nomeEmpresa?.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase()));
  }

  filterByCuradorType(): void {
    switch(this.filter.curador) {
      case 'a':
        this.proposals = this.allProposals.filter(({ curadorType }) => curadorType === 'curadora');
        break;
      case 'b':
        this.proposals = this.allProposals.filter(({ curadorType }) => curadorType === 'curadorb');
        break;
      case 'c':
        this.proposals = this.allProposals.filter(({ curadorType }) => curadorType === 'curadorc');
        break;
      case 'admin':
        this.proposals = this.allProposals.filter(({ curadorType }) => curadorType === 'admin');
        break;
      default:
        this.proposals = this.allProposals;
        break;
    }
  }

  filterByCurador(): void {
    // this.proposals = this.allProposals.filter(({ curador }) => curador.toLowerCase().includes(this.filter.curador.toLowerCase()));
    this.proposals = this.allProposals.filter((proposal) => proposal.userId == this.filter.curador);
  }

  filterProposals(): void {
    console.log(this.filter.seeAll);
    if (this.filter.start && this.filter.end) {
      this.proposals = this.proposals.filter(({ createdAt }) => moment(getDate(createdAt)).isSameOrAfter(moment(this.filter.start)) && moment(getDate(createdAt)).isSameOrBefore(moment(this.filter.end)));
    } else {
      this.proposals = this.allProposals.filter(({ createdAt }) => moment(getDate(createdAt)).isSameOrAfter(moment(this.filter.start)) && moment(getDate(createdAt)).isSameOrBefore(moment(this.filter.end)));
    }

    this.proposalsCount = this.proposals.length;
  }

  resetFilter(): void {
    this.proposals = this.ownerProposals;
    this.filter = {
      start: null,
      end: null,
      showAll: false,
    }

    this.proposalsCount = this.proposals.length;
  }

  calcPrincipalValue(): number {
    let totalValue = 0;
    this.proposals.forEach((proposal: any) => {
      if (proposal?.comission) {
        totalValue += Number(proposal.comission);
      }
    });
    return totalValue;
  }

  editProposal(proposalId: number, isOwner: boolean): void {
    if (isOwner) {
      window.open(
        `${environment.GERADOR}proposta/edit/${proposalId}`,
        '_blank'
      );
    } else {
      this.toast.error('Você não pode editar propostas de outros usuários!');
    }
  }

  downloadProposal(pdf: string): void {
    // if (!pdf || pdf === '') {
    //   this.toast.error('Não foi possível fazer o download da proposta.');
    // } else {
    //   let link = document.createElement('a');
    //   link.href = pdf;
    //   link.download = 'proposta.pdf';
    //   link.dispatchEvent(new MouseEvent('click'));
    // }
    window.open(`https://gerador.profissionaissa.com/download-pdf/${pdf}`, '_blank')
  }

  copyToClipboard(pdf: string): void {
    if (pdf) {
      this.clipboard.copy(pdf);
      this.toast.success('Link copiado com sucesso!');
    } else {
      this.toast.error('Não foi possível copiar o link.');
    }
  }

  downloadCSV(): void {
    const data = this.proposals.map((proposal: any) => {
      return {
        createdAt: proposal.createdAt,
        nomeEmpresa: proposal.nomeEmpresa,
        email: proposal.email,
        curador: proposal.curador,
        briefing: proposal.briefing,
        validade: proposal.validade,
        formaPagamento: proposal.formaPagamento,
        principal: proposal.principal,
        palestrantes: proposal.palestrantes.join(';'),
        valorCusto: proposal.valorCusto.join(';'),
        valorVenda: proposal.valorVenda.join(';'),
        updatedAt: proposal.updated_at
      }
    });

    console.log(data);
    const currDate = new Date().toLocaleDateString();
    new ngxCsv(data, `planilha_propostas_geradas_${currDate}`, this.options);
  }
}
