<section class="profile">
    <div class="container">
        <div class="row justify-content-center">
            <header class="col-12 my-5">
                <h1 class="font-title text-center">Escolha seu perfil</h1>
            </header>

            <div *ngIf="defaultProfile == option.CONTRATANTE" class="col-12 col-md-4 mt-4 mt-md-0 content__opction">
                <div (click)="select(option.CONTRATANTE)" class="d-flex flex-column align-items-center"
                    [class.profile__default]="defaultProfile == option.CONTRATANTE">
                    <figure>
                        <img height="40px" src="assets/icons/company.svg" alt="contratante">
                    </figure>
                    <h3 class="font-title">Contratante</h3>
                </div>
            </div>
            <div class="col-12 col-md-4 mt-4 mt-md-0 content__opction">
                <div (click)="select(option.ESPECTADOR)" class="d-flex flex-column align-items-center"
                    [class.profile__default]="defaultProfile == option.ESPECTADOR">
                    <figure>
                        <img height="40px" src="assets/icons/viewer.svg" alt="espectador">
                    </figure>
                    <h3 class="font-title">Espectador</h3>
                </div>
            </div>
            <div *ngIf="defaultProfile == option.PALESTRANTE" class="col-12 col-md-4 mt-4 mt-md-0 content__opction">
                <div (click)="select(option.PALESTRANTE)" class="d-flex flex-column align-items-center"
                    [class.profile__default]="defaultProfile == option.PALESTRANTE">
                    <figure>
                        <img height="40px" src="assets/icons/lecture.svg" alt="palestrante">
                    </figure>
                    <h3 class="font-title">Palestrante</h3>
                </div>
            </div>
        </div>
    </div>
</section>