import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner';
import { CheckValidacaoSenhaComponent } from './check-validacao-senha';
import { PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [SpinnerComponent, CheckValidacaoSenhaComponent],
  imports: [
    CommonModule,
    PdfViewerModule,
  ],
  exports: [SpinnerComponent, CheckValidacaoSenhaComponent]
})
export class ExportComponentsModule { }
