import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-precos',
  templateUrl: './precos.component.html',
  styleUrls: ['./precos.component.scss']
})
export class PrecosComponent implements OnInit {

  prices: any = [];

  newPrices: any = [];

  constructor(
    private adminService: AdminService,
    private toast: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getPricesData();
  }

  getPricesData(): void {
    this.adminService.getPrices()
    .then(res => {
      console.log(res);
      this.prices = res;
      this.newPrices = res;
    })
    .catch(e => {
      this.toast.error('Não foi possível recuperar a tabela de preços');
    })
  }

  editValue(id: number) {
    console.log(this.newPrices);
  }

  savePrices() {
    this.newPrices.forEach(({
      percentage_multiple,
      percentage_single,
      value_range,
      value_title,
      id
    }) => {
      this.adminService.editPrices({
        percentage_multiple,
        percentage_single,
        value_range,
        value_title,
      }, id)
      .then((res) => {
        console.log('edited', id);
      })
      .catch(e => {
        console.log(e);
      });
    });
  }
}
