<div
style="
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;" *ngIf="loading"
>
  <img src="assets/img/psaLoading.svg" alt="Carregando" title="Carregando" />
</div>

<main *ngIf="!loading" class="mt-5">
  <h1 class="ml-3">Resumo de uso - QR Code</h1>
  <div>
    <div class="filterContainer">
      <section>
        <label for="speakerFilter">Filtrar por palestrante (nome)</label>
        <input name="speakerFilter" id="speakerFilter" [(ngModel)]="filterSpeaker" [readonly]="filterCS !== ''" />
      </section>
      <section class="read__only">
        <label for="csFilter">Filtrar por CS responsável (email)</label>
        <input name="csFilter" id="csFilter" [(ngModel)]="filterCS" [readonly]="filterSpeaker !== ''" />
      </section>
    </div>

    <div class="filterContainer">
      <section>
        <label for="dateStart">Data Início</label>
        <input id="dateStart" name="dateStart" type="date" [(ngModel)]="filterStart" />
      </section>
      <section>
        <label for="dateEnd">Data Fim</label>
        <input id="dateEnd" name="dateEnd" type="date" [(ngModel)]="filterEnd" />
      </section>

      <section>
        <button type="button" (click)="filter()">Filtrar</button>
      </section>
      <section>
        <button type="button">Resetar Filtro</button>
      </section>
    </div>

    <div *ngIf="!loading && (summary?.email || summary?.speakerName)">
      <table class="customTable">
        <thead>
          <th>Curador/Palestrante</th>
          <th>Público esperado</th>
          <th>Quantidade de Respostas</th>
          <th>Média de respostas (%)</th>
          <th>Média de respostas (numérico)</th>
          <th>Quantidade de eventos</th>
        </thead>

        <tbody>
          <tr>
            <td>{{ summary?.email || summary.speakerName }}</td>
            <td>{{ summary.participants }}</td>
            <td>{{ summary.answers }}</td>
            <td>{{ summary.percentageAvg }}%</td>
            <td>{{ summary.numericalAvg }}</td>
            <td>{{ summary.eventsCount }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="!loading && (summary?.events)" class="list__container">
      <h4 class="mt-4">Eventos filtrados</h4>

      <ul>
        <li *ngFor="let event of summary.events">{{ event }}</li>
      </ul>
    </div>
  </div>
</main>
