<!-- <div *ngIf="!loading" class="container">
  <div class="pesquisa__ampla">
    <input id="searchInput" [(ngModel)]="searchInput"  placeholder="O que você quer buscar hoje, curador?" />
    <button (click)="generalSearch()">COMEÇAR</button>
  </div>

  <div class="main__searchs">
    <div class="left__container">
      <p>Pesquisas Rápidas</p>
      <div class="pesquisas__rapidas">
        <div class="card justify-content-between">
          <p>5 pontos importantes sobre um tema</p> <br>
          <input type="text" [(ngModel)]="searchImportant" placeholder="digite o tema" />
          <button type="button" (click)="getThemeImportants()">COMEÇAR</button>
        </div>
        <div class="card justify-content-between">
          <p>5 principais palestrantes sobre um tema</p> <br>
          <input type="text" [(ngModel)]="searchSpeakers" placeholder="digite o tema" />
          <button type="button" (click)="getThemeSpeakers()">COMEÇAR</button>
        </div>
        <div class="card justify-content-between">
          <p>5 cases reais de empresas que implementaram um tema</p>
          <input class="diff_input" type="text" [(ngModel)]="searchCases" placeholder="digite o tema" />
          <button type="button" (click)="getThemeCases()">COMEÇAR</button>
        </div>
        <div class="card justify-content-between">
          <p>20 palestrantes por tema (interno)</p>

          <form class="example-form">
            <mat-form-field class="example-full-width">

              <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option.nome}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>

          <button type="button" (click)="getDbSpeakers()" [disabled]="!searchControl.value?.id">COMEÇAR</button>
        </div>
      </div>

      <div class="comparar_palestrantes">
        <p>Comparar palestrantes</p>
        <div class="flex">
          <input type="text" placeholder="inserir palestrantes entre vírgulas" [(ngModel)]="searchCompare" />
          <button class="buttons m-1" type="button" (click)="compareSpeakers()">COMEÇAR</button>
        </div>
      </div>
      <div class="comparar_palestrantes mt-3">
        <p>Perfil do Palestrante - Resumão</p>
        <div class="flex">
          <input type="text" [(ngModel)]="smallProfile" placeholder="nome do palestrante" />
          <button class="buttons m-1" type="button" (click)="getProfile()">COMEÇAR</button>
        </div>
      </div>
      <div class="comparar_palestrantes mt-3">
        <p>Perfil do palestrante completo</p>
        <div class="flex">
          <input type="text" [(ngModel)]="completeProfile" placeholder="nome do palestrante" />
          <button class="buttons m-1" type="button" (click)="getCompleteProfile()">COMEÇAR</button>
        </div>
      </div>

      <div class="comparar_palestrantes my-3">
        <p>Defesa palestrante</p>
        <div class="flex">
          <form [formGroup]="defesaForms" (submit)="generateDefesa()">
            <input type="text" placeholder="Nome do palestrante" [formControlName]="'palestrante'" />
            <input type="text" placeholder="Tema da palestra" [formControlName]="'tema'" />
            <input type="text" placeholder="Briefing da palestra" [formControlName]="'briefing'" />

            <button class="buttons m-1" type="submit">COMEÇAR</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div
*ngIf="loading"
style="
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
"
>
<img
  src="assets/img/psaLoading.svg"
  alt="Carregando"
  title="Carregando"
/>
</div> -->

<div
  *ngIf="loading"
  style="
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  "
>
  <img
    src="assets/img/psaLoading.svg"
    alt="Carregando"
    title="Carregando"
  />
</div>

<main *ngIf="!loading" class="m-5 p-3">

  <div class="my-5">
    <label>Pesquise por um especialista por termo ou nome</label>
    <div class="flex">
      <input id="searchInput" [(ngModel)]="searchInput"  placeholder="Digite aqui" />
      <button class="buttons" (click)="generalSearch()">Buscar</button>
    </div>
  </div>

  <mat-accordion>
    <mat-expansion-panel class="panel__container">
      <mat-expansion-panel-header>
        <mat-panel-title class="font-weight-bold">
          TEMAS
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="mt-3">
        <label>5 pontos importantes sobre um tema</label>
        <div class="flex">
          <input type="text" [(ngModel)]="searchImportant" placeholder="Digite o tema" />
          <button class="buttons" type="button" (click)="getThemeImportants()">Enviar</button>
        </div>
      </div>

      <div class="mt-3">
        <label>5 principais palestrantes sobre um tema</label>
        <div class="flex">
          <input type="text" [(ngModel)]="searchSpeakers" placeholder="digite o tema" />
          <button type="button" class="buttons" (click)="getThemeSpeakers()">Enviar</button>
        </div>
      </div>

      <div class="mt-3">
        <label>5 cases reais de empresas que implementaram um tema</label>
        <div class="flex">
          <input class="diff_input" type="text" [(ngModel)]="searchCases" placeholder="digite o tema" />
          <button class="buttons" type="button" (click)="getThemeCases()">Enviar</button>
        </div>
      </div>

      <div class="mt-3">
        <label class="mb-0">20 palestrantes por tema (interno)</label>

        <div class="flex">
          <form class="example-form w-100 mr-3">
            <mat-form-field class="w-100 mr-3">
              <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                  {{option.nome}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>

          <button type="button" class="buttons mt-4" (click)="getDbSpeakers()" [disabled]="!searchControl.value?.id">Enviar</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion class="m-3">
    <mat-expansion-panel class="panel__container">
      <mat-expansion-panel-header>
        <mat-panel-title class="font-weight-bold">
          ESPECIALISTAS
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="comparar_palestrantes">
        <label for="comparar_speaker">Comparar especialistas</label>
        <div class="flex">
          <input type="text" id="comparar_speaker" placeholder="Inserir especialistas entre vírgulas" [(ngModel)]="searchCompare" />
          <button class="buttons" type="button" (click)="compareSpeakers()">Enviar</button>
        </div>
      </div>
      <div class="comparar_palestrantes mt-3">
        <label>Perfil do especialista - Resumão</label>
        <div class="flex">
          <input type="text" [(ngModel)]="smallProfile" placeholder="nome do palestrante" />
          <button class="buttons" type="button" (click)="getProfile()">Enviar</button>
        </div>
      </div>
      <div class="comparar_palestrantes mt-3">
        <label>Perfil do especialista completo</label>
        <div class="flex">
          <input type="text" [(ngModel)]="completeProfile" placeholder="nome do palestrante" />
          <button class="buttons" type="button" (click)="getCompleteProfile()">Enviar</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel class="panel__container">
      <mat-expansion-panel-header>
        <mat-panel-title class="font-weight-bold">
          DEFENDER UM NOME
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="my-3">
        <div class="flex d-flex flex-column">
          <form class="flex d-flex flex-column" [formGroup]="defesaForms" (submit)="generateDefesa()">
            <label for="speakerName">Nome do especialista</label>
            <input type="text" id="speakerName" placeholder="Digite aqui" [formControlName]="'palestrante'" />

            <label class="mt-4" for="theme">Tema da palestra</label>
            <input type="text" id="theme" placeholder="Digite aqui" [formControlName]="'tema'" />

            <label class="mt-4" for="briefing">Briefing da palestra</label>
            <input type="text" id="briefing" placeholder="Digite aqui" [formControlName]="'briefing'" />

            <button class="buttons mt-4 defesa__button" type="submit">Enviar</button>
          </form>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</main>
