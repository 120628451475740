export const themesLocalization = {
  'pt-br': [
    {
      localization: 'Motivação',
      value: 'Motivação',
    },
    {
      localization: 'Vendas',
      value: 'Vendas',
    },
    {
      localization: 'Liderança',
      value: 'Liderança',
    },
    {
      localization: 'Gestão de Pessoas',
      value: 'Gestão de Pessoas',
    },
    {
      localization: 'Negócios',
      value: 'Negócios',
    },
    {
      localization: 'ESG',
      value: 'ESG',
    },
    {
      localization: 'Diversidade',
      value: 'Diversidade',
    },
    {
      localization: 'Inovação',
      value: 'Inovação',
    },
    {
      localization: 'Comportamento',
      value: 'Comportamento',
    },
    {
      localization: 'Educação',
      value: 'Educação',
    },
    {
      localization: 'Saúde',
      value: 'Saúde',
    },
    {
      localization: 'Outros',
      value: 'Outros',
    },
  ],
  'es': [
    {
      localization: 'Motivación',
      value: 'Motivação',
    },
    {
      localization: 'Ventas',
      value: 'Vendas',
    },
    {
      localization: 'Liderazgo',
      value: 'Liderança',
    },
    {
      localization: 'Gestión de personas',
      value: 'Gestão de Pessoas',
    },
    {
      localization: 'Negocio',
      value: 'Negócios',
    },
    {
      localization: 'ESG',
      value: 'ESG',
    },
    {
      localization: 'Diversidad',
      value: 'Diversidade',
    },
    {
      localization: 'Innovación',
      value: 'Inovação',
    },
    {
      localization: 'Comportamiento',
      value: 'Comportamento',
    },
    {
      localization: 'Educación',
      value: 'Educação',
    },
    {
      localization: 'Salud',
      value: 'Saúde',
    },
    {
      localization: 'Outros',
      value: 'Outros',
    },
  ],
  'en': [
    {
      localization: 'Motivation',
      value: 'Motivação',
    },
    {
      localization: 'Sales',
      value: 'Vendas',
    },
    {
      localization: 'Lidership',
      value: 'Liderança',
    },
    {
      localization: 'People Management',
      value: 'Gestão de Pessoas',
    },
    {
      localization: 'Business',
      value: 'Negócios',
    },
    {
      localization: 'ESG',
      value: 'ESG',
    },
    {
      localization: 'Diversity',
      value: 'Diversidade',
    },
    {
      localization: 'Innovation',
      value: 'Inovação',
    },
    {
      localization: 'Behavior',
      value: 'Comportamento',
    },
    {
      localization: 'Education',
      value: 'Educação',
    },
    {
      localization: 'Health',
      value: 'Saúde',
    },
    {
      localization: 'Outhers',
      value: 'Outros',
    },
  ]
}

export const labelsLocalization = {
  'pt-br': {
    'name': 'Qual seu nome completo? (Vai para o certificado)',
    'performance': 'A performance do PALESTRANTE conectou com suas expectativas?',
    'contentAccess': 'Gostaria de ter acesso a mais conteúdos do PALESTRANTE?',
    'job': 'Qual o seu cargo?',
    'phone': 'Qual o seu número de telefone?',
    'themes': 'Quais outros assuntos você mais tem interesse em se aprofundar neste ano?',
    'comments': 'Deixe seu comentário sobre este aprendizado!'
  },
  'es': {
    'name': '¿Cuál es su nombre completo? (Va al certificado)',
    'performance': '¿El rendimiento del ALTAVOZ cumplió con sus expectativas?',
    'contentAccess': '¿Te gustaría tener acceso a más contenido de SPEAKER?',
    'job': '¿Cual es tu papel?',
    'phone': '¿Cual es su numero de telefono?',
    'themes': '¿Qué otros temas te interesa más profundizar este año?',
    'comments': '¡Deja tu comentario sobre este aprendizaje!'
  },
  'en': {
    'name': "What's your full name? (It goes to the certificate)",
    'performance': "Did the SPEAKER's performance meet your expectations?",
    'contentAccess': 'Would you like to have access to more SPEAKER content?',
    'job': 'What is your role?',
    'phone': 'What is your phone number?',
    'themes': 'What other topics are you most interested in delving into this year?',
    'comments': 'Leave your comment about this learning!'
  },
}

export const selectOptionsLocalization = {
  'pt-br': {
    'performance': [
      'Foi incrível, conectou demais',
      'Conectou, mas não foi incrível',
      'Mais ou menos, esperava mais',
      'Não conectou'
    ],
    'accessContent': [
      'Sim, quero! Adorei o(a) palestrante',
      'Sim, pois tenho interesse neste tema, mas preciso avaliar melhor o(a) palestrante',
      'No momento não',
    ],
    'jobs': [
      'CEO/Presidente(a)',
      'Diretor(a)',
      'Gerente',
      'Coordenador(a)/Supervisor(a)',
      'Analista',
      'Assistente',
      'Auxiliar',
      'Professor',
      'Outros'
    ]
  },
  'es': {
    'performance': [
      'Fue increíble, conectó muchísimo',
      'Conectó, pero no fue sorprendente',
      'Más o menos, esperaba más',
      'No se conectó'
    ],
    'accessContent': [
      '¡Si quiero! Me encantó el orador',
      'Sí, porque me interesa este tema, pero necesito evaluar mejor al hablante.',
      'En el momento no',

    ],
    'jobs': [
      'CEO/Presidente',
      'Director',
      'Gerente',
      'Coordinador/Supervisor',
      'Analista', 'Asistente',
      'Asistente',
      'Profesor',
      'Otros'
    ]
  },
  'en': {
    'performance': [
      'It was incredible, I felt connected!',
      "It connected, but it wasn't amazing",
      'More or less, I expected more',
      "Didn't connect"
    ],
    'accessContent': [
      'Yes I want! I loved the speaker',
      'Yes, because I am interested in this topic, but I need to evaluate the speaker better',
      'At the time no'
    ],
    'jobs': [
      'CEO/President',
      'Director',
      'Manager',
      'Coordinator/Supervisor',
      'Analyst',
      'Assistant',
      'Assistant',
      'Teacher',
      'Others'
    ]
  }
}
