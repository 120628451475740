import { Component, OnInit } from "@angular/core";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})

export class AnswerComponent implements OnInit {

  htmlContent: any = '';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    showToolbar: true,
    enableToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  type: string;

  constructor() {}

  ngOnInit(): void {
    this.type = JSON.parse(localStorage.getItem('answerType'));
    this.htmlContent = JSON.parse(localStorage.getItem('answer'));
  }

  getAnswer() {
    switch(this.type) {
      case 'generalSearch':
        this.htmlContent = JSON.parse(localStorage.getItem('generalSearchAnswer'))
    }
  }
}
