<div class="pl-lg-5 pr-lg-5">

    <div class="pr-0">
        <div class="mb-3 align-middle d-flex align-items-center">
            <button (click)="openFilter()" type="button"
                class="btn btn-warning btn-back-circle-middle float-left flexFull mr-3">
                <img height="21" src="assets/icons/filter.svg" alt="filtros" title="Filtros">
            </button>
            <h3 class="d-inline font-title m-0">Palestras</h3>
        </div>
    </div>

    <div class="row ml-lg-1">

        <!-- FILTRO -->
        <section class="section__filter pl-0"
            [ngClass]="{'col-12 col-lg-3': isOpenFilters, 'div__filter__none': !isOpenFilters}">

            <div class="div__filter">
                <form (submit)="applyFilter()">
                    <h6 class="d-inline filter__title text-uppercase m-0">
                        <img class="mr-2" height="16" src="assets/icons/filter.svg" alt="filtros" title="Filtros">
                        Filtros
                    </h6>


                    <div class="pt-2 mt-3">
                        <label class="filter__label" for="search">BUSCA POR TEXTO</label>
                        <input type="text" placeholder="Buscar *" name="search" [(ngModel)]="search" />
                    </div>

                    <div class="pt-2">
                        <label class="filter__label" for="id_tema">Temas</label>
                        <select class="form-control" name="id_tema" [(ngModel)]="id_tema">
                            <option value="0">Selecione *</option>
                            <option *ngFor="let option of select_temas" [value]="option.id">{{option.nome}}</option>
                        </select>
                    </div>

                    <button type="submit" class="filter__btn">Aplicar filtros</button>
                </form>
            </div>
        </section>
        <!-- FILTRO -->

        <div [ngClass]="{'col-12 col-lg-9': isOpenFilters}" class="container__conteudo p-0 w-100">

            <!-- <h5 class="d-inline font-title">Encontre a sua palestra</h5> -->

            <section class="py-4 mt-lg-0">
                <div class="flexFull">
                    <div *ngIf="loading" class="spinner-border text-warning" role="status">
                        <span class="sr-only">Buscando...</span>
                    </div>
                </div>


                <div class="d-flex justify-content-center flex-wrap pl-1">

                    <div *ngIf="temas.length == 0 && !loading" class="flexFull mt-4">
                        <h6 class="text__empty">Nenhum tema encontrado.</h6>
                    </div>

                    <div (click)="goTema(tema.nome)" class="container__temas mr-lg-4" *ngFor="let tema of temas">

                        <div>
                            <div class="tema__img">
                                <img [src]="tema.foto || 'https://placehold.it/300'" [alt]="tema.nome">

                                <div class="tema__orcar">
                                    <div class="container__btn__orcar">
                                        <button (click)="orcar()" class="orcar__btn flexFull">
                                            <img src="assets/icons/shopping-cart.svg" alt="Orçar">
                                            <span class="font-primary">Orçar</span>
                                        </button>

                                    </div>
                                </div>
                            </div>

                            <h5 class="tema__title">{{tema.nome}}</h5>
                        </div>

                    </div>
                </div>
            </section>


        </div>
    </div>
</div>