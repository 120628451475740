<form (submit)="selectGrupo()" class="d-flex flex-column justify-content-center align-items-center">
    <label class="label-input" for="grupo">Selecione um grupo do tutor</label>
    <div class="form-group d-flex flex-column flexFull">
        <select class="form-control select__default" name="grupoSelected" id="grupoSelected"
            [(ngModel)]="grupoSelected">
            <option value="null">Selecione*</option>
            <option *ngFor="let item of gruposUsuarios" [value]="item.id">{{item.nome}}</option>
        </select>
    </div>
    <div class="flexFull mt-3">
        <button (click)="back()" type="button" class="btn__secondary mr-3">Voltar</button>
        <button *ngIf="gruposUsuarios.length > 0" type="submit" class="btn__primary">Avançar</button>
    </div>
</form>

<div *ngIf="gruposUsuarios.length == 0" class="d-flex flex-column justify-content-center align-items-center">
    <label class="label-input">Este tutor não está em um grupo</label>
    <label class="label-input">O insira emm um grupo ou volte selecione outro tutor</label>
    <button (click)="back()" *ngIf="gruposUsuarios.length > 0" type="button" class="btn__secondary mt-3">Voltar</button>
</div>