<div *ngIf="pergunta">
    <h5 class="font-title mb-2">Pergunta</h5>
    <form>
        <div class="form-group">
            <label class="label-input" for="pergunta">pergunta *</label>
            <textarea [class.invalid]="isIncorrect('pergunta', null)" [disabled]="!isOwner" id="pergunta"
                class="form-control input__default" name="pergunta" type="text" [(ngModel)]="pergunta.pergunta"
                placeholder="Pergunta *" maxlength="400"></textarea>
            <span class="error">{{getError('pergunta', null)}}</span>
        </div>

        <div class="form-group">
            <label class="label-input" for="tipo">Tipo *</label>
            <select [class.invalid]="isIncorrect('tipo', null)" (change)="setOpcoes()" [disabled]="!isOwner" id="tipo"
                class="form-control" name="tipo" placeholder="Tipo *" [(ngModel)]="pergunta.tipo">
                <option value="null">Selecione *</option>
                <option *ngFor="let i of tipos" [value]="i.value">{{i.text}}</option>
            </select>
            <span class="error">{{getError('tipo', null)}}</span>
        </div>
    </form>

    <h5 *ngIf="opcoes && opcoes.length > 0" class="font-title mb-2">Respostas</h5>
    <div class="container__questoes">
        <ul class="list-group">
            <li class="list-group-item d-flex" *ngFor="let opcao of opcoes, let index = index">

                <span class="opcao__index">{{opcao.ordem}}: </span>

                <div class="d-flex flex-column content__questoes">
                    <div class="d-flex content__questoes__opcao">
                        <div class="input__first">
                            <input [disabled]="getDisbled()" class="form-control input__default" name="descricao_opcao"
                                [(ngModel)]="opcao.descricao_opcao" placeholder="Descrição *" maxlength=80
                                [class.correct]="opcao.peso > 75" [class.part__correct]="opcao.peso == 75"
                                [class.incorrect]="opcao.peso <= 50">
                            <span class="error">{{getError('descricao_opcao', index)}}</span>

                        </div>

                        <div>
                            <select *ngIf="pergunta.tipo != 'DESCRITIVA'" [disabled]="!isOwner" class="form-control"
                                name="peso" placeholder="Peso *" [(ngModel)]="opcao.peso"
                                [class.correct]="opcao.peso > 75" [class.part__correct]="opcao.peso == 75"
                                [class.incorrect]="opcao.peso <= 50">
                                <option value="null">Selecione *</option>
                                <option *ngFor="let i of getPesos()" [value]="i">{{i}}</option>
                            </select>
                            <span class="error">{{getError('peso', index)}}</span>
                        </div>
                    </div>

                    <div class="content__questoes__explicacao">
                        <textarea [disabled]="getDisbled()" class="form-control input__default" name="explicacao"
                            [(ngModel)]="opcao.explicacao" placeholder="Explicação *" [class.correct]="opcao.peso > 75"
                            [class.part__correct]="opcao.peso == 75" [class.incorrect]="opcao.peso <= 50"
                            maxlength="300"></textarea>
                        <span class="error">{{getError('explicacao', index)}}</span>
                    </div>
                </div>

            </li>
        </ul>
    </div>
    <div *ngIf="isOwner" class="col-12 form-group d-flex justify-content-end mt-2">
        <button (click)="cancel()" class="btn__secondary mr-2">cancelar</button>
        <button (click)="submit()" class="btn__primary">Salvar</button>
    </div>
</div>