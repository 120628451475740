import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IColaborador } from 'src/app/pages/universidade-corporativa/colaboradores';

@Injectable({
  providedIn: 'root'
})
export class ContratanteService {

  constructor(public http: HttpClient) { }

  async getColaboradoresByOrder(page: number, order: string, search: string, status: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const usuario = JSON.parse(localStorage.getItem("usuario"))

    let params = `?page=${page}`
    if (status) params += `&status=${status}`
    if (search && search != "") params += `&search=${search}`
    if (order) params += `&order=${order}`
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}colaboradores/colaboradores-order/${usuario.id_contratante}${params}`, { headers: headers }).toPromise();
    return result;
  }

  async getLideres(page: number, order: string, search: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const usuario = JSON.parse(localStorage.getItem("usuario"))

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "colaboradores/lideres/" + usuario.id_contratante + "/" + page + "/" + order + "/" + search}`, { headers: headers }).toPromise();
    return result;
  }

  async getUsuario(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "usuarios/"}`, { headers: headers }).toPromise();

    return result.body;
  }

  async updateContratante(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "usuarios/update-contratante"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async setLiderGrupo(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "contratantes/updatelidergrupo"}`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async updateColaborador(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "colaboradores/update"}`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async insertColaborador(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "colaboradores/insert"}`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async importColaboradores(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "colaboradores/import"}`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async insertCollectionColaboradores(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "colaboradores/insert-collection"}`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async emailInviteCollaborator(email: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { email };
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}colaboradores/invite-collaborator`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async getProfileIsComplete(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "contratantes/iscomplete"}`, { headers: headers }).toPromise()
    return result;
  }


  async updateTutorCollection(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "colaboradores/update-tutor-collection"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async verificaColaboradores(): Promise<any> {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "contratantes/verifica-colaboradores/" + usuario.id_contratante}`, { headers: headers }).toPromise();
    return result.body;
  }

  async hasGrupo(): Promise<any> {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "contratantes/has-grupo/" + usuario.id_contratante}`, { headers: headers }).toPromise();
    return result.body;
  }

  async deleteColaborador(id_colaborador: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.delete<any>(`${environment.API_V2_URL_DEFAULT + "colaboradores/" + id_colaborador}`, { headers: headers }).toPromise();
    return result.body;
  }

  async deleteColaboradorCollection(): Promise<any> {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.delete<any>(`${environment.API_V2_URL_DEFAULT + "colaboradores/delete-collection/" + usuario.id_contratante}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getContratanteById(id: number): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}contratantes/get-nome/${id}`).toPromise();
    return result.body;
  }

}
