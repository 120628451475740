import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PalestrantesService {
  constructor(public http: HttpClient) {}

  listSpeakerForCertificate() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    return this.http.get(`${environment.API_V2_URL_DEFAULT}admin/palestrantes/formulario`, {headers});
  }

  createExclusivo(body: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http.post(`${environment.API_V2_URL_DEFAULT}palestrantes/exclusivo`, body);
  }

  editExclusivo(id: string, body: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http.put(`${environment.API_V2_URL_DEFAULT}palestrantes/exclusivo/${id}`, body);
  }

  getSpeakerExclusivo(id: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    return this.http.get(`${environment.API_V2_URL_DEFAULT}curador/consulta-palestrantes/exclusivo/${id}`, { headers });
  }

  getSpeakersLight() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    return this.http.get(`${environment.API_V2_URL_DEFAULT}curador/consulta-palestrantes/light`, { headers });
  }

  getCompleteSpeakers(start, end) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const body = {
      start, end
    }
    return this.http.post(`${environment.API_V2_URL_DEFAULT}curador/consulta-palestrantes`, body, { headers });
  }

  getTicketMedio() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    return this.http.get(`${environment.API_V2_URL_DEFAULT}curador/ticket-medio`, { headers });
  }

  async verificaPlano(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(`${environment.API_V2_URL_DEFAULT}palestrantes/verificaplano`, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async getBasic(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(`${environment.API_V2_URL_DEFAULT}palestrantes/basic`, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async getArtigosExclusivos(page: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Accept', 'application/json')
      .set('Content-type', 'application/json');
    const body = { page: page };

    const result = await this.http
      .post<any>(
        `${environment.API_V2_URL}base/front/artigosExclusivos`,
        body,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async getCountHome(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    let palestrante = JSON.parse(localStorage.getItem('palestrante'));
    const result = await this.http
      .get<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/count/${palestrante.id}`,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async getMeuPerfil(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(`${environment.API_V2_URL_DEFAULT}palestrantes/meu-perfil`, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async getPalestranteById(palestranteId: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/${palestranteId}`,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async getPalestrantes(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(`${environment.API_V2_URL_DEFAULT}palestrantes`, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async getPropostasByPalestranteId(palestranteId: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(
        `${environment.API_V2_URL_DEFAULT}/palestrantes/propostas/${palestranteId}`,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async updateProfileStatus(status: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .put<any>(
        `${environment.API_V2_URL_DEFAULT}/palestrantes//update-profile-status`,
        { status },
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async getDados(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(`${environment.API_V2_URL_DEFAULT}palestrantes/dados`, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async getDadosByUserId(userId: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/dados/${userId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
    return result.body;
  }

  async updateDados(usuario: any, palestrante): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    let body = {
      usuario: usuario,
      palestrante: palestrante,
    };

    const result = await this.http
      .post<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/update-dados`,
        body,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async updateDadosPalestrante(usuario: any, palestrante): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    let body = {
      usuario: usuario,
      palestrante: palestrante,
    };

    const result = await this.http
      .post<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/update-dados-palestrante`,
        body,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async setIndicatedSpeaker(isIndicated: boolean, userId: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const body = {
      isIndicated,
    };

    const result = await this.http.post<any>(
      `${environment.API_V2_URL_DEFAULT}/indicated/${userId}`,
      body,
      { headers: headers }
    ).toPromise();

    return result.body;
  }

  async getDadosBancarios(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    const result = await this.http
      .get<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/dados-bancario`,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async updateDadosBancarios(usuario: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    let body = { usuario: usuario };
    const result = await this.http
      .post<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/update-dados-bancario`,
        body,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async getConhecimentos(palestrante?: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));

    if (!palestrante) {
      palestrante = JSON.parse(localStorage.getItem('palestrante'));
    }

    const result = await this.http
      .get<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/conhecimentos/${palestrante.id}`,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async getConhecimentosByPalestranteId(palestrante: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    const result = await this.http
      .get<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/conhecimentos/${palestrante.id}`,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  getAllConhecimentos() {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    return this.http.get(`${environment.API_V2_URL_DEFAULT}palestrantes/conhecimentos`, { headers });
  }

  async updateConhecimentos(
    temas: Array<any>,
    idTemaPrincipal: any
  ): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    let palestrante = JSON.parse(localStorage.getItem('palestrante'));
    let body = {
      idTemaPrincipal: idTemaPrincipal,
      temas: temas,
      idPalestrante: palestrante.id,
    };
    const result = await this.http
      .post<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/conhecimentos/update`,
        body,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async updateConhecimentosByPalestranteId(
    temas: Array<any>,
    idTemaPrincipal: any,
    palestrante: any
  ): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    let body = {
      idTemaPrincipal: idTemaPrincipal,
      temas: temas,
      idPalestrante: palestrante.id,
    };
    const result = await this.http
      .post<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/conhecimentos/update/:id`,
        body,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async getDepoimentos(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    let palestrante = JSON.parse(localStorage.getItem('palestrante'));
    const result = await this.http
      .get<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/depoimentos/${palestrante.id}`,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async getDepoimentosByPalestranteId(palestrante: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    const result = await this.http
      .get<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/depoimentos/${palestrante.id}`,
        { headers: headers }
      )
      .toPromise();
      console.log(result);
    return result.body;
  }

  async solicitarDepoimento(form: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    const body = { form: form };

    const result = await this.http
      .post<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/depoimentos/solicitar`,
        body,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }

  async editarDepoimento(form: any, depoimentoId: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    const body = form;

    const result = await this.http
      .post<any>(
        `${environment.API_V2_URL_DEFAULT}palestrantes/depoimentos/editar/${depoimentoId}`,
        body,
        { headers: headers }
      )
      .toPromise();
    return result.body;
  }


  async sendPDFPalestrante(slug: string, empresa: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem('token'));
    const body = { slug: slug, empresa: empresa.toString() };

    const result = await this.http
      .post<any>(`${environment.API_V2_URL}base/front/pdf-palestrante`, body, {
        headers: headers,
      })
      .toPromise();
    return result.body;
  }

  async cadastrarPalestrante(body: any): Promise<any> {
    const user = localStorage.getItem('user')
    if (user) {
      body.isLogged = true;
    }

    const result = await this.http
      .post<any>(`${environment.API_V2_URL_DEFAULT}palestrantes`, body)
      .toPromise();
    return result.body;
  }

  async getRepositories(speakerId: string) {
    const result = await this.http
    .get<any>(`${environment.API_V2_URL_DEFAULT}palestrantes/repository/${speakerId}`)
    .toPromise();
  return result.body;
  }

  async createRepositories(speakerId: string, body: any) {
    const result = await this.http
    .post<any>(`${environment.API_V2_URL_DEFAULT}palestrantes/repository/${speakerId}`, body)
    .toPromise();

    return result.body;
  }

  async editRepositories(speakerId: string, body: any) {
    const result = await this.http
    .put<any>(`${environment.API_V2_URL_DEFAULT}palestrantes/repository/${speakerId}`, body)
    .toPromise();

    return result.body;
  }

  confirmarIndicacao(speakerId: string, proposalId: string) {
    const body = {
      speakerId,
      proposalId,
    }
    return this.http.post(`${environment.API_V2_URL_DEFAULT}palestrantes/confirmar-indicacao`, body);
  }
}
