<div class="container-fluid px-4 px-md-5">
  <!-- <app-wizard [itemSelected]="6"></app-wizard> -->

  <div class="container__conteudo__default row mt-4">
    <div class="sectionActions">
      <div class="closeButton" (click)="close()">
        <img src="../../../../../assets/icons/close.svg" />
      </div>
    </div>

    <div class="col-12" style="height: 100%;">
      <div class="row justify-content-between align-items-start align-items-md-center flex-nowrap mb-2">
        <h3 class="font-title pr-2">Escolha as 3 principais</h3>

        <div *ngIf="!(idSelected >= 0)">
          <button (click)="insert()" class="btn__add" title="Adicionar Formação">Adicionar</button>
        </div>
      </div>

      <div class="row">
        <div *ngIf="idSelected >= 0" class="col-12 col-md-8">
          <div class="">
            <app-form-formacao [id]="idSelected" (finished)="formFinished()">
            </app-form-formacao>
          </div>
        </div>

        <div *ngIf="formacoes.length == 0">
          Nenhuma formação acadêmica cadastrada
        </div>

        <div [class.d-none]="idSelected >= 0" *ngFor="let i of formacoes" class="col-12 col-md-4">
          <div class="d-flex flex-column content__item">
            <div class="d-flex flex-column content__info">
              <label class="">{{i.titulo}}</label>
              <label class="">{{i.local}}</label>
              <label class="">{{i.data_formatada}}</label>
              <label class="">{{i.cidade}}/{{i.estado}}</label>
              <label class="container__checkbox">
                Cursando
                <input type="checkbox" [checked]="i.cursando" disabled>
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="content__action">
              <button (click)="update(i.id)" class="btn">
                <img src="assets/icons/editar.svg" alt="editar">
                Editar
              </button>
              <button (click)="delete(i.id)" class="btn" title="Excluir">
                <img src="assets/icons/excluir.svg" alt="excuir">
                Excluir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
