<div class="container__live">

    <app-spinner *ngIf="loading"></app-spinner>


    <div class="container__header">

        <div class="flexCol content__info">
            <p class="txtDesc">Palestra</p>
            <p class="txtTitle">{{palestra?.nome}}</p>
        </div>

        <div *ngIf="live && isTempoTolerancia" class="content__tolerancia">
            <img src="assets/img/cronometro.png">
            <label>Você está no tempo de tolerância!</label>
        </div>


        <div *ngIf="live" class="btn cronometro">
            <img src="assets/img/cronometro.png">
            <div>
                <label *ngIf="minutos < 10">0</label>{{minutos}}:<label *ngIf="segundos < 10">0</label>{{segundos}}
            </div>
        </div>

        <a *ngIf="!live" [routerLink]="['/palestrante/agenda']" title="Perfil"><img class="iconAction"
                src="assets/img/home.jpeg"></a>

        <a [href]="'https://api.whatsapp.com/send?phone=' + whatsSupportNumber + '&text=Olá, eu sou um palestrante e estou solicitando auxílio na live.'"
            target="_blank">
            <img class="iconAction" src="assets/img/WhatsappOff.png">
        </a>

        <a href="https://www.teamviewer.com/pt-br/produtos/teamviewer/" target="_blank" title="Suporte"><img
                class="iconAction" src="assets/img/teamViewer.png"></a>
    </div>
    <!-- FIM HEADER -->



    <!-- CONTENT -->
    <div class="live__main">
        <!-- CONTENT LEFT -->
        <div class="flexCol contentLeft">
            <!-- INICIO SLIDE -->

            <div *ngIf="live" class="divAoVivo">
                <label>AO VIVO</label>
            </div>

            <div *ngIf="live" class="divNroEspec">
                <label style="padding-top: 7px;">{{numeroEspectadores ? numeroEspectadores : 0}}</label>
                <img class="imgEspec" src="assets/icons/olho.svg">
            </div>

            <div *ngIf="exibeArquivo" class="pagina">
                <p style="line-height: 22px;">
                    <a (click)="paginaAnterior()" style="padding-right: 10px;">
                        < </a>
                            {{currentPage}}
                            <a (click)="paginaPosterior()" style="padding-left: 10px;"> > </a>
                </p>
            </div>

            <app-spinner class="loadingPDF" *ngIf="loadingPDF"></app-spinner>

            <div class="divSlide" *ngIf="!exibeArquivo">

                <div class="flexColFull" style="height: 500px;">
                    <img style="height: 50px; margin-bottom: 10px;" src="assets/img/slide.png">
                    <label>Nenhum arquivo</label>

                </div>
            </div>

            <div class="divSlide" *ngIf="exibeArquivo">
                <div style="margin: 0; height: 500px;">
                    <pdf-viewer style="display: inline;" [src]="path" [render-text]="true" [show-all]="false"
                        [autoresize]="true" [original-size]="false" [zoom-scale]="'page-fit'" [(page)]="currentPage">
                    </pdf-viewer>
                </div>
            </div>
            <!-- TERMINO SLIDE -->


            <div class="flex" style="flex: 1; justify-content: start;">
                <div class="flexCol">
                    <label class="txtDesc">Interações</label>
                    <label class="txtTitle">Libere as interações para público</label>
                </div>
                <button *ngIf="exibeArquivo" (click)="stopSlide()" class="btn txtActionBtn btnAction">PARAR
                    SLIDE
                </button>
                <button *ngIf="!exibeArquivo" (click)="uploadArquivo()" class="btn txtActionBtn btnAction">COMPARTILHAR
                    SLIDE
                </button>

            </div>


            <!-- DIV LINKS -->
            <div class="divCards">

                <div class="divLink">

                    <div>
                        <div class="card cardPendente">
                            <div class="flex">
                                <label class="tituloCard">Termômetro</label>
                            </div>
                            <label class="txtCard">Qual a sua satisfação com a pelestra até o momento?
                            </label>


                            <div class="flex" style="justify-content: space-between;">
                                <label class="txtCard" style="font-weight: bold;">Ruim :</label>
                                <label class="txtCard" style="font-weight: bold;">{{termometro.count_porcentagem1 &&
                                    termometro.count_porcentagem1 >= 0 ? termometro.count_porcentagem1 : 0}}%</label>
                            </div>
                            <div class="flex" style="justify-content: space-between;">
                                <label class="txtCard" style="font-weight: bold;">Bom :</label>
                                <label class="txtCard" style="font-weight: bold;">{{termometro.count_porcentagem2 &&
                                    termometro.count_porcentagem2 >= 0 ? termometro.count_porcentagem2 : 0}}%</label>
                            </div>
                            <div class="flex" style="justify-content: space-between;">
                                <label class="txtCard" style="font-weight: bold;">Muito Bom :</label>
                                <label class="txtCard" style="font-weight: bold;">{{termometro.count_porcentagem3 &&
                                    termometro.count_porcentagem3 >= 0 ? termometro.count_porcentagem3 : 0}}%</label>
                            </div>
                            <!-- <label class="txtCard" style="color: #fff;"><img (click)="selecionaQuiz(quiz)"
                                    style="height: 13px; margin-left: 5px;" src="assets/icons/people-white.svg">
                                Respostas {{termometro.tetal_avaliacoes ? termometro.tetal_avaliacoes : 0}}</label> -->
                            <label style="font-size: 14px; color: red;">{{mensagem}}</label>
                        </div>
                    </div>


                    <div *ngFor="let quiz of quiz; index as i">

                        <div *ngIf="quiz.liberado == 1" class="card cardLiberado">
                            <div class="flex" style="margin-bottom: 5px;">
                                <label class="tituloCard">Quiz {{i + 1}}</label>
                                <button (click)="liberaQuiz(quiz.id)" class="btnCardLiberado">LIBERADO</button>
                            </div>
                            <div class="flex" style="justify-content: space-between;">
                                <label class="txtCard"><b>1) </b>{{quiz.opcao1}} :</label>
                                <label class="txtCard" style="font-weight: bold;">{{quiz.porcentagem1 ?
                                    quiz.porcentagem1 : 0}}%</label>
                            </div>
                            <div class="flex" style="justify-content: space-between;">
                                <label class="txtCard"><b>2) </b>{{quiz.opcao2}} :</label>
                                <label class="txtCard" style="font-weight: bold;">{{quiz.porcentagem2 ?
                                    quiz.porcentagem2 : 0}}%</label>
                            </div>
                            <div class="flex" style="justify-content: space-between;">
                                <label class="txtCard"><b>3) </b>{{quiz.opcao3}} :</label>
                                <label class="txtCard" style="font-weight: bold;">{{quiz.porcentagem3 ?
                                    quiz.porcentagem3 : 0}}%</label>
                            </div>
                            <div class="flex" style="justify-content: space-between;">
                                <label class="txtCard"><b>4) </b>{{quiz.opcao4}} :</label>
                                <label class="txtCard" style="font-weight: bold;">{{quiz.porcentagem4 ?
                                    quiz.porcentagem4 : 0}}%</label>
                            </div>

                            <!-- <label class="txtCard" style="color: #fff;"><img (click)="selecionaQuiz(quiz)"
                                    style="height: 13px; margin-left: 5px;" src="assets/icons/people-white.svg">
                                Respostas {{quiz.qtde ? quiz.qtde : 0}}</label> -->
                        </div>

                        <div *ngIf="quiz.liberado == 0" class="card cardPendente">
                            <div class="flex" style="margin-bottom: 5px;">
                                <label class="tituloCard">Quiz {{i + 1}}</label>
                                <button (click)="liberaQuiz(quiz.id)" class="btnCard">LIBERAR</button>
                            </div>
                            <label class="txtCard">{{quiz.pergunta}}</label>

                            <div class="editar">
                                <img (click)="selecionaQuiz(quiz)" style="height: 15px; margin-left: 5px;"
                                    src="assets/icons/editar.svg">
                            </div>
                        </div>
                    </div>

                    <div *ngIf="quiz.length < 5">
                        <div class="card cardPendente">
                            <div class="flexFull" style="margin-bottom: 5px;">
                                <label class="tituloCard">Adicionar quiz</label>
                            </div>
                            <button (click)="addQuiz()" class="btnAdd"><img src="assets/icons/add.svg"></button>
                        </div>
                    </div>

                    <!-- <div *ngFor="let link of links; index as i">
                        <div *ngIf="link.liberado == 'SIM'" class="card cardLiberado">
                            <div class="flex" style="margin-bottom: 5px;">
                                <label class="tituloCard">Vídeo {{i + 1}}</label>
                                <button (click)="msgBloLink(link.id)" class="btnCardLiberado">LIBERADO</button>
                            </div>

                            <label class="mb-0" style="font-weight: bold; font-size: 11px;">Autor:
                                {{link.autor}}</label>
                            <label class="maxLine">Titulo: {{link.titulo}}</label>
                            <img class="imgVideo" src="https://img.youtube.com/vi/{{link.video_codigo}}/0.jpg" />

                        </div>

                        <div *ngIf="link.liberado == 'NAO'" class="card cardPendente">
                            <div class="flex" style="margin-bottom: 5px;">
                                <label class="tituloCard">Vídeo {{i + 1}}</label>
                                <button (click)="msgLibLink(link.id)" class="btnCard">LIBERAR</button>
                            </div>
                            <label class="mb-0 maxLine">Titulo: {{link.titulo}}</label>
                            <label class="mb-0" style="font-size: 10px;">Autor: {{link.autor}}</label>
                            <img class="imgVideo" src="https://img.youtube.com/vi/{{link.video_codigo}}/0.jpg" />

                            <div *ngIf="!link" class="card cardPendente">
                                <label class="tituloCard" style="margin-bottom: 5px;">Vídeo</label>
                                <label style="font-size: 14px;">Não há video disponível</label>
                                <button (click)="msgLibLink(link.id)" class="btnCard">LIBERAR</button>
                            </div>

                            <div class="editar">
                                <img (click)="selecionaVideo(link)" style="height: 15px; margin-left: 5px;"
                                    src="assets/icons/editar.svg">
                            </div>

                        </div>
                    </div>

                    <div *ngIf="links.length < 5">
                        <div class="card cardPendente">
                            <div class="flexFull" style="margin-bottom: 5px;">
                                <label class="tituloCard">Adicionar vídeo</label>
                            </div>
                            <button (click)="addVideo()" class="btnAdd"><img src="assets/icons/add.svg"></button>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
        <!-- FIM CONTENT LEFT -->



        <!-- CONTENT RIGHT -->
        <div class="flexCol" style="flex: 2;">
            <div class="divLive" style="position: relative; ">

                <iframe id="my-broadcaster" width="100%" height="100%" [src]="urlBambuser" style="border: none"
                    allowfullscreen allow="microphone; camera"></iframe>

                <div *ngIf="liveLiberada && !live" class="flexFull avisoLive">
                    <label>Clique no botão para iniciar a
                        Live</label>
                </div>
                <div *ngIf="!liveLiberada" (click)="liberaLive()" class="flexFull divOverLive">
                    <label style="width: 50%; text-align: center;">Inicie sua live até 5 minutos antes. clique para
                        preparar a transmissão</label>
                </div>
            </div>

            <div *ngIf="editaQuiz" class="chat relative">
                <div *ngIf="loadingEdit" class="loadingEdit">
                    <app-spinner></app-spinner>
                </div>
                <div class="flex">
                    <img style="height: 36px;" src="assets/icons/quiz.svg">

                    <div class="flexCol" style="margin-left: 15px; flex: 1;">
                        <div class="flex" style="flex: 1;">
                            <label *ngIf="quizSelecionado.id" class="txtTitle"
                                style="margin-bottom: 10px; flex: 1;">Editar Quiz</label>
                            <label *ngIf="!quizSelecionado.id" class="txtTitle"
                                style="margin-bottom: 10px; flex: 1;">Cadastrar Quiz</label>
                            <img (click)="showFormQuiz(false)" style="height: 18px;"
                                src="assets/icons/close-yellow.svg">

                        </div>
                        <label class="txtDesc" style="font-size: 11px;">Selecione uma das perguntas abaixo e faça as
                            alterações em suas perguntas e respostas:</label>
                    </div>
                </div>
                <div class="flexCol" style="flex-wrap: wrap;">

                    <label maxlength="90" class="labelInput" style="margin-top: 15px;">Pergunta:</label>
                    <input maxlength="90" class="input" placeholder="Pergunta" [(ngModel)]="quizSelecionado.pergunta">
                    <label class="labelInput">Alternativas:</label>
                    <div class="flex">
                        <input maxlength="18" class="input inputLeft" placeholder="Alternativa 1"
                            [(ngModel)]="quizSelecionado.opcao1">
                        <input maxlength="18" class="input" placeholder="Alternativa 2"
                            [(ngModel)]="quizSelecionado.opcao2">
                    </div>
                    <div class="flex" style="margin-top: 10px;">
                        <input maxlength="18" class="input inputLeft" placeholder="Alternativa 3"
                            [(ngModel)]="quizSelecionado.opcao3">
                        <input maxlength="18" class="input" placeholder="Alternativa 4"
                            [(ngModel)]="quizSelecionado.opcao4">
                    </div>
                    <button class="btnSalvar" (click)="salvaQuiz()">SALVAR</button>

                </div>
            </div>

            <div *ngIf="editaVideo" class="chat relative">
                <div *ngIf="loadingEdit" class="loadingEdit">
                    <app-spinner></app-spinner>
                </div>
                <div class="flex">
                    <img style="height: 36px;" src="assets/icons/livros.svg">

                    <div class="flexCol" style="margin-left: 15px; flex: 1;">
                        <div class="flex" style="flex: 1;">
                            <label class="txtTitle" style="margin-bottom: 10px; flex: 1;">Vídeos de apoio</label>
                            <img (click)="showFormVideo(false)" style="height: 18px;"
                                src="assets/icons/close-yellow.svg">

                        </div>
                        <label class="txtDesc" style="font-size: 11px;">Insira e edite os links dos vídeos de apoio para
                            o seu público acessar:</label>
                    </div>
                </div>
                <div class="flexCol" style="flex-wrap: wrap; margin-top: 20px;">
                    <label class="txtDesc"
                        style="font-size: 11px; margin-bottom: 10px;">{{videoSelecionado.autor}}</label>
                    <label class="txtTituloVideo" style="margin-bottom: 10px;">{{videoSelecionado.titulo}}</label>
                    <input class="input" placeholder="https://www.youtube.com/watch?v=uxn...."
                        [(ngModel)]="videoSelecionado.link">

                    <button class="btnSalvar" (click)="salvaVideo()">SALVAR</button>

                </div>
            </div>

            <!-- CHAT -->
            <div *ngIf="!editaVideo && !editaQuiz" class="chat">

                <div class="flex">
                    <img style="height: 36px;" src="assets/icons/chat.svg">

                    <div class="flexCol" style="margin-left: 15px;">
                        <label class="txtTitle" style="margin-bottom: 10px;">Bate-papo</label>
                        <label class="txtDesc" style="font-size: 11px;">Acompanhe as últimas dúvidas do público</label>
                    </div>
                </div>

                <div class="divPerguntas">
                    <div style="flex: 1" *ngFor="let pergunta of perguntas; index as i">
                        <div class="flex" style="margin-bottom: 10px;">
                            <img *ngIf="!pergunta.photo" class="imgUsuario" src="assets/icons/profile.svg">
                            <img *ngIf="pergunta.photo" class="imgUsuario" src="{{pergunta.photo}}">
                            <div class="flexCol">
                                <label class="nomeUsuario">{{pergunta.nome}} {{pergunta.sobrenome}} |
                                    {{pergunta.datahora_pergunta | date:"h:mm a"}}</label>
                                <label class="pergunta">{{pergunta.perguntas}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- FIM CONTENT RIGHT -->

    </div>
    <!-- FIM CONTENT -->



    <!-- DIV MSG LIBERA LINK -->
    <div class="msgLink" *ngIf="mlLink">
        <p>Deseja liberar este vídeo para os espectadores?</p>
        <button (click)="liberaLink()"
            style="border-radius: 10px; margin-left: 55%; margin-right: 5px; background-color: red; border: none; color: white; width: 20%; height: 30%;">SIM</button>
        <button (click)="closeMsg()"
            style="border-radius: 10px; margin-right: 5px; background-color: red; border: none; color: white; width: 20%; height: 30%;">NÃO</button>
    </div>
    <!-- FIM DIV MSG LIBERA LINK -->


    <!-- DIV MSG BLOQUEIA LINK -->
    <div class="msgLink" *ngIf="mbLink">
        <p>Deseja bloquear este vídeo para os espectadores?</p>
        <button (click)="bloqueiaLink()"
            style="border-radius: 10px; margin-left: 55%; margin-right: 5px; background-color: red; border: none; color: white; width: 20%; height: 30%;">SIM</button>
        <button (click)="closeMsg()"
            style="border-radius: 10px; margin-right: 5px; background-color: red; border: none; color: white; width: 20%; height: 30%;">NÃO</button>
    </div>
    <!-- FIM DIV MSG BLOQUEIA LINK -->


    <div class="popupUpload" *ngIf="exibeSlide">
        <a style="position: absolute; top: 10%; left: 80%; text-decoration: none;" (click)="stopSlide()">Fechar</a>
        <input type="file" #pdfFile (change)="onChangePdf($event)" placeholder="Selecione o arquivo"
            class="form-control p-0" accept=".pdf">
        <label style="font-size: 14px; color: black; margin-top: 5px;">Para melhor visualização dos espectadores,
            recomendamos o pdf no <b>formato PAISAGEM/HORIZONTAL</b>.</label>
        <label style="font-size: 14px; color: black;">O tamanho do arquivo PDF deve ser de no máximo 4.5 MB.</label>
    </div>


    <!-- FIM ADD TEMPO -->
    <div *ngIf="mensagemAlerta" class="flex container__aviso" style="justify-content: center; padding: 10px;">

        <div class="addTempo">
            <label>{{mensagemAlerta}}</label>
        </div>

        <div>
            <button class="btTime" (click)="fechaAlerta()"> OK </button>
        </div>
    </div>
    <!-- FIM ADD TEMPO -->


</div>

<app-aviso-tempo-live *ngIf="showAvisoTempo" [mensagem]="mensagemAvisoTempo" [submensagem]="submensagemAvisoTempo">
</app-aviso-tempo-live>

<app-modal-add-tempo *ngIf="addTempo" [submensagem]="'Restam menos de 10 minutos para a sua transmissão encerrar.'"
    [mensagem]="'Deseja adicionar mais 10 minutos de
                transmissão?'" (addTime)="addTime()" (cancelAddTime)="cancelAddTime()"></app-modal-add-tempo>