<div [class.none]="concorda">
    <div class="d-flex align-items-center">
        <label class="container__checkbox mb-4">Concordo com os <a target="_blank"
                href="https://s3-sa-east-1.amazonaws.com/basesa/termos-usuario.pdf">termos e condições</a>
            do
            contrato e com as <a target="_blank"
                href="https://basesa.s3-sa-east-1.amazonaws.com/politica_de_privacidade_e_termos_de_uso_de_dados_profissionais.pdf">
                políticas de privacidade</a>.
            <input type="checkbox" name="concorda" [(ngModel)]="concorda" (change)="submit()">
            <span class="checkmark"></span>
        </label>
    </div>
</div>