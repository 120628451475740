<div class="container-fluid px-5">
  <!-- <app-wizard [itemSelected]="1"></app-wizard> -->

  <div class="row mt-4">
    <div *ngIf="loading" class="d-flex justify-content-center">
      <!-- <app-spinner></app-spinner> -->
    </div>
    <div class="col-12 container__conteudo__default" style="height: 100%" *ngIf="!loading">
      <form (submit)="submit()" *ngIf="palestrante && usuario">
        <div class="row">
          <div class="col-12 col-md-3 d-flex flex-column container__foto">
            <div>
              <app-foto-perfil [foto]="usuario?.photo"></app-foto-perfil>
            </div>
          </div>

          <div class="col-12 col-md-9">
            <div class="row">
              <div class="form-group col-12 col-md-8">
                <label class="label-input" for="titulo">Frase Referência *</label>
                <textarea id="titulo" [class.invalid]="isIncorrect('titulo')" class="form-control" name="titulo"
                  type="text" [(ngModel)]="palestrante.titulo" [ngModelOptions]="{ standalone: true }"
                  placeholder="Frase Referência"></textarea>
                <span class="error">{{ getError("titulo") }}</span>
              </div>

              <div class="form-group col-12 col-md-4">
                <label class="label-input" for="video">Código do Vídeo (Youtube)
                </label>
                <img class="ml-2" height="16px" src="assets/icons/info.svg" alt="info"
                  title="Insira o link do video do youtube, o código é gerado automáticamente ao tirar o cursor do campo." />
                <input (change)="changeVideo()" class="form-control" name="video" type="text"
                  [(ngModel)]="palestrante.video" [ngModelOptions]="{ standalone: true }"
                  placeholder="Código do Vídeo" />
              </div>

              <div class="form-group col-12">
                <label class="label-input" for="minibio"
                  >Mini Biografia *</label
                >
                <!-- <textarea
                  id="minibio"
                  [class.invalid]="isIncorrect('minibio')"
                  class="form-control"
                  type="text"
                  name="minibio"
                  maxlength="1800"
                  [(ngModel)]="palestrante.minibio"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Descreva sua história"
                ></textarea>
                <span class="error">{{ getError("minibio") }}</span> -->
                <angular-editor
                  id="editor1"
                  [(ngModel)]="palestrante.minibio"
                ></angular-editor>
              </div>
            </div>
          </div>
        </div>

        <div class="row container__label__radio">
          <p class="label-input">MOTIVOS PARA CONTRATAR O PALESTRNATE</p>
          <app-reasons [reasons]="htmlReasons" (newItemEvent)="addItem($event)"></app-reasons>
        </div>

        <div class="row container__label__radio">
          <div class="form-group col-6 col-md-3">
            <label class="label-input label__radio" for="palestrante">palestrante</label>

            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="palestrante_ativo_s" name="palestrante" class="custom-control-input" value="SIM"
                  [(ngModel)]="palestrante.palestrante" />
                <label class="custom-control-label radio_text" for="palestrante_ativo_s">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="palestrante_ativo_n" name="palestrante" class="custom-control-input" value="NAO"
                  [(ngModel)]="palestrante.palestrante" />
                <label class="custom-control-label radio_text" for="palestrante_ativo_n">Não</label>
              </div>
            </div>
          </div>

          <div class="form-group col-6 container__label__radio col-md-3">
            <label class="label-input label__radio" for="indicated">Chancela para indicação</label>

            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="indicated" name="indicated" class="custom-control-input" value="SIM"
                  [(ngModel)]="palestrante.indicated" />
                <label class="custom-control-label radio_text" for="indicated">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="indicated_n" name="indicated" class="custom-control-input" value="NAO"
                  [(ngModel)]="palestrante.indicated" />
                <label class="custom-control-label radio_text" for="indicated_n">Não</label>
              </div>
            </div>
          </div>

          <div class="form-group col-6 container__label__radio col-md-3">
            <label class="label-input label__radio" for="mentor">Mentor</label>

            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="mentor_ativo_s" name="mentor" class="custom-control-input" value="SIM"
                  [(ngModel)]="palestrante.mentor" />
                <label class="custom-control-label radio_text" for="mentor_ativo_s">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="mentor_ativo_n" name="mentor" class="custom-control-input" value="NAO"
                  [(ngModel)]="palestrante.mentor" />
                <label class="custom-control-label radio_text" for="mentor_ativo_n">Não</label>
              </div>
            </div>
          </div>

          <div class="form-group col-6 container__label__radio col-md-3">
            <label class="label-input label__radio" for="consultor">consultor</label>

            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="consultor_ativo_s" name="consultor" class="custom-control-input" value="SIM"
                  [(ngModel)]="palestrante.consultor" />
                <label class="custom-control-label radio_text" for="consultor_ativo_s">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="consultor_ativo_n" name="consultor" class="custom-control-input" value="NAO"
                  [(ngModel)]="palestrante.consultor" />
                <label class="custom-control-label radio_text" for="consultor_ativo_n">Não</label>
              </div>
            </div>
          </div>

          <div class="form-group col-6 container__label__radio col-md-3">
            <label class="label-input label__radio" for="influenciador">influenciador</label>

            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="influenciador_ativo_s" name="influenciador" class="custom-control-input"
                  value="SIM" [(ngModel)]="palestrante.influenciador" />
                <label class="custom-control-label radio_text" for="influenciador_ativo_s">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="influenciador_ativo_n" name="influenciador" class="custom-control-input"
                  value="NAO" [(ngModel)]="palestrante.influenciador" />
                <label class="custom-control-label radio_text" for="influenciador_ativo_n">Não</label>
              </div>
            </div>
          </div>

          <div class="form-group col-6 container__label__radio col-md-3">
            <label class="label-input label__radio" for="trainer">trainer</label>
            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="trainer_ativo_s" name="trainer" class="custom-control-input" value="SIM"
                  [(ngModel)]="palestrante.trainer" />
                <label class="custom-control-label radio_text" for="trainer_ativo_s">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="trainer_ativo_n" name="trainer" class="custom-control-input" value="NAO"
                  [(ngModel)]="palestrante.trainer" />
                <label class="custom-control-label radio_text" for="trainer_ativo_n">Não</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row container__label__radio">
          <!-- campos em usuario -->
          <div class="form-group col-6 col-md-3">
            <label class="label-input label__radio" for="autorizar_perfil">Mostrar Perfil no Site</label>

            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="autorizar_perfil_ativo_s" name="autorizar_perfil" class="custom-control-input"
                  value="1" [(ngModel)]="palestrante.autorizar_perfil" />
                <label class="custom-control-label radio_text" for="autorizar_perfil_ativo_s">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="autorizar_perfil_ativo_n" name="autorizar_perfil" class="custom-control-input"
                  value="0" [(ngModel)]="palestrante.autorizar_perfil" />
                <label class="custom-control-label radio_text" for="autorizar_perfil_ativo_n">Não</label>
              </div>
            </div>
          </div>

          <div class="form-group col-6 col-md-3">
            <label class="label-input label__radio" for="ja_palestrou">Já Palestrou</label>

            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="ja_palestrou_ativo_s" name="ja_palestrou" class="custom-control-input" value="1"
                  [(ngModel)]="palestrante.ja_palestrou" />
                <label class="custom-control-label radio_text" for="ja_palestrou_ativo_s">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="ja_palestrou_ativo_n" name="ja_palestrou" class="custom-control-input" value="0"
                  [(ngModel)]="palestrante.ja_palestrou" />
                <label class="custom-control-label radio_text" for="ja_palestrou_ativo_n">Não</label>
              </div>
            </div>
          </div>

          <div class="form-group col-6 col-md-3">
            <label class="label-input label__radio" for="email_oportunidade">Receber e-mail</label>

            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="email_oportunidade_ativo_s" name="email_oportunidade"
                  class="custom-control-input" value="1" [(ngModel)]="palestrante.email_oportunidade" />
                <label class="custom-control-label radio_text" for="email_oportunidade_ativo_s">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="email_oportunidade_ativo_n" name="email_oportunidade"
                  class="custom-control-input" value="0" [(ngModel)]="palestrante.email_oportunidade" />
                <label class="custom-control-label radio_text" for="email_oportunidade_ativo_n">Não</label>
              </div>
            </div>
          </div>

          <div class="form-group col-6 col-md-3">
            <label class="label-input label__radio" for="palestra_gratuita">Palestrar Gratuitamente</label>

            <div class="d-flex">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="palestra_gratuita_ativo_s" name="palestra_gratuita" class="custom-control-input"
                  value="1" [(ngModel)]="palestrante.palestra_gratuita" />
                <label class="custom-control-label radio_text" for="palestra_gratuita_ativo_s">Sim</label>
              </div>

              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="palestra_gratuita_ativo_n" name="palestra_gratuita" class="custom-control-input"
                  value="0" [(ngModel)]="palestrante.palestra_gratuita" />
                <label class="custom-control-label radio_text" for="palestra_gratuita_ativo_n">Não</label>
              </div>
            </div>
          </div>

          <div class="form-group col-6 col-md-3"></div>
        </div>

        <h5 class="font-title mt-3">Dados Pessoais</h5>
        <div class="row">
          <div class="form-group col-12 col-md-4">
            <label class="label-input" for="firstName">Nome</label>
            <input class="form-control" type="text" name="firstName" placeholder="Nome" [(ngModel)]="usuario.nome" />
          </div>

          <div class="form-group col-12 col-md-4">
            <label class="label-input" for="lastName">Sobrenome</label>
            <input class="form-control" type="text" name="lastName" placeholder="Sobrenome"
              [(ngModel)]="usuario.sobrenome" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label for="slug" class="label-input">Slug</label>
            <input type="text" class="form-control" name="slug" placeholder="Slug" [(ngModel)]="palestrante.slug">
          </div>
          <div class="form-group col-12 col-md-4">
            <label class="label-input" for="firstName">Cidade</label>
            <input class="form-control" type="text" name="city" placeholder="Cidade" [(ngModel)]="usuario.cidade" />
          </div>

          <div class="form-group col-12 col-md-4">
            <label class="label-input" for="lastName">Estado</label>
            <select class="form-control" name="states" [(ngModel)]="usuario.estado">
              <option *ngFor="let state of states" [value]="state">{{ state }}</option>
            </select>
          </div>
        </div>

        <h5 class="font-title mt-3">Informações de Contato</h5>
        <div class="row">
          <div class="form-group col-12 col-md-4">
            <label for="email" class="label-input">Email</label>
            <input type="text" class="form-control" name="email" placeholder="email" [(ngModel)]="usuario.email" />
          </div>
          <div class="form-group col-12 col-md-4">
            <label for="phoneNumber" class="label-input">Telefone</label>
            <input type="text" class="form-control" name="phoneNumber" placeholder="Telefone"
              [(ngModel)]="usuario.telefone" (ngModelChange)="formatPhone($event)" />
          </div>
        </div>

        <h5 class="font-title mt-3">Redes Sociais</h5>
        <div class="row">
          <div class="form-group col-12 col-md-4">
            <label class="label-input" for="linkedin">linkedin</label>
            <input class="form-control" name="linkedin" type="text" [(ngModel)]="usuario.linkedin"
              [ngModelOptions]="{ standalone: true }" placeholder="Link Linkedin" />
          </div>

          <div class="form-group col-12 col-md-4">
            <label class="label-input" for="instagram">instagram</label>
            <input class="form-control" name="instagram" type="text" [(ngModel)]="usuario.instagram"
              [ngModelOptions]="{ standalone: true }" placeholder="Link Instagram" />
          </div>

          <div class="form-group col-12 col-md-4">
            <label class="label-input" for="facebook">facebook</label>
            <input class="form-control" name="facebook" type="text" [(ngModel)]="usuario.facebook"
              [ngModelOptions]="{ standalone: true }" placeholder="Link Facebook" />
          </div>
        </div>

        <h5 class="font-title mt-3">Observações</h5>
        <div class="row">
          <div class="form-group col-md-4">
            <textarea id="observations" class="form-control" type="text" name="observations" maxlength="1800"
              placeholder="Observações sobre o palestrante." [(ngModel)]="usuario.observacao"></textarea>
          </div>

          <div class="form-group col-12 col-md-4">
            <label class="label-input" for="nps">NPS</label>
            <input class="form-control" name="nps" type="number" step=".1" [(ngModel)]="usuario.nps"
              [ngModelOptions]="{ standalone: true }" placeholder="NPS" />
          </div>
          <span class="error">{{ getError("nps") }}</span>
          <div class="form-group col-6 col-md-4">
            <label class="label-input" for="nps">Selo de Plano</label>
            <select class="form-control" name="states" [(ngModel)]="palestrante.selo_plano">
              <option *ngFor="let selo of selos" [value]="selo">{{ selo }}</option>
            </select>
          </div>
        </div>

        <h5 class="font-title mt-3">Informações da Assessoria</h5>
        <div class="row">
          <div class="form-group col-12 col-md-4">
            <label for="empresa" class="label-input">Empresa assessora</label>
            <input name="empresa" type="text" [(ngModel)]="assessoria.empresa" class="form-control" />
          </div>

          <div class="form-group col-12 col-md-4">
            <label for="nome" class="label-input">Nome do Assessor(a)</label>
            <input name="nome" type="text" [(ngModel)]="assessoria.nome" class="form-control" />
          </div>

          <div class="form-group col-12 col-md-4">
            <label for="funcao" class="label-input">Função</label>
            <input name="funcao" type="text" [(ngModel)]="assessoria.funcao" class="form-control" />
          </div>

          <div class="form-group col-12 col-md-4">
            <label for="telefone" class="label-input">Telefone</label>
            <input name="telefone" type="text" [(ngModel)]="assessoria.telefone" class="form-control" />
          </div>

          <div class="form-group col-12 col-md-4">
            <label for="emailAssessoria" class="label-input">E-mail</label>
            <input name="emailAsseria" type="text" [(ngModel)]="assessoria.email" class="form-control" />
          </div>

          <div class="form-group col-12 col-md-4 flex flex-row align-content-center justify-content-start">
            <input name="exclusividade" type="checkbox" [(ngModel)]="assessoria.exclusividade" class="form-control text-sm mt-4" />
            <label for="exclusividade" class="label-input ml-3 mt-4 s">Tem exclusividade com outra empresa?</label>
          </div>
        </div>

        <div class="row">
          <p class="col-12">Último editor: {{ palestrante?.ultimaEdicao || '--' }}</p>
          <p class="col-12">Data da última edição: {{ palestrante?.lastEditCurador }}</p>
        </div>

        <div class="row">
          <div class="d-flex div__btn__salvar">
            <button *ngIf="!loading" type="submit" class="btn btn-primary btn-warning">
              Salvar
            </button>

            <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
              <img height="40px" src="assets/img/simple_loading.gif" alt="carregando" />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
