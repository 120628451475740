import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IPergunta } from 'src/app/pages/meus-conteudos/habilidade';

@Injectable({
  providedIn: 'root'
})
export class MeusConteudosService {

  idContratantePSA: number = 16146;

  constructor(public http: HttpClient) { }

  async getCompetencias(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}competencias/list/${usuario.id_contratante}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getCompetenciasPSA(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}competencias/list-psa`, { headers: headers }).toPromise();
    return result.body;
  }

  async getCompetenciaById(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}competencias/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertUpdateCompetencia(competencia: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { competencia: competencia }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}competencias/insert-update`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getHabilidadesByCompetencia(idCompetencia): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}habilidades/list/${idCompetencia}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getHabilidadeById(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}habilidades/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertUpdateHabilidade(habilidade: any, id_competencia: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { habilidade, id_competencia }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}habilidades/insert-update`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getPerguntas(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}gameficacao/perguntas/list-habilidade/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getPerguntaById(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}gameficacao/perguntas/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getModelsOpcoes(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}gameficacao/perguntas/models`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertUpdatePergunta(id_habilidade: number, pergunta: IPergunta): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = {
      id_habilidade,
      ...pergunta
    }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}gameficacao/perguntas/insert-update`, body, { headers: headers }).toPromise();
    return result.body;
  }
}
