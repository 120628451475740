<div class="row">
    <div class="container__search col-12 col-lg-6 d-flex">
        <img src="assets/icons/search-black.svg" alt="ícone pesquisar">
        <input class="container__search__input form-group m-0 border-0 bg-transparent" placeholder="Buscar..."
            type="text" name="search" [(ngModel)]="search" (ngModelChange)="buscar()">
    </div>

    <div class="col-12 col-lg-6 d-flex flex-column flex-md-row justify-content-end align-items-center">
        <div class="container__select__filter__order d-flex flex-column flex-lg-row align-items-center">
            <label class="m-0 mx-2" for="order">Status: </label>
            <select class="select__filter__order" [(ngModel)]="status" name="status"
                (ngModelChange)="changeStatusFilter()">
                <option [value]="'SIM'">Ativo</option>
                <option [value]="'NAO'">Inativo</option>
            </select>

            <label class="m-0 mx-2" for="order">Ordenar: </label>
            <select class="select__filter__order" [(ngModel)]="order" name="order" (ngModelChange)="changeOrder()">
                <option *ngFor="let i of orders" [value]="i.value">{{i.name}}</option>
            </select>
        </div>

        <div class="d-flex">
            <button (click)="excluirCollection()" class="btn bg-dark text-white button__delete button__expandable">
                <span>
                    <img src="assets/icons/excluir__white.svg" alt="excluir">
                </span>
            </button>

            <button (click)="adicionar()" class="btn__primary button__insert button__expandable"
                title="Adicionar colaborador">
                <span>
                    <img src="assets/icons/add.svg" alt="adicionar">
                </span>
            </button>

        </div>
    </div>
</div>

<div class="content__cabecalho text-left d-flex p-0">
    <label class="font-title text-uppercase flex2">Nome</label>
    <label class="font-title text-uppercase flex1">Cargo</label>
    <label class="font-title text-uppercase flex1">Tutor</label>
    <label class="font-title text-uppercase flex1">Grupo</label>
    <label class="font-title text-uppercase flex1">Status</label>
    <label class="font-title text-uppercase flex07">Ações</label>
</div>

<ul>
    <li #colaborador *ngFor="let colaborador of colaboradores">

        <div class="d-flex content__list__item">

            <div class="flex2 w-100 item__nome pl-0">
                <div class="d-flex">
                    <img *ngIf="colaborador.responsavel_grupo == 'SIM'" height="20px" src="assets/icons/star.svg"
                        alt="líder">
                    <input #colaborador_nome class="input__disable item__nome" type="text"
                        [(ngModel)]="colaborador.nome_completo" disabled="disabled">
                </div>
            </div>

            <div class="flex1">
                <input class="input__disable" type="text" [(ngModel)]="colaborador.cargo_nome" disabled="disabled">
            </div>

            <div class="flex1 item__nome"
                (click)="colaborador.class = colaborador.class == 'showChildren' ? 'colaborador__item' :  'showChildren'">
                <input class="input__disable item__nome" type="text"
                    [value]="colaborador.nome_lider ? colaborador.nome_lider : 'Indefinido'" disabled="disabled">
            </div>

            <div class="flex1">
                <input [class.d-none]="!colaborador.id_grupo" class="input__disable" type="text"
                    [(ngModel)]="colaborador.grupo_nome" disabled="disabled">
                <span [class.d-none]="colaborador.id_grupo" class="input__disable">Indefinido</span>
            </div>

            <div class="flex1">
                <input class="input__disable" type="text"
                    [value]="colaborador.contratante_ativo == 'SIM' ? 'Ativo' : 'Inativo'" disabled="disabled">
            </div>

            <div class="flex07 td_acao align-items-center">
                <img (click)="editar(colaborador)" src="assets/icons/editar.svg" alt="adicionar">
                <img *ngIf="colaborador.removable != 0" (click)="excluir(colaborador.id_colaborador)"
                    class="float-right" src="assets/icons/excluir.svg" alt="excluir">
                <img style="pointer-events: auto;" *ngIf="colaborador.removable == 0"
                    (click)="messageNotDelete(colaborador)" class="float-right unclickable"
                    src="assets/icons/excluir.svg" alt="excluir">
                <img [class.unclickable]="colaborador.nome_lider" (click)="changeTutor(colaborador)" class="float-right"
                    src="assets/icons/tutor.svg" alt="substituir tutor" title="substituir tutor">
            </div>
        </div>
    </li>
</ul>
<p *ngIf="colaboradores?.length == 0">Nenhum colaborador cadastrado.</p>

<div class="d-flex justify-content-between align-items-center">
    <nav *ngIf="qtdePages?.length > 1" aria-label="Page navigation example">
        <ul class="pagination">
            <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                <a class="page-link">Anterior</a>
            </li>

            <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)" class="page-item">
                <a class="page-link">1</a>
            </li>
            <span *ngIf="(page + 1) > 4" class="px-2">...</span>

            <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)"
                class="page-item" *ngFor="let number of getPages()">
                <a class="page-link">{{number+1}}</a>
            </li>

            <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
            <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}"
                (click)="changePage(qtdePages.length - 1)" class="page-item">
                <a class="page-link">{{qtdePages.length}}</a>
            </li>

            <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                <a class="page-link">Próxima</a>
            </li>
        </ul>
    </nav>
</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>