<main class="psa-main mb-5">

    <div class="col-12 col-lg-10 offset-lg-1 p-0 mt-5 mt-lg-4">

        <div *ngIf="isLoading" class="loading flexFull">
            <img src="assets/img/simple_loading.gif" alt="loading">
        </div>
        <!-- RECENTES -->
        <section class="m-0">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide *ngFor="let recente of artigosRecentes">
                    <div (click)="goArtigo(recente.slug, recente.categoria)"
                        class="slideRecente d-flex flex-column flex-lg-row">
                        <figure>
                            <img [src]="recente.image_src" [attr.alt]="recente.slug" />
                        </figure>
                        <div class="artigo__texto">
                            <h1>{{recente.titulo}}</h1>
                            <p class="description" [innerHTML]="recente.resumo"></p>

                            <div class="artigo__info">
                                <p *ngIf="recente.fonte">por <b>{{recente.fonte}}</b></p>
                                <!-- <p>{{recente.data}}</p> -->
                                <p>{{recente.tipo}}</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </section>

        <!-- CATEGORIAS -->
        <section *ngIf="!isLoading" class="mt-5">
            <div class="">
                <h1 class="title__section text-center mb-3">Navegue por Categoria</h1>
                <owl-carousel-o [options]="customOptionsCategorias">
                    <ng-template carouselSlide *ngFor="let categoria of categorias">
                        <div (click)="goTipo(categoria.id)"
                            class="categoria__item d-flex flex-column justify-content-center align-items-center">
                            <figure class="categoria__figure">
                                <img class="categoria__img" *ngIf="categoria.imagem" [src]="categoria.imagem"
                                    [attr.alt]="categoria.nome" />
                                <img class="categoria__img" *ngIf="!categoria.imagem" src="http://placehold.it/300"
                                    [attr.alt]="categoria.nome">
                            </figure>

                            <h1 class="title__article">{{categoria.descricao}}</h1>

                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>

        <!-- SA NEWS -->
        <section class="mt-5">
            <div (click)="goTipo(categoriasId[0])" class="d-flex justify-content-between px-4 py-4">

                <h1 class="d-inline title__section">{{artigos1[0]?.tipo}}</h1>
                <button class="btn__more">
                    <span>VER MAIS</span>
                    <i class="fa fa-plus"></i>
                </button>
            </div>

            <owl-carousel-o [options]="customOptionsArtigos">
                <ng-template carouselSlide *ngFor="let artigo of artigos1">
                    <div (click)="goArtigo(artigo.slug, artigo.categoria)" class="container__item">
                        <div class="artigo__item d-flex flex-column">
                            <figure class="artigo__item__img">
                                <img [src]="artigo.image_src" [attr.alt]="artigo.slug" />
                            </figure>
                            <div class="artigo__item__info">
                                <h1 class="title__article">{{artigo.titulo}}</h1>
                                <p [innerHTML]="artigo.resumo"></p>
                                <p class="text__more">Saiba mais</p>
                            </div>

                            <div class="artigo__item__detail">
                                <!-- <p>{{artigo.data}}</p> -->
                                <p>{{artigo.fonte}}</p>
                                <p class="artigo__item__detail__tipo">{{artigo.tipo}}</p>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </section>
    </div>

    <div *ngIf="artigosVideos.length > 0" class="background__videos ">
        <!-- VIDEOS -->
        <section class="col-12 col-lg-10 offset-lg-1 p-0 mt-5 mt-lg-4">
            <div class="py-4">
                <owl-carousel-o [options]="customOptionsVideos">
                    <ng-template carouselSlide *ngFor="let artigo of artigosVideos">

                        <div>
                            <div class="d-flex flex-column flex-lg-row align-items-center">

                                <div class="video__item__info py-2 px-2">
                                    <h1 class="mb-4">{{artigo.titulo}}</h1>
                                    <p [innerHTML]="artigo.resumo"></p>
                                </div>

                                <div class="video__item__video">
                                    <video controls controlsList="nodownload">
                                        <source [src]="artigo.video_link" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>
    </div>


    <div class="col-12 col-lg-10 offset-lg-1 p-0 mt-5 mt-lg-4">
        <section *ngIf="artigos2?.length > 0">
            <div (click)="goTipo(categoriasId[1])" class="d-flex justify-content-between px-4 py-4">
                <h1 class="d-inline title__section">{{artigos2[0]?.tipo}}</h1>
                <button class="btn__more">
                    <span>VER MAIS</span>
                    <i class="fa fa-plus"></i>
                </button>
            </div>

            <div class="container__carousel">
                <owl-carousel-o [options]="customOptionsArtigos">
                    <ng-template carouselSlide *ngFor="let artigo of artigos2">

                        <div [ngStyle]="{'width': artigos4?.length < 3 ? '410px' : 'auto'}"
                            (click)="goArtigo(artigo.slug, artigo.categoria)" class="container__item">
                            <div class="artigo__item d-flex flex-column">

                                <figure class="artigo__item__img">
                                    <img [src]="artigo.image_src" [attr.alt]="artigo.slug" />
                                </figure>

                                <div class="artigo__item__info">
                                    <h1>{{artigo.titulo}}</h1>
                                    <p [innerHTML]="artigo.resumo"></p>
                                    <p class="text__more">Saiba mais</p>
                                </div>

                                <div class="artigo__item__detail">
                                    <!-- <p>{{artigo.data}}</p> -->
                                    <p>{{artigo.fonte}}</p>
                                    <p class="artigo__item__detail__tipo">{{artigo.tipo}}</p>
                                </div>
                            </div>

                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>


        <!-- Educação Corporativa -->
        <section *ngIf="artigos3?.length > 0">
            <div (click)="goTipo(categoriasId[2])" class="d-flex justify-content-between px-4 py-4">
                <h1 class="d-inline title__section">{{artigos3[0]?.tipo}}</h1>
                <button class="btn__more">
                    <span>VER MAIS</span>
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <div class="container__carousel">
                <owl-carousel-o [options]="customOptionsArtigos">
                    <ng-template carouselSlide *ngFor="let artigo of artigos3">

                        <div [ngStyle]="{'width': artigos3?.length < 3 ? '410px' : 'auto'}"
                            (click)="goArtigo(artigo.slug, artigo.categoria)" class="container__item">
                            <div class="artigo__item d-flex flex-column">

                                <figure class="artigo__item__img">
                                    <img [src]="artigo.image_src" [attr.alt]="artigo.slug" height="100%" />
                                </figure>

                                <div class="artigo__item__info">
                                    <h1>{{artigo.titulo}}</h1>
                                    <p [innerHTML]="artigo.resumo"></p>
                                    <p class="text__more">Saiba mais</p>
                                </div>

                                <div class="artigo__item__detail">
                                    <!-- <p>{{artigo.data}}</p> -->
                                    <p>{{artigo.fonte}}</p>
                                    <p class="artigo__item__detail__tipo">{{artigo.tipo}}</p>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>


        <!-- Marcas e Eventos -->
        <section *ngIf="artigos4?.length > 0">
            <div (click)="goTipo(categoriasId[3])" class="d-flex justify-content-between px-4 py-4">
                <h1 class="d-inline title__section">{{artigos4[0]?.tipo}}</h1>
                <button class="btn__more">
                    <span>VER MAIS</span>
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <div class="container__carousel">
                <owl-carousel-o [options]="customOptionsArtigos">
                    <ng-template carouselSlide *ngFor="let artigo of artigos4">

                        <div [ngStyle]="{'width': artigos4?.length < 3 ? '410px' : 'auto'}"
                            (click)="goArtigo(artigo.slug, artigo.categoria)" class="container__item">
                            <div class="artigo__item d-flex flex-column">

                                <figure class="artigo__item__img">
                                    <img [src]="artigo.image_src" [attr.alt]="artigo.slug" height="100%" />
                                </figure>

                                <div class="artigo__item__info">
                                    <h1>{{artigo.titulo}}</h1>
                                    <p [innerHTML]="artigo.resumo"></p>
                                    <p class="text__more">Saiba mais</p>
                                </div>

                                <div class="artigo__item__detail">
                                    <!-- <p>{{artigo.data}}</p> -->
                                    <p>{{artigo.fonte}}</p>
                                    <p class="artigo__item__detail__tipo">{{artigo.tipo}}</p>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

        </section>


        <!-- 5 -->
        <section *ngIf="artigos5?.length > 0">
            <div (click)="goTipo(categoriasId[4])" class="d-flex justify-content-between px-4 py-4">
                <h1 class="d-inline title__section">{{artigos5[0]?.tipo}}</h1>
                <button class="btn__more">
                    <span>VER MAIS</span>
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <div class="container__carousel">
                <owl-carousel-o [options]="customOptionsArtigos">
                    <ng-template carouselSlide *ngFor="let artigo of artigos5">

                        <div [ngStyle]="{'width': artigos4?.length < 3 ? '410px' : 'auto'}"
                            (click)="goArtigo(artigo.slug, artigo.categoria)" class="container__item">
                            <div class="artigo__item d-flex flex-column">

                                <figure class="artigo__item__img">
                                    <img [src]="artigo.image_src" [attr.alt]="artigo.slug" height="100%" />
                                </figure>

                                <div class="artigo__item__info">
                                    <h1>{{artigo.titulo}}</h1>
                                    <p [innerHTML]="artigo.resumo"></p>
                                    <p class="text__more">Saiba mais</p>
                                </div>

                                <div class="artigo__item__detail">
                                    <!-- <p>{{artigo.data}}</p> -->
                                    <p>{{artigo.fonte}}</p>
                                    <p class="artigo__item__detail__tipo">{{artigo.tipo}}</p>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

        </section>


        <!-- 6 -->
        <section *ngIf="artigos6?.length > 0">
            <div (click)="goTipo(categoriasId[5])" class="d-flex justify-content-between px-4 py-4">
                <h1 class="d-inline title__section">{{artigos6[0]?.tipo}}</h1>
                <button class="btn__more">
                    <span>VER MAIS</span>
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <div class="container__carousel">
                <owl-carousel-o [options]="customOptionsArtigos">
                    <ng-template carouselSlide *ngFor="let artigo of artigos6">

                        <div [ngStyle]="{'width': artigos4?.length < 3 ? '410px' : 'auto'}"
                            (click)="goArtigo(artigo.slug, artigo.categoria)" class="container__item">
                            <div class="artigo__item d-flex flex-column">

                                <figure class="artigo__item__img">
                                    <img [src]="artigo.image_src" [attr.alt]="artigo.slug" height="100%" />
                                </figure>

                                <div class="artigo__item__info">
                                    <h1>{{artigo.titulo}}</h1>
                                    <p [innerHTML]="artigo.resumo"></p>
                                    <p class="text__more">Saiba mais</p>
                                </div>

                                <div class="artigo__item__detail">
                                    <!-- <p>{{artigo.data}}</p> -->
                                    <p>{{artigo.fonte}}</p>
                                    <p class="artigo__item__detail__tipo">{{artigo.tipo}}</p>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

        </section>


        <!-- 7 -->
        <section *ngIf="artigos7?.length > 0">
            <div (click)="goTipo(categoriasId[6])" class="d-flex justify-content-between px-4 py-4">
                <h1 class="d-inline title__section">{{artigos7[0]?.tipo}}</h1>
                <button class="btn__more">
                    <span>VER MAIS</span>
                    <i class="fa fa-plus"></i>
                </button>
            </div>
            <div class="container__carousel">
                <owl-carousel-o [options]="customOptionsArtigos">
                    <ng-template carouselSlide *ngFor="let artigo of artigos7">

                        <div [ngStyle]="{'width': artigos4?.length < 3 ? '410px' : 'auto'}"
                            (click)="goArtigo(artigo.slug, artigo.categoria)" class="container__item">
                            <div class="artigo__item d-flex flex-column">

                                <figure class="artigo__item__img">
                                    <img [src]="artigo.image_src" [attr.alt]="artigo.slug" height="100%" />
                                </figure>

                                <div class="artigo__item__info">
                                    <h1>{{artigo.titulo}}</h1>
                                    <p [innerHTML]="artigo.resumo"></p>
                                    <p class="text__more">Saiba mais</p>
                                </div>

                                <div class="artigo__item__detail">
                                    <!-- <p>{{artigo.data}}</p> -->
                                    <p>{{artigo.fonte}}</p>
                                    <p class="artigo__item__detail__tipo">{{artigo.tipo}}</p>
                                </div>

                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

        </section>
    </div>
</main>