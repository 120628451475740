<section class="psa-showcase-register" *ngIf="tema">
  <div class="container row">
    <figure style="width: 100%; height: 220px; border-radius: 5px;">
      <img [attr.src]="tema.foto" style="width: 100%; height: 100%; border-radius: 5px; object-fit: cover;" />
    </figure>
    <h1>{{tema ? tema.nome : ''}}</h1>
    <p [innerHTML]="tema.descricao"></p>
  </div>
</section>

<section class="psa-featured-themes mt-3" *ngIf="subtemas.length">
  <div class="container relacionados">
    <owl-carousel-o [options]="relacionadosOptions">
      <ng-template carouselSlide *ngFor="let subtema of subtemas">
        <div (click)="goSubitem(subtema.nome)" style="cursor: pointer;">
          <figure class="psa-featured-theme-cover">
            <img [attr.src]="subtema.foto || 'assets/img/imagem_fake_190'">
          </figure>
          <div class="psa-featured-theme-info">
            <h1>{{subtema.nome}}</h1>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>

<section class="psa-featured-themes mt-3 mb-3" *ngIf="palestras.length > 0">
  <div class="container palestras">
    <h1 class="psa-featured-themes-title">Palestras</h1>
    <owl-carousel-o [options]="palestrasOptions">
      <ng-template carouselSlide *ngFor="let palestra of palestras">
        <div class="content" (click)="goPalestra(palestra.slug)">
          <figure class="psa-featured-theme-cover">
            <img [attr.src]="palestra.photo" alt="{{palestra.nome}}">
          </figure>
          <div class="psa-featured-theme-info">
            <h1>{{palestra.nome.substring(0,50)}}</h1>
            <p>{{palestra.descricao.substring(0,90) + '...'}}</p>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>

<app-especialistas-carousel *ngIf="especialistas && especialistas.length > 0" [profissionaisInput]="especialistas"
  [titulo]="'Conheça os especialistas do time da Profissionais SA e leve o melhor em palestras '+tema.nome.toLowerCase()+':'">
</app-especialistas-carousel>

<div class="mt-5"></div>

<app-orcar-buttom></app-orcar-buttom>