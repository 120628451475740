<main class="appContainer fadeIn mb-5">
  <div class="row">
    <div class="col-12 col-md-7 col-lg-9">
      <div class="row">
        <!-- Cards Topo -->
        <section class="col-12 mb-3 fadeIn">
          <div class="cardsContainer cardsContainer--fullItems">
            <div *ngIf="isLeader" class="cardInfo cardsContainer__item">
              <div class="cardInfo__item">
                <img
                  width="16"
                  src="assets/icons/list_check.svg"
                  alt="Avaliações Pendentes"
                >
              </div>

              <div class="cardInfo__item">
                <h3 class="cardInfo__title">{{totalizers.total_avaliacao_pendentes}}</h3>
                <p class="cardInfo__subtitle">Avaliações pendentes</p>
              </div>
            </div>

            <a
              class="cardInfo cardsContainer__item"
              [routerLink]="['/espectador/minhaTrilha']"
              title="Ir para Minhas Trilhas"
            >
              <div class="cardInfo__item">
                <img
                  width="16"
                  src="assets/icons/calendar.svg"
                  alt="Palestras Agendadas"
                >
              </div>

              <div class="cardInfo__item" style="width: 200px;">
                <progress-bar [progress]="percent" [color]="'#1abc9c'">
                </progress-bar>
                <p class="cardInfo__subtitle pl-1">Trilhas concluídas</p>
              </div>
            </a>

            <a
              class="cardInfo cardsContainer__item"
              [routerLink]="['/espectador/agenda']"
              title="Ir para Agenda de Palestras"
            >
              <div class="cardInfo__item">
                <img
                  width="16"
                  src="assets/icons/calendar.svg"
                  alt="Palestras Agendadas"
                >
              </div>

              <div class="cardInfo__item">
                <h3 class="cardInfo__title">{{totalizers.total_inscricao}}</h3>
                <p class="cardInfo__subtitle">Palestras agendadas</p>
              </div>
            </a>

            <a
              [routerLink]="['/espectador/certificados']"
              class="cardInfo cardsContainer__item"
              title="Ir para Certificados Liberados"
            >
              <div class="cardInfo__item">
                <img
                  width="16"
                  src="assets/icons/certificate.svg"
                  alt="Certificados liberados"
                >
              </div>

              <div class="cardInfo__item">
                <h3 class="cardInfo__title">{{totalizers.total_certificados}}</h3>
                <p class="cardInfo__subtitle">Certificados liberados</p>
              </div>
            </a>
          </div>
        </section>

        <!-- Section Palestras -->
        <section class="col-12 col-lg-6 fadeIn mt-3 order-lg-1">
          <!-- Destaques -->
          <div class="mb-3 cardMain">
            <div class="cardMain__header mb-2">
              <h2 class="title">Em Destaque</h2>
            </div>

            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let relacionado of destaques">
                  <a href="{{'https://www.profissionaissa.com/sa-news/' + relacionado.slug}}" target="_blank">
                    <div style="max-height: 30vh;" class="d-flex flex-column relacionados__item">
                      <figure class="relacionados__item__img">
                          <img [src]="relacionado.image_src" class="cardPalestraDestaque cardPalestraDestaque--home fadeIn" [attr.alt]="relacionado.slug" />
                      </figure>
                  </div>
                  </a>
              </ng-template>
            </owl-carousel-o>
          </div> 
          
          <!-- Próximas Palestras -->
          <div class="cardMain">
            <div class="cardMain__header mb-2">
              <h2 class="title">Próximas Palestras</h2>
            </div>

            <div [class.d-none]="eventSelected" class="cardMain__contentScroll cardMain__contentScroll--responsiveVertical">
              <app-proximas-palestras class="cardsContainer cardsContainer--responsiveScrollVertical" (openDetail)="selectToShowEvent($event)"></app-proximas-palestras>
            </div>

            <div *ngIf="eventSelected" class="cardMain__contentScroll">
              <app-palestra [id]="eventSelected" (back)="setEventSelected(null)"></app-palestra>
            </div>
          </div>
        </section>

        <!-- Section Continue Estudando -->
        <section class="col-12 col-lg-5 fadeIn mt-3 order-lg-0">
          <div class="cardMain">
            <div class="cardMain__header">
              <h2 class="title">Continue estudando</h2>
              <p class="subtitle subtitle--smaller colorFontSecondary">Explore suas trilhas e mantenha-se na frente</p>
            </div>

            <div class="cardMain__contentScroll cardMain__contentScroll--responsiveVertical" style="max-height: 77vh;" >
              <app-minha-trilha class="cardsContainer"></app-minha-trilha>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="col-12 col-md-5 col-lg-3 d-none d-md-block">
      <!-- Rank -->
      <section class="mb-3 fadeIn">
        <div class="cardMain">
          <div class="cardMain__header">
            <h2 class="title d-flex align-items-center">
              MeuRank
              <img class="mr-3" src="assets/img/logo-white.svg" alt="Meu Rank SA" height="20" />
            </h2>
          </div>

          <div class="cardMain__container">
            <p class="mb-0">Aguarde novidades...</p>

            <div class="d-none">
              <div class="meuRank">
                <div class="meuRank__number">
                  32
                </div>

                <div class="meuRank__content">
                  <h3 class="meuRank__name">
                    Eduardo Santos
                  </h3>
                  <p class="meuRank__info">
                    #1.244
                  </p>
                </div>
              </div>

              <div class="meuRank active">
                <div class="meuRank__number">
                  33
                </div>

                <div class="meuRank__content">
                  <h3 class="meuRank__name">
                    Marcio Soares
                  </h3>
                  <p class="meuRank__info">
                    #1322
                  </p>
                </div>

                <p class="meuRank__infoTag">
                  você
                </p>
              </div>

              <div class="meuRank">
                <div class="meuRank__number">
                  34
                </div>

                <div class="meuRank__content">
                  <h3 class="meuRank__name">
                    Marcus Tabosa
                  </h3>
                  <p class="meuRank__info">
                    #999
                  </p>
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <a class="linkSimple" href="javascript:void()" title="Saiba mais">
                  Saiba mais
                </a>

                <a href="javascript:void()" title="Ranking completo">
                  Ranking completo
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Temas -->
      <section class="fadeIn">
        <div class="cardMain">
          <div class="cardMain__header">
            <h2 class="title">Habilidades para capacitar</h2>
            <p class="subtitle subtitle--smaller colorFontSecondary">Em que você quer melhorar hoje?</p>
          </div>

          <div class="cardMain__contenScroll cardMain__container">
            <a
              href="javascript:void()"
              class="cardTema cardTema--green hoverTransform"
              title="Empatia para equipes"
              (click)="messageInDevelopment()"
            >
              Empatia para equipes
            </a>

            <a
              href="javascript:void()"
              class="cardTema cardTema--dark hoverTransform"
              title="Resolução de Conflitos"
              (click)="messageInDevelopment()"
            >
              Resolução de Conflitos
            </a>

            <a
              href="javascript:void()"
              class="cardTema cardTema--red hoverTransform"
              title="Inteligência Emocional"
              (click)="messageInDevelopment()"
            >
              Inteligência Emocional
            </a>

            <a
              href="javascript:void()"
              class="cardTema cardTema--purple hoverTransform"
              title="Comunicação Efetiva"
              (click)="messageInDevelopment()"
            >
              Comunicação Efetiva
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</main>
