import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IInvalid } from 'src/app/pages/configuracoes/palestrante/tb-dados';
import { CommonService } from 'src/app/services/common';
import { PalestrantesService } from 'src/app/services/palestrantes';

interface Itema {
  id: number
  nome: string
  descricao: string
  foto: string
  item_pai: number
  id_assunto_pai: number
  nome_pai: string
  checked: boolean
  iserted: boolean
}
@Component({
  selector: 'app-temas-admin',
  templateUrl: './temas-admin.component.html',
  styleUrls: ['./temas-admin.component.scss']
})
export class TemasAdminComponent implements OnInit {
  @Input() palestrante: any;
  @Output() onClose = new EventEmitter()

  invalids: Array<IInvalid> = []
  loading: boolean = false
  temasPalestrante: any

  temasPai: Array<Itema> = []
  temas: Array<Itema> = []
  temasFilhoFilter: Array<Itema> = []
  idTemaPrincipal: number;

  temaSelecionado: Itema = null

  search: string = ""
  searchTemaFilho: string = ""

  allChildren: boolean = false

  constructor(
    private palestrantesService: PalestrantesService,
    private commonService: CommonService,
    private toast: ToastrService,
    private router: Router
  ) { }

  async ngOnInit() {
    await this.getConhecimentosByUsuarioId()
    await this.getTemas()

    this.selectTemaPai(this.temasPai[0])
  }

  async getConhecimentosByUsuarioId() {
    try {
      this.loading = true
      this.temasPalestrante = await this.palestrantesService.getConhecimentosByPalestranteId(this.palestrante)
      for (const key in this.temasPalestrante) {
        const element = this.temasPalestrante[key];
        if (element.assunto_principal == 1) {
          this.idTemaPrincipal = element.assuntos_id
          break
        }
      }

      this.loading = false
    } catch (error) {
      this.toast.error("Algum erro ocorreu ao buscar seus temas")
      this.loading = false
    }
  }

  async getTemas() {
    this.temas = await this.commonService.getAllTemas(false)
    this.temas.forEach((i: Itema) => {
      i.iserted = this.hasTema(i.id)
      i.checked = i.iserted
    })
    this.temasPai = this.temas.filter((i: Itema) => i.item_pai == 1)
  }

  hasTema(id: number): boolean {
    for (const key in this.temasPalestrante) {
      if (this.temasPalestrante[key].assuntos_id == id) return true
    }
    return false
  }

  async salvar() {
    try {
      this.invalids = []

      if (!this.validateMaxTemas()) return

      if (!this.idTemaPrincipal || !(this.idTemaPrincipal > 0)) {
        this.invalids.push({ campo: "idTemaPrincipal", erro: "Informe o seu tema principal" })
        this.toast.error("Selecione seu tema principal")
        return
      }

      this.loading = true
      let arrayToSend: Array<any> = this.temas.filter((i: Itema) => (i.iserted == true || i.checked))
      let res = await this.palestrantesService.updateConhecimentosByPalestranteId(arrayToSend, this.idTemaPrincipal, this.palestrante)
      this.loading = false
      this.toast.success("Seus temas foram salvos com sucesso!")
      // this.router.navigate(['palestrante/perfil/produtos'])
      this.close()
    } catch (error) {
      this.toast.error("Algo deu errado a salvar temas")
      this.loading = false
    }
  }

  getTemaFiltered() {
    return this.temasPai.filter(i => i.nome.toLowerCase().includes(this.search.toLowerCase()))
  }

  getTemaFilhoFiltered(){
    return this.temasFilhoFilter.filter(i => i.nome.toLowerCase().includes(this.searchTemaFilho.toLowerCase()))
  }

  getTemaPrincipalFiltered() {
    return this.temas.filter((i: Itema) => (i.checked))
  }

  selectTemaPai(tema: Itema) {
    this.allChildren = false
    this.temaSelecionado = tema
    this.temasFilhoFilter = this.temas.filter(i => i.id_assunto_pai == this.temaSelecionado.id)
  }

  check(idTema: number) {
    const tema = this.getTema(idTema)
    const element = this.temas[tema.index]

    if (element.item_pai == 1) this.selectTemaPai(element)
    if (element.id_assunto_pai > 0 && element.checked == true) this.checkTemaPai(element.id_assunto_pai)
    if (element.item_pai == 1 && element.checked == false) this.uncheckedChildren(element.id)

    this.validateMaxTemas()
  }

  validateMaxTemas() {
    const temasMacroSelecteds: Array<any> = this.temasPai.filter((i: Itema) => (i.checked))

    if (temasMacroSelecteds.length >= 7) {
      this.toast.error('Selecione no máximo 6 Temas Macro.', 'O limite foi excedido!')

      return false
    }

    return true
  }

  getTema(idTema: number): any {
    for (const key in this.temas) {
      if (this.temas[key].id == idTema) return { item: this.temas[key].id, index: key }
    }
  }

  checkTemaPai(idTema: number): any {
    for (const key in this.temas) {
      if (this.temas[key].id == idTema) {
        this.temas[key].checked = true
        return
      }
    }
  }

  uncheckedChildren(idPai: number) {
    for (const key in this.temas) {
      if (this.temas[key].id_assunto_pai == idPai) {
        this.temas[key].checked = false
      }
    }
  }

  checkedChildren(idPai: number) {
    for (const key in this.temas) {
      if (this.temas[key].id_assunto_pai == idPai) {
        this.temas[key].checked = true
      }
    }
  }

  setTemaPai(id: number): boolean {
    for (const key in this.temasPai) {
      if (this.temasPai[key].id == id) {
        this.temasPai[key].checked = true
        return
      }
    }
  }

  setValuesAllChildren() {
    if (this.allChildren == false) this.uncheckedChildren(this.temaSelecionado.id)
    if (this.allChildren == true) {
      this.checkedChildren(this.temaSelecionado.id)
      this.checkTemaPai(this.temaSelecionado.id)
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  close(){
    this.onClose.emit('')
  }
}
