<div *ngIf="canEdit">
  <div class="customTabs pl-5">
    <ul class="nav nav-tabs">
      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          [ngClass]="{ active: activeTab === 'DADOS' }"
          (click)="changeActiveTab('DADOS')"
          >DADOS</span
        >
      </li>
      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          (click)="changeActiveTab('TEMAS')"
          [ngClass]="{ active: activeTab === 'TEMAS' }"
          >TEMAS</span
        >
      </li>

      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          (click)="changeActiveTab('PRODUTOS')"
          [ngClass]="{ active: activeTab === 'PRODUTOS' }"
          >PRODUTOS</span
        >
      </li>

      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          (click)="changeActiveTab('CACHES')"
          [ngClass]="{ active: activeTab === 'CACHES' }"
          >CACHES</span
        >
      </li>

      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          (click)="changeActiveTab('EXPERIENCIA')"
          [ngClass]="{ active: activeTab === 'EXPERIENCIA' }"
          >EXPERIÊNCIA</span
        >
      </li>

      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          (click)="changeActiveTab('FORMACAO')"
          [ngClass]="{ active: activeTab === 'FORMACAO' }"
          >FORMAÇÃO</span
        >
      </li>

      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          (click)="changeActiveTab('DEPOIMENTOS')"
          [ngClass]="{ active: activeTab === 'DEPOIMENTOS' }"
          >DEPOIMENTOS</span
        >
      </li>

      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          (click)="changeActiveTab('REPOSITORIO')"
          [ngClass]="{ active: activeTab === 'REPOSITORIO' }"
          >REPOSITORIO</span
        >
      </li>

      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          (click)="changeActiveTab('GESTAO')"
          [ngClass]="{ active: activeTab === 'GESTAO' }"
          >GESTÃO DE PALESTRANTES</span
        >
      </li>

      <li class="nav-item cursorPointer">
        <span
          class="nav-link p-2"
          (click)="changeActiveTab('EXCLUSIVO')"
          [ngClass]="{ active: activeTab === 'EXCLUSIVO' }"
          >EXCLUSIVO</span
        >
      </li>
    </ul>
  </div>

  <app-dados-admin
    style="width: 100%"
    *ngIf="activeTab === 'DADOS'"
    [usuarioId]="usuarioId"
  ></app-dados-admin>

  <app-temas-admin
    style="width: 100%"
    *ngIf="activeTab === 'TEMAS'"
    [palestrante]="palestrante"
  ></app-temas-admin>

  <app-palestras-disponiveis-admin
    style="width: 100%"
    *ngIf="activeTab === 'PRODUTOS'"
    [palestrante]="palestrante"
  ></app-palestras-disponiveis-admin>

  <app-cache-admin
    style="width: 100%"
    *ngIf="activeTab === 'CACHES'"
    [palestrante]="palestrante"
  ></app-cache-admin>

  <app-experiencia-admin
    style="width: 100%"
    *ngIf="activeTab === 'EXPERIENCIA'"
    [palestrante]="palestrante"
  ></app-experiencia-admin>

  <app-formacao-admin
    style="width: 100%"
    *ngIf="activeTab === 'FORMACAO'"
    [palestrante]="palestrante"
  ></app-formacao-admin>

  <app-depoimentos-admin
    style="width: 100%"
    *ngIf="activeTab === 'DEPOIMENTOS'"
    [palestrante]="palestrante"
  ></app-depoimentos-admin>

  <app-repositorio-admin
    *ngIf="activeTab === 'REPOSITORIO'"
    [palestrante]="palestrante"
  ></app-repositorio-admin>

  <app-gestao-admin
    *ngIf="activeTab === 'GESTAO'"
    [palestrante]="palestrante"
  ></app-gestao-admin>
  <app-exclusivo-admin
    *ngIf="activeTab === 'EXCLUSIVO'"
    [palestrante]="palestrante"
  ></app-exclusivo-admin>
</div>

<div *ngIf="!canEdit">
  <p class="m-4">Você não tem autorização para acessar essa rota!</p>
</div>
