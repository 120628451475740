<div class="conteiner-fluid px-0 px-md-2 container__dados">

    <form class="row" (submit)="submit()">

        <div class="col-12 col-lg-3 d-flex flex-column container__foto">
            <div>
                <app-foto-perfil [foto]="user?.photo" (refresh)="refreshPhoto()"></app-foto-perfil>
            </div>

            <div class="form-group">
                <label class="label-input" for="id">seu código para indicação</label>
                <input class="form-control" name="id" type="text" [value]="user.id" disabled>
            </div>

            <div class="form-group">
                <label class="label-input" for="pais">quem te indicou</label>
                <input class="form-control" name="pais" type="text" [value]="user.codigo_indicacao" disabled>
            </div>

            <div style="flex: 1">
                <app-concorda-termos></app-concorda-termos>
            </div>
        </div>

        <div class="col-12 col-lg-9">
            <div class="row">
                <div class="form-group col-12 col-lg-5">
                    <label class="label-input" for="nome">nome *</label>
                    <input id="nome" [class.invalid]="isIncorrect('nome')" class="form-control" name="nome" type="text"
                        [(ngModel)]="user.nome" placeholder="Nome *">
                    <span class="error">{{getError('nome')}}</span>
                </div>

                <div class="form-group col-12 col-lg-7">
                    <label id="sobrenome" class="label-input" for="sobrenome">sobrenome *</label>
                    <input [class.invalid]="isIncorrect('sobrenome')" class="form-control" name="sobrenome" type="text"
                        [(ngModel)]="user.sobrenome" placeholder="Sobrenome">
                    <span class="error">{{getError('sobrenome')}}</span>
                </div>


                <div class="form-group col-12 col-lg-6">
                    <label class="label-input" for="cpf">cpf *</label>
                    <input id="cpf" [class.invalid]="isIncorrect('cpf')" class="form-control" name="cpf" type="text"
                        [(ngModel)]="user.cpf" mask="000.000.000-00" placeholder="CPF">
                    <span class="error">{{getError('cpf')}}</span>
                </div>

                <div class="form-group col-12 col-lg-6">
                    <label class="label-input" for="email">e-mail *</label>
                    <input class="form-control" name="email" type="text" [(ngModel)]="user.email" placeholder="E-mail *"
                        disabled>
                </div>



                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="telefone">telefone *</label>
                    <input id="telefone" [class.invalid]="isIncorrect('telefone')" class="form-control" name="telefone"
                        type="text" [(ngModel)]="user.telefone" placeholder="Telefone *">
                    <span class="error">{{getError('telefone')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="id_cargo">Cargo *</label>
                    <select id="id_cargo" [class.invalid]="isIncorrect('id_cargo')" class="form-control" name="id_cargo"
                        placeholder="Estado *" [(ngModel)]="user.id_cargo">
                        <option value="">Selecione *</option>
                        <option *ngFor="let cargo of cargos" [value]="cargo.id">{{cargo.descricao}}</option>
                    </select>
                    <span class="error">{{getError('id_cargo')}}</span>
                </div>

                <div [class.d-none]="user.id_cargo != 6" class="form-group col-12 col-lg-4">
                    <label class="label-input" for="cargo">Informe o cargo *</label>
                    <input id="cargo" [class.invalid]="isIncorrect('cargo')" class="form-control" name="cargo"
                        type="text" [(ngModel)]="user.cargo" placeholder="Cargo *">
                    <span class="error">{{getError('cargo')}}</span>
                </div>


                <h5 class="font-title mt-3">Endereço</h5>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="pais">país *</label>
                    <input id="pais" [class.invalid]="isIncorrect('pais')" class="form-control" name="pais" type="text"
                        [(ngModel)]="user.pais" placeholder="País *">
                    <span class="error">{{getError('pais')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="cep">cep *</label>
                    <input id="cep" [class.invalid]="isIncorrect('cep')" (change)="completeCEP()" class="form-control"
                        name="cep" type="text" [(ngModel)]="user.cep" placeholder="CEP *" mask="00000-000">
                    <span class="error">{{getError('cep')}}</span>
                </div>
                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="estado">estado *</label>
                    <select id="estado" [class.invalid]="isIncorrect('estado')" class="form-control" name="estado"
                        placeholder="Estado *" [(ngModel)]="user.estado">
                        <option value="">Selecione *</option>
                        <option *ngFor="let estado of arrayStates" [value]="estado.uf">{{estado.estado}}</option>
                    </select>
                    <span class="error">{{getError('estado')}}</span>
                </div>



                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="estado">cidade *</label>
                    <input id="cidade" [class.invalid]="isIncorrect('cidade')" class="form-control" name="cidade"
                        type="text" [(ngModel)]="user.cidade" placeholder="Cidade *">
                    <span class="error">{{getError('cidade')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="bairro">bairro *</label>
                    <input id="bairro" [class.invalid]="isIncorrect('bairro')" class="form-control" name="bairro"
                        type="text" [(ngModel)]="user.bairro" placeholder="Bairro *">
                    <span class="error">{{getError('bairro')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="numero">número *</label>
                    <input id="numero" [class.invalid]="isIncorrect('numero')" class="form-control" name="numero"
                        type="text" [(ngModel)]="user.numero" placeholder="Número *">
                    <span class="error">{{getError('numero')}}</span>
                </div>

                <div class="form-group col-12">
                    <label class="label-input" for="logradouro">logradouro *</label>
                    <input id="logradouro" [class.invalid]="isIncorrect('logradouro')" class="form-control"
                        name="logradouro" type="text" [(ngModel)]="user.logradouro" placeholder="Logradouro *">
                    <span class="error">{{getError('logradouro')}}</span>
                </div>


                <div class="d-flex div__btn__salvar">
                    <button *ngIf="!loading" type="submit" class="btn__primary">Salvar</button>

                    <button *ngIf="loading" class="btn__primary mt-2">
                        <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
                    </button>
                </div>

            </div>
        </div>
    </form>
</div>