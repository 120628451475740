<div class="container px-5 py-4">
    <div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </div>
    <div class="row flexFull">
        <div class="col-12 flexFull">
            <img src="assets/icons/draw.svg" alt="gerando mídias">
        </div>
        <h1 class="font-title">Estamos gerando suas mídias personalizadas!</h1>
        <h4 class="font-title">Em breve receberá em seu e-mail.</h4>
    </div>
</div>