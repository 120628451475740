import { Component, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ngxCsv } from "ngx-csv";
import { ModalDepoimentosComponent } from "src/app/common/depoimentos/modaldepoimentos.component";
import { PalestrantesService } from "src/app/services/palestrantes";

@Component({
  selector: 'app-resumo-indicacoes',
  templateUrl: './resumo-indicacoes.component.html',
  styleUrls: ['./resumo-indicacoes.component.scss'],
})
export class ResumoIndicacoesComponent implements OnInit{
  speakers: any = [];
  speakersListSmall: any = [];
  speakersListComplete: any = [];

  completeSpeakers: any = [];
  proposals: any = [];
  curadoresComMaisIndicacoes: any = ['--', '--', '--'];

  loading: boolean = false;

  //table pagination
  pages: number;
  currentPage: number = 0;

  //filters
  filterI: boolean = false;
  filterP: boolean = true;
  filterTM: boolean = true;
  filterT: boolean = true;
  filterName: string = '';
  dateStart: any = null;
  dateEnd: any = null;

  headers: string[] = [
    'Nome',
    'Selo',
    'NPS',
    'Último curador à indicar',
    'Ticket médio palestrante',
    'Indicações principais',
    'Indicações totais',
    'Curadores que mais indicam',
  ]

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Planilha de respostas',
    useBom: true,
    noDownload: false,
    headers: this.headers,
    eol: '\n'
  };

  constructor(
    private speakersService: PalestrantesService,
    private bsModalRef: BsModalRef,
    private modalService:BsModalService,
  ) {
  }

  ngOnInit(): void {
    this.getSpeakers();
  }

  async getSpeakers() {
    this.loading = true;

    this.speakersService.getCompleteSpeakers(this.dateStart, this.dateEnd).subscribe(
      (result: any) => {
        console.log(result.body[0]);

        this.speakers = result.body;
        this.completeSpeakers = this.speakers;

        this.loading = false;
      }
    );
  }

  async getTicketMedio() {
    this.speakersService.getTicketMedio().subscribe(
      (result: any) => {

        this.proposals = result.body;

        this.speakers.forEach((speaker) => {
          speaker.ticketProposta = this.calcTicketProposta(speaker?.propostas);
          speaker.ticketMedio = this.calcTicket(speaker?.ticketArr, speaker?.ticketMedio);
        })

        this.loading = false;
      }
    );
  }

  calcTicketProposta(values: string) {
    if (!values) {
      return 'R$ 0,00';
    }

    const propostas = values.split(',');

    const tickets = propostas.map((proposta) => {
      const [proposalValue] = this.proposals.filter(({ propostaId }) => propostaId ==  proposta);

      return Number(proposalValue?.valor);
    });

    return tickets.reduce((acc, curr) => acc + curr) / tickets.length;
  }

  calcTicket(values: any, sum: number) {
    if (!values) {
      return 'R$ 0,00';
    }

    const valuesArr = values.split(',');
    return (sum / valuesArr.length);
  }

  findCuradorIndicacoes(curadores: Array<string>) {
  let contagem = {};

  for (let i = 0; i < curadores.length; i++) {
    let item = curadores[i];
    contagem[item] = (contagem[item] || 0) + 1;
  }

  let contagemArray = [];
  for (let item in contagem) {
    contagemArray.push([item, contagem[item]]);
  }

  contagemArray.sort(function (a, b) {
    return b[1] - a[1];
  });

  let resultado = [];
  for (let i = 0; i < contagemArray.length; i++) {
    resultado.push(contagemArray[i][0]);
  }

  return resultado;
}

  getCuradores(values: string) {
    if(!values || values.length == 0) {
      this.curadoresComMaisIndicacoes = ['--', '--', '--']
      return;
    }
    const propostas = values.split(',');

    const curadores = propostas.map((proposta) => {
      const [proposalValue] = this.proposals.filter(({ propostaId }) => propostaId ==  proposta);

      return proposalValue?.curador;
    });

    this.curadoresComMaisIndicacoes = this.findCuradorIndicacoes(propostas);
  }


  filterByIndicacoes() {
    if(this.filterI) {
      this.speakers.sort((propostaA, propostaB) => {
        if (propostaA.indicacoes < propostaB.indicacoes) {
          return -1;
        }

        if (propostaA.indicacoes > propostaB.indicacoes) {
          return 1;
        }

        return 0;
      })

    } else {
      this.speakers.sort((propostaA, propostaB) => {
        if (propostaA.indicacoes > propostaB.indicacoes) {
          return -1;
        }

        if (propostaA.indicacoes < propostaB.indicacoes) {
          return 1;
        }

        return 0;
      })
    }

    this.filterI = !this.filterI;
  }

  filterByPrincipal() {
    if(this.filterP) {
      this.speakers.sort((propostaA, propostaB) => {
        if (propostaA.indicacoesPrincipal < propostaB.indicacoesPrincipal) {
          return -1;
        }

        if (propostaA.indicacoesPrincipal > propostaB.indicacoesPrincipal) {
          return 1;
        }

        return 0;
      })

    } else {
      this.speakers.sort((propostaA, propostaB) => {
        if (propostaA.indicacoesPrincipal > propostaB.indicacoesPrincipal) {
          return -1;
        }

        if (propostaA.indicacoesPrincipal < propostaB.indicacoesPrincipal) {
          return 1;
        }

        return 0;
      })
    }

    this.filterP = !this.filterP;
  }

  filterByTicketMedio() {
    if(this.filterTM) {
      this.speakers.sort((propostaA, propostaB) => {
        if (Number(propostaA.ticketMedio) < Number(propostaB.ticketMedio)) {
          return -1;
        }

        if (Number(propostaA.ticketMedio) > Number(propostaB.ticketMedio)) {
          return 1;
        }

        return 0;
      })

    } else {
      this.speakers.sort((propostaA, propostaB) => {
        if (Number(propostaA.ticketMedio) > Number(propostaB.ticketMedio)) {
          return -1;
        }

        if (Number(propostaA.ticketMedio) < Number(propostaB.ticketMedio)) {
          return 1;
        }

        return 0;
      })
    }

    this.filterTM = !this.filterTM;
  }

  filterByTicket() {
    if(this.filterT) {
      this.speakers.sort((propostaA, propostaB) => {
        if (Number(propostaA.ticketProposta) < Number(propostaB.ticketProposta)) {
          return -1;
        }

        if (Number(propostaA.ticketProposta) > Number(propostaB.ticketProposta)) {
          return 1;
        }

        return 0;
      })

    } else {
      this.speakers.sort((propostaA, propostaB) => {
        if (Number(propostaA.ticketProposta) > Number(propostaB.ticketProposta)) {
          return -1;
        }

        if (Number(propostaA.ticketProposta) < Number(propostaB.ticketProposta)) {
          return 1;
        }

        return 0;
      })
    }

    this.filterT = !this.filterT;
  }

  filterByName() {
    this.speakers = this.completeSpeakers.filter((speaker: any) => speaker?.nomeCompleto?.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase()));
  }

  filterByDate() {
    this.getSpeakers();
  }
  resetDateFilter(): void {
    this.dateEnd = null;
    this.dateStart = null;
  }
  openDepoimentos(id: string | any) {
    const initialState = { id };
    const config = { class: 'modal-play', initialState };

    this.bsModalRef = this.modalService.show(ModalDepoimentosComponent, config);

    this.modalService.onHide.subscribe(
      (result: any) => {
        console.log('closed');
      }
    )
  }

  downloadCSV(): void {
    const data = this.speakers.map((speaker: any) => {
      delete speaker.id;
      return speaker;
    });

    new ngxCsv(data, 'planilha_indicacoes', this.options);
  }
}
