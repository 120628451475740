import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IPalestra } from 'src/app/pages/palestrante/form-evento';
import * as moment from 'moment';
import { ICertificate } from 'src/app/pages/espectador/certificados';

@Injectable({
  providedIn: 'root'
})
export class PalestrasService {

  constructor(public http: HttpClient) { }

  async getTemas(has_filter: boolean, id_pai: number, search: string): Promise<any> {
    let search_param = `?pai=${id_pai}&text=${search.toLowerCase().trim()}`

    const res = await this.http.get<any>(`${environment.API_V2_URL}common/assuntos/${has_filter ? 1 : 0}${search_param}`).toPromise();
    return res.body
  }

  async getTemaByName(assunto_nome: string): Promise<any> {
    const res = await this.http.get<any>(`${environment.API_V2_URL}common/especialistasPorTema/${assunto_nome}`).toPromise();
    return res.body
  }

  async getPalestraDisponivelBySlug(slug: string): Promise<any> {
    const res = await this.http.get<any>(`${environment.API_V2_URL}common/palestrasDisponiveis/${slug}`).toPromise();
    return res.body
  }

  async insertOrUpdate(palestra: IPalestra): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const body = { palestra }
    const res = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}palestras/ministradas/insert-update`, body, { headers: headers }).toPromise();
    return res.body
  }


  async getMinistradaById(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const palestrante = JSON.parse(localStorage.getItem("palestrante"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}palestras/ministrada/${id}/${palestrante.id}`, { headers: headers }).toPromise();
    return result.body;
  }


  async generateMedias(id: number): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/gerar-midias/${id}`).toPromise();
    return result.body;
  }

  async getPalestrasAgenda(all: boolean): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}palestras/agenda/${all}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getPalestrasAnteriores(page: number, limit: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}palestras/agenda/anteriores/${page}/${limit}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getProximas(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/palestras/proximas`, { headers: headers }).toPromise();
    return result.body;
  }

  async setMidia(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "palestras/update-midia"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getPalestraById(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/palestras/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async checkSubscription(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/palestras/checksubscription/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async subscribe(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = JSON.stringify({ id: id });
    const result = await this.http.post<any>(`${environment.API_V2_URL}app-espectador/palestras/participar/${id}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async generateCertificate(id: number): Promise<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}espectador/getCertificate/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getLecturesAndCertificates(): Promise<Array<ICertificate>> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/profile/lectures-certificates`, { headers: headers }).toPromise();
    return result?.body?.certificados;
  }

  async getToken(): Promise<string> {
    let usuario = JSON.parse(localStorage.getItem("usuario"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}profissionais/auth/token/${usuario.id}`).toPromise();
    return result.body;
  }

  async getDestaques(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/palestras/highlights`, { headers: headers }).toPromise();
    return result.body;
  }

  async getPercent(): Promise<any> {
    let headers = new HttpHeaders();
    const espectador = JSON.parse(localStorage.getItem("espectador"));
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/palestras/percent/${espectador.id_espectador}`, { headers: headers }).toPromise();
    return result.body;
  }

  async postAnotacao(habilidadeId, observacao): Promise<any> {
    let headers = new HttpHeaders();
    const espectador = JSON.parse(localStorage.getItem("espectador"));
    const body = {observacao, espectador_id: espectador.id_espectador, habilidade_id: habilidadeId}
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result =  await this.http.post<any>(`${environment.API_V2_URL}app-espectador/conteudos/anotacao`, body, {headers: headers}).toPromise();
    return result.body;
  }

  async updateAnotacao(anotacaoId, observacao): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = {observacao}
    const result =  await this.http.put<any>(`${environment.API_V2_URL}app-espectador/conteudos/anotacao/${anotacaoId}`, body, {headers: headers}).toPromise();
    return result.body;
  }

  async getAnotacoes(habilidadeId): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result =  await this.http.get<any>(`${environment.API_V2_URL}app-espectador/conteudos/anotacaoByHabilidade/${habilidadeId}`, {headers: headers}).toPromise();
    return result.body;
  }

  async deleteAnotacao(anotacaoId): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result =  await this.http.delete<any>(`${environment.API_V2_URL}app-espectador/conteudos/anotacao/${anotacaoId}`, {headers: headers}).toPromise();
    return result.body;
  }

  async getPrevious(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}app-espectador/palestras/anteriores`).toPromise();
    let body = result.body;
    body.map((p) => (p.data = moment(p.data).format('DD [de] MMM [de] YYYY')));
    return body;
  }


  slugify(text) {
    return text.toLowerCase().trim()
      .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a')
      .replace(/[èÈéÉêÊëË]+/g, 'e')
      .replace(/[ìÌíÍîÎïÏ]+/g, 'i')
      .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o')
      .replace(/[ùÙúÚûÛüÜ]+/g, 'u')
      .replace(/\s/g, '-')
  }
}
