export function getPathHome() {
  const perfil = localStorage.getItem('perfil');
  let path: string = '/home';

  switch (perfil) {
    case 'ADMIN':
      path = '/admin/home';
      break;

    case  'AVALIADOR':
      path = '/admin/home';

    case 'GESTAO':
      path = '/admin/home';

    case 'GESTAOP':
    path = '/admin/home';

    case 'CS':
    path = '/admin/home';

    case 'CURADORA':
      path = '/admin/home';
      break;

    case 'CURADORB':
      path = '/admin/home';
      break;

    case 'CURADORC':
      path = '/admin/home';
      break;

    case 'CONTRATANTE':
      path = '/home';
      break;

    case 'PALESTRANTE':
      path = '/palestrante/meu-perfil';
      break;

    case 'ESPECTADOR':
      path = '/espectador/home';
      break;

    default:
      path = '/home';
      break;
  }
  return path;
}

export function getPathNotifications() {
  const perfil = localStorage.getItem('perfil');
  let path: string = '/notificacoes';

  switch (perfil) {
    case 'CONTRATANTE':
      path = '/notificacoes';
      break;

    case 'PALESTRANTE':
      path = '/palestrante/notificacoes';
      break;

    case 'ESPECTADOR':
      path = '/espectador/notificacoes';
      break;

    default:
      path = '/notificacoes';
      break;
  }
  return path;
}

export const getPathPerfil = () => {
  const perfil = localStorage.getItem('perfil');
  let path: string = '/perfil';

  switch (perfil) {
    case 'CONTRATANTE':
      path = '/perfil';
      break;

    case 'PALESTRANTE':
      path = '/palestrante/perfil';
      break;

    case 'ESPECTADOR':
      path = '/espectador/perfil';
      break;

    default:
      path = '/perfil';
      break;
  }
  return path;
};

export const getPathAgenda = () => {
  const perfil = localStorage.getItem('perfil');
  let path: string = null;

  switch (perfil) {
    case 'CONTRATANTE':
      path = null;
      break;

    case 'PALESTRANTE':
      path = '/palestrante/agenda';
      break;

    case 'ESPECTADOR':
      path = null;
      break;

    default:
      path = null;
      break;
  }
  return path;
};

export const getMenuPrincipal = () => {
  const perfil = localStorage.getItem('perfil');
  let itemsMenuPrincipal: Array<any> = [];

  switch (perfil) {
    case 'ADMIN':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Home',
          route: 'admin/home',
          srcIcon: '../../../assets/newIcons/iconHome.svg',
        },
        {
          id: 2,
          text: 'Palestrantes',
          route: 'admin/palestrantes',
          srcIcon: '../../../assets/icons/person.svg',
        },
        {
          id: 3,
          text: 'Propostas Geradas',
          route: 'curador/palestras',
          srcIcon: '../../../assets/icons/conteudo.svg',
        },
        {
          id: 4,
          text: 'Gerenciar Usuários',
          route: 'admin/usuarios',
          srcIcon: '../../../assets/icons/editar.svg',
        },
        {
          id: 5,
          text: 'Cadastrar Usuário',
          route: 'admin/usuarios/cadastro',
          srcIcon: '../../../assets/icons/add.svg',
        },
        {
          id: 6,
          text: 'Pesquisar',
          route: 'admin/pesquisar',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 7,
          text: 'Resultados',
          route: 'admin/resultados',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        {
          id: 8,
          text: 'Resumo Indicações',
          route: 'curador/resumo-indicacoes',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        {
          id: 9,
          text: 'Pesquisas (Site)',
          route: 'admin/pesquisas-site',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 10,
          text: 'Criar Formulário de Evento',
          route: 'admin/certificados',
          srcIcon: '../../../assets/icons/add.svg'
        },
        {
          id: 11,
          text: 'Respostas Certificados',
          route: 'admin/respostas-formulario',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 12,
          text: 'Validar Certificados',
          route: 'admin/verificar-certificados',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 12,
          text: 'THE BEST SPEAKER BRAZIL',
          route: 'admin/tbsb',
          srcIcon: '../../../assets/icons/lecture.svg',
        }
      ];
      break;
    case 'AVALIADOR':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Home',
          route: 'admin/home',
          srcIcon: '../../../assets/newIcons/iconHome.svg',
        },
        {
          id: 2,
          text: 'Palestrantes',
          route: 'admin/palestrantes',
          srcIcon: '../../../assets/icons/person.svg',
        },
        {
          id: 3,
          text: 'Propostas Geradas',
          route: 'curador/palestras',
          srcIcon: '../../../assets/icons/conteudo.svg',
        },
        {
          id: 6,
          text: 'Pesquisar',
          route: 'admin/pesquisar',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 7,
          text: 'Resultados',
          route: 'admin/resultados',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        {
          id: 8,
          text: 'Resumo Indicações',
          route: 'curador/resumo-indicacoes',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
      ];
      break;
    case 'CURADORA':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Home',
          route: 'admin/home',
          srcIcon: '../../../assets/newIcons/iconHome.svg',
        },
        {
          id: 3,
          text: 'Propostas Geradas',
          route: 'curador/palestras',
          srcIcon: '../../../assets/icons/conteudo.svg',
        },
        {
          id: 4,
          text: 'Pesquisar',
          route: 'admin/pesquisar',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 5,
          text: 'Resultados',
          route: 'admin/resultados',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        {
          id: 6,
          text: 'Resumo Indicações',
          route: 'curador/resumo-indicacoes',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
      ];
      break;

    case 'CURADORB':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Home',
          route: 'admin/home',
          srcIcon: '../../../assets/newIcons/iconHome.svg',
        },
        {
          id: 3,
          text: 'Propostas Geradas',
          route: 'curador/palestras',
          srcIcon: '../../../assets/icons/conteudo.svg',
        },
        {
          id: 4,
          text: 'Pesquisar',
          route: 'admin/pesquisar',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 5,
          text: 'Resultados',
          route: 'admin/resultados',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        // {
        //   id: 6,
        //   text: 'Resumo Indicações',
        //   route: 'curador/resumo-indicacoes',
        //   srcIcon: '../../../assets/icons/list_check.svg',
        // }
      ];
      break;

    case 'CURADORC':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Home',
          route: 'admin/home',
          srcIcon: '../../../assets/newIcons/iconHome.svg',
        },
        {
          id: 3,
          text: 'Propostas Geradas',
          route: 'curador/palestras',
          srcIcon: '../../../assets/icons/conteudo.svg',
        },
        {
          id: 4,
          text: 'Pesquisar',
          route: 'admin/pesquisar',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 5,
          text: 'Resultados',
          route: 'admin/resultados',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        // {
        //   id: 6,
        //   text: 'Resumo Indicações',
        //   route: 'curador/resumo-indicacoes',
        //   srcIcon: '../../../assets/icons/list_check.svg',
        // }
      ];
      break;
    case 'CONTRATANTE':
      itemsMenuPrincipal = [
        // {
        //   id: 1,
        //   text: 'Educação Corporativa',
        //   route: 'universidade/home',
        //   srcIcon: '../../../assets/icons/ead.svg',
        // },
        // {
        //   id: 4,
        //   text: 'Meus Conteúdos',
        //   route: 'conteudos/tipo',
        //   srcIcon: 'assets/icons/conteudo.svg',
        // },
        // {
        //   id: 6,
        //   text: 'Comunicação Interna',
        //   route: 'comunicacao-interna',
        //   srcIcon: 'assets/icons/alert.svg',
        // },
        // { id: 6, text: 'Comunicação interna', route: 'conteudos/competencias', srcIcon: "assets/icons/alert.svg" },
        // {
        //   id: 5,
        //   text: 'Calculadora T&D',
        //   route: 'calculadora/passo1',
        //   srcIcon: '../../../assets/icons/calc.svg',
        // },
        // {
        //   id: 2,
        //   text: 'Minhas contrataçōes',
        //   route: null,
        //   srcIcon: '../../../assets/icons/microfone.svg',
        // },
        // {
        //   id: 3,
        //   text: 'Contrate Especialistas',
        //   route: 'especialistas',
        //   srcIcon: '../../../assets/icons/orcamentos.svg',
        // },
        // { id: 4, text: 'Meus Orçamentos', route: null, srcIcon: "../../../assets/icons/ead.svg" },
        // { id: 4, text: 'grupo SA', route: null, srcIcon: "../../../assets/icons/sa.svg" },
      ];
      break;

    case 'PALESTRANTE':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Meu Perfil',
          route: 'palestrante/meu-perfil',
          srcIcon: '../../../assets/icons/person.svg',
        },
        // { id: 5, text: 'Meus eventos', route: 'palestrante/agenda', srcIcon: "../../../assets/icons/calendar.svg" },
        // { id: 2, text: 'Minhas oportunidades', route: null, srcIcon: "../../../assets/icons/dardo.svg" },
        // { id: 3, text: 'Minhas contratações', route: null, srcIcon: "../../../assets/icons/microfone.svg" },
        // { id: 4, text: 'Meus conteúdos', route: null, srcIcon: "../../../assets/icons/conteudo.svg" },
        // { id: 6, text: 'Configurações', route: 'palestrante/perfil', srcIcon: "../../../assets/icons/setting.svg" },
        // { id: 7, text: 'Calculadora T&D', route: 'calculadora/passo1', srcIcon: "../../../assets/icons/calc.svg" },
      ];
      break;

    case 'ESPECTADOR':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Dashboard',
          route: 'espectador/home',
          srcIcon: '../../../assets/newIcons/iconHome.svg',
        },
        {
          id: 4,
          text: 'Palestras',
          route: 'espectador/agenda',
          srcIcon: '../../../assets/newIcons/iconPalestras.svg',
        },
        {
          id: 2,
          text: 'Minha trilha',
          route: 'espectador/minhaTrilha',
          srcIcon: '../../../assets/newIcons/iconTrilhas.svg',
        },
        {
          id: 3,
          text: 'Meus certificados',
          route: 'espectador/certificados',
          srcIcon: '../../../assets/icons/certificate.svg',
        },
      ];
      break;

    case 'GESTOR':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Home',
          route: 'admin/home',
          srcIcon: '../../../assets/newIcons/iconHome.svg',
        },
        {
          id: 2,
          text: 'Palestrantes',
          route: 'admin/palestrantes',
          srcIcon: '../../../assets/icons/person.svg',
        },
        {
          id: 3,
          text: 'Propostas Geradas',
          route: 'curador/palestras',
          srcIcon: '../../../assets/icons/conteudo.svg',
        },
        {
          id: 5,
          text: 'Cadastrar Usuário',
          route: 'admin/usuarios/cadastro',
          srcIcon: '../../../assets/icons/add.svg',
        },
        {
          id: 6,
          text: 'Pesquisar',
          route: 'admin/pesquisar',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 7,
          text: 'Resultados',
          route: 'admin/resultados',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        {
          id: 8,
          text: 'Resumo Indicações',
          route: 'curador/resumo-indicacoes',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        {
          id: 9,
          text: 'Pesquisas (Site)',
          route: 'admin/pesquisas-site',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 10,
          text: 'Criar Formulário de Evento',
          route: 'admin/certificados',
          srcIcon: '../../../assets/icons/add.svg'
        },
        {
          id: 11,
          text: 'Respostas Certificados',
          route: 'admin/respostas-formulario',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 12,
          text: 'Validar Certificados',
          route: 'admin/verificar-certificados',
          srcIcon: '../../../assets/icons/search-black.svg',
        }
      ];
      break;
    case 'GESTORP':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Home',
          route: 'admin/home',
          srcIcon: '../../../assets/newIcons/iconHome.svg',
        },
        {
          id: 2,
          text: 'Palestrantes',
          route: 'admin/palestrantes',
          srcIcon: '../../../assets/icons/person.svg',
        },
        {
          id: 3,
          text: 'Propostas Geradas',
          route: 'curador/palestras',
          srcIcon: '../../../assets/icons/conteudo.svg',
        },
        {
          id: 6,
          text: 'Pesquisar',
          route: 'admin/pesquisar',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 7,
          text: 'Resultados',
          route: 'admin/resultados',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        {
          id: 8,
          text: 'Resumo Indicações',
          route: 'curador/resumo-indicacoes',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
      ]
      break;

    case 'CS':
      itemsMenuPrincipal = [
        {
          id: 1,
          text: 'Home',
          route: 'admin/home',
          srcIcon: '../../../assets/newIcons/iconHome.svg',
        },
        {
          id: 2,
          text: 'Palestrantes',
          route: 'admin/palestrantes',
          srcIcon: '../../../assets/icons/person.svg',
        },
        {
          id: 6,
          text: 'Pesquisar',
          route: 'admin/pesquisar',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 7,
          text: 'Resultados',
          route: 'admin/resultados',
          srcIcon: '../../../assets/icons/list_check.svg',
        },
        {
          id: 10,
          text: 'Criar Formulário de Evento',
          route: 'admin/certificados',
          srcIcon: '../../../assets/icons/add.svg'
        },
        {
          id: 11,
          text: 'Respostas Certificados',
          route: 'admin/respostas-formulario',
          srcIcon: '../../../assets/icons/search-black.svg',
        },
        {
          id: 12,
          text: 'Validar Certificados',
          route: 'admin/verificar-certificados',
          srcIcon: '../../../assets/icons/search-black.svg',
        }
      ];
      break;
    default:
      break;
  }
  return itemsMenuPrincipal;
};

export const getCommonMenu = () => {
  const perfil = localStorage.getItem('perfil');
  let items: Array<any> = [];

  switch (perfil) {
    case 'CONTRATANTE':
      items = [
        { id: 1, text: 'Especialistas', route: 'especialistas' },
        { id: 2, text: 'Palestras', route: 'palestras/temas' },
        { id: 4, text: 'SA News', route: 'sa-news/home' },
        {
          id: 5,
          text: 'O que fazemos',
          href: 'https://www.profissionaissa.com/o-que-fazemos',
        },
        {
          id: 6,
          text: 'Agenda de Eventos',
          href: 'https://www.profissionaissa.com/para-voce-agenda',
        },
      ];
      break;

    case 'PALESTRANTE':
      items = null;
      break;

    case 'ESPECTADOR':
      items = [
        // { id: 1, text: 'Especialistas', route: "especialistas" },
        // { id: 2, text: 'Palestras', route: "palestras/temas" },
        { id: 4, text: 'SA News', route: 'sa-news/home' },
        {
          id: 5,
          text: 'O que fazemos',
          href: 'https://www.profissionaissa.com/o-que-fazemos',
        },
        // { id: 6, text: 'Agenda de Eventos', href: "https://www.profissionaissa.com/para-voce-agenda" },
      ];
      break;

    default:
      break;
  }
  return items;
};

export function getUserLocalStorage() {
  const perfil = localStorage.getItem('perfil');
  let user;

  switch (perfil) {
    case 'CONTRATANTE':
      user = localStorage.getItem('usuario');
      break;

    case 'PALESTRANTE':
      user = localStorage.getItem('usuario');
      break;

    case 'ESPECTADOR':
      user = localStorage.getItem('espectador');
      if (!user) {
        user = localStorage.getItem('usuario');
      }
      break;

    default:
      user = localStorage.getItem('usuario');
      break;
  }
  return JSON.parse(user);
}

export function getKeyConfigSupportWhats() {
  const perfil = localStorage.getItem('perfil');
  let key;

  switch (perfil) {
    case 'CONTRATANTE':
      key = 'telefone__suporte__contratante';
      break;

    case 'PALESTRANTE':
      key = 'dashboard__espectador__suporte__whatsapp';
      break;

    case 'ESPECTADOR':
      key = 'telefone__contato__palestrante';
      break;

    default:
      key = 'telefone__suporte__contratante';
      break;
  }
  return key;
}

export function getPrincipalProfile() {
  const user = JSON.parse(localStorage.getItem('usuario'));
  return user.perfil.toUpperCase();
}
