import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { ModalAvisoCadastroComponent } from '../common/modal-aviso-cadastro';
import { ModalConfirmationComponent } from '../common/modal-confirmation';
import { ContratanteService } from '../services/contratante';
import { EspectadoresService } from '../services/espectadores';
import { PalestrantesService } from '../services/palestrantes';
import { UserService } from '../services/user';
import { SideComponent } from '../shared/side';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {
  bsModalRef: BsModalRef;
  showSide: boolean = true;
  showHeader: boolean = true;
  lockScreen: boolean = false;
  @ViewChild("side", { static: false }) side: SideComponent;

  constructor(
    private modalService: BsModalService,
    private contratanteService: ContratanteService,
    private palestrantesService: PalestrantesService,
    private espectadoresService: EspectadoresService,
    private userService: UserService,
    private app: AppComponent,
    private toast: ToastrService
  ) { }

  async ngOnInit() {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    const perfil = localStorage.getItem("perfil")

    switch (perfil) {
      case "CONTRATANTE":
        await this.contratante(usuario)
        break;

      case "PALESTRANTE":
        await this.palestrante()
        break;

      case "ESPECTADOR":
        await this.espectador(usuario.perfil)
        break;

      default:
        break;
    }


  }


  closeSide(val: boolean) {
    this.showSide = !val;
  }

  closeHeader(val: boolean) {
    this.showHeader = val;
  }

  avisoCadastro() {
    const config = {}
    this.bsModalRef = this.modalService.show(ModalAvisoCadastroComponent, config);
  }

  async getUsuario() {
    await this.ngOnInit()
    this.side.getUsuario()
  }

  async contratante(usuario: any) {
    this.contratanteService.getProfileIsComplete()
      .then(res => {
        let profileComplete: boolean = res.body.profileComplete
        if (profileComplete == false) this.avisoCadastro()
      })
      .catch(e => {
        this.isInvalidToken(e)
      })

    try {
      const body = { id_usuario: usuario.id }

      const valida = await this.userService.validaUsuario(body);
      const status = [64, 63, 62, 61, 60] //['diamante', 'safira', 'ouro', 'prata', 'bronze']

      if (!status.includes(valida.id_status) && valida.prazo == true) this.app.logout()

    } catch (e) {
      this.toast.error(e.error.message ? e.error.message : "Não foi possível estabelecer uma conexão")
      return;
    }
  }

  async palestrante() {
    try {
      this.palestrantesService.verificaPlano()
        .then(res => {
          sessionStorage.setItem("plano_nome", res.plano_nome)
        })
        .catch(e => {

          this.isInvalidToken(e)
        })

      this.palestrantesService.getBasic()
        .then(res => {
          localStorage.setItem("palestrante", JSON.stringify(res))
        })
        .catch(e => {
          this.isInvalidToken(e)
        })

      this.userService.getUsuarioTermos()
        .then((res: any) => {
          let usuario = res
          if (parseInt(usuario.concorda_lgpd) != 1 || parseInt(usuario.concorda_termos) != 1) this.avisoCadastro()
        })
    } catch (error) {

    }
  }

  async espectador(perfilOriginal: string) {
    try {
      if (perfilOriginal.toUpperCase() == "ESPECTADOR") {
        const result = await this.userService.verificaPlano()
        sessionStorage.setItem("plano_nome", result.plano_nome)
      }

      this.userService.getUsuarioTermos()
        .then((res: any) => {
          let usuario = res
          if (parseInt(usuario.concorda_lgpd) != 1 || parseInt(usuario.concorda_termos) != 1) this.avisoCadastro()
        })

      const espectador: { user } = await this.espectadoresService.getProfile()
      localStorage.setItem("espectador", JSON.stringify(espectador.user))

    } catch (e) {
      let err = null ?? e.error.message

      if (err && err.status == 0) {
        this.toast.error(err.message)
        this.app.logout()
        return;
      }
      this.isInvalidToken(e)
    }
  }

  isInvalidToken(e: any) {
    if (e.status == 401 || e.status == 404) {
      this.app.logout()
    }

    if (e.error?.message?.message?.includes("Nenhuma plano encontrado para o usuário.")) {
      this.toast.error("Não foi possível validar o plano", "Entre em contato conosco: contato@profissionaissa.com")
      this.app.logout()
    }
    if (e.error.message.includes("TokenExpiredError")
      || e.error.message.includes("JsonWebTokenError: invalid signature")
    ) this.app.logout()
  }

  modalPlano(mensagem: string) {
    const initialState = { mensagem: mensagem, submensagem: "Deseja atualizar seu plano?" };
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      // if (this.bsModalRef.content.value) router
    })
  }
}
