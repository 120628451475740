<div class="container-fluid pl-lg-5 pr-lg-5">
    <app-wizard [itemSelected]="2"></app-wizard>

    <div class="container__conteudo mt-3 col-12 pl-lg-5">
        <h5 class="title">
            Selecione seus respectivos Tutores
            <img class="ml-2" height="18px" src="assets/icons/info.svg" alt="info"
                title="Tutor é o responsável pelo grupo, por avaliar as tarefas dos colaboradores">
        </h5>

        <div class="row">
            <div class="container__search col-12 col-lg-8 d-flex">
                <img src="assets/icons/search-black.svg" alt="ícone pesquisar">
                <input class="container__search__input form-group m-0 border-0 bg-transparent" placeholder="Buscar..."
                    type="text" name="search" [(ngModel)]="search" (ngModelChange)="buscar()">
            </div>

            <div
                class="container__select__filter__order col-12 col-lg-2 bg-transparent offset-lg-1 d-flex align-items-center">
                <label class="m-0 mr-2" for="order">Ordenar: </label>
                <select class="select__filter__order" [(ngModel)]="order" name="order" (ngModelChange)="changeOrder()">
                    <option *ngFor="let i of orders" [value]="i.value">{{i.name}}</option>
                </select>
            </div>
        </div>

        <div class="content__cabecalho text-left d-flex p-0">
            <label class="flex03"></label>
            <label class="font-title text-uppercase flex2">NOME</label>
            <label class="font-title text-uppercase flex1">CPF</label>
            <label class="font-title text-uppercase flex2">E-MAIL</label>
            <label class="font-title text-uppercase flex1">SETOR</label>
            <label class="font-title text-uppercase flex1">CARGO</label>
            <label class="font-title text-uppercase flex03">AÇÕES</label>
        </div>

        <ul>
            <li [ngClass]="{ isLider : colaborador.responsavel_grupo_bool, hasLiderado: (colaborador.has_liderado == 1) }"
                *ngFor="let colaborador of colaboradores">

                <div class="d-flex content__list__item">
                    <div class="flex03 p-0">
                        <label *ngIf="colaborador.has_liderado == 0" class="container__checkbox mb-0">
                            <input type="checkbox" (change)="changeTutor(colaborador)"
                                [(ngModel)]="colaborador.responsavel_grupo_bool">
                            <span class="checkmark"></span>
                        </label>
                    </div>

                    <div class="flex2 w-100 item__nome pl-0">
                        <input class="input__disable" type="text" [(ngModel)]="colaborador.nome_completo"
                            disabled="disabled">
                    </div>

                    <div class="flex1">
                        <input class="input__disable" type="text" [(ngModel)]="colaborador.cpf" disabled="disabled"
                            mask="000.000.000-00">
                    </div>

                    <div class="flex2">
                        <input class="input__disable" type="text" [(ngModel)]="colaborador.email" disabled="disabled">
                    </div>

                    <div class="flex1">
                        <input class="input__disable" type="text" [(ngModel)]="colaborador.setor" disabled="disabled">
                    </div>

                    <div class="flex1">
                        <input class="input__disable" type="text" [(ngModel)]="colaborador.cargo_nome"
                            disabled="disabled">
                    </div>


                    <div class="flex03 td_acao">
                        <img (click)="editar(colaborador)" src="assets/icons/editar.svg" alt="adicionar">
                        <img [ngClass]="{'unclickable': (colaborador.removable == 0 || colaborador.has_liderado == 1) }"
                            (click)="excluir(colaborador.id_colaborador)" class="float-right"
                            src="assets/icons/excluir.svg" alt="excluir">
                    </div>
                </div>

            </li>
        </ul>

        <p *ngIf="colaboradores?.length == 0">Nenhum colaborador cadastrado.</p>

        <div class="d-flex justify-content-between align-items-end">
            <nav *ngIf="qtdePages?.length > 1" aria-label="Page navigation example">
                <ul class="pagination">
                    <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                        <a class="page-link">Anterior</a>
                    </li>

                    <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)"
                        class="page-item">
                        <a class="page-link">1</a>
                    </li>
                    <span *ngIf="(page + 1) > 4" class="px-2">...</span>

                    <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)"
                        class="page-item" *ngFor="let number of getPages()">
                        <a class="page-link">{{number+1}}</a>
                    </li>

                    <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
                    <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}"
                        (click)="changePage(qtdePages.length - 1)" class="page-item">
                        <a class="page-link">{{qtdePages.length}}</a>
                    </li>

                    <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                        <a class="page-link">Próxima</a>
                    </li>
                </ul>
            </nav>

            <button (click)="selecionaLideres()" class="btn__primary">SALVAR TUTORES</button>
        </div>
        <span class="hasLiderado float-right mt-2"> * Colaboradores em amarelo possuem liderados, para removê-los dos
            tutores ou excluí-los é necessário trocar o tutor de seus liderados.</span>
    </div>
</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>