import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MeusConteudosService } from 'src/app/services/meus-conteudos';
import { IPergunta } from '../habilidade.component';
import { FormPerguntaComponent } from './form-pergunta';

@Component({
  selector: 'app-perguntas',
  templateUrl: './perguntas.component.html',
  styleUrls: ['./perguntas.component.scss', '../../common-style.scss']
})
export class PerguntasComponent implements OnInit {
  @Input() idHabilidade: number
  @Input() isOwner: boolean
  perguntas: Array<IPergunta> = []
  perguntaSelected: IPergunta = null
  idSelected: number = null
  isPerguntaDefault: boolean = true

  @ViewChild("formPerguntaComponent", { static: false }) formPerguntaComponent: FormPerguntaComponent;

  constructor(
    private toast: ToastrService,
    private meusConteudosService: MeusConteudosService,) { }

  ngOnInit(): void {
    this.getPerguntas()
  }

  async getPerguntas() {
    try {
      const res = await this.meusConteudosService.getPerguntas(this.idHabilidade)
      this.perguntas = res

    } catch (error) {
      console.log(error);
      this.toast.error("Não foi possível buscar suas tarefas.")
    }
  }

  setPergunta(pergunta: IPergunta) {
    this.perguntaSelected = pergunta
    this.idSelected = this.perguntaSelected.id
    this.formPerguntaComponent.getById(this.perguntaSelected.id)
  }

  formFinished() {
    this.idSelected = null
    this.perguntaSelected = null
    this.getPerguntas()
  }

  insert() {
    this.idSelected = null
    this.formPerguntaComponent.getById(null)
  }
}
