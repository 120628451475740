import { Routes } from '@angular/router';

import { LivePalestranteComponent } from './palestrante/live-palestrante';
import { LiveEspectadorComponent } from './espectador/live-espectador';

export const LiveRoutes: Routes = [
  {
    path: 'palestrante/live/:id',
    component: LivePalestranteComponent
  },
  {
    path: 'espectador/live/:id',
    component: LiveEspectadorComponent
  },
];
