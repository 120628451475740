import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoService } from '../../../services/grupo';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalAdicionarColaboradoresComponent } from '../modal-adicionar-colaboradores';
import { ModalFormColaboradorComponent } from '../modal-form-colaborador';
import { IColaborador } from '../colaboradores';


export interface ILider {
  id: number,
  nome: string,
  email: string,
  id_cargo: number,
  cargo?: string,
  id_grupo: number,
  grupo?: string,
  id_usuario_lider?: number
  status?: string
  habilidades?: Array<string>
}

export interface IGrupo {
  id: number,
  nome: string,
  descricao: string,
  ativo: string,
  id_contratante: number,
  colaboradores?: Array<ILider>
}

export interface IGrupoLideres {
  id: number,
  id_colaborador: number,
  nome: string,
  descricao: string,
  ativo: string,
  id_contratante: number,
  id_lider: number,
  id_cargo_lider: number,
  id_espectador_lider?: number,
  id_usuario_lider: number,
  nome_lider: string
  email_lider: string
  isLider: boolean
  grupos: string
}

@Component({
  selector: 'app-grupo-colaboradores',
  templateUrl: './grupo-colaboradores.component.html',
  styleUrls: ['./grupo-colaboradores.component.scss']
})
export class GrupoColaboradoresComponent implements OnInit {
  bsModalRef: BsModalRef;

  grupo: IGrupo = {
    id: null,
    nome: null,
    descricao: null,
    ativo: null,
    id_contratante: null,
  };
  colaboradores: Array<IGrupoLideres>;
  lideres: Array<ILider>;
  resultGrupo: any;
  grupo_id: number;

  loading: boolean = false;
  qtdePages: Array<number> = [];
  page: number = 0;
  search: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private grupoService: GrupoService,
    private toast: ToastrService,
    private modalService: BsModalService,) { }

  ngOnInit(): void {
    this.grupo_id = parseInt(this.route.snapshot.paramMap.get('id'));
    this.getGrupo();
  }

  async getGrupo() {
    const res = await this.grupoService.getGrupoLideres(this.grupo_id, this.page, this.search)
      .catch((error) => {
        console.log(error);
        return;
      });
    this.grupo = res.grupo;
    this.setQtdePages(res.quantidade)
    this.lideres = res.lideres ? res.lideres : [];

    const resColaboradores = await this.grupoService.getGrupoColaboradores(this.grupo_id, this.page, this.search)
      .catch((error) => {
        console.log(error);
        return;
      });
    this.colaboradores = resColaboradores.colaboradores ? resColaboradores.colaboradores : [];
  }

  editar(colaborador: IColaborador) {
    const initialState = { colaborador: colaborador };
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalFormColaboradorComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.success) this.getGrupo()
    })
  }

  async salvar() {
    try {
      if (this.colaboradores.length == 0) {
        this.toast.error("Adicione um colaborador.", "Grupo deve conter pelo menos um colaborador!")
        return
      }

      this.router.navigate(['/universidade/', this.grupo_id, 'habilidades']);
    } catch (error) {
      this.loading = false
    }
  }

  // excluir(id: number) {

  // }

  addColaboradores(lider: IColaborador) {
    const initialState = { id_grupo: this.grupo_id, lider: lider };
    const config: any = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(ModalAdicionarColaboradoresComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.success) this.getGrupo()
    })
  }

  changePage(number: number) {
    this.page = number;
    this.getGrupo()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array.from(Array(qtdeColaboradores).keys())
  }

  getPages() {
    let listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2))
    return listPage
  }

  buscar() {
    this.page = 0
    this.getGrupo()
  }
}
