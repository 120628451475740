import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';

@Component({
  selector: 'app-depoimentos-admin',
  templateUrl: './depoimentos-admin.component.html',
  styleUrls: ['./depoimentos-admin.component.scss']
})
export class DepoimentosAdminComponent implements OnInit {
  @Output() onClose = new EventEmitter()
  @Input() palestrante: any;

  loading: boolean = false;
  depoimentos: Array<any> = [];
  openForm: boolean = false;
  openFormEdit: boolean = false;

  editDepoimento: string;

  constructor(
    private palestrantesService: PalestrantesService,
    private toast: ToastrService,) { }

  ngOnInit(): void {
    this.getDepoimentos()
  }

  async getDepoimentos() {
    try {
      this.loading = true;
      const res = await this.palestrantesService.getDepoimentosByPalestranteId(this.palestrante);

      this.depoimentos = res;
      this.loading = false;
    } catch (error) {
      this.toast.error("Não foi possível carregas seus produtos");
      this.loading = false;
    }
  }

  solicitar() {
    this.openForm = true;
  }

  editar(depoimento: any) {
    console.log('editando depoimento', depoimento);
    this.editDepoimento = depoimento;
    this.openFormEdit = true;
  }

  formFinished() {
    this.openForm = false;
    this.openFormEdit = false;
    this.close();
    this.getDepoimentos();
  }

  close(){
    this.onClose.emit('');
  }
}
