import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';

@Component({
  selector: 'app-depoimentos',
  templateUrl: './depoimentos.component.html',
  styleUrls: ['./depoimentos.component.scss']
})
export class DepoimentosComponent implements OnInit {
  @Output() onClose = new EventEmitter()

  loading: boolean = false
  depoimentos: Array<any> = []
  openForm: boolean = false

  constructor(
    private palestrantesService: PalestrantesService,
    private toast: ToastrService,) { }

  ngOnInit(): void {
    this.getDepoimentos()
  }

  async getDepoimentos() {
    try {
      this.loading = true
      const res = await this.palestrantesService.getDepoimentos()
      this.depoimentos = res
      this.loading = false
    } catch (error) {
      this.toast.error("Não foi possível carregas seus produtos")
      this.loading = false
    }
  }

  solicitar() {
    this.openForm = true
  }

  formFinished() {
    this.openForm = false
    this.close();
    this.getDepoimentos()
  }

  close(){
    this.onClose.emit('')
  }
}
