import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalOrcarComponent } from "src/app/common/modal-orcar";

@Component({
  selector: 'app-orcar-buttom',
  template: `
    <div class="filter fixedbutton" (click)="orcar()">
        <img src="assets/icons/shopping-cart.svg" />
        <label>ORÇAR</label>
    </div>`,
  styleUrls: ['./orcar-buttom.component.scss']
})
export class OrcarButtomComponent implements OnInit {
  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,) {}

  ngOnInit(): void {
  }

  orcar() {
    this.bsModalRef = this.modalService.show(ModalOrcarComponent);
  }

}
