import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppComponent } from 'src/app/app.component';
import { ContratanteService } from 'src/app/services/contratante';
import { GrupoService } from '../../../services/grupo';
import { ModalLockedComponent } from '../modal-locked';

export interface IResultCount {
  total_grupos: number,
  total_liderados: number,
  total_lideres: number
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  bsModalRef: BsModalRef;

  resultCount: IResultCount = {
    total_grupos: 0,
    total_liderados: 0,
    total_lideres: 0
  };

  constructor(
    private grupoService: GrupoService,
    private contratanteService: ContratanteService,
    private modalService: BsModalService,
    private app: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Educação Corporativa")
    let params = this.route.queryParams['_value']
    if (params.locked) this.msgLocked()
    this.getCount();
  }

  async getCount() {
    this.resultCount = await this.grupoService.getCountGrupos()
      .catch((e) => {
        console.log(e);
        return;
      });
  }

  async go() {
    let can = await this.verificaAcesso()
    if (!can) return
    
    try {
      await this.grupoService.verifyAndCreateDefaultGroup()
    } catch (error) { }

    this.router.navigate(['/universidade/colaboradores'])
  }

  async verificaAcesso() {
    const usuario = await this.contratanteService.getUsuario()
    if (usuario.universidade_corporativa == 1) {
      this.app.refreshInfoGlobal()
      return true
    } else {
      this.msgLocked()
      return false
    }
  }

  msgLocked() {
    const initialState = {};
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalLockedComponent, config);
    let newSubscriber = this.modalService.onHide.subscribe(() => { newSubscriber.unsubscribe(); })
  }
}
