import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-cadastrar-usuario',
  templateUrl: './cadastrar-usuario.component.html',
  styleUrls: ['./cadastrar-usuario.component.scss'],
})
export class CadastrarUsuarioComponent implements OnInit {
  nome: string;
  sobrenome: string;
  email: string;
  perfil: string;
  telefone: string;

  constructor(
    private router: Router,
    private adminService: AdminService,
    private toast: ToastrService
  ) {}

  ngOnInit(): void {}

  async cadastrar() {
    try {
      const data = {
        nome: this.nome,
        sobrenome: this.sobrenome,
        email: this.email,
        perfil: this.perfil,
        telefone: this.telefone,
      };

      if (!data.nome || data.nome === '') {
        return this.toast.error('O campo nome não pode ser vazio');
      }

      if (!data.sobrenome || data.sobrenome === '') {
        return this.toast.error('O campo sobrenome não pode ser vazio');
      }

      if (!data.email || data.email === '') {
        return this.toast.error('O campo email não pode ser vazio');
      }

      if (!data.perfil || data.perfil === '') {
        return this.toast.error('O campo perfil não pode ser vazio');
      }

      if (!data.telefone || data.telefone === '') {
        return this.toast.error('O campo telefone não pode ser vazio');
      }

      await this.adminService.cadastrarUsuario(data);

      this.toast.success('Usuário cadastrado com sucesso!');

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      this.toast.error('Não foi possível cadastrar o usuário');
    }
  }

  voltar() {
    this.router.navigateByUrl('/admin/palestrantes');
  }
}
