import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface ISegmento {
  nome: string
  descricao: string
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(public http: HttpClient) { }

  async getSegmentos(): Promise<Array<ISegmento>> {
    const result = await this.http.get<any>(`${environment.API_V2_URL + "common/segmentos"}`).toPromise();
    return result.body;
  }

  async getFormatos(): Promise<Array<any>> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/formatos`).toPromise();
    return result.body;
  }

  async getTemas(): Promise<Array<any>> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/temas`).toPromise();
    return result.body;
  }

  async getAllTemas(justChildren: boolean): Promise<Array<any>> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/temas/all?justChildren=${justChildren}`).toPromise();
    return result.body;
  }

  async getDuracoes(id_formato: number): Promise<Array<any>> {
    const result = await this.http.get<any>(`${environment.API_V2_URL + "common/duracoes/" + id_formato}`).toPromise();
    return result.body;
  }

  async getCarteiraByUF(uf: string): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL + "common/carteira/" + uf}`).toPromise();
    return result.body;
  }

  async getClassesHabitante(): Promise<Array<any>> {
    const result = await this.http.get<any>(`${environment.API_V2_URL + "common/classes-habitante"}`).toPromise();
    return result.body;
  }

  async getInfoCalculadora(): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL + "common/info-calculadora"}`).toPromise();
    return result.body;
  }

  async calcula(body: any): Promise<any> {
    const result = await this.http.post<any>(`${environment.API_V2_URL + "common/calcula"}`, body).toPromise();
    return result.body;
  }

  async generateCalculadora(body: any): Promise<any> {
    const result = await this.http.post<any>(`${environment.API_V2_URL + "common/calculadora-export"}`, body).toPromise();
    return result.body;
  }

  async getConfiguracao(valor: string, valor_default: string) {
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/configuracao/${valor}${valor_default ? "/" + valor_default : ''}`).toPromise();
    return result.body;
  }

  async getBancos() {
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}usuarios/bancos`).toPromise();
    return result.body;
  }

  async getCidades(uf: string) {
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/cidades/${uf}`).toPromise();
    return result.body;
  }

  async getComplexidadesArtigo() {
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/complexidades-artigo`).toPromise();
    return result.body;
  }
}
