import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';

@Component({
  selector: 'app-palestras-carousel',
  templateUrl: './palestras-carousel.component.html',
  styleUrls: ['./palestras-carousel.component.scss']
})
export class PalestrasCarouselComponent implements OnInit {
  public palestras;
  @Input() palestrasInput;
  @Input() titulo: string;

  carouselOptions: OwlOptions = {
    margin: 10,
    nav: true,
    dots: false,
    navText: ["<i class='carousel-control-prev-icon'></i>", "<i class='carousel-control-next-icon'></i>"],
    responsive: { 0: { items: 1 }, 400: { items: 1 }, 740: { items: 3 }, 940: { items: 4 } }
  }

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  getMinibio(text: String) {
    const maxString = 150
    return text.length > maxString ? text.substring(0, (maxString - 4)) + ' ...' : text;
  }

  goto(palestra) {
    window.open(window.location.origin + '/palestras/disponivel/' + palestra.slug, "_self")
  }
}
