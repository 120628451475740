import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-perfil-palestrante',
  templateUrl: './perfil-palestrante.component.html',
  styleUrls: ['./perfil-palestrante.component.scss']
})
export class PerfilPalestranteComponent implements OnInit, OnDestroy {

  tab: string = 'dados';

  constructor(private activatedRoute: ActivatedRoute, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Configurações")
    let params = this.activatedRoute.queryParams['_value']
    if (params.tab) this.tab = params.tab
  }

  ngOnDestroy(): void {
    this.titleService.setTitle("Dashboard")
  }

  back(){
    window.history.back()
  }

  next() {}
}
