<div *ngIf="loading" class="d-flex justify-content-center">
    <app-spinner></app-spinner>
</div>

<section class="container-fluid px-1 px-md-5" *ngIf="!loading">
    <div class="row">
        <article class="container__task col-12" [class.offset-md-2]="!taskStarted" [class.col-md-8]="!taskStarted"
            [class.col-md-4]="taskStarted">

            <video *ngIf="skill.habilidade_arquivo_principal !== 'PDF'" #videoTask webkit-playsinline playsinline
                autoplay controls controlsList="nodownload">
                <source src="{{ videoSource }}" type="video/mp4" />
            </video>

            <div *ngIf="skill.habilidade_arquivo_principal == 'PDF' && taskStarted" class="pdfviwer">
                <pdf-viewer style="display: inline;" [src]="skill.habilidade_pdf_link" [render-text]="true"
                    [autoresize]="true" [original-size]="false" [zoom-scale]="'page-fit'">
                </pdf-viewer>
            </div>

            <div *ngIf="skill.habilidade_arquivo_principal == 'PDF' && !taskStarted" class="pdfviwer">
                <pdf-viewer style="display: inline;" [src]="skill.habilidade_pdf_link" [render-text]="true"
                    [autoresize]="true" [original-size]="false" [zoom-scale]="'page-fit'">
                </pdf-viewer>
            </div>

            <div *ngIf="!taskStarted" class="d-flex justify-content-center">
                <button *ngIf="skill.habilidade_pdf_link && skill.habilidade_arquivo_principal == 'PDF'"
                    class="btn__secondary mr-2" (click)="showPDF()">abrir pdf</button>
                <button *ngIf="skill.habilidade_video_link && skill.habilidade_arquivo_principal == 'PDF'"
                    (click)="openVideo()" class="btn__secondary">abrir vídeo</button>
                <button *ngIf="skill.habilidade_pdf_link && skill.habilidade_arquivo_principal !== 'PDF'"
                    class="btn__secondary" (click)="openPDF()">abrir pdf</button>
            </div>

            <div class="content__info">
                <h3 class="font-title">{{skill.habilidade_titulo}}</h3>

                <p class="description" [innerHtml]="skill.habilidade_complemento"></p>
                <div class="justify-content-center" [class.d-flex]="!taskStarted" [class.d-none]="taskStarted">
                    <button (click)="startTask()" class="btn__primary">
                        <img src="assets/icons/editar.svg" alt="Responder tarefas">
                        {{ skill.habilidade_status == 'PENDENTE' || skill.habilidade_status == 'INICIADA' ? 'Responder
                        tarefas' : 'Tarefas'}}
                    </button>
                </div>
            </div>
        </article>


        <article class="col-12 pl-3" [class.d-none]="!taskStarted" [class.col-md-8]="taskStarted">

            <div class="container__task">
                <app-questions [skill]="skill"></app-questions>
            </div>
        </article>
    </div>
</section>