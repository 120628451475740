import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-palestra-incompany',
  templateUrl: './modal-palestra-incompany.component.html',
  styleUrls: ['./modal-palestra-incompany.component.scss']
})
export class ModalPalestraIncompanyComponent implements OnInit {


  @Input() id_palestra: number;
  @Input() id_contratante: number;
  code: string
  message: string

  success: boolean = false

  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit(): void { }

  subscripe() {
    this.message = ""
    if (parseInt(this.code) == this.id_contratante && this.code) {
      this.success = true
      this.bsModalRef.hide()
    } else {
      this.message = "Código incorreto"
    }
  }
}
