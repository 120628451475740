<section class="container">
    <div class="row">
        <div *ngIf="loading" class="col-12 pt-5">
            <div class="d-flex align-items-center justify-content-center">
                <h3 class="font-title">Configuração sua conta
                    <div class="snippet" data-title=".dot-elastic">
                        <div class="stage">
                            <div class="dot-elastic"></div>
                        </div>
                    </div>
                </h3>
            </div>
        </div>
        <div *ngIf="!loading" class="col-12 pt-5 d-flex flex-column align-items-center justify-content-center">
            <h3 class="font-title">{{error}}</h3>
            <p>Entre em contato conosco: contato@profissionaissa.com</p>
        </div>
    </div>
</section>