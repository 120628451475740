import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RdStationService } from 'src/app/services/rd-station/rd-station.service';

@Component({
  selector: 'app-modal-orcar',
  templateUrl: './modal-orcar.component.html',
  styleUrls: ['./modal-orcar.component.scss']
})
export class ModalOrcarComponent implements OnInit {

  descricao: string = "";

  constructor(
    public bsModalRef: BsModalRef,
    private toast: ToastrService,
    public rdStationService: RdStationService,
  ) { }

  ngOnInit(): void {
  }

  async sendInfo() {
    const usuario = JSON.parse(window.localStorage.getItem("usuario"))
    let body = {
      cep: usuario.cep,
      email: usuario.email,
      empresa: usuario.empresa,
      nome: usuario.nome,
      segmento: usuario.segmento,
      personal_phone: usuario.telefone,
      origem: 'Site_PSA',
      perfil: 'contratante',
      identificador: 'ctr_form',
      descricao: this.descricao
    }
    try {
      await this.rdStationService.formNewsletter(body)

      this.bsModalRef.hide()

      this.toast.success("Dados enviados. Em breve, entraremos em contato.", "Obrigado!");
    } catch (error) {
      this.toast.error("Não foi possível enviar", "Tente novamente");
      this.rdStationService.SendEmailInfoRD(Object.assign({}, body));
    }

  }
}
