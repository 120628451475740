import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { SaNewsService } from 'src/app/services/sa-news';
import { IArtigo, ICategoriaArtigo } from '../home'

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent implements OnInit {

  artigos: IArtigo[];
  categoria: ICategoriaArtigo;
  isLoading: boolean = true;

  page: number = 0;
  qtdePages: Array<number> = [];
  id_tipo: number = 0;


  constructor(
    private saNewsService: SaNewsService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.id_tipo = parseInt(this.route.snapshot.paramMap.get('id'));
    this.loadArtigos()
  }


  loadArtigos() {
    this.isLoading = true
    try {
      this.saNewsService.getByTipo(this.id_tipo, this.page).then(result => {
        this.isLoading = false
        this.artigos = result.artigos ? result.artigos : []
        this.categoria = result.categoria
        this.setQtdePages(result.quantidade)

        this.titleService.setTitle("SA News - " + this.categoria.descricao);
      })


    } catch (error) {
      this.isLoading = false
    }
  }

  goArtigo(slug: string, categoria: string) {
    this.router.navigate([`sa-news/${categoria}/${slug}`])
  }

  changePage(number: number) {
    this.page = number;
    this.loadArtigos()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array(qtdeColaboradores);
  }
}
