<div *ngIf="question" class="container__question">
    <span *ngIf="size > 0">{{indexQuestion + 1}} / {{size}}</span>

    <h5 [class.font-weight-bold]="question.tipo != 'DESCRITIVA'" class="text__question">{{question.pergunta}}</h5>

    <ul *ngIf="question.tipo != 'DESCRITIVA' && hasArrayOptions()">
        <li (click)="checkAnswer(option)" class="d-flex text-left container__option"
            *ngFor="let option of question.opcoes, let index = index">
            <div class="option__index" [class.option__correct]="isAnswered && option.peso == '100'"
                [class.option__incorrect]="isAnswered && !isCorrect && (option.ordem == optionChoosed.ordem)">
                <span class="mr-1">{{index + 1}}.</span>
            </div>
            <div class="d-flex flex-column">
                <div class="option__text" [class.option__correct]="isAnswered && option.peso == 100"
                    [class.option__incorrect]="isAnswered && !isCorrect && (option.ordem == optionChoosed.ordem)">
                    <span>
                        {{option.descricao_opcao}}
                    </span>
                </div>

                <div *ngIf="isAnswered && (option.ordem == optionChoosed.ordem)" class="justify">
                    <p>{{option.explicacao}}</p>
                </div>
            </div>
        </li>
    </ul>

    <div class="form-group" *ngIf="question.tipo == 'DESCRITIVA'">
        <textarea [readonly]="isAnswered == true" class="form-control textarea__default"
            placeholder="Descreva a resposta" name="anwser" id="anwser" rows="8" [(ngModel)]="anwser"></textarea>

        <p *ngIf="question.resposta.feedbackTutor">Feedback de seu tutor: {{question.resposta.feedbackTutor}}</p>
    </div>
</div>