import { Routes } from '@angular/router';

import { PalestrasComponent } from './palestras';
import { ResumoIndicacoesComponent } from './resumo-indicacoes/resumo-indicacoes.component';

export const CuradorRoutes: Routes = [
  {
    path: 'curador/palestras',
    component: PalestrasComponent,
  },
  {
    path: 'curador/resumo-indicacoes',
    component: ResumoIndicacoesComponent,
  }
];
