import { Routes } from '@angular/router';

import { HomeComponent } from './home';
import { GruposComponent } from './grupos';
import { GrupoLideresComponent } from './grupo-lideres';
import { HabilidadesComponent } from './habilidades';
import { NotificacaoComponent } from './notificacao';
import { ColaboradoresComponent } from './colaboradores';
import { SelecionaLideresComponent } from './seleciona-lideres';
import { ImportComponent } from './import';
import { GrupoColaboradoresComponent } from './grupo-colaboradores';
import { RelatorioRankingComponent } from './relatorio-ranking';
import { SolicitacaoColaboradorComponent } from './solicitacao-colaborador';

export const UniversidadeCorporativaRoutes: Routes = [
  {
    path: 'universidade/home',
    component: HomeComponent
  },
  {
    path: 'universidade/grupos',
    component: GruposComponent
  },
  {
    path: 'universidade/:id/lideres',
    component: GrupoLideresComponent
  },
  {
    path: 'universidade/:id/colaboradores',
    component: GrupoColaboradoresComponent
  },
  {
    path: 'universidade/:id/habilidades',
    component: HabilidadesComponent
  },
  {
    path: 'universidade/notificacao',
    component: NotificacaoComponent
  },
  {
    path: 'universidade/colaboradores',
    component: ColaboradoresComponent
  },
  {
    path: 'universidade/colaboradores/:tab',
    component: ColaboradoresComponent
  },
  {
    path: 'universidade/importar',
    component: ImportComponent
  },
  {
    path: 'universidade/seleciona-lideres',
    component: SelecionaLideresComponent
  },
  {
    path: 'universidade/relatorio/ranking',
    component: RelatorioRankingComponent
  },
  {
    path: 'universidade/solicitacao-colaborador/:idCompany/:idUser',
    component: SolicitacaoColaboradorComponent
  },
  {
    path: 'universidade/**',
    redirectTo: 'universidade/home'
  },
];
