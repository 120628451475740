<div class="d-flex">

    <div *ngIf="posicao == 1" title="{{posicao}}" class="quiz-medal">
        <div class="quiz-medal__circle quiz-medal__circle--gold">
            <span>{{posicao}}</span>
        </div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
    </div>

    <div *ngIf="posicao == 2" title="{{posicao}}" class="quiz-medal">
        <div class="quiz-medal__circle quiz-medal__circle--silver">
            <span>{{posicao}}</span>
        </div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
    </div>

    <div *ngIf="posicao == 3" title="{{posicao}}" class="quiz-medal">
        <div class="quiz-medal__circle quiz-medal__circle--bronze">
            <span>{{posicao}}</span>
        </div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
    </div>

    <div *ngIf="posicao > 3" title="{{posicao}}" class="quiz-medal">
        <div [class.font]="(posicao > 99)" class="quiz-medal__circle">
            <span>{{posicao}}</span>
        </div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--left"></div>
        <div class="quiz-medal__ribbon quiz-medal__ribbon--right"></div>
    </div>

</div>