import { Component, OnInit } from '@angular/core';
import { PalestrasService } from 'src/app/services/palestras';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { EspecialistasService } from 'src/app/services/especialistas';

@Component({
  selector: 'app-palestra',
  templateUrl: './palestra.component.html',
  styleUrls: ['./palestra.component.scss']
})
export class PalestraComponent implements OnInit {
  bsModalRef: BsModalRef;

  loading: boolean = false;
  palestraItem;
  profissional: any;
  palestras: any = [];
  depoimentos: any = [];
  linkVideo: any

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private palestrasService: PalestrasService,
    private especialistasService: EspecialistasService,
    ) { }

  async ngOnInit() {
    const slug = this.route.snapshot.paramMap.get("slug")

    this.loading = true
    try {
      const result2 = await this.palestrasService.getPalestraDisponivelBySlug(slug);
      this.palestraItem = result2

      const retornoPalestrante = await this.especialistasService.getEspecialistaBySlug(this.palestraItem.palestrante_slug)
      this.loading = false
      this.profissional = retornoPalestrante.palestrante

      this.getUrlVideo()

      if (retornoPalestrante.depoimentos.length > 0) this.depoimentos.push(...retornoPalestrante.depoimentos)
      this.palestras = retornoPalestrante.palestras


    } catch (error) {
      this.loading = false
    }
  }

  gotoPerfil(slug: string) {
    this.router.navigate(['/especialistas/', slug])
  }

  getMinibio(text: String) {
    const maxString = 150
    return text.length > maxString ? text.substring(0, (maxString - 4)) + ' ...' : text;
  }

  getUrlVideo() {
    if (this.palestraItem.video) {
      this.linkVideo = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.palestraItem.video}`)
    } else if (this.profissional.palestrante_video) {
      this.linkVideo = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.profissional.palestrante_video}`)
    } else {
      this.linkVideo = null
    }
  }
}
