import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PalestrasService } from 'src/app/services/palestras';

export interface ICertificate {
  carga_horaria: number
  data_palestra: string
  id: number | null
  idPalestra: number
  nome: string
  tipo: string
  palestrante: string
  pdf_url: number | null
  tema: string
  status: "CADASTRANDO"  | "EXECUTANDO" | "CONCLUIDA" | "CANCELADA"
}

@Component({
  selector: 'app-certificados',
  templateUrl: './certificados.component.html',
  styleUrls: ['./certificados.component.scss']
})
export class CertificadosComponent implements OnInit {

  idPalestra: number
  loading: boolean = false
  certificates: Array<ICertificate> = []

  constructor(
    private toast: ToastrService,
    private palestrasService: PalestrasService
  ) { }

  ngOnInit(): void {
    this.getCertificates()
  }

  async getCertificates() {
    try {
      const result: Array<ICertificate> = await this.palestrasService.getLecturesAndCertificates()
      this.certificates = result
    } catch (error) {
      this.toast.error("Falha na conexão")
    }
  }

  async getCertificate(idPalestra: number, pdf_url: string) {
    try {
      if (pdf_url) {
        this.openPDF(pdf_url)
        return
      }
      this.idPalestra = idPalestra
      this.loading = true
      const pdf = await this.generate(idPalestra)
      this.loading = false
      this.idPalestra = null
      if (pdf) {
        this.openPDF(pdf)
      } else {
        throw "Erro ao gerar PDF"
      }
    } catch (error) {
      this.loading = false
      this.idPalestra = null
      this.toast.error("Falha ao gerar certificado", "Tente novamente ou entre em contato conosco: contato@profissionaissa.com")
    }
  }

  openPDF(url: string) {
    window.open(url, "_blank")
  }

  async generate(idPalestra: number): Promise<string> {
    try {
      const result = await this.palestrasService.generateCertificate(idPalestra)
      if (result && result.pdf_url) {
        this.toast.success("Em breve receberá o certificado em seu e-mail")
        return result.pdf_url
      } else {
        throw "Erro ao gerar PDF"
      }
    } catch (error) {
      this.toast.error("Falha ao gerar certificado", "Tente novamente ou entre em contato conosco: contato@profissionaissa.com")
    }
  }
}
