import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedefinirSenhaComponent } from 'src/app/pages/redefinir-senha/redefinir-senha.component'
import { UniversidadeCorporativaRoutes } from 'src/app/pages/universidade-corporativa';
import { ConfiguracoesRoutes } from 'src/app/pages/configuracoes';
import { EspecialistasRoutes } from 'src/app/pages/especialistas';
import { PalestrasRoutes } from 'src/app/pages/palestras';
import { SaNewsRoutes } from 'src/app/pages/sa-news';
import { HomeComponent } from 'src/app/pages/sa-news/home';
import { CalculadoraRoutes } from 'src/app/pages/calculadora';
import { PalestranteRoutes } from 'src/app/pages/palestrante';
import { LiveRoutes } from 'src/app/pages/live';
import { MeusConteudosRoutes } from 'src/app/pages/meus-conteudos';
import { EspectadorRoutes } from 'src/app/pages/espectador';
import { ComunicacaoInternaRoutes } from 'src/app/pages/comunicacao-interna';
import { DesenvolvimentoRoutes } from 'src/app/pages/desenvolvimento';
import { AuthGuard } from './guards/auth';
import { NotificacoesRoutes } from './pages/notificacoes';
import { AdminRoutes } from './pages/admin';
import { RegistrationComponent } from './pages/registration';
import { CuradorRoutes } from './pages/curador/curador-routing.module';
import { NewHomeComponent } from './pages/newHome/new-home.component';

const perfil: string = localStorage.getItem("perfil");

const routes: Routes = [
  { path: '', component: NewHomeComponent, canActivate: [AuthGuard] },
  // { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'redefinir-senha/:token', component: RedefinirSenhaComponent },
  ...AdminRoutes,
  ...UniversidadeCorporativaRoutes,
  ...ConfiguracoesRoutes,
  ...EspecialistasRoutes,
  ...PalestrasRoutes,
  ...SaNewsRoutes,
  ...CalculadoraRoutes,
  ...PalestranteRoutes,
  ...LiveRoutes,
  ...MeusConteudosRoutes,
  ...EspectadorRoutes,
  ...ComunicacaoInternaRoutes,
  ...DesenvolvimentoRoutes,
  ...NotificacoesRoutes,
  ...CuradorRoutes,
  { path: '**', component: NewHomeComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
