import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IFormato } from 'src/app/pages/palestrante/form-evento';
import { CachesService } from 'src/app/services/caches';
import { CommonService } from 'src/app/services/common';

export interface IInvalid {
  campo: string;
  erro: string;
  index: number;
}
interface ICache {
  id: number;
  id_palestrante: number;
  id_casa_formato: number;
  id_casa_formula_duracao: number;
  valor: number;
  principal: number;
  nome_formato: string;
  duracao: string;
  arrayDuracoes: Array<any> | null;
  invalids: Array<any> | null;
}
@Component({
  selector: 'app-cache-admin',
  templateUrl: './cache-admin.component.html',
  styleUrls: ['./cache-admin.component.scss'],
})
export class CacheAdminComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Input() palestrante: any;

  loading: boolean = false;
  caches: Array<any> = [];
  formatos: Array<IFormato> = [];
  duracoes: Array<any> = [];

  idPalestrante: number;
  invalids: Array<IInvalid> = [];

  constructor(
    private cachesService: CachesService,
    private commonService: CommonService,
    private toast: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.idPalestrante = this.palestrante.id;

    this.getCaches();
    this.getInfo();
  }

  async getInfo() {
    try {
      this.formatos = await this.commonService.getFormatos();
    } catch (error) {
      this.toast.error('Não foi possível carregar formatos');
    }
  }

  async getDuracoes(index: number) {
    try {
      const res: any = await this.commonService.getDuracoes(
        this.caches[index].id_casa_formato
      );
      this.caches[index].arrayDuracoes = res ? res.duracoes : [];
    } catch (error) {
      this.toast.error('Não foi possível carregar a lista de durações');
      this.caches[index].arrayDuracoes = [];
    }
  }

  async getCaches() {
    try {
      const res = await this.cachesService.getListByPalestranteId(this.palestrante);

      this.caches = res.caches;
      this.caches = [...this.caches, ...res.preCaches];
      for (const key in this.caches) {
        this.getDuracoes(parseInt(key));
        let i = this.caches[key];
        if (!i.principal) i.principal = 0;
        if (!i.id_palestrante) i.id_palestrante = this.idPalestrante;
      }
    } catch (error) {
      this.toast.error('Não foi possível carregar a lista');
    }
  }

  async submit() {
    try {
      let isOk = false;
      for (const item of this.caches) {
        if (item.principal == 1) isOk = true;
      }
      if (!isOk) {
        this.toast.error('Selecione seu formato principal');
        return;
      }

      this.invalids = [];
      this.loading = true;
      const res = await this.cachesService.insertOrUpdate(this.caches);
      this.loading = false;
      this.toast.success('Salvo com sucesso');
      // this.router.navigate(['palestrante/perfil/experiencia'])
      this.close();
      this.getCaches();
    } catch (error) {
      this.loading = false;
      if (error.error.message.invalids) {
        this.invalids = error.error.message.invalids;
        this.toast.error('Campos inválidos!', 'Por favor, revise.');
      } else {
        this.toast.error('Não foi possível salvar!');
      }
    }
  }

  addLine() {
    this.caches.push({
      id: 0,
      id_palestrante: this.idPalestrante,
      id_casa_formato: '',
      id_casa_formula_duracao: '',
      valor: null,
      principal: 0,
      nome_formato: '',
      duracao: '',
      arrayDuracoes: [],
    });
  }

  cancel(index: number) {
    this.caches.splice(index, 1);
  }

  setPrincipal(index: number) {
    for (const key in this.caches) {
      if (parseInt(key) != index && this.caches[key].principal == 1)
        this.caches[key].principal = 0;
    }
  }

  isIncorrect(value: string, index: number) {
    for (const key in this.invalids) {
      let item = this.invalids[key];
      if (item['campo'] == value && item['index'] == index) return true;
    }
    return false;
  }

  close() {
    this.onClose.emit('');
  }

  async deleteLine(id: number) {
    try {
      const res = await this.cachesService.deleteCache(id);
      this.toast.success('Produto excluído com sucesso!');
      this.getCaches();
    } catch (err) {
      this.toast.error('Não foi possível remover o produto, tente novamente mais tarde.');
    }
  }
}
