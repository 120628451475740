<div class="modal-header p-4">
    <div class="flexFull text-center content__mensagem">
        <h3 class="font-title">{{mensagem}}</h3>
        <h4 class="font-title">{{submensagem}}</h4>
    </div>
</div>
<div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
</div>
<div class="modal-body d-flex justify-content-around">
    <div class="psa-form-group">
        <button class="btn btn-secondary" (click)="returnValue(false)">Não</button>
    </div>
    <div class="psa-form-group">
        <button type="submit" class="btn btn-warning" (click)="returnValue(true)">Sim</button>
    </div>
</div>