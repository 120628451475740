<div class="container-fluid px-5">

    <!-- <app-wizard [itemSelected]="4"></app-wizard> -->

    <div class="container__conteudo__default row mt-4">
        <div class="sectionActions">
            <div class="closeButton" (click)="close()">
              <img src="../../../../../assets/icons/close.svg" />
            </div>
          </div>
          
        <div *ngIf="caches.length == 0">
            Nenhum cachê
        </div>


        <div class="container__list col-12" style="height: 100%;">
            <ul class="list-group">
                <li class="list-group-item">
                    <div class="row cabecalho">
                        <div class="col-12 col-md-3">
                            <label class="font-title mb-0">Formato</label>
                        </div>
                        <div class="col-12 col-md-3">
                            <label class="font-title mb-0">Duração</label>
                        </div>
                        <div class="col-12 col-md-3">
                            <label class="font-title mb-0">Valor R$</label>
                        </div>
                        <div class="col-12 col-md-3">
                            <label class="font-title mb-0">Assinale seu formato principal</label>
                        </div>
                    </div>
                </li>
                <li class="list-group-item list__cache__item" *ngFor="let cache of caches, let index = index">
                    <div class="row">
                        <div class="form-group col-12 col-md-3">
                            <select class="form-control" [class.invalid]="isIncorrect('id_casa_formato', index)"
                                [(ngModel)]="cache.id_casa_formato" (change)="getDuracoes(index)" name="id_casa_formato"
                                id="id_casa_formato">
                                <option value="">Selecione *</option>
                                <option *ngFor="let i of formatos" [value]="i.id">{{i.nome_formato}}</option>
                            </select>
                        </div>
                        <div class="form-group col-12 col-md-3">
                            <select class="form-control" [class.invalid]="isIncorrect('id_casa_formula_duracao', index)"
                                [(ngModel)]="cache.id_casa_formula_duracao" name="id_casa_formula_duracao"
                                id="id_casa_formula_duracao">
                                <option value="">Selecione *</option>
                                <option *ngFor="let i of cache.arrayDuracoes" [value]="i.id">
                                    {{i.duracao}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-12 col-md-3">
                            <input class="form-control" [class.invalid]="isIncorrect('valor', index)" [(ngModel)]="cache.valor" prefix="R$ " mask="separator.2"
                                thousandSeparator="." type="text" placeholder="Valor *">
                        </div>
                        <div class="form-group d-flex col-12 col-md-3">
                            <select class="form-control" [class.invalid]="isIncorrect('principal', index)"
                                [(ngModel)]="cache.principal" (ngModelChange)="setPrincipal(index)" name="principal"
                                id="principal">
                                <option value="0">Não</option>
                                <option value="1">Sim</option>
                            </select>

                            <img *ngIf="cache.id == 0" (click)="cancel(index)"
                                class="float-right ml-3 td__acao__excluir" src="assets/icons/excluir.svg" alt="excluir"
                                title="Remover linha">
                        </div>
                    </div>
                </li>
                <li class="list-group-item">

                    <div class="d-flex div__btn__salvar">
                        <div (click)="addLine()" class="li__add">
                            <img src="assets/icons/more_circle.svg" alt="adicionar linha">
                            Adicionar mais uma linha
                        </div>

                        <button *ngIf="!loading" (click)="submit()" class="btn btn-primary btn-warning">Salvar</button>

                        <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
                            <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
                        </button>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</div>