import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { IResSkills, ISkill, TarefasService } from 'src/app/services/tarefas';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-minha-trilha-page',
  templateUrl: './minha-trilha-page.component.html'
})

export class MinhaTrilhaPageComponent implements OnInit {
  loading:  boolean = false
  concludedSkills:  Array<ISkill> = []
  pendingSkills:    Array<ISkill> = []
  startedSkills:    Array<ISkill> = []
  answeredSkills:   Array<ISkill> = []

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    dots: false,
    autoWidth: true,
    pullDrag: false,
    navSpeed: 700,
    nav: true,
    navText: [
      "<i class='fas fa-chevron-left'></i>",
      "<i class='fas fa-chevron-right'></i>"
    ],
  }

  constructor(
    private skillService: TarefasService,
    private toast: ToastrService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Minha Trilha - Vamos começar a aprender?")
    this.getSkills()
  }

  async getSkills() {
    try {
      this.loading = true

      const result: IResSkills = await this.skillService.getSkills()

      this.concludedSkills = result.tarefasFinalizadasAvaliadas
      this.answeredSkills = result.tarefasRespondidas
      this.pendingSkills = result.tarefasPendentes
      this.startedSkills = result.tarefasIniciadas

      this.loading = false
    } catch (error) {
      this.loading = false
      this.toast.error("Falha na conexão")
    }
  }
}
