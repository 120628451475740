import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class LiveService {

  constructor(public http: HttpClient) { }


  async verificaLive(idPalestra: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}live/verifica-live/${idPalestra}`, { headers: headers }).toPromise();
    return result.body;
  }

  async iniciaLive(idPalestra: number, idLive: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let palestrante = JSON.parse(localStorage.getItem("palestrante"))
    const body = {
      id_palestrante: palestrante.id,
      id_palestra: idPalestra,
      id_live: idLive
    }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/inicia`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async terminaLive(idPalestra: number, socket: any): Promise<any> {

  //   socket.emit('finish', { id: idPalestra })
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', localStorage.getItem("token"));
  //   let palestrante = JSON.parse(localStorage.getItem("palestrante"))
  //   const body = {
  //     id_palestrante: palestrante.id,
  //     id_palestra: idPalestra
  //   }
  //   const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/termina`, body, { headers: headers }).toPromise();
  //   return result.body;
  // }

  // async getPerguntas(idPalestra: number): Promise<any> {
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Authorization', localStorage.getItem("token"));
  //   const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}live/perguntas/${idPalestra}`, { headers: headers }).toPromise();
  //   return result.body;
  }

  async getArquivo(idPalestra: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}live/arquivo/${idPalestra}`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertArquivo(idPalestra: number, pdfBase64: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { pdfBase64: pdfBase64, id_palestra: idPalestra }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/insert-arquivo`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async updatePaginaArquivo(idPalestra: number, paginaAtual: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { pagina_atual: paginaAtual, id_palestra: idPalestra }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/arquivo-update-page`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async deleteArquivo(idPalestra: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.delete<any>(`${environment.API_V2_URL_DEFAULT}live/arquivo/${idPalestra}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getTermometro(idPalestra: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}live/media-avaliacao/${idPalestra}`, { headers: headers }).toPromise();
    return result.body;
  }

  async liberaAvaliacao(idPalestra: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { id_palestra: idPalestra }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/libera-avaliacao`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getQuiz(idPalestra: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}live/quiz/${idPalestra}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getRespostasQuiz(idPalestra: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}live/respostas-quiz/${idPalestra}`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertUpdateQuiz(idPalestra: number, quiz: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { id_palestra: idPalestra, quiz: quiz }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/quiz/insert-update`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async liberaQuiz(id_quiz: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { id_quiz: id_quiz }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/libera-quiz`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getLinks(idPalestra: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}live/links/${idPalestra}`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertUpdateLink(idPalestra: number, id: number | string, link: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { id_palestra: idPalestra, id: id, link: link }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/link/insert-update`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async liberaLink(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { id: id }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/libera-link`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async bloqueiaLink(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const body = { id: id }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}live/bloqueia-link`, body, { headers: headers }).toPromise();
    return result.body;
  }
}
