import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ComunicacaoService } from 'src/app/services/comunicacao';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  notifications: Array<any> = [];
  constructor(
    private titleService: Title,
    private comunicacao: ComunicacaoService,
    private router: Router) { }

  ngOnInit(): void {
    this.titleService.setTitle("Notificações")
    this.getNotifications()
  }

  async archive(messageId) {
    await this.comunicacao.archivePush(messageId)
    this.getNotifications()    
  }

  access(data){
    if(data.openPage == 'palestra') {
      this.router.navigate([`/espectador/palestra/${data.dataPage.subjectId}`])
    } else if(data.openPage == 'plano' || data.openPage == 'planos') {
      const params = {tab: 'planos'}
      this.router.navigate([`/espectador/perfil`], {queryParams: params})
    }
  }

  getNotifications(){
    this.comunicacao.getPushMessages().then(res => {this.notifications = res});
  }

  back(){
    window.history.back()
  }

  ngOnDestroy(): void {
    this.titleService.setTitle("Dashboard")
  }
}