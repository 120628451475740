import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {UserService} from 'src/app/services/user';
import {UsuarioContatosService} from 'src/app/services/usuario-contatos';
import {IContato} from '..';

@Component({
  selector: 'app-form-contato',
  templateUrl: './form-contato.component.html',
  styleUrls: ['./form-contato.component.scss']
})
export class FormContatoComponent implements OnInit {
  @Input('contact') contact: IContato = null;
  @Output('finished') finished = new EventEmitter();

  mensagem = '';
  loading = false;
  reload = true;
  cargos: Array<any> = [];

  constructor(public bsModalRef: BsModalRef,
              private userService: UserService,
              private userContactsService: UsuarioContatosService,
              private toast: ToastrService, ) {
  }

  ngOnInit(): void {
    this.getStartInfo();
    if (!this.contact) {
      this.contact = {
        id: null,
        id_usuario: null,
        nome: '',
        email: '',
        telefone: '',
        cargo: '',
        id_cargo: null,
        departamento: '',
        principal: 'NAO',
        percentual_societario: '',
        razaosocial_societario: '',
        datafuntacao_societario: '',
        tipofiscal_societario: '',
        cpf_cnpj: '',
        senha: '',
        id_contratante_colaborador: null
      };
    }
  }

  async getStartInfo() {
    const cargos = await this.userService.getCargos();
    this.cargos = cargos ? cargos : [];
    this.cargos.unshift({id: null, nome: '', descricao: 'Selecione o cargo', ativo: ''});
  }

  async salvarContato() {
    const validationIsOk = await this.validation();
    if (!validationIsOk) { return; }

    this.loading = true;

    try {
      if (this.contact.id > 0) {

        await this.userContactsService.updateContato(Object.assign(this.contact));
        this.loading = false;

      } else {

        const usuario = JSON.parse(localStorage.getItem('usuario'));
        this.contact.id_usuario = usuario.id;

        await this.userContactsService.insertContato(Object.assign({
          id_usuario: this.contact.id_usuario,
          nome: this.contact.nome,
          email: this.contact.email,
          telefone: this.contact.telefone,
          cargo: this.contact.cargo,
          id_cargo: this.contact.id_cargo,
          departamento: this.contact.departamento,
          principal: this.contact.principal,
          cpf_cnpj: this.contact.cpf_cnpj
        }));
      }

      this.toast.success('Administrador atualizado com sucesso.');
      this.loading = false;
      this.reload = true;
      // this.getContatos();
      this.bsModalRef.hide();

    } catch (e) {
      this.loading = false;
      this.toast.error('Não foi possível salvar Administrador.', e.error.message);
    }
  }

  close() {
    this.reload = false;
    // this.finished.emit()
    this.bsModalRef.hide();
  }

  async validation(): Promise<boolean> {
    this.mensagem = '';

    if (!this.contact.nome || this.contact.nome.length === 0) {
      this.mensagem = 'Preencha o nome do administrador.';
      return false;
    }

    if (!this.contact.email || this.contact.email.length === 0) {
      this.mensagem = 'Preencha o e-mail do administrador.';
      return false;
    }

    const pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,4}$/;
    if (!pattern.test(this.contact.email)) {
      this.mensagem = 'Preencha um e-mail válido.';
      return false;
    }

    const resEmail = await this.userService.verificaEmail(this.contact.email);
    if (resEmail && !this.contact.id) {
      this.mensagem = 'E-mail já está sendo utilizado.';
      return false;

    }

    if (this.contact.cpf_cnpj && this.contact.cpf_cnpj.length > 0 && this.contact.cpf_cnpj.length !== 11 && this.contact.cpf_cnpj.length !== 14) {
      this.mensagem = 'CPF ou CNPJ inválido.';
      return false;

    }

    if (this.contact.cpf_cnpj && this.contact.cpf_cnpj.length === 11 && !this.userService.validationCPF(this.contact.cpf_cnpj)) {
      this.mensagem = 'CPF inválido.';
      return false;

    }

    return true;
  }
}
