import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { getPathPerfil } from 'src/app/controle-perfil';
import { UserService } from 'src/app/services/user';

@Component({
  selector: 'app-modal-aviso-cadastro',
  templateUrl: './modal-aviso-cadastro.component.html',
  styleUrls: ['./modal-aviso-cadastro.component.scss']
})
export class ModalAvisoCadastroComponent implements OnInit {

  concorda: boolean = false;
  concordaLGPD: boolean = false;

  constructor(public bsModalRef: BsModalRef,
    private userService: UserService,
    private toast: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
  }

  async setTermos() {
    try {
      if (this.concorda == false) {
        this.toast.error("Por favor, leia e concorde com os termos e os aceite!")
        return
      }
      if (this.concordaLGPD == false) {
        this.toast.error("Por favor, leia e concorde com as políticas de privacidade, seguindo a LGPD (Lei Geral de Proteção de Dados Pessoais)!")
        return
      }

      await this.userService.setConcordaTermos()
      this.bsModalRef.hide()
      this.router.navigate([getPathPerfil()])
    } catch (error) {
      this.toast.error("Não foi possível aceitar os termos!")
    }
  }
}
