import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { estados } from 'src/app/common/array-estados';
import { IInvalid } from 'src/app/pages/configuracoes/palestrante/tb-dados';
import { ExperienciasProfissionaisService } from 'src/app/services/experienciasProfissionais';
import { IExperiencia } from '../experiencia.component';

@Component({
  selector: 'app-form-experiencia',
  templateUrl: './form-experiencia.component.html',
  styleUrls: ['./form-experiencia.component.scss']
})
export class FormExperienciaComponent implements OnInit {

  @Input() id: number
  @Output("finished") finished = new EventEmitter();
  idPalestrante: number
  invalids: Array<IInvalid> = []

  loading: boolean = false

  arrayEstados = estados;
  insertDefault: IExperiencia = { id: 0, palestrantes_id: 0, cargo: "", empresa: "", cidade: "", estado: "", pais: "Brasil", atual: false, data_inicio: new Date(), data_fim: new Date(), descricao: '' }
  experiencia: IExperiencia = { id: 0, palestrantes_id: 0, cargo: "", empresa: "", cidade: "", estado: "", pais: "Brasil", atual: false, data_inicio: new Date(), data_fim: new Date(), descricao: '' }

  constructor(
    private experienciasProfissionaisService: ExperienciasProfissionaisService,
    private toast: ToastrService,) {
    let palestrante = JSON.parse(localStorage.getItem("palestrante"))
    this.idPalestrante = palestrante.id
  }

  ngOnInit(): void {
    if (!(this.id > 0)) {
      this.insertDefault.palestrantes_id = this.idPalestrante
      this.experiencia = this.insertDefault
    }
    else
      this.getExperiencia()
  }

  async getExperiencia() {
    try {
      this.experiencia = await this.experienciasProfissionaisService.getById(this.id)
    } catch (error) {
      this.toast.error("Não foi possível salvar sua experiência")
    }
  }

  cancel() {
    this.finished.emit({ refresh: false })
  }

  async submit() {
    try {
      this.invalids = []
      this.loading = true
      this.experiencia = await this.experienciasProfissionaisService.insertOrUpdate(this.experiencia)
      this.loading = true
      this.toast.success("Salvo com sucesso")
      this.cancel()
    } catch (e) {
      this.loading = false
      if (e?.error?.message.invalids) {
        this.invalids = e.error.message.invalids
        this.toast.error("Campos inválidos!", "Por favor, revise.")
      } else {
        this.toast.error("Não foi possível salvar os dados", "Falha na conexão")
      }
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }
}
