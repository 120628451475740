export interface IOrder {
    value: string
    name: string
}

export const arrayOrders = [
    {
      name: 'Data de cadastro',
      value: 'data_cadastro ASC'
    },
    {
      name: 'Nome A - Z',
      value: 'nome_completo ASC'
    },
    {
      name: 'Nome Z - A',
      value: 'nome_completo DESC'
    },
    {
      name: 'E-mail',
      value: 'email ASC'
    },
    {
      name: 'Setor',
      value: 'setor ASC'
    },
    // {
    //   name: 'Cargo',
    //   value: 'cargo ASC'
    // },
    {
      name: 'Tutor',
      value: 'tutor ASC'
    },
  ]