<div>
    <label class="span-left">Sua senha <b>deve conter</b>:</label>
    <div>
        <span [ngStyle]="{'color': colorPasswordLowerCap }">
            <img [ngStyle]="{'color': colorPasswordLowerCap }" [class.d-none]="!isValidPasswordLowerCap"
                src="assets/icons/checked_circle.svg" alt="check">Letras maiúsculas e minúsculas
        </span>
    </div>
    <div>
        <span [ngStyle]="{'color': colorPasswordLength }">
            <img [ngStyle]="{'color': colorPasswordLowerCap }" [class.d-none]="!isValidPasswordLength"
                src="assets/icons/checked_circle.svg" alt="check">8 ou mais caracteres
        </span>
    </div>
    <div>
        <span [ngStyle]="{'color': colorPasswordNumber}">
            <img [ngStyle]="{'color': colorPasswordLowerCap }" [class.d-none]="!isValidPasswordNumber"
                src="assets/icons/checked_circle.svg" alt="check">Pelo menos um número
        </span>
    </div>
</div>