<section class="profissional__palestras w-100">
    <div class="container palestrasOferecidas">
        <h2 class="profissional__palestras__title text-center mt-3">{{titulo}}</h2>
        <owl-carousel-o [options]="carouselOptions">
            <ng-container *ngFor="let palestra of palestrasInput">
                <ng-template carouselSlide>
                    <a style="cursor: pointer;" (click)="goto(palestra)">
                        <article class="profissional__palestras__slide">
                            <div class="profissional__palestras__slide__hero">
                                <img width="100%" height="100%"
                                    [src]="palestra.photo || palestra.foto_tema || 'https://placehold.it/300'"
                                    alt="Palestra" />
                            </div>
                            <h3 class="profissional__palestras__slide__title">{{palestra.nome}}</h3>
                            <p class="profissional__palestras__slide__text">{{palestra.descricao?.substring(0,160)}}</p>
                        </article>
                    </a>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>