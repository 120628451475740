<section class="d-flex flex-column align-items-center">
  <figure class="profilePicture">
      <img
        (click)="alterarFoto()"
        src="{{foto || 'https://basesa.s3-sa-east-1.amazonaws.com/imagens/logo_img_alternativa.png'}}"
        alt="foto usuário"
      >
  </figure>

  <button
    (click)="alterarFoto()"
    class="btn"
    title="Alterar foto do perfil"
    type="button"
  >
    <b>Alterar Foto</b>
  </button>
</section>
