import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MeusConteudosService } from 'src/app/services/meus-conteudos';
import { FormCompetenciaComponent } from './form-competencia';

export interface ICompetenciaContratante {
  id: number,
  descricao: string,
  resumo: string,
  id_contratante: number,
  ativo: "SIM" | "NAO"
}

@Component({
  selector: 'app-competencias',
  templateUrl: './competencias.component.html',
  styleUrls: ['./competencias.component.scss', '../common-style.scss']
})
export class CompetenciasComponent implements OnInit {
  bsModalRef: BsModalRef;

  loading: boolean = false
  competencias: Array<ICompetenciaContratante> = []

  tipo: number = 1; // 1 = contratante, 2 = padrão / sa

  constructor(
    private modalService: BsModalService,
    private meusConteudosService: MeusConteudosService,
    private toast: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) { }

  ngOnInit(): void {

    let params = this.route.queryParams['_value']
    this.tipo = parseInt(params.tipo)

    this.titleService.setTitle("Meus conteúdos - Competências")
    this.getCompetencias()
  }

  async getCompetencias() {
    try {
      this.loading = true
      this.competencias = this.tipo == 2 ? await this.meusConteudosService.getCompetenciasPSA() : await this.meusConteudosService.getCompetencias()

      this.loading = false
    } catch (error) {
      this.loading = false
      this.toast.error("Não foi possível carregar suas competências!")
    }
  }

  delete() {
    this.toast.info('Em desenvolvimento.', 'Em breve traremos novidades.')
  }

  update(competencia: ICompetenciaContratante) {
    const initialState = { id: competencia.id };
    const config = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(FormCompetenciaComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      this.getCompetencias()
    })
  }

  insert() {
    const initialState = { id: 0 };
    const config = { initialState }
    this.bsModalRef = this.modalService.show(FormCompetenciaComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      this.getCompetencias()
    })
  }

  goHabilidades(idCompetencia: number) {
    this.router.navigate([`/conteudos/habilidades/${idCompetencia}`])
  }

  getResumo(resumo: string) {
    if (resumo.length <= 130) return resumo
    return resumo.substring(0, 130) + "..."
  }
}
