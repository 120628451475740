<div class="container-fluid pl-lg-5 pr-lg-5">
    <app-wizard [itemSelected]="3"></app-wizard>

    <div class="container__conteudo mt-3 col-12 col-lg-12">

        <div class="clearfix mb-3 pr-0 col-12">
            <button (click)="goInsert()" type="button"
                class="btn btn-warning float-right text-uppercase">Inserir</button>
        </div>

        <div class="row div__shadow container__conteudo__grupo mb-4" *ngFor="let grupo of grupos">
            <div class="col-9">
                <div class="row pl-2">
                    <div class="d-flex align-items-center">
                        <h4 class="font-title mb-2">{{grupo.nome}}</h4>
                        <div [class.d-none]="grupo.tipo != 'PDI'" class="div__item__info">
                            <span>PDI</span>
                        </div>
                        <div [class.d-none]="grupo.perguntas_descritivas == 1" class="div__item__info">
                            <span>sem tarefas descritivas</span>
                        </div>
                    </div>
                </div>
                <div class="row pl-2 border-bottom pr-4">
                    <p class="grupo__descricao">{{grupo.descricao}}</p>
                </div>
                <div class="row pt-2">
                    <div class="col-5">
                        <div class="row">
                            <div class="col-2">
                                <img class="icon" src="assets/icons/lider.svg" alt="setting">
                            </div>
                            <div class="col-10 flexCol">
                                <label class="grupo__numero">{{grupo.total_lideres}}</label>
                                <label class="grupo__numero__descricao">Tutores em Exercício</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="row">
                            <div class="col-2">
                                <img class="icon" src="assets/icons/people.svg" alt="setting">
                            </div>
                            <div class="col-10 flexCol">
                                <label class="grupo__numero">{{grupo.total_liderados}}</label>
                                <label class="grupo__numero__descricao">Colaboradoes Cadastrados</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3 text-center flexColFull border-left">
                <div class="flexFull" style="flex: 1;">
                    <div class="status" [ngStyle]="{'background-color': grupo.ativo == 'SIM' ? 'lightgreen' : 'red'}">
                    </div>
                    <label class="font-title grupo_acao">GRUPO {{grupo.ativo == 'SIM' ? 'ATIVO' : 'INATIVO'}}</label>
                </div>
                <div [routerLink]="['/universidade/' + grupo.id + '/lideres']" class="flexFull acao">
                    <img src="assets/icons/editar.svg" alt="setting">
                    <label class="font-title grupo_acao"> EDITAR</label>
                </div>
                <div *ngIf="grupo.ativo == 'SIM'" class="flexFull acao"
                    [title]="grupo.forma_criacao == 'AUTOMATICO' ? 'Grupo criado automaticamente, não é possível inativar' : 'inativar'">
                    <div [class.unclickable]="grupo.forma_criacao == 'AUTOMATICO'" (click)="inativar(grupo)"
                        class="flexFull acao">
                        <img src="assets/icons/excluir.svg" alt="setting">
                        <label class="font-title grupo_acao">inativar</label>
                    </div>
                </div>
                <div *ngIf="grupo.ativo != 'SIM'" (click)="ativar(grupo)" class="flexFull acao">
                    <img src="assets/icons/checked_circle_black.svg" alt="setting">
                    <label class="font-title grupo_acao">ativar</label>
                </div>
            </div>
        </div>

        <div *ngIf="grupos?.length == 0">
            <p class="grupo__descricao">Nenhum grupo cadastrado.</p>
            <p class="grupo__descricao">Para criar seu primeiro grupo, <b>clique no botão <span
                        style="background-color: var(--yellow)">INSERIR</span></b> no canto superior direito da tela.
            </p>
        </div>
    </div>
</div>