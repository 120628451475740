import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-concorda-termos',
  templateUrl: './concorda-termos.component.html',
  styleUrls: ['./concorda-termos.component.scss']
})
export class ConcordaTermosComponent implements OnInit {

  concorda: boolean = false;

  constructor(
    private userService: UserService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.userService.getUsuarioTermos().then((res: any) => {
      this.concorda = (parseInt(res.concorda_lgpd) == 0 || parseInt(res.concorda_termos) == 0) ? false : true
    })
  }

  async submit() {
    try {
      await this.userService.setConcordaTermos()
      this.toast.success("Termos salvos com sucesso!")
    } catch (error) {
      this.toast.error("Falha na conexão ao concordar com os termos!")
    }
  }
}
