<mat-tab-group class="p-4" #tabGroup (selectedTabChange)="tabChanged($event)">
  <mat-tab label="EVENTOS">
    <div style="
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  " *ngIf="loading">
      <img src="assets/img/psaLoading.svg" alt="Carregando" title="Carregando" />
    </div>

    <main *ngIf="!loading" class="mt-5">
      <h1 class="ml-3">Respostas dos formulários de geração de certificados</h1>

      <div>
        <div class="filterContainer">
          <section>
            <label for="dateStart">Data Início</label>
            <input id="dateStart" name="dateStart" type="date" [(ngModel)]="filterStart" />
          </section>
          <section>
            <label for="dateEnd">Data Fim</label>
            <input id="dateEnd" name="dateEnd" type="date" [(ngModel)]="filterEnd" />
          </section>
        </div>

        <div class="filterContainer">
          <section>
            <label for="nameFilter">Filtrar por nome do negócio</label>
            <input name="nameFilter" id="nameFilter" [(ngModel)]="filterName" />
          </section>
          <section>
            <label for="speakerFilter">Filtrar por palestrante</label>
            <input name="speakerFilter" id="speakerFilter" [(ngModel)]="filterNameSpeaker" />
          </section>
          <section>
            <label for="csFilter">Filtrar por CS responsável (email)</label>
            <input name="csFilter" id="csFilter" [(ngModel)]="filterCS" />
          </section>
        </div>

        <div class="filterContainer">
          <section>
            <button (click)="filterByDate()" type="button">Filtrar</button>
          </section>
          <section>
            <button type="button" (click)="resetFilter()">Resetar Filtro</button>
          </section>
          <section>
            <button type="button" (click)="downloadCSV()">Baixar planilha</button>
          </section>
        </div>

        <section>
          <p>Média geral de respostas: {{ generalAvg }}%</p>
        </section>
      </div>

      <div *ngIf="!loading">
        <table class="customTable">
          <thead>
            <th>Nome do Negócio</th>
            <th>Palestrante</th>
            <th>CS Responsável</th>
            <th>Quantidade de participantes</th>
            <th>Quantidade de respostas</th>
            <th>Média de respostas</th>
            <th>Avaliação performance (%)</th>
            <th>Acesso a mais conteúdos (%)</th>
            <th>Cargo (%)</th>
            <th>Temas de interesse (%)</th>
            <th>Data do Evento</th>
            <th>Data de criação</th>
            <th>Ações</th>
          </thead>

          <tbody>
            <tr *ngFor="let resp of filteredAnswers | paginate: { itemsPerPage: 10, currentPage: currentPage }">
              <td>{{ resp.businessName }}</td>
              <td>{{ resp.speakerName }}</td>
              <td>{{ resp.emailCS }}</td>
              <td>{{ resp.qtdParticipantes || '0' }}</td>
              <td>{{ resp.respostas }}</td>
              <td>{{ resp.average }}%</td>
              <td *ngIf="!resp.newAnswers">
                <ul>
                  <li>Conectou demais ({{ calcPercentageRespostas(resp.avaliacao, resp.respostas, 'Conectou demais')}}%)
                  </li>
                  <li>Sim ({{ calcPercentageRespostas(resp.avaliacao, resp.respostas, 'Sim')}}%)</li>
                  <li>Não ({{ calcPercentageRespostas(resp.avaliacao, resp.respostas, 'Não')}}%)</li>
                  <li>Nenhum pouco ({{ calcPercentageRespostas(resp.avaliacao, resp.respostas, 'Nenhum pouco')}}%)</li>
                </ul>
              </td>
              <td *ngIf="resp.newAnswers">
                <ul>
                  <li>Foi incrível, conectou demais ({{ calcPercentageRespostas(resp.avaliacaoNew, resp.respostas, 'Foi
                    incrível, conectou demais')}}%)</li>
                  <li>Conectou, mas não foi incrível ({{ calcPercentageRespostas(resp.avaliacaoNew, resp.respostas,
                    'Conectou, mas não foi incrível')}}%)</li>
                  <li>Mais ou menos, esperava mais ({{ calcPercentageRespostas(resp.avaliacaoNew, resp.respostas, 'Mais
                    ou menos, esperava mais')}}%)</li>
                  <li>Não conectou ({{ calcPercentageRespostas(resp.avaliacaoNew, resp.respostas, 'Não conectou')}}%)
                  </li>
                </ul>
              </td>
              <td *ngIf="!resp.newAnswers">
                <ul>
                  <li>Sim, quero ({{ calcPercentageRespostas(resp.accessContent, resp.respostas, 'Sim, quero')}}%)</li>
                  <li>Sim ({{ calcPercentageRespostas(resp.accessContent, resp.respostas, 'Sim')}}%)</li>
                  <li>Talvez ({{ calcPercentageRespostas(resp.accessContent, resp.respostas, 'Talvez')}}%)</li>
                  <li>No momento não ({{ calcPercentageRespostas(resp.accessContent, resp.respostas, 'No momento
                    não')}}%)</li>
                  <li>Não ({{ calcPercentageRespostas(resp.accessContent, resp.respostas, 'Não')}}%)</li>
                </ul>
              </td>
              <td *ngIf="resp.newAnswers">
                <ul>
                  <li>Sim, quero! Adorei o(a) palestrante ({{ calcPercentageRespostas(resp.accessContentNew,
                    resp.respostas, 'Sim, quero! Adorei o(a) palestrante')}}%)</li>
                  <li>Sim, pois tenho interesse neste tema, mas preciso avaliar melhor o(a) palestrante ({{
                    calcPercentageRespostas(resp.accessContentNew, resp.respostas, 'Sim, pois tenho interesse neste
                    tema, mas preciso avaliar melhor o(a) palestrante')}}%)</li>
                  <li>No momento não ({{ calcPercentageRespostas(resp.accessContentNew, resp.respostas, 'No momento
                    não')}}%)</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>CEO/Presidente ({{ calcPercentageRespostas(resp.job, resp.respostas, 'CEO/Presidente')}}%)</li>
                  <li>Diretor(a) ({{ calcPercentageRespostas(resp.job, resp.respostas, 'Diretor(a)')}}%)</li>
                  <li>Gerente ({{ calcPercentageRespostas(resp.job, resp.respostas, 'Gerente')}}%)</li>
                  <li>Coordenador(a)/Supervisor(a) ({{ calcPercentageRespostas(resp.job, resp.respostas,
                    'Coordenador(a)/Supervisor(a)')}}%)</li>
                  <li>Analista ({{ calcPercentageRespostas(resp.job, resp.respostas, 'Analista')}}%)</li>
                  <li>Assistente ({{ calcPercentageRespostas(resp.job, resp.respostas, 'Assistente')}}%)</li>
                  <li>Auxiliar ({{ calcPercentageRespostas(resp.job, resp.respostas, 'Auxiliar')}}%)</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Motivação ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Motivação')}})%</li>
                  <li>Vendas ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Vendas')}})%</li>
                  <li>Liderança ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Liderança')}})%</li>
                  <li>Gestão de Pessoas ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Gestão de
                    Pessoas')}})%</li>
                  <li>Negócios ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Negócios')}})%</li>
                  <li>ESG ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'ESG')}})%</li>
                  <li>Diversidade ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Diversidade')}})%</li>
                  <li>Inovação ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Inovação')}})%</li>
                  <li>Comportamento ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Comportamento')}})%</li>
                  <li>Educação ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Educação')}})%</li>
                  <li>Saúde ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Saúde')}})%</li>
                  <li>Outros ({{ calcPercentageRespostas(resp.themes, resp.respostas, 'Outros')}})%</li>
                </ul>
              </td>
              <td>{{ resp.date }}</td>
              <td>{{ resp.createdAt }}</td>
              <td>
                <ul>
                  <li (click)="downloadLine(resp.id)">Download</li>
                  <li (click)="deleteForm(resp.id)">Excluir</li>
                  <li [routerLink]="['/admin/certificados/editar/', resp.id]">Editar</li>
                  <li (click)="gerarRelatorio(resp.id, resp.pdf)">Gerar relatório</li>
                  <li (click)="downloadQRCode(resp.id)">Download QR Code</li>
                  <li (click)="copyEventLink(resp.hash)">Copiar link do evento</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination-controls (pageChange)="currentPage = $event"></pagination-controls>
      </div>
    </main>

  </mat-tab>

  <mat-tab label="RANKING PALESTRANTES">
    <div style="
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;"
      *ngIf="loadingRanking"
    >
      <img src="assets/img/psaLoading.svg" alt="Carregando" title="Carregando" />
    </div>

    <main *ngIf="!loadingRanking" class="mt-5">
      <h1 class="ml-3">Ranking de palestrantes</h1>

      <div>
        <div class="filterContainer">
          <section>
            <label for="dateStart">Data Início</label>
            <input id="dateStart" name="dateStart" type="date" [(ngModel)]="filterStart" />
          </section>
          <section>
            <label for="dateEnd">Data Fim</label>
            <input id="dateEnd" name="dateEnd" type="date" [(ngModel)]="filterEnd" />
          </section>

          <section>
            <button (click)="filterRankingByDate()" type="button">Filtrar</button>
          </section>
          <section>
            <button type="button" (click)="resetFilter()">Resetar Filtro</button>
          </section>
          <section>
            <button type="button" (click)="downloadCSV()">Baixar planilha</button>
          </section>
        </div>

        <div class="filterContainer">
          <section>
            <label for="nameFilter">Filtrar por nome do palestrante</label>
            <input name="nameFilter" id="nameFilter" [(ngModel)]="filterName" (change)="filterRankingByName()" />
          </section>
        </div>
      </div>

      <div *ngIf="!loadingRanking">
        <table class="customTable">
          <thead>
            <th>Ranking</th>
            <th>Palestrante</th>
            <th>Quantidade de eventos</th>
            <th>Quantidade de respostas</th>
            <th>Quantidade de participantes</th>
            <th>Média de respostas</th>
            <th>Pontuação</th>
          </thead>

          <tbody>
            <tr *ngFor="let speaker of filteredRanking | paginate: { itemsPerPage: 10, currentPage: currentPage }">
              <td>#{{ speaker.rank }}</td>
              <td>{{ speaker.nomeCompleto }}</td>
              <td>{{ speaker.qtdEventos }}</td>
              <td>{{ speaker.qtdRespostas }}</td>
              <td>{{ speaker.qtdPar }}</td>
              <td>{{ speaker.media }}%</td>
              <td>{{ speaker.points }}</td>
            </tr>
          </tbody>
        </table>
        <pagination-controls (pageChange)="currentPage = $event"></pagination-controls>
      </div>
    </main>
  </mat-tab>

  <mat-tab label="RESUMO DE USO - QR CODE">
    <app-use-summary></app-use-summary>
  </mat-tab>
</mat-tab-group>
