import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IInvalid } from 'src/app/pages/configuracoes/palestrante/tb-dados';
import { CommonService } from 'src/app/services/common';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { PalestrasDisponiveisService } from 'src/app/services/palestras-disponiveis';

interface IDisponivel {
  descricao: string
  id: number
  id_tema: number
  modo_palestra: "NAO" | "SIM"
  modo_treinamento: "NAO" | "SIM"
  nome: string
  palestrantes_id: number
  photo: any
  publico_alvo: string
  slug: string
  subtitulo: string
  video: string
  new_photo_64: any | null
  id_casa_formula_duracao: number
  id_casa_formato: number
}

interface IDuracao {
  id: number
  duracao: string
  minimo_minuto: number
  minuto_maximo: number
}

@Component({
  selector: 'app-form-palestra-disponivel-admin',
  templateUrl: './form-palestra-disponivel-admin.component.html',
  styleUrls: ['./form-palestra-disponivel-admin.component.scss']
})
export class FormPalestraDisponivelAdminComponent implements OnInit {
  @Input() id: number
  @Input() palestrante: any
  @Output("finished") finished = new EventEmitter();
  loading: boolean = false

  palestra: IDisponivel = { descricao: "", id: null, id_tema: null, modo_palestra: "SIM", modo_treinamento: "NAO", nome: "", palestrantes_id: null, photo: "", publico_alvo: "", slug: "", subtitulo: "", video: "", new_photo_64: null, id_casa_formato: null, id_casa_formula_duracao: null }
  invalids: Array<IInvalid> = []

  temas: Array<any> = []
  formatos: Array<any> = []
  duracoes: Array<IDuracao> = []

  img_temp: any

  exemploDescricao: string = "Exemplo: Monte uma equipe de alta performance com ultra vendedores. Sua equipe precisa potencializar seus resultados? Nesta palestra online eu vou mostrar para você e seus vendedores quais são os 7 passos da jornada em que equipes e vendedores de sucesso tiveram para se tornarem máquinas de vendas, entregando resultados muito acima da média. Você vai aprender: prospecção assertiva; negociação de alta persuasão; fechamento de negócios com as melhores margens. Inscreva-se agora mesmo para transformar seus resultados!";
  mostrarExemploDescricao: boolean = false;
  exemploTitulo: string = "Exemplo: A INCRÍVEL JORNADA DO ULTRA VENDEDOR";
  mostrarExemploTitulo: boolean = false;
  exemploSubtitulo: string = "Exemplo: COMO TRANSFORMAR SUA EQUIPE EM UMA MÁQUINA DE VENDAS";
  mostrarExemploSubtitulo: boolean = false;

  constructor(
    private disponiveisComponent: PalestrasDisponiveisService,
    private palestrantesService: PalestrantesService,
    private commonService: CommonService,
    private toast: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.id > 0) {
      this.disponiveisComponent.getById(this.id, this.palestrante)
        .then((res) => {
          this.palestra = res;
          this.getDuracoes()
        })
        .catch((e) => { this.toast.error("Não foi possível carregar seu produto") })
    }
    this.getTemas()
    this.getInfo()
  }

  async getTemas() {
    try {
      this.temas = await this.palestrantesService.getConhecimentos(this.palestrante)
    } catch (error) {
      this.toast.error("Não foi possível carregar seus temas")
    }
  }

  async getInfo() {
    try {
      this.formatos = await this.commonService.getFormatos()
    } catch (error) {
      this.toast.error("Não foi possível carregar formatos")
    }
  }

  async submit() {
    try {
      this.invalids = []
      this.loading = true

      if (this.id == 0) this.palestra.palestrantes_id = this.palestrante.id

      const res = await this.disponiveisComponent.insertOrUpdate(this.palestra)

      this.toast.success("Salvo com sucesso!");
      this.loading = false
      this.finished.emit({ refresh: false })

    } catch (e) {
      this.loading = false
      if (e?.error?.message.invalids) {
        this.invalids = e.error.message.invalids
        this.toast.error("Campos inválidos!", "Por favor, revise.")
      } else {
        this.toast.error("Não foi possível salvar os dados", "Falha na conexão")
      }
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }

  changeVideo() {
    let v = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;;
    this.palestra.video = (this.palestra.video.match(v)) ? RegExp.$1 : this.palestra.video;
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => { this.palestra.new_photo_64 = reader.result; };
    }
  }

  cancel() {
    this.finished.emit({ refresh: false })
  }

  getDuracoes() {
    this.palestra.id_casa_formula_duracao = null
    this.commonService.getDuracoes(this.palestra.id_casa_formato)
      .then((res: any) => {
        this.duracoes = res.duracoes
        if (this.duracoes.length == 1) this.palestra.id_casa_formula_duracao = this.duracoes[0].id
      })
  }
}
