<div class="row">
    <div class="container__search col-12 col-lg-7 d-flex my-2">
        <img src="assets/icons/search-black.svg" alt="ícone pesquisar">
        <input class="container__search__input form-group m-0 border-0 bg-transparent" placeholder="Buscar..."
            type="text" name="search" [(ngModel)]="search" (ngModelChange)="buscar()">
    </div>
    <div class="col-12 col-lg-4 offset-lg-1 d-flex align-items-center justify-content-end">
        <span>Remover do grupo </span>
        <img class="ml-2 float-right" height="16px" src="assets/icons/excluir.svg" alt="excluir">
    </div>
</div>

<div class="content__cabecalho text-left d-flex p-0">
    <label class="font-title text-uppercase flex2">Nome</label>
    <label class="font-title text-uppercase flex2">E-mail</label>
    <label class="font-title text-uppercase flex1">Setor</label>
    <label class="font-title text-uppercase flex1">Cargo</label>
    <label class="font-title text-uppercase flex03">Ações</label>
</div>

<ul>
    <li #colaborador *ngFor="let colaborador of colaboradores">

        <div class="d-flex content__list__item">
            <div class="flex2">
                <input class="input__disable" type="text" [(ngModel)]="colaborador.nome_completo" disabled="disabled">
            </div>

            <div class="flex2">
                <input class="input__disable" type="text" [(ngModel)]="colaborador.email" disabled="disabled">
            </div>

            <div class="flex1">
                <input class="input__disable" type="text" [(ngModel)]="colaborador.setor" disabled="disabled">
            </div>

            <div class="flex1">
                <input class="input__disable" type="text" [(ngModel)]="colaborador.cargo_nome" disabled="disabled">
            </div>

            <div class="flex03 td_acao align-items-center">
                <img (click)="changeTutor(colaborador)" class="float-right"
                    src="assets/icons/tutor.svg" alt="substituir tutor" ttile="Substituir tutor">

                <img [ngClass]="{'unclickable': colaborador.tarefa_iniciada != null}"
                    (click)="delete(colaborador.id_grupo_equipe)" class="float-right" src="assets/icons/excluir.svg"
                    alt="excluir">
            </div>
        </div>
    </li>
</ul>
<p *ngIf="colaboradores?.length == 0">Nenhum colaborador cadastrado.</p>

<div class="d-flex justify-content-between align-items-center">
    <nav *ngIf="qtdePages?.length > 1">
        <ul class="pagination">
            <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                <a class="page-link">Anterior</a>
            </li>

            <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)"
                class="page-item">
                <a class="page-link">1</a>
            </li>
            <span *ngIf="(page + 1) > 4" class="px-2">...</span>

            <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)"
                class="page-item" *ngFor="let number of getPages()">
                <a class="page-link">{{number+1}}</a>
            </li>

            <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
            <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}"
                (click)="changePage(qtdePages.length - 1)" class="page-item">
                <a class="page-link">{{qtdePages.length}}</a>
            </li>

            <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                <a class="page-link">Próxima</a>
            </li>
        </ul>
    </nav>
</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>