
<main class="appContainer fadeIn row px-5">
  <div class="cardMain col-lg-9">
    <div class="cardMain__header">
      <h2 class="title">
        Vamos começar a aprender
      </h2>
    </div>

    <div class="cardMain__contentScroll mt-2" style="min-height: 78vh;">
      <article *ngIf="loading"  class="d-flex justify-content-center align-items-center w-100" style="height: 75vh;">
        <app-spinner></app-spinner>
      </article>

      <section *ngIf="pendingSkills?.length > 0" class="mb-5">
        <h2 class="title my-3">
          Próximos conteúdos
        </h2>

        <owl-carousel-o class="owlCarouselCustom" [options]="customOptions">
          <ng-template *ngFor="let skill of pendingSkills" [width]="190" carouselSlide>
            <app-card-minha-trilha
              [skill]="skill"
              [outerClasses]="['zoomIn','cardTrilha--horizontal']"
            ></app-card-minha-trilha>
          </ng-template>
        </owl-carousel-o>
      </section>

      <section *ngIf="startedSkills?.length > 0" class="mb-5">
        <h2 class="title my-3">
          Em andamento
        </h2>

        <owl-carousel-o class="owlCarouselCustom" [options]="customOptions">
          <ng-template *ngFor="let skill of startedSkills" [width]="190" carouselSlide>
            <app-card-minha-trilha
              [skill]="skill"
              textButton="Continuar"
              [outerClasses]="['zoomIn','cardTrilha--horizontal']"
            ></app-card-minha-trilha>
          </ng-template>
        </owl-carousel-o>
      </section>

      <section *ngIf="answeredSkills?.length > 0" class="mb-5">
        <h2 class="title my-3">
          Em avaliação
        </h2>

        <owl-carousel-o class="owlCarouselCustom" [options]="customOptions">
          <ng-template *ngFor="let skill of answeredSkills" [width]="190" carouselSlide>
            <app-card-minha-trilha
              [skill]="skill"
              textButton=""
              [outerClasses]="['zoomIn','cardTrilha--horizontal']"
            ></app-card-minha-trilha>
          </ng-template>
        </owl-carousel-o>
      </section>

      <section *ngIf="concludedSkills?.length > 0">
        <h2 class="title my-3">
          Concluidos
        </h2>

        <owl-carousel-o class="owlCarouselCustom" [options]="customOptions">
          <ng-template *ngFor="let skill of concludedSkills" [width]="190" carouselSlide>
            <app-card-minha-trilha
              [skill]="skill"
              textButton=""
              concluded={true}
              [outerClasses]="['zoomIn','cardTrilha--horizontal']"
            ></app-card-minha-trilha>
          </ng-template>
        </owl-carousel-o>
      </section>
    </div>
  </div>
  <div class="cardMain col-lg-2 ml-2">
    <div class="cardMain__header">
      <h2 class="title d-flex align-items-center">
        Meu Progresso
        <img class="mr-3" src="assets/img/logo-white.svg" alt="Meu Rank SA" height="20" />
      </h2>
    </div>
  </div>
</main>
