<section class="container">
  <div class="row">

    <div class="col-10 offset-1 mb-3">
      <div style="text-align: center; margin-bottom: 5px">
        <h3 style="font-size: 22px; font-weight: 600;">Escolha como você quer realizar sua trilha de
          desenvolvimento
        </h3>
      </div>
      <div class="grid-task-container">
        <div style="text-align: center">
          <img class="icon-trail" src="../../../assets/icons/list.svg">
        </div>
      </div>
    </div>

    <article *ngFor="let o of options" class="col-10 offset-1 container-trail mb-4">
      <header>
        <h5>{{o.title}}</h5>
      </header>
      <div class="px-4 px-0 ">
        <p>
          {{o.description}}
        </p>
      </div>
      <button (click)="selectOption(o)" class="btn__primary">Escolher</button>
    </article>

  </div>
</section>
