<div class="container-fluid px-2 px-md-5">

    <div class="row">
        <div class="col-12 col-md-8 container__palestrante">

            <h3 class="font-title">Meu perfil</h3>


            <div class="container px-0">
                <div class="row container__default mr-0 mr-md-3">

                    <div *ngIf="loading" class="d-flex justify-content-center">
                        <app-spinner></app-spinner>
                    </div>

                    <div *ngIf="!loading" class="col-12 col-md-4 content__palestrante__img">
                        <figure>
                            <img *ngIf="palestrante.photo" class="content__palestrante__photo" [src]="palestrante.photo"
                                [alt]="palestrante.nome">
                            <img *ngIf="!palestrante.photo" class="content__palestrante__icon"
                                src="assets/icons/person.svg" [alt]="palestrante.nome">
                        </figure>

                        <div class="div__btn__edit">
                            <button routerLink="/palestrante/perfil/dados" class="btn btn__edit">Editar Perfil</button>
                        </div>

                        <div class="div__btn__edit">
                            <button (click)="openSite()" class="btn btn__edit gray" title="Ver seu perfil no site">Ver
                                no site</button>
                        </div>

                        <div class="div__btn__edit">
                            <button (click)="sendPDFEmailPerfil()" class="btn btn__edit gray"
                                title="Receber perfil completo por e-mail">Perfil por
                                e-mail</button>
                        </div>

                        <div class="d-flex content__palestrante__social">
                            <a [class.unclickable]="!palestrante.linkedin" [href]="palestrante.linkedin"
                                target="_blank">
                                <img src="assets/icons/linkedin-black.svg" alt="linkedin">
                            </a>
                            <a [class.unclickable]="!palestrante.instagram" [href]="palestrante.instagram"
                                target="_blank">
                                <img src="assets/icons/instagram-black.svg" alt="instagram">
                            </a>
                            <a [class.unclickable]="!palestrante.facebook" [href]="palestrante.facebook"
                                target="_blank">
                                <img src="assets/icons/facebook-black.svg" alt="facebook">
                            </a>
                            <a [class.unclickable]="!palestrante.telefone" [href]="palestrante.telefone"
                                target="_blank">
                                <img src="assets/icons/whatsapp-black.svg" alt="whatsapp">
                            </a>
                        </div>
                    </div>


                    <div class="col-12 col-md-8">
                        <div class="d-flex flex-column">
                            <h4 class="font-title m-0">{{palestrante.nome_palestrante}}</h4>

                            <p class="palestrante__cidade">{{palestrante.cidade}} / {{palestrante.estado}} |
                                {{palestrante.pais}}</p>

                            <div class="palestrante__nota">
                                <i *ngFor="let i of nota" [class.gradient07]="i < 1 && i >= 0.7"
                                    [class.gradient05]="i < 0.7 && i > 0.3" [class.gradient03]="i <= 0.3"
                                    class="fa fa-star" aria-hidden="true"></i>
                                <span>({{palestrante.pontuacao}})</span>
                            </div>

                            <p class="palestrante__minibio">{{palestrante.minibio}}</p>
                        </div>
                    </div>


                    <div class="col-12 content__info">
                        <div class="content__title__info">
                            <h4 class="font-title">Temas</h4>
                            <img routerLink="/palestrante/perfil/temas" src="assets/icons/editar-yellow.svg"
                                alt="Editar" title="Editar">
                        </div>

                        <div class="container__areas d-flex">
                            <div *ngIf="assuntos.length == 0">
                                <span class="empty">Nenhum tema cadastrado.</span>
                            </div>
                            <div *ngFor="let assunto of assuntos" class="areas">
                                <label class="areas__item">{{assunto.nome}}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 content__info">
                        <div class="content__title__info">
                            <h4 class="font-title">Experiência profissional</h4>
                            <img routerLink="/palestrante/perfil/experiencia" src="assets/icons/editar-yellow.svg"
                                alt="Editar" title="Editar">
                        </div>

                        <div class="row">
                            <div *ngIf="experiencias.length == 0">
                                <span class="empty">Nenhuma experiência cadastrada.</span>
                            </div>
                            <div *ngFor="let i of experiencias" class="col-12 col-md-4">
                                <div class="d-flex flex-column formacao">
                                    <label class="">{{i.cargo}}</label>
                                    <label class="">{{i.empresa}}</label>
                                    <label class="">{{i.data_formatada}}</label>
                                    <label class="">{{i.cidade}}/{{i.estado}}</label>
                                    <label class="container__checkbox">
                                        Atual
                                        <input type="checkbox" [checked]="i.atual" disabled>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 content__info">
                        <div class="content__title__info">
                            <h4 class="font-title">Formação Acadêmica</h4>
                            <img routerLink="/palestrante/perfil/formacao" src="assets/icons/editar-yellow.svg"
                                alt="Editar" title="Editar">
                        </div>

                        <div class="row">
                            <div *ngIf="formacoes.length == 0">
                                <span class="empty">Nenhuma formação cadastrada.</span>
                            </div>
                            <div *ngFor="let i of formacoes" class="col-12 col-md-4">
                                <div class="d-flex flex-column formacao">
                                    <label class="">{{i.titulo}}</label>
                                    <label class="">{{i.data_formatada}}</label>
                                    <label class="">{{i.local}}</label>
                                    <label class="">{{i.cidade}}/{{i.estado}}</label>
                                    <label class="container__checkbox">
                                        Cursando
                                        <input type="checkbox" [checked]="i.cursando">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-12 content__info">
                        <div class="content__title__info">
                            <h4 class="font-title">Depoimentos</h4>
                            <img routerLink="/palestrante/perfil/depoimentos" src="assets/icons/editar-yellow.svg"
                                alt="Editar" title="Editar">
                        </div>

                        <div class="row">
                            <div *ngIf="depoimentos.length == 0">
                                <span class="empty">Nenhum depoimento.</span>
                            </div>
                            <div *ngFor="let i of depoimentos" class="col-12 col-md-6">
                                <div class="d-flex flex-column depoimento">
                                    <label class="">{{i.nome}}</label>
                                    <label class="depoimento__desc">"{{i.descricao}}"</label>
                                    <label class="">{{i.empresa}}{{i.cidade ? " - " : ""}}{{i.cidade}}</label>
                                    <label class="">{{i.data}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12 col-md-4 mt-4 mt-md-0">

            <div class="container">
                <div class="row">
                    <div class="content__title__info">
                        <h3 class="font-title">Agenda de Palestras</h3>
                        <img routerLink="/palestrante/eventos" src="assets/icons/editar-yellow.svg" alt="Editar"
                            title="Editar">
                    </div>
                    <div class="col-12 container__default container__palestras">
                        <app-list-palestras [tipo]="0" [palestras]="ministradas"
                            [palestrante]="palestrante.nome_palestrante"></app-list-palestras>
                    </div>

                    <div class="content__title__info mt-4">
                        <h3 class="font-title">Produtos</h3>
                        <img routerLink="/palestrante/perfil/produtos" src="assets/icons/editar-yellow.svg" alt="Editar"
                            title="Editar">
                    </div>
                    <div class="col-12 container__default container__palestras">
                        <app-list-palestras [tipo]="1" [palestras]="disponiveis"
                            [palestrante]="palestrante.nome_palestrante"></app-list-palestras>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div style="margin-top: 40px;"></div>
