import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { PalestrasService } from 'src/app/services/palestras';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {
  @Output("updating") updating = new EventEmitter();

  @Input() meses: Array<any> = []
  mesesAnteriores: Array<any> = []
  @Input() palestras: Array<any> = []
  palestrasAnteriores: Array<any> = []
  @Input() all: boolean
  loading: boolean = false
  tab: string = 'prox';

  idSelected: number

  page: number = 0
  limit: number = 5

  constructor(
    private activatedRoute: ActivatedRoute,
    private palestrantesService: PalestrantesService,
    private palestrasService: PalestrasService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    let params = this.activatedRoute.queryParams['_value']
    if (params.tab) this.tab = params.tab

    this.getPalestrasAnteriores()
  }


  async getPalestrasAnteriores() {
    try {
      this.loading = true
      const res = await this.palestrasService.getPalestrasAnteriores(this.page, this.limit)

      this.palestrasAnteriores = [...this.palestrasAnteriores, ...res]
      this.setGroupMonth()
      this.loading = false
    } catch (error) {
      this.toast.error("Não foi possível carregar as anteriores")
      this.loading = false
    }
  }

  setGroupMonth() {
    let setMeses = new Set(this.palestrasAnteriores.map(item => item.mes));
    this.mesesAnteriores = [];
    setMeses.forEach(g => {
      this.mesesAnteriores.push({
        name: g,
        values: this.palestrasAnteriores.filter(i => i.mes == g)
      })
    });
  }

  loadMore() {
    this.page += 1
    this.getPalestrasAnteriores()
  }

  updateMinistrada(e: any) {
    this.idSelected = e.id
    this.updating.emit({ fullscreen: true })
  }

  setMinistrada() {
    this.idSelected = null
    this.updating.emit({ fullscreen: false })
  }
}
