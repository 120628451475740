import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { FormacoesAcademicasService } from 'src/app/services/formacoesAcademcias';

export interface IFormacao {
  id: number;
  palestrantes_id: number;
  titulo: string;
  local: string;
  cidade: string;
  estado: string;
  cursando: boolean;
  data_inicio: Date;
  data_fim: Date;
}
@Component({
  selector: 'app-formacao-admin',
  templateUrl: './formacao-admin.component.html',
  styleUrls: ['./formacao-admin.component.scss'],
})
export class FormacaoAdminComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Input() palestrante: any;

  bsModalRef: BsModalRef;

  idSelected: number = undefined;
  loading: boolean = false;
  formacoes: Array<IFormacao> = [];

  constructor(
    private formacoesAcademicasService: FormacoesAcademicasService,
    private toast: ToastrService,
    private modalService: BsModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getFormacoes();
  }

  async getFormacoes() {
    try {
      this.loading = true;
      const res = await this.formacoesAcademicasService.getListByPalestranteId(
        this.palestrante
      );
      this.formacoes = res;
      this.loading = false;
    } catch (error) {
      this.toast.error('Não foi possível carregas seus produtos');
      this.loading = false;
    }
  }

  insert() {
    if (!(this.formacoes.length >= 3)) {
      this.idSelected = 0;
    } else {
      this.toast.error(
        'O limite de cadastros foi excedido. Exclua um dos cadastros para inserir um novo.'
      );
    }
  }

  update(id: number) {
    this.idSelected = id;
  }

  delete(id: number) {
    const initialState = {
      mensagem: 'Deseja realmente excluir?',
      submensagem: '',
    };
    const config = { initialState };
    this.bsModalRef = this.modalService.show(
      ModalConfirmationComponent,
      config
    );

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value;
      value == true ? true : false;
      if (value == true) this.deleteRequest(id);
    });
  }

  async deleteRequest(id: number) {
    try {
      this.loading = true;
      const res = await this.formacoesAcademicasService.delete(id, this.palestrante);
      this.getFormacoes();
      this.toast.success('Excluído com sucesso!');

      this.loading = false;
    } catch (error) {
      this.toast.error('Não foi possível exluir');
      this.loading = false;
    }
  }

  formFinished() {
    this.idSelected = undefined;
    this.close();
    this.getFormacoes();
  }

  close() {
    this.onClose.emit('');
  }
}
