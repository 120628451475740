import { Routes } from '@angular/router';

import { HomeComponent } from './home';
import { AgendaComponent } from './agenda';
import { CertificadosComponent } from './certificados';
import { PalestrasGravadasComponent } from './palestras-gravadas';
import { TrilhaPersonalizadaComponent } from './trilha-personalizada';
import { PdiListComponent } from './trilha-personalizada/pdi-list';
import { PdiExecutionComponent } from './trilha-personalizada/pdi-execution/pdi-execution.component';
import { MinhaTrilhaStreamingComponent } from './minha-trilha-streaming/minha-trilha-streaming.component';
import { MinhaTrilhaPageComponent } from './minha-trilha-page/minha-trilha-page.component';
import { PalestraComponent } from './palestra';

export const EspectadorRoutes: Routes = [
  {
    path: 'espectador/home',
    component: HomeComponent
  },
  {
    path: 'espectador/agenda',
    component: AgendaComponent
  },
  {
    path: 'espectador/palestras-gravadas',
    component: PalestrasGravadasComponent
  },
  {
    path: 'espectador/certificados',
    component: CertificadosComponent
  },
  {
    path: 'espectador/trilha-personalizada',
    component: TrilhaPersonalizadaComponent
  },
  {
    path: 'espectador/trilha-personalizada/pdi',
    component: PdiListComponent
  },
  {
    path: 'espectador/pdi-execution',
    component: PdiExecutionComponent

  },
  {
    path: 'espectador/minhaTrilha',
    component: MinhaTrilhaPageComponent
  },
  {
    path: 'espectador/minhaTrilha/:id',
    component: MinhaTrilhaStreamingComponent
  },
  {
    path: 'espectador/palestra/:id',
    component: PalestraComponent
  },
  {
    path: 'espectador/**',
    redirectTo: 'espectador/home'
  }
];
