import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'string'
})
export class StringPipe implements PipeTransform {

  transform(value: any, args: string): any {
    if (args.toUpperCase() == 'CPFCNPJ') {
      if (value.length == 11) {
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
      } else if (value.length == 14) {
        return value.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
          '$1.$2.$3/$4-$5'
        )
      }
    } else if (args.toUpperCase() == 'PHONE') {
      if (value.length == 10) {
        return value.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3')
      } else if (value.length == 11) {
        return value.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3')
      }
    }
    return null;
  }

}
