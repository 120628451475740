import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

// const config: SocketIoConfig = { url: environment.API_V2_URL, options: {} };

import { AppRoutingModule } from './app-routing.module';
import { UniversidadeCorporativaModule } from './pages/universidade-corporativa';
import { DesenvolvimentoModule } from './pages/desenvolvimento';
import { EspecialistasModule } from './pages/especialistas';
import { ExportComponentsModule } from './common/export-components';
import { EspectadorModule } from './pages/espectador';

import { AppComponent } from './app.component';
import { SideComponent } from './shared/side';
import { HeaderComponent } from './shared/header';
import { EntryComponent } from './entry';
import { LoginComponent } from './pages/login';
import { FooterComponent } from './shared/footer';
import { ModalPlayComponent } from './common/modal-play';
import { ModalPDFComponent } from './common/modal-pdf';
import { ModalAvisoCadastroComponent } from './common/modal-aviso-cadastro';
import { RedefinirSenhaComponent } from './pages/redefinir-senha';
import { LockScreenComponent } from './pages/lock-screen';

import { ModalConfirmationComponent } from './common/modal-confirmation';
import { ModalMessageComponent } from './common/modal-message';
import { AuthComponent } from './pages/login/auth';
import { EntryProfileComponent } from './pages/login/entry-profile';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/messaging/messaging.service';
import { AsyncPipe } from '@angular/common';
import { RegistrationComponent } from './pages/registration';
import { SharedModule } from './shared/shared.module';
import { AdminModule } from './pages/admin';
import { StringPipe } from './utils/pipes/string.pipe';
import { CuradorModule } from './pages/curador/curador.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NewHomeComponent } from './pages/newHome/new-home.component';
import { SearchHomeComponent } from './pages/pesquisar/searchHome/search-home.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ResultsComponent } from './pages/results/results.component';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { ResumoIndicacoesComponent } from './pages/curador/resumo-indicacoes/resumo-indicacoes.component';
import { ModalDepoimentosComponent } from './common/depoimentos/modaldepoimentos.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
@NgModule({
  declarations: [
    AppComponent,
    SideComponent,
    HeaderComponent,
    EntryComponent,
    LoginComponent,
    FooterComponent,
    ModalPlayComponent,
    ModalAvisoCadastroComponent,
    ModalConfirmationComponent,
    RedefinirSenhaComponent,
    LockScreenComponent,
    AuthComponent,
    EntryProfileComponent,
    ModalMessageComponent,
    ModalPDFComponent,
    StringPipe,
    NewHomeComponent,
    SearchHomeComponent,
    ResultsComponent,
    ResumoIndicacoesComponent,
    ModalDepoimentosComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    FullCalendarModule,
    ToastrModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    NgxMaskModule.forRoot(),
    CarouselModule,
    NgSelectModule,
    PdfViewerModule,
    UniversidadeCorporativaModule,
    EspectadorModule,
    EspecialistasModule,
    ExportComponentsModule,
    DesenvolvimentoModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    SharedModule,
    CuradorModule,
    NgxPaginationModule,
    AngularEditorModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    PowerBIEmbedModule,
    MatExpansionModule,
    MatDialogModule
  ],
  providers: [HeaderComponent, BsModalRef, MessagingService, AsyncPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
