<div class="container-fluid container__dados mb-4">
  <div class="d-flex justify-content-between">
    <h5 class="font-title text-uppercase">{{idPalestra > 0 ? 'Editar Evento' : 'Adicionar Evento'}}</h5>
    <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close" title="Cancelar">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form class="row" (submit)="salvar()">
    <div class="col-12 d-flex flex-column">
      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="formato">formato *</label>
          <select disabled="{{isDisable}}" id="formato" [class.invalid]="isIncorrect('formato')" class="form-control"
            name="formato" placeholder="Formato *" [ngModelOptions]="{standalone: true}" (change)="changeTipo($event)"
            [(ngModel)]="palestra.formato">
            <option value="">Selecione *</option>
            <option *ngFor="let i of formatos" [value]="i.id">{{i.nome_formato}}</option>
          </select>
          <span class="error">{{getError('formato')}}</span>
        </div>

        <!-- <div class="form-group col-12 col-md-4">
                    <label class="label-input" for="tipo">Tipo *</label>
                    <select disabled="{{isDisable}}" id="tipo" [class.invalid]="isIncorrect('tipo')" class="form-control" name="tipo"
                        placeholder="Tipo *" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.tipo">
                        <option value="null">Selecione *</option>
                        <option *ngFor="let i of arrayTipo" [value]="i.value">{{i.text}}</option>
                    </select>
                    <span class="error">{{getError('tipo')}}</span>
                </div> -->

        <div [class.d-none]="palestra.tipo != arrayTipo[0].value" class="form-group col-12 col-md-4">
          <label class="label-input" for="gratuita">Ingresso *</label>
          <select disabled="{{isDisable}}" id="gratuita" [class.invalid]="isIncorrect('gratuita')" class="form-control"
            name="gratuita" placeholder="Ingresso *" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="palestra.gratuita">
            <option value="null">Selecione *</option>
            <option *ngFor="let i of arrayIngresso" [value]="i.value">{{i.text}}</option>
          </select>
          <span class="error">{{getError('gratuita')}}</span>
        </div>

        <div
          [class.d-block]="((palestra.gratuita == arrayIngresso[2].value) && (palestra.tipo == arrayTipo[0].value))"
          class="form-group d-none col-12 col-md-4"
        >
          <label class="label-input" for="valor">valor *</label>
          <input
            disabled="{{isDisable}}"
            id="valor"
            [class.invalid]="isIncorrect('valor')"
            class="form-control"
            name="valor"
            type="text"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="palestra.valor"
            placeholder="valor *"
            mask="separator.2"
          >
          <span class="error">
            {{getError('valor')}}
          </span>
          <p class="input__info">
            Para usuários que não possuem assinatura será adicionado R$ 5,00 ao valor do ingresso.
            <br> <strong>Valor Total: R$ {{getValorInfo()}},00</strong>
          </p>
        </div>
      </div>

      <div [class.d-none]="palestra.gratuita != arrayIngresso[0].value" class="row">
        <div class="form-group col-12 col-md-4">
          <P class="descr__contratante m-0">Palestras In Company deve conter o código do contratante. Não
            possui o código do contratante? <a (click)="openWhats()">Entre em contato</a>.</P>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="id_contratante">código do contratante *</label>
          <input disabled="{{isDisable}}" id="id_contratante" [class.invalid]="isIncorrect('id_contratante')"
            class="form-control" name="id_contratante" type="text" (change)="searchContratante()"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.id_contratante" placeholder="código *">
          <span class="error">{{getError('id_contratante')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="nomeContratante">contratante *</label>
          <input readonly id="nomeContratante" [class.invalid]="isIncorrect('nomeContratante')" class="form-control"
            name="nomeContratante" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="nomeContratante"
            placeholder="Empresa">
          <span class="error">{{getError('nomeContratante')}}</span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-md-6">
          <label class="label-input" for="nome">
            título *
            (<a class="exemplo" (click)="mostrarExemploTitulo = !mostrarExemploTitulo">
              {{!mostrarExemploTitulo?"ver exemplo":"esconder exemplo"}}</a>)
          </label>
          <p class="exemplo" [class.d-none]="!mostrarExemploTitulo">{{exemploTitulo}}</p>
          <input disabled="{{isDisable}}" id="nome" [class.invalid]="isIncorrect('nome')" class="form-control"
            name="nome" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.nome"
            placeholder="Título *" maxlength=50>
          <span class="error">{{getError('nome')}}</span>
        </div>

        <div class="form-group col-12 col-md-6">
          <label class="label-input" for="subtitulo">
            subtítulo
            (<a class="exemplo" (click)="mostrarExemploSubtitulo = !mostrarExemploSubtitulo">
              {{!mostrarExemploSubtitulo?"ver exemplo":"esconder exemplo"}}</a>)
          </label>
          <p class="exemplo" [class.d-none]="!mostrarExemploSubtitulo">{{exemploSubtitulo}}</p>
          <input disabled="{{isDisable}}" id="subtitulo" [class.invalid]="isIncorrect('subtitulo')" class="form-control"
            name="subtitulo" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.subtitulo"
            placeholder="Subtítulo" maxlength=50>
          <span class="error">{{getError('subtitulo')}}</span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12">
          <label class="label-input" for="descricao">Descrição *
            (<a class="exemplo" (click)="mostrarExemploDescricao = !mostrarExemploDescricao">
              {{!mostrarExemploDescricao?"ver exemplo":"esconder exemplo"}}</a>)
          </label>
          <div [class.d-none]="!mostrarExemploDescricao" class="ex__75">
            <p class="exemplo" [innerHTML]="exemploDescricao"></p>
          </div>
          <textarea disabled="{{isDisable}}" id="descricao" [class.invalid]="isIncorrect('descricao')"
            class="form-control" type="text" name="descricao" maxlength="1500" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="palestra.descricao" placeholder="Descreva sua palestra"></textarea>
          <span class="error">{{getError('descricao')}}</span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-md-8">
          <label class="label-input" for="publico_alvo">público-alvo</label>
          <input disabled="{{isDisable}}" id="publico_alvo" [class.invalid]="isIncorrect('publico_alvo')"
            class="form-control" name="publico_alvo" type="text" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="palestra.publico_alvo" placeholder="Público-alvo" maxlength=60>
          <span class="error">{{getError('publico_alvo')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="id_tema">tema *</label>
          <ng-select disabled="{{isDisable}}" [class.invalid]="isIncorrect('id_tema')" [searchable]="true"
            [clearable]="true" bindLabel="nome" bindValue="id" placeholder="Selecione *" groupBy="nome_pai"
            notFoundText="Não encontrado" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.id_tema"
            [items]="temas">

            <ng-template ng-optgroup-tmp let-item="item">
              {{item.nome_pai || 'Sem grupo'}}
            </ng-template>
            <!-- <ng-option [value]="i.id" *ngFor="let i of temas">
                            {{i.nome}}
                        </ng-option> -->
          </ng-select>
          <span class="error">{{getError('id_tema')}}</span>
        </div>
      </div>

      <div class="row">
      </div>

      <div class="row">
        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="data">data *</label>
          <input disabled="{{isDisable}}" id="data" [class.invalid]="isIncorrect('data')" class="form-control"
            name="data" type="date" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.data"
            (change)="avisoData($event)" placeholder="Data *">
          <span class="error">{{getError('data')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="hora">Horário *</label>
          <select disabled="{{isDisable}}" id="hora" [class.invalid]="isIncorrect('hora')" class="form-control"
            name="hora" placeholder="Horário *" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.hora">
            <option value="">Selecione *</option>
            <option *ngFor="let i of arrayHorarios" [value]="i.value">{{i.text}}</option>
          </select>
          <span class="error">{{getError('hora')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="duracao">Duração *</label>
          <select disabled="{{isDisable}}" id="duracao" [class.invalid]="isIncorrect('duracao')" class="form-control"
            name="duracao" placeholder="Duração *" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.duracao">
            <option value="null">Selecione *</option>
            <option *ngFor="let i of arrayDuracoes" [value]="i.value">{{i.text}}</option>
          </select>
          <span class="error">{{getError('duracao')}}</span>
        </div>
      </div>

      <div [class.d-none]="palestra.tipo != arrayTipo[1].value" class="row">
        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="cep">cep *</label>
          <input disabled="{{isDisable}}" id="cep" [class.invalid]="isIncorrect('cep')" class="form-control" name="cep"
            type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.cep" placeholder="CEP *"
            (change)="completeCEP()" mask="00000-000">
          <span class="error">{{getError('cep')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="estado">estado *</label>
          <select disabled="{{isDisable}}" id="estado" [class.invalid]="isIncorrect('estado')" class="form-control"
            name="estado" placeholder="Prória *" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.estado">
            <option value="null">Selecione *</option>
            <option *ngFor="let i of arrayEstados" [value]="i.uf">{{i.estado}}</option>
          </select>
          <span class="error">{{getError('estado')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="cidade">cidade *</label>
          <input disabled="{{isDisable}}" id="cidade" [class.invalid]="isIncorrect('cidade')" class="form-control"
            name="cidade" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.cidade"
            placeholder="Cidade *">
          <span class="error">{{getError('cidade')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="endereco">Endereço *</label>
          <input disabled="{{isDisable}}" id="endereco" [class.invalid]="isIncorrect('endereco')" class="form-control"
            name="endereco" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.endereco"
            placeholder="Endereço *">
          <span class="error">{{getError('endereco')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="local">Local/Prédio</label>
          <input disabled="{{isDisable}}" id="local" [class.invalid]="isIncorrect('local')" class="form-control"
            name="local" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.local"
            placeholder="Local / Prédio">
          <span class="error">{{getError('local')}}</span>
        </div>

        <div class="form-group col-12 col-md-4">
          <label class="label-input" for="sala">sala</label>
          <input disabled="{{isDisable}}" id="sala" [class.invalid]="isIncorrect('sala')" class="form-control"
            name="sala" type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.sala"
            placeholder="sala">
          <span class="error">{{getError('sala')}}</span>
        </div>
      </div>

      <!-- <div class="row"> -->
      <!-- <div class="form-group col-12 col-md-4">
                    <label class="label-input" for="propria">Palestra prória? *</label>
                    <select disabled="{{isDisable}}" id="propria" [class.invalid]="isIncorrect('propria')" class="form-control" name="propria"
                        placeholder="Prória *" [ngModelOptions]="{standalone: true}" [(ngModel)]="palestra.propria">
                        <option value="">Selecione *</option>
                        <option *ngFor="let i of arrayPropria" [value]="i.value">{{i.text}}</option>
                    </select>
                    <span class="error">{{getError('propria')}}</span>
                </div> -->

      <!-- <div [class.d-none]="palestra.propria != 0" class="form-group col-12 col-md-4">
                    <label class="label-input" for="empresa_contratante">contratante *</label>
                    <input disabled="{{isDisable}}" id="empresa_contratante" [class.invalid]="isIncorrect('empresa_contratante')" class="form-control"
                        name="empresa_contratante" type="text" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="palestra.empresa_contratante" placeholder="empresa_contratante *">
                    <span class="error">{{getError('empresa_contratante')}}</span>
                </div> -->

      <!-- <div [class.d-none]="palestra.propria != 0" class="form-group col-12 col-md-4">
                    <label class="label-input" for="email_contratante">E-mail do email_contratante *</label>
                    <input disabled="{{isDisable}}" id="email_contratante" [class.invalid]="isIncorrect('email_contratante')"
                        class="form-control" name="email_contratante" type="text" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="palestra.email_contratante" placeholder="E-mail do contratante *">
                    <span class="error">{{getError('email_contratante')}}</span>
                </div> -->
      <!-- </div> -->

      <div class="row">
        <div class="d-flex pt-2 px-3">
          <button (click)="changeMidia()" type="button" class="btn btn-primary btn-warning">Alterar Mídia</button>
        </div>
      </div>

      <div class="row">
        <div class="d-flex pt-2 div__btn__salvar">
          <button (click)="cancel()" type="button" class="btn btn-primary btn-secondary mr-3"
            title="Cancelar">CANCELAR</button>

          <button *ngIf="!loading && !isDisable" type="submit" class="btn btn-primary btn-warning">Salvar</button>
          <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
            <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
          </button>
        </div>
      </div>

    </div>
  </form>
</div>
