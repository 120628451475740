<div class="modal-header">
    <div class="d-flex flex-column align-items-center">
        <h1>{{message}}</h1>
        <h2>{{submessage}}</h2>
    </div>
    <div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </div>
</div>
<div class="modal-body">
    <div class="psa-form-group">
        <button (click)="bsModalRef.hide()">OK</button>
    </div>
</div>