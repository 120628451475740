import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { estados } from 'src/app/common/array-estados';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { ICargo } from 'src/app/pages/universidade-corporativa';
import { EspectadoresService } from 'src/app/services/espectadores';
import { UserService } from 'src/app/services/user';
import { ViacepService } from 'src/app/services/viacep';

export interface IProfileEspectador {
  id: number
  bairro: string
  cep: string
  cidade: string
  codigo_indicacao: string
  email: string
  estado: string
  logradouro: string
  nome: string
  numero: string
  pais: string
  perfil: string
  photo: string
  sobrenome: string
  telefone: string
  cpf: string
  id_cargo: number
  cargo: string
}

export interface IInvalid {
  campo: string
  erro: string
}

@Component({
  selector: 'app-tb-espectador',
  templateUrl: './tb-espectador.component.html',
  styleUrls: ['./tb-espectador.component.scss']
})
export class TbEspectadorComponent implements OnInit {
  bsModalRef: BsModalRef;
  @Output("next") next = new EventEmitter();

  loading: boolean = false
  arrayStates = estados;
  cargos: Array<ICargo> = [];

  user: IProfileEspectador = { id: 0, bairro: '', cep: '', cidade: '', codigo_indicacao: '', email: '', estado: '', logradouro: '', nome: '', numero: '', pais: '', perfil: '', photo: '', sobrenome: '', telefone: '', cpf: '', id_cargo: 0, cargo: '' };
  invalids: Array<IInvalid> = []

  constructor(
    private cepService: ViacepService,
    private userService: UserService,
    private espectadoresService: EspectadoresService,
    private modalService: BsModalService,
    private app: AppComponent,
    private toast: ToastrService) { }

  async ngOnInit() {
    this.getUser()
    this.getCargos()
  }

  async getCargos() {
    try {
      const res = await this.userService.getCargos()
      this.cargos = res ?? res;
      const cargoItem = this.cargos.filter((i: ICargo) => i.descricao == this.user.cargo)[0]

      if (cargoItem) {
        this.user.id_cargo = cargoItem.id
      }
    } catch (error) {
    }
  }

  getUser() {
    this.user = JSON.parse(localStorage.getItem("espectador"))
  }

  refreshPhoto() {
    this.espectadoresService.getProfile()
      .then((res: { user: IProfileEspectador }) => this.user.photo = res.user.photo )
  }

  async completeCEP() {
    if (this.user.cep?.length === 8) {
      const initialState = { mensagem: "Completar endereço com CEP?", submensagem: "" }
      const config = { initialState }
      this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)
      let newSubscriber = this.modalService.onHide.subscribe(() => {
        newSubscriber.unsubscribe();
        if (this.bsModalRef.content.value) this.completeAddressByCEP()
      })
    } else {
      this.toast.error("CEP inválido.")
    }

  }

  validations() {
    this.user.email = this.user.email ? this.user.email.toLowerCase().trim() : "";
    this.user.nome = this.user.nome ? this.user.nome.trim() : "";
    this.user.cpf = this.user.cpf ? this.user.cpf.trim() : "";
    this.user.cep = this.user.cep ? this.user.cep.trim() : "";
    this.user.cidade = this.user.cidade ? this.user.cidade.trim() : "";
    this.user.estado = this.user.estado ? this.user.estado.trim() : "";
    this.user.cep = this.user.cep ? this.user.cep.trim() : "";
    this.user.numero = this.user.numero ? this.user.numero.trim() : "";
    this.user.logradouro = this.user.logradouro ? this.user.logradouro.trim() : "";
    this.user.bairro = this.user.bairro ? this.user.bairro.trim() : "";
    this.user.cargo = this.user.cargo ? this.user.cargo.trim() : "";
    if (this.user.id_cargo != 6) this.user.cargo = this.cargos.filter((item: ICargo) => item.id == this.user.id_cargo)[0].descricao

    if (!this.user.nome || this.user.nome == "") {
      this.pushInvalid('nome', 'Nome', true)
    }

    if (!this.user.sobrenome || this.user.sobrenome == "") {
      this.pushInvalid('sobrenome', 'Sobrenome', true)
    }

    if (!this.user.telefone || this.user.telefone == "") {
      this.pushInvalid('telefone', 'Telefone', true)

    }

    if (!this.userService.validationCPF(this.user.cpf)) {
      this.pushInvalid('cpf', 'CPF', true)
    }

    if (!this.user.telefone || this.user.telefone == "") {
      this.pushInvalid('telefone', 'Telefone', true)

    }

    if (!this.user.cep || this.user.cep == "") {
      this.pushInvalid('cep', 'CEP', true)
    }

    if (!this.user.estado || this.user.estado == "") {
      this.pushInvalid('estado', 'Estado', true)
    }

    if (!this.user.cidade || this.user.cidade == "") {
      this.pushInvalid('cidade', 'Cidade', true)
    }

    if (!this.user.numero || this.user.numero == "") {
      this.pushInvalid('numero', 'Número', true)
    }

    if (!this.user.logradouro || this.user.logradouro == "") {
      this.pushInvalid('logradouro', 'Logradouro', true)
    }

    if (!this.user.bairro || this.user.bairro == "") {
      this.pushInvalid('bairro', 'Bairro', true)
    }
  }

  async submit() {
    this.invalids = []
    try {
      this.loading = true

      this.validations()

      if (this.invalids.length > 0) {
        this.loading = false
        this.toast.error("Campos inválidos!", "Por favor, revise.")
        return
      }

      const res = await this.espectadoresService.saveProfile(this.user)
      this.loading = false
      this.toast.success("Salvo com sucesso!");
      this.next.emit({ next: true });
      this.app.refreshInfoGlobal()

    } catch (error) {
      this.loading = false
      this.toast.error("Não foi possível salvar os dados", "Falha na conexão")
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }

  async completeAddressByCEP() {
    try {
      const resCEP = await this.cepService.getCEP(this.user.cep)
      if (resCEP.logradouro) {
        this.user.bairro = resCEP.bairro
        this.user.cidade = resCEP.localidade
        this.user.logradouro = resCEP.logradouro
        this.user.bairro = resCEP.bairro
        this.user.estado = resCEP.uf
      } else {
        this.toast.error("Não foi possível preencher os campos com o CEP.", "Revise o CEP informado.")
      }
    } catch (error) {
      this.toast.error("Não foi possível preencher os campos com o CEP.", "Revise o CEP informado.")
    }
  }

  pushInvalid(fieldName: string, text: string, isDefaultText: boolean) {
    this.invalids.push(
      {
        campo: fieldName,
        erro: !isDefaultText ? text : `Campo ${text} deve ser preenchido`
      }
    )
  }
}
