import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ISkill, TarefasService } from 'src/app/services/tarefas';
import { QuestionAnswersComponent } from '../question-answers';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  @ViewChild("questionAnswer", { static: false }) questionAnswer: QuestionAnswersComponent;

  questions: Array<any>
  @Input() skill: ISkill
  status: string
  skillTitle: string
  loading: boolean = false

  isShowButtons: boolean = false
  indexSelected: number = null;
  idSkill: number = null;
  canConcluded: boolean = false;

  constructor(
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private taskService: TarefasService,
  ) { }

  ngOnInit(): void {
    this.skillTitle = this.skill.habilidade_titulo
    this.status = this.skill.habilidade_status
    this.questions = this.skill.perguntas
    let id = this.route.snapshot.paramMap.get("id")
    this.idSkill = parseInt(id)
    this.checkCanConclued()
  }

  async getQuestions() {
    this.questions = await this.taskService.getQuestionsSkillById(this.idSkill)
    this.checkCanConclued()
  }

  checkCanConclued() {
    let filtered = this.questions?.find(i => !i.resposta)
    if (!filtered) this.canConcluded = true
  }

  selectQuestionAnswer(question: any) {
    this.isShowButtons = true
    this.indexSelected = this.questions.indexOf(question)
    this.questionAnswer.size = this.questions.length
    this.questionAnswer.indexQuestion = this.questions.indexOf(question)
    this.questionAnswer.question = question
    this.questionAnswer.ngOnInit()
  }

  async next() {

    if (this.questionAnswer.question.tipo == "DESCRITIVA") {
      await this.questionAnswer.checkAnswer(null)
    }

    if (this.questionAnswer.isAnswered && this.status !== "FINALIZADA" && this.status !== "AVALIADA") {
      this.getQuestions()
    }

    let nextIndexSelected = this.indexSelected + 1
    if (nextIndexSelected >= this.questions.length) {
      nextIndexSelected = 0
    }

    this.selectQuestionAnswer(this.questions[nextIndexSelected])
  }

  async concludeSkill() {
    if (this.questionAnswer.question.tipo == "DESCRITIVA") {
      await this.questionAnswer.checkAnswer(null)
    }
    try {
      if (this.status == 'PENDENTE' || this.status == 'INICIADA' || this.status == 'REFAZER')
        await this.taskService.concludeTask(this.skill.id)
      this.router.navigate(['/desenvolvimento/home'])
    } catch (error) {
      this.toast.error("Algo deu errado")
    }
  }
}
