<p class="mb-2 empty" *ngIf="alertPrincipalProfile">{{alertPrincipalProfile}}</p>
<section class="grid-container">

    <div *ngIf="loading" class="d-flex justify-content-center">
        <app-spinner></app-spinner>
    </div>

    <article #planShow [ngClass]="{ 
        plan__selected: planShow.class === 'showChildren', 
        hideMore: planShow.class !== 'showChildren',
        currentPlan: userPlan.plano_id == p.id
    }" class="card__plano" *ngFor="let p of plans">
        <header class="d-flex justify-content-between flex-wrap">
            <div class="d-flex flex-column">
                <span *ngIf="userPlan.plano_id == p.id" class="current">Seu plano</span>
                <span class="text">Plano</span>
                <h5 class="title">{{p.nome}}</h5>
            </div>

            <div class="d-flex flex-column-end align-items-end">
                <span class="title">
                    {{formatMoney(p.valor)}} <span class="month">/mês</span>
                </span>
            </div>
        </header>

        <p class="text">
            {{p.subtitulo}}
        </p>

        <div class="containerHideMore">
            <p [innerHTML]="p.descricao_curta_html" class="moreInfo"></p>

            <div class="d-flex justify-content-center">
                <button *ngIf="userPlan.plano_id != p.id" (click)="select(p)" class="btn__primary">
                    alterar plano
                </button>
                <button *ngIf="userPlan.plano_id == p.id" (click)="select(p)" class="btn__primary">
                    renovar plano
                </button>
            </div>
        </div>

        <div [ngClass]="{ rotate180 : planShow.class != 'showChildren'}" class="container__more" #more title="Ver mais"
            (click)="planShow.class = planShow.class == 'showChildren' ? '' :  'showChildren'">
            <img class="icon__more" src="assets/icons/open_more.svg" alt="abrir" title="Ver mais">
        </div>

    </article>

</section>