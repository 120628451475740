<main>
  <h1>Gerenciar Usuários</h1>

  <div class="filterContainer">
    <section>
      <label for="name">Filtrar Usuários</label>
      <input
        type="text"
        id="name"
        name="name"
        placeholder="Filtrar por: nome, sobrenome ou email..."
        [(ngModel)]="filters.query"
        (change)="queryFilter()"
      />
    </section>

    <section>
      <label for="profile">Filtrar pro Perfil</label>
      <select name="profile" id="profile" [(ngModel)]="filters.profile" (change)="filterByProfile()">
        <option value="all">Todos</option>
        <option value="admin">Admin</option>
        <option value="avaliador">Admin Avaliador</option>
        <option value="curadora">Curador A</option>
        <option value="curadorb">Curador B</option>
        <option value="curadorc">Curador C</option>
      </select>
    </section>
  </div>

  <div>
    <table class="customTable">
      <thead>
        <th>#</th>
        <th>NOME</th>
        <th>EMAIL</th>
        <th>PERFIL</th>
        <th>STATUS</th>
        <th>EXCLUIR</th>
        <th>EDITAR</th>
      </thead>
      <tbody>
        <tr *ngFor="let user of users; index as i">
          <td>{{ i + 1}}</td>
          <td>{{ user.nome }} {{ user.sobrenome }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.perfil }}</td>
          <TD>{{ user.is_active ? 'Ativo' : 'Inativo' }}</TD>
          <td>
            <img src="../../../../assets/icons/excluir.svg" alt="ícone de exclusão" (click)="openConfirmationModal(user.id)">
          </td>
          <td>
            <img src="../../../../assets/icons/editar.svg" alt="ícone de edição" (click)="editUser(user.id)">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</main>
