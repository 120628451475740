import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { EspectadoresService } from 'src/app/services/espectadores';

@Component({
  selector: 'app-solicitacao-colaborador',
  templateUrl: './solicitacao-colaborador.component.html',
  styleUrls: ['./solicitacao-colaborador.component.scss']
})
export class SolicitacaoColaboradorComponent implements OnInit {

  loading: boolean = true
  idUser: number;
  idCompany: number;

  error: string = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private app: AppComponent,
    private viwerService: EspectadoresService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    let company = this.route.snapshot.paramMap.get("idCompany")
    let userStorage = JSON.parse(localStorage.getItem("usuario"))
    let user = this.route.snapshot.paramMap.get("idUser")
    if (user != userStorage?.id) {
      this.app.logout()
    }

    this.idCompany = parseInt(company)
    this.idUser = parseInt(user)

    this.acceptInvite()
  }

  async acceptInvite() {
    try {
      const res = await this.viwerService.acceptInviteToBeCollaborator(this.idCompany, this.idUser)
      this.loading = false
      this.toast.success("Solicitação aceita", "Conta configurada")
      this.app.setIsRouteLogout(false)
      this.router.navigate(['login'])
    } catch (e) {
      let messageError = e.error.message;
      this.loading = false;
      this.error = messageError;
      this.toast.error("Erro ao aceitar convite", messageError)
    }
  }
}
