import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

interface IItemMenu {
  ordem: number,
  title: string,
  page: string,
  selected: boolean
  lastItem?: boolean
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  @Input() itemSelected: string;

  list: Array<IItemMenu> = [
    {
      ordem: 1,
      title: "Dados",
      page: "palestrante/perfil/dados",
      selected: false
    },
    {
      ordem: 2,
      title: "Temas",
      page: "palestrante/perfil/temas",
      selected: false
    },
    {
      ordem: 3,
      title: "Portfólio de produtos",
      page: "palestrante/perfil/produtos",
      selected: false
    },
    {
      ordem: 4,
      title: "Tabela de cachês",
      page: "palestrante/perfil/cache",
      selected: false,
      lastItem: true
    },
    {
      ordem: 5,
      title: "Experiência",
      page: "palestrante/perfil/experiencia",
      selected: false
    },
    {
      ordem: 6,
      title: "Formação",
      page: "palestrante/perfil/formacao",
      selected: false,
      lastItem: false
    },
    {
      ordem: 7,
      title: "Depoimentos",
      page: "palestrante/perfil/depoimentos",
      selected: false
    },
  ]

  constructor(private router: Router,
    private toast: ToastrService,) { }

  ngOnInit(): void {
  }

  async goPage(item: IItemMenu) {
    if (!item.page) {
      this.msgEmDev()
      return
    }
    this.router.navigate([item.page])
  }

  msgEmDev() {
    this.toast.info('Em desenvolvimento.', 'Em breve traremos novidades.')
  }
}
