<div *ngIf="isLoading" class="col-10 offset-1">
  <span class="empty">
    Buscando perguntas...
  </span>
</div>

<section *ngIf="!isLoading">
  <div>

    <div class="col-10 offset-1 py-3">
      <div style="text-align: center; margin-bottom: 5px">
        <h3 style="font-size: 22px; font-weight: 600;">
          Responda o questionário e receba uma trilha de conteúdos sugeridos
        </h3>
      </div>
    </div>

    <div class="col-12 border__dashed"></div>

    <article *ngFor="let q of questions" (click)="navigateTo(q)" class="col-10 offset-1 container-question">
      <img style="height: 20px; margin-right: 10px; zoom: 1.8" src="../../../assets/icons/next.svg">
      <p *ngIf="q.tipo != 'DESCRITIVA'" style="white-space: pre-line">{{q.pergunta}}</p>
      <p *ngIf="q.tipo == 'DESCRITIVA'" style="white-space: pre-line">Tarefa prática</p>
    </article>

    <div *ngIf="!isLoading && !questions?.length" style="text-align: center; margin: 15px; margin-top: 30px">
      <p class="empty">Não existem perguntas cadastradas para essa habilidade</p>
    </div>

  </div>
</section>
