import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss']
})
export class LockScreenComponent implements OnInit {

  @Input('canclose') canClose: boolean

  constructor() { }

  ngOnInit(): void {
  }

  refresh() {
    window.location.reload()
  }
}
