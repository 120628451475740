import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { IInvalid } from '../tb-dados/tb-dados.component';

interface IDado {
  banco: string
  agencia: string
  conta: string
}
interface IBanco {
  id_banco: number
  codigo: string
  banco: string
  ativo: string
}

@Component({
  selector: 'app-tb-palestrante-dados-fiscais',
  templateUrl: './tb-dados-fiscais.component.html',
  styleUrls: ['./tb-dados-fiscais.component.scss']
})
export class TbDadosFiscaisComponent implements OnInit {
  @Output("next") next = new EventEmitter();

  loading: boolean = false

  usuario: IDado = { agencia: null, banco: null, conta: null }
  bancos: Array<IBanco>
  invalids: Array<IInvalid> = []

  constructor(
    private palestrantesService: PalestrantesService,
    private commonService: CommonService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getDados()
    this.getBancos()
  }

  async getDados() {
    const res = await this.palestrantesService.getDadosBancarios()
    this.usuario = res
  }

  async getBancos() {
    const res = await this.commonService.getBancos()
    this.bancos = res
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }

  async salvar() {
    this.invalids = []
    try {
      this.loading = true
      const res = await this.palestrantesService.updateDadosBancarios(this.usuario)
      this.loading = false
      this.toast.success("Salvo com sucesso!");
      this.next.emit({ next: true });

    } catch (error) {
      this.loading = false
      if (error.error.message.invalids) {
        this.invalids = error.error.message.invalids
        this.toast.error("Campos inválidos!", "Por favor, revise.")
      } else {
        this.toast.error("Não foi possível salvar os dados", "Falha na conexão")
      }
    }
  }

}
