<main class="container mt-5">
  <h1 class="ml-3">THE BEST SPEAKER BRAZIL - RANKING</h1>

  <div>
    <div class="filterContainer">
      <section>
        <label for="speaker">Palestrante (email)</label>
        <input type="text" name="speaker" [(ngModel)]="filters.email" />
      </section>
      <section>
        <label for="dateStart">Data Início</label>
        <input id="dateStart" name="dateStart" type="date" [(ngModel)]="filters.dateStart" />
      </section>
      <section>
        <label for="dateEnd">Data Fim</label>
        <input id="dateEnd" name="dateEnd" type="date" [(ngModel)]="filters.dateEnd" />
      </section>

      <section>
        <label for="category">Categoria</label>
        <select name="category" [(ngModel)]="filters.category">
          <option value="all">Todas</option>
          <option *ngFor="let category of categories" [value]="category">{{ category }}</option>
        </select>
      </section>
    </div>

    <div class="filterContainer">
      <button class="ml-4" type="button" (click)="exportToExcel()">Exportar dados</button>
      <button class="ml-3" type="button" (click)="getSpeakers()">Filtrar</button>
      <button class="ml-3" type="button" (click)="resetFilters()">Limpar filtros</button>
    </div>
  </div>

  <div *ngIf="!loading">
    <table class="customTable">
      <thead>
        <th>Ranking atual</th>
        <th>Nome do Palestrante</th>
        <th>Email do Palestrante</th>
        <th>Categoria</th>
        <th>Quantidade de votos (em banco)</th>
        <th>Data de publicação do vídeo</th>
        <th>Ações</th>
      </thead>

      <tbody>
        <tr *ngFor="let speaker of speakers | paginate: { itemsPerPage: 10, currentPage: currentPage }">
          <td>{{ getPosition(speaker) }}</td>
          <td>{{ speaker.nome }} {{ speaker.sobrenome }}</td>
          <td>{{ speaker.email }}</td>
          <td>{{ speaker.video_palestrantes[0]?.category || 'Não enviou vídeo' }}</td>
          <td>{{ getDbVotes(speaker) }}</td>
          <td>{{ getParsedDate(speaker) }}</td>
          <td>
            <button (click)="openUpdateRankingDialog(speaker)">Editar</button>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls (pageChange)="currentPage = $event"></pagination-controls>
  </div>
</main>
