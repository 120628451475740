import { Component, OnInit, ViewChild, forwardRef, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { Calendar, CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarioComponent implements OnInit {

  @Input("palestrasDay") palestrasDay: Array<any> = []
  @Output("selected") selected = new EventEmitter();

  calendarOptions: CalendarOptions;
  eventsModel: any;
  @ViewChild('fullcalendar')
  fullcalendar: FullCalendarComponent;

  selectedDay: any

  constructor() { }

  ngOnInit(): void {
    forwardRef(() => Calendar);

    let events = [];
    this.palestrasDay.forEach(i => {
      let datetime: Date = new Date(i.data)

      events.push({
        title: "",
        color: '#ffc800',
        start: datetime,
        end: datetime,
        date: datetime
      })
    })

    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      editable: true,
      dateClick: this.handleDateClick.bind(this),
      eventClick: this.handleEventClick.bind(this),
      locale: 'pt-br',
      events: events
    }
  }

  ngAfterContentChecked() {
    let ele = document.getElementsByClassName("fc-today-button")
    let i = ele[0]
    if (i) i.innerHTML = "HOJE"
  }

  handleDateClick(arg: any) {
    this.selectedDay = arg.date
    this.selected.emit(this.selectedDay)
  }

  handleEventClick(arg: any) {
    this.selectedDay = arg.event._instance.range.start
    this.selected.emit(this.selectedDay)
  }
}
