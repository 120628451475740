import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { IInvalid } from 'src/app/pages/configuracoes/palestrante/tb-dados';
import { MeusConteudosService } from 'src/app/services/meus-conteudos';
import { ICompetenciaContratante } from '../competencias.component';

@Component({
  selector: 'app-form-competencia',
  templateUrl: './form-competencia.component.html',
  styleUrls: ['./form-competencia.component.scss', '../../common-style.scss']
})
export class FormCompetenciaComponent implements OnInit {

  id: number
  competencia: ICompetenciaContratante = { id: null, descricao: "", resumo: "", id_contratante: null, ativo: "SIM" }
  insertDefault: ICompetenciaContratante
  invalids: Array<IInvalid> = []
  loading: boolean = false

  constructor(
    public bsModalRef: BsModalRef,
    private toast: ToastrService,
    private meusConteudosService: MeusConteudosService,
  ) { }

  ngOnInit(): void {
    let usuario = JSON.parse(localStorage.getItem("usuario"))
    if (this.id > 0) this.getCompetencia()
    else this.competencia.id_contratante = usuario.id_contratante
  }

  async getCompetencia() {
    try {
      this.competencia = await this.meusConteudosService.getCompetenciaById(this.id)
    } catch (error) {
      this.toast.error("Não foi possível buscar os dados", "Falha na conexão")
    }
  }

  async submit() {
    try {
      this.invalids = []
      this.loading = true
      const res = await this.meusConteudosService.insertUpdateCompetencia(this.competencia)
      this.loading = true
      this.toast.success("Salvo com sucesso")
      this.bsModalRef.hide()
    } catch (e) {
      this.loading = false
      if (e?.error?.message.invalids) {
        this.invalids = e.error.message.invalids
        this.toast.error("Campos inválidos!", "Por favor, revise.")
      } else {
        this.toast.error("Não foi possível salvar os dados", "Falha na conexão")
      }
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }
}
