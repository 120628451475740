import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { GrupoService } from 'src/app/services/grupo';
import { ILider } from '../../grupo-lideres';

@Component({
  selector: 'app-notificacao-modal-lideres',
  templateUrl: './notificacao-modal-lideres.component.html',
  styleUrls: ['./notificacao-modal-lideres.component.scss']
})
export class NotificacaoModalLideresComponent implements OnInit {

  id_grupo: number;

  lideres: Array<ILider>;

  loading: boolean = false;
  qtdePages: Array<number> = [];
  page: number = 0;
  search: string = "";

  list: Array<number> = []

  sendAll: boolean = false

  constructor(
    public bsModalRef: BsModalRef,
    private grupoService: GrupoService,
    private toast: ToastrService,) { }

  ngOnInit(): void {
    this.load();
  }

  close() {
    this.bsModalRef.hide();
  }

  async load() {
    try {
      const result = await this.grupoService.getGrupoLideres(this.id_grupo, this.page, this.search);

      this.lideres = result.lideres ? result.lideres : [];
      this.lideres.map((i: any) => i.added = this.valueAdded(i.id_colaborador))

      this.setQtdePages(result.quantidade)
    } catch (error) {
      this.toast.error("Não foi possível buscar os tutores")
    }
  }

  add(id: number) {
    if (!this.list.includes(id)) this.list.push(id)
  }

  async save() {
    try {
      this.loading = true

      this.bsModalRef.hide();
      this.loading = false
      this.close()
    } catch (error) {
      this.loading = false
    }
  }


  valueAdded(id_colaborador: number): boolean {
    let isInclude = this.list.find(i => i == id_colaborador)
    return isInclude ? true : false
  }

  changePage(number: number) {
    this.page = number;
    this.load()
  }

  setQtdePages(qtdelideres: number) {
    this.qtdePages = Array.from(Array(qtdelideres).keys())
  }

  getPages() {
    let listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2))
    return listPage
  }

  buscar() {
    this.page = 0
    this.load()
  }
}
