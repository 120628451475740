import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IInvalid } from 'src/app/pages/configuracoes/palestrante/tb-dados';
import { MeusConteudosService } from 'src/app/services/meus-conteudos';
import { IPergunta } from '../..';

@Component({
  selector: 'app-form-pergunta',
  templateUrl: './form-pergunta.component.html',
  styleUrls: ['./form-pergunta.component.scss', '../../../common-style.scss']
})
export class FormPerguntaComponent implements OnInit {

  @Output("finished") finished = new EventEmitter();
  @Input() pergunta: IPergunta
  @Input() id: number
  @Input() opcoes: Array<any> = []
  @Input() idHabilidade: number

  loading: boolean = false
  isOwner: boolean = false
  invalids: Array<IInvalid> = []

  tipos: Array<any> = [
    { text: "Relativa ", value: "RELATIVA" },
    { text: "Objetiva", value: "OBJETIVA" },
    { text: "Descritiva", value: "DESCRITIVA" },]
  models: Array<any> = []
  idContratante: number

  pesos: Array<number> = []
  arrayPesos: any = {
    "DESCRITIVA": null,
    "OBJETIVA": [0, 100],
    "RELATIVA": [50, 75, 100]
  }

  perguntaExample: IPergunta = {
    id: null,
    pergunta: "",
    tipo: "DESCRITIVA",
    ativa: 1,
    id_contratante: null,
    id_palestrante: null,
    opcoes: []
  }

  constructor(
    private toast: ToastrService,
    private meusConteudosService: MeusConteudosService,) { }

  async ngOnInit() {
    let usuario = JSON.parse(localStorage.getItem("usuario"))
    this.idContratante = usuario.id_contratante
    await this.meusConteudosService.getModelsOpcoes()
      .then(res => { this.models = res })
      .catch(e => { })

  }

  async getById(id: number) {
    try {
      this.invalids = []
      this.id = id
      if (!(this.id > 0)) {
        this.pergunta = this.perguntaExample
        this.pergunta.id_contratante = this.idContratante
        this.opcoes = this.models[this.tipos[2].value]
        this.isOwner = true
        return
      }
      const res = await this.meusConteudosService.getPerguntaById(id)
      this.pergunta = res
      if (this.idContratante == this.pergunta.id_contratante ||
        (this.meusConteudosService.idContratantePSA == this.idContratante && !this.pergunta.id_contratante)) {
        this.isOwner = true
      }
    } catch (error) {
      this.toast.error("Não foi possível carregar a pergunta")
    }
  }

  setOpcoes() {
    switch (this.pergunta.tipo) {
      case this.tipos[0].value:
        this.opcoes = this.models[this.tipos[0].value]
        break;

      case this.tipos[1].value:
        this.opcoes = this.models[this.tipos[1].value]
        break;

      case this.tipos[2].value:
        this.opcoes = this.models[this.tipos[2].value]
        break;

      default:
        break;
    }
    this.getPesos()
  }

  getPesos() {
    switch (this.pergunta.tipo) {
      case this.tipos[0].value:
        this.pesos = this.arrayPesos[this.tipos[0].value]
        break;

      case this.tipos[1].value:
        this.pesos = this.arrayPesos[this.tipos[1].value]
        break;

      case this.tipos[2].value:
        this.pesos = this.arrayPesos[this.tipos[2].value]
        break;

      default:
        break;
    }
    return this.pesos
  }


  async submit() {
    try {
      this.loading = false
      this.pergunta.opcoes = this.opcoes
      let res = await this.meusConteudosService.insertUpdatePergunta(this.idHabilidade, this.pergunta)
      this.loading = false
      this.toast.success("Salvo com sucesso!")
      this.pergunta = null
      this.finished.emit({ reload: true })
    } catch (e) {
      this.loading = false
      if (e?.error?.message.invalids) {
        this.invalids = e.error.message.invalids
        this.toast.error("Campos inválidos!", "Por favor, revise.")
      } else {
        this.toast.error("Não foi possível salvar os dados", "Falha na conexão")
      }
    }
  }

  isIncorrect(value: string, index: number | null) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value && (!index || (index == item['index']))) return true
    }
    return false
  }

  getError(value: string, index: number | null) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value && (!index || (index == item['index']))) return item['erro']
    }
    return ""
  }

  cancel() {
    this.pergunta = null
    this.id = null
    this.finished.emit({ reload: false })
  }

  getDisbled() {
    return (!this.isOwner || this.pergunta.tipo == 'DESCRITIVA')
  }
}
