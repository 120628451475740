<main class="container mt-5">
  <h1 class="ml-3">THE BEST SPEAKERS BRAZIL - VIDEOS</h1>

  <div>
    <div class="filterContainer">
      <section>
        <label for="status">Filtrar por status</label>
        <select name="status" [(ngModel)]="filterStatus" (change)="getVideos()">
          <option value="aprovado">APROVADO</option>
          <option value="negado">NEGADO</option>
          <option value="pendente">PENDENTE</option>
          <option value="falha">FALHA</option>
        </select>
      </section>
      <section>
        <label for="status">Filtrar por categoria</label>
        <select name="status" [(ngModel)]="categoryFilter" (change)="getVideos()">
          <option value="todas">TODAS</option>
          <option [value]="category" *ngFor="let category of categories">{{ category }}</option>
        </select>
      </section>
    </div>
  </div>

  <div class="video__card container m-2" *ngFor="let video of videos" (click)="openVideoDetails(video)">
    <div class="card flex-row w-100">
      <section class="left col-3">
        <img *ngIf="video.photo" [src]="video.photo" alt="TBSB" class="img-fluid">
        <img *ngIf="!video.photo" src="../../../../../assets/img/placeholder.jpg" alt="TBSB" class="img-fluid">

        <div class="status__container">
          <p>{{ video.status }}</p>
        </div>
      </section>

      <section class="right col-8">
        <p>{{ video.usuario.nome }} {{ video.usuario.sobrenome }} | {{ video.title }}</p>
        <p>{{ video.description }}</p>
      </section>
    </div>
  </div>
  <mat-paginator
    [length]="length"
    [pageSize]="size"
    (page)="changePage($event)">
  </mat-paginator>
</main>
