import { Routes } from '@angular/router';

import { HabilidadesComponent } from './habilidades';
import { CompetenciasComponent } from './competencias';
import { HabilidadeComponent } from './habilidade';
import { TipoConteudoComponent } from './tipo-conteudo';

export const MeusConteudosRoutes: Routes = [
  {
    path: 'conteudos/tipo',
    component: TipoConteudoComponent
  },
  {
    path: 'conteudos/competencias',
    component: CompetenciasComponent
  },
  {
    path: 'conteudos/habilidades/:idCompetencia',
    component: HabilidadesComponent
  },
  {
    path: 'conteudos/habilidade/:idCompetencia/:idHabilidade',
    component: HabilidadeComponent
  },
];
