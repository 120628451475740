<div class="container__dados">

  <form class="row col-12" (submit)="salvar()">

    <div class="col-12 col-lg-3 d-flex flex-column container__foto">
      <div>
        <app-foto-perfil [foto]="usuario?.photo"></app-foto-perfil>
      </div>

      <div class="form-group">
        <label class="label-input" for="id">seu código para indicação</label>
        <input class="form-control" name="id" type="text" [value]="usuario.id" disabled>
      </div>

      <div class="form-group">
        <label class="label-input" for="pais">quem te indicou</label>
        <input class="form-control" name="pais" type="text"
          [value]="usuario.codigo_indicacao ? usuario.perfil_indicacao + ' - ' + usuario.nome_completo_indicacao : ''"
          disabled>
      </div>

      <div style="flex: 1">
        <app-concorda-termos></app-concorda-termos>
      </div>
    </div>

    <div class="col-12 col-lg-9 d-flex flex-column">
      <div class="row">
        <div class="form-group col-12">
          <label class="label-input" for="nome_fantasia">Empresa</label>
          <input
            id="nome_fantasia"
            class="form-control"
            name="nome_fantasia"
            type="text"
            [(ngModel)]="usuario.nome_fantasia"
            placeholder="Empresa"
          >
          <span class="error">{{getError('nome_fantasia')}}</span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-lg-5">
          <label class="label-input" for="nome">nome *</label>
          <input id="nome" [class.invalid]="isIncorrect('nome')" class="form-control" name="nome" type="text"
            [(ngModel)]="usuario.nome" placeholder="Nome *">
          <span class="error">{{getError('nome')}}</span>
        </div>

        <div class="form-group col-12 col-lg-7">
          <label id="sobrenome" class="label-input" for="sobrenome">sobrenome *</label>
          <input [class.invalid]="isIncorrect('sobrenome')" class="form-control" name="sobrenome" type="text"
            [(ngModel)]="usuario.sobrenome" placeholder="Sobrenome">
          <span class="error">{{getError('sobrenome')}}</span>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-lg-6">
          <label class="label-input" for="cpf">cpf *</label>
          <input id="cpf" [class.invalid]="isIncorrect('cpf')" class="form-control" name="cpf" type="text"
            [(ngModel)]="usuario.cpf" mask="000.000.000-00" placeholder="CPF">
          <span class="error">{{getError('cpf')}}</span>
        </div>

        <div class="form-group col-12 col-lg-6">
          <label class="label-input" for="email">e-mail *</label>
          <input class="form-control" name="email" type="text" [(ngModel)]="usuario.email" placeholder="E-mail *"
            disabled>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="data_nasc">Data de Nascimento *</label>
          <input id="data_nasc" [class.invalid]="isIncorrect('data_nasc')" class="form-control" name="data_nasc"
            type="date" [(ngModel)]="usuario.data_nasc" placeholder="Data de Nascimento">
          <span class="error">{{getError('data_nasc')}}</span>
        </div>

        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="telefone">telefone *</label>
          <input id="telefone" [class.invalid]="isIncorrect('telefone')" class="form-control" name="telefone"
            type="text" [(ngModel)]="usuario.telefone" placeholder="Telefone *">
          <span class="error">{{getError('telefone')}}</span>
        </div>

        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="whatsapp">whatsapp</label>
          <input [class.invalid]="isIncorrect('whatsapp')" class="form-control" name="whatsapp" type="text"
            [(ngModel)]="usuario.whatsapp" placeholder="whatsapp">
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-lg-6">
          <label class="label-input" for="nome_mae">Nome da mãe *</label>
          <input id="nome_mae" [class.invalid]="isIncorrect('nome_mae')" class="form-control" name="nome_mae"
            type="text" [(ngModel)]="usuario.nome_mae" placeholder="Nome da mãe *">
          <span class="error">{{getError('nome_mae')}}</span>
        </div>

        <div class="form-group col-12 col-lg-6">
          <label class="label-input" for="nome_pai">Nome do pai</label>
          <input class="form-control" name="nome_pai" type="text" [(ngModel)]="usuario.nome_pai"
            placeholder="Nome da pai">
        </div>
      </div>

      <h5 class="font-title mt-3">Endereço</h5>

      <div class="row">
        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="pais">país *</label>
          <input id="pais" [class.invalid]="isIncorrect('pais')" class="form-control" name="pais" type="text"
            [(ngModel)]="usuario.pais" placeholder="País *">
          <span class="error">{{getError('pais')}}</span>
        </div>

        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="cep">cep *</label>
          <input id="cep" [class.invalid]="isIncorrect('cep')" (change)="completeCEP()" class="form-control" name="cep"
            type="text" [(ngModel)]="usuario.cep" placeholder="CEP *" mask="00000-000">
          <span class="error">{{getError('cep')}}</span>
        </div>

        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="estado">estado *</label>
          <select id="estado" [class.invalid]="isIncorrect('estado')" class="form-control" name="estado"
            placeholder="Estado *" [(ngModel)]="usuario.estado">
            <option value="">Selecione *</option>
            <option *ngFor="let estado of arrayEstados" [value]="estado.uf">{{estado.estado}}</option>
          </select>
          <span class="error">{{getError('estado')}}</span>
        </div>

      </div>

      <div class="row">
        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="estado">cidade *</label>
          <input id="cidade" [class.invalid]="isIncorrect('cidade')" class="form-control" name="cidade" type="text"
            [(ngModel)]="usuario.cidade" placeholder="Cidade *">
          <span class="error">{{getError('cidade')}}</span>
        </div>

        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="bairro">bairro *</label>
          <input id="bairro" [class.invalid]="isIncorrect('bairro')" class="form-control" name="bairro" type="text"
            [(ngModel)]="usuario.bairro" placeholder="Bairro *">
          <span class="error">{{getError('bairro')}}</span>
        </div>

        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="complemento">complemento</label>
          <input class="form-control" name="complemento" type="text" [(ngModel)]="usuario.complemento"
            placeholder="Complemento">
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-lg-8">
          <label class="label-input" for="logradouro">logradouro *</label>
          <input id="logradouro" [class.invalid]="isIncorrect('logradouro')" class="form-control" name="logradouro"
            type="text" [(ngModel)]="usuario.logradouro" placeholder="Logradouro *">
          <span class="error">{{getError('logradouro')}}</span>
        </div>

        <div class="form-group col-12 col-lg-4">
          <label class="label-input" for="numero">número *</label>
          <input id="numero" [class.invalid]="isIncorrect('numero')" class="form-control" name="numero" type="text"
            [(ngModel)]="usuario.numero" placeholder="Número *">
          <span class="error">{{getError('numero')}}</span>
        </div>
      </div>

      <!-- <h5 class="font-title mt-3">Redes Sociais</h5>
            <div class="row">

                <div class="form-group col-12 col-lg-6">
                    <label class="label-input" for="linkedin">linkedin</label>
                    <input class="form-control" name="linkedin" type="text" [(ngModel)]="usuario.linkedin"
                        placeholder="Link Linkedin">
                </div>

                <div class="form-group col-12 col-lg-6">
                    <label class="label-input" for="instagram">instagram</label>
                    <input class="form-control" name="instagram" type="text" [(ngModel)]="usuario.instagram"
                        placeholder="Link Instagram">
                </div>

                <div class="form-group col-12 col-lg-6">
                    <label class="label-input" for="facebook">facebook</label>
                    <input class="form-control" name="facebook" type="text" [(ngModel)]="usuario.facebook"
                        placeholder="Link Facebook">
                </div>
            </div> -->

      <!-- <h5 class="font-title mt-3">Palestrante</h5>

            <div class="row">

                <div class="form-group col-6 col-lg-4">
                    <label class="label-input label__radio" for="palestrante">palestrante</label>
                    <div class="d-flex">
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="palestrante_ativo_s" name="palestrante" class="custom-control-input"
                                value="SIM" [(ngModel)]="palestrante.palestrante">
                            <label class="custom-control-label radio_text" for="palestrante_ativo_s">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="palestrante_ativo_n" name="palestrante" class="custom-control-input"
                                value="NAO" [(ngModel)]="palestrante.palestrante">
                            <label class="custom-control-label radio_text" for="palestrante_ativo_n">Não</label>
                        </div>
                    </div>
                </div>

                <div class="form-group col-6 col-lg-4">
                    <label class="label-input label__radio" for="mentor">Mentor</label>
                    <div class="d-flex">
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="mentor_ativo_s" name="mentor" class="custom-control-input"
                                value="SIM" [(ngModel)]="palestrante.mentor">
                            <label class="custom-control-label radio_text" for="mentor_ativo_s">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="mentor_ativo_n" name="mentor" class="custom-control-input"
                                value="NAO" [(ngModel)]="palestrante.mentor">
                            <label class="custom-control-label radio_text" for="mentor_ativo_n">Não</label>
                        </div>
                    </div>
                </div>

                <div class="form-group col-6 col-lg-4">
                    <label class="label-input label__radio" for="consultor">consultor</label>
                    <div class="d-flex">
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="consultor_ativo_s" name="consultor" class="custom-control-input"
                                value="SIM" [(ngModel)]="palestrante.consultor">
                            <label class="custom-control-label radio_text" for="consultor_ativo_s">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="consultor_ativo_n" name="consultor" class="custom-control-input"
                                value="NAO" [(ngModel)]="palestrante.consultor">
                            <label class="custom-control-label radio_text" for="consultor_ativo_n">Não</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-6 col-lg-4">
                    <label class="label-input label__radio" for="influenciador">influenciador</label>
                    <div class="d-flex">
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="influenciador_ativo_s" name="influenciador"
                                class="custom-control-input" value="SIM" [(ngModel)]="palestrante.influenciador">
                            <label class="custom-control-label radio_text" for="influenciador_ativo_s">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="influenciador_ativo_n" name="influenciador"
                                class="custom-control-input" value="NAO" [(ngModel)]="palestrante.influenciador">
                            <label class="custom-control-label radio_text" for="influenciador_ativo_n">Não</label>
                        </div>
                    </div>
                </div>

                <div class="form-group col-6 col-lg-4">
                    <label class="label-input label__radio" for="trainer">trainer</label>
                    <div class="d-flex">
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="trainer_ativo_s" name="trainer" class="custom-control-input"
                                value="SIM" [(ngModel)]="palestrante.trainer">
                            <label class="custom-control-label radio_text" for="trainer_ativo_s">Sim</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="trainer_ativo_n" name="trainer" class="custom-control-input"
                                value="NAO" [(ngModel)]="palestrante.trainer">
                            <label class="custom-control-label radio_text" for="trainer_ativo_n">Não</label>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="form-group col-12 col-lg-8">
                    <label class="label-input" for="titulo">titulo *</label>
                    <input id="titulo" [class.invalid]="isIncorrect('titulo')" class="form-control" name="titulo"
                        type="text" [(ngModel)]="palestrante.titulo" placeholder="Título">
                    <span class="error">{{getError('titulo')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="preco">Cachê *</label>
                    <input id="preco" [class.invalid]="isIncorrect('preco')" class="form-control" name="preco"
                        type="text" [(ngModel)]="palestrante.preco" placeholder="Cachê * R$" mask="separator.2"
                        thousandSeparator=".">
                    <span class="error">{{getError('preco')}}</span>
                </div>
            </div>


            <div class="row">
                <div class="form-group col-12">
                    <label class="label-input" for="minibio">Mini Biografia *</label>
                    <textarea id="minibio" [class.invalid]="isIncorrect('minibio')" class="form-control" type="text"
                        name="minibio" maxlength="1500" [(ngModel)]="palestrante.minibio"
                        placeholder="Descreva sua história"></textarea>
                    <span class="error">{{getError('minibio')}}</span>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="video">Link do Vídeo (Youtube)
                    </label>
                    <input class="form-control" name="video" type="text" [(ngModel)]="palestrante.video"
                        placeholder="Link do Vídeo">
                </div>
            </div> -->

      <div class="row">
        <div class="d-flex div__btn__salvar">
          <button *ngIf="!loading" type="submit" class="btn__primary">Salvar</button>

          <button *ngIf="loading" class="btn__primary mt-2">
            <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
          </button>
        </div>
      </div>

    </div>

  </form>
</div>
