<main *ngIf="!showConfirmation">
  <h1>Gerar formulário para emissão de certificado</h1>

  <div>
    <label for="speakerName">Palestrante</label>
    <select name="speakerName" [(ngModel)]="selectedSpeaker" (change)="selectSpeaker()">
      <option *ngFor="let speaker of speakers" [value]="speaker.id">{{ speaker.nomeCompleto }}</option>
    </select>

    <label for="speakerEmail">Email Palestrante</label>
    <input id="speakerEmail" name="speakerEmail" placeholder="Email do palestrante" [(ngModel)]="newForm.speakerEmail" />

    <label for="clientEmail">Email Cliente</label>
    <input id="clientEmail" name="clientEmail" placeholder="Email do cliente" [(ngModel)]="newForm.emailClient" />

    <label for="talkName">Título da palestra</label>
    <input id="talkName" name="talkName" placeholder="Nome da palestra" [(ngModel)]="newForm.talkTitle" />

    <label for="eventName">Evento</label>
    <input id="eventName" name="eventName" placeholder="Nome do evento" [(ngModel)]="newForm.eventName" />

    <label for="emailCS">Email CS Responsável</label>
    <input id="emailCS" name="emailCS" placeholder="Email do CS responsável" [(ngModel)]="newForm.emailCS" />

    <label for="eventDate">Data do evento</label>
    <input type="date" id="eventDate" name="eventDate" placeholder="Data do evento..." [(ngModel)]="newForm.eventDate" />

    <label for="businessName">Nome do negócio</label>
    <input id="businessName" name="businessName" placeholder="Nome do negócio..." [(ngModel)]="newForm.businessName" required />

    <label for="qtdParticipantes">Quantidade de Participantes</label>
    <input id="qtdParticipantes" type="number" name="qtdParticipantes" [(ngModel)]="newForm.qtdParticipantes" placeholder="Informe a quantidade esperada de participantes do evento" required />

    <label for="language">Selecione o idioma do evento</label>
    <select name="language" [(ngModel)]="newForm.language">
      <option *ngFor="let language of languages" [value]="language.value">{{ language.label }}</option>
    </select>

    <label *ngIf="useClientLogo || newForm.noPSALogo" for="file">Upload logo</label>
    <input
      *ngIf="useClientLogo || newForm.noPSALogo"
      id="file" class="my-2"
      type="file" name="file"
      accept="image/*"
      (change)="onFileChange($event)"
    />

    <div class="container row">
      <div class="col">
        <input name="noAvaliation" type="checkbox" [(ngModel)]="newForm.noAvaliation" />
        <label for="noAvaliation">Gerar formulário sem campos de avaliação?</label>
      </div>

      <div class="col">
        <input name="noPSALogo" type="checkbox" [(ngModel)]="newForm.noPSALogo" />
        <label for="noPSALogo">Gerar qrCode sem a logo da PSA e com o logo do Cliente?</label>
      </div>
    </div>

    <div class="container row">
      <div class="col">
        <input name="noAvaliation" type="checkbox" [(ngModel)]="useClientLogo" />
        <label for="noAvaliation">Gerar certificado com logo do cliente?</label>
      </div>

      <div class="col">
        <input name="useTitle" type="checkbox" [(ngModel)]="newForm.useTitle" />
        <label for="useTitle">Deseja mostrar o título da palestra no certificado?</label>
      </div>
    </div>

    <div class="container row">
      <div class="col">
        <input name="noCertificate" type="checkbox" [(ngModel)]="newForm.noCertificate" />
        <label for="noCertificate">Não gerar certificado.</label>
      </div>

      <div class="col">
        <input name="noPhone" type="checkbox" [(ngModel)]="newForm.noPhone" />
        <label for="noPhone">Remover campo "Telefone" do formulário?</label>
      </div>
    </div>
  </div>

    <div class="container row">
      <div class="col">
        <input name="noEmail" type="checkbox" [(ngModel)]="newForm.noEmail" />
        <label for="noEmail">Remover campo "Email" do formulário?</label>
      </div>
  </div>

  <button class="mt-5" (click)="createForm()">Gerar Formulário</button>
</main>

<main class="confirmation__message__container" *ngIf="showConfirmation">
  <div class="confirmation__message">
    <h1>Tudo Certo!</h1>
    <p>Em breve você receberá por email o QRCode com o link do formulário.</p>
  </div>

  <button (click)="this.showConfirmation = false">Gerar novo formulário</button>
</main>
