import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GrupoService } from 'src/app/services/grupo';

interface IGrupoUsuario {
  descricao: string
  id: number
  id_contratantes_colaborador: number
  id_espectador_lider: number
  id_grupo: number
  nome: string
}
@Component({
  selector: 'app-list-grupos-lider',
  templateUrl: './list-grupos-lider.component.html',
  styleUrls: ['./list-grupos-lider.component.scss']
})
export class ListGruposLiderComponent implements OnInit {
  @Output("selected") selected = new EventEmitter();
  @Output("back") backAction = new EventEmitter();

  gruposUsuarios: Array<IGrupoUsuario> = []
  @Input() lider;

  grupoSelected: number = null

  constructor(
    private grupoService: GrupoService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getGruposByLider()
  }

  async getGruposByLider() {
    try {
      this.grupoService.getGruposByLider(this.lider.id_colaborador)
        .then((res: Array<IGrupoUsuario>) => {
          this.gruposUsuarios = res
          if (this.gruposUsuarios.length == 1) {
            this.selected.emit({ grupoUsuario: this.gruposUsuarios[0] })
            return;
          }
        })
    } catch (error) {
      this.toast.error("Não foi possível carregar os tutores")
    }
  }

  selectGrupo() {
    if (!this.grupoSelected) {
      this.toast.error("Selecione um grupo")
      return
    }
    this.selected.emit({ grupoUsuario: this.gruposUsuarios.filter((i: IGrupoUsuario) => i.id == this.grupoSelected)[0] })
  }

  back() {
    this.backAction.emit()
  }
}
