import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';


import { ListComponent } from './list';
import { PerfilComponent } from './perfil';
import { DepoimentosComponent } from './depoimentos';
import { ModalOrcarComponent } from 'src/app/common/modal-orcar';
import { OrcarButtomComponent } from './orcar-buttom';
import { ExportComponentsModule } from 'src/app/common/export-components';

@NgModule({
  declarations: [ListComponent, PerfilComponent, DepoimentosComponent, ModalOrcarComponent, OrcarButtomComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    CarouselModule,
    ExportComponentsModule
  ],
  exports: [
    DepoimentosComponent,
  ]
})
export class EspecialistasModule { }
