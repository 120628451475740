<main class="profissional">
  <section class="profissional__header">
    <div class="container">
      <div *ngIf="loading" class="d-flex align-items-center justify-content-center">
        <app-spinner></app-spinner>
      </div>
      <div *ngIf="!loading" class="row">
        <div class="col-md-4">
          <div class="profissional__header__hero">
            <img class="profissional__header__hero__img" [src]="especialista?.photo"
              [attr.alt]="'Profissional ' + especialista?.slug">
          </div>

          <button [class.d-none]="loadingDownload" (click)="sendPDFEmailPerfil()"
            class="btn__primary btn__download__profile mt-2" title="Receber perfil completo por e-mail">
            <img class="img__icon" src="assets/icons/email.svg" alt="Receber perfil completo por e-mail">
            receba o perfil por e-mail
          </button>
          <button [class.d-none]="!loadingDownload" class="btn__primary btn__download__profile mt-2"
            title="Receber perfil completo por e-mail">
            <img height="24px" src="assets/img/simple_loading.gif" alt="Receber perfil completo por e-mail">
          </button>
        </div>
        <div class="col-md-8">
          <div class="profissional__header__info">
            <h2 class="profissional__header__info__title">{{especialista?.nome_palestrante}}</h2>
            <h6 class="profissional__header__info__subtitle">{{especialista?.titulo}}</h6>
            <p class="profissional__header__info__bio">{{especialista?.minibio}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="profissional__temas" *ngIf="assuntos">
    <div class="container">
      <h2 *ngIf="!loading" class="profissional__temas__title">Temas Abordados por {{especialista?.nome_palestrante}}
      </h2>
      <div class="profissional__temas__pills d-md-flex flex-row">
        <span class="profissional__temas__pills__item d-inline-block"
          *ngFor="let assunto of assuntos">{{assunto.nome}}</span>
      </div>
    </div>
  </section>

  <section class="profissional__palestras" *ngIf="palestras?.length > 0">
    <div [ngStyle]="getStyle()" class="container palestrasOferecidas">
      <h2 class="profissional__palestras__title text-center mt-3">Palestras oferecidas de
        {{especialista?.nome_palestrante}}</h2>
      <owl-carousel-o [options]="carouselOptions">
        <ng-container *ngFor="let palestra of palestras">
          <ng-template carouselSlide>
            <a style="cursor: pointer;" href="/palestras/disponivel/{{palestra.slug}}">
              <article class="profissional__palestras__slide">
                <div class="profissional__palestras__slide__hero">
                  <img width="100%" height="100%"
                    [src]="palestra.photo || palestra.foto_tema || 'https://placehold.it/300'" alt="Palestra" />
                </div>
                <h3 class="profissional__palestras__slide__title">{{palestra.nome}}</h3>
                <p class="profissional__palestras__slide__text">{{palestra.descricao?.substring(0,160)}}</p>
              </article>
            </a>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </section>
  <section class="profissional__depoimentos" *ngIf="depoimentos?.length > 0">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <app-depoimentos [palestrante]="especialista?.nome_palestrante" [depoimentos]="depoimentos"></app-depoimentos>
        </div>
        <div *ngIf="especialista?.palestrante_video" class="col-12 col-md-6">
          <h2 style="margin: 20px 0; padding: 0 0 10px 0" class="profissional__temas__title">Vídeo de
            {{especialista?.nome_palestrante}}</h2>
          <iframe *ngIf="linkVideo" [src]="linkVideo" height="300" width="300" frameborder="0"></iframe>
        </div>
      </div>
    </div>
  </section>
</main>


<section class="profissional__relacionados text-center mb-5" *ngIf="relacionados?.length > 0">
  <div class="container">
    <h2 class="profissional__relacionados__title text-center">Especialistas Relacionados</h2>
    <owl-carousel-o [options]="carouselOptions">
      <ng-container *ngFor="let item_espec of relacionados">
        <ng-template carouselSlide>
          <a style="cursor: pointer;" (click)="goEspecialista(item_espec.slug)">
            <article class="profissional__relacionados__slide d-flex flex-column align-items-center text-center">
              <div class="profissional__relacionados__slide__hero">
                <img [src]="item_espec?.photo" [alt]="'Profissional relacionado ' + item_espec?.slug" />
              </div>
              <h3 class="profissional__relacionados__slide__title">{{item_espec?.nome_sobrenome}}</h3>
              <p class="profissional__relacionados__slide__text">{{getMinibio(item_espec?.minibio)}}</p>
            </article>
          </a>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
    <a [routerLink]="'/especialistas'" class="profissional__relacionados__link">Ver Todos os Especialistas</a>
  </div>
</section>
<app-orcar-buttom></app-orcar-buttom>