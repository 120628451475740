<div [class.d-none]="idSelected">
  <tabset>
    <tab heading="Próximos" [active]="tab == 'prox'">
      <div class="margin"></div>
      <app-tb-eventos [palestras]="palestras" [meses]="meses" (toUpdate)="updateMinistrada($event)">
      </app-tb-eventos>
    </tab>

    <tab heading="Anteriores" *ngIf="!all" [active]="tab == 'ant'">
      <div class="margin"></div>

      <app-tb-eventos [palestras]="palestrasAnteriores" [meses]="mesesAnteriores" (toUpdate)="updateMinistrada($event)">
      </app-tb-eventos>

      <div *ngIf="palestrasAnteriores.length > 0" class="content__more">
        <button (click)="loadMore()" [class.d-none]="(palestrasAnteriores.length / 5) < (page + 1)" class="btn">
          CARREGAR MAIS
        </button>
      </div>
    </tab>
  </tabset>
</div>

<div *ngIf="idSelected" [class.d-none]="!idSelected">
  <app-form-evento [idPalestra]="idSelected" (finished)="setMinistrada()"></app-form-evento>
</div>
