import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GrupoService } from 'src/app/services/grupo';
import { ContratanteService } from 'src/app/services/contratante';
import { AppComponent } from 'src/app/app.component';

interface IItemMenu {
  ordem: number,
  title: string,
  page: string,
  selected: boolean
  lastItem?: boolean
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {

  @Input() itemSelected: string;
  id_grupo: number;

  list: Array<IItemMenu> = [
    {
      ordem: 1,
      title: "Colaboradores",
      page: "universidade/colaboradores",
      selected: false
    },
    {
      ordem: 2,
      title: "Selecione seus tutores",
      page: "universidade/seleciona-lideres",
      selected: false
    },
    {
      ordem: 3,
      title: "Grupos",
      page: "universidade/grupos",
      selected: false
    },
    {
      ordem: 4,
      title: "Habilidades",
      page: "universidade/id_grupo/habilidades",
      selected: false
    },
    {
      ordem: 5,
      title: "Notificação",
      page: "universidade/notificacao",
      selected: false
    },
    {
      ordem: 6,
      title: "Relatórios",
      page: "universidade/relatorio/ranking",
      selected: false,
      lastItem: true
    },
  ]

  constructor(private router: Router,
    private app: AppComponent,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private grupoService: GrupoService,
    private contratanteService: ContratanteService,) { }

  ngOnInit(): void {
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    if (!usuario.universidade_corporativa || usuario.universidade_corporativa == 0) {
      if (!this.verificaAcesso()) return
    }
  }

  async verificaAcesso() {

    const usuario = await this.contratanteService.getUsuario()
    if (usuario.universidade_corporativa == 1) {
      this.app.refreshInfoGlobal()
      return true
    } else {
      this.router.navigate(['/universidade/home'], { queryParams: { locked: 1 } })
      return false
    }
  }

  async goPage(item: IItemMenu) {
    if (!item.page) {
      this.msgEmDev()
      return
    }
    if ([4].includes(item.ordem)) {
      let id_grupo = this.route.snapshot.paramMap.get('id')

      if (!id_grupo) {
        this.toast.error('Clique em Grupos e em editar o grupo desejado.', 'Grupo não informado.')
        this.router.navigate(['/universidade/grupos'])
        return
      }
      this.id_grupo = parseInt(id_grupo)

      const res = await this.canGoGrupo()
      if (res.status == false) {
        this.toast.error("Cadastre-os!", res.message)
        return
      }
    }

    if ([3].includes(item.ordem)) {
      const resCreate = await this.canGoGrupo()
      if (resCreate.hasGrupo == false) {
        this.toast.error("Cadastre-os ou importe sua planilha!", resCreate.message)
        return
      }

    }
    this.router.navigate([item.page.replace("id_grupo", this.id_grupo?.toString())])
  }

  msgEmDev() {
    this.toast.info('Em desenvolvimento.', 'Em breve traremos novidades.')
  }

  async canGoGrupo(): Promise<any> {
    try {
      return await this.grupoService.verificaColaboradores(this.id_grupo)
    } catch (error) {
      return false
    }
  }

  async canCreateGrupo(): Promise<any> {
    try {
      return await this.contratanteService.hasGrupo()
    } catch (error) {
      return false
    }
  }
}
