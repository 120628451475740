<div class="main container-fluid" *ngIf="!loading">
  <h1>Cadastrar palestrante Exclusivo</h1>

  <div>
    <label>Selecionar Palestrante</label>
    <input type="text" list="speakers" name="speakers" [(ngModel)]="exclusivo.speaker" />
    <datalist id="speakers">
      <select>
        <option *ngFor="let speaker of speakers" [value]="speaker.nomeCompleto"></option>
      </select>
    </datalist>

    <label>Plano de fundo personalizado</label>
    <input
      id="file"
      type="file"
      name="file"
      accept="image/*"
      (change)="onFileChange($event)"
    />

    <label>Texto do email de certificado</label>
    <textarea rows="7" [(ngModel)]="exclusivo.emailContent"></textarea>

    <label>Página de destino pós resposta do formulário (QRCode)</label>
    <input type="text" [(ngModel)]="exclusivo.redirectPage" />

    <span>Inserir links no formato: https://dominio.com</span>
  </div>

  <div>
    <button class="cadastrarPalestrante" (click)="create()">Finalizar</button>
  </div>
</div>

<div
  style="
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  "
  *ngIf="loading"
>
<img
  src="assets/img/psaLoading.svg"
  alt="Carregando"
  title="Carregando"
/>
</div>
