import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Toast, ToastrService } from 'ngx-toastr';
import { Videos } from 'src/app/interfaces/videos';
import { TbsbService } from 'src/app/services/tbsb/tbsb.service';
@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss']
})
export class VideoDetailsComponent implements OnInit {
  videoId: string;
  video: Videos;
  videoUrl: any;

  loading: boolean = false;

  showForm: boolean = false;
  reason: string = '';
  respId: string;

  status: string = '';

  youtubeUrl: string = '';

  isYoutubeUrl: boolean = false;
  constructor(
    private VideosService: TbsbService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private toast: ToastrService,
    private domSanitizer: DomSanitizer
  ) {
    this.loading = true;
    const user = JSON.parse(localStorage.getItem('usuario'));
    console.log(user);
    this.respId = user.id;
    this.videoId = this.activeRoute.snapshot.paramMap.get('id');

    if(!this.videoId) {
      this.toast.error('Vídeo não encontrado');
      this.router.navigate(['/admin/tbsb']);
      this.loading = false;
    } else {
      this.getVideo();
    }
  }

  ngOnInit(): void {
  }

  getVideo() {
    console.log(this.videoId)
    this.VideosService.getVideoById(+this.videoId).subscribe(
      (res: any) => {
      console.log(res);
      this.video = res;
      this.videoUrl = this.getVideoUrl(this.video.url);
      this.loading = false;
      this.status = this.video.status;

      var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      this.isYoutubeUrl = this.video.url.match(p) ? true : false;

      if(this.isYoutubeUrl) {
        console.log(this.videoUrl)
        this.videoUrl = this.getVideoUrl(this.video.url.replace("watch?v=", "embed/"))
        console.log(this.videoUrl)
      }
      },
      (data: any) => {
        this.toast.error('Vídeo não encontrado');
        this.router.navigate(['/admin/tbsb']);
        this.loading = false;
      }
    );
  }

  getVideoUrl(url: string) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  approveVideo() {
    console.log(this.video.status)
    if(this.video.status === 'aprovado' || this.video.status === 'falha') {
      this.toast.error('Vídeo não está apto à aprovação ou recusa.');
    } else {
      const user = JSON.parse(localStorage.getItem('user'));
      const body = {
        action: 'approved',
        reason: this.reason,
        respId: this.respId,
      };

      this.VideosService.approveOrDenieVideo(+this.videoId, body).subscribe(
        (result: any) => {
          this.toast.success('Vídeo aprovado com sucesso');
          this.getVideo();
        },
        (data: any) => {
          console.log(data);
          this.toast.error('Erro ao aprovar o vídeo');
        }
      );
    }
  }

  denieVideo() {
    console.log(this.video.status)
    console.log(this.reason)
    if(this.video.status === 'recusado' || this.video.status === 'falha') {
      this.toast.error('Vídeo não está apto à aprovação ou recusa.');
    } else if(!this.reason) {
      this.toast.error('Por favor, informe o motivo da recusa');
    } else {
      const body = {
        action: 'denied',
        reason: this.reason,
        respId: this.respId,
      };
      this.VideosService.approveOrDenieVideo(+this.videoId, body).subscribe(
        (result: any) => {
          this.toast.success('Vídeo recusado com sucesso');
          this.getVideo();
          this.showForm = false;
        },
        (data: any) => {
          console.log(data);
          this.toast.error('Erro ao recusar o vídeo');
        }
      )
    }

  }

  updateYoutubeUrl() {
    const body = {
      url: this.youtubeUrl,
      oldUrl: this.video.url,
    };

    this.VideosService.updateYoutubeUrl(+this.videoId, body).subscribe(
      (result: any) => {
        this.toast.success('Url atualizada com sucesso');
        this.getVideo();
      },
      (data: any) => {
        this.toast.error('Erro ao atualizar a url');
      }
    );
  }
}
