<main>
  <div class="container">
    <img src="../../../assets/img/logo-black.svg" />
    <h1 *ngIf="!loading && !showError">Indicação confirmada com sucesso!</h1>

    <div *ngIf="showError">
      <h1>Parece que algo deu errado ao confirmar sua indicação, clique no botão abaixo para tentar novamente!</h1>

      <button (click)="sendConfirmation()">Reenviar confirmação</button>
    </div>
  </div>
</main>


<div
  *ngIf="loading"
  style="
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  "
  >
  <img
    src="assets/img/psaLoading.svg"
    alt="Carregando"
    title="Carregando"
  />
</div>
