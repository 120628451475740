// VAI PRECISAR MUDAR O INPUT
export const promptDefesa = (profile: string, speaker: string, theme: string, briefing: string) => {
  return `
  ${profile}
  Com base no conteúdo do objeto acima, faça um texto, em formato de tópicos, de defesa em HTML do palestrante ${speaker} destacando 3 motivos fortes para contratar o palestrante a partir das chaves 'titulo', 'minibio' e 'reasons', relacionando isso ao tema: "${theme}" e ao briefing: "${briefing}". Finalize o texto utilizando um gatilho de persuasão e urgência para convencer o cliente.
  O texto deve ser dividido em 03 parágrafos com até 3 linhas por parágrafo. Cada parágrafo deve elencar os motivos mencionados acima.`;
}

// OK
export const promptCompararPalestrantes = (profiles: any, input: string): string => {
  return `
  ${profiles}
  Cada item do array acima é o perfil de um dos palestrantes: ${input}. Com base nessas informações faça um comparativo entre eles destacando 3 pontos fortes de cada palestrante, utilizando principalmente, as chaves 'titulo', 'temas' e 'reasons'.
   Divida o texto em 3 parágrafos, com até 6 linhas, e pontue em bold 3 principais palavras-chave de cada palestrante.
  `;
};

// OK
export const promptProfile = (profile: any, name: string): string => {
  return `
  ${profile}
  A partir das informações do objeto anterior faça um resumo do perfil de ${name}  em texto corrido contendo no máximo 600 caracteres. As informações devem se basear nas chaves 'minibio', 'temas', 'titulo' e 'reasons'. Caso as informações não sejam fornecidas, crie o resumo com base em conhecimentos públicos sobre ${name}
  `;
};


// OK
export const promptCompleteProfile = (profile: any, name: string): string => {
  return `
  ${profile}
   A partir das informações do objeto anterior faça um resumo do perfil de ${name}  em texto corrido contendo no máximo 1200 caracteres. As informações devem se basear nas chaves 'minibio', 'titulo', 'temas' e 'reasons'. Caso as informações não sejam fornecidas, crie o resumo com base em conhecimentos públicos sobre ${name}
  `;
};

// OK
export const promptThemeImportants = (input: string) => {
  return `Explique de forma clara o que é ${input}, como funciona e principais conceitos. Faça uma lista com os 5 principais pontos relevantes sobre o tema com uma breve explicação. Depois, cite um exemplo de como uma empresa pode usufruir ou aplicar isso no seu dia a dia. `;
};

// OK
export const promptCases = (input: string): string => {
  return `Cite 5 cases reais de empresas brasileiras que implementaram o tema ${input} através de palestras, programas de desenvolvimento, workshops ou imersões para os seus colaboradores. Explique com detalhes como cada uma delas aplicou o tema ${input} dentro da empresa`;
};
