<section class="psa-showcase-palestrantes" id="psa-showcase-palestrantes">
    <section class="container">
        <div class="psa-showcase-palestrantes-title">
            <h1>{{titulo}}</h1>
        </div>
        <owl-carousel-o [options]="profissionaisOptions">
            <ng-template carouselSlide *ngFor="let profissional of profissionaisInput">
                <div class="psa-showcase-palestrante">
                    <div class="image-carrosel psa-showcase-palestrante-cover"
                            [ngStyle]="{'background': 'url('+profissional.photo+') center center no-repeat' ,
                            'background-size': 'cover'}"
                            (click)="load(profissional.slug)"
                         >
                    </div>

                    <div class="psa-showcase-palestrante-info">
                        <a (click)="orcar(profissional.slug)" class="psa-showcase-palestrante-budget">
                            <img src="assets/icons/shopping-cart.svg" alt="orçar">
                            <span>Orçar</span>
                        </a>
                        <h1>{{profissional.nome_sobrenome}}</h1>
                        <p>{{getMinibio(profissional.minibio)}}</p>
                        <a (click)="load(profissional.slug)" class="psa-showcase-palestrante-profile">Ver Perfil</a>
                    </div>
                </div>
            </ng-template>
            <!-- End Component Palestrante -->
        </owl-carousel-o>
        <div class="psa-showcase-palestrantes-btn-container" *ngIf="!profissionaisInput">
            <a [routerLink]="'/especialistas'" class="psa-showcase-palestrantes-btn-show-all">Ver Todos os Especialistas</a>
        </div>
    </section>
</section>
