<main class="p__top">
    <div class="container">
        <div *ngIf="isLoading" class="loading flexFull">
            <img src="assets/img/simple_loading.gif" alt="loading">
        </div>
        <section class="sa-news__breadcrumb mt-5 mt-lg-2 mb-3 mt-lg-2">
            <div class="">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent align-items-baseline">
                        <li class="breadcrumb-item"><a href="/sa-news/home">SA News</a></li>
                        <li class="breadcrumb-item"><a [routerLink]="['/sa-news/categoria/' + artigo.id_conteudo_tipo]">{{getCategoriaNome()}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{artigo?.titulo}}</li>
                    </ol>
                </nav>
            </div>
        </section>

        <!-- <button (click)="back()" class="btn p__top bg-warning flexFull">
            <img src="../../../assets/icons/arrow-right-black.svg" alt="back">
        </button> -->

        <h1 *ngIf="!artigo && !isLoading" class="d-inline relacionados__title">Artigo não encontrado.</h1>

        <div class="row mb-5" *ngIf="isLoading">
            <div class="col text-center">
                <div class="spinner-border text-warning" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>


        <div *ngIf="artigo" class="row">

            <!-- CONTENT -->
            <div class="content col-12 col-lg-10 offset-lg-1">

                <div class="content__div__img mb-2">
                    <img *ngIf="artigo?.image_src.length > 0" src="{{artigo.image_src}}">
                    <img *ngIf="!(artigo?.image_src.length > 0)" src="http://placehold.it/400">
                </div>

                <h1 class="content__title">{{artigo?.formato == 'AUDIO' ? "Podcast - " : ''}}{{artigo?.titulo}}</h1>
                <video *ngIf="artigo?.formato == 'AUDIO'" width="100%" controls controlsList="nodownload">
                    <source [src]="artigo?.video_link" type="video/mp4">
                </video>
                <div class="content__info d-flex justify-content-between py-4">
                    <p *ngIf="artigo?.fonte" class="p-0 m-0">por <b>{{artigo?.fonte}}</b> {{"|" + artigo?.tipo}}</p>
                    <p *ngIf="!artigo?.fonte" class="p-0 m-0">{{artigo?.tipo}}</p>
                    <p class="p-0 m-0">{{artigo?.data}}</p>
                </div>
                <div class="content__artigo">
                    <app-inner-html [html]="artigo?.artigo"></app-inner-html>
                </div>

                <div class="content__control row mb-4">
                    <div class="d-block w-100 mt-3 ml-3">
                        <h3 class="content__subtitle">Compartilhe:</h3>
                    </div>

                    <div class="d-flex content__social__media ml-3">
                        <button (click)="share('linkedin')" class="button__share">
                            <img src="../../../assets/icons/linkedin-black.svg" alt="linkedin">
                        </button>
                        <button (click)="share('facebook')" class="button__share">
                            <img src="../../../assets/icons/facebook-black.svg" alt="facebook">
                        </button>
                        <button (click)="share('twitter')" class="button__share">
                            <img src="../../../assets/icons/twitter-black.svg" alt="twitter">
                        </button>
                        <button (click)="share('whatsapp')" class="button__share">
                            <img src="../../../assets/icons/whatsapp-black.svg" alt="whatsapp">
                        </button>
                        <button (click)="share('telegram')" class="button__share">
                            <img src="../../../assets/icons/telegram-black.svg" alt="telegram">
                        </button>
                    </div>

                    <div class="col-12 col-lg-10 offset-lg-1 d-flex">
                        <button (click)="goArtigo(anterior.slug, anterior.categoria)" class="content__control__back btn__article">
                            <img src="../../../assets/icons/arrow-right-black.svg" alt="back">
                            <span>ANTERIOR</span>
                        </button>
                        <button (click)="goArtigo(proximo.slug, anterior.categoria)" class="content__control__next btn__article">
                            <span>PRÓXIMA</span>
                            <img src="../../../assets/icons/arrow-right-black.svg" alt="next">
                        </button>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <!-- Relacionados -->
    <div class="background__gray" *ngIf="artigosRelacionados?.length > 0">
        <section class="py-5 container">
            <div class="mb-5 col-12">
                <div class="d-flex justify-content-between py-4">
                    <h1 class="d-inline relacionados__title">Relacionados</h1>
                    <button (click)="goTipo(artigo.id_conteudo_tipo)" class="btn__more">
                        <span>VER MAIS</span>
                        <i class="fa fa-plus"></i>
                    </button>
                </div>
                <owl-carousel-o [options]="options">
                    <ng-template carouselSlide *ngFor="let relacionado of artigosRelacionados">
                        <div (click)="goArtigo(relacionado.slug, relacionado.categoria)" class="d-flex flex-column relacionados__item">
                            <figure class="relacionados__item__img">
                                <img [src]="relacionado.image_src" [attr.alt]="relacionado.slug" height="100%" />
                            </figure>
                            <div class="relacionados__item__info">
                                <h1 class="relacionados__item__title">{{relacionado.titulo}}</h1>
                                <p class="max__lines" [innerHTML]="relacionado.resumo"></p>
                                <p class="text__more">Saiba mais</p>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>
    </div>
</main>

<!-- <psa-form-news-buttom></psa-form-news-buttom> -->