import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { GrupoService } from 'src/app/services/grupo';
import { ICompetencia } from '../habilidades';

export interface IRanking {
  contratante: string
  contato_contratante: string
  id_usuario_contratante: number
  usuario_id: number
  url_imagem: string
  estado: string
  nome: string
  espectador_id: number
  nota: number
  pontuacao: number
  id_grupo: number
  grupo_nome: string
  lider_nome: string
  usuario_lider_id: number
  ids_habilidades: string
  ids_competencias: string
  posicao: number
}
export interface ITotalizadores {
  count: number
  count_competencias: number
  count_grupos: number
  count_usuarios_contratante: number
  count_usuarios_lider: number
}

@Component({
  selector: 'app-relatorio-ranking',
  templateUrl: './relatorio-ranking.component.html',
  styleUrls: ['./relatorio-ranking.component.scss']
})
export class RelatorioRankingComponent implements OnInit, OnDestroy {
  // formGroup: FormGroup;

  loading: boolean = false;
  isOpenFilters: boolean = true;

  relatorio: Array<any> = []
  competencias: Array<ICompetencia> = []
  empresas: Array<any> = []
  grupos: Array<any> = []
  lideres: Array<any> = []

  page: number = 0
  count: number;
  qtdePages: Array<number> = [];
  limitPage: number = 12;

  totalizadores: ITotalizadores

  filterEmpresa: number = null
  filterCompetencia: number = null
  filterGrupo: number = null
  filterLider: number = null
  filterUsuario: string = null

  constructor(
    private grupoService: GrupoService,
    private fb: FormBuilder,
    private titleService: Title,
    private toast: ToastrService) { }

  ngOnDestroy(): void {
    this.titleService.setTitle("Dashboard")
  }

  async ngOnInit() {

    this.titleService.setTitle("Relatório - Ranking")

    let usuario = JSON.parse(localStorage.getItem("usuario"))
    if (usuario.id) this.filterEmpresa = usuario.id

    this.getRanking()

    const res = await this.grupoService.getCompetencias()
    this.competencias = res
  }

  async getInfo() {
    try {
      const info = await this.grupoService.getRankingInfo({
        id_usuario_empresa: this.filterEmpresa,
        id_grupo: this.filterGrupo,
        id_usuario_lider: this.filterLider,
        id_competencia: this.filterCompetencia,
        pesquisa: this.filterUsuario,
      }, this.limitPage)

      this.count = info.count
      this.empresas = info.empresas

      this.lideres = info.lideres
      this.grupos = info.grupos
      this.totalizadores = info.totalizadores

      let count = parseInt(info.pages)
      this.setQtdePages(count < info.pages ? count + 1 : count)
    } catch (error) {
      console.log(error);
    }
  }

  async getRanking() {
    this.loading = true
    try {
      const res = await this.grupoService.getRanking({
        id_usuario_empresa: this.filterEmpresa,
        id_grupo: this.filterGrupo,
        id_usuario_lider: this.filterLider,
        id_competencia: this.filterCompetencia,
        pesquisa: this.filterUsuario,
      }, this.page, this.limitPage)
      var index = 0
      this.relatorio = res
      this.relatorio.forEach((i: IRanking) => { i.posicao = ++index + (this.page * this.limitPage) })
      this.loading = false
      this.getInfo()
    } catch (error) {
      this.loading = false
      this.toast.error("Não foi possível carregar relatório")
    }
  }

  openFilter() {
    this.isOpenFilters = !this.isOpenFilters
  }

  applyFilter() {
    this.page = 0
    this.getRanking()
  }

  resetFilter() {
    this.filterEmpresa = null
    this.filterCompetencia = null
    this.filterGrupo = null
    this.filterLider = null
    this.filterUsuario = null
    this.getRanking()
  }


  changePage(number: number) {
    this.page = number;
    this.getRanking()
    this.getPages()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array.from(Array(qtdeColaboradores).keys())
  }

  getPages() {
    let listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2))
    return listPage
  }

  order(criterio: string, asc: boolean) {
    switch (criterio) {
      case 'nome':
        this.relatorio.sort((a, b) => a.nome.toLowerCase() > b.nome.toLowerCase() && asc ? 1 : -1)
        break;
      case 'nota':
        this.relatorio.sort((a, b) => a.nota > b.nota && asc ? 1 : -1)
        break;
      case 'pontuacao':
        this.relatorio.sort((a, b) => a.pontuacao > b.pontuacao && asc ? 1 : -1)
        break;
      case 'grupo_nome':
        this.relatorio.sort((a, b) => a.grupo_nome.toLowerCase() > b.grupo_nome.toLowerCase() && asc ? 1 : -1)
        break;
      case 'lider_nome':
        this.relatorio.sort((a, b) => a.lider_nome.toLowerCase() > b.lider_nome.toLowerCase() && asc ? 1 : -1)
        break;
      case 'contratante':
        this.relatorio.sort((a, b) => a.contratante && a.contratante?.toLowerCase() > b.contratante?.toLowerCase() && asc ? 1 : -1)
        break;

      default:
        this.relatorio.sort((a, b) => a.nota > b.nota ? 1 : -1)
        break;
    }
  }
}
