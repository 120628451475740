import { Routes } from '@angular/router';

import { HomeComponent } from './home';
import { CategoriaComponent } from './categoria';
import { ArtigoComponent } from './artigo';

export const SaNewsRoutes: Routes = [
  {
    path: 'sa-news/home',
    component: HomeComponent
  },
  {
    path: 'sa-news/categoria/:id',
    component: CategoriaComponent
  },
  {
    path: 'sa-news/:categoria/:slug',
    component: ArtigoComponent,
  },
  {
    path: 'conteudo/:slug',
    component: ArtigoComponent,
  },
];
