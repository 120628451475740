<mat-tab-group class="p-4" #tabGroup (selectedTabChange)="tabChanged($event)">
  <mat-tab label="VIDEOS">
    <tbsb-videos></tbsb-videos>
  </mat-tab>

  <mat-tab label="RANKING">
    <tbsb-ranking></tbsb-ranking>
  </mat-tab>

  <mat-tab label="AVISOS">
    <tbsb-notices></tbsb-notices>
  </mat-tab>
</mat-tab-group>
