import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
// import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { ModalPlayComponent } from 'src/app/common/modal-play';
import { GrupoService } from 'src/app/services/grupo';
import { PalestrasService } from 'src/app/services/palestras';
import { ModalPalestraIncompanyComponent } from './modal-palestra-incompany';


interface IPalestra {
  data: string
  descricao: string
  duracao: string
  email: string
  gratuita: number
  hora: string
  id: number
  id_live: string
  id_palestrante: number
  nome_palestrante: string
  photo: string
  publico_alvo: string
  slug: string
  status: "CADASTRANDO" | "EXECUTANDO" | "CONCLUIDA"
  subtitulo: string
  telefone: string
  tipo: "PRESENCIAL" | "ONLINE"
  titulo: string
  url_offline: string
  valor: number
  imagem_url: string
  minibio: string
  linkedin: string
  id_contratante: number
  pais: string
  local: string
  endereco: string
  estado: string
  nome_formato: string
  assunto: string
  grupo_id: number
}

@Component({
  selector: 'app-palestra',
  templateUrl: './palestra.component.html',
  styleUrls: ['./palestra.component.scss']
})
export class PalestraComponent implements OnInit, OnDestroy {
  bsModalRef: BsModalRef;

  @Output("back") back = new EventEmitter();

  loading: boolean = false
  isStarted: boolean = false
  isFinished: boolean = false
  isOnline: boolean = false
  isSubscribed: boolean = false
  source: string = null

  buckets3: string = "https://basesa.s3-sa-east-1.amazonaws.com/palestras/"

  @Input() id: number;
  palestra: IPalestra

  data: any
  dataScreen: string

  today: any = moment();
  interval: any = null;

  constructor(
    private palestrasService: PalestrasService,
    private grupoService: GrupoService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    // private socket: Socket
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get("id")
    if (!this.id) {
      this.id = parseInt(id);
      if(!this.id){
        this.toast.error("Erro ao carregar")
        window.history.back()
        return
      }
      this.source = 'internal';
    }
    this.getEvent()
      .then(() => {
        this.checkSubscription()
      })
  }

  ngOnDestroy(): void {
    clearInterval(this.interval)
  }

  async getEvent() {
    try {
      this.loading = true
      this.palestra = await this.palestrasService.getPalestraById(this.id)

      this.isOnline = this.palestra.tipo.toUpperCase() == 'ONLINE' ? true : false;
      this.setStatus(this.palestra.status)
      this.loading = false

      this.data = moment(this.palestra.data).format('ll')
      this.dataScreen = this.data + ' às ' + this.palestra.hora

    } catch (error) {
      this.toast.error("Não foi possível carregar os dados")
      this.loading = false
    }
  }

  setStatus(status: string) {
    this.isStarted = status == "EXECUTANDO" ? true : false;
    this.isFinished = status == "CONCLUIDA" ? true : false;
  }

  async checkSubscription() {
    try {
      let res = await this.palestrasService.checkSubscription(this.id)
      this.isSubscribed = res

      if (moment(this.today).isSame(this.palestra.data, "day") && this.isSubscribed && this.isOnline) {
        this.startIntervalVerifyStatus()
      }
    } catch (error) {

    }
  }

  startIntervalVerifyStatus() {
    // this.socket.fromEvent('start').subscribe(data => {
    //   this.palestrasService.getPalestraById(this.id)
    //     .then((res: any) => {
    //       this.setStatus(res.status)
    //     })
    // });
  }

  async subscribe() {
    try {
      if (this.palestra.gratuita === 2) {
        let usuario = JSON.parse(localStorage.getItem("usuario"))
        if (usuario.codigo_indicacao != this.palestra.id_contratante) {
          this.modalInCompany()
        }
        return
      }
      if (this.palestra.valor > 0) {
        window.open(`https://www.profissionaissa.com/checkout/${this.palestra.id}`, "_blank")
        return
      }
      this.subscribeUser()
    } catch (error) {
      this.toast.error("Não foi possível se inscrever")
    }
  }

  async subscribeUser() {
    try {
      await this.palestrasService.subscribe(this.id)
      this.checkSubscription()
      if (this.palestra.grupo_id) {
        this.grupoService.insertViewerInGroup(this.palestra.grupo_id)
      }
      this.toast.success("Inscrito com sucesso!")
    } catch (error) {
      this.toast.error("Não foi possível se inscrever")
    }
  }

  async generateCertificate() {
    try {
      await this.palestrasService.generateCertificate(this.id)
      this.toast.success('Certificado emitido!', 'Enviamos o certificado para em seu e-mail.')
    } catch (error) {
      this.toast.error('Não foi possível emitir certificado!', 'Tente novamente mais tarde ou entre em contato conosco.')
    }
  }

  modalInCompany() {
    const initialState = { id_palestra: this.palestra.id, id_contratante: this.palestra.id_contratante }
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalPalestraIncompanyComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.success
      value == true ? true : false;
      if (value == true) this.subscribeUser()
    })
  }

  play(url: string, title: string) {
    const initialState = {
      videoLink: url,
      title: title
    };
    const config = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(ModalPlayComponent, config);
  }
}
