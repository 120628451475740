import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  bsModalRef: BsModalRef;

  isLoading: boolean;
  filters: any;

  users: any = [];
  allUsers: any = [];

  constructor(
    private adminService: AdminService,
    private toaster: ToastrService,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.filters = {
      query: '',
      profile: 'all',
    }
    this.getUsers();
  }

  getUsers(): void {
    this.isLoading = true;

    this.adminService.getCuradores()
      .then( res => {
        this.users = res;
        this.allUsers = res;
        this.isLoading = false;
      })
      .catch( e => {
        this.toaster.error('Não foi possível recuperar os usuários.');
        this.isLoading = false;
      })
  }

  queryFilter(): void {
    this.users = this.allUsers.filter(({ nome, sobrenome, email}) =>( nome.includes(this.filters.query) || sobrenome.includes(this.filters.query)) || email.includes(this.filters.query));
  }

  filterByProfile(): void {
    if (this.filters.profile === 'all') {
      this.users = this.allUsers;
    } else {
      this.users = this.allUsers.filter(({ perfil }) => perfil === this.filters.profile);
    }
  }

  editUser(userId: number): void {
    this.router.navigate(['/admin/usuario/editar', userId])
  }

  openConfirmationModal(userId: number) {
    const message: string = 'Deseja excluir este usuário?';
    const initialState = { mensagem: message, submensagem: "" };
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config);

    this.modalService.onHide.subscribe((result: any) => {
      if (this.bsModalRef?.content?.value) {
        this.deleteUser(userId);
      }
    })
  }

  deleteUser(curadorId: number): void {
    this.adminService.deleteCurador(curadorId)
      .then(res => {
        console.log(res);
        this.toaster.success('Usuário deletado com sucesso!');
        window.location.reload();
      })
      .catch(e => {
        console.log(e);
        this.toaster.error('Ocorreu um erro ao deletar este usuário.');
      });
  }
  // moved to editar-usuario component
  // inactivateUser(userId: number): void {
  //   this.adminService.inactivateCurador(userId)
  //   .then(res => {
  //     this.toaster.success('Usuário inativado com sucesso!');
  //     window.location.reload();
  //   })
  //   .catch(e => {
  //     console.log(e);
  //     this.toaster.error('Ocorreu um erro ao inativar este usuário.');
  //   });
  // }

  // reactivateUser(userId: number): void {
  //   this.adminService.reactivateCurador(userId)
  //   .then(res => {
  //     this.toaster.success('Usuário reativado com sucesso!');
  //     window.location.reload();
  //   })
  //   .catch(e => {
  //     console.log(e);
  //     this.toaster.error('Ocorreu um erro ao reativar este usuário.');
  //   });
  // }

}
