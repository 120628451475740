import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common';
import { PalestrasService } from 'src/app/services/palestras';

@Component({
  selector: 'app-modal-medias',
  templateUrl: './modal-medias.component.html',
  styleUrls: ['./modal-medias.component.scss']
})

export class ModalMediasComponent implements OnInit {

  @Input() download: boolean = false
  @Input() id: number

  constructor(
    public bsModalRef: BsModalRef,
    private toast: ToastrService,
    private palestrasService: PalestrasService
  ) { }

  ngOnInit(): void {
    if (this.download) this.dowloadMedias()
  }

  async dowloadMedias() {
    try {
      const resultMidias = await this.palestrasService.generateMedias(this.id)
      var a = document.createElement("a");
      a.href = resultMidias.img_email_data64;
      a.download = "email.png";
      a.click();
      a.remove();
      var a = document.createElement("a");
      a.href = resultMidias.img_story_data64;
      a.download = "story.png";
      a.click();
      a.remove();
      var a = document.createElement("a");
      a.href = resultMidias.img_palestra_data64;
      a.download = "palestra.png";
      a.click();
      a.remove();

      this.toast.success("Em breve, também receberá as mídias em seu e-mail.", "Download das mídias executado com sucesso.")

      this.bsModalRef.hide()
    } catch (error) {
      this.toast.error("Ocorreu algum erro ao concluir o processo.", "Não foi possível gerar as mídias")
    }
  }
}
