<div aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="">

    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
      <button (click)="bsModalRef.hide()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="d-flex justify-content-center p-1">
      <div class="rounded col-12 div__iframe">
        <video *ngIf="!isYoutube()" width="100%" height="100%" controls controlsList="nodownload">
          <source [src]="url" type="video/mp4">
        </video>
        <iframe *ngIf="isYoutube()" width="100%" height="100%" [src]="url" frameborder="0" allow="autoplay; encrypted-media"></iframe>
      </div>
    </div>
    <div class="text-center" *ngIf="isYoutube()" >
      <a class="text-dark" target="_blank" href="{{videoLink}}">Abrir no youtube</a>
    </div>
    <div class="modal-footer">
      <button (click)="close()" type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
    </div>

  </div>
</div>