<div class="d-flex">
    <div class="container__search col-12 col-lg-6 d-flex align-items-center">
        <img height="18px" src="assets/icons/search-black.svg" alt="ícone pesquisar">
        <input class="container__search__input form-group m-0 border-0 bg-transparent" placeholder="Buscar..."
            type="text" name="search" [(ngModel)]="search" (ngModelChange)="buscar()">
    </div>

    <div class="col-12 col-lg-6 pr-0 d-flex flex-column flex-md-row justify-content-end align-items-center">

        <div class="container__select__filter__order bg-transparent d-flex align-items-center">
            <label class="m-0 mr-2" for="order">Ordenar: </label>
            <select class="select__filter__order" [(ngModel)]="order" name="order" (ngModelChange)="changeOrder()">
                <option *ngFor="let i of orders" [value]="i.value">{{i.name}}</option>
            </select>
        </div>

        <button (click)="adicionarLider()" class="btn__primary button__insert button__expandable"
            title="Adicionar tutor">
            <span>
                <img src="assets/icons/add.svg" alt="adicionar">
            </span>
        </button>
    </div>
</div>

<div class="content__cabecalho text-left d-flex p-0">
    <label class="font-title text-uppercase flex2">Nome</label>
    <label class="font-title text-uppercase flex1">Cargo</label>
    <label class="font-title text-uppercase flex1">Grupo</label>
    <label class="font-title text-uppercase flex1">Status</label>
    <label class="font-title text-uppercase flex03">Ações</label>
</div>

<ul>
    <li #lider *ngFor="let lider of lideres">

        <div class="d-flex content__list__item">

            <div class="flex2 w-100 item__nome pl-0">

                <input #lider_nome class="input__disable item__nome" type="text" [(ngModel)]="lider.nome_completo"
                    disabled="disabled">
            </div>

            <div class="flex1">
                <input class="input__disable" type="text" [(ngModel)]="lider.cargo_nome" disabled="disabled">
            </div>

            <div class="flex1">
                <input class="input__disable" type="text" [value]="lider.grupo_nome ? lider.grupo_nome : 'Indefinido'"
                    disabled="disabled">
            </div>

            <div class="flex1">
                <input class="input__disable" type="text"
                    [value]="lider.contratante_ativo == 'SIM' ? 'Ativo' : 'Inativo'" disabled="disabled">
            </div>

            <div class="flex03 td_acao align-items-center">
                <img (click)="editar(lider)" src="assets/icons/editar.svg" alt="adicionar">
                <img [ngClass]="{'unclickable': lider.removable == 0}" (click)="excluir(lider.id_colaborador)"
                    class="float-right" src="assets/icons/excluir.svg" alt="excluir">
            </div>
        </div>
    </li>
</ul>

<p *ngIf="lideres?.length == 0">Nenhum líder cadastrado.</p>

<div class="d-flex justify-content-between align-items-center">
    <nav *ngIf="qtdePages?.length > 1" aria-label="Page navigation example">
        <ul class="pagination">
            <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                <a class="page-link">Anterior</a>
            </li>
            <li (click)="changePage(i)" class="page-item" *ngFor="let number of qtdePages, let i = index">
                <a class="page-link">{{i+1}}</a>
            </li>
            <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                <a class="page-link">Próxima</a>
            </li>
        </ul>
    </nav>
</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>