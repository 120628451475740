import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const perfil: string = localStorage.getItem("perfil");

    switch (perfil) {
      case "PALESTRANTE":
        this.router.navigate(['/palestrante/home']);
        return false;
      case "CONTRATANTE":
        break
      case "ESPECTADOR":
        this.router.navigate(['/espectador/home']);
        return false;
      default:
        break
    }
    return true;
  }

}
