import { Component, Input, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Toast, ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';

@Component({
  selector: 'app-exclusivo-admin',
  templateUrl: './exclusivo-admin.component.html',
  styleUrls: ['./exclusivo-admin.component.scss']
})
export class ExclusivoAdminComponent implements OnInit {
  @Input() palestrante: any;
  speakers: any = [];

  exclusivo: any = {
    speakerId: null,
    emailContent: '',
    redirectPage: '',
  }
  exclusivoId = '';
  certificateBg = '';
  file: any;

  loading: boolean = false;
  hasError: boolean = false;
  isEdit: boolean = false;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    showToolbar: true,
    enableToolbar: true,
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [],
    sanitize: true,
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  constructor(
    private palestrantesService: PalestrantesService,
    private toast: ToastrService,
  ) {}

  ngOnInit(): void {
    console.log(this.palestrante);
    this.getSpeakerInfo();
  }

  getSpeakerInfo() {
    this.palestrantesService.getSpeakerExclusivo(this.palestrante.id).subscribe(
      (result: any) => {
        const speaker = result.body[0];
        if(!speaker) {
          this.isEdit = false;
        } else {
          this.isEdit = true;
          this.exclusivo = {
            speakerId: this.palestrante.id,
            emailContent: speaker.emailContent,
            redirectPage: speaker.redirectPage,
          };
          this.exclusivoId = speaker.id;
          this.certificateBg = speaker.certificateBg;
        }
      }
    );
  }


  onFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      var vFileTobeRead = event.target.files[0];
      var vFileReader = new FileReader();

      vFileReader.onload = () => {
        const fileSize = event.target.files[0].size / 1024 / 1024 / 1024;

        if (fileSize > 4) {
          event.target.files = null;
          event.target.value = null;

          const message = 'O arquivo selecionado ultrapassa o tamanho máximo de 4GB!';
          this.toast.error('Ocorreu um erro ao salvar a imagem');
        }
        else {
          const [file] = event.target.files;
          this.file = file
        }
      }

      vFileReader.readAsDataURL(vFileTobeRead);
    }
  }

  matchSpeaker() {
    try {
      const selected = this.speakers.filter((speaker: any) => speaker.nomeCompleto === this.exclusivo.speaker);

      if(!selected || selected.length === 0) {
        throw new Error('Palestrante não selecionado e/ou não encontrado.');
      } else {
        console.log(selected);
        this.exclusivo.speakerId = selected[0].id;
      }
    } catch (err) {
      this.hasError = true;
      this.toast.error(err.message);
    }
  }

  setAction() {
    if(this.isEdit) {
      this.edit();
    } else {
      this.create();
    }
  }

  checkIfEmailContentIsEmpty(content: string) {
    const element = document.createElement('div');
    element.innerHTML = content;

    const contentText = element.innerText.trim();
    console.log(contentText.length);
    if(!contentText || contentText.length === 0) {
      return null;
    }

    return content;
  }

  edit() {
    this.loading = true;

    const formData = new FormData();
    formData.append('speakerId', this.palestrante.id);
    formData.append('file', this.file || null);
    formData.append('emailContent', this.checkIfEmailContentIsEmpty(this.exclusivo.emailContent));
    formData.append('redirectPage', this.exclusivo?.redirectPage);

    this.palestrantesService.editExclusivo(this.exclusivoId, formData).subscribe(
      (result: any) => {
        this.loading = false;
        this.toast.success('Palestrante exclusivo editado com sucesso!');
      },
      (data: any) => {
        this.loading = false;
        this.toast.error('Ocorreu um erro ao salvar o palestrante');
      }
    )
  }

  create() {
    this.loading = true;

    const formData = new FormData();
    formData.append('speakerId', this.palestrante.id);
    formData.append('file', this.file || null);
    formData.append('emailContent', this.checkIfEmailContentIsEmpty(this.exclusivo.emailContent));
    formData.append('redirectPage', this.exclusivo?.redirectPage);

    this.loading = true;
    this.palestrantesService.createExclusivo(formData).subscribe(
      (result: any) => {
        this.loading = false;
        this.toast.success('Palestrante exclusivo salvo com sucesso!');
      },
      (data: any) => {
        this.loading = false;
        this.toast.error('Ocorreu um erro ao salvar o palestrante');
      }
    );
  }
}
