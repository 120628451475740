import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { duracao, horarios } from 'src/app/common/array-horarios';
import { estados } from 'src/app/common/array-estados';
import { IInvalid } from '../../configuracoes/palestrante/tb-dados';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { PalestrasService } from 'src/app/services/palestras';
import { CommonService } from 'src/app/services/common';
import { ActivatedRoute } from '@angular/router';
import { ModalConfirmationComponent } from 'src/app/common/modal-confirmation';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalHorarioNobreComponent } from './modal-horario-nobre';
import { ContratanteService } from 'src/app/services/contratante';
import { ViacepService } from 'src/app/services/viacep';
import { ModalMediasComponent } from '../modal-medias';
import { ModalAlterarMidiaComponent } from '../modal-alterar-midia';

export interface IPalestra {
  id: number
  palestrantes_id: number
  cep: string
  cidade: string
  empresa_contratante: string
  data: string
  descricao: string
  duracao: number
  email_contratante: string
  endereco: string
  estado: string
  formato: string
  gratuita: number
  hora: string
  id_contratante: number
  id_tema: number
  imagem_url: string
  local: string
  nome: string
  propria: number
  publico_alvo: string
  subtitulo: string
  sala: string
  tipo: string
  valor: number
  canHorarioNobre: boolean | undefined | null
}

export interface IFormato {
  id: number
  nome_formato: string
}

@Component({
  selector: 'app-form-evento',
  templateUrl: './form-evento.component.html',
  styleUrls: ['./form-evento.component.scss']
})
export class FormEventoComponent implements OnInit {

  bsModalRef: BsModalRef;
  @Output("finished") finished = new EventEmitter();
  @Output("refresh") refresh = new EventEmitter();

  @Input() idPalestra: number;

  loading: boolean = false
  invalids: Array<IInvalid> = []

  formatos: Array<any>
  temas: Array<any>
  temasPorPai: Array<any>

  plano: any;
  palestra: IPalestra = { id: null, palestrantes_id: null, tipo: null, gratuita: null, nome: "", formato: "", subtitulo: "", id_tema: null, descricao: "", publico_alvo: "", data: "", hora: "", duracao: null, valor: 0, propria: null, cep: "", cidade: "", estado: null, endereco: "", local: "", empresa_contratante: "", email_contratante: "", imagem_url: "", sala: "", id_contratante: null, canHorarioNobre: undefined }

  arrayTipo = [{ value: "ONLINE", text: "Online" }, { value: "PRESENCIAL", text: "Presencial" }]
  arrayIngresso = [{ value: 2, text: "In Company" }, { value: 1, text: "Gratuita" }, { value: 0, text: "Com cobrança de ingresso" },]
  arrayPropria = [{ value: 1, text: "Própria" }, { value: 0, text: "Parceira" }]
  arrayEstados = estados;
  arrayHorarios = horarios;
  arrayDuracoes = duracao;
  canHorarioNobre: boolean = false

  exemploDescricao: string = "Exemplo: Monte uma equipe de alta performance com ultra vendedores. Sua equipe precisa potencializar seus resultados? Nesta palestra online eu vou mostrar para você e seus vendedores quais são os 7 passos da jornada em que equipes e vendedores de sucesso tiveram para se tornarem máquinas de vendas, entregando resultados muito acima da média. Você vai aprender: prospecção assertiva; negociação de alta persuasão; fechamento de negócios com as melhores margens. Inscreva-se agora mesmo para transformar seus resultados!";
  mostrarExemploDescricao: boolean = false;
  exemploTitulo: string = "Exemplo: A INCRÍVEL JORNADA DO ULTRA VENDEDOR";
  mostrarExemploTitulo: boolean = false;
  exemploSubtitulo: string = "Exemplo: COMO TRANSFORMAR SUA EQUIPE EM UMA MÁQUINA DE VENDAS";
  mostrarExemploSubtitulo: boolean = false;

  isDisable: boolean = false

  nomeContratante: string
  contatoPalestrante: string

  constructor(
    private route: ActivatedRoute,
    private toast: ToastrService,
    private palestrantesService: PalestrantesService,
    private palestrasService: PalestrasService,
    private contratanteService: ContratanteService,
    private commonService: CommonService,
    private modalService: BsModalService,
    private cepService: ViacepService,
  ) { }

  ngOnInit(): void {
    if (!this.idPalestra) this.idPalestra = parseInt(this.route.snapshot.paramMap.get("id"))

    if (this.idPalestra > 0) {
      this.getPalestra()
    } else {
      let palestrante = JSON.parse(localStorage.getItem("palestrante"))
      this.palestra.palestrantes_id = palestrante.id
    }
    this.verificaPlano()
    this.loadSelects()

    this.commonService.getConfiguracao("telefone__contato__palestrante", "5511956372536")
      .then(res => { this.contatoPalestrante = res.valor })
      .catch(e => { console.log(e); })
  }

  async getPalestra() {
    try {
      const pm = await this.palestrasService.getMinistradaById(this.idPalestra)
      this.palestra = pm
      const valor = (this.palestra.valor)/100
      this.palestra.valor = valor
      if (this.compareDate(pm.data)) this.isDisable = true
    } catch (error) {
      this.toast.error("Não foi possível buscar sua palestra")
      history.back()
    }
  }

  async loadSelects() {
    try {
      const f = await this.commonService.getFormatos()
      this.formatos = f
      const t = await this.commonService.getAllTemas(true)
      this.temas = t
    } catch (error) {

    }
  }

  async verificaPlano() {
    try {
      const res = await this.palestrantesService.verificaPlano()
      this.plano = res
      // validar palestras disponiveis
      // oferecer compra de palestra
    } catch (error) {

    }
  }

  async salvar() {
    this.invalids = []
    try {
      this.loading = true
      this.palestra.canHorarioNobre = this.canHorarioNobre
      this.palestra.valor = this.palestra.valor * 100

      const res = await this.palestrasService.insertOrUpdate(this.palestra)

      this.loading = false
      this.toast.success("Salvo com sucesso!");
      this.finished.emit({ refresh: true })

      this.mediasDownload(res.id);
    } catch (error) {
      this.loading = false
      if (error.error.message.invalids) {
        this.invalids = error.error.message.invalids
        this.toast.error("Campos inválidos!", "Por favor, revise.")

        this.invalids.forEach(item => {
          if (item.campo == 'canHorarioNobre') this.horarioNobre(item)
        })
      } else {
        this.toast.error("Não foi possível salvar os dados", "Falha na conexão")
      }
    }
  }

  mediasDownload(id: number) {
    if (document.location.hostname == 'localhost'){
      console.error('A geração de medias não pode ser realizada no ambiente local!')
      return
    }

    try {
      const initialState = { id: id, download: true };
      const config = { initialState }
      this.bsModalRef = this.modalService.show(ModalMediasComponent, config);
    } catch (error) {
      console.log(error);
      this.toast.error("Ocorreu algum erro ao concluir o processo.", "Não foi possível gerar as mídias")
    }
  }

  changeMidia(){
    this.bsModalRef = this.modalService.show(ModalAlterarMidiaComponent, {backdrop : 'static', keyboard : false});
    this.bsModalRef.content.palestraId = this.idPalestra;

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      // if (this.bsModalRef.content.success) this.getUsuario()
      if (this.bsModalRef.content.success) this.refresh.emit()
    })
  }

  changeTipo(e: any) {
    let id = e.target.value;
    for (const key in this.formatos) {
      if (this.formatos[key].id == id) {
        this.palestra.tipo = this.formatos[key].presencial == 0 ? "ONLINE" : "PRESENCIAL"
        return
      }
    }
  }

  horarioNobre(item: any) {
    this.toast.error(item.erro)
    const initialState = item.freePlan
      ? {
        text: item.erro,
        textSchedule: `Horários reservados: ${item.horarios.toString()}`
      }
      : {}
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalHorarioNobreComponent, config)
    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.liberado) this.canHorarioNobre = true
    })
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return true
    }
    return false
  }

  getError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) return item['erro']
    }
    return ""
  }

  getValorInfo() {
    const valorInicial = 5;
    if (this.palestra.valor)
      return parseInt(this.palestra.valor + "") + valorInicial
    else
      return valorInicial
  }

  cancel() {
    this.finished.emit({ refresh: false })
  }

  async avisoData(e: any) {
    let data = new Date(e.target.value)
    if (data.getFullYear() > 1111 && this.compareDate(e.target.value)) {
      const initialState = { mensagem: "Se você escolher a data no passado, não poderá modificar mais.", submensagem: "Tem certeza que gostaria de continuar?" }
      const config = { initialState }
      this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)
    }
  }

  compareDate(date: any) {
    let data = new Date(this.palestra.data)
    let now = new Date()
    return data <= now
  }

  async searchContratante() {
    try {
      let res = await this.contratanteService.getContratanteById(this.palestra.id_contratante)
      this.nomeContratante = res.empresa
      this.deleteError("id_contratante")
    } catch (error) {
      this.toast.error("Contratante não encontrado")
      this.nomeContratante = ""
    }
  }

  openWhats() {
    const usuario = JSON.parse(window.localStorage.getItem("usuario"));
    let texto = `Olá, entro em contato por meio do site Profissionais SA - Dashboard. Perfil Palestrante, plano ${this.plano.plano_nome}. Sou ${usuario.nome}, e-mail:  ${usuario.email}`;
    window.open("https://api.whatsapp.com/send?phone=" + this.contatoPalestrante + "&text=" + texto, "_blank")
  }

  deleteError(value: string) {
    for (const key in this.invalids) {
      let item = this.invalids[key]
      if (item['campo'] == value) {
        this.invalids.splice(parseInt(key), 1)
        return
      }
    }
  }

  async completeCEP() {
    if (this.palestra.cep?.length === 8) {
      const initialState = { mensagem: "Completar endereço com CEP?", submensagem: "" }
      const config = { initialState }
      this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)
      let newSubscriber = this.modalService.onHide.subscribe(() => {
        newSubscriber.unsubscribe();
        if (this.bsModalRef.content.value) this.completeUsingCEP()
      })
    } else {
      this.toast.error("CEP inválido.")
    }

  }

  async completeUsingCEP() {

    try {
      const resCEP = await this.cepService.getCEP(this.palestra.cep)

      if (resCEP.logradouro) {
        this.palestra.endereco = resCEP.bairro
        this.palestra.cidade = resCEP.localidade
        this.palestra.estado = resCEP.uf
      } else {
        this.toast.error("Não foi possível preencher os campos com o CEP.", "Revise o CEP informado.")
      }
    } catch (error) {
      this.toast.error("Não foi possível preencher os campos com o CEP.", "Revise o CEP informado.")
    }
  }
}
