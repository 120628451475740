<div class="modal-header border-0 p-2">
    <div class="d-flex justify-content-between align-items-center text-center">
        <h3 class="font-title">Comunicação interna</h3>
    </div>
    <div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </div>
</div>
<div class="modal-body border-0 d-flex justify-content-around">
    <form class="row" (submit)="sendPush()">
        <div class="form-group col-12">
            <label class="label-input" for="title">Título *</label>
            <input class="form-control input__default" [(ngModel)]="push.title" name="title" type="text"
                placeholder="Título *" maxlength="60">
        </div>

        <div class="form-group col-12">
            <label class="label-input" for="title">Mensagem *</label>
            <textarea id="message" rows="3" class="form-control input__default" [(ngModel)]="push.message"
                name="message" type="text" placeholder="Mensagem *" maxlength="250"></textarea>
        </div>

        <div class="form-group col-12">
            <label class="label-input" for="hour">Direcionamento</label>
            <select class="form-control" name="indexTypeRedirect" [(ngModel)]="push.indexTypeRedirect">
                <option *ngFor="let t of typeRedirects, let index = index" [value]="index">
                    {{t.text}}</option>
            </select>
        </div>

        <div [class.d-none]="!(typeRedirects[push.indexTypeRedirect].value == 'LECTURE')" class="form-group col-12">
            <label class="label-input" for="lecture">PALESTRA *</label>
            <ng-select [searchable]="true" [clearable]="true" bindLabel="lecture" placeholder="Selecione"
                appendTo="body" notFoundText="Não encontrado" [(ngModel)]="push.idRedirect"
                [ngModelOptions]="{standalone: true}">
                <ng-option [value]="l.id" *ngFor="let l of lectures">
                    {{l.nome}}
                </ng-option>
            </ng-select>
        </div>

        <div [class.d-none]="!(typeRedirects[push.indexTypeRedirect].value == 'CONTENT')" class="form-group col-12">
            <label class="label-input" for="content">Conteúdo *</label>
            <ng-select [searchable]="true" [clearable]="true" bindLabel="content" placeholder="Selecione"
                appendTo="body" notFoundText="Não encontrado" [(ngModel)]="push.idRedirect"
                [ngModelOptions]="{standalone: true}">
                <ng-option [value]="c.id" *ngFor="let c of contents">
                    {{c.titulo}}
                </ng-option>
            </ng-select>
        </div>

        <div [class.d-none]="!(typeRedirects[push.indexTypeRedirect].value == 'SKILL')" class="form-group col-12">
            <label class="label-input" for="content">Curso rápido *</label>
            <ng-select [searchable]="true" [clearable]="true" bindLabel="skill" placeholder="Selecione" appendTo="body"
                notFoundText="Não encontrado" [(ngModel)]="push.idRedirect" [ngModelOptions]="{standalone: true}">
                <ng-option [value]="s.id" *ngFor="let s of skills">
                    {{s.titulo}}
                </ng-option>
            </ng-select>
        </div>

        <div [class.d-none]="!(typeRedirects[push.indexTypeRedirect].value == 'LINK')" class="form-group col-12">
            <label class="label-input" for="title">Link *</label>
            <input class="form-control input__default" [(ngModel)]="push.externalLink" name="externalLink"
                type="text" placeholder="Link *" maxlength="300">
        </div>

        <div class="form-group col-12">
            <label class="label-input" for="date">Data da notificação *</label>
            <input class="form-control input__default" [(ngModel)]="push.date" name="date" type="date">
        </div>

        <div class="form-group col-12">
            <label class="label-input" for="hour">Hora da notificação *</label>
            <input class="form-control input__default" [(ngModel)]="push.hour" name="hour" type="text" mask="00:00"
                placeholder="Hora (ex: 18:00)">
        </div>

        <div class="col-12">
            <button class="btn__primary" type="submit">Enviar</button>
        </div>
    </form>
</div>