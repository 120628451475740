<div class="py-4 px-5" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </div>

    <div class="modal-header d-flex flex-column align-items-center justify-content-center">

        <h2 class="font-title">Palestra extra</h2>
        <p>Você não tem mais nenhuma palestra disponivel, gostaria de comprar mais cotas?</p>
        <h5 class="font-title">+1 Palestra disponível</h5>
        <h3 *ngIf="amount" class="font-title">{{formatMoney()}}</h3>
    </div>

    <div>
        <form (submit)="pay()">

            <div class="row">
                <div class="form-group col-12">
                    <label class="label-input" for="nome">nome no cartão *</label>
                    <input id="nome" [class.invalid]="isIncorrect('nome')" class="form-control" name="nome" type="text"
                        [(ngModel)]="form.nome" placeholder="Nome no cartão *">
                    <span class="error">{{getError('nome')}}</span>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12">
                    <label class="label-input" for="numero">Número do cartão *</label>
                    <input id="numero" [class.invalid]="isIncorrect('numero')" class="form-control" name="numero"
                        type="text" [(ngModel)]="form.numero" placeholder="Número *" mask="0000 0000 0000 0000">
                    <span class="error">{{getError('numero')}}</span>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12">
                    <label class="label-input" for="codigo">Código de Segurança *</label>
                    <input id="codigo" [class.invalid]="isIncorrect('codigo')" class="form-control" name="codigo"
                        type="password" [(ngModel)]="form.codigo" placeholder="Código *" mask="000">
                    <span class="error">{{getError('codigo')}}</span>
                </div>
            </div>

            <h6 class="font-title">Validade do Cartão</h6>
            <div class="row">

                <div class="form-group col-12 col-md-6">
                    <label class="label-input" for="mes">Mês *</label>
                    <select id="mes" [class.invalid]="isIncorrect('mes')" class="form-control" name="mes"
                        placeholder="Mês *" [(ngModel)]="form.mes">
                        <option value="">Selecione *</option>
                        <option *ngFor="let i of arrayMeses" [value]="i.value">{{i.mes}}</option>
                    </select>
                    <span class="error">{{getError('mes')}}</span>
                </div>

                <div class="form-group col-12 col-md-6">
                    <label class="label-input" for="ano">ano *</label>
                    <select id="ano" [class.invalid]="isIncorrect('ano')" class="form-control" name="ano"
                        placeholder="ano *" [(ngModel)]="form.ano">
                        <option value="">Selecione *</option>
                        <option *ngFor="let i of arrayAnos" [value]="i">{{i}}</option>
                    </select>
                    <span class="error">{{getError('ano')}}</span>

                </div>
            </div>

            <div class="row d-flex justify-content-center">
                <button *ngIf="!loading" type="submit" class="btn">Salvar</button>
    
                <button *ngIf="loading" class="btn mt-2">
                    <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
                </button>
            </div>
        </form>
    </div>


</div>