import { Component, OnInit } from '@angular/core';
import { ContratanteService } from '../../../services/contratante';
import { IOrder, arrayOrders } from 'src/app/common/array-filtro-colaboradores';
import { ILider } from '../grupo-lideres'
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalFormColaboradorComponent } from '../modal-form-colaborador';
import { ModalConfirmationComponent } from '../../../common/modal-confirmation';
import { IColaborador } from '../colaboradores'
import { SubstituirLiderComponent } from '../substituir-lider';

@Component({
  selector: 'app-tb-colaborador',
  templateUrl: './tb-colaborador.component.html',
  styleUrls: ['./tb-colaborador.component.scss']
})
export class TbColaboradorComponent implements OnInit {
  bsModalRef: BsModalRef;

  lideres: Array<ILider>;
  colaboradores: Array<IColaborador>;

  loading: boolean = false;
  qtdePages: Array<number> = [];
  page: number = 0;
  search: string = "";
  status: string = "SIM";
  orders: Array<IOrder> = arrayOrders;
  order: string = this.orders[1].value;

  constructor(
    private contratanteService: ContratanteService,
    private toast: ToastrService,
    private modalService: BsModalService,) { }

  ngOnInit(): void {
    this.loadColaboradores();
  }

  async loadColaboradores() {
    try {
      const result = await this.contratanteService.getColaboradoresByOrder(this.page, this.order, this.search, this.status);

      this.colaboradores = result.colaboradores ? result.colaboradores : [];
      this.lideres = result.lideres ? result.lideres : [];
      this.setQtdePages(result.quantidade)
    } catch (error) {
      console.log('error', error);
    }
  }

  adicionar() {
    let col: IColaborador = {
      id_usuario: null,
      id_cargo: null,
      id_colaborador: null,
      id_contratante: null,
      id_espectador: null,
      id_grupo: null,
      grupo_nome: null,
      cpf: null,
      setor: null,
      email: null,
      nome_completo: null,
      responsavel_grupo: "NAO",
      cargo_nome: null,
      contratante_ativo: "SIM",
      removable: 0,
    };
    this.editar(col)
  }

  editar(colaborador: IColaborador) {
    const initialState = { colaborador: colaborador };
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalFormColaboradorComponent, config);

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      this.loadColaboradores()
    })
  }

  async excluir(id_colaborador: number) {
    const initialState = { mensagem: "Deseja realmente excluir?", submensagem: "" }

    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(async () => {

      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value
      value == true ? true : false;

      if (value == true) {

        try {
          this.loading = true
          await this.contratanteService.deleteColaborador(id_colaborador);
          this.loadColaboradores()
          this.loading = false

        } catch (error) {
          this.loading = false
          this.toast.error("Não foi possível deletar colaborador!")
        }
      }
    })
  }

  async excluirCollection() {
    const initialState = { mensagem: "Deseja realmente excluir?", submensagem: "" }

    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)

    let newSubscriber = this.modalService.onHide.subscribe(async () => {

      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value
      value == true ? true : false;

      if (value == true) {
        try {

          this.loading = true
          await this.contratanteService.deleteColaboradorCollection();
          this.page = 0
          this.loadColaboradores()
          this.loading = false

        } catch (error) {
          this.loading = false
          this.toast.error("Não foi possível deletar colaborador!")
        }
      }
    })
  }


  changePage(number: number) {
    this.page = number;
    this.loadColaboradores()
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array.from(Array(qtdeColaboradores).keys())
  }

  getPages() {
    let listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2))
    return listPage
  }

  buscar() {
    this.page = 0
    this.loadColaboradores()
  }

  changeOrder() {
    this.page = 0
    this.loadColaboradores()
  }

  changeStatusFilter() {
    this.page = 0
    this.loadColaboradores()
  }

  messageNotDelete(colaborador: IColaborador) {
    const initialState = { mensagem: "Só é possível excluir um colaborador que não tiver realizado nenhuma tarefa.", submensagem: "Deseja inativá-lo agora?" }
    const config = { initialState }
    this.bsModalRef = this.modalService.show(ModalConfirmationComponent, config)
    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      let value = this.bsModalRef.content.value
      value == true ? true : false;
      if (value == true) this.editar(colaborador);
    })
  }

  changeTutor(colaborador: IColaborador) {
    const initialState = { colaborador: colaborador }
    const config = { class: 'modal-play', initialState }
    this.bsModalRef = this.modalService.show(SubstituirLiderComponent, config)
    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      this.loadColaboradores()
    })
  }
}
