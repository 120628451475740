<div class="container-fluid px-2 px-md-5">
    <h3 class="font-title">Bem-vindo, PALESTRANTE!</h3>
    <div class="row">

        <div class="col-12 col-md-8">
            <div class="row content__total">

                <div class="col-12 col-md-4 d-flex">
                    <img src="assets/icons/microfone.svg" alt="Palestras Cadastradas">
                    <div class="d-flex flex-column justify-content-center">
                        <span class="total__number">{{totalizadores.total_palestras}}</span>
                        <span class="total__desc">Produtos Cadastrados</span>
                    </div>
                </div>

                <div class="col-12 col-md-4 d-flex">
                    <img src="assets/icons/calendar.svg" alt="Palestras Agendadas">
                    <div class="d-flex flex-column justify-content-center">
                        <span class="total__number">{{totalizadores.total_agendadas}}</span>
                        <span class="total__desc">Eventos Agendados</span>
                    </div>
                </div>

                <div class="col-12 col-md-4 d-flex">
                    <img src="assets/icons/document.svg" alt="Orçamentos em Avaliaçāo">
                    <div class="d-flex flex-column justify-content-center">
                        <span class="total__number">{{totalizadores.total_orcamentos_avaliacao}}</span>
                        <span class="total__desc">Orçamentos em Avaliaçāo</span>
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12 col-md-4 pl-md-4 pr-md-0">
            <div (click)="whats()" class="content__total content__contato d-flex">
                <img src="assets/icons/whatsapp-black.svg" alt="whatsapp">
                <div class="d-flex flex-column justify-content-center">
                    <p class="m-0">Tire suas dúvidas. Entre em contato conosco.</p>
                </div>
            </div>
        </div>


        <div class="col-12 px-0 mt-4 mb-4">
            <div class="container__artigo">
                <h3 class="font-title mb-4">Conteúdos Exclusivos</h3>

                <div class="row">
                    <div *ngFor="let artigo of artigos" [routerLink]="['/conteudo/' + artigo.slug]" class="content__artigo col-12 col-md-4">
                        <figure>
                            <img [src]="artigo.image_src || 'https://placehold.it/300'" [alt]="artigo.titulo">
                        </figure>
                        <h4 class="m-0">{{artigo.titulo}}</h4>
                        <span class="artigo__data">{{artigo.data}}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>