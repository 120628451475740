import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';

import { HomeComponent } from './home';
import { MeuPerfilComponent } from './meu-perfil';
import { ListPalestrasComponent } from './meu-perfil/list-palestras';
import { SpinnerComponent } from './spinner';
import { AgendaComponent } from './agenda';
import { CalendarioComponent } from './agenda/calendario';
import { EventosComponent } from './agenda/eventos/eventos.component';
import { TbEventosComponent } from './agenda/eventos/tb-eventos';
import { RouterModule } from '@angular/router';
import { FormEventoComponent } from './form-evento';
import { EventoComponent } from './evento/evento.component';
import { ModalHorarioNobreComponent } from './form-evento/modal-horario-nobre';
import { ModalPalestraExtraComponent } from './modal-palestra-extra';
import { ModalMediasComponent } from './modal-medias';
import {
  PalestrasDisponiveisComponent,
  DadosComponent,
  CacheComponent,
  DepoimentosComponent,
  TemasComponent,
  FormacaoComponent,
  ExperienciaComponent,
  WizardComponent,
} from './perfil-completo';
import { ConfiguracoesModule } from '../configuracoes';
import { FormPalestraDisponivelComponent } from './perfil-completo/palestras-disponiveis/form-palestra-disponivel/form-palestra-disponivel.component';
import { FormExperienciaComponent } from './perfil-completo/experiencia/form-experiencia/form-experiencia.component';
import { FormFormacaoComponent } from './perfil-completo/formacao/form-formacao/form-formacao.component';
import { FormSolicitarDepoimentoComponent } from './perfil-completo/depoimentos/form-solicitar-depoimento/form-solicitar-depoimento.component';
import { ModalAlterarMidiaComponent } from './modal-alterar-midia';
import { MeuPerfilNewComponent } from './meu-perfil-new';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    HomeComponent,
    MeuPerfilComponent,
    MeuPerfilNewComponent,
    ListPalestrasComponent,
    SpinnerComponent,
    AgendaComponent,
    CalendarioComponent,
    EventosComponent,
    TbEventosComponent,
    FormEventoComponent,
    EventoComponent,
    ModalHorarioNobreComponent,
    ModalPalestraExtraComponent,
    ModalMediasComponent,
    FormacaoComponent,
    TemasComponent,
    DepoimentosComponent,
    CacheComponent,
    ExperienciaComponent,
    WizardComponent,
    PalestrasDisponiveisComponent,
    FormPalestraDisponivelComponent,
    FormExperienciaComponent,
    FormFormacaoComponent,
    FormSolicitarDepoimentoComponent,
    ModalAlterarMidiaComponent,
    DadosComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    RouterModule,
    FullCalendarModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    ConfiguracoesModule
  ],
  exports: [SpinnerComponent],
})
export class PalestranteModule {}
