<div class="container-fluid px-5">
  <!-- <app-wizard [itemSelected]="2"></app-wizard> -->

  <div class="row container__conteudo__default mt-4">
    <div class="sectionActions">
      <div class="closeButton" (click)="close()">
        <img src="../../../../../assets/icons/close.svg" />
      </div>
    </div>
    <ul *ngIf="temasPai?.length > 0" class="col-12 col-md-6 list-group" style="height: 100%;">
      <div class="headContainer">
        <div>
          <h4 class="font-title mr-2 mb-0">Temas macro</h4>

          <p class="textInfo">
            Escolha até 6 Temas Macro
          </p>
        </div>

        <input
          class="inputCustom"
          id="search"
          name="search"
          type="text"
          [(ngModel)]="search"
          placeholder="Buscar..."
        >
      </div>

      <div class="container__macro">
        <li [class.bg__selected]="pai.id == temaSelecionado.id" class="list-group-item content__item__macro"
          *ngFor="let pai of getTemaFiltered()">
          <div class="d-flex align-items-center item__macro">
            <label class="container__checkbox mb-0">
              <input [(ngModel)]="pai.checked" type="checkbox" (ngModelChange)="check(pai.id)">
              <span class="checkmark"></span>
            </label>

            <div (click)="selectTemaPai(pai)" class="d-flex justify-content-between item__macro__info w-100">
              <span>{{pai.nome}}</span>
              <img class="img__more" src="assets/icons/open_more.svg" alt="abrir" title="selecionar subtemas">
            </div>
          </div>
        </li>
      </div>
    </ul>

    <ul class="col-12 col-md-6 list-group pl-0 pl-md-5 mt-3 mt-md-0">
      <div class="headContainer">
        <!-- <label class="container__checkbox container__checkbox__all mb-0">Todos
            <input type="checkbox" [(ngModel)]="allChildren" (ngModelChange)="setValuesAllChildren()">
            <span class="checkmark checkmark__all"></span>
        </label> -->
        <h4 class="font-title mr-2 mb-0">Subtemas</h4>

        <input
          class="inputCustom"
          id="searchTemaFilho"
          name="searchTemaFilho"
          type="text"
          [(ngModel)]="searchTemaFilho"
          placeholder="Buscar..."
        >
      </div>

      <div class="container__macro">
        <li class="list-group-item content__item__macro" *ngFor="let item of getTemaFilhoFiltered()">
          <div class="d-flex align-items-center item__macro">
            <label class="container__checkbox mb-0">
              <input [(ngModel)]="item.checked" type="checkbox" (ngModelChange)="check(item.id)">
              <span class="checkmark"></span>
            </label>

            <div class="d-flex justify-content-between item__macro__info w-100">
              <span>{{item.nome}}</span>
            </div>
          </div>
        </li>

        <span *ngIf="temasFilhoFilter.length == 0">Nenhum subtema</span>
      </div>
    </ul>

    <div class="col-12 d-flex flex-column flex-md-row justify-content-end pl-0 my-3">
      <label for="idTemaPrincipal" class="label-input mr-1 mb-0">Tema principal *</label>
      <select name="idTemaPrincipal" id="idTemaPrincipal" [class.invalid]="isIncorrect('idTemaPrincipal')"
        [(ngModel)]="idTemaPrincipal">
        <option value="null">Selecione *</option>
        <option *ngFor="let pai of getTemaPrincipalFiltered()" [value]="pai.id">{{pai.nome}}</option>
      </select>
    </div>

    <div class="col-12 container__button mt-2">
      <button *ngIf="!loading" (click)="salvar()" class="btn btn-primary btn-warning">Salvar</button>

      <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
        <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
      </button>
    </div>
  </div>
</div>
