<div class="p-4">
    <button (click)="bsModalRef.hide()" type="button" class="btn__close close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h3 class="font-title mb-4 b-bottom-1">Alterar foto</h3>

    <form class="d-flex flex-column align-items-center justify-content-center" [formGroup]="formGroup" novalidate
        (ngSubmit)="changeFoto()">
        <div class="div__img flexFull mb-3" *ngIf="formGroup.controls.file.value">
            <img src="{{this.formGroup.controls.file.value}}" alt="foto">
        </div>

        <div class="form-group">
            <input (change)="onFileChange($event)" type="file" name="foto" class="form-control-file" accept="image/*" id="inputfile">
        </div>

        <div class="d-flex align-items-center justify-content-center mt-2">
            <button [disabled]="formGroup.invalid" *ngIf="!loading" type="submit"
                class="btn btn-primary btn-warning">Salvar</button>

            <button *ngIf="loading" class="btn btn-primary btn-warning">
                <img height="40px" src="../../../assets/img/simple_loading.gif" alt="loading">
            </button>
        </div>
    </form>
</div>