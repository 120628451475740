<!-- TODO - Melhorar visual do loading da trilha -->
<div *ngIf="loading" class="d-flex justify-content-center">
  <app-spinner></app-spinner>
</div>

<header class="streamingHeader">
  <div class="streamingHeader__item">
    <h2 *ngIf="skill?.habilidade_titulo" class="streamingHeader__title">
      <img class="pt-1" height="30" src="assets/icons/video_outline.svg" [alt]="skill?.habilidade_titulo">
      {{skill?.habilidade_titulo}}
    </h2>
  </div>

  <div class="streamingHeader__item d-none d-lg-flex">
    <a (click)="indicarAmigo()" href="javascript:void()" class="streamingHeader__link hoverTransformImg"
      title="Indicar um amigo">
      <img height="25" src="assets/newIcons/iconShare.svg" alt="Indicar para um amigo">
      Indicar para um amigo
    </a>

    <a (click)="avaliarConteudo()" href="javascript:void()" class="streamingHeader__link hoverTransformImg"
      title="Avalie este conteúdo">
      <img height="25" src="assets/newIcons/iconRating.svg" alt="Avalie este conteúdo">
      Avalie este conteúdo
    </a>

    <!-- TODO - Implementar progresso -->
    <div class="minhaTrilhaProgress d-none">
      <div class="minhaTrilhaProgress__progress"></div>

      <div class="minhaTrilhaProgress__infos">
        <h3 class="minhaTrilhaProgress__title">
          Seu progresso
        </h3>

        <p class="minhaTrilhaProgress__text">
          05/10 concluídas
        </p>
      </div>
    </div>
  </div>
</header>

<main *ngIf="!loading" class="fadeIn">
  <section *ngIf="skill.habilidade_arquivo_principal === 'PDF'" class="streamingPDF">
    <span class="streamingPDF__badge tagBadge tagBadge--primary m-2">
      PDF
    </span>

    <div class="streamingPDF__viewContainer">
      <pdf-viewer class="streamingPDF__pdf" [src]="skill.habilidade_pdf_link" [autoresize]="true" [render-text]="true"
        [original-size]="false" [zoom-scale]="'page-fit'" [stick-to-page]="true">
      </pdf-viewer>
    </div>
  </section>

  <section *ngIf="skill.habilidade_arquivo_principal !== 'PDF'" class="streamingPlayer">
    <video
      autoplay
      #videoTask
      controls
      controlsList="nodownload"
      playsinline
      webkit-playsinline
      class="streamingPlayer__video"
      width="500"
      height="500"
    >
      <source src="{{ skill.habilidade_video_link }}" type="video/mp4" />
      Seu browser não suporta a tag de video
    </video>
  </section>

  <div class="streamingContent appContainer">
    <div class="row">
      <div class="col-12 col-lg-8 mt-3 order-md-0">
        <tabset class="tabsetCustom">
          <tab heading="Visão geral" active={true}>
            <section class="mb-5">
              <h3 class="title mb-2">Sobre essa trilha</h3>

              <p *ngIf="skill.habilidade_complemento" [innerHtml]="skill.habilidade_complemento"></p>

              <div class="my-4" *ngIf="skill.habilidade_pdf_link && skill.habilidade_arquivo_principal !== 'PDF'">
                <h4 class="title title--smaller">
                  Materiais de apoio:
                </h4>

                <div class="my-2">
                  <div class="d-flex align-items-center">
                    <button (click)="openPDF()" class="btn btnCustom btnCustom--secondary mr-2" title="Visualizar material de apoio">
                      <img height="12" src="assets/newIcons/iconPreview.svg" alt="Visualizar material de apoio">
                    </button>

                    <button (click)="downloadPDF()" class="btn btnCustom btnCustom--secondary mr-2" title="Download material de apoio">
                      <img height="12" src="assets/newIcons/iconDownload.svg" alt="Download material de apoio">
                    </button>

                    <p class="text m-0">{{getTitlePDF()}}</p>
                  </div>
                </div>
              </div>
            </section>
          </tab>

          <!-- TODO - Implementar anotações da tarefa -->
          <tab heading="Suas anotações">
            <section class="mb-5">
              <h3 class="title">
                Suas anotações nesta aula
              </h3>
              <textarea style="width: 100%" class="form-control my-3" [(ngModel)]="noteText" name="noteText" type="text" placeholder="Descreva aqui sua anotação..."></textarea>
              <div>
                <button (click)="updateNote()" *ngIf="selectedNote" type="button" class="btn__primary float-right">Salvar</button>
                <button (click)="cancel()" *ngIf="selectedNote" type="button" class="btn__primary float-right mr-2">Cancelar</button>
                <button (click)="registerNote()" *ngIf="!selectedNote" type="button" class="btn__primary float-right">Registrar</button>
              </div>
              <ul style="margin-top: 70px;">
                <div *ngFor="let note of notes" class="card flex-row p-3 mb-2 row">
                  <p class="col-10">{{note.observacao}}</p>
                  <button (click)="selectNote(note)" type="button" class="float-right col-1" style="border: none;background-color: transparent;">
                    <img class="float-right" src="../../../../assets/icons/editar.svg" alt="editar">
                  </button>
                  <button (click)="deleteNote(note)" type="button" class="float-right col-1" style="border: none;background-color: transparent;">
                    <img class="float-right" src="../../../../assets/icons/excluir.svg" alt="excluir">
                  </button>
                </div>
              </ul>
            </section>
          </tab>

          <tab heading="Tarefas">
            <section class="mb-5 row">
              <app-tasks class="mt-lg-3 col-12 col-lg-10 offset-lg-1" [skill]="skill"></app-tasks>
            </section>
          </tab>
        </tabset>
      </div>

      <section class="col-12 col-lg-4 fadeIn my-md-4 order-md-1">
        <div class="cardMain">
          <div class="cardMain__header mb-2">
            <h2 class="title">Próximos conteúdos</h2>
            <p class="subtitle subtitle--smaller colorFontSecondary">Explore suas trilhas e mantenha-se na frente</p>
          </div>

          <div class="cardMain__contentScroll cardMain__contentScroll--responsiveVertical" style="max-height: 70vh;">
            <app-minha-trilha class="cardsContainer"></app-minha-trilha>
          </div>
        </div>
      </section>
    </div>
  </div>
</main>
