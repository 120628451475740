import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {GrupoService} from 'src/app/services/grupo';
import {ToastrService} from 'ngx-toastr';
import {IColaborador} from '../colaboradores';
import {ILider} from '../grupo-lideres';

interface IColaboradorItem extends IColaborador {
  added: boolean;
}

interface ILiderColaborador extends ILider {
  nome_completo: string;
}

interface IUpdateCollection {
  id_colaborador: number;
  id_espectador: number | null;
  id_grupo: number;
  isLider: boolean;
}

@Component({
  selector: 'app-modal-adicionar-colaboradores',
  templateUrl: './modal-adicionar-colaboradores.component.html',
  styleUrls: ['./modal-adicionar-colaboradores.component.scss'],
})
export class ModalAdicionarColaboradoresComponent implements OnInit {

  recarregarDados = new EventEmitter();

  id_grupo: number;
  lider: ILiderColaborador;

  colaboradores: Array<IColaborador>;

  loading = false;
  success = false;
  qtdePages: Array<number> = [];
  page = 0;
  search = '';

  colaboradoresEditar: Array<IUpdateCollection> = [];

  constructor(
    public bsModalRef: BsModalRef,
    private grupoService: GrupoService,
    private toast: ToastrService) {
  }

  ngOnInit(): void {
    this.loadColaboradores();
  }

  close() {
    this.bsModalRef.hide();
  }

  async loadColaboradores() {
    try {
      const result = await this.grupoService.getColaboradores(this.id_grupo, this.page, this.search);

      this.colaboradores = result.colaboradores ? result.colaboradores : [];
      this.colaboradores.map((i: IColaboradorItem) => i.added = this.valueAdded(i.id_colaborador));

      this.setQtdePages(result.quantidade);
    } catch (error) {
      this.toast.error('Não foi possível buscar os colaboradores');
    }
  }

  addColaborador(lider: IColaboradorItem) {

    const aux = {
      id_colaborador: lider.id_colaborador,
      id_espectador: lider.id_espectador,
      isLider: true,
      id_grupo: this.id_grupo,
    };

    const isInclude = this.colaboradoresEditar.find(i => i.id_colaborador == lider.id_colaborador);

    if (isInclude) {
      const index = this.colaboradoresEditar.indexOf(isInclude);
      this.colaboradoresEditar.splice(index, 1);
      return;
    }
    this.colaboradoresEditar.push(aux);
  }

  async salvar() {
    try {
      this.loading = true;

      await this.grupoService.saveColaboradores({
        id_grupo: this.id_grupo,
        id_grupo_usuario: this.lider.id_usuario_lider,
        colaboradores: this.colaboradoresEditar,
      });

      this.toast.success('Salvo com sucesso!');
      this.loading = false;
      this.success = true;
      this.loadColaboradores();
      this.colaboradoresEditar = [];
      this.recarregarDados.emit(true);
    } catch (error) {
      this.loading = false;
    }
  }

  valueAdded(id_colaborador: number): boolean {
    const isInclude = this.colaboradoresEditar.find(i => i.id_colaborador == id_colaborador);
    return isInclude ? true : false;
  }

  changePage(number: number) {
    this.page = number;
    this.loadColaboradores();
  }

  setQtdePages(qtdeColaboradores: number) {
    this.qtdePages = Array.from(Array(qtdeColaboradores).keys());
  }

  getPages() {
    const listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2));
    return listPage;
  }

  buscar() {
    this.page = 0;
    this.loadColaboradores();
  }
}
