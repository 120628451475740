import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalOrcarComponent } from "src/app/common/modal-orcar";

@Component({
  selector: 'app-especialistas-carousel',
  templateUrl: './especialistas-carousel.component.html',
  styleUrls: ['./especialistas-carousel.component.scss']
})
export class EspecialistasCarouselComponent implements OnInit {
  bsModalRef: BsModalRef;
  public profissionais;
  @Input() profissionaisInput;
  @Input() titulo: string;
  profissionaisOptions: OwlOptions = {
    margin: 10,
    nav: false,
    navText: ['', ''],
    responsive: { 0: { items: 1 }, 400: { items: 2 }, 940: { items: 4 } }
  }

  constructor(
    private modalService: BsModalService,
    private router: Router,) { }

  ngOnInit(): void {
    this.titulo = !this.titulo ? 'Conheça os especialistas da Profissionais SA' : this.titulo;
  }

  getMinibio(text: String) {
    const maxString = 100
    return text.length > maxString ? text.substring(0, (maxString - 4)) + ' ...' : text;
  }

  load(slug: string) {
    this.router.navigate(['/especialistas', slug]);
  }


  orcar(slug: string) {
    this.bsModalRef = this.modalService.show(ModalOrcarComponent);
    this.load(slug)
  }
}
