import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IResSkills, ISkill, TarefasService } from 'src/app/services/tarefas';

@Component({
  selector: 'app-minha-trilha',
  templateUrl: './minha-trilha.component.html',
})

export class MinhaTrilhaComponent implements OnInit {
  concludedSkills: Array<ISkill> = []
  pendingSkills: Array<ISkill> = []
  startedSkills: Array<ISkill> = []
  answeredSkills: Array<ISkill> = []
  numberNextSkill: number
  loading: boolean = false

  constructor(
    private skillService: TarefasService,
    private toast: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getSkills()
  }

  async getSkills() {
    try {
      this.loading = true
      const result: IResSkills = await this.skillService.getSkills()
      this.pendingSkills = result.tarefasPendentes
      this.numberNextSkill = result.numeroProximaTarefa
      this.concludedSkills = result.tarefasFinalizadasAvaliadas
      this.answeredSkills = result.tarefasRespondidas
      this.startedSkills = result.tarefasIniciadas

      this.loading = false
    } catch (error) {
      this.loading = false

      this.toast.error("Falha na conexão")
    }
  }
}
