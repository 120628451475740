import { FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
  embedConfig = {
    type: "report",
    id: undefined,
    embedUrl: "https://app.powerbi.com/view?r=eyJrIjoiZTNkODRjZTgtNDZjOS00NWRkLTljOGMtM2JhNzYxYWUzNTgwIiwidCI6IjgzMjc2YzQwLTJlNWUtNGUzYS1iNzVhLWFhNTcwZGNhYjQ0ZCJ9",
    hostname: 'https://app.powerbi.com',
    accessToken: undefined,
    settings: {
      filterPaneEnabled: false,
      navContentPaneEnabled: false,
      bars: {
        actionBar: { visible: false }
      },
      zoomLevel: 3,
      pageSize: 4,
    },
}
  constructor() {}

  ngOnInit(): void {

  }
}
