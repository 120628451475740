<div class="container__dados">

    <form class="row col-12" (submit)="salvar()">

        <div class="col-12 d-flex flex-column">

            <div class="row">
                <div class="form-group col-12 col-lg-4">
                    <label class="label-input" for="banco">banco *</label>
                    <select id="banco" [class.invalid]="isIncorrect('banco')" class="form-control" name="banco"
                        placeholder="Banco *" [(ngModel)]="usuario.banco">
                        <option value="">Selecione *</option>
                        <option *ngFor="let i of bancos" [value]="i.codigo">{{i.banco}}</option>
                    </select>
                    <span class="error">{{getError('banco')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label id="agencia" class="label-input" for="agencia">Agência *</label>
                    <input [class.invalid]="isIncorrect('agencia')" class="form-control" name="agencia" type="text"
                        [(ngModel)]="usuario.agencia" placeholder="Agência *">
                    <span class="error">{{getError('agencia')}}</span>
                </div>

                <div class="form-group col-12 col-lg-4">
                    <label id="conta" class="label-input" for="conta">Conta *</label>
                    <input [class.invalid]="isIncorrect('conta')" class="form-control" name="conta" type="text"
                        [(ngModel)]="usuario.conta" placeholder="Conta *">
                    <span class="error">{{getError('conta')}}</span>
                </div>
            </div>



            <div class="row">

                <div class="d-flex div__btn__salvar">
                    <button *ngIf="!loading" type="submit" class="btn btn-primary btn-warning">Salvar</button>

                    <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
                        <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
                    </button>
                </div>
            </div>

        </div>
    </form>
</div>