import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SaNewsService {

  constructor(public http: HttpClient,) { }


  async getHome(): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL + "dashboard/sanews/"}`).toPromise();
    return result.body;
  }

  async getRecentes(): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL + "dashboard/sanews/recentes"}`).toPromise();
    return result.body;
  }

  async getByTipo(id_tipo_conteudo: number, page: number): Promise<any> {
    const limit = 9
    const result = await this.http.get<any>(`${environment.API_V2_URL + "dashboard/sanews/by-tipo/" + id_tipo_conteudo + "/" + page + "/" + limit}`).toPromise();
    return result.body;
  }

  async getCategorias(): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL + "dashboard/sanews/categorias"}`).toPromise();
    return result.body;
  }

  getBySlugV1(slug: string, cb: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('action', 'GetSANews');
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('token', '');

    const body = Object.assign({ slug: slug })
    this.http.post<any>(`${environment.API_URL}`,
      body,
      { headers: headers })
      .subscribe((data) => { cb(data) })
  }

  async getBySlug(slug: string): Promise<any> {
    const result = await this.http.get<any>(`${environment.API_V2_URL + "dashboard/sanews/" + slug}`).toPromise();
    return result.body;
  }
}
