<div class="cadastrarPalestrante" style="max-width: 600px">
  <section>
    <h2>Cadastrar usuário</h2>
    <div class="formFields">
      <div class="field">
        <label>Nome:</label>
        <input type="text" [(ngModel)]="nome" />
      </div>
      <div class="field">
        <label>Sobrenome:</label>
        <input type="text" [(ngModel)]="sobrenome" />
      </div>
      <div class="field">
        <label>E-mail:</label>
        <input type="text" [(ngModel)]="email" />
      </div>
      <div class="field">
        <label>Telefone:</label>
        <input type="text" [(ngModel)]="telefone" [mask]="'(00) 0 0000-0000'" [validation]="true" />
      </div>
      <div class="field">
        <label>Perfil:</label>
        <select [(ngModel)]="perfil">
          <option selected disabled value="">-- Selecione o perfil --</option>
          <option value="admin">ADMIN</option>
          <option value="curadora">CURADOR A</option>
          <option value="curadorb">CURADOR B</option>
          <option value="curadorc">CURADOR C</option>
          <option value="avaliador">AVALIADOR</option>
          <option value="gestorp">GESTOR DE PALESTRANTES</option>
          <option value="gestor">GESTÃO</option>
          <option value="cs">CS</option>
        </select>
      </div>
    </div>
    <span>OBS: Será cadastrado com a senha padrão: <b>BzbE4p6lpVO4</b></span>
    <div class="actions">
      <!-- <button (click)="voltar()">Voltar</button> -->
      <button class="cadastrar" (click)="cadastrar()">Cadastrar</button>
    </div>
  </section>
</div>
