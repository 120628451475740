import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { HomeComponent } from './home';
import { ProximasPalestrasComponent } from './proximas-palestras';
import { AgendaComponent } from './agenda';
import { PalestraComponent } from './palestra';
import { ModalPalestraIncompanyComponent } from './palestra/modal-palestra-incompany';

import { ProgressBarModule } from "angular-progress-bar";

import { ExportComponentsModule } from 'src/app/common/export-components';
import { ListConteudoComponent } from './agenda/list-conteudo';
import { CertificadosComponent } from './certificados';
import { PalestrasGravadasComponent } from './palestras-gravadas';
import { TrilhaPersonalizadaComponent } from './trilha-personalizada';
import { PdiListComponent } from './trilha-personalizada/pdi-list';
import { PdiExecutionComponent } from './trilha-personalizada/pdi-execution/pdi-execution.component';

import { MinhaTrilhaPageComponent } from './minha-trilha-page/minha-trilha-page.component';
import { MinhaTrilhaComponent } from './minha-trilha/minha-trilha.component';
import { MinhaTrilhaStreamingComponent } from './minha-trilha-streaming/minha-trilha-streaming.component';

import { TasksComponent } from './tasks/tasks.component';
import { TasksItemComponent } from './tasks-item/tasks-item.component';
import { CardMinhaTrilhaComponent } from './card-minha-trilha/card-minha-trilha.component';

@NgModule({
  declarations: [
    HomeComponent,
    ProximasPalestrasComponent,
    AgendaComponent,
    PalestraComponent,
    ModalPalestraIncompanyComponent,
    ListConteudoComponent,
    CertificadosComponent,
    PalestrasGravadasComponent,
    TrilhaPersonalizadaComponent,
    PdiListComponent,
    PdiExecutionComponent,
    MinhaTrilhaComponent,
    MinhaTrilhaPageComponent,
    MinhaTrilhaStreamingComponent,
    TasksComponent,
    TasksItemComponent,
    CardMinhaTrilhaComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ExportComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    CarouselModule,
    ProgressBarModule,
    TabsModule.forRoot(),
  ]
})
export class EspectadorModule { }
