import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wizard-calculadora',
  templateUrl: './wizard-calculadora.component.html',
  styleUrls: ['./wizard-calculadora.component.scss']
})
export class WizardCalculadoraComponent implements OnInit {

  @Input() selected: number;

  steps = [
    {
      id: 1,
      name: "Passo 1",
      description: "Informações Básicas"
    },
    {
      id: 2,
      name: "Passo 2",
      description: "Cronograma"
    },
    {
      id: 3,
      name: "Passo 3",
      description: "Finalização"
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
