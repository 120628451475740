import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface IPlan {
  descricao_curta_html: string
  descricao_html: string
  details_json: string
  id: number
  identificador: string
  nome: string
  order: number
  planousuariostatus: number
  publico_alvo: "ESPECTADORES" | "CONTRATANTES" | "PALESTRANTES"
  size: string
  status_plano: "0" | "1"
  subtitulo: string
  valor: number
}

export interface IAssignPlan {
  id_usuario: any
  id_plano: number
}

export interface IPayment {
  id_usuario: number,
  card_name: string,
  card_number: string,
  security_code: string,
  expiration_month: string,
  expiration_year: string,
  amount: number
}

@Injectable({
  providedIn: 'root'
})
export class PlanosService {

  constructor(public http: HttpClient) { }

  async getPlans(publico: string): Promise<Array<IPlan>> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/planos/${publico}`).toPromise();
    return result.body;
  }

  async getById(id: number): Promise<IPlan> {
    const result = await this.http.get<any>(`${environment.API_V2_URL}plano/id/${id}`).toPromise();
    return result.body;
  }

  async assignPlan(form: IAssignPlan): Promise<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/json').set("authorization", localStorage.getItem("token"));
    let body = JSON.stringify(form)
    const result = await this.http.post<any>(`${environment.API_V2_URL}plano/assinar-plano`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getnetTransaction(payment: IPayment): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = payment

    const result = await this.http.post<any>(`${environment.API_V2_URL}getnet/oper/transaction`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async increaseLectureAvaiableInPlan(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.put<any>(`${environment.API_V2_URL_DEFAULT}palestras/increase-lecture-available`, {}, { headers: headers }).toPromise();
    return result.body;
  }
}
