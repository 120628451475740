<div class="container-fluid px-4 px-md-5" *ngIf="!loading">
  <div class="row mt-4">
    <div class="col-12 container__conteudo__default" style="height: 100%;">
      <h5 class="font-title mt-3">Palestrante Exclusivo</h5>

      <div class="row">
        <div class="form-group col">
          <label>Plano de fundo personalizado</label>
          <input
            id="file"
            type="file"
            name="file"
            accept="image/*"
            (change)="onFileChange($event)"
          />

          <div class="container__textarea mt-2">
            <label>Texto do email de certificado</label>
            <angular-editor
            [(ngModel)]="exclusivo.emailContent"
            [config]="config"
          ></angular-editor>
          </div>

          <div class="mt-2">
            <label>Página de destino pós resposta do formulário (QRCode)</label>
            <input type="text" [(ngModel)]="exclusivo.redirectPage" />
            <span>Inserir links no formato: https://dominio.com</span>
          </div>

          <div>
            <button class="btn__add" (click)="setAction()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  style="
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  "
  *ngIf="loading"
>
<img
  src="assets/img/psaLoading.svg"
  alt="Carregando"
  title="Carregando"
/>
</div>
