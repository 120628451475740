import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PalestrantesService } from 'src/app/services/palestrantes';
import { ModalAlterarFotoComponent } from '../modal-alterar-foto';

@Component({
  selector: 'app-foto-perfil',
  templateUrl: './foto-perfil.component.html',
  styleUrls: ['./foto-perfil.component.scss'],
})
export class FotoPerfilComponent implements OnInit {
  bsModalRef: BsModalRef;

  @Input() foto: string;
  @Output() onChangePhoto = new EventEmitter();

  userId: number;

  constructor(
    private modalService: BsModalService,
    private palestrantesService: PalestrantesService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (!this.foto) this.foto = null;

    this.activatedRoute.params.subscribe((params) => {
      this.userId = params?.id;

      if (isNaN(this.userId)) {
        this.userId = null;
      }
    });
  }

  alterarFoto() {
    this.bsModalRef = this.modalService.show(ModalAlterarFotoComponent, {
      backdrop: 'static',
      keyboard: false,
    });

    let newSubscriber = this.modalService.onHide.subscribe(() => {
      newSubscriber.unsubscribe();
      if (this.bsModalRef.content.success) this.getDados();
      // console.log('aaaaphoto', this.foto);
      if (this.bsModalRef.content.success) this.onChangePhoto.emit(this.foto);
    });
  }

  async getDados() {
    let usuario;

    console.log('user', this.userId);
    if (this.userId) {
      const res = await this.palestrantesService.getDadosByUserId(this.userId);
      this.foto = res.usuario.photo;

      // usuario.photo = this.foto;

      // localStorage.setItem('usuario', JSON.stringify(usuario));
    } else {
      usuario = JSON.parse(localStorage.getItem('usuario'));
      console.log({ usuario });
      const res = await this.palestrantesService.getDadosByUserId(usuario.id);
      this.foto = res.usuario.photo;

      usuario.photo = this.foto;

      localStorage.setItem('usuario', JSON.stringify(usuario));
    }
  }
}
