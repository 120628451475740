import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { CheckValidacaoSenhaComponent } from 'src/app/common/export-components/check-validacao-senha';
import { getPathHome } from 'src/app/controle-perfil';
import { UserService } from '../../services/user'

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.scss']
})
export class RedefinirSenhaComponent implements OnInit, OnDestroy {

  token: any;
  senha: any;
  confirmSenha: any = '';
  loading: boolean = false;
  mensagem: any;

  showPass: boolean = false;
  showPassConfirm: boolean = false;

  @ViewChild("validacaoPass", { static: true }) checkValidacaoSenhaComponent: CheckValidacaoSenhaComponent;

  constructor(private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private appComponent: AppComponent) { }

  ngOnInit(): void {
    if (localStorage.getItem("token")) this.router.navigate(['perfil'])
    this.route.params.subscribe(async (params) => {
      if (params && params.token) this.token = params.token;
    });

  }

  ngOnDestroy() {
    this.appComponent.setIsRouteLogout(false)
  }

  async redefinir(senha: any, confirmSenha: any) {

    if (senha != confirmSenha) {
      this.mensagem = "Senha e confirmação de senha não coincidem.";
      return;
    }

    let senhaRegex = /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})\S{8,20}$/;
    const valida = senhaRegex.test(senha)
    if (!valida) {
      this.mensagem = "Sua senha deve conter no mínimo 8 e no máximo 20 caracteres. Deve conter também letras e números, sendo ao menos uma letra maiuscula.";
      return;
    }

    let body = {
      senha: senha,
      token: this.token
    }

    try {
      await this.userService.redefinirSenha(body);
      this.toastService.success("Senha alterada com sucesso!")
      this.appComponent.setIsRouteLogout(false)
      this.router.navigate([getPathHome()])
    } catch (e) {
      this.mensagem = e.error.mensagem;
    }

  }

  setShow() {
    this.showPass = !this.showPass
  }

  setShowConfirm() {
    this.showPassConfirm = !this.showPassConfirm
  }

  checkPasswordRule() {
    this.checkValidacaoSenhaComponent.checkPasswordRule()
  }
}
