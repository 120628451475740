import { Component, Input, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { GestaoService } from 'src/app/services/Gestao/gestao.service';

import { PalestrantesService } from 'src/app/services/palestrantes';


@Component({
  selector: 'app-gestao-admin',
  templateUrl: './gestao-admin.component.html',
  styleUrls: ['./gestao-admin.component.scss'],
})
export class GestaoAdminComponent implements OnInit {
  @Input() palestrante: any;
  repositories: any = [];
  isEdit: boolean = false;

  gestaoData: any = {
    cnpj: '',
    formatoFaturas: '',
    overOrBv: '',
    posPay: false,
    nomeCompleto: '',
    rg: '',
    cpf: '',
    dataNascimento: '',
    cidade: '',
    tipoViajem: '',
    acompanhante: '',
    minuta: '',
    preferencias: '',
    minibio: '',
    reuniaoPreBriefing: false,
    restricaoCliente: false,
    notas: '',
    historico: '',
    photosDrive: '',
  }

  files: any = [];

  loading: boolean = false;

  constructor(
    private palestrantesService: PalestrantesService,
    private gestaoService: GestaoService,
    private toast: ToastrService,
  ) { }

  ngOnInit(): void {
    this.getGestaoData();
  }

  async getGestaoData() {
    try {
      const [res] = await this.gestaoService.getList(this.palestrante.id);

      this.gestaoData = {
        cnpj: res.cnpj,
        formatoFaturas: res.formato_faturas,
        overOrBv: res.over_or_bv,
        posPay: res.pos_pay,
        nomeCompleto: res.nome_completo,
        rg: res.rg,
        cpf: res.cpf,
        dataNascimento: moment(res.data_nascimento).utc().format('YYYY-MM-DD'),
        cidade: res.cidade,
        tipoViajem: res.tipo_viajem,
        acompanhante: res.acompanhante,
        minuta: res.minuta,
        preferencias: res.preferencias,
        minibio: res.minibio,
        reuniaoPreBriefing: res.reuniao_pre_briefing,
        restricaoCliente: res.restricao_cliente,
        notas: res.notas,
        historico: res.historico,
        photosDrive: res.photosDrive,
      }

    } catch (err) {
      this.toast.error('Algo deu errado ao recuperar as informações do palestrante, tente novamente mais tarde!');
    }
  }

  async saveForm() {
    this.loading = true;
    try {
      const res = await this.gestaoService.insertUpdate(this.palestrante.id, this.gestaoData);
      console.log(res);
      this.loading = false;
      window.location.reload();
    } catch (err) {
      this.loading = false;
      this.toast.error('Algo deu errador ao salvar as informações do palestrante, tente novamente mais tarde!');
    }
  }

  onFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {

      for (let i = 0; i < event.target.files.length; i++) {
        console.log(event.target.files[i]);

        var vFileTobeRead = event.target.files[i];
        var vFileReader = new FileReader();

        vFileReader.onload = () => {
          const fileSize = event.target.files[i].size / 1024 / 1024 / 1024;

          if (fileSize > 4) {
            event.target.files = null;
            event.target.value = null;

            const message = 'O arquivo selecionado ultrapassa o tamanho máximo de 4GB!';
            this.toast.error('Ocorreu um erro ao salvar a imagem');
          }
          else {
            const [file] = event.target.files;
            this.files.push(event.target.files[i]);
          }
        }

        vFileReader.readAsDataURL(vFileTobeRead);
      }
    }
  }
}
