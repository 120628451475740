import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropostasService {

  constructor(public http: HttpClient) {}

  async getProposals(userId: number) {
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}curador/${userId}/proposals`)
    .toPromise();
    return result.body;
  }

  async getAllProposals() {
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}curador/proposals`)
    .toPromise();
    return result.body;
  }

  editProposal(proposalId: number, body) {
    return this.http.put(`${environment.API_V2_URL_DEFAULT}curador/proposals/${proposalId}`, body);
  }

  getCuradores() {
    return this.http.get(`${environment.API_V2_URL_DEFAULT}curador/names`);
  }
}
