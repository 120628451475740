import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProgressBarModule } from "angular-progress-bar";
import { CKEditorModule } from 'ckeditor4-angular';

import { HabilidadesComponent } from './habilidades';
import { CompetenciasComponent } from './competencias';
import { FormCompetenciaComponent } from './competencias/form-competencia';
import { ExportComponentsModule } from 'src/app/common/export-components';
import { WizardComponent } from './wizard';
import { HabilidadeComponent } from './habilidade';
import { PerguntasComponent } from './habilidade/perguntas';
import { TipoConteudoComponent } from './tipo-conteudo';
import { FormPerguntaComponent } from './habilidade/perguntas/form-pergunta/form-pergunta.component';


@NgModule({
  declarations: [
    HabilidadesComponent,
    CompetenciasComponent,
    FormCompetenciaComponent,
    WizardComponent,
    HabilidadeComponent,
    PerguntasComponent,
    TipoConteudoComponent,
    FormPerguntaComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    ExportComponentsModule,
    NgSelectModule,
    ProgressBarModule,
    CKEditorModule,
  ]
})
export class MeusConteudosModule { }
