import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ContratanteService } from '../../../services/contratante';
import { UserService } from '../../../services/user';
import { GrupoService } from '../../../services/grupo';
import { ToastrService } from 'ngx-toastr';
import { ICargo, ILider } from '../grupo-lideres'
import { IColaborador } from '../colaboradores'

@Component({
  selector: 'app-modal-form-colaborador',
  templateUrl: './modal-form-colaborador.component.html',
  styleUrls: ['./modal-form-colaborador.component.scss']
})
export class ModalFormColaboradorComponent implements OnInit {

  @Input('colaborador') colaborador: IColaborador;
  loading: boolean = false;
  success: boolean = false;

  cargos: Array<ICargo> = [];
  grupos: Array<any> = [];

  emailErr: string = null;

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private contratanteService: ContratanteService,
    private grupoService: GrupoService,
    private toast: ToastrService,) { }

  ngOnInit(): void {
    this.getStartInfo()
    if (this.colaborador.id_cargo && this.colaborador.id_cargo != 6) {
      this.colaborador.cargo_nome = ""
    } else if (!this.colaborador.id_cargo) {
      this.colaborador.id_cargo = 6
    }
  }

  async getStartInfo() {
    const res = await this.userService.getCargos()
      .catch(e => { return; });
    const resGrupo = await this.grupoService.getGrupos()
      .catch(e => { return; });

    if (res && res.length) this.cargos = res;
    if (resGrupo && resGrupo.length) this.grupos = resGrupo;
    this.cargos.unshift({ id: null, nome: "", descricao: "Selecione um cargo", ativo: '' });
    this.grupos.unshift({ id: null, nome: "Selecione um grupo" });
  }

  async salvarColaborador() {
    this.emailErr = null
    try {
      if (!this.validations()) return;

      this.cargos.forEach(i => { if (i.id == this.colaborador.id_cargo && !(this.colaborador.id_cargo == 6)) this.colaborador.cargo_nome = i.descricao })

      if (!this.colaborador.id_colaborador) {

        const usuario = JSON.parse(localStorage.getItem("usuario"))
        this.colaborador.id_contratante = usuario.id_contratante

        await this.contratanteService.insertColaborador(Object.assign(this.colaborador))

      } else {
        await this.contratanteService.updateColaborador(Object.assign(this.colaborador))
      }

      this.toast.success("Colaborador salvo com sucesso!")
      this.cancelar();
      this.success = true

    } catch (e) {
      const err = e.error.message;

      if (err.isAvailable) {
        this.emailErr = "Já existe usuário com este e-mail!"
        this.toast.error("Ocorreu um erro ao salvar", err.message ? err.message : err)
        return
      } else if (err.isAvailable == false) {
        this.toast.error(
          err.message ? err.message : err,
          "Caso tenha certeza deste e-mail. Entre em contato: contato@profissionaissa.com"
        )
        return
      }
      this.toast.error("Ocorreu um erro ao salvar", err.message ? err.message : err)
    }
  }

  validations(): boolean {
    if (!this.colaborador.email) {
      this.toast.error("Informe o e-mail!")
      return false
    }

    if (!this.colaborador.nome_completo) {
      this.toast.error("Informe o nome completo!")
      return false
    }

    if (!this.colaborador.cpf) {
      this.toast.error("Informe o cpf!")
      return false
    }

    if (!(this.colaborador.id_cargo > 0)) {
      this.toast.error("Informe o cargo!")
      return false
    }

    if (this.colaborador.id_cargo == 6 && !this.colaborador.cargo_nome) {
      this.toast.error("Informe o cargo!", "Cargo selecionado Outro, descreva o cargo.")
      return false
    }
    return true
  }

  cancelar() {
    this.bsModalRef.hide();
  }

  async sendInvite() {
    try {
      await this.contratanteService.emailInviteCollaborator(this.colaborador.email)
      this.toast.success("Solicitação enviada para " + this.colaborador.email)
    } catch (error) {
      console.log(error);
      this.toast.error("Falha ao enviar e-mail", error.error.message)
    }
  }
}
