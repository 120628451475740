import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { PalestrantesService } from 'src/app/services/palestrantes';

export interface IFormacao {
  cidade: string
  cursando: number
  data_fim: string
  data_inicio: string
  estado: string
  id: number
  local: string
  palestrantes_id: number
  titulo: string
  data_formatada: string
}
export interface IExperiencia {
  atual: number
  cargo: string
  cidade: string
  data_fim: string
  data_inicio: string
  descricao: string
  empresa: string
  estado: string
  id: number
  pais: string
  data_formatada: string
}
export interface IDepoimento {
  data: string
  descricao: string
  empresa: string
  nome: string
  pais: string
}
export interface IAssunto {
  descricao: string
  foto: string
  id: number
  id_assunto_pai: number
  item_pai: number
  nome: string
}

@Component({
  selector: 'app-meu-perfil',
  templateUrl: './meu-perfil.component.html',
  styleUrls: ['./meu-perfil.component.scss']
})
export class MeuPerfilComponent implements OnInit, OnDestroy {
  loading: boolean = false

  nota: Array<any> = []
  palestrante: any = {
    nome_palestrante: "",

  };

  ministradas: Array<any> = []
  disponiveis: Array<any> = []
  formacoes: Array<IFormacao> = []
  experiencias: Array<IExperiencia> = []
  depoimentos: Array<IDepoimento> = []
  assuntos: Array<IAssunto> = []
  

  constructor(
    private palestrantesService: PalestrantesService,
    private toast: ToastrService,
    private title: Title
  ) { }

  ngOnInit(): void {
    this.getPerfil()
    this.title.setTitle("Meu perfil - Dashboard")
  }

  ngOnDestroy(): void {
    this.title.setTitle("Dashboard")
  }

  async getPerfil() {
    try {
      this.loading = true
      const res = await this.palestrantesService.getMeuPerfil()
      this.palestrante = res.palestrante
      this.disponiveis = res.palestrasDisponiveis
      this.ministradas = res.palestrasMinistradas
      this.formacoes = res.formacao
      this.depoimentos = res.depoimentos
      this.experiencias = res.experiencia
      this.assuntos = res.assuntos

      this.loading = false
      this.setNota()
    } catch (error) {
      this.loading = false
      console.log(error);
      
      this.toast.error("Não foi possível carregar seu perfil")
    }
  }

  setNota() {
    const auxNota = this.palestrante.pontuacao
    let size = Math.round(auxNota)
    size = auxNota > size ? size + 1 : size

    this.nota = Array.from(Array(size).keys())
    this.nota[0] = 1
    let lastValue = auxNota - this.nota[this.nota.length - 1]
    this.nota[this.nota.length - 1] = lastValue
  }

  openSite() {
    window.open(
      this.palestrante.id_nossa_empresa == 4 ?
      `https://www.profissionaissa.com/palestrante/${this.palestrante.slug}`
      :
      `https://base.profissionaissa.com/palestrante/${this.palestrante.slug}`
      , "_blank")
  }

  msgEmDev() {
    this.toast.info('Em desenvolvimento.', 'Em breve traremos novidades.')
  }

  async sendPDFEmailPerfil() {
    try {
      await this.palestrantesService.sendPDFPalestrante(this.palestrante.slug, this.palestrante.id_nossa_empresa)
      this.toast.success("PDF enviado para seu e-mail", "Fique atento a sua caixa de entrada e spam")
    } catch (error) {
      this.toast.error("Não foi possível enviar o email")
    }
  }
}
