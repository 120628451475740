<div class="cardsContainer w-100">
  <section [class.active]="tabTask === 1" class="cardHidden">
    <div class="cardCustom cardCustom--responsive cardCustom--destaque cardsContainer__item">
      <div class="cardCustom__item">
        <img
          height="120"
          src="assets/newIcons/iconTask.png"
          alt="Avaliações Pendentes"
        >
      </div>

      <div class="cardCustom__contentBox">
        <h3 class="cardCustom__title">
          Vamos reforçar o que aprendemos?
        </h3>

        <p class="cardCustom__text">
          Complete as atividades sobre o tema que acabou de aprender.
        </p>

        <a
          (click)="startQuiz()"
          class="btnCustom btnCustom--primary px-4 hoverTransform"
          title="Clique para inciar a tarefa"
        >
          Iniciar Tarefa
        </a>
      </div>
    </div>

    <div class="mt-2">
      <p class="text colorFontTertiary">
        A avaliação é composta por 3 questões de múltipla escolha e uma questão de dissertação que será
        revisada por um Tutor.
      </p>
      <p class="text colorFontTertiary">
        Para ter um bom rendimento, antes de realizar o teste, recomendamos a
        visualização do vídeo, Livro da Disciplina, sobretudo os materiais contidos em cada conteúdo.
      </p>
      <p class="text colorFontTertiary">
        Após concluir o teste, um tutor fará uma avaliação das respostas não subjetivas. Desejamos uma ótima
        tarefa!
      </p>
    </div>
  </section>

  <!-- TODO - Otimizar renderização do componente tarefa Item -->
  <section [class.active]="tabTask === 2" class="questionsContainer cardHidden">
    <h3 *ngIf="skillTitle" class="title title--smaller colorFontSecondary mb-4">
      Tarefas sobre: {{skillTitle}}
    </h3>

    <!-- <app-tasks-item #questionAnswer #questionAnswer></app-tasks-item> -->

    <div class="questionsContainer__buttons">
      <button
        (click)="nextQuestion()"
        class="btnCustom btnCustom--success mr-1 hoverTransform"
        title="Prosseguir para a próxima questão"
        type="button"
      >
        {{((this.indexSelected + 1) === this.questions.length) ? 'Anterior' : 'Prosseguir'}}
      </button>

      <button
        *ngIf="((this.indexSelected + 1) === this.questions.length) && !this.taskCompleted"

        (click)="concludeSkill()"
        class="btnCustom btnCustom--success mr-1 hoverTransform"
        title="Concluir tarefa"
        type="button"
      >
        Terminar
      </button>

      <p *ngIf="questions" class="textDefault colorFontSecondary mb-0">
        {{addZeroLeft(indexSelected + 1)}} de {{addZeroLeft(questions.length)}}
      </p>
    </div>
  </section>

  <section [class.active]="tabTask === 3" class="cardHidden w-100">
    <div *ngIf="noQuestions" class="cardCustom cardCustom--responsive cardsContainer__item">
      <div class="align-items-center justify-content-center text-center w-100">
        <img height="30" src="assets/newIcons/iconSmile.png" alt="emoticon sorrindo">

        <h3 class="cardCustom__title mb-0 mt-1">
          Essa trilha não possui tarefas.
        </h3>
      </div>
    </div>

    <div *ngIf="!isApproved && !noQuestions" class="cardCustom cardCustom--responsive cardsContainer__item hoverTransformImg">
      <div class="cardCustom__item">
        <img
          height="60"
          src="assets/newIcons/iconRevisaoTarefa.png"
          alt="aguardar revisão tarefa"
        >
      </div>

      <div class="cardCustom__contentBox">
        <h3 class="cardCustom__title">
          Sua tarefa está sendo revisada por um Tutor.
        </h3>

        <p class="cardCustom__text">
          Geralmente isso leva menos de 6 horas.
        </p>
      </div>
    </div>

    <div *ngIf="isApproved && !noQuestions" class="cardCustom cardCustom--responsive cardsContainer__item">
      <div class="align-items-center justify-content-center text-center w-100">
        <h3 class="cardCustom__title">
          Tarefa revisada com sucesso!
        </h3>

        <p class="cardCustom__text">Confira o resultado:</p>

        <p class="cardCustom__text my-4">
          Sua Nota ficou: <img class="mx-1" height="20" src="assets/newIcons/iconSmile.png" alt="emoticon sorrindo"> <span class="colorSuccess"> Acima da média</span>
        </p>

        <!-- TODO - Implementar não alcançou a média -->
        <div class="my-4 d-none">
          <p class="cardCustom__text colorWarning">
            Você não alcançou a nota mínima de 5, neste momento é possível repetir a sua tarefa.
          </p>

          <a
            [routerLink]="['/desenvolvimento/habilidade/tarefa', skill.id_habilidade]"
            class="btnCustom btnCustom--primary px-4 hoverTransform m-auto"
            title="Clique para inciar a tarefa"
          >
            Fazer Tarefa
          </a>
        </div>

        <div *ngIf="skill.resposta.feedbackTutor" class="boxInfo my-5">
          <h4 class="boxInfo__title">Mensagem do tutor:</h4>
          <p class="boxInfo__text">
            {{skill.resposta.feedbackTutor}}
          </p>
        </div>

        <a
          href="javascript:void()"
          title="Clique para ver as questões respondidas"
          (click)="setTabStatus(2)"
        >
          Ver questões
        </a>
      </div>
    </div>
  </section>

  <section [class.active]="tabTask === 4" class="cardHidden w-100">
    <div class="cardCustom cardCustom--responsive cardsContainer__item">
      <div class="align-items-center justify-content-center text-center w-100">
        <img height="30" src="assets/newIcons/iconSmile.png" alt="emoticon sorrindo">

        <h3 class="cardCustom__title mb-0 mt-1">
          Essa trilha não possui tarefas.
        </h3>
      </div>
    </div>
  </section>
</div>

