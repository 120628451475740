import { Routes } from '@angular/router';

import { HomeComponent } from './home';
import { MeuPerfilComponent } from './meu-perfil';
import { AgendaComponent } from './agenda';
import { EventoComponent } from './evento/evento.component';
import {
  CacheComponent,
  DadosComponent,
  DepoimentosComponent,
  ExperienciaComponent,
  FormacaoComponent,
  FormPalestraDisponivelComponent,
  PalestrasDisponiveisComponent,
  TemasComponent
} from './perfil-completo';
import { MeuPerfilNewComponent } from './meu-perfil-new';
import { RegistrationComponent } from '../registration/registration.component';


export const PalestranteRoutes: Routes = [
  {
    path: 'palestrante/home',
    component: HomeComponent
  },
  {
    path: 'palestrante/meu-perfil-old',
    component: MeuPerfilComponent
  },
  {
    path: 'palestrante/meu-perfil',
    component: MeuPerfilNewComponent
  },
  {
    path: 'palestrante/agenda',
    component: AgendaComponent
  },
  {
    path: 'palestrante/eventos',
    component: AgendaComponent
  },
  {
    path: 'palestrante/evento/:id',
    component: EventoComponent
  },
  {
    path: 'palestrante/perfil/dados',
    component: DadosComponent
  },
  {
    path: 'palestrante/perfil/temas',
    component: TemasComponent
  },
  {
    path: 'palestrante/perfil/produtos',
    component: PalestrasDisponiveisComponent
  },
  {
    path: 'palestrante/perfil/depoimentos',
    component: DepoimentosComponent
  },
  {
    path: 'palestrante/perfil/experiencia',
    component: ExperienciaComponent
  },
  {
    path: 'palestrante/perfil/formacao',
    component: FormacaoComponent
  },
  {
    path: 'palestrante/perfil/cache',
    component: CacheComponent
  }
];
