<div class="container__conteudo col-12 pl-lg-5">

    <div class="close pull-right ml-3" aria-label="Close" (click)="bsModalRef.hide()">
        <span style="cursor: pointer; font-size: 30px;" aria-hidden="true">&times;</span>
    </div>

    <div class="d-lg-flex justify-content-between align-items-center">
        <h4 class="font-title">Selecione os tutores para o grupo</h4>

        <div class="container__search col-12 col-lg-5 d-flex">
            <img src="assets/icons/search-black.svg" alt="ícone pesquisar">
            <input class="container__search__input form-group m-0 border-0 bg-transparent" placeholder="Buscar..."
                type="text" name="search" [(ngModel)]="search" (ngModelChange)="buscar()">
        </div>

        <div class="form-group d-flex align-items-center mb-0">
            <label class="label-input mb-0 mr-1" for="limitPage">Paginação</label>
            <select class="form-control select__default" (change)="ngOnInit()" name="limitPage" [(ngModel)]="limitPage">
                <option *ngFor="let i of selectLimits" [value]="i">{{i}}</option>
            </select>
        </div>
    </div>

    <div class="content__cabecalho text-left d-flex p-0 mt-1">
        <label class="flex03">
            <label class="container__checkbox mb-0">
                <input type="checkbox" (change)="selectAll($event)">
                <span class="checkmark"></span>
            </label>
            <img height="13px" src="assets/icons/info.svg" alt="info" title="Seleciona todos os tutores DESTA página"
                class="ml-1">
        </label>
        <label class="font-title text-uppercase flex2">NOME</label>
        <label class="font-title text-uppercase flex2">E-MAIL</label>
        <label class="font-title text-uppercase flex1">SETOR</label>
        <label class="font-title text-uppercase flex1">CARGO</label>
    </div>

    <ul>
        <li [ngClass]="{ added : lider.responsavel_grupo_bool}" *ngFor="let lider of lideres">

            <div class="d-flex content__list__item">
                <div class="flex03">
                    <label class="container__checkbox mb-0">
                        <input type="checkbox" (change)="addLider(lider)" [(ngModel)]="lider.responsavel_grupo_bool">
                        <span class="checkmark"></span>
                    </label>
                </div>

                <div class="flex2 w-100 item__nome">
                    <input class="input__disable" type="text" [(ngModel)]="lider.nome_completo" disabled="disabled">
                </div>

                <div class="flex2">
                    <input class="input__disable" type="text" [(ngModel)]="lider.email" disabled="disabled">
                </div>

                <div class="flex1">
                    <input class="input__disable" type="text" [(ngModel)]="lider.setor" disabled="disabled">
                </div>

                <div class="flex1">
                    <input class="input__disable" type="text" [(ngModel)]="lider.cargo_nome" disabled="disabled">
                </div>

            </div>

        </li>
    </ul>

    <p *ngIf="lideres?.length == 0">Nenhum lider cadastrado.</p>

    <div class="d-flex flex-row-reverse justify-content-between align-items-end mt-4">

        <button (click)="salvar()" class="btn__primary">SALVAR</button>

        <nav *ngIf="qtdePages?.length > 1" aria-label="Page navigation example">
            <ul class="pagination">
                <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                    <a class="page-link">Anterior</a>
                </li>
                <li (click)="changePage(i)" class="page-item" *ngFor="let number of qtdePages, let i = index">
                    <a class="page-link">{{i+1}}</a>
                </li>
                <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                    <a class="page-link">Próxima</a>
                </li>
            </ul>
        </nav>
    </div>

</div>

<div *ngIf="loading" class="loading flexFull">
    <img src="assets/img/simple_loading.gif" alt="loading">
</div>