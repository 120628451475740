import { Component, OnInit, Input } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-depoimentos',
  templateUrl: './depoimentos.component.html',
  styleUrls: ['./depoimentos.component.scss']
})
export class DepoimentosComponent implements OnInit {
  @Input() depoimentos = [];
  @Input() palestrante: string;

  customOptionsDepoimentos: OwlOptions = {
    margin: 20,
    nav: false,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      }
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

  getNome(nome: string) {
    const nomesplit = nome.split(" ");
    return nomesplit[0] + " " + (nomesplit.length > 1 ? nomesplit[nomesplit.length - 1] : "");
  }

}
