<div class="content">

    <section class="section__cabecalho">
        <div class="container_fluid">
            <div>
                <app-wizard-calculadora [selected]="2"></app-wizard-calculadora>
            </div>
        </div>

        <div class="container_fluid content__cabecalho col-12 col-lg-8 offset-lg-2 p-3">
            <h2>Preencha para simular o investimento</h2>
            <p>Ao preencher as linhas com as suas palestras, treinamentos e demais eventos, você terá acesso a simulação
                de cada investimento de forma separada. Depois receberá automaticamente a indicação de especialistas
                para cada uma delas. Assim você pode visualizar cada evento de forma independente e ter o seu
                planejamento detalhado.</p>
        </div>
    </section>



    <div class="container__content col-12">
        <ul class="list-group">
            <li class="list-group-item table__calc__header border-0">
                <span class="title__mobile">Tabela de palestras</span>
                <div class="title__web d-lg-flex">
                    <span class="col-2 pr-0">Título</span>
                    <span class="col-1 pr-0">Formato</span>
                    <span class="col-1 pr-0">HORAS</span>
                    <span class="col-1 pr-0">Qtde. de Público</span>
                    <span class="col-2 pr-0">Especialista</span>
                    <span class="col-1 pr-0">Tema</span>

                    <div class="col-4 pr-0 row">
                        <span class="col-8 text-center">Investimento</span>
                        <span class="col-4 text-center">ESPECIALISTAS</span>
                    </div>
                </div>
            </li>
            <li class="list-group-item" *ngFor="let orcamento of orcamentos, let i = index">


                <div class="item__info row pt-3 pb-4 p-lg-0">
                    <div class="col-12 col-lg-2 pr-0 d-flex justify-content-between">
                        <input maxlength="50" type="text" [placeholder]="getPlaceholderTitle(i)"
                            [disabled]="isEditable(i)" [(ngModel)]="orcamento.titulo">
                    </div>

                    <div class="col-6 col-lg-1 pr-0">
                        <label class="title__mobile" for="porte">Formatos</label>
                        <select (change)="getDuracoes(i)" class="form-control" name="formatos"
                            [disabled]="isEditable(i)" [(ngModel)]="orcamento.formato">
                            <option value="0">Selecione *</option>
                            <option *ngFor="let formato of formatos" [value]="formato.id">
                                {{formato.nome_formato}}</option>
                        </select>
                    </div>

                    <div class="col-6 col-lg-1 pr-lg-0">
                        <label class="title__mobile" for="horas">Horas</label>
                        <select (change)="refreshResult(i)" class="form-control" name="horas" [disabled]="isEditable(i)"
                            [(ngModel)]="orcamento.horas">
                            <option value="">Selecione *</option>
                            <option *ngFor="let duracao of orcamento.duracoes" [value]="duracao.id">
                                {{duracao.duracao}}</option>
                        </select>
                    </div>

                    <div class="col-6 col-lg-1 pr-0">
                        <label class="title__mobile" for="publico">Público</label>
                        <input min="0" class="form-control" (change)="refreshResult(i)" type="number" name="publico"
                            placeholder="Público *" [disabled]="isEditable(i)" [(ngModel)]="orcamento.publico">
                    </div>

                    <div class="col-6 col-lg-2 pr-lg-0">
                        <label class="title__mobile" for="especialista">Especialistas</label>
                        <select (change)="refreshResult(i)" class="form-control" name="especialista"
                            [disabled]="isEditable(i)" [(ngModel)]="orcamento.especialista">
                            <option value="">Selecione *</option>
                            <option *ngFor="let especialista of especialistas" [value]="especialista.id">
                                {{especialista.descricao}}</option>
                        </select>
                    </div>

                    <div class="col-6 col-lg-1 pr-0">
                        <label class="title__mobile" for="tema">Tema</label>
                        <select (change)="refreshResult(i)" class="form-control" name="tema" [disabled]="isEditable(i)"
                            [(ngModel)]="orcamento.tema">
                            <option value="">Selecione *</option>
                            <option *ngFor="let tema of temas" [value]="tema.id">
                                {{tema.nome}}</option>
                        </select>
                    </div>

                    <div *ngIf="orcamento.value_min" class="col-12 col-lg-4 item__result text-center py-0 py-lg-0">

                        <div
                            class="item__result__investimentos relative border-top border-top-lg-0 mt-3 pt-4 mt-lg-0 pt-lg-0">
                            <h3 class="title__mobile">INVESTIMENTO</h3>
                            <div
                                class="item__result__investimentos__info d-flex justify-content-around justify-content-lg-center">
                                <div>
                                    <span class="item__result__investimentos__info__title">Mínimo</span>
                                    <input class="item__result__investimentos__info__result bg-transparent text-center"
                                        disabled prefix="R$ " mask="separator.3" thousandSeparator="."
                                        [(ngModel)]="orcamento.value_min">
                                </div>
                                <div>
                                    <span class="item__result__investimentos__info__title">Médio</span>
                                    <input class="item__result__investimentos__info__result bg-transparent text-center"
                                        disabled prefix="R$ " mask="separator.3" thousandSeparator="."
                                        [(ngModel)]="orcamento.value_med">
                                </div>
                                <div>
                                    <span class="item__result__investimentos__info__title">Máximo</span>
                                    <input class="item__result__investimentos__info__result bg-transparent text-center"
                                        disabled prefix="R$ " mask="separator.3" thousandSeparator="."
                                        [(ngModel)]="orcamento.value_max">
                                </div>
                            </div>
                        </div>

                        <div class="item__result__espec mt-3 mt-lg-0">
                            <h3 class="title__mobile">ESPECIALISTAS</h3>
                            <div class="d-flex justify-content-around">

                                <img (click)="openPalestrante(orcamento.espec_min?.slug)"
                                    *ngIf="orcamento.espec_min?.photo" class="item__result__espec__img"
                                    [src]="orcamento.espec_min?.photo" [alt]="orcamento.espec_min?.nome_sobrenome"
                                    [title]="orcamento.espec_min?.nome_sobrenome">


                                <img (click)="openPalestrante(orcamento.espec_med?.slug)"
                                    *ngIf="orcamento.espec_med?.photo" class="item__result__espec__img"
                                    [src]="orcamento.espec_med?.photo" [alt]="orcamento.espec_med?.nome_sobrenome"
                                    [title]="orcamento.espec_med?.nome_sobrenome">


                                <img (click)="openPalestrante(orcamento.espec_max?.slug)"
                                    *ngIf="orcamento.espec_max?.photo" class="item__result__espec__img"
                                    [src]="orcamento.espec_max?.photo" [alt]="orcamento.espec_max?.nome_sobrenome"
                                    [title]="orcamento.espec_max?.nome_sobrenome">

                                <div (click)="goEspecialistas(i)" class="tr__add p-0"
                                    style="width: auto; background: transparent;">
                                    <img src="../../../assets/icons/more_circle.svg" alt="VER MAIS">
                                </div>

                            </div>
                        </div>
                    </div>

                    <div *ngIf="!orcamento.value_min" class="col-12 col-lg-4 mt-4 mt-lg-0 text-center">
                        <span class="item__result__investimentos__info__title">Carregamento automático</span>
                    </div>
                </div>


            </li>
            <li class="list-group-item">
                <div (click)="addLine()" class="tr__add">
                    <img src="../../../assets/icons/more_circle.svg" alt="adicionar linha">
                    Adicionar mais uma linha
                </div>
            </li>
        </ul>
    </div>

    <div class="p-4 pt-lg-2 col-12 col-lg-10 offset-lg-1">
        <div class="content__actions">
            <button (click)="excel()" class="btn__excel">
                <img src="../../../assets/icons/excel.svg" alt="excel">
                Exportar Tabela
            </button>
            <div style="width: 4px;"></div>
            <button (click)="sendEmail()" class="btn__email">
                <img src="../../../assets/icons/email.svg" alt="email">
                Enviar por e-mail
            </button>
        </div>
    </div>

    <div class="p-4 p-lg-0 col-12 col-lg-8 offset-lg-2">
        <div class="content__conclude mt-3 mt-lg-0 mb-lg-4">
            <button (click)="save()" class="btn__conclude">Concluir</button>
        </div>
    </div>
</div>

<div *ngIf="loading" class="loading">
    <img src="../../../assets/img/simple_loading.gif" alt="loading">
</div>