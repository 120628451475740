import { Routes } from '@angular/router';

import { NotificationsComponent } from './notificacoes';

export const NotificacoesRoutes: Routes = [
  {
    path: 'notificacoes',
    component: NotificationsComponent
  },
  {
    path: 'palestrante/notificacoes',
    component: NotificationsComponent
  },
  {
    path: 'espectador/notificacoes',
    component: NotificationsComponent
  }
];
