<div class="p-4">
  <h3 class="title text-uppercase text-center">
    Alterar foto
  </h3>

  <hr>

  <form
    #form="ngForm"
    novalidate
    (ngSubmit)="changeFoto()"
  >
    <section class="row align-items-center justify-content-center">
      <div *ngIf="croppedImage" class="col-12 col-lg-4">
        <div class="profilePicture d-flex justify-content-center">
          <img [src]="croppedImage" />
        </div>
      </div>

      <div class="col-12 col-lg-8">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="4 / 3"
          format="png"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>
      </div>
    </section>

    <input
      class="my-2"
      type="file"
      name="userPhoto"
      id="userPhoto"
      (change)="onFileChange($event)"
      accept="image/*"
    />

    <hr>

    <div class="d-flex justify-content-center w-100">
      <button
        class="btnCustom btnCustom--secondary m-1 text-uppercase"
        type="reset"
        title="Cancelar"
        (click)="close()"
      >
        Cancelar
      </button>

      <button
        class="btnCustom btnCustom--primary m-1 text-uppercase"
        type="submit"
        [title]="form.valid ? 'Salvar dados' : 'Preencha os campos obrigatórios'"
        [disabled]="!form.valid"
      >
        <span *ngIf="loading" class="colorFontSecondary">
          Carregando <img height="18" src="assets/img/simple_loading.gif" alt="Carregando">
        </span>

        <span *ngIf="!loading">
          Alterar Foto
        </span>
      </button>
    </div>
  </form>
</div>
