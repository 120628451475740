<div class="quiz">
    <div class="container__cabecalho d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <img class="mr-3" height="40px" src="assets/icons/quiz.svg">
            <div class="d-flex flex-column">
                <h2 class="title">Responda o Quiz</h2>
            </div>
        </div>
        <img (click)="cancel()" class="image__close" height="25px" src="assets/icons/close_yellow.svg" alt="fechar"
            title="fechar">
    </div>
    <div class="mt-1">
        <p class="subtitle">Chegou a hora de conhecermos um pouco mais sobre você! Responda as perguntas abaixo
            escolhendo apenas uma das
            opções, levando em conta seus gostos e preferências.</p>
    </div>
    <div *ngIf="quiz">
        <h3 class="subtitle"> <span class="number">1.</span> {{quiz.pergunta}}</h3>

        <div class="container__opcoes">
            <div class="opcao">
                <label (click)="setSelected(1)" class="container__checkbox">
                    {{quiz.opcao1}}
                    <input id="opcao_1" type="checkbox" name="selected1" [(ngModel)]="selected1">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="opcao">
                <label (click)="setSelected(2)" class="container__checkbox">
                    {{quiz.opcao2}}
                    <input type="checkbox" name="selected2" [(ngModel)]="selected2">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div (click)="setSelected(3)" *ngIf="quiz.opcao3" class="opcao">
                <label class="container__checkbox">
                    {{quiz.opcao3}}
                    <input type="checkbox" name="selected3" [(ngModel)]="selected3">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div (click)="setSelected(4)" *ngIf="quiz.opcao4" class="opcao">
                <label class="container__checkbox">
                    {{quiz.opcao4}}
                    <input type="checkbox" name="selected4" [(ngModel)]="selected4">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <button (click)="submit()" class="btn__primary">
                enviar
            </button>
        </div>
    </div>
</div>