export const meses = [
    { value: 1, name: "01", mes: "Janeiro" },
    { value: 2, name: "02", mes: "Fevereiro" },
    { value: 3, name: "03", mes: "Março" },
    { value: 4, name: "04", mes: "Abril" },
    { value: 5, name: "05", mes: "Maio" },
    { value: 6, name: "06", mes: "Junho" },
    { value: 7, name: "07", mes: "Julho" },
    { value: 8, name: "08", mes: "Agosto" },
    { value: 9, name: "09", mes: "Setembro" },
    { value: 10, name: "10", mes: "Outubro" },
    { value: 11, name: "11", mes: "Novembro" },
    { value: 12, name: "12", mes: "Dezembro" }
];
