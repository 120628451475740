<div class="d-flex flex-column flex-lg-row justify-content-center align-items-canter">

    <div class="d-flex flex-column mr-2 pl-lg-2 div__title">
        <h4 class="d-inline font-title">Meus </h4>
        <h4 class="d-inline font-title">Conteúdos</h4>
    </div>

    <div [class.unclickable]="!item.page" class="item flexFull mt-1 mt-lg-0" (click)="goPage(item)" [ngStyle]="{
                'background-color': itemSelected >= item.ordem ? 'var(--yellow)' : '#CACAD0 ',
                'opacity': item.ordem < itemSelected ? '0.5' : '1'
                }" [ngClass]="{ 
                firstTab : item.ordem == 1,
                middleTab : item.ordem > 1,
                lastTab : item.ordem == list.length
            }" *ngFor="let item of list">{{item.title}}</div>
</div>