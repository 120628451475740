import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ComunicacaoService, IFilterGetUsersPush } from 'src/app/services/comunicacao';
import { UsuarioContatosService } from 'src/app/services/usuario-contatos';

export interface IViewersByCompany {
  nome_completo: string
  email: string
  cargo: string
  tutor: "SIM" | "NAO"
  ativo: "SIM" | "NAO"
  id: number
  id_contratante: number
  id_espectador: number
  id_usuario: number
  id_grupo: number
  cadastrado: number
  baixou_app: number
  added: boolean
}

@Component({
  selector: 'app-list-colaboradores',
  templateUrl: './list-colaboradores.component.html',
  styleUrls: ['./list-colaboradores.component.scss']
})
export class ListColaboradoresComponent implements OnInit {
  page: number = 0;
  qtdePages: Array<number>;
  loading: boolean = false;
  reload: boolean = false;

  users: Array<IViewersByCompany> = []
  selected: number = null

  filter: IFilterGetUsersPush = { id_group: null, onlyLeader: null, onlyContributors: null, onlyUsers: false }

  constructor(
    public bsModalRef: BsModalRef,
    private toast: ToastrService,
    private comunicacaoService: ComunicacaoService,
    private usuarioContatosService: UsuarioContatosService
    ) { }

  async ngOnInit() {
    this.load()
  }

  add(v: IViewersByCompany) {
    this.selected = v.id
    this.setList()
  }

  async load() {
    try {
      this.loading = true
      const res: Array<IViewersByCompany> = await this.comunicacaoService.getContributors(this.page, this.filter)
      this.loadPage()
      this.users = res
      this.setList()
      this.loading = false
    } catch (error) {
      this.toast.error("Falha de conexão ao buscar usuários")
      this.loading = false
    }
  }

  async loadPage() {
    this.loading = true
    let onlyLeader = this.filter.onlyContributors
    if (onlyLeader == true) onlyLeader = this.filter.onlyLeader
    const res: any = await this.comunicacaoService.getPagesContributors(this.filter)
    this.setQtdePages(res)
    this.loading = false
  }

  changePage(number: number) {
    this.page = number;
    this.load()
  }

  setQtdePages(amount: number) {
    this.qtdePages = Array(amount);
  }

  resetFilter() {
    this.filter = { id_group: null, onlyLeader: null, onlyContributors: null, onlyUsers: true }
    this.load()
  }

  async convert() {
    if(!this.selected) {
      this.toast.error("Selecione um colaborador")
      return
    }
    this.loading = true
    try {
      await this.usuarioContatosService.convertContactByCollaborator(this.selected)
      this.loading = false
      this.reload = true
      this.bsModalRef.hide()
    } catch (error) {
      this.toast.error("Falha na conexão")
      this.loading = false
    }
  }

  setList() {
    this.users.map((i: IViewersByCompany) => { i.added = (this.selected == i.id) })
  }

}