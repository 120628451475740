import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IPushNotification } from 'src/app/pages/comunicacao-interna/modal-push';

export interface IViewersByCompany {
  nome_completo: string
  email: string
  cargo: string
  tutor: "SIM" | "NAO"
  ativo: "SIM" | "NAO"
  id_contratante: number
  id_espectador: number
  id_usuario: number
  id_grupo: number
  cadastrado: number
  baixou_app: number
}

export interface IFilterGetUsersPush {
  id_group: number
  onlyLeader: boolean | null
  onlyContributors: boolean | null
  onlyUsers: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ComunicacaoService {

  constructor(public http: HttpClient) { }

  async sendPushNotification(idUsers: Array<number>, push: IPushNotification): Promise<any> {
    const user = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = {
      recipientUserId: idUsers,
      scheduleDate: `${push.date} ${push.hour.substring(0, 2)}:${push.hour.substring(2, 4)}:00`,
      "type": "AUTOMATICA",
      "filterSended": "10",
      "creatorUser": user.id,
      "message": {
        "tokens": null,
        "data": {
          "urlLink": push.typeRedirect.value == "LINK" ? push.externalLink : null,
          "openPage": push.typeRedirect.page,
          "dataPage": {
            "subjectId":
              push.idRedirect
          }
        },
        "notification": {
          "image": null,
          "title": push.title,
          "body": push.message
        }
      }
    }

    const result = await this.http.post<any>(`
    ${environment.API_V2_URL_DEFAULT}usuarios/schedulepush`,
      body,
      { headers: headers }).toPromise();
    return result.body;
  }

  async getContributors(page: number, filter: IFilterGetUsersPush): Promise<any> {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let params = `?page=${page}&limit=12&onlyUsers=${filter.onlyUsers}`
    if (filter.id_group) params += `&id_grupo=${filter.id_group}`
    if (filter.onlyContributors) params += `&tutor=NAO`
    if (filter.onlyLeader) params += `&tutor=SIM`
    const result = await this.http.get<any>(`${environment.API_V2_URL}espectador/by-company/${usuario.id_contratante}${params}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getPagesContributors(filter: IFilterGetUsersPush): Promise<any> {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let params = `?limit=12&onlyUsers=${filter.onlyUsers}`
    if (filter.id_group) params += `&id_grupo=${filter.id_group}`
    if (filter.onlyContributors) params += `&tutor=NAO`
    if (filter.onlyLeader) params += `&tutor=SIM`
    const result = await this.http.get<any>(`${environment.API_V2_URL}espectador/by-company/pages/${usuario.id_contratante}${params}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getAllIds(filter: IFilterGetUsersPush): Promise<any> {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let params = "?"
    if (filter.id_group) params += `id_grupo=${filter.id_group}`
    if (filter.onlyContributors) params += `&tutor=NAO`
    if (filter.onlyLeader) params += `&tutor=SIM`
    const result = await this.http.get<any>(`${environment.API_V2_URL}espectador/by-company/ids/${usuario.id_contratante}${params}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getHistory(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}usuarios/push-createdat`, { headers: headers }).toPromise();
    return result.body;
  }

  async getCompanySkills(): Promise<any> {
    const user = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}habilidade/by-company/${user.id_contratante}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getLecturesAvailable(): Promise<any> {
    const user = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}palestra-ministrada/avaiable-to-company/${user.id_contratante}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getCompanyContents(): Promise<any> {
    const user = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}artigo/company/${user.id_contratante}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getPushMessages(): Promise<any> {
    const user = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL}dashboard/usuarios/push`, { headers: headers }).toPromise();
    return result.body;
  }

  async archivePush(messageId): Promise<any> {
    const user = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.put<any>(`${environment.API_V2_URL}dashboard/usuarios/archivePush/${messageId}`, { headers: headers }).toPromise();
    return result.body;
  }
}
