import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { getPrincipalProfile } from 'src/app/controle-perfil';
import { PlanosService, IPlan } from 'src/app/services/planos';
import { UserService } from 'src/app/services/user';
import { IUserPlan } from '../../tb-planos.component';

@Component({
  selector: 'app-planos',
  templateUrl: './planos.component.html',
  styleUrls: ['./planos.component.scss']
})
export class PlanosComponent implements OnInit {

  @Output("cancel") cancel = new EventEmitter();
  @Output("selected") selected = new EventEmitter();
  planSelected: IPlan
  plans: Array<IPlan>
  loading: boolean = false

  userPlan: IUserPlan;
  alertPrincipalProfile = null;

  constructor(
    private planosService: PlanosService,
    private userService: UserService,
    private toast: ToastrService,
  ) { }

  async ngOnInit() {
    await this.getUserPlan()
    this.getPlans()
    
    const profileLogged = window.localStorage.getItem("perfil");
    const profile = getPrincipalProfile()
    if (profileLogged != profile) {
      this.alertPrincipalProfile = `Planos para seu perfil cadastrado: ${profile}`
    }
  }

  async getUserPlan() {
    try {
      this.userPlan = await this.userService.verificaPlano()
    } catch (error) { }
  }


  async getPlans() {
    try {
      this.loading = true
      const profile = getPrincipalProfile()
      let publico: string = profile == "PALESTRANTE" ? "PALESTRANTES" : profile == "CONTRATANTE" ? "CONTRATANTES" : "ESPECTADORES"
      const result = await this.planosService.getPlans(publico)

      this.plans = result.filter(i => (i.valor > 0 || i.id == this.userPlan.plano_id))

      this.loading = false
    } catch (error) {
      this.loading = false
      this.toast.error("Não foi possível carregar os planos")
    }
  }


  formatMoney(price: number): string {
    return (price / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  select(plan: IPlan) {
    this.selected.emit({ plan: plan })
  }
}
