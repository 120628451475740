import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { estados } from 'src/app/common/array-estados';
import { IInvalid } from 'src/app/pages/configuracoes/palestrante/tb-dados';
import { CommonService } from 'src/app/services/common';
import { PalestrantesService } from 'src/app/services/palestrantes';

interface ISolicitarDepoimento {
  palestrante_id: number
  nome: string
  empresa: string
  cidade: string
  email: string
  telefone: string
  renomado: string
  data: any
  nomedoevento: string
  tipodoevento: number
  descricao: string
}

@Component({
  selector: 'app-form-solicitar-depoimentos-admin',
  templateUrl: './form-solicitar-depoimentos-admin.component.html',
  styleUrls: ['./form-solicitar-depoimentos-admin.component.scss']
})
export class FormSolicitarDepoimentoAdminComponent implements OnInit {

  @Output("finished") finished = new EventEmitter();
  @Input() palestrante: any;

  invalids: Array<IInvalid> = [];
  loading: boolean = false;
  arrayEstados = estados;
  arrayCidades: Array<any> = [];

  form: ISolicitarDepoimento = {
    palestrante_id: 0,
    nome: "",
    empresa: "",
    cidade: "",
    email: "",
    telefone: "",
    renomado: "",
    data: new Date,
    nomedoevento: "",
    tipodoevento: 1,
    descricao: '',
  };

  estado: string = "";

  constructor(
    private palestrantesService: PalestrantesService,
    private commonService: CommonService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.form.palestrante_id = this.palestrante.id;
    this.form.renomado = this.palestrante?.renomado || false;
  }


  async submit() {
    try {
      this.invalids = [];
      this.loading = true;
      const res = await this.palestrantesService.solicitarDepoimento(this.form);
      this.loading = true;
      this.toast.success("Solicitado com sucesso");
      this.cancel();
    } catch (e) {
      this.loading = false;
      if (e?.error?.message.invalids) {
        this.invalids = e.error.message.invalids;
        this.toast.error("Campos inválidos!", "Por favor, revise.");
      } else {
        this.toast.error("Não foi possível salvar os dados", "Falha na conexão");
      }
    }
  }

  cancel() {
    this.finished.emit({ refresh: false });
  }

  async getCidades(estado: string){
    try {
      this.arrayCidades = await this.commonService.getCidades(estado);
    } catch (error) {
      this.toast.error("Não foi possível buscar as cidades");
    }
  }

  isIncorrect(value: string) {
    for (const key in this.invalids) {
      const item = this.invalids[key];
      if (item['campo'] == value) return true;
    }
    return false;
  }

  getError(value: string) {
    for (const key in this.invalids) {
      const item = this.invalids[key];
      if (item['campo'] == value) return item['erro'];
    }
    return "";
  }
}
