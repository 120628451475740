<section class="psa-showcase-register topo">


    <div class="flexFull">
        <div *ngIf="loading" class="spinner-border text-warning" role="status">
            <span class="sr-only">Buscando...</span>
        </div>
    </div>

    <div class="container" *ngIf="palestraItem">
        <div class="row">
            <div class="col-12 col-lg-6">
                <iframe *ngIf="linkVideo" width="425" height="328" [src]="linkVideo" frameborder="0"></iframe>
                <img style="border-radius: 4px; object-fit: cover; width: 100%;" *ngIf="!linkVideo" width="425"
                    height="328" [src]="palestraItem.photo || 'https://placehold.it/300'" alt="foto palestra">
            </div>
            <div class="col-12 col-lg-6">
                <h2 class="mt-4 mt-lg-0">PALESTRA</h2>
                <h1>{{palestraItem.nome}}</h1>
                <p>{{palestraItem.descricao}}</p>
            </div>
        </div>
    </div>
</section>

<!-- <section class="psa-featured-themes" *ngIf="assuntosRelacionados.length">
    <div class="container">
      <h1 class="psa-featured-themes-title subthemes">Temas da Palestra</h1>
      <ul class="flex-container wrap">
        <li *ngFor="let assunto of assuntosRelacionados" class="flex-item">{{assunto.nome}}</li>
      </ul>
    </div>
</section> -->

<section class="psa-featured-themes" *ngIf="!loading && profissional">
    <div class="container">
        <div class="row">

            <div class="col-12 col-lg-6" *ngIf="depoimentos && depoimentos.length">
                <app-depoimentos [palestrante]="profissional.nome_palestrante" [depoimentos]="depoimentos"></app-depoimentos>
            </div>


            <div class="col-12 col-lg-6">
                <h1 class="text-center text-lg-left">Especialista</h1>
                <div class="row especialista">
                    <div class="col-12 col-lg-5 container__img__especialista">
                        <figure>
                            <img [src]="profissional.photo" [attr.alt]="profissional.slug" />
                        </figure>
                    </div>
                    <div class="col-12 col-lg-7 container__txt__especialista">
                        <h1>{{profissional.nome_palestrante}}</h1>
                        <p>{{getMinibio(profissional.minibio)}}</p>
                        <div class="psa-form-group">
                            <button type="button" (click)="gotoPerfil(profissional.slug)">PERFIL COMPLETO</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="mb-5">
    <app-palestras-carousel *ngIf="palestras.length > 0" [palestrasInput]="palestras"
        [titulo]="'Palestras relacionadas'"></app-palestras-carousel>
</section>

<app-orcar-buttom></app-orcar-buttom>