<div class="pl-lg-5 pr-lg-4">

    <div class="pr-0">
        <div class="mb-3 align-middle d-flex align-items-center">
            <button (click)="openFilter()" type="button"
                class="btn btn-warning btn-back-circle-middle float-left flexFull mr-3">
                <img height="21" src="assets/icons/filter.svg" alt="filtros" title="Filtros">
            </button>
            <h3 class="d-inline font-title m-0">Especialistas</h3>
        </div>
    </div>

    <div class="row">

        <!-- FILTRO -->
        <section class="section__filter pl-0"
            [ngClass]="{'col-12 col-lg-3': isOpenFilters, 'div__filter__none': !isOpenFilters}">

            <div class="div__filter">
                <form [formGroup]="formGroup" (submit)="applyFilter()">
                    <h6 class="d-inline filter__title text-uppercase m-0">
                        <img class="mr-2" height="16" src="assets/icons/filter.svg" alt="filtros" title="Filtros">
                        Filtros
                    </h6>


                    <div class="pt-2 mt-3">
                        <label class="filter__label" for="pesquisa">BUSCA POR TEXTO</label>
                        <input type="text" placeholder="Buscar *" name="pesquisa" formControlName="pesquisa" />
                    </div>

                    <div class="pt-2">
                        <label class="filter__label" for="id_tema">Tema</label>
                        <select class="form-control" name="id_tema" formControlName="id_tema">
                            <option value="">Selecione *</option>
                            <option *ngFor="let option of temas" [value]="option.id">{{option.nome}}</option>
                        </select>
                    </div>

                    <div class="pt-2">
                        <label class="filter__label" for="formato">Formato</label>
                        <select class="form-control" name="formato" formControlName="formato">
                            <option value="">Selecione *</option>
                            <option *ngFor="let option of formatos" [value]="option.nome_formato">
                                {{option.nome_formato}}</option>
                        </select>
                    </div>

                    <div class="pt-2">
                        <label class="filter__label" for="investimento">Investimento</label>
                        <select class="form-control" name="investimento" formControlName="investimento">
                            <option value="">Selecione *</option>
                            <option *ngFor="let option of investimentos" [value]="option.value">
                                {{option.text}}</option>
                        </select>
                    </div>

                    <button type="submit" class="filter__btn">Aplicar filtros</button>
                </form>
            </div>
        </section>
        <!-- FILTRO -->


        <div [ngClass]="{'col-12 col-lg-9': isOpenFilters}" class="container__conteudo p-0 w-100">

            <!-- <h5 class="d-inline font-title">Conheça os especialistas da Profissionais SA</h5> -->

            <section class="py-4 mt-lg-0">
                <div class="flexFull" *ngIf="loading">
                    <app-spinner></app-spinner>
                </div>

                <div class="d-flex justify-content-center flex-wrap pl-2">

                    <div *ngIf="especialistas.length == 0 && !loading" class="flexFull mt-4">
                        <h6 class="text__empty">Nenhum palestrante encontrado.</h6>
                    </div>

                    <div class="palestras__palestrantes mr-4" *ngFor="let especialista of especialistas">
                        <!-- <a class="text-decoration-none text-dark" (click)="navigateToEspecialistaPerfil(especialista.slug, especialista.id_tema)"> -->
                        <a [routerLink]="['/especialistas/', especialista.slug]" class="text-decoration-none text-dark">

                            <div class="palestras__palestrantes__palestrante text-center pb-3"
                                [class.d-none]="especialista.hide">

                                <div class="palestras__palestrantes__palestrante__img m-2">
                                    <img [src]="especialista.photo" />
                                    <div class="palestras__palestrantes__palestrante__body__orcar flexFull">
                                        <button (click)="orcar()" class="orcar__btn">
                                            <img class="mr-2" src="assets/icons/shopping-cart.svg" alt="Orçar"
                                                width="19px" height="17px">
                                            <span class="font-primary">Orçar</span>
                                        </button>
                                    </div>
                                </div>

                                <div class="palestras__palestrantes__palestrante__body text-center">
                                    <h2 class="font__primary">{{especialista.nome_sobrenome}}</h2>
                                    <p class="palestras__palestrantes__palestrante__body__desc">
                                        {{getMinibio(especialista.minibio)}}</p>
                                    <button class="palestras__palestrantes__palestrante__body__link">Ver
                                        Perfil</button>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>

            </section>
        </div>

    </div>

    <div class="d-flex justify-content-center align-items-center mt-2">
        <nav *ngIf="qtdePages?.length > 1" aria-label="Page navigation example">
            <ul class="pagination">
                <li (click)="changePage(page-1)" class="page-item" *ngIf="1 != (page + 1)">
                    <a class="page-link">Anterior</a>
                </li>

                <li [ngStyle]="{'font-weight': 0 == page ? 'bold' : 'normal'}" (click)="changePage(0)" class="page-item">
                    <a class="page-link">1</a>
                </li>
                <span *ngIf="(page + 1) > 4" class="px-2">...</span>

                <li [ngStyle]="{'font-weight': number == page ? 'bold' : 'normal'}" (click)="changePage(number)" class="page-item" *ngFor="let number of getPages()">
                    <a class="page-link">{{number+1}}</a>
                </li>

                <span *ngIf="(page + 1) < (qtdePages.length - 3)" class="px-2">...</span>
                <li [ngStyle]="{'font-weight': page == (qtdePages.length - 1)? 'bold' : 'normal'}" (click)="changePage(qtdePages.length - 1)" class="page-item">
                    <a class="page-link">{{qtdePages.length}}</a>
                </li>

                <li (click)="changePage(page+1)" class="page-item" *ngIf="qtdePages.length != (page + 1)">
                    <a class="page-link">Próxima</a>
                </li>
            </ul>
        </nav>
    </div>
</div>