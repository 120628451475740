import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ContratanteService } from 'src/app/services/contratante';

interface colaboradoresRetorno {
  nome_completo: string
  email: string
  cpf: string
  setor?: string
  cargo?: string
  erro?: string
}

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  loading: boolean = false;

  model_file = "https://basesa.s3-sa-east-1.amazonaws.com/arquivos/modelo_importacao.xlsx";

  formGroup = this.fb.group({
    file: [null, Validators.required]
  });

  colaboradoresRetorno: Array<colaboradoresRetorno> = [];
  invalids: Array<colaboradoresRetorno> = [];

  step: number = 1;
  qtdePages: Array<number> = [];
  page: number = 0;
  limit: number = 10;

  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private contratanteService: ContratanteService,
    private toast: ToastrService,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,) { }

  ngOnInit(): void {
    // TESTE
    // this.step = 2
    // this.colaboradoresRetorno = JSON.parse(window.localStorage.getItem('col_teste'))
    // this.setQtdePages()
  }


  onFileChange(event: any) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.formGroup.patchValue({
          file: reader.result
        });

        this.cd.markForCheck();
        this.import()
      };
    }
  }

  import() {
    this.loading = true;
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let id_contratante = usuario.id_contratante

    let base64 = this.formGroup.controls.file.value.split("base64,")[1]
    try {
      this.contratanteService.importColaboradores({
        id_contratante: id_contratante,
        file64: base64
      }).then(res => {
        this.loading = false;

        if (res.imported) {
          this.toast.success("Importado com sucesso!")
          this.bsModalRef.hide()
          this.router.navigate(['/universidade/seleciona-lideres'])
          return
        }

        this.step = 2
        this.invalids = res.invalids ? res.invalids : [];
        this.colaboradoresRetorno = [...res.invalids, ...res.colaboradores]
        this.setQtdePages()
      })
    } catch (error) {
      this.toast.error("Não foi possível importar!")
      this.loading = false;
    }
  }

  downloadExample() {
    let a = document.createElement("a");
    a.href = this.model_file;
    a.download = "modelo_importacao.xlsx";
    a.click();
    a.remove();
  }

  changePage(number: number) {
    this.page = number;
  }

  setQtdePages() {
    let cont = parseInt((this.colaboradoresRetorno.length / this.limit) + '')
    cont = cont < (this.colaboradoresRetorno.length / this.limit) ? cont + 1 : cont
    this.qtdePages = Array.from(Array(cont).keys())
  }

  getPages() {
    let listPage = this.qtdePages.filter(i => i > 0 && i < (this.qtdePages.length - 1) && i >= (this.page - 2) && i <= (this.page + 2))
    return listPage
  }

  backToImport() {
    this.step = 1
    this.invalids = [];
    this.colaboradoresRetorno = []
  }

  nextIgnoring(toValid: boolean) {
    this.loading = true;
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let id_contratante = usuario.id_contratante

    try {
      this.contratanteService.insertCollectionColaboradores({
        id_contratante: id_contratante,
        colaboradores: this.colaboradoresRetorno,
        needValidation: toValid
      })
        .then(res => {
          this.loading = false;

          if (res.imported || toValid == false) {
            this.toast.success("Importado com sucesso!")
            this.bsModalRef.hide()
            this.router.navigate(['/universidade/seleciona-lideres'])
            return
          }

          this.invalids = res.invalids ? res.invalids : [];
          this.colaboradoresRetorno = [...res.invalids, ...res.colaboradores]
          this.setQtdePages()
        })
        .catch((e) => {
          this.toast.error("Não foi possível importar!")
          this.loading = false;
        })
    } catch (error) {
      this.toast.error("Não foi possível importar!")
      this.loading = false;
    }
  }

  close() {
    if (window.confirm("Deseja fechar? Você irá cancelar a importação!")) this.bsModalRef.hide()
  }
}
