import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SaNewsService } from 'src/app/services/sa-news';
import { IArtigo } from '../home'
import { ToastrService } from 'ngx-toastr';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-artigo',
  templateUrl: './artigo.component.html',
  styleUrls: ['./artigo.component.scss']
})
export class ArtigoComponent implements OnInit {
  slug: string

  selectDefaultOption: String;
  identificador: String;
  submitted = false;
  mensagem: string;

  proximo: IArtigo;
  anterior: IArtigo;

  artigo: IArtigo | null
  artigosRelacionados: Array<IArtigo>
  isLoading: boolean = false

  options: OwlOptions = {
    margin: 10,
    dots: true,
    nav: false,
    responsive: { 0: { items: 1 }, 400: { items: 2 }, 900: { items: 2.8 }, 1350: { items: 3.3 } }
  };

  constructor(
    // private rdStationService: RdStationService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private saNewsService: SaNewsService
  ) { }

  ngOnInit(): void {
    this.slug = this.route.snapshot.paramMap.get("slug")
    this.getArtigo()
  }

  getArtigo(): void {
    this.isLoading = true
    try {
      this.saNewsService.getBySlug(this.slug).then(res => {
        this.artigo = res.artigo
        this.artigosRelacionados = res.relacionados
        this.proximo = res.proximo
        this.anterior = res.anterior

        this.titleService.setTitle(this.artigo?.titulo);
        this.isLoading = false

      })
    } catch (error) {
      this.isLoading = false
    }
  }

  goTipo(id_tipo: number) {
    this.router.navigate(['sa-news/categoria/' + id_tipo])
  }

  goArtigo(slug: string, categoria: string) {
    this.router.navigate(["sa-news/" + categoria + "/" + slug])
    this.slug = slug
    this.getArtigo()
  }

  share(social_media: string) {
    let base_url: string = 'https://base.profissionaissa.com/artigo-sa/' + this.slug
    switch (social_media) {
      case 'linkedin':
        window.open(`https://www.linkedin.com/cws/share?url=${base_url}`)
        break;

      case 'facebook':
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${base_url}`)
        break;

      case 'twitter':
        window.open(`http://twitter.com/share?text=${this.artigo.titulo}&url=${base_url}`)
        break;

      case 'whatsapp':
        window.open(`https://api.whatsapp.com/send?text=${base_url}`)
        break;

      case 'telegram':
        window.open(`https://telegram.me/share/url?url=${base_url}&text=${this.artigo.titulo}`)
        break;

      default:
        window.open(`https://www.linkedin.com/cws/share?url=${base_url}`)
    }
  }

  getCategoriaNome(): string {
    return this.artigo?.categoria.replace(/[-]+/g, ' ')
  }
}
