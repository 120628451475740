import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ComunicacaoService } from 'src/app/services/comunicacao';

export interface IPushNotification {
  title: string,
  message: string,
  date: Date,
  hour: any,
  type: string
  idRedirect: number
  typeRedirect: ITypeRedirects
  indexTypeRedirect: number
  externalLink: string
}

export interface ITypeRedirects {
  value: string
  text: string
  page?: string
}

@Component({
  selector: 'app-modal-push',
  templateUrl: './modal-push.component.html',
  styleUrls: ['./modal-push.component.scss']
})
export class ModalPushComponent implements OnInit {

  loading: boolean = false
  users: Array<number> = []
  sent: boolean = false;

  typeRedirects: Array<ITypeRedirects> = [
    {
      value: null,
      text: "Sem Redirecionamento",
    },
    {
      value: "LINK",
      text: "Link Externo",
    },
    {
      value: "LECTURE",
      text: "Palestras",
      page: "palestra",
    },
    {
      value: "CONTENT",
      text: "Conteúdo",
    },
    {
      value: "SKILL",
      text: "Cursos rápidos",
      page: "desenvolvimento",
    },
  ]

  lectures: Array<{ id, nome }> = []
  contents: Array<{ id, titulo }> = []
  skills: Array<{ id, titulo }> = []

  push: IPushNotification = {
    title: "",
    message: "",
    date: null,
    hour: null,
    type: "AUTOMATICA",
    indexTypeRedirect: 0,
    typeRedirect: this.typeRedirects[0],
    idRedirect: null,
    externalLink: null
  }

  constructor(
    public bsModalRef: BsModalRef,
    private comunicacaoService: ComunicacaoService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getCombos()
  }

  getCombos() {
    this.comunicacaoService.getLecturesAvailable().then((res: any) => this.lectures = res)
    this.comunicacaoService.getCompanyContents().then((res: any) => this.contents = res)
    this.comunicacaoService.getCompanySkills().then((res: any) => this.skills = res)
  }

  async sendPush() {
    try {
      this.push.typeRedirect = this.typeRedirects[this.push.indexTypeRedirect]
      if (!this.validations()) return

      this.loading = true
      const result = await this.comunicacaoService.sendPushNotification(this.users, this.push)

      this.loading = false
      this.toast.success("Notificação salva com sucesso")

      this.sent = true
      this.bsModalRef.hide()
    } catch (error) {
      this.loading = false
      this.toast.error("Erro ao gravar notificação")
    }
  }

  validations(): boolean {

    if (!this.push.title) {
      this.toast.error("Informe o título")
      return false
    }

    if (!this.push.message) {
      this.toast.error("Informe a mensagem")
      return false
    }

    if (!this.push.date) {
      this.toast.error("Informe a data")
      return false
    }

    if (!this.push.hour) {
      this.toast.error("Informe a hora")
      return false
    }

    if (this.push.typeRedirect.value == "LINK" && !this.push.externalLink) {
      this.toast.error("Informe o link externo")
      return false
    }

    if (
      (this.push.typeRedirect.value &&
      this.push.typeRedirect.value != "LINK") &&
      !this.push.idRedirect) {
      this.toast.error("Ou selecione: Sem direcionamento", "Informe o direcionamento")
      return false
    }
    return true
  }
}
