import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GrupoService {

  constructor(public http: HttpClient) { }

  async getCountGrupos(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/count"}`, { headers: headers }).toPromise();
    return result.body[0];
  }

  async getGrupos(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/grupos"}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getGrupo(id_grupo: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/" + id_grupo}`, { headers: headers }).toPromise();
    return result.grupo;
  }

  async getGrupoLideres(id_grupo: number, page: number, search: string): Promise<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/grupo-lideres/" + id_grupo + "/" + page + "/" + search}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getGrupoColaboradores(id_grupo: number, page: number, search: string): Promise<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/grupo-colaboradores/" + id_grupo + "/" + page + "/" + search}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getGrupoColaboradoresByTutor(id_grupo: number, id_usuario_lider: number, page: number, search: string): Promise<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/grupo-tutor-colaboradores/" + id_grupo + "/" + id_usuario_lider + "/" + page + "/" + search}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getLideres(id_grupo: number, page: number, search: string, limitPage: number): Promise<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const usuario = JSON.parse(localStorage.getItem("usuario"))

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}grupos/lideres/${usuario.id_contratante}/${id_grupo}/${page}/${search}?limit=${limitPage}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getColaboradores(id_grupo: number, page: number, search: string): Promise<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const usuario = JSON.parse(localStorage.getItem("usuario"))

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/colaboradores/" + usuario.id_contratante + "/" + id_grupo + "/" + page + "/" + search}`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertGrupo(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "grupos/insert"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async updateGrupo(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "grupos/update"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async getGrupoHabilidades(id_grupo: number): Promise<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/grupo-habilidades/" + id_grupo}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getCompetencias(): Promise<any> {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}grupos/competencias?idContratante=${usuario.id_contratante}`).toPromise();
    return result.body;
  }


  async saveHabilidades(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "grupos/save-habilidades"}`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async saveColaboradores(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "grupos/save-colaboradores"}`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async saveLideres(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "grupos/save-lideres"}`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async verificaColaboradores(id_grupo: number): Promise<any> {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/verifica-colaboradores/" + usuario.id_contratante + "/" + id_grupo}`, { headers: headers }).toPromise();
    return result.body;
  }

  async updateTutorColaboradoresCollection(id_grupo: number, lider_atual: object, lider_novo: object): Promise<any> {
    const usuario = JSON.parse(localStorage.getItem("usuario"))
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const body = {
      id_grupo: id_grupo,
      lider_atual: lider_atual,
      lider_novo: lider_novo,
      id_contratante: usuario.id_contratante,
    }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "grupos/update-tutor-colaboradores"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async liderHasLiderado(id_grupo_usuario: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT + "grupos/has-liderado/" + id_grupo_usuario}`, { headers: headers }).toPromise();
    return result.body;
  }

  async sendMaterial(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT + "grupos/send-material-grupo"}`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async removeColaboradorEquipe(id_grupo_equipe: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.delete<any>(`${environment.API_V2_URL_DEFAULT + "grupos/remove-colaborador-equipe/" + id_grupo_equipe}`, { headers: headers }).toPromise();
    return result.body;
  }

  async removeGrupoHabilidade(id_grupo_habilidade: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.delete<any>(`${environment.API_V2_URL_DEFAULT + "grupos/remove-grupo-habilidade/" + id_grupo_habilidade}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getRanking(body: any, page: number, limitPage: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    body['page'] = page
    body['limit'] = limitPage
    const params: string = `?idUsuarioEmpresa=${body.id_usuario_empresa}&idGrupo=${body.id_grupo}&idUsuarioLider=${body.id_usuario_lider}&idCompetencia=${body.id_competencia}&pesquisa=${body.pesquisa}&limit=${body.limit}&page=${body.page}`
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/espectadores/ranking${params}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getRankingInfo(body: any, limitPage: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    body['limit'] = limitPage
    const params: string = `?idUsuarioEmpresa=${body.id_usuario_empresa}&idGrupo=${body.id_grupo}&idUsuarioLider=${body.id_usuario_lider}&idCompetencia=${body.id_competencia}&pesquisa=${body.pesquisa}&limit=${body.limit}`
    const result = await this.http.get<any>(`${environment.API_V2_URL}common/espectadores/ranking-info${params}`, { headers: headers }).toPromise();
    return result.body;
  }

  async getGruposByLider(id_colaborador_lider: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}grupos/lider-grupos/${id_colaborador_lider}`, { headers: headers }).toPromise();
    return result.body;
  }

  async changeLider(id_grupos_usuarios: number, id_colaborador: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = { id_grupos_usuarios, id_colaborador }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}grupos/change-lider`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async verifyAndCreateDefaultGroup(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = {}
    const result = await this.http.put<any>(`${environment.API_V2_URL_DEFAULT}grupos/insert-default-group`, body, { headers: headers }).toPromise();
    return result.body;
  }

  async insertViewerInGroup(id_grupo: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = { id_grupo }
    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}grupos/inserir-espectador-grupo`, body, { headers: headers }).toPromise();
    return result.body;
  }
}
