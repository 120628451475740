import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-profile-picture-update-modal',
  templateUrl: './profile-picture-update-modal.component.html',
})
export class ProfilePictureUpdateModalComponent implements OnInit {
  loading: boolean        = false;
  success: boolean        = false;
  imageChangedEvent: any  = '';
  croppedImage: any       = '';
  userId: number;

  form = this.fb.group({
    file: [null, Validators.required],
  });

  constructor(
    public bsModalRef: BsModalRef,
    private toast: ToastrService,
    private usuarioService: UserService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.userId = JSON.parse(localStorage.getItem('usuario')).id;
    this.form.controls.file.setValue(null);
  }

  close() {
    this.bsModalRef.hide();
  }

  async changeFoto() {
    this.loading = true;

    await this.usuarioService.setFoto({ img_data64: this.croppedImage }, this.userId)
      .then(() => {
        this.success = true;
        this.toast.success('Foto alterada com sucesso!');
        this.close();
      })
      .catch((error) =>{
        this.toast.error('Não foi possível alterar a foto!');
      })

    this.loading = false;
  }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.form.patchValue({ file: reader.result });
        this.cd.markForCheck();
      };
    }

    this.imageChangedEvent = event;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
}
