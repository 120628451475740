import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common';
import { UserService } from 'src/app/services/user';
import { getKeyConfigSupportWhats, getPrincipalProfile } from 'src/app/controle-perfil';
import { EspectadoresService } from 'src/app/services/espectadores';

export interface IUserPlan {
  ativo: number
  data_cancelamento: null | Date
  id: number
  mensagem: string
  palestras_disponiveis: number
  palestras_total: number
  plano_descricao: string
  plano_id: number
  plano_nome: string
  plano_valido_ate: string
  usuario_id: number
  valido: boolean
  valor_pago: number
}

@Component({
  selector: 'app-tb-planos',
  templateUrl: './tb-planos.component.html',
  styleUrls: ['./tb-planos.component.scss']
})
export class TbPlanosComponent implements OnInit {

  plan: IUserPlan = null;

  showCheckout: boolean = false;
  idPlan: number = null;

  whatsNumber: string = "5511956372536";

  alertPrincipalProfile = null;
  hasPermissionActions: boolean = true

  constructor(
    private userService: UserService,
    private commonService: CommonService,
    private espectadoresService: EspectadoresService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.getUserPlan()


    const profileLogged = window.localStorage.getItem("perfil").toUpperCase();
    const profile = getPrincipalProfile()
    if (profileLogged != profile) {
      this.alertPrincipalProfile = `Plano do seu perfil cadastrado: ${profile}`
    }

    if (profile == "ESPECTADOR" && this.espectadoresService.userHasCompanyPlan()) {
      this.hasPermissionActions = false
    } else if (profile == "CONTRATANTE") {
      this.hasPermissionActions = false
    }
  }

  async getUserPlan() {
    try {
      this.plan = await this.userService.verificaPlano()
    } catch (error) {
      this.toast.error("Não foi possível carregar seu plano")
    }
  }

  formatMoney(): string {
    let valor: number = this.plan.valor_pago / 100
    return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  renewPlan() {
    this.showCheckout = true
    this.idPlan = this.plan.plano_id
  }

  updatePlan() {
    this.idPlan = null
    this.showCheckout = true
  }

  finished(event: any) {
    this.showCheckout = false
    if (event.success) {
      this.getUserPlan()
    }
  }


  async cancelPlan() {
    const usuario = JSON.parse(window.localStorage.getItem("usuario"));
    try {
      let key: string = getKeyConfigSupportWhats()

      const res: { valor } = await this.commonService.getConfiguracao(key, "5511956372536")
      this.whatsNumber = res.valor

    } catch (error) {
      this.whatsNumber = "5511956372536";
    }

    let text = `Olá, entro em contato por meio do site Profissionais SA - Dashboard. Sou ${usuario.nome}, e-mail: ${usuario.email}. Gostaria de cancelar meu plano.`;
    window.open(`https://api.whatsapp.com/send?phone=${this.whatsNumber}&text=${text}`, "_blank")

  }
}
