import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioContatosService {

  constructor(public http: HttpClient) { }


  async getUsuarioContatos(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}contratantes/contatos`, { headers: headers }).toPromise();
    return result.body;
  }

  async insertContato(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}contratantes/insert-contato`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async updateContato(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}contratantes/update-contato`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async deleteContato(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    const result = await this.http.delete<any>(`${environment.API_V2_URL_DEFAULT}contratantes/${id}`, { headers: headers }).toPromise();
    return result.body;
  }

  async updateSenhaContato(body: object): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const result = await this.http.post<any>(`${environment.API_V2_URL}usuario-contatos/convert-to-collaborator`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async convertContactToCollaborator(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = { id }
    const result = await this.http.post<any>(`${environment.API_V2_URL}usuario-contatos/convert-to-collaborator`, body, { headers: headers }).toPromise();
    return result.body;
  }


  async convertContactByCollaborator(id: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));
    let body = { id }
    const result = await this.http.post<any>(`${environment.API_V2_URL}usuario-contatos/create-by-collaborator`, body, { headers: headers }).toPromise();
    return result.body;
  }


}
