import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tipo-conteudo',
  templateUrl: './tipo-conteudo.component.html',
  styleUrls: ['./tipo-conteudo.component.scss', '../common-style.scss']
})
export class TipoConteudoComponent implements OnInit {

  routes = [
    { id: 1, route: 'conteudos/competencias', params: { tipo: 1 } },
    { id: 2, route: 'conteudos/competencias', params: { tipo: 2 } },
    { id: 3, route: 'comunicacao-interna', params: null },
  ]

  constructor(
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Meus conteúdos")
  }


  go(index: number) {
    this.router.navigate(
      [this.routes[index].route],
      { queryParams: this.routes[index].params }
    )
  }
}
