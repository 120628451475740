import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { PerfilComponent } from './perfil';
import { TbSenhaComponent } from './tb-senha';
import { ModalAlterarFotoComponent } from './modal-alterar-foto';
import { PerfilPalestranteComponent } from './perfil-palestrante';
import { PerfilEspectadorComponent } from './perfil-espectador';
import { TbPlanosComponent } from './tb-planos';
import { TbEmpresaComponent } from './contratante/tb-empresa';
import { TbContatosComponent } from './contratante/tb-contatos';
import { TbDadosComponent } from './palestrante/tb-dados';
import { TbDadosFiscaisComponent } from './palestrante/tb-dados-fiscais';
import { FotoPerfilComponent } from './foto-perfil';
import { ExportComponentsModule } from 'src/app/common/export-components';
import { ConcordaTermosComponent } from './concorda-termos';
import { TbEspectadorComponent } from './espectador/tb-espectador';
import { FormContatoComponent } from './contratante/tb-contatos/form-contato';
import { OpcoesAdicaoContatoComponent } from './contratante/tb-contatos/opcoes-adicao-contato';
import { ListColaboradoresComponent } from './contratante/tb-contatos/list-colaboradores';
import { CheckoutPlanosComponent } from './tb-planos/checkout-planos';
import { PlanosComponent } from './tb-planos/checkout-planos/planos';
import { PagamentoComponent } from './tb-planos/checkout-planos/pagamento';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { PersonalDataComponent } from './user-settings/personal-data/personal-data.component';
import { ProfilePictureComponent } from './profile-picture/profile-picture.component';
import { ProfilePictureUpdateModalComponent } from './profile-picture/profile-picture-update-modal/profile-picture-update-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    PerfilComponent,
    TbEmpresaComponent,
    TbSenhaComponent,
    TbContatosComponent,
    ModalAlterarFotoComponent,
    PerfilPalestranteComponent,
    TbDadosComponent,
    TbDadosFiscaisComponent,
    TbPlanosComponent,
    FotoPerfilComponent,
    ConcordaTermosComponent,
    PerfilEspectadorComponent,
    TbEspectadorComponent,
    FormContatoComponent,
    OpcoesAdicaoContatoComponent,
    ListColaboradoresComponent,
    CheckoutPlanosComponent,
    PlanosComponent,
    PagamentoComponent,
    UserSettingsComponent,
    PersonalDataComponent,
    ProfilePictureComponent,
    ProfilePictureUpdateModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    ExportComponentsModule,
    ImageCropperModule
  ],
  exports: [FotoPerfilComponent]
})
export class ConfiguracoesModule { }
