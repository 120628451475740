<section>

    <header class="header__palestra d-flex align-items-center justify-content-between">
        <div class="d-flex flex-column">
            <span class="subtitle">Palestra</span>
            <h1 *ngIf="palestra" class="title">{{palestra.titulo}}</h1>
        </div>
        <button (click)="goApp()" class="d-flex align-items-center justify-content-center d-md-none btn__primary">
            baixe o app
        </button>
    </header>


    <div class="container__live">

        <div class="container__interactions">

            <div class="container__pdf">

                <pdf-viewer *ngIf="pdf" style="display: inline;" [src]="pdf" [render-text]="true" [show-all]="false"
                    [autoresize]="true" [original-size]="false" [zoom-scale]="'page-fit'" [(page)]="pdfPage">
                </pdf-viewer>

                <div *ngIf="!pdf" style="height: 100%;" class="flexFull flex-column">
                    <img height="50px" src="assets/img/slide.png">
                    <label class="subtitle">Nenhum arquivo</label>
                </div>
            </div>

            <div class="container__title__interactions">
                <span class="subtitle">Interações</span>
            </div>

            <div class="container__actions">
                <div class="d-flex">
                    <div (click)="setShow('QUIZ')" [class.unlocked]="hasQuiz" [class.card__unclickable]="!hasQuiz"
                        class="container__actions__item quiz">
                        <img [class.d-none]="!hasQuiz" class="image__lock" src="assets/icons/padlock.svg"
                            alt="Bloqueada">
                        <img [class.d-none]="hasQuiz" class="image__lock" src="assets/icons/unlock.svg"
                            alt="desbloqueada">
                        <div class="container__actions__item__text container__actions__item__text d-flex flex-column">
                            <span class="text__locked">
                                {{hasQuiz ? 'Desbloqueada' : 'Bloqueada'}}
                            </span>
                            <h3 class="title">Responda O Quiz</h3>
                        </div>
                        <div class="action__imagem">
                            <img src="assets/icons/quiz_white.svg" alt="quiz">
                        </div>
                    </div>

                    <div (click)="setShow('TERMOMETRO')" [class.unlocked]="hasRating"
                        [class.card__unclickable]="!hasRating" class="container__actions__item rating">
                        <img [class.d-none]="!hasRating" class="image__lock" src="assets/icons/padlock.svg"
                            alt="Bloqueada">
                        <img [class.d-none]="hasRating" class="image__lock" src="assets/icons/unlock.svg"
                            alt="desbloqueada">
                        <div class="container__actions__item__text d-flex flex-column">
                            <span class="text__locked">
                                {{hasRating ? 'Desbloqueada' : 'Bloqueada'}}
                            </span>
                            <h3 class="title">Termômetro</h3>
                        </div>
                        <div class="action__imagem">
                            <img src="assets/icons/meter.svg" alt="termômetro">
                        </div>
                    </div>

                    <!-- <div (click)="setShow(VIDEO)" [class.unlocked]="hasVideo" [class.card__unclickable]="!hasVideo"
                        class="container__actions__item video">
                        <img [class.d-none]="hasVideo" class="image__lock" src="assets/icons/unlock.svg"
                            alt="desbloqueada">
                        <img [class.d-none]="!hasVideo" class="image__lock" src="assets/icons/padlock.svg"
                            alt="Bloqueada">
                        <div class="container__actions__item__text d-flex flex-column">
                            <span class="text__locked">
                                {{hasVideo ? 'Desbloqueada' : 'Bloqueada'}}
                            </span>
                            <h3 class="title">Vídeos de Apoio</h3>
                        </div>
                        <div class="action__imagem">
                            <img src="assets/icons/video_outline.svg" alt="vídeo">
                        </div>
                    </div> -->

                    <div class="container__actions__item user__satisfation">
                        <img class="image__lock" src="assets/icons/padlock.svg" alt="Bloqueada">
                        <div class="container__actions__item__text d-flex flex-column">
                            <span class="text__locked">Bloqueada</span>
                            <h3 class="title">Pesquisa</h3>
                        </div>
                        <div class="action__imagem">
                            <img src="assets/icons/smile_white.svg" alt="satisfação">
                        </div>
                    </div>

                </div>
            </div>
        </div>




        <div class="container__player">

            <div [class.d-none]="!palestra || palestra?.status != 'EXECUTANDO'" class="content__player">
                <div style="height: 100%; width: 100%; border-radius: 5px;" (click)="play()" #player></div>
            </div>

            <div *ngIf="palestra?.status != 'EXECUTANDO'" class="content__player flexFull">
                <label style="font-size: 1.5rem;" class="subtitle">
                    {{palestra?.status == 'CONCLUIDA' ? 'Live finalizada' : 'Live ainda não iniciou.' }}
                </label>
            </div>


            <div class="container__form">
<!--
                <app-chat [class.d-none]="showInteraction != 'CHAT'" [socket]="socket" [chat]="chat"
                    [idPalestra]="idPalestra"></app-chat>

                <app-quiz [class.d-none]="showInteraction != 'QUIZ'" [socket]="socket"
                    [idPalestra]="idPalestra" (hasQuiz)="setHasQuiz(null)" (finished)="setHasQuiz($event)"></app-quiz>

                <app-videos [socket]="socket"></app-videos> -->

                <app-termometro [class.d-none]="showInteraction != 'TERMOMETRO'" [idPalestra]="idPalestra"
                    (finished)="resetShow()">
                </app-termometro>

            </div>
        </div>

    </div>


</section>
