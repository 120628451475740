import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cadastrar-palestrante',
  templateUrl: './cadastrar-palestrante.component.html',
  styleUrls: ['./cadastrar-palestrante.component.scss'],
})
export class CadastrarPalestranteComponent implements OnInit {

  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {}

  voltar() {
    this.router.navigateByUrl('/admin/palestrantes');
  }
}
