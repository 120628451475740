<section class="page login">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="login__container py-5">
          <h1 class="font-weight-bold text-center">Bem-vindo a Profissionais SA.</h1>
          <form class="login__container__form mt-4">
            <div class="form-group d-flex align-items-center mb-0">
              <input class="form-control custom-input" [type]="showPass ? 'text' : 'password' " name="senha"
                placeholder="Senha" [(ngModel)]="senha" (ngModelChange)="checkPasswordRule()" />
              <img [class.none]="showPass" class="show" (click)="setShow()" src="assets/icons/eye.svg"
                alt="mostrar senha" title="Mostrar senha">
              <img [class.none]="!showPass" class="show" (click)="setShow()" src="assets/icons/closed-eye.svg"
                alt="mostrar senha" title="Mostrar senha">
            </div>
            <app-check-validacao-senha #validacaoPass [senha]="senha"></app-check-validacao-senha>
            <div class="form-group d-flex align-items-center mt-2">
              <input class="form-control custom-input" [type]="showPassConfirm ? 'text' : 'password' "
                name="confirmSenha" placeholder="Confirme sua senha" [(ngModel)]="confirmSenha" />
              <img [class.none]="showPassConfirm" class="show" (click)="setShowConfirm()" src="assets/icons/eye.svg"
                alt="mostrar senha" title="Mostrar senha">
              <img [class.none]="!showPassConfirm" class="show" (click)="setShowConfirm()" src="assets/icons/closed-eye.svg"
                alt="mostrar senha" title="Mostrar senha">
            </div>
            <label class="login__container__form__mensagem pl-2">{{mensagem}}</label>
            <div class="form-group">
              <button *ngIf="!loading"
                class="d-block w-100 border-0 rounded text-uppercase bg-warning font-weight-bold py-2" type="submit"
                (click)="redefinir(senha, confirmSenha)">Redefinir</button>
              <button *ngIf="loading"
                class="d-block w-100 border-0 rounded text-uppercase bg-warning font-weight-bold py-2" type="submit">
                <img height="40px" src="../../../assets/img/simple_loading.gif" alt="loading">
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>