<div class="container-fluid container__dados mb-4">

    <div class="d-flex justify-content-between">
        <h5 class="font-title text-uppercase">{{id > 0 ? 'Editar Evento' : 'Adicionar Evento'}}</h5>
        <button (click)="cancel()" type="button" class="close" data-dismiss="modal" aria-label="Close" title="Cancelar">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <form class="row" (submit)="submit()">

        <div class="col-12 d-flex flex-column">

            <div class="row">
                <div class="form-group col-12 col-md-3">
                    <label class="label-input" for="id_casa_formato">formato *</label>
                    <select id="id_casa_formato" [class.invalid]="isIncorrect('id_casa_formato')" class="form-control"
                        name="id_casa_formato" placeholder="Formato *" (change)="getDuracoes()"
                        [(ngModel)]="palestra.id_casa_formato" [ngModelOptions]="{standalone: true}">
                        <option value="null">Selecione *</option>
                        <option *ngFor="let i of formatos" [value]="i.id">{{i.nome_formato}}</option>
                    </select>
                    <span class="error">{{getError('id_casa_formato')}}</span>
                </div>

                <div [class.d-none]="!(palestra.id_casa_formato > 0)" class="form-group col-12 col-md-3">
                    <label class="label-input" for="id_casa_formula_duracao">duração *</label>
                    <select id="id_casa_formula_duracao" [class.invalid]="isIncorrect('id_casa_formula_duracao')"
                        class="form-control" name="id_casa_formula_duracao" placeholder="Duração *"
                        [(ngModel)]="palestra.id_casa_formula_duracao" [ngModelOptions]="{standalone: true}">
                        <option value="null">Selecione *</option>
                        <option *ngFor="let i of duracoes" [value]="i.id">{{i.duracao}}</option>
                    </select>
                    <span class="error">{{getError('id_casa_formula_duracao')}}</span>
                </div>

                <div class="form-group col-12 col-md-6" [class.col-md-9]="!(palestra.id_casa_formato > 0)">
                    <label class="label-input" for="nome">
                        título *
                        (<a class="exemplo" (click)="mostrarExemploTitulo = !mostrarExemploTitulo">
                            {{!mostrarExemploTitulo?"ver exemplo":"esconder exemplo"}}</a>)
                    </label>
                    <p class="exemplo" [class.d-none]="!mostrarExemploTitulo">{{exemploTitulo}}</p>
                    <input id="nome" [class.invalid]="isIncorrect('nome')" class="form-control" name="nome" type="text"
                        [(ngModel)]="palestra.nome" placeholder="Título *" maxlength=100 [ngModelOptions]="{standalone: true}">
                    <span class="error">{{getError('nome')}}</span>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12 col-md-6">
                    <label class="label-input" for="subtitulo">
                        subtítulo
                        (<a class="exemplo" (click)="mostrarExemploSubtitulo = !mostrarExemploSubtitulo">
                            {{!mostrarExemploSubtitulo?"ver exemplo":"esconder exemplo"}}</a>)
                    </label>
                    <p class="exemplo" [class.d-none]="!mostrarExemploSubtitulo">{{exemploSubtitulo}}</p>
                    <input id="subtitulo" [class.invalid]="isIncorrect('subtitulo')" class="form-control"
                        name="subtitulo" type="text" [(ngModel)]="palestra.subtitulo" placeholder="Subtítulo"
                        maxlength=50 [ngModelOptions]="{standalone: true}">
                    <span class="error">{{getError('subtitulo')}}</span>
                </div>

                <div class="form-group col-12 col-md-6">
                    <label class="label-input" for="id_tema">tema *</label>
                    <ng-select [class.invalid]="isIncorrect('id_tema')" [searchable]="true" [clearable]="true"
                        bindLabel="nome" bindValue="assuntos_id" placeholder="Selecione *" notFoundText="Não encontrado"
                        [(ngModel)]="palestra.id_tema" [items]="temas" [ngModelOptions]="{standalone: true}">
                    </ng-select>
                    <span class="error">{{getError('id_tema')}}</span>
                </div>
            </div>


            <div class="row">
                <div class="form-group col-12">
                    <label class="label-input" for="descricao">Descrição *
                        (<a class="exemplo" (click)="mostrarExemploDescricao = !mostrarExemploDescricao">
                            {{!mostrarExemploDescricao?"ver exemplo":"esconder exemplo"}}</a>)
                    </label>
                    <div [class.d-none]="!mostrarExemploDescricao" class="ex__75">
                        <p class="exemplo" [innerHTML]="exemploDescricao"></p>
                    </div>
                    <textarea id="descricao" [class.invalid]="isIncorrect('descricao')" class="form-control" type="text"
                        name="descricao" maxlength="600" [(ngModel)]="palestra.descricao"
                        placeholder="Descreva sua palestra" [ngModelOptions]="{standalone: true}"></textarea>
                    <span class="error">{{getError('descricao')}}</span>
                </div>
            </div>

            <div class="row">

                <div class="form-group col-12 col-md-4">
                    <label class="label-input" for="img_temp">Imagem</label>
                    <input (change)="onFileChange($event)" id="img_temp" [class.invalid]="isIncorrect('img_temp')"
                        class="form-control form-control-file" name="img_temp" type="file" [(ngModel)]="img_temp"
                        placeholder="Imagem" maxlength=60 [ngModelOptions]="{standalone: true}">
                </div>

                <div class="form-group col-12 col-md-4">
                    <label class="label-input" for="video">Código do Vídeo (Youtube)</label>
                    <input (change)="changeVideo()" id="video" [class.invalid]="isIncorrect('video')"
                        class="form-control" name="video" type="text" [(ngModel)]="palestra.video"
                        placeholder="Código do Vídeo" maxlength=60 [ngModelOptions]="{standalone: true}">
                    <span class="error">{{getError('video')}}</span>
                </div>

                <div class="form-group col-12 col-md-4">
                    <label class="label-input" for="publico_alvo">público-alvo</label>
                    <input id="publico_alvo" [class.invalid]="isIncorrect('publico_alvo')" class="form-control"
                        name="publico_alvo" type="text" [(ngModel)]="palestra.publico_alvo" placeholder="Públicao-alvo"
                        maxlength=60 [ngModelOptions]="{standalone: true}">
                    <span class="error">{{getError('publico_alvo')}}</span>
                </div>
            </div>

            <div class="row">
                <div *ngIf="palestra.photo && palestra.photo.length > 0"
                    class="form-group content__imagem col-12 col-md-3">
                    <label class="label-input" for="photo">Imagem atual</label>
                    <img [src]="palestra.photo" alt="imagem">
                </div>

                <div *ngIf="palestra.new_photo_64" class="form-group content__imagem col-12 col-md-3">
                    <label class="label-input" for="photo">Imagem carregada</label>
                    <img [src]="palestra.new_photo_64" alt="imagem">
                </div>
            </div>

            <div class="row">

                <div class="d-flex pt-2 div__btn__salvar">

                    <button (click)="cancel()" type="button" class="btn btn-primary btn-secondary mr-3"
                        title="Cancelar">CANCELAR</button>

                    <button *ngIf="!loading" type="submit" class="btn btn-primary btn-warning">Salvar</button>

                    <button *ngIf="loading" class="btn btn-primary btn-warning mt-2">
                        <img height="40px" src="assets/img/simple_loading.gif" alt="carregando">
                    </button>
                </div>
            </div>

        </div>
    </form>
</div>
