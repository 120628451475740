import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common';

@Component({
  selector: 'app-passo3',
  templateUrl: './passo3.component.html',
  styleUrls: ['./passo3.component.scss']
})
export class Passo3Component implements OnInit {
  public whatsNumber = '5511956372536';

  constructor(
    private commonService: CommonService,
    private router: Router,
    private titleService: Title,) { }

  ngOnInit() {
    this.titleService.setTitle("Calculadora");

    this.commonService.getConfiguracao("telefone__contato__calculadora", "5511956372536")
      .then(res => { this.whatsNumber = res.valor })
      .catch(e => { console.error(e); })
  }

  openInstagram() {
    window.open("https://www.instagram.com/profissionaissa", "_blank")
  }

  openWhats() {
    const usuario = JSON.parse(window.localStorage.getItem("usuario"));
    let texto = "Olá, entro em contato por meio do site Profissionais SA - Dashboard. Sou " + usuario.nome + ", e-mail: + " + usuario.email;
    texto += usuario.empresa ? ". Empresa: " + usuario.empresa : ".";
    window.open("https://api.whatsapp.com/send?phone=" + this.whatsNumber + "&text=" + texto, "_blank")
  }

  refreshCalc() {
    this.router.navigate(['calculadora/passo2'])
  }
}