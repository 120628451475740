<div class="container-fluid">
    <div class="row mx-1 mx-md-5">

        <app-wizard class="col-12 col-md-10" [id]="2"></app-wizard>
        <div class="col-12 col-md-2 container__btn__add">
            <button *ngIf="idContratante == competencia?.id_contratante || isPSA" (click)="insert()"
                class="btn__primary">ADICIONAR</button>
        </div>

        <div class="col-12 mt-3 container__default">

            <div *ngIf="loading" class="flexFull">
                <app-spinner></app-spinner>
            </div>

            <div class="d-flex flex-column">

                <div *ngIf="!habilidades || habilidades.length == 0">
                    Nenhuma habilidade
                </div>

                <div *ngFor="let item of habilidades" class="content__item div__shadow row mx-0">
                    <div class="col-12 col-md-9 content__item__info">
                        <h4 class="font-title">{{item.titulo}}</h4>
                        <p [innerHTML]="getResumo(item.resumo)" class="content__item__descricao"></p>
                    </div>
                    <div class="col-12 col-md-3 flexColFull border-left">

                        <div (click)="goHabilidade(item.id_habilidade)" class="flexFull acao">
                            <img src="assets/icons/editar.svg" alt="editar">
                            <label class="font-title">EDITAR/perguntas</label>
                        </div>
                        <div *ngIf="idContratante == item.id_contratante || isPSA" (click)="delete()" class="flexFull acao">
                            <img src="assets/icons/excluir.svg" alt="excluir">
                            <label class="font-title">Excluir</label>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>