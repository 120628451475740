export const segmentos = [
    { value: null, text: 'Selecione um segmento' },
    { value: 'agronegocio', text: 'Agronegócio' },
    { value: 'alimentosbebidas', text: 'Alimentos e Bebidas' },
    { value: 'arquiteturaengenhariaconstrucao', text: 'Arquitetura, Engenharia e Construção' },
    { value: 'automotivo', text: 'Automotivo' },
    { value: 'bensconsumo', text: 'Bens de Consumo' },
    { value: 'consultoria', text: 'Consultoria' },
    { value: 'cooperativas', text: 'Cooperativas' },
    { value: 'educacao', text: 'Educação' },
    { value: 'energiautilidadepublica', text: 'Energia e Utilidade Pública' },
    { value: 'equipamentosmedicos', text: 'Equipamentos Médicos' },
    { value: 'farmaceuticobiotecnologia', text: 'Farmacêutico e Biotecnologia' },
    { value: 'hospitaiselaboratorios', text: 'Hospitais e Laboratórios' },
    { value: 'industria', text: 'Industria' },
    { value: 'mineracaometalurgia', text: 'Mineração e Metalurgia' },
    { value: 'oleogas', text: 'Óleo e Gás' },
    { value: 'operadorassaude', text: 'Operadoras de Saúde' },
    { value: 'produtosquimicos', text: 'Produtos Químicos' },
    { value: 'promotoresdeeventos', text: 'Promotores de eventos' },
    { value: 'servicos', text: 'Serviços' },
    { value: 'servicosfinanceiros', text: 'Serviços Financeiros' },
    { value: 'orgaopublico', text: 'Órgão Público' },
    { value: 'tisoftware', text: 'TI e Software' },
    { value: 'transportelogistica', text: 'Transporte e Logística' },
    { value: 'universidades', text: 'Universidades' },
    { value: 'varejo', text: 'Varejo' },
];
