import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})
export class AcessoriaService {
  constructor(public http: HttpClient) {}

  async getList(palestranteId: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));


    const result = await this.http.get<any>(`${environment.API_V2_URL_DEFAULT}assessoria/list/${palestranteId}`, { headers: headers }).toPromise();

    return result.body;
  }

  async insertOrUpdate(acessoria: any, palestranteId: number): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', localStorage.getItem("token"));

    const body = { ...acessoria }
    const res = await this.http.post<any>(`${environment.API_V2_URL_DEFAULT}assessoria/insert-update/${palestranteId}`, body, { headers: headers }).toPromise();
    return res.body;
  }
}
