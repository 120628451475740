import { Routes } from '@angular/router';

import { ListComponent } from './list';
import { PerfilComponent } from './perfil';

export const EspecialistasRoutes: Routes = [
  {
    path: 'especialistas',
    component: ListComponent
  },
  {
    path: 'especialistas/:slug',
    component: PerfilComponent
  },
];
