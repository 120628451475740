import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/services/admin/admin.service';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.scss']
})
export class EditarUsuarioComponent implements OnInit {

  user: any = {};
  userId: string;

  form: any = {};

  constructor(
    private router: Router,
    private toast: ToastrService,
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.userId = this.router.url.split('/').pop();

    this.getUser();
  }

  getUser(): void {
    this.adminService.getCuradorById(this.userId)
    .then(res => {
      this.user = res[0];
      this.createForms();
    })
    .catch(e => {
      this.toast.error('Não foi possível encontrar o usuário');
      this.router.navigate(['/admin/usuarios']);
    });
  }

  createForms(): void {
    this.form = {
      nome: this.user.nome || '',
      sobrenome: this.user.sobrenome || '',
      email: this.user.email || '',
      perfil: this.user.perfil || '',
      telefone: this.user.telefone || '',
      senha: '',
      is_active: this.user.is_active,
    };
  }

  save(): void {
    if (!this.form.senha) {
      this.form.senha = this.user.senha;
    }

    this.adminService.editarCurador(this.userId, this.form)
    .then(res => {
      this.toast.success('Usuário editado com sucesso!');
      this.form.senha = '';
      this.router.navigate(['/admin/usuarios']);
    })
    .catch(e => {
      console.log(e);
      this.toast.error('Não foi possível editar esse usuário');
      this.form.senha = '';
    })
  }

}
