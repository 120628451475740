import { Routes } from '@angular/router';
import { CadastrarPalestranteComponent } from './palestrantes/cadastrar-palestrante/cadastrar-palestrante.component';
import { EditarPalestranteComponent } from './palestrantes/editar-palestrante/editar-palestrante.component';
import { PalestrantesComponent } from './palestrantes/palestrantes.component';
import { PrecosComponent } from './precos/precos.component';
import { CadastrarUsuarioComponent } from './usuarios/cadastrar-usuario/cadastrar-usuario.component';
import { EditarUsuarioComponent } from './usuarios/editar-usuario/editar-usuario.component';
import { UsuarioComponent } from './usuarios/usuario.component';
import { NewHomeComponent } from '../newHome/new-home.component';
import { SearchHomeComponent } from '../pesquisar/searchHome/search-home.component';
import { AnswerComponent } from '../pesquisar/answer/answer.component';
import { ResultsComponent } from '../results/results.component';
import { ResultadosPesquisasComponent } from './resultados-pesquisas/resultados-pesquisas.component';
import { CreateFormComponent } from './certificados/create-forms/create-forms.component';
import { AnswerFormComponent } from './certificados/answer-forms/answer-forms.component';
import { CertificatesAnswersComponent } from './certificados/certificates-answers/certificates-answers.component';
import { ConfirmarIndicacaoComponent } from '../confirmarIndicacao/confirmar-indicacao.component';
import { EditEventComponent } from './certificados/edit-event/edit-event.component';
import { VerifyCertificatesComponent } from './certificados/verify-certificates/verify-certificates.component';
import { PalestranteExclusivoComponent } from './palestrantes/cadastrar-palestrante-exclusivo/palestrante-exclusivo.component';
import { VideosComponent } from './tbsb/videos/videos.component';
import { TbsbComponent } from './tbsb/tbsb.component';
import { VideoDetailsComponent } from './tbsb/videos/video-details/video-details.component';
export const AdminRoutes: Routes = [
  {
    path: 'admin/home',
    component: NewHomeComponent,
  },
  {
    path: 'admin/pesquisar',
    component: SearchHomeComponent,
  },
  {
    path: 'admin/resposta',
    component: AnswerComponent,
  },
  {
    path: 'admin/resultados',
    component: ResultsComponent,
  },
  {
    path: 'admin/palestrantes',
    component: PalestrantesComponent,
  },
  {
    path: 'admin/palestrantes/cadastro',
    component: CadastrarPalestranteComponent,
  },
  {
    path: 'admin/palestrantes/cadastro-exclusivo',
    component: PalestranteExclusivoComponent,
  },
  {
    path: 'admin/usuarios',
    component: UsuarioComponent,
  },
  {
    path: 'admin/usuario/editar/:usuarioId',
    component: EditarUsuarioComponent,
  },
  {
    path: 'admin/usuarios/cadastro',
    component: CadastrarUsuarioComponent,
  },
  {
    path: 'admin/palestrantes/:id',
    component: EditarPalestranteComponent,
  },
  {
    path: 'admin/tabela-precos',
    component: PrecosComponent,
  },
  {
    path: 'admin/pesquisas-site',
    component: ResultadosPesquisasComponent,
  },
  {
    path: 'admin/certificados',
    component: CreateFormComponent,
  },
  {
    path: 'admin/certificados/editar/:id',
    component: EditEventComponent,
  },
  {
    path: 'responder-formulario/:hash',
    component: AnswerFormComponent,
  },
  {
    path: 'confirmar-indicacao/:proposalId/:speakerId',
    component: ConfirmarIndicacaoComponent,
  },
  {
    path: 'admin/respostas-formulario',
    component: CertificatesAnswersComponent,
  },
  {
    path: 'admin/verificar-certificados',
    component: VerifyCertificatesComponent,
  },
  {
    path: 'admin/tbsb',
    component: TbsbComponent
  },
  {
    path: 'admin/tbsb/videos/:id',
    component: VideoDetailsComponent,
  }
];
